<template>
  <core-signs-labels-view-template title="Render Progress">
    <core-view-section title="Renders">
      <v-row class="mt-3">
        <v-col>
          <paper-stock-loading-images v-model="paperStockDialog" :showAll="true">
          </paper-stock-loading-images>
        </v-col>
      </v-row>
      <v-row v-if="isLabelAdmin">
        <v-col title="Username override is a label admin function.">
          <v-combobox
            class="admin-icon"
            prepend-icon="supervised_user_circle"
            v-model="usernameOverride"
            :items="usernameOverrides"
            label="Username Override"
            @change="initLabelItemsTable"
            :clearable="true"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-if="renderProgress"
            v-model="renderProgress.selected"
            :headers="renderProgress.headers"
            :items="renderProgress.items"
            :loading="renderProgress.loading"
            :disable-pagination="true"
            :options.sync="renderProgress.options"
            :fixed-header="true"
            @click:row="renderProgressRowClick"
            @update:sort-by="renderProgress.get(renderProgressAdditionalFilter)"
            item-key="batchHashId"
            class="elevation-1 scrollable"
            show-expand
            :single-expand="true"
            :expanded.sync="expanded"
          >
            <template v-slot:item.beginDate="{ value }">{{ value | formatDate }}</template>
            <template v-slot:item.endDate="{ value }">{{ value | formatDate }}</template>
            <template v-slot:item.timeInserted="{ value }">
              {{ value | formatUtcDateTime }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                :disabled="item.refreshing"
                :loading="item.refreshing"
                @click="refreshRenderingsProgress(item, true)"
                >Refresh</v-btn
              >
            </template>
            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length">
                <v-data-table
                  :loading="item.refreshing"
                  :headers="renderProgressHeaders"
                  :items="item.renderProgresses"
                  dense
                >
                  <template v-slot:item.progress="{ item }">
                    <data-circular-progress
                      v-if="item.progress < 100 || item.errorMessage"
                      class="my-2"
                      :size="50"
                      :width="5"
                      :id="item.renderId"
                      :showDescription="false"
                      :showName="false"
                      :initialValue="item.progress"
                      :closeOnError="false"
                      :errorState="!!item.errorMessage"
                      @error="progressUpdated"
                      @progressUpdated="progressUpdated"
                    ></data-circular-progress>
                    <span v-else>{{ item.progress }}%</span>
                  </template>
                  <template v-slot:item.errorMessage="{ item }">
                    {{ formattedErrorMessage(item) }}
                  </template>
                  <template v-slot:item.pdfUrl="{ item }">
                    <v-btn v-if="item.pdfUrl && !item.errorMessage" @click="openLabelSheet(item)"
                      >Show Print Sheet</v-btn
                    >
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      :disabled="reRendering"
                      :loading="reRendering"
                      @click="reRender(item)"
                      v-if="item.errorMessage && item.errorMessage.length > 0"
                      >Re-Render</v-btn
                    >
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:body.append>
              <infinite-loader
                :pogonaTable="renderProgress"
                :additionalFilter="renderProgressAdditionalFilter"
                :colspan="renderProgress.headers.length"
              ></infinite-loader>
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="renderProgress"></infinite-paganation>
        </v-col>
      </v-row>
      <v-row v-if="pdfDialog">
        <v-col>
          <v-dialog v-model="pdfDialog" :scrollable="true" max-width="65em">
            <v-card>
              <v-card-title>
                <v-col>
                  <v-row>
                    <span class="headline float-left">Rendered Label Sheet</span>
                  </v-row>
                </v-col>
              </v-card-title>
              <v-card-text>
                <v-row class="mt-1">
                  <data-print-dialog
                    v-model="printDialogActive"
                    class="float-right"
                    v-if="this.$useCustomPrintDialog"
                    :pdfUrl="pdfSrc"
                    :partialUrls="partialUrls"
                    :pdfFileName="fileNameFromFilePath(pdfSrc)"
                    :isLandscape="selectedRenderingIsPortrait === false"
                    :numPages="selectedTotalPages"
                    :loading="printLoading"
                    :disabled="printLoading"
                    :showPartialsCheckbox="showPartialsCheckbox"
                    @printed="printed"
                    @print-finished="printFinished"
                  ></data-print-dialog>
                </v-row>
                <v-row
                  ><label class="text--black" v-if="selectedTotalPages && selectedTotalPages > 0"
                    >Total Pages: {{ selectedTotalPages }}</label
                  ></v-row
                >
                <v-row>
                  <v-col>
                    <data-pdf
                      :src="pdfSrc"
                      width="70em"
                      :height="`${windowHeight - 300}px`"
                      :downloadButton="!printDialogActive"
                      :printButton="!printDialogActive"
                      :zoom="'page-width'"
                    ></data-pdf>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="pdfDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <data-tour
          tourName="renderingsProgressTour"
          :steps="tourSteps"
          maxWidth="600px"
          :show="true"
        ></data-tour>
      </v-row>
    </core-view-section>
  </core-signs-labels-view-template>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import { mapState } from 'vuex';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import PogonaDataTable from '@/utils/PogonaDataTable';
import stringHelperMixins from '@/mixins/string-helper-mixins';
import { vueWindowSizeMixin } from 'vue-window-size';
import blobUrlMixins from '@/mixins/blob-url-mixins';
import { ContainerClient } from '@azure/storage-blob';
import { PaperStockLoadingImages } from './PaperStockLoadingImages.vue';

export default {
  mixins: [vueWindowSizeMixin, stringHelperMixins, blobUrlMixins],
  components: {
    PaperStockLoadingImages,
  },
  data() {
    return {
      renderProgress: null,
      pdfDialog: false,
      pdfSrc: null,
      expanded: [],
      reRendering: false,
      batchHashRefreshing: {},
      renderStartWsConn: [],
      adalHelper: new AdalHelpers(),
      lastUpdate: [],
      svgTemplatePortraitMappings: [],
      selectedRenderingIsPortrait: false,
      printDialogActive: false,
      selectedItem: null,
      selectedTotalPages: null,
      isLabelAdmin: false,
      usernameOverride: null,
      paperStockDialog: false,
      printLoading: false,
      partialUrls: null,
      showPartialsCheckbox: false,
      setupPollId: null,
      usernameOverrides: [],
      renderProgressHeaders: [
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Progress',
          value: 'progress',
        },
        {
          text: 'Result',
          value: 'errorMessage',
        },
        {
          text: 'Print Sheet',
          value: 'pdfUrl',
          sortable: false,
        },
        {
          text: 'Re-Render (Only On Error)',
          value: 'actions',
          sortable: false,
        },
      ],
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Render Progress Video Tour',
          },
          video: 'renderings_progress',
          placement: 'bottom',
        },
      ],
    };
  },
  async created() {
    this.isLabelAdmin = await this.$authApi.roleHasRights('LabelAdmin');
    if (this.isLabelAdmin) {
      this.usernameOverrides = (
        await this.$authApi.http.get(
          `label/renderingsprogressapply/usernamesdistinct/${this.username}`,
        )
      ).data;
    }

    this.setupPollLastUpdate();
    await this.getTemplatesAndSpecs();
    await this.initLabelItemsTable();
  },
  destroyed() {
    if (this.setupPollId !== null) {
      clearInterval(this.setupPollId);
    }
  },
  computed: {
    ...mapState('app', ['username']),
    renderProgressAdditionalFilter() {
      return null;
    },
    selectedUsername() {
      if (this.isLabelAdmin === true && this.usernameOverride && this.usernameOverride.length > 0) {
        return this.usernameOverride;
      }
      return this.username;
    },
  },
  methods: {
    setPdfSrc(item) {
      this.pdfSrc = item.pdfUrl;
    },
    async openLabelSheet(item) {
      this.selectedItem = item;
      this.setPdfSrc(item);
      this.selectedRenderingIsPortrait = this.svgTemplatePortraitMappings[item.svgTemplateId];

      this.showPartialsCheckbox = false;
      this.partialUrls = null;

      if (item.partialFilesJson !== null && item.partialFilesJson.length > 0) {
        try {
          this.partialUrls = JSON.parse(item.partialFilesJson);

          if (this.partialUrls.length > 0) {
            this.showPartialsCheckbox = true;
          }
        } catch (err) {
          console.error('failed to parse partialFilesJson', err);
        }
      }

      // get total pages from blob metadata
      if (item.totalPages !== null && item.totalPages !== undefined) {
        this.selectedTotalPages = item.totalPages;
      } else {
        try {
          const urlParts = item.pdfUrl.split('/');
          const blobFileName = urlParts.slice(3, urlParts.length).join('/');
          const metadata = (
            await this.$authApi.http.get(`label/render/blob/metadata/${blobFileName}`)
          ).data;

          if (metadata && isNaN(metadata.totalPages) === false) {
            this.selectedTotalPages = Number(metadata.totalPages);
          } else {
            this.selectedTotalPages = null;
          }
        } catch (e) {
          this.$appInsights.trackException({
            exception: e,
            properties: {
              text: `Error getting properies for PDF '${item.pdfUrl}'`,
              username: this.username,
              storeNumber: this.storeNumber,
              route: this.$route,
              id: '878f9da1-b323-49c0-9c1c-b8608b42af41',
            },
          });
        }
      }

      this.pdfDialog = true;
    },
    async printed() {
      this.printLoading = true;
    },
    printFinished() {
      this.printLoading = false;
    },
    async getTemplatesAndSpecs() {
      const gets = [];
      gets.push(this.$authApi.http.get('label/svgtemplate?$select=templateId,specId'));
      gets.push(this.$authApi.http.get('label/sheetspecification?$select=specId,isPortrait'));

      await Promise.all(gets);

      const templates = (await gets[0]).data;
      const specs = (await gets[1]).data;

      for (const template of templates) {
        const spec = specs.filter(x => x.specId === template.specId);
        if (spec && spec.length === 1) {
          this.svgTemplatePortraitMappings[template.templateId] = spec[0].isPortrait;
        } else {
          this.svgTemplatePortraitMappings[template.templateId] = true;
        }
      }
    },
    renderProgressRowClick(e) {
      this.expanded = [e];
    },
    // there are cases where the page is loaded with 99% complete
    // the page will miss the 100% progress update because things happened so fast
    // this attempts to poll the database to check on the progress if no progress update
    // via websockets have been sent in a little while.
    // once a render progress is at 100% they *should* be removed from this.lastUpdate
    setupPollLastUpdate() {
      this.setupPollId = setInterval(async () => {
        const currTime = new Date().getTime();
        for (const lix in this.lastUpdate) {
          const lu = this.lastUpdate[lix];
          // if no update in a minute, force refresh
          if (lu.progress >= 100) {
            delete this.lastUpdate[lix];
          } else if (currTime - lu.time > 10000) {
            // find the batch item
            let item = null;
            for (item of this.renderProgress.items) {
              const itemIx = item.renderProgresses.findIndex(x => x.renderId === lix);
              if (itemIx > -1) {
                break;
              }
              item = null;
            }

            if (item) {
              await this.refreshRenderingsProgress(item, true);
            }
          }
        }
      }, 5000);
    },
    progressUpdated(e) {
      // keep track of last update
      this.lastUpdate[e.id] = { time: new Date().getTime(), progress: e.complete };

      if (e.complete >= 100 || e.error) {
        for (const i of this.renderProgress.items) {
          const progressLine = i.renderProgresses.filter(x => x.renderId === e.id);
          if (progressLine && progressLine.length === 1) {
            progressLine[0].pdfUrl = e.additionalData.pdfUrl;
            progressLine[0].errorMessage = e.additionalData.error;
            break;
          }
        }
      }
    },
    async getRenderProgressRow(batchHashId) {
      return await this.$authApi.http.get(
        `label/renderingsprogress/${this.selectedUsername}/${batchHashId}`,
      );
    },
    async refreshRenderingsProgress(item, turnOffRefresh) {
      let renderData = null;
      try {
        const rendering = await this.getRenderProgressRow(item.batchHashId);
        renderData = rendering.data;
        renderData.refreshing = true;

        // if any of the renderProgresses are at 100% remove them from the last update array
        if (renderData.renderProgresses && renderData.renderProgresses.length > 0) {
          for (const rp of renderData.renderProgresses) {
            if (rp && this.lastUpdate[rp.renderId] && rp.progress >= 100) {
              delete this.lastUpdate[rp.renderId];
            }
          }
        }

        for (const ix in this.renderProgress.items) {
          if (this.renderProgress.items[ix].batchHashId === item.batchHashId) {
            // if we're poll refreshing, for this batch, see if any render ID's have changed
            // if they did change, turn off polling
            if (this.batchHashRefreshing[item.batchHashId]) {
              let turnOffRefreshing = false;
              const boundRenderProgresses = this.renderProgress.items[ix].renderProgresses;

              if (boundRenderProgresses && boundRenderProgresses.length > 0) {
                for (const rix in boundRenderProgresses) {
                  if (
                    boundRenderProgresses[rix].renderId !==
                    rendering.data.renderProgresses[rix].renderId
                  ) {
                    turnOffRefreshing = true;
                    break;
                  }
                }
              } else if (
                boundRenderProgresses.length === 0 &&
                rendering.data.renderProgresses.length > 0
              ) {
                turnOffRefreshing = true;
              }

              if (turnOffRefreshing === true) {
                clearInterval(this.batchHashRefreshing[item.batchHashId]);
                delete this.batchHashRefreshing[item.batchHashId];
                Vue.set(this.renderProgress.items, ix, rendering.data);
              }
            } else {
              Vue.set(this.renderProgress.items, ix, rendering.data);
            }

            for (const rix in this.renderProgress.items[ix].renderProgress) {
              Vue.set(
                this.renderProgress.items[ix].renderProgress,
                rix,
                rendering.data[ix].renderProgress[rix],
              );
            }
            break;
          }
        }
        if (turnOffRefresh === true) {
          renderData.refreshing = false;
        }
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to refresh',
          err,
          id: 'f3b4000e-6fcb-4238-bb96-014490f25bea',
        });
        if (turnOffRefresh === true) {
          renderData.refreshing = false;
        }
      }
    },
    setRefreshingForProgress(batchHashId, refreshing) {
      for (const rix in this.renderProgress.items) {
        if (this.renderProgress.items[rix].batchHashId === batchHashId) {
          Vue.set(this.renderProgress.items[rix], 'refreshing', refreshing);
          break;
        }
      }
    },
    async getRenderProgressData() {
      await this.renderProgress.get();

      if (
        this.$route.query.batchHashId &&
        this.renderProgress &&
        this.renderProgress.items &&
        this.renderProgress.items.length > 0
      ) {
        // find the item with this batch hash, and expand it
        const batchHashId = this.$route.query.batchHashId.toLowerCase();
        let itemWithHash = this.renderProgress.items.filter(x => x);

        let rix = 0;
        for (rix in this.renderProgress.items) {
          if (this.renderProgress.items[rix].batchHashId === batchHashId) {
            itemWithHash = this.renderProgress.items[rix];
            break;
          }
        }

        this.expanded = [itemWithHash];

        if (!itemWithHash.renderProgresses || itemWithHash.renderProgresses.length === 0) {
          await this.setupRenderStart(itemWithHash, false);
        }
      }
    },
    async setupRenderStart(item, isReRender) {
      // set up websocket to listen for render_label_start
      const adalToken = await this.adalHelper.getJwtToken(this.$authApi);

      const onClose = () => {
        // remove the connection
        delete this.renderStartWsConn[item.batchHashId];
      };

      this.renderStartWsConn[item.batchHashId] = new PogonaWebSocket(
        this.$apiBasePath,
        'render_start',
        item.batchHashId,
        adalToken,
        onClose,
        event => {
          // once render starts, just refresh row
          this.refreshRenderingsProgress(item, true);
          // close the connection
          this.renderStartWsConn[item.batchHashId].close();
        },
        86400000, // give it a day
      );

      // do another refresh just to see if we missed the render start message
      this.refreshRenderingsProgress(item, false);

      const itemWithHashUpdate = this.renderProgress.items.filter(
        x => x.batchHashId === item.batchHashId,
      )[0];
      if (
        itemWithHashUpdate.renderProgresses &&
        itemWithHashUpdate.renderProgresses.length > 0 &&
        isReRender === false
      ) {
        this.setRefreshingForProgress(item.batchHashId, false);
        this.renderStartWsConn[item.batchHashId].close();
      }
    },
    async reRender(item) {
      try {
        this.reRendering = true;

        const renderings = await this.getRenderProgressRow(item.batchHashId);
        const renderData = JSON.parse(renderings.data.renderDataJson);

        // setup new re-render
        renderData.force = true;
        renderData.svgTemplateTypes = [item.svgTemplateId];

        // clear current render in database
        await this.$authApi.http.put(`label/renderprogress/${item.renderId}`);

        // refresh line
        await this.refreshRenderingsProgress(item, false);

        await this.setupRenderStart(item, true);

        // now send the re-render request to service bus
        await this.$authApi.http.post('servicebus/renderlabels', renderData);

        item.progress = 0;

        this.reRendering = false;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to re-render',
          err,
          id: '6b10d99a-c4d4-4d4d-86fc-7d2728f0112f',
        });
        this.reRendering = false;
      }
    },
    formattedErrorMessage(item) {
      if (item.errorMessage) {
        return item.errorMessage;
      } else if (item.progress >= 100) {
        return 'Success';
      }
      return '';
    },
    async initLabelItemsTable() {
      this.renderProgress = new PogonaDataTable({
        headers: [
          {
            text: 'From',
            value: 'originatingPage',
          },
          {
            text: 'Username',
            value: 'username',
          },
          {
            text: 'BeginDate',
            value: 'beginDate',
          },
          {
            text: 'EndDate',
            value: 'endDate',
          },
          {
            text: 'Created At',
            value: 'timeInserted',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ],
        keys: ['batchHashId'],
        defaultAllSelected: false,
        baseUrl: `label/renderingsprogress/${this.selectedUsername}`,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['timeInserted'], sortDesc: [true] },
        isInfinite: true,
        onPageFilter: () => this.renderProgressAdditionalFilter,
      });

      this.renderProgress.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting render progress',
          err,
          id: 'f1a7effb-4ab2-4d6f-a9f5-b8ba236a3f5b',
        });
      });

      this.renderProgress.on('dataBound', () => {
        // add "refreshing" data to bound data items
        for (const ix in this.renderProgress.items) {
          let refreshing = false;

          // if there are rows with progresses below 100, setup last update poll
          if (this.renderProgress.items[ix].renderProgresses.length > 0) {
            for (const rp of this.renderProgress.items[ix].renderProgresses) {
              if (rp.progress < 100) {
                this.lastUpdate[rp.renderId] = {
                  time: new Date().getTime(),
                  progress: rp.progress,
                };
              }
            }
          }

          // if the batch hash was specified, and there are zero renderProgresses
          // then we'll start a polling refresh, so set this to true right away
          refreshing =
            this.$route.query.batchHashId &&
            this.$route.query.batchHashId.toLowerCase() ===
              this.renderProgress.items[ix].batchHashId &&
            this.renderProgress.items[ix].renderProgresses.length === 0;

          Vue.set(
            this.renderProgress.items,
            ix,
            Object.assign(this.renderProgress.items[ix], { refreshing }),
          );
        }
      });

      await this.getRenderProgressData();
    },
  },
  watch: {
    printDialogActive: {
      handler: function () {
        this.setPdfSrc(this.selectedItem);
      },
    },
  },
};
</script>
