<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title class="text--black">Text Area</v-card-title>
          <v-card-text>
            <data-paste-from-excel
              v-model="excelData"
              :typePrototype="typePrototype"
              :errorsLoading="processing"
              @imageBlob="imageBlob"
              @noClipboardData="
                $emit('showSnackbarMessage', { text: $event.text, color: 'warning' })
              "
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="$emit('submitClicked')"
              :loading="processing"
              :disabled="processing || multiSaveDisabled"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, ModelSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ItemUpdateExcelData from '@/store/modules/vendorportal/ItemUpdateExcelData';
import { Manufacturer, Brand, Vendor, UnitOfMeasure } from '@/utils/VendorDomainTypes';

const vpModule = namespace('vendorportal');

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({})
export default class ItemUpdatePasteFromExcel extends Vue {
  @ModelSync('value', 'textChange', { type: Array })
  private excelData!: Array<ItemUpdateExcelData>;

  @Prop({ required: true, default: false })
  readonly processing!: boolean;

  @Prop({ required: true, default: false })
  readonly multiSaveDisabled!: boolean;

  @Prop({ required: true })
  readonly id!: string;

  @Prop({ required: true })
  readonly unitOfMeasures!: UnitOfMeasure[];

  @vpModule.Action('generateAggregateId') generateAggregateId!: () => Promise<string>;
  @vpModule.Action('uploadImageFromByteArray') uploadImageFromByteArray!: (
    data: any,
  ) => Promise<string>;

  private typePrototype: ItemUpdateExcelData | null = null;

  @Watch('excelData', { deep: true }) excelDataChanged(newVal: Array<ItemUpdateExcelData>) {
    this.$emit('input', newVal);
  }

  mounted() {
    this.typePrototype = new ItemUpdateExcelData(this.unitOfMeasures);
  }

  public async validateMultiInput(isNew: boolean, defaultStatus: string, schema: any) {
    let allAreValid = true;
    const requestData = [] as any[];

    const multiId = await this.generateAggregateId();

    let lineIx = 0;
    for (const line of this.excelData) {
      lineIx++;
      line.clearErrors();

      const validatedState = await this.createStateFromMultiLineInput(
        line,
        multiId,
        isNew,
        defaultStatus,
        false,
      );
      requestData.push(validatedState);
      try {
        await schema.validate(validatedState);
      } catch (validationError: any) {
        line.pushError(`Row ${lineIx} has the following error: ${validationError.errors}`);
        allAreValid = false;
      }
    }

    return { allAreValid, requestData };
  }

  private async createStateFromMultiLineInput(
    line: ItemUpdateExcelData,
    multiId: string,
    isNew: boolean,
    defaultStatus: string,
    privateLabel: boolean,
  ) {
    return {
      isNew: isNew,
      id: multiId,
      status: defaultStatus,
      requestType: 'Package Change',
      privateLabel: privateLabel,
      fullUnitBarcode: line.fullUnitBarcode,
      caseBarcode: line.caseBarcode ?? '',
      otherBarcode: '',
      productName: line.productName,
      retailPackageSize: line.retailPackageSize ?? '',
      retailPackageUOM: line.retailPackageUOM ?? '',
      manufacturerAccountNumber: '',
      brand: 0,
      vendorId: '',
      externalItemNumber: line.externalItemNumber ?? '',
      masterCase: line.masterCase ?? 0,
      innerCasePack: line.innerCasePack ?? '',
      layerQuantity: '',
      palletQuantity: line.palletQuantity ?? '',
      directToStore: '',
      directToStoreOrVendorIntoPspdPurchaseCost: 0,
      mfgSuggestedRetail: 0,
      mapPrice: 0,
      imap: 0,
      autoShipEligible: false,
      initialDiscountFundingPercent: 0,
      onGoingFundingDiscountPercent: 0,
      materialSafetyDataSheetNeeded: false,
      minimumOrderQuantity: 0,
      unitWeight: line.unitWeight ?? 0,
      unitHeight: 0,
      unitWidth: 0,
      unitDepth: 0,
      caseHeight: 0,
      caseWidth: 0,
      caseDepth: 0,
      productAttribute: '',
      images: [],
      programName: '',
      htsCode: '',
      dutyRate: '',
      fobPort: '',
      pluScan: '',
      pluAssigned: '',
      daxItemNumber: '',
      signsAndLabelsDescription1: '',
      signsAndLabelsDescription2: '',
      vendorNumber: '',
      shipsToStores: '',
      shipUnitCostFromPspd: 0,
      retailBasePrice: 0,
      retailHighPrice: 0,
      hierarchy: '',
      categoryManagerName: '',
      pspdInventoryManagerName: '',
      reason: '',
    };
  }
}
</script>
