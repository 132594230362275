<template>
  <core-view-template title="Coupon Image Upload">
    <core-view-section title="Upload">
      <v-row>
        <v-col cols="12" xl="8" lg="8" md="8">
          <v-file-input
            multiple
            prepend-icon="image"
            label="Click Here to Add Coupon Image(s) (jpeg, jpg, or png)"
            v-model="localFiles"
            accept=".jpg,.jpeg,.png"
          ></v-file-input>
          <span class="ml-2 text-caption text--black">Each image file must be less than 2 mb</span>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4">
          <v-btn @click="onUpload" :disabled="disabledUpload" :loading="uploadInProgress"
            >Upload{{ numberOfUploadsText }}</v-btn
          >
          <v-btn @click="onClearCouponImages" :disabled="disabledClear">Clear</v-btn>
        </v-col>
        <v-row class="mb-2">
          <v-col cols="12">
            <coupon-image-upload-row
              v-for="(image, ix) in couponImages"
              :key="ix"
              :couponImage="image"
              @remove="onRemove"
              class="ml-3"
            />
          </v-col>
        </v-row>
      </v-row>
    </core-view-section>
    <core-view-section title="Coupons">
      <v-row class="mt-1">
        <v-text-field
          label="Search by coupon name, or PLU."
          v-model="searchText"
          clearable
          @click:clear="reset"
          v-debounce:300="reset"
          prepend-icon="search"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="dwCoupons"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="dwCouponsGetInProgress"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="dwCouponsCount"
            class="elevation-1 scrollable"
            hide-default-footer
            item-key="Id"
            @update:options="updateSortBy"
            height="100%"
          >
            <template v-slot:item.StartDate="{ value }">
              {{ value | formatDateTime }}
            </template>

            <template v-slot:item.EndDate="{ value }">
              {{ value | formatDateTime }}
            </template>
            <template v-slot:item.ImagePath="{ value }">
              <coupon-image-dialog :couponImage="value" />
            </template>
            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="dwCoupons && dwCoupons.length > 0"
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ><span class="text--black">{{ dwCoupons.length }} / {{ dwCouponsCount }} </span></v-col
        >
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script setup lang="ts">
import { computed, ref, defineComponent, watch, onMounted } from 'vue';
import CouponImageUploadRow from '@/components/ecom/CouponImageUploadRow.vue';
import CouponImageDialog from '@/components/ecom/CouponImageDialog.vue';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';
import { useCouponimageuploadstore, ImageUploadState } from '@/stores/couponimageuploadstore';

defineComponent({
  name: 'CouponImageUpload',
  components: {
    CouponImageUploadRow,
    CouponImageDialog,
  },
});

const couponimageuploadstore = useCouponimageuploadstore();

const localFiles = ref<File[]>([]);
const options = ref<any>({});
const searchText = ref<string | null>(null);

let isMounted = false;
let dwCouponsOData: ODataProps = {} as ODataProps;
let infiniteId = 0;

const headers: any = ref<any>([
  { value: 'Id', text: 'ID' },
  { value: 'DwcouponId', text: 'PLU' },
  { value: 'ReferenceKey', text: 'Reference Key' },
  { value: 'CouponName', text: 'Coupon Name' },
  { value: 'StartDate', text: 'From' },
  { value: 'EndDate', text: 'To' },
  { value: 'ImagePath', text: 'Image' },
]);

const defaultSort = { sortBy: ['Id'], sortDesc: [true] };

const uploadInProgress = computed(() => {
  return couponimageuploadstore.uploadInProgress;
});

const disabledClear = computed(() => {
  return (
    couponimageuploadstore.uploadInProgress || !dwCoupons.value || dwCoupons.value.length === 0
  );
});

const dwCoupons = computed(() => {
  return couponimageuploadstore.dwCoupons;
});

const dwCouponsCount = computed(() => {
  return couponimageuploadstore.dwCouponsCount;
});

const dwCouponsGetInProgress = computed(() => {
  return couponimageuploadstore.dwCouponsGetInProgress;
});

const couponImages = computed(() => {
  return couponimageuploadstore.couponImages;
});

const disabledUpload = computed(() => {
  let allComplete = true;
  if (couponimageuploadstore.couponImages && couponimageuploadstore.couponImages.length > 0) {
    for (const coupon of couponimageuploadstore.couponImages) {
      if (
        coupon.uploadState !== ImageUploadState.complete &&
        coupon.uploadState !== ImageUploadState.tooLarge
      ) {
        allComplete = false;
        break;
      }
    }
  }

  return disabledClear.value || allComplete === true;
});

const numberOfUploadsText = computed(() => {
  if (numberOfUploads.value > 0) {
    return ` (${numberOfUploads.value})`;
  } else {
    return '';
  }
});

const numberOfUploads = computed(() => {
  if (couponimageuploadstore.couponImages && couponimageuploadstore.couponImages.length > 0) {
    return couponimageuploadstore.couponImages.filter(
      x =>
        x.uploadState !== ImageUploadState.complete && x.uploadState !== ImageUploadState.tooLarge,
    ).length;
  }
  return 0;
});

watch(
  () => localFiles.value,
  () => {
    couponimageuploadstore.appendCouponImages(localFiles.value);
  },
);

watch(
  () => dwCoupons.value,
  () => {
    // clear the input box, as these are persisted in state
    localFiles.value = [];
  },
);

function onClearCouponImages(): void {
  couponimageuploadstore.couponImages = [];
  localFiles.value = [];
}
function onRemove(coupon): void {
  couponimageuploadstore.removeCouponImage(coupon);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function onUpload(): Promise<any> {
  await couponimageuploadstore.uploadCouponImages();
}

onMounted(() => {
  setODataOrderBy();
  isMounted = true;
});

async function updateSortBy() {
  if (isMounted == false) {
    setODataOrderBy();
  }

  if (options.value.sortBy.length === 1) {
    dwCouponsOData.orderBy = [
      `${options.value.sortBy[0]} ${options.value.sortDesc[0] === true ? 'desc' : 'asc'}`,
    ];
  } else {
    dwCouponsOData.orderBy = ['Id desc'];
  }

  dwCouponsOData.top = 50;
  dwCouponsOData.skip = 0;

  infiniteId += 1;

  if (isMounted == true) {
    await getCouponData();
  }
}

function getCouponData() {
  return Promise.all([
    couponimageuploadstore.getDwCoupons(dwCouponsOData),
    couponimageuploadstore.getDwCouponsCount(dwCouponsOData),
  ]);
}

function setODataOrderBy() {
  if (dwCouponsOData.orderBy && dwCouponsOData.orderBy.length === 1) {
    const splitOrder = dwCouponsOData.orderBy[0].split(' ');
    options.value = { sortBy: [splitOrder[0]], sortDesc: [splitOrder[1] === 'desc'] };
  } else {
    options.value = Object.assign({}, defaultSort);
  }
}

function infiniteHandler($state: any) {
  couponimageuploadstore
    .getDwCoupons(dwCouponsOData)
    .then(() => {
      if (dwCoupons.value.length < dwCouponsCount.value) {
        $state.loaded();
        incrementSkip();
      } else {
        $state.complete();
      }
    })
    .catch(() => {
      $state.complete();
    });
}

function reset(doNotSetOrderBy = false) {
  setTimeout(async () => {
    infiniteId += 1;

    const dwCouponsODataLocal = new ODataProps();
    dwCouponsODataLocal.top = 50;
    dwCouponsODataLocal.skip = 0;

    if (doNotSetOrderBy !== true) {
      dwCouponsODataLocal.orderBy = [
        `${defaultSort.sortBy[0]} ${defaultSort.sortDesc[0] === true ? 'desc' : 'asc'}, Id asc`,
      ];
    } else {
      dwCouponsODataLocal.orderBy = dwCouponsOData.orderBy;
    }

    // clear the grid data
    couponimageuploadstore.dwCoupons.value = [];

    const trimmedSearch = searchText.value?.trim() ?? null;

    if (trimmedSearch && trimmedSearch.length > 0) {
      dwCouponsODataLocal.filter = f('or')
        .contains("'CouponName'", trimmedSearch)
        .contains("'DwcouponId'", trimmedSearch)
        .toString();
    } else {
      dwCouponsODataLocal.filter = null;
    }

    dwCouponsOData = dwCouponsODataLocal;

    await getCouponData();
    incrementSkip();
  }, 0);
}

function incrementSkip() {
  dwCouponsOData.skip += dwCouponsOData.top;
}
</script>
