<template>
  <core-view-template :title="title">
    <ShowImpersonation />
    <CustomerSelection :custAcct="selectedCustomer" @customerSelected="customerSelected" />
    <v-card outlined>
      <v-card-text
        >Customer Balance:
        {{ formatters.formatCurrency(balance.totalAmount - balance.totalSettlement) }}</v-card-text
      >
    </v-card>
    <CustTransGrid
      :headers="headers"
      :custTrans="vpFinanceStore.custTransResult"
      :loading="loading"
      :hideDefaultFooter="true"
      :page="1"
      class="mt-3"
    />
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import CustTransGrid from '@/components/vendorportal/CustTransGrid.vue';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import CustomerSelection from '@/components/vendorportal/CustomerSelection.vue';
import { useFormatters } from '@/composables/formatters';
import { useTransGridShared } from '@/composables/TransGridShared';
defineComponent({
  name: 'CustBalance',
});
const gridShared = useTransGridShared();
const headers = [
  gridShared.dataAreaIdHeader,
  gridShared.vendAcctHeader,
  gridShared.invoiceHeader,
  gridShared.txtHeader,
  gridShared.documentDateHeader,
  gridShared.amountCurHeader,
  gridShared.balanceHeader,
  gridShared.dueDateHeader,
  gridShared.paymTermIdHeader,
  gridShared.paymModeHeader,
];
const formatters = useFormatters();
const title = ref('Customer Balance');
const vpFinanceStore = useVpFinanceStore();
const loading = ref(false);
const selectedCustomer = ref('');
function loadCustBalance(account: string) {
  if (account) {
    loading.value = true;
    vpFinanceStore.getCustBalance(account).finally(() => {
      loading.value = false;
    });
  }
}
function customerSelected(customer: string) {
  selectedCustomer.value = customer;
}
watch(selectedCustomer, newVal => {
  if (newVal) {
    loadCustBalance(newVal);
  }
});
const balance = computed(() => {
  return vpFinanceStore.custTransResult.reduce(
    (acc: { totalAmount: number; totalSettlement: number }, item) => ({
      totalAmount: acc.totalAmount + Number(item.AmountCur),
      totalSettlement: acc.totalSettlement + Number(item.SettleAmountCur),
    }),
    { totalAmount: 0, totalSettlement: 0 },
  );
});
watch(
  () => vpFinanceStore.custSearchResultWithRestriction,
  newVal => {
    if (newVal?.length === 1) {
      selectedCustomer.value = newVal[0].CustomerAccount;
    }
  },
);
</script>
