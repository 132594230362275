<template>
  <v-container>
    <v-row>
      <v-card outlined class="mb-5" :loading="loadingProcessData">
        <v-card-title class="green">
          <span
            >Autoship Partner Funding - Upload File
            {{ current == null ? '' : current.FileName }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <span>File Uploaded:</span>
              <span class="ml-1">{{ current == null ? '' : current.FileName }}</span>
            </v-row>
            <v-row>
              <span>Uploaded ID:</span>
              <span class="ml-1">{{ current == null ? '' : current.CorrelationId }}</span>
            </v-row>
            <v-row>
              <span>Rows Processing:</span>
              <span class="ml-1">{{ current == null ? '' : current.Processing }}</span>
            </v-row>
            <v-row>
              <span>Rows Processed:</span>
              <span class="ml-1">{{ current == null ? '' : current.Processed }}</span>
            </v-row>
            <v-row>
              <span>Number of Errors:</span>
              <span class="ml-1">{{ current == null ? '' : current.Errors.length }}</span>
            </v-row>
            <v-row>
              <span class="mt-2">Errors:</span>
            </v-row>
            <v-row>
              <v-data-table :loading="loadingProcessData" :headers="errorHeaders" :items="errors">
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="doneClicked">Done</v-btn>
          <v-btn @click="exportErrorsClicked" :loading="exportingErrors">Export Errors</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-row>
      <v-card>
        <v-card-title class="green">Partner Funding Uploads</v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loadingProcessData"
            :headers="processingHeaders"
            :items="processingData"
            show-select
            single-select
            item-key="CorrelationId"
            v-model="selected"
          >
            <template v-slot:item.Started="{ item }">
              {{ formatGridDate(item.Started) }}
            </template>
            <template v-slot:item.Ended="{ item }">
              {{ formatGridDate(item.Ended) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions> <v-btn @click="doneClicked">Done</v-btn></v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pfa from '@/store/modules/partnerfunding/actions';
import * as pfs from '@/store/modules/partnerfunding/state';
import * as pfm from '@/store/modules/partnerfunding/mutations';
import { FileUploadProcessingStateInfo } from '@psp/pogona_ecom_client_js';
import dateFormat from 'dateformat';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pfModule = namespace('partnerfunding');

@Component({})
export default class PartnerFundingImportProcess extends Vue {
  @pfModule.State(pfs.processingData) processingData!: FileUploadProcessingStateInfo[];
  @pfModule.State(pfs.showProcessing) showProcessing!: boolean;
  @pfModule.Mutation(pfm.setShowProcessing) setShowProcessing!: (payload: boolean) => void;
  @pfModule.Mutation(pfm.setProcessingData) setProcessingData!: (
    payload: FileUploadProcessingStateInfo[],
  ) => void;
  @pfModule.Action(pfa.getPartnerFundingImportProcesses)
  getPartnerFundingImportProcesses!: () => Promise<any>;
  @pfModule.Action(pfa.exportPartnerFundingErrors)
  exportPartnerFundingErrors!: (correlationId: string) => Promise<any>;

  private loadingProcessData: boolean | string = false;
  private timeout: any;
  private selected = [] as any[];
  private current: FileUploadProcessingStateInfo | null = null;
  private errors = [] as any[];
  private exportingErrors = false;

  @Watch('current', { deep: true }) currentChanged(newVal: any[]) {
    if (newVal !== null) {
      if (this.current !== null && this.current?.Errors && this.current.Errors?.length > 0) {
        this.errors = [];
        this.current.Errors.forEach(e => {
          const parsedError = JSON.parse(e);
          this.errors.push({
            ...parsedError.Row,
            ErrorMessage: parsedError.ErrorMessage,
          });
        });
      } else {
        this.errors = [];
      }
    } else {
      this.errors = [];
    }
  }

  @Watch('selected') selectedChanged(curr) {
    if (curr) {
      var index = this.processingData.findIndex(
        x => x.CorrelationId === this.selected[0].CorrelationId,
      );
      if (index > -1) {
        this.current = this.processingData[index];
      }
    } else {
      this.current = null;
    }
  }

  private created() {
    clearTimeout(this.timeout);
    this.getProcessingDataLoop();
  }

  private destroyed() {
    this.setProcessingData([]);
    clearTimeout(this.timeout);
  }

  private exportErrorsClicked() {
    if (this.current) {
      this.exportingErrors = true;
      this.exportPartnerFundingErrors(this.current.CorrelationId).finally(
        () => (this.exportingErrors = false),
      );
    }
  }

  private formatGridDate(d) {
    let format = '';
    if (d) {
      format = dateFormat(d, 'mm/dd/yyyy HH:MM:ss');
    }
    return format;
  }

  private getProcessingDataLoop() {
    this.loadingProcessData = 'light-green';
    clearTimeout(this.timeout);
    var currentId = this.processingData.length > 0 ? this.processingData[0].CorrelationId : '';
    this.getPartnerFundingImportProcesses()
      .then(() => {
        if (this.showProcessing) {
          if (
            this.processingData.length > 0 &&
            (this.selected.length === 0 || this.processingData[0].CorrelationId !== currentId)
          ) {
            this.selected = [];
            this.selected.push(this.processingData[0]);
          }
          var index = this.processingData.findIndex(
            x => x.CorrelationId === this.selected[0].CorrelationId,
          );
          if (index > -1) {
            this.current = this.processingData[index];
          } else {
            this.current = null;
          }
          this.timeout = setTimeout(() => this.getProcessingDataLoop(), 8000);
        }
      })
      .finally(() => (this.loadingProcessData = false));
  }

  private doneClicked() {
    this.setProcessingData([]);
    clearTimeout(this.timeout);
    this.setShowProcessing(false);
  }

  private processingHeaders = [
    { text: 'File', value: 'FileName' },
    { text: 'ID', value: 'CorrelationId' },
    { text: 'Started', value: 'Started' },
    { text: 'Ended', value: 'Ended' },
    { text: 'Processing', value: 'Processing' },
    { text: 'Processed', value: 'Processed' },
  ];

  private errorHeaders = [
    { text: 'ItemID', value: 'ItemId' },
    { text: 'SKU', value: 'Sku' },
    { text: 'Initial Percent Funded', value: 'InitialPercentFunded' },
    { text: 'On Going Percent Funded', value: 'OnGoingPercentFunded' },
    { text: 'ERROR', value: 'ErrorMessage' },
  ];
}
</script>
