<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="dialogClick"
          @click.stop=""
          v-bind:small="small"
          style="
            color: white;
            border-width: 1px;
            border-style: solid;
            border-color: white;
            background-color: #4caf50 !important;
          "
        >
          {{ buttonText }}
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Manage District</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedDistrictId"
                  label="Select District"
                  :items="allDistricts"
                  item-text="Name"
                  item-value="DistrictId"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Division/Region"
                  v-model="currentDistrict.DivisionRegionId"
                  :items="divisionRegionsNoRelated"
                  item-text="Name"
                  item-value="DivisionRegionId"
                  :error-messages="districtErrorMessages.DivisionRegionId"
                  @blur="validateDistrict('DivisionRegionId')"
                  @change="validateDistrict('DivisionRegionId')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="currentDistrict.Name"
                  label="District Name"
                  :error-messages="districtErrorMessages.Name"
                  @blur="validateDistrict('Name')"
                  @change="validateDistrict('Name')"
                  counter="50"
                  maxlength="50"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="districtSelections.selectedDistrictTeamLeaderId"
                  :items="employeeDropDownData"
                  label="District Team Leader"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="districtSelections.selectedFieldTrainers"
                  :items="employeeDropDownData"
                  label="Field Trainers"
                  item-text="name"
                  item-value="value"
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="districtSelections.selectedSSTLIds"
                  :items="employeeDropDownData"
                  label="SSTL"
                  item-text="name"
                  item-value="value"
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="districtSelections.selectCDLIds"
                  :items="employeeDropDownData"
                  label="CDL"
                  item-text="name"
                  item-value="value"
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-dialog
            v-if="!deleteEnabled && selectedDistrictId !== 0"
            v-model="storesDialog"
            max-width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-1"
                text
                v-bind="attrs"
                v-on="on"
                @click="storesDialog = true"
                >Show Stores</v-btn
              >
            </template>
            <v-card>
              <v-card-title>Stores in District {{ currentDistrict.Name }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row v-for="s in currentDistrict.Stores" :key="s.StoreId">
                    {{ s.StoreId }} - {{ s.Name }}
                  </v-row>
                </v-container>
                <v-card
                  v-for="m in currentDistrict.Markets"
                  :key="m.MarketId"
                  :v-if="m.Stores.length > 0"
                >
                  <v-card-title> Stores in Market {{ m.Name }} </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row v-for="s in m.Stores" :key="s.StoreId">
                        {{ s.StoreId }} - {{ s.Name }}
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="storesDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="blue darken-1" text @click="showConfirmDelete = true" v-if="deleteEnabled"
            >Delete</v-btn
          >
          <v-btn color="blue darken-1" text @click="closeClicked">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveClicked" :loading="loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmDelete" max-width="400px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete district {{ currentDistrict.Name }}</v-card-title
        >
        <v-card-actions>
          <v-btn @click="showConfirmDelete = false">No</v-btn>
          <v-btn @click="deleteConfirmed">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <p v-for="e in validationErrors" v-bind:key="e">
        {{ e }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pcldto from '@psp/pcldto';
import * as pclclient from '@psp/pogona_pcl_client_js';
import * as yup from 'yup';
import { mixins } from 'vue-class-component';
import DivisionRegionEditMixin from './DivisionRegionEditMixin';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */

const pclModule = namespace('pcl');

@Component({
  components: {},
})
export default class ManageDistrictPopup extends mixins(DivisionRegionEditMixin) {
  @Prop({ default: 0 }) districtId!: number;
  @Prop({ default: 0 }) divisionRegionId!: number;
  @Prop({ default: null }) small!: string | null;
  @Prop({ default: 'Edit District' }) buttonText!: string;

  @pclModule.Action(pa.getDistricts) getDistricts!: () => Promise<unknown>;
  @pclModule.Action(pa.getDivisionRegionsNoRelated) getDivisionRegionsNoRelated!: () => Promise<
    pcldto.pclDivisionRegion[]
  >;
  @pclModule.Action(pa.getDistrictResults) getDistrictResults!: (odata: ODataProps) => Promise<any>;
  @pclModule.State(ps.districtData) allDistricts!: pclclient.DistrictResult[];
  @pclModule.State(ps.divisionRegionsNoRelated)
  divisionRegionsNoRelated!: pcldto.pclDivisionRegion[];
  @pclModule.State(ps.districtResults) districtResults!: pcldto.pclDistrict[];

  private originalDistrict: pcldto.pclDivisionRegionWithRelatedTypes.District | null = null;
  private duplicateName = false;
  private dialog = false;
  private storesDialog = false;
  private showConfirmDelete = false;
  private confirmDelete = false;
  private selectedDistrictId = 0;
  private districtErrorMessages: ps.PclDistrictMessages = new ps.PclDistrictMessages();
  private currentDistrict: pcldto.pclDivisionRegionWithRelatedTypes.District = new pcldto.pclDivisionRegionWithRelatedTypes.District(
    [],
    0,
    null,
    0,
    [],
    [],
    '',
    [],
    [],
  );
  private districtSelections: ps.DistrictSelections = new ps.DistrictSelections(
    0,
    0,
    [],
    [],
    [],
    [],
  );

  get deleteEnabled(): boolean {
    return (
      this.selectedDistrictId !== 0 &&
      this.currentDistrict.Stores.length === 0 &&
      this.currentDistrict.Markets.length === 0
    );
  }

  private districtSchema: any = yup.object().shape({
    Name: yup.string().required().test('unique', this.testNameUnique),
    DivisionRegionId: yup.number().required().moreThan(0, 'Division/Region must be selected'),
  });

  public async testNameUnique(
    value: string | undefined,
    context: yup.TestContext<Record<string, any>>,
  ): Promise<boolean | yup.ValidationError> {
    const check = this.originalDistrict?.DistrictId === 0 || value !== this.originalDistrict?.Name;
    if (check) {
      let odata = new ODataProps();
      odata.filter = f().eq('Name', value).toString();
      await this.getDistrictResults(odata);
    }
    return !check || this.$store.state.pcl.districtResults.length === 0
      ? true
      : context.createError({ message: 'The district name already exists' });
  }

  private closeDialog() {
    this.selectedDistrictId = 0;
    this.currentDistrict = new pcldto.pclDivisionRegionWithRelatedTypes.District(
      [],
      0,
      null,
      0,
      [],
      [],
      '',
      [],
      [],
    );
    this.deleteEntities = [];
    this.dialog = false;
  }

  private closeClicked() {
    this.closeDialog();
  }

  private dialogClick() {
    this.loading = true;
    this.getEmployees().then(() => (this.loading = false));
  }

  private processDistrict(): boolean {
    let success = true;
    if (
      this.currentDistrict.DistrictId !== 0 &&
      this.originalDistrict?.DivisionRegionId === this.dr.DivisionRegionId
    ) {
      let district = this.dr.Districts.find(d => d.DistrictId === this.currentDistrict.DistrictId);
      if (district) {
        district = this.currentDistrict;
      } else {
        this.showSnackbarMessage(
          `Update district not found in Division/Region DistrictId: ${this.currentDistrict.DistrictId} DivRegId: ${this.dr.DivisionRegionId}`,
          'error',
        );
        success = false;
      }
    } else {
      let divisionRegionIdForSave = this.currentDistrict.DivisionRegionId;
      if (
        this.originalDistrict &&
        this.originalDistrict.DivisionRegionId !== 0 &&
        this.originalDistrict.DivisionRegionId !== this.dr.DivisionRegionId
      ) {
        divisionRegionIdForSave = this.originalDistrict.DivisionRegionId;
      }
      this.dr.Districts.push({
        ...this.currentDistrict,
        DivisionRegionId: divisionRegionIdForSave,
      });
    }
    return success;
  }

  private saveClicked() {
    this.districtSchema
      .validate(this.currentDistrict)
      .then(() => {
        this.mapUiToDistrict();
        let save = true;
        if (this.deleteEntities.length === 0) {
          save = this.processDistrict();
        }
        if (save) {
          this.save('District')
            .then(() => {
              this.$emit('save-complete');
              this.closeDialog();
            })
            .finally(() => (this.loading = false));
        }
      })
      .catch(err => {
        this.districtErrorMessages[err.path] = err.errors;
      });
  }

  private deleteConfirmed() {
    this.showConfirmDelete = false;
    this.removeDistrict(this.currentDistrict);
    this.saveClicked();
  }

  mapUiToDistrict() {
    if (
      this.districtSelections.selectedDistrictTeamLeaderId !==
      this.currentDistrict?.DistrictTeamLeaderRole?.EmployeeId
    ) {
      if (this.districtSelections.selectedDistrictTeamLeaderId === 0) {
        this.currentDistrict.DistrictTeamLeaderRole = null;
      } else {
        if (
          this.districtSelections.selectedDistrictTeamLeaderId !==
          this.currentDistrict.DistrictTeamLeaderRole?.EmployeeId
        ) {
          this.currentDistrict.DistrictTeamLeaderRole = new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.DistrictTeamLeaderRolepclDistrictTeamLeaderRole(
            this.districtSelections.districtId,
            null,
            this.districtSelections.selectedDistrictTeamLeaderId,
            0,
            pclclient.RoleName.DistrictTeamLeader,
          );
        }
      }
    }

    this.currentDistrict.FieldTrainerRoles = this.districtSelections.selectedFieldTrainers.map(
      ft => {
        const index = this.currentDistrict.FieldTrainerRoles.findIndex(
          dft => dft.EmployeeId === ft,
        );
        return index === -1
          ? new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.FieldTrainerRolepclFieldTrainerRole(
              this.districtSelections.districtId,
              null,
              ft,
              0,
              pclclient.RoleName.FieldTrainer,
            )
          : this.currentDistrict.FieldTrainerRoles[index];
      },
    );

    this.currentDistrict.SeniorStoreTeamLeaderRoles = this.districtSelections.selectedSSTLIds.map(
      ss => {
        const index = this.currentDistrict.SeniorStoreTeamLeaderRoles.findIndex(
          dss => dss.EmployeeId == ss,
        );
        return index === -1
          ? new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.SeniorStoreTeamLeaderRolepclSeniorStoreTeamLeaderRole(
              this.districtSelections.districtId,
              null,
              ss,
              0,
              pclclient.RoleName.SeniorStoreTeamLeader,
            )
          : this.currentDistrict.SeniorStoreTeamLeaderRoles[index];
      },
    );

    this.currentDistrict.CritterDistrictLeadRoles = this.districtSelections.selectCDLIds.map(
      cdl => {
        const index = this.currentDistrict.CritterDistrictLeadRoles.findIndex(
          dc => dc.EmployeeId == cdl,
        );
        return index === -1
          ? new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.CritterDistrictLeadRolepclCritterDistrictLeadRole(
              this.districtSelections.districtId,
              null,
              cdl,
              0,
              pclclient.RoleName.CritterDistrictLead,
            )
          : this.currentDistrict.CritterDistrictLeadRoles[index];
      },
    );
  }

  mapDistrictToUi() {
    this.districtSelections.districtId = this.currentDistrict.DistrictId;
    this.districtSelections.selectedDistrictTeamLeaderId =
      this.currentDistrict.DistrictTeamLeaderRole === null
        ? 0
        : this.currentDistrict.DistrictTeamLeaderRole.EmployeeId;
    this.districtSelections.selectedFieldTrainers = this.currentDistrict.FieldTrainerRoles.map(
      r => r.EmployeeId,
    );
    this.districtSelections.selectedSSTLIds = this.currentDistrict.SeniorStoreTeamLeaderRoles.map(
      r => r.EmployeeId,
    );
    this.districtSelections.selectCDLIds = this.currentDistrict.CritterDistrictLeadRoles.map(
      r => r.EmployeeId,
    );
  }

  mapDrDistrictToCurrentDistrict() {
    const district = this.dr.Districts.find(d => d.DistrictId === this.selectedDistrictId);

    if (district) {
      this.currentDistrict = district;
    } else {
      this.currentDistrict = new pcldto.pclDivisionRegionWithRelatedTypes.District(
        [],
        0,
        null,
        0,
        [],
        [],
        '',
        [],
        [],
      );
    }
    this.originalDistrict = { ...this.currentDistrict };
    this.districtErrorMessages = new ps.PclDistrictMessages();
  }

  @Watch('currentDistrict.DivisionRegionId') selectedDivisionRegionIdChanged(next: number): void {
    if (this.dr.DivisionRegionId !== next) {
      this.loading = true;
      this.loadDivisionRegion(next).finally(() => {
        this.loading = false;
        //this.currentDistrict.DivisionRegionId = this.dr.DivisionRegionId;
      });
    }
  }

  @Watch('selectedDistrictId') selectedDistrictIdChanged(next: number): void {
    const district = this.allDistricts.find(d => d.DistrictId === next);
    if (district) {
      this.loadDivisionRegion(district.DivisionRegionId)
        .then(() => {
          this.mapDrDistrictToCurrentDistrict();
          this.mapDistrictToUi();
          //this.selectedDivisionRegionId = district.DivisionRegionId;
        })
        .catch(err =>
          this.showSnackbarMessage(
            `Error loading Division/Region ${district.DivisionRegionId} err: ${err}`,
            'error',
          ),
        )
        .finally(() => (this.loading = false));
    } else {
      this.showSnackbarMessage(`selectedDistrictIdChanged district not found ${next}`, 'error');
    }
  }

  @Watch('dialog') dialogChanged(next: boolean): void {
    if (next) {
      this.initialize();
    }
  }

  private validateDistrict(field: any): any {
    this.districtErrorMessages[field] = [];
    this.districtSchema
      .validateAt(field, this.currentDistrict)
      .catch(err => (this.districtErrorMessages[field] = err.errors));
  }

  private initialize() {
    this.loading = true;
    this.loadDivisionRegion(this.divisionRegionId)
      .then(() => {
        this.getDivisionRegionsNoRelated().then(() => {
          this.getDistricts().then(() => {
            this.allDistricts.unshift(new pclclient.DistrictResult(0, 0, '(Add New)'));
            this.selectedDistrictId = this.districtId;
            if (this.districtId === 0) {
              this.currentDistrict.DivisionRegionId = this.divisionRegionId;
            }
          });
        });
      })
      .finally(() => (this.loading = false));
  }
}
</script>
