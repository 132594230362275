<template>
  <core-signs-labels-view-template title="Ingredient Staging" :showStoreNumber="false">
    <core-info-blurb title="What is ingredient staging?">
      The ingredient staging page allows you to view and make changes to data imported via the data
      import page, from the "Ingredient import" type. The data here will be applied to the actual
      ingredient data the next morning.
    </core-info-blurb>
    <v-col cols="12">
      <core-view-section title="Items">
        <ingredient-print
          :admin="true"
          @snackbar-error="$emit('snackbar-error', $event)"
        ></ingredient-print>
      </core-view-section>
    </v-col>
  </core-signs-labels-view-template>
</template>

<script>
import IngredientPrint from '../IngredientPrintComponent.vue';

export default {
  components: { IngredientPrint },
  data() {
    return {};
  },
};
</script>
