<template>
  <core-view-template :title="title">
    <div>
      <ShowImpersonation />
      <v-row class="mb-2">
        <v-col cols="10">
          <POSearch></POSearch>
        </v-col>
        <v-col cols="2">
          <v-btn v-if="!loading && poFound" @click="exportToExcel">Export to Excel</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Purchase Order Header</h4>
          <POHeaderGrid
            :poHeaderRows="poRows"
            :pos="pos"
            :loading="loading"
            :hideDefaultFooter="true"
            :linkPO="false"
            :page="1"
            :showDetailCols="true"
          ></POHeaderGrid>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col>
          <h4>Purchase Order Lines</h4>
          <v-data-table
            :headers="poLineHeaders"
            :items="poLines"
            :loading="loading"
            :items-per-page="poLines.length"
            hide-default-footer
          >
            <template v-slot:item.LineDescription="{ item }">
              <span class="no-wrap">{{ item.LineDescription }}</span>
            </template>
            <template v-slot:item.PurchasePrice="{ item }">
              {{ formatters.formatCurrency(item.PurchasePrice) }}
            </template>
            <template v-slot:item.LineAmount="{ item }">
              {{ formatters.formatCurrency(item.LineAmount) }}
            </template>
            <template v-slot:item.InvoicedValue="{ item }">
              {{ formatters.formatCurrency(item.InvoicedValue) }}
            </template>
            <template v-slot:item.PurchaseOrderLineStatus="{ item }">
              <span class="no-wrap">{{
                financeMapper.mapPOStatus(item.PurchaseOrderLineStatus)
              }}</span>
            </template>
            <template v-slot:item.ExternalItemNumber="{ item }">
              <span class="no-wrap">{{ item.ExternalItemNumber }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="chargesPresent" class="mt-5">
        <v-col>
          <h4>Charges</h4>
          <p v-if="confirmationHeaderPresent">
            Ordered Total Value:
            {{ formatters.formatCurrency(getConfirmationHeader?.TotalChargeAmount) }}
          </p>
          <h6 v-if="chargeDetailsPresent">Received</h6>
          <v-row v-if="chargeDetailsPresent">
            <v-col cols="4">
              <v-data-table
                :headers="chargesHeaders"
                :items="chargeDetailRows"
                width="200px"
                hide-default-footer
              >
                <template v-slot:item.CalculatedAmount="{ item }">
                  {{ formatters.formatCurrency(item.CalculatedAmount) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import { useRoute } from 'vue-router/composables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import POSearch from '@/components/vendorportal/POSearch.vue';
import POHeaderGrid from '@/components/vendorportal/POHeaderGrid.vue';
import { useFormatters } from '@/composables/formatters';
import { useFinanceMapper } from '@/composables/financeComposables';
import {
  ProductReceiptLineV2Dto,
  PurchaseOrderLineV2Dto,
  VendInvoiceTransBiEntityDto,
} from '@psp/pogona_vendor_api_lib';
import { getSome, isSome } from '@/utils/FableUtils.fs.js';

defineComponent({
  name: 'PODetail',
});
const poLineHeaders = [
  { text: 'Line #', value: 'LineNumber', sortable: false },
  { text: 'PSP Item #', value: 'ItemNumber', sortable: false },
  { text: 'UPC/Barcode', value: 'Barcode', sortable: false },
  { text: 'External Item #', value: 'ExternalItemNumber', sortable: false },
  { text: 'Item Description', value: 'LineDescription', sortable: false },
  { text: 'PO#', value: 'PurchaseOrderNumber', sortable: false },
  { text: 'Line Status', value: 'PurchaseOrderLineStatus', sortable: false },
  { text: 'Ordered Qty', value: 'OrderedPurchaseQuantity', sortable: false, align: 'end' },
  { text: 'Unit of Measure', value: 'PurchaseUnitSymbol', sortable: false },
  { text: 'PO Price', value: 'PurchasePrice', sortable: false, align: 'end' },
  { text: 'Line Amount', value: 'LineAmount', sortable: false, align: 'end' },
  { text: 'Received Qty', value: 'ReceivedPurchaseQuantity', sortable: false, align: 'end' },
  { text: 'Invoiced Qty', value: 'InvoicedQty', sortable: false, align: 'end' },
  { text: 'Invoiced Value', value: 'InvoicedValue', sortable: false, align: 'end' },
];
const chargesHeaders = [
  { text: 'Code', value: 'MarkupCode', sortable: false },
  { text: 'Received Value', value: 'CalculatedAmount', sortable: false, align: 'end' },
];
const vpFinanceStore = useVpFinanceStore();
const route = useRoute();
const formatters = useFormatters();
const financeMapper = useFinanceMapper();
const loading = ref(false);
const title = computed(() => {
  return `Purchase Order Detail: ${getPONumberFromRoute()}`;
});

function getPONumberFromRoute() {
  return route.params.poNumber === '-' ? '' : decodeURIComponent(route.params.poNumber);
}

function loadPO() {
  vpFinanceStore.po = null;
  loading.value = true;
  vpFinanceStore.getPOByNumber(getPONumberFromRoute()).finally(() => {
    loading.value = false;
  });
}
function exportToExcel() {
  if (vpFinanceStore.po) {
    vpFinanceStore.getPOExcel(vpFinanceStore.po.PurchaseOrderHeader.PurchaseOrderNumber);
  }
}
const getInvoicedData = (
  poLine: PurchaseOrderLineV2Dto,
  invTrans: VendInvoiceTransBiEntityDto[],
) => {
  return invTrans.reduce(
    (acc, invTran) => {
      if (
        Number(invTran.PurchaseLineLineNumber) === poLine.LineNumber &&
        invTran.ItemId === poLine.ItemNumber
      ) {
        acc.InvoicedQty += Number(invTran.Qty);
        acc.InvoicedValue += Number(invTran.LineAmount);
      }
      return acc;
    },
    { InvoicedQty: 0, InvoicedValue: 0 },
  );
};
const getReceiptData = (
  poLine: PurchaseOrderLineV2Dto,
  receiptLines: ProductReceiptLineV2Dto[],
) => {
  return receiptLines.reduce(
    (acc, receiptLine) => {
      if (
        Number(receiptLine.PurchaseOrderLineNumber) === poLine.LineNumber &&
        receiptLine.ItemNumber === poLine.ItemNumber
      ) {
        acc.ReceivedPurchaseQuantity += Number(receiptLine.ReceivedPurchaseQuantity);
      }
      return acc;
    },
    { ReceivedPurchaseQuantity: 0 },
  );
};
const poLines = computed(() => {
  const lines = vpFinanceStore.po ? vpFinanceStore.po.PurchaseOrderLines : [];
  const invTrans = vpFinanceStore.po ? vpFinanceStore.po.VendInvoiceTransBiEntities : [];
  const receiptLines = vpFinanceStore.po ? vpFinanceStore.po.ProductReceiptLines : [];
  return lines.map(line => {
    return {
      ...line,
      ...getInvoicedData(line, invTrans),
      ...getReceiptData(line, receiptLines),
    };
  });
});
const poFound = computed(() => {
  return vpFinanceStore.po !== null;
});
const poRows = computed(() => {
  return vpFinanceStore.po ? [vpFinanceStore.po.PurchaseOrderHeader] : [];
});
const pos = computed(() => {
  return vpFinanceStore.po ? [vpFinanceStore.po] : [];
});
watch(
  () => route.params.poNumber,
  () => {
    loadPO();
  },
);
const chargesPresent = computed(() => {
  return (
    vpFinanceStore.po &&
    (isSome(vpFinanceStore.po.ConfirmationHeader) ||
      vpFinanceStore.po.MarkupTransactions.length > 0)
  );
});
const confirmationHeaderPresent = computed(() => {
  return vpFinanceStore.po && isSome(vpFinanceStore.po.ConfirmationHeader);
});
const getConfirmationHeader = computed(() => {
  if (vpFinanceStore.po && isSome(vpFinanceStore.po.ConfirmationHeader)) {
    return getSome(vpFinanceStore.po.ConfirmationHeader);
  } else {
    return null;
  }
});
const chargeDetailsPresent = computed(() => {
  return vpFinanceStore.po && vpFinanceStore.po.MarkupTransactions.length > 0;
});
const chargeDetailRows = computed(() => {
  return vpFinanceStore.po ? vpFinanceStore.po.MarkupTransactions : [];
});
onMounted(() => {
  loadPO();
});
</script>
<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap !important;
}
</style>
