<template>
  <v-container class="mb-4">
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="text--black">
                <v-row>
                  <v-col cols="auto"> How Does This Work? </v-col>
                  <v-col cols="auto">
                    <data-tour
                      class="mb-3"
                      tourName="newItemRequestTour"
                      maxWidth="600px"
                      :steps="tourSteps"
                      :show="showTour"
                      :hideHelpIcon="hideHelpIcon"
                      :autoplay="true"
                      :scrollFirstStep="false"
                      :hideDoNotShowCheckbox="true"
                    ></data-tour>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle
                >Choose the Category Manager from the drop down, then download the “New Item Excel
                Template” below, fill out your required fields including the required fields noted
                with an *, then simply copy and paste the data into the field below.
              </v-card-subtitle>
              <v-card-actions>
                <v-btn id="dataEntry" text color="green" class="normal-case" @click="templateClick"
                  >New Item Excel Template</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row id="pasteNewItem">
          <v-col>
            <v-card>
              <v-card-title class="text--black">Category Manager</v-card-title>
              <v-card-actions>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="categoryManager"
                  :items="categoryManagers"
                  :loading="processing"
                  :disabled="processing"
                  autocomplete="off"
                  :item-text="x => `${x.FirstName} ${x.LastName}`"
                  :item-value="x => `${x.FirstName} ${x.LastName}`"
                  clearable
                >
                </v-autocomplete>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <new-item-paste-from-excel
              v-if="
                manufacturers &&
                manufacturers.length > 0 &&
                brands &&
                brands.length > 0 &&
                vendors &&
                vendors.length > 0 &&
                unitOfMeasures &&
                unitOfMeasures.length > 0
              "
              ref="pasteFromExcel"
              v-model="excelData"
              :id="id"
              :processing="processing"
              :multiSaveDisabled="multiSaveDisabled"
              :manufacturers="manufacturers"
              :brands="brands"
              :vendors="vendors"
              :unitOfMeasures="unitOfMeasures"
              @submitClicked="submitClickedMulti"
              @showSnackbarMessage="showSnackbarMessage($event.text, $event.color)"
            />
          </v-col>
        </v-row>
        <v-row id="pasteDsd">
          <v-col>
            <dsd-paste-from-excel
              v-if="vendors && vendors.length > 0 && unitOfMeasures && unitOfMeasures.length > 0"
              ref="dsdPasteFromExcel"
              v-model="dsdExcelData"
              :id="id"
              :processing="processing"
              :multiSaveDisabled="multiSaveDisabled"
              :vendors="vendors"
              :unitOfMeasures="unitOfMeasures"
              @submitClicked="submitClickedMulti"
              @showSnackbarMessage="showSnackbarMessage($event.text, $event.color)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              @click="submitClickedMulti"
              :loading="processing"
              :disabled="
                processing ||
                multiSaveDisabled ||
                zeroPasteItems ||
                !categoryManager ||
                categoryManager.length === 0
              "
              >Save</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                Click SAVE once both the Merchandising NIF and DSD Distributor (if needed) are
                complete. Once SAVE is clicked, you can no longer edit.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog v-model="multiBarcodeDialog" max-width="700px">
          <v-card>
            <v-card-title>Select Barcode From Database</v-card-title>
            <v-card-text>
              <v-radio-group v-model="selectedBarcodeInfo">
                <v-radio
                  v-for="(barcodeOption, ix) in multiBarcodeOptions"
                  :key="ix"
                  :value="barcodeOption"
                >
                  <template v-slot:label>
                    <v-row class="multiBarcode mt-4">
                      <v-col>
                        <v-row v-if="barcodeOption.CaseUpc">
                          <v-col> Case UPC: {{ barcodeOption.CaseUpc }} </v-col>
                        </v-row>
                        <v-row v-if="barcodeOption.OtherUpc">
                          <v-col> Other UPC: {{ barcodeOption.CaseUpc }} </v-col>
                        </v-row>
                        <v-row>
                          <v-col> {{ barcodeOption.Size }} {{ barcodeOption.Uom }} </v-col>
                        </v-row>
                        <v-row>
                          <v-col> {{ barcodeOption.SignsLabelsDescription1 }}</v-col>
                        </v-row>
                        <v-row>
                          <v-col> {{ barcodeOption.SignsLabelsDescription2 }}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="!selectedBarcodeInfo" @click="autoFillFromDb"
                >Fill with selected</v-btn
              >
              <v-btn @click="multiBarcodeDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import pLimit from 'p-limit';
import {
  NewItemRequest,
  Manufacturer,
  Brand,
  Vendor,
  DistributionCenter,
  ReasonForNewItem,
  PspEmployee,
  UnitOfMeasure,
  VendorRequestBarcodeInfo,
  PogovNewItemRequest,
  DsdDistributorData,
  Distributor,
} from '@/utils/VendorDomainTypes';
import {
  initDsdDistributorData,
  addEmptyDistributor,
  updateDistributor,
  updateDistributorShipToStoreAs,
} from '@/utils/DomainExports.fs.js';
import { getSome, isSome } from '@/utils/FableUtils.fs.js';
import PetPartnerExcelData from '@/store/modules/vendorportal/PetPartnerExcelData';
import DsdExcelData from '@/store/modules/vendorportal/DsdExcelData';
import * as vcs from '@/store/modules/vendorportal/consts';
import ItemRequestImageUpload from '@/components/vendorportal/ItemRequestImageUpload.vue';
import NewItemPasteFromExcel from '@/components/vendorportal/NewItemPasteFromExcel.vue';
import DsdPasteFromExcel from '@/components/vendorportal/DsdPasteFromExcel.vue';
import { useVpNewItemStore } from '@/stores/vpnewitemstore';

const vpModule = namespace('vendorportal');

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({
  components: { ItemRequestImageUpload, NewItemPasteFromExcel, DsdPasteFromExcel },
})
export default class EditNewItemRequest extends Vue {
  @Prop({ default: true }) public IsNew!: boolean;
  @Prop({ default: false }) public Processing!: boolean;

  @vpModule.Action('generateAggregateId') generateAggregateId!: () => Promise<string>;
  @vpModule.Action('getManufacturers') getManufacturers!: () => Promise<any>;
  @vpModule.Action('getBrands') getBrands!: () => Promise<any>;
  @vpModule.Action('getVendors') getVendors!: () => Promise<any>;
  @vpModule.Action('getDistributionCenters') getDistributionCenters!: () => Promise<any>;
  @vpModule.Action('getReasonsForNewItem') getReasonsForNewItem!: () => Promise<any>;
  @vpModule.Action('getCategoryManagers') getCategoryManagers!: () => Promise<any>;
  @vpModule.Action('getInventoryManagers') getInventoryManagers!: () => Promise<any>;
  @vpModule.Action('getUnitOfMeasures') getUnitOfMeasures!: () => Promise<any>;
  @vpModule.Action('getHierarchyLookup') getHierarchyLookup!: () => Promise<any>;
  @vpModule.Action('checkForUpcInNewRequests') checkForUpcInNewRequests!: (
    data: any,
  ) => Promise<NewItemRequest[]>;
  @vpModule.Action('checkForUpcInBarcodeMaster') checkForUpcInBarcodeMaster!: (
    upc: string,
  ) => Promise<VendorRequestBarcodeInfo[]>;

  @vpModule.Mutation('setImageuploadedFiles') setImageuploadedFiles!: (
    blobs: string[],
  ) => Promise<string>;

  @vpModule.State('manufacturers') manufacturers!: Manufacturer[];
  @vpModule.State('brands') brands!: Brand[];
  @vpModule.State('vendors') vendors!: Vendor[];
  @vpModule.State('distributionCenters') distributionCenters!: DistributionCenter[];
  @vpModule.State('reasonsForNewItem') reasonsForNewItem!: ReasonForNewItem[];
  @vpModule.State('inventoryManagers') inventoryManagers!: PspEmployee[];
  @vpModule.State('unitOfMeasures') unitOfMeasures!: UnitOfMeasure[];
  @vpModule.State('hierarchyLookup') hierarchyLookup!: string[];
  @vpModule.State('categoryManagers') categoryManagers!: PspEmployee[];

  public vpNewItemStore = useVpNewItemStore();
  public showSnackbar = false;
  public snackbarMessage = '';
  public snackbarColor = '';
  public id = '';
  public saveComplete = false;
  public multiSaveDisabled = false;

  public excelData: Array<PetPartnerExcelData> = [] as Array<PetPartnerExcelData>;
  public dsdExcelData: Array<DsdExcelData> = [] as Array<DsdExcelData>;

  // pet partner
  public requestType = 'NewItem';
  public caseBarcode = '';
  public otherBarcode = '';
  public retailPackageSize = '';
  public retailPackageUOM = '';
  public manufacturerAccountNumber = '';
  public brand: number | null = null;
  public vendorId = '';
  public externalItemNumber = '';
  public pluScan = '';
  public pluAssigned = '';
  public daxItemNumber = '';
  public signsAndLabelsDescription1 = '';
  public signsAndLabelsDescription2 = '';
  public categoryManager = '';

  public processing = false;
  public isPetPartner = false;
  public isPspMerchServices = false;
  public isPspPrivateLabel = false;
  public isPspCategoryManager = false;
  public upcHasPendingRequest = false;
  public upcHasNotInProgressRequest = false;
  public upcInProgressAggId: string | null = null;
  public hasSingleBarcodeMasterRow = false;
  public hasMultiBarcodeMasterRow = false;
  public multiBarcodeOptions = [] as VendorRequestBarcodeInfo[];
  public selectedBarcodeInfo: VendorRequestBarcodeInfo | null = null;
  public multiBarcodeDialog = false;
  public autoFilled = false;
  public dupeCheckUpcs = [] as string[];

  public showTour = true;
  public hideHelpIcon = false;
  public tourSteps = [
    {
      target: '#dataEntry',
      header: {
        title: 'Data Entry',
      },
      video: 'new_item_request_data_entry',
      placement: 'bottom',
    },
    {
      target: '#pasteNewItem',
      header: {
        title: 'Paste Merchandising Data',
      },
      video: 'new_item_request_paste_nif',
      placement: 'bottom',
    },
    {
      target: '#pasteDsd',
      header: {
        title: 'Paste DSD Data',
      },
      video: 'new_item_request_paste_dsd',
      placement: 'top',
    },
  ];

  @Watch('Processing') processingPropChange(curr) {
    this.processing = curr;
  }

  public async submitClickedMulti() {
    this.processing = true;
    const { allAreValid, requestData } = await (
      this.$refs.pasteFromExcel as NewItemPasteFromExcel
    ).validateMultiInput(this.IsNew, vcs.defaultStatus, vcs.schema);

    const { allAreValid: dsdAllAreValid, requestData: dsdRequestData } = await (
      this.$refs.dsdPasteFromExcel as DsdPasteFromExcel
    ).validateMultiInput(vcs.dsdSchema);

    if (allAreValid === true && dsdAllAreValid === true) {
      for (const r of requestData) {
        r.dsdDistributor = initDsdDistributorData();
        r.categoryManagerName = this.categoryManager;
      }

      if (dsdRequestData && dsdRequestData.length > 0) {
        // create lookup by UPC for DSD data
        const dsdLookup: Record<string, DsdDistributorData> = {} as Record<
          string,
          DsdDistributorData
        >;

        for (const dsd of dsdRequestData) {
          if (!dsdLookup[dsd.upc]) {
            dsdLookup[dsd.upc] = {} as DsdDistributorData;

            dsdLookup[dsd.upc].DaxItemNumber = dsd.daxItemNumber;
            dsdLookup[dsd.upc].Upc = dsd.upc;
            dsdLookup[dsd.upc].Distributors = [] as Distributor[];
          }

          const distributor = {
            DistributorId: dsd.vendor,
            ExternalItemId: dsd.externalItemId,
            StoreCost: dsd.storeCost,
            ShipToStoreAs: dsd.shipsAsCaseOrUnit,
          } as Distributor;

          dsdLookup[dsd.upc].Distributors.push(distributor);
        }

        // now fill in the DSD data into requestData
        for (const r of requestData) {
          if (dsdLookup[r.fullUnitBarcode]) {
            r.dsdDistributor.DaxItemNumber = dsdLookup[r.fullUnitBarcode].DaxItemNumber;
            r.dsdDistributor.Upc = dsdLookup[r.fullUnitBarcode].Upc;
            r.dsdDistributor.ItemDescription = '';

            let distIx = 0;
            for (let d of dsdLookup[r.fullUnitBarcode].Distributors) {
              r.dsdDistributor = addEmptyDistributor(r.dsdDistributor);
              d = updateDistributorShipToStoreAs(d.ShipToStoreAs, d);
              r.dsdDistributor = updateDistributor(distIx, d, r.dsdDistributor);
              distIx++;
            }
          }
        }
      }

      this.$emit('request-data-multi', requestData);
    }

    this.processing = false;
  }

  public showSnackbarMessage(message: string, color: string) {
    this.snackbarMessage = message;
    this.snackbarColor = color;
    this.showSnackbar = true;
  }

  public get isPsp(): boolean {
    return this.isPspMerchServices || this.isPspPrivateLabel || this.isPspCategoryManager;
  }

  public async mounted() {
    this.processing = true;

    this.isPetPartner = await this.$authApi.roleHasRights('PetPartner');
    this.isPspMerchServices = await this.$authApi.roleHasRights('PSPMerchServices');
    this.isPspPrivateLabel = await this.$authApi.roleHasRights('PSPPrivateLabel');
    this.isPspCategoryManager = await this.$authApi.roleHasRights('PSPCategoryManager');

    await Promise.all([
      this.getManufacturers(),
      this.getBrands(),
      this.getVendors(),
      this.getDistributionCenters(),
      this.getReasonsForNewItem(),
      this.getCategoryManagers(),
      this.getInventoryManagers(),
      this.getUnitOfMeasures(),
      this.getHierarchyLookup(),
    ]);

    if (this.IsNew) {
      this.generateAggregateId().then(id => (this.id = id));
    }

    this.processing = false;
  }

  public async checkForDuplicateBarcode(upc: string | null) {
    this.upcHasPendingRequest = false;
    this.upcHasNotInProgressRequest = false;
    this.upcInProgressAggId = null;
    this.hasSingleBarcodeMasterRow = false;
    this.hasMultiBarcodeMasterRow = false;
    this.autoFilled = false;

    if (upc && upc.trim().length > 0 && this.id && this.id.length > 0) {
      let { inNewRequestsInProgress, inNewRequestsNotInProgress, inBarcodeMaster } =
        await this.checkUpcInCurrentData(upc);

      if (inNewRequestsInProgress?.length > 0) {
        this.upcHasPendingRequest = true;
        this.upcInProgressAggId =
          inNewRequestsInProgress[0]?.AggregateId?.replaceAll('-', '')?.toLowerCase() ?? '';
      } else if (inNewRequestsNotInProgress?.length > 0) {
        this.upcHasNotInProgressRequest = true;
      } else if (inBarcodeMaster?.length > 0 && this.requestType === 'NewItem') {
        if (inBarcodeMaster.length === 1) {
          this.hasSingleBarcodeMasterRow = true;
          this.selectedBarcodeInfo = inBarcodeMaster[0];
        } else {
          this.hasMultiBarcodeMasterRow = true;
          this.multiBarcodeOptions = Object.assign(
            [],
            inBarcodeMaster,
          ) as VendorRequestBarcodeInfo[];
        }
      }
    }
  }

  public async checkUpcInCurrentData(upc: string): Promise<any> {
    const requests = await Promise.all<any>([
      this.checkForUpcInNewRequests({ Upc: upc, AggregateId: this.id }),
      this.checkForUpcInBarcodeMaster(upc),
    ]);
    let inNewRequestsInProgress = [] as PogovNewItemRequest[];
    let inNewRequestsNotInProgress = [] as PogovNewItemRequest[];
    if (isSome(requests[0])) {
      inNewRequestsInProgress = (getSome(requests[0]) as PogovNewItemRequest[]).filter(
        x => x.Status === 'In Progress',
      );
      inNewRequestsNotInProgress = (getSome(requests[0]) as PogovNewItemRequest[]).filter(
        x => x.Status !== 'In Progress',
      );
    }

    let inBarcodeMaster = [] as VendorRequestBarcodeInfo[];
    if (isSome(requests[1])) {
      inBarcodeMaster = getSome(requests[1]) as VendorRequestBarcodeInfo[];
    }
    return { upc, inNewRequestsInProgress, inNewRequestsNotInProgress, inBarcodeMaster };
  }

  public autoFillFromDb() {
    if (this.selectedBarcodeInfo) {
      this.autoFilled = true;
      this.caseBarcode = this.selectedBarcodeInfo.CaseUpc;
      this.otherBarcode = this.selectedBarcodeInfo.OtherUpc;
      this.pluScan = this.selectedBarcodeInfo.PluUpc;
      this.pluAssigned = this.selectedBarcodeInfo.Plu;
      this.daxItemNumber = this.selectedBarcodeInfo.ItemNumber;
      this.retailPackageSize = this.selectedBarcodeInfo.Size;
      this.retailPackageUOM = this.selectedBarcodeInfo.Uom;
      this.signsAndLabelsDescription1 = this.selectedBarcodeInfo.SignsLabelsDescription1;
      this.signsAndLabelsDescription2 = this.selectedBarcodeInfo.SignsLabelsDescription2;
    }

    this.multiBarcodeDialog = false;
  }

  public templateClick() {
    this.vpNewItemStore.getCurrentNewItemTemplate();
  }

  @Watch('excelData') async excelDataChanged() {
    this.processing = true;
    this.multiSaveDisabled = false;
    try {
      const { allAreValid } = await (
        this.$refs.pasteFromExcel as NewItemPasteFromExcel
      ).validateMultiInput(this.IsNew, vcs.defaultStatus, vcs.schema);
      if (this.excelData && this.excelData.length > 0) {
        // check for duplicates within the dataset
        const upcCounts = this.excelData.reduce((p, c) => {
          if (!p[c.fullUnitBarcode]) {
            p[c.fullUnitBarcode] = 0;
          }
          p[c.fullUnitBarcode] += 1;
          return p;
        }, {});

        const productNumberCounts = this.excelData.reduce((p, c) => {
          if (!p[c.externalItemNumber]) {
            p[c.externalItemNumber] = 0;
          }
          p[c.externalItemNumber] += 1;
          return p;
        }, {});

        const dupeUpcCounts = Object.keys(upcCounts).filter(key => upcCounts[key] > 1);
        if (dupeUpcCounts?.length > 0) {
          for (const dupeUpc of dupeUpcCounts) {
            this.excelData[0].pushError(
              `UPC ${dupeUpc} appears multiple times in the data. Please ensure each UPC is unique.`,
            );
          }
        }

        const dupeProductNumberCounts = Object.keys(productNumberCounts).filter(
          key => productNumberCounts[key] > 1,
        );
        if (dupeProductNumberCounts?.length > 0) {
          for (const dupeProductNumber of dupeProductNumberCounts) {
            this.excelData[0].pushError(
              `Vendor product number ${dupeProductNumber} appears multiple times in the data. Please ensure each row is unique.`,
            );
          }
        }

        const limit = pLimit(5);
        const dupeChecks = [] as Promise<any>[];
        // check for dupes
        for (const line of this.excelData) {
          if (this.dupeCheckUpcs.indexOf(line.fullUnitBarcode) === -1) {
            this.dupeCheckUpcs.push(line.fullUnitBarcode);
            dupeChecks.push(
              limit(async () => {
                return await this.checkUpcInCurrentData(line.fullUnitBarcode);
              }),
            );
          }
        }

        await Promise.all(dupeChecks);

        let lineIx = 1;
        let hasUpcError = false;
        for (const dupeCheck of dupeChecks) {
          const dupeCheckResult = await dupeCheck;

          if (
            dupeCheckResult.inNewRequestsInProgress?.length > 0 ||
            dupeCheckResult.inNewRequestsNotInProgress?.length > 0 ||
            dupeCheckResult.inBarcodeMaster?.length > 0
          ) {
            let errorMessage = `Row ${lineIx} has the following error: `;
            if (dupeCheckResult.inNewRequestsInProgress?.length > 0) {
              errorMessage += `${dupeCheckResult.upc} has an in-progress request. Please remove from Excel data.`;
            } else if (dupeCheckResult.inNewRequestsNotInProgress?.length > 0) {
              errorMessage += `${dupeCheckResult.upc} already has a request. Please remove from Excel data.`;
            } else if (dupeCheckResult.inBarcodeMaster?.length > 0) {
              errorMessage += `${dupeCheckResult.upc} is already in our product data. Please remove from Excel data.`;
            }
            this.excelData[0].pushError(errorMessage);
            hasUpcError = true;
          }

          lineIx += 1;
        }

        if (allAreValid !== true || hasUpcError === true) {
          this.multiSaveDisabled = true;
        }
      }

      this.processing = false;
    } catch (err) {
      this.processing = false;
      this.showSnackbarMessage('Failed to validate multiple item input.', 'error');
    }
  }

  @Watch('dsdExcelData') async dsdExcelDataChanged() {
    this.processing = true;
    this.multiSaveDisabled = false;
    try {
      const { allAreValid } = await (
        this.$refs.dsdPasteFromExcel as DsdPasteFromExcel
      ).validateMultiInput(vcs.dsdSchema);

      if (allAreValid !== true) {
        this.multiSaveDisabled = true;
      }

      this.processing = false;
    } catch (err) {
      this.processing = false;
      this.showSnackbarMessage('Failed to validate multiple DSD input.', 'error');
    }
  }

  public get zeroPasteItems() {
    return !this.excelData || this.excelData.length === 0;
  }

  public async destroyed() {
    await this.setImageuploadedFiles([] as string[]);
  }
}
</script>

<style lang="scss" scoped>
.normal-case {
  text-transform: none;
}
.required {
  color: red !important;
  max-width: 10px;
  margin-top: 20px;
  padding-left: 0;
}

.required.key {
  max-width: 100% !important;
  margin-top: 0;
  padding-top: 0;
  margin-left: 20px;
}

.multiBarcode > div {
  display: inline-block;
}

.multiBarcode {
  border: 1px solid #a2a2a2;
  margin: 0 10px;
  padding-top: 10px;
}
</style>
