import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  batchInfoItems: set('batchInfoItems'),
  approvedTypes: set('approvedTypes'),
  labelItems: set('labelItems'),
  createdBatchInfo: set('createdBatchInfo'),
  selectedItems: set('selectedItems'),
  search: set('search'),
  nonActivatedToggle: set('nonActivatedToggle'),
  queuedItems: set('queuedItems'),
  selectedBarcodeSetups: set('selectedBarcodeSetups'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
};
