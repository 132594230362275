<template>
  <div>
    <v-img v-if="lazySrc && loaded === false" :lazy-src="lazySrc"></v-img>
    <slot name="placeholder"></slot>
    <div v-if="loaded" v-html="svg"></div>

    <v-row v-if="error">
      <v-col>
        <v-row>
          <p class="red--text d-block mx-5 title">Failed to load preview.</p>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn @click.stop="loadSvg" title="Reload">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="js">
import AxiosHelpers from '@/utils/AxiosHelpers';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    src: {
      type: String,
      required: true,
      default: '',
    },
    lazySrc: {
      type: String,
      required: false,
      default: null,
    },
    headers: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    maxWidth: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      axiosHelpers: new AxiosHelpers(),
      loaded: false,
      svg: '',
      error: false,
    };
  },
  mounted() {
    this.loadSvg();
  },
  methods: {
    loadSvg() {
      this.$emit('loading');
      this.loaded = false;
      this.error = false;

      this.$authApi.http
        .get(this.src, {
          headers: this.headers,
        })
        .then(r => {
          let svgText = r.data;

          if (this.maxWidth) {
            try {
              const replacerStrip = (match, start) => start;
              const replacer = (match, start) => [start, `width="${this.maxWidth}"`].join(' ');
              // if they've specified a max-width, we need to remove the width and height from the svg text

              const widthHeightRegex = /(<svg[^>]+)((width|height)=("|')[^("|')]+("|'))/i;
              // first remove the second width or height
              svgText = svgText.replace(widthHeightRegex, replacerStrip);
              // then, replace either the width or height with our maxWidth
              svgText = svgText.replace(widthHeightRegex, replacer);
            } catch (e) {
              this.$appInsights.trackException({
                exception: e,
                properties: {
                  text: 'Error trying to transform SVG',
                  username: this.username,
                  storeNumber: this.storeNumber,
                  route: this.$route,
                  id: '834004f4-6f0d-4be0-a898-2c3b822029e3',
                },
              });
            }
          }

          const xmlStart = '<xml';
          if (svgText.substring(0, xmlStart.length) === xmlStart) {
            // Parse the XML doc and take only the first <svg> element
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(svgText, 'text/xml');

            this.svg = xmlDoc.getElementsByTagName('svg')[0].outerHTML;
          } else {
            this.svg = svgText;
          }
          this.error = false;
          this.$emit('input', true);
        })
        .catch(err => {
          this.error = true;
          this.$emit('snackbar-error', {
            text: 'Error loading preview.',
            err,
            id: '56c9034c-95dd-4d91-9274-d1ffb7788949',
          });
          this.$emit('input', false);
        })
        .finally(() => {
          this.loaded = true;
          this.$emit('load');
        });
    },
  },
  watch: {
    src: {
      handler: function () {
        this.svg = '';
        this.loaded = false;
        this.loadSvg();
      },
    },
    value: {
      handler: function (val) {
        if(val === false && this.svg && this.svg.length > 0) {
          this.loaded = false;
        }
      },
    },
  },
};
</script>
