import {
  IncludeItemsType,
  PartnerFundingSearchParams,
  PartnerFundingSearchResult,
  PartnerFundingValidationResult,
  FileUploadProcessingStateInfo,
} from '@psp/pogona_ecom_client_js';
import { PartnerFundingState } from './state';
import { DwBrand } from '@psp/pogona_ecom_client_js/DwBrand';
import { DwCategory } from '@psp/pogona_ecom_client_js/DwCategory';

export const setClient = 'setClient';
export const setPartnerFundingData = 'setPartnerFundingData';
export const clearPartnerFundingData = 'clearPartnerFundingData';
export const setPartnerFundingSearch = 'setPartnerFundingSearch';
export const clearPartnerFundingSearch = 'clearPartnerFundingSearch';
export const incrementSearchPage = 'incrementSearchPage';
export const setFirstPage = 'setFirstPage';
export const setSearchText = 'setSearchText';
export const setBrands = 'setBrands';
export const setDepartments = 'setDepartments';
export const setSubDepartments = 'setSubDepartments';
export const setCategories = 'setCategories';
export const setSubCategories = 'setSubCategories';
export const setUploadFile = 'setUploadFile';
export const setValidationData = 'setValidationData';
export const setShowProcessing = 'setShowProcessing';
export const setProcessingData = 'setProcessingData';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  [setClient](state: PartnerFundingState, payload: any): void {
    state.init(payload.http, payload.baseUrl);
  },
  [setPartnerFundingData](state: PartnerFundingState, payload: PartnerFundingSearchResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new PartnerFundingSearchResult(), payload[i]);
    }
    if (state.partnerFundingSearch.PageNumber === 1) {
      state.partnerFundingData = payload;
    } else {
      state.partnerFundingData.push(...payload);
    }
  },
  [clearPartnerFundingData](state: PartnerFundingState): void {
    state.partnerFundingData = [];
  },
  [setPartnerFundingSearch](state: PartnerFundingState, payload: PartnerFundingSearchParams): void {
    state.partnerFundingSearch = payload;
  },
  [clearPartnerFundingSearch](state: PartnerFundingState): void {
    state.partnerFundingSearch.BrandIds = [];
    state.partnerFundingSearch.CategoryLevel1Id = null;
    state.partnerFundingSearch.CategoryLevel2Id = null;
    state.partnerFundingSearch.CategoryLevel3Id = null;
    state.partnerFundingSearch.CategoryLevel4Ids = [];
    state.partnerFundingSearch.GenericText = null;
    state.partnerFundingSearch.ItemId = null;
    state.partnerFundingSearch.Sku = null;
    state.partnerFundingSearch.PageNumber = 1;
    state.partnerFundingSearch.Include = IncludeItemsType.All;
  },
  [incrementSearchPage](state: PartnerFundingState, payload: number): void {
    state.partnerFundingSearch.PageNumber += payload;
  },
  [setFirstPage](state: PartnerFundingState): void {
    state.partnerFundingSearch.PageNumber = 1;
  },
  [setSearchText](state: PartnerFundingState, payload: string): void {
    state.partnerFundingSearch.GenericText = payload;
  },
  [setBrands](state: PartnerFundingState, payload: DwBrand[]): void {
    state.brands = payload;
  },
  [setDepartments](state: PartnerFundingState, payload: DwCategory[]): void {
    state.departments = payload;
  },
  [setSubDepartments](state: PartnerFundingState, payload: DwCategory[]): void {
    state.subDepartments = payload;
  },
  [setCategories](state: PartnerFundingState, payload: DwCategory[]): void {
    state.categories = payload;
  },
  [setSubCategories](state: PartnerFundingState, payload: DwCategory[]): void {
    state.subCategories = payload;
  },
  [setUploadFile](state: PartnerFundingState, payload: File | null): void {
    state.uploadFile = payload;
  },
  [setValidationData](state: PartnerFundingState, payload: PartnerFundingValidationResult): void {
    state.validationData = payload;
  },
  [setShowProcessing](state: PartnerFundingState, payload: boolean): void {
    state.showProcessing = payload;
  },
  [setProcessingData](state: PartnerFundingState, payload: FileUploadProcessingStateInfo[]): void {
    state.processingData = payload;
  },
};
