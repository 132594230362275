var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({},on),[_vm._v(_vm._s(_vm.newText))])]}}]),model:{value:(_vm.newUserStoreMappingDialog),callback:function ($$v) {_vm.newUserStoreMappingDialog=$$v},expression:"newUserStoreMappingDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.newText))])]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.appendStoreToNewMappingGrid.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Store Number","error-messages":_vm.storeNumberErrors},model:{value:(_vm.newStoreToMap),callback:function ($$v) {_vm.newStoreToMap=$$v},expression:"newStoreToMap"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":_vm.appendStoreToNewMappingGrid}},[_vm._v("Add Store")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 scrollable",attrs:{"headers":[
                {
                  text: 'Store Number',
                  value: 'storeNumber',
                },
                {
                  text: 'Remove',
                  value: 'remove',
                },
              ],"items":_vm.newStoresToMap,"disable-pagination":true,"fixed-header":true,"item-key":"storeNumber","show-select":false},scopedSlots:_vm._u([{key:"item.remove",fn:function({ item }){return [_c('v-icon',{on:{"click":function($event){return _vm.removeNewStoreFromGrid(item)}}},[_vm._v("delete")])]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.saveDisabled,"loading":_vm.loading},on:{"click":_vm.storeMappingsSaved}},[_vm._v("Save")]),_c('v-btn',{on:{"click":function($event){_vm.newUserStoreMappingDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }