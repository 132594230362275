/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PogonaApiClient, PogonaApiClientConfig } from '@psp/pogona_api_client_js';
import { AxiosInstance } from 'axios';

export const client = 'client';

export const stores = 'stores';
export const reports = 'reports';
export const selectedStore = 'selectedStore';
export const selectedBatchId = 'selectedBatchId';
export const storeGetInProgress = 'storeGetInProgress';
export const searchInProgress = 'searchInProgress';
export const downloadInProgress = 'downloadInProgress';

export class PreCountReportsState {
  constructor(
    public client: PreCountReportApiClient | null = null,

    public stores: Array<string> = [] as Array<string>,
    public reports: Array<FileShareFile> = [] as Array<FileShareFile>,
    public selectedStore: string | null = null,
    public selectedBatchId: string | null = null,
    public searchInProgress: boolean = false,
    public downloadInProgress: boolean = false,
    public storeGetInProgress: boolean = false,
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public init(http: any, baseUrl: string | null): void {
    this.client = new PreCountReportApiClient(null, http, baseUrl);
  }
}

export class PreCountReportApiClient extends PogonaApiClient {
  constructor(
    config: PogonaApiClientConfig | null = null,
    client: AxiosInstance | null = null,
    baseOverrideUrl: string | null = null,
  ) {
    super(config, client, baseOverrideUrl);
  }
}

export const preCountReportsState: PreCountReportsState = new PreCountReportsState();

export class FileShareFile {
  constructor(
    public id: string | null = null,
    public name: string | null = null,
    public createdOn: Date | null = null,
    public lastModified: Date | null = null,
    public fileSize: number | null = null,
  ) {}
}

export default preCountReportsState;
