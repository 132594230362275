<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-file-input
            v-model="files"
            @change="filesChanged"
            label="Images"
            :loading="uploading"
            prepend-icon="image"
            multiple
          ></v-file-input>
          <v-list>
            <v-list-item two-line v-for="uploadedFile in uploadedFiles" :key="uploadedFile">
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ fileNameFromBlobUrl(uploadedFile) }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  <v-row class="image-pointer">
                    <v-col cols="2">
                      <v-img
                        :src="uploadedFile"
                        @click="clickedImage(uploadedFile)"
                        max-width="100px"
                      />
                    </v-col>
                    <v-col>
                      <v-icon @click="deleteBlob(uploadedFile)">close</v-icon>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-dialog v-model="largeImageDialog" max-width="700px">
            <v-img :src="largeImageSrc" />
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, Model, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const vpModule = namespace('vendorportal');

@Component({})
export default class ItemRequestImageUpload extends Vue {
  private files: File[] | null = null;
  private uploading = false;
  private inited = false;

  private largeImageDialog = false;
  private largeImageSrc: string | null = null;

  @Model('input')
  private value!: string[] | null;

  @Prop({ required: true })
  private aggregateId!: string;

  @vpModule.Action('uploadImage') uploadImage!: (data: any) => Promise<string>;

  @vpModule.Mutation('setImageuploadedFiles') setImageuploadedFiles!: (
    blobs: string[],
  ) => Promise<string>;
  @vpModule.Mutation('removeImageUploadResult') removeImageUploadResult!: (
    blob: string,
  ) => Promise<string>;

  @vpModule.State('uploadedFiles') uploadedFiles!: Array<string>;

  @Watch('value') valueChanged(val: string[] | null) {
    if (this.inited === false) {
      this.inited = true;
      this.setImageuploadedFiles(val ?? ([] as string[]));
    }
  }

  public async filesChanged() {
    this.uploading = true;
    try {
      if (this.files && this.files.length > 0) {
        for (const file of this.files) {
          await this.uploadImage({ File: file, AggregateId: this.aggregateId });
        }
      }

      this.uploading = false;
      this.files = null;
      this.$emit('input', this.uploadedFiles);
    } catch (err) {
      this.uploading = false;
      this.files = null;
      this.$emit('snackbar-error', {
        text: 'Error uploading image',
        err,
        id: '29deabfe-58fc-4cb0-9bba-45965f77f98c',
      });
    }
  }

  public async deleteBlob(uploadedFile: string) {
    try {
      this.removeImageUploadResult(uploadedFile);
      this.$emit('input', this.uploadedFiles);
    } catch (err) {
      this.uploading = false;
      this.$emit('snackbar-error', {
        text: 'Error deleting image',
        err,
        id: 'de708898-ba9a-4592-a919-e8ee20f983cf',
      });
    }
  }

  private clickedImage(blobUrl: string | null) {
    this.largeImageSrc = blobUrl;
    this.largeImageDialog = true;
  }

  private fileNameFromBlobUrl(blobUrl: string | null) {
    if (blobUrl) {
      const parts = blobUrl.split('/');
      if (parts.length > 4) {
        return parts[parts.length - 1];
      }
    }
    return '';
  }
}
</script>

<style lang="scss" scoped>
.image-pointer {
  cursor: pointer;
}
</style>
