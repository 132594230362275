/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PogonaApiClient, PogonaApiClientConfig } from '@psp/pogona_api_client_js';
import { AxiosInstance } from 'axios';

export const client = 'client';

export const allStoreSets = 'allStoreSets';
export const storeStoreSets = 'storeStoreSets';
export const comparedStoreSet = 'comparedStoreSet';
export const storeSetCompareParams = 'storeSetCompareParams';

export const sourceStoreSetSearch = 'sourceStoreSetSearch';
export const targetStoreSetSearch = 'targetStoreSetSearch';
export const comparedSearch = 'comparedSearch';

export const selectedSource = 'selectedSource';
export const selectedTarget = 'selectedTarget';

export const allStoreSetsGetInProgress = 'allStoreSetsGetInProgress';
export const storeStoreSetsGetInProgress = 'storeStoreSetsGetInProgress';
export const compareStoreSetsInProgress = 'compareStoreSetsInProgress';

export const compareStoreSetsGetsInProgress = 'compareStoreSetsGetsInProgress';

export class StoreSetCompareState {
  constructor(
    public client: StoreSetCompareApiClient | null = null,

    public allStoreSets: Array<StoreSet> = [] as Array<StoreSet>,
    public storeStoreSets: Array<StoreSet> = [] as Array<StoreSet>,
    public comparedStoreSet: Array<StoreSetDataCompared> = [] as Array<StoreSetDataCompared>,
    public storeSetCompareParams: StoreSetCompareParams | null = null,
    public sourceStoreSetSearch: string | null = null,
    public targetStoreSetSearch: string | null = null,
    public comparedSearch: string | null = null,
    public selectedSource: any | null = null,
    public selectedTarget: any | null = null,
    public allStoreSetsGetInProgress: boolean = false,
    public storeStoreSetsGetInProgress: boolean = false,
    public compareStoreSetsInProgress: boolean = false,
    public compareStoreSetsGetInProgress: any = null,
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public init(http: any, baseUrl: string | null): void {
    this.client = new StoreSetCompareApiClient(null, http, baseUrl);
    this.storeSetCompareParams = new StoreSetCompareParams();
  }
}

export class StoreSetCompareApiClient extends PogonaApiClient {
  constructor(
    config: PogonaApiClientConfig | null = null,
    client: AxiosInstance | null = null,
    baseOverrideUrl: string | null = null,
  ) {
    super(config, client, baseOverrideUrl);
  }
}

export const storeSetCompareState: StoreSetCompareState = new StoreSetCompareState();

export class StoreSetDataCompared {
  constructor(
    public setId: number = 0,
    public upc: string | null = null,
    public itemId: string | null = null,
    public fulldescription1: string | null = null,
    public fulldescription2: string | null = null,
    public onHand: number = 0,
    public movementStore: number | null = null,
    public retailStore: number | null = null,
    public movementAvg: number | null = null,
    public retailAvg: number | null = null,
    public setSize: number = 0,
    public salesUom: string | null = null,
    public isRemoved: boolean = false,
  ) {}
}

export class StoreSet {
  constructor(
    public setId: number = 0,
    public description: string | null = null,
    public setSize: number = 0,
    public isEndCap: boolean = false,
  ) {}
}

export class StoreSetCompareParams {
  constructor(
    public StoreNumber: string | null = null,
    public SourceSetId: number | null = null,
    public TargetSetId: number | null = null,
  ) {}
}

export default storeSetCompareState;
