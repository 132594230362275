<template>
  <v-row>
    <v-col>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 37.5 350 25"
        :color="color"
        :transform="`rotate(${rotation}, -5,0)`"
      >
        <defs>
          <marker
            marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7" fill="currentcolor" stroke="currentcolor" />
          </marker>
          <marker
            marker
            id="arrowheadDouble"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="0 0, 10 3.5, 0 7"
              fill="currentcolor"
              stroke="currentcolor"
              transform="rotate(180, 5,3.5)"
            />
          </marker>
        </defs>
        <line
          x1="0"
          y1="50"
          :x2="length"
          y2="50"
          :stroke-width="strokeWidth"
          marker-end="url(#arrowhead)"
          :marker-start="doubleArrow === true ? 'url(#arrowheadDouble)' : ''"
          fill="currentcolor"
          stroke="currentcolor"
        />
      </svg>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    length: {
      type: Number,
      required: false,
      default: 310,
    },
    strokeWidth: {
      type: Number,
      required: false,
      default: 2,
    },
    direction: {
      type: String,
      required: false,
      default: 'right',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
    doubleArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rotation() {
      switch (this.direction) {
        case 'right':
          return 0;
        case 'left':
          return 180;
        case 'up':
          return -90;
        case 'down':
          return 90;
        default:
          throw new Error(
            `Direction ${this.direction} not supported. Supported directions: right, left, up, down.`,
          );
      }
    },
  },
};
</script>
