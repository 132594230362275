<template>
  <v-container>
    <v-row>
      <span>File name: {{ uploadFile.name }}</span>
    </v-row>
    <v-row>
      <span>Valid rows found: {{ validationData.Count }}</span>
    </v-row>
    <v-row>
      <span class="mt-3">File Preview:</span>
    </v-row>
    <v-row>
      <v-data-table :headers="headers" :items="validationData.SampleRows" hide-default-footer>
      </v-data-table>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="cancelClicked">Cancel</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="saveExceptionsClicked">Save Funding</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pfa from '@/store/modules/partnerfunding/actions';
import * as pfm from '@/store/modules/partnerfunding/mutations';
import * as pfs from '@/store/modules/partnerfunding/state';
import { PartnerFundingValidationResult } from '@psp/pogona_ecom_client_js';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pfModule = namespace('partnerfunding');

@Component({})
export default class PartnerFundingValidationReview extends Vue {
  @pfModule.State(pfs.validationData) validationData!: PartnerFundingValidationResult | null;
  @pfModule.State(pfs.uploadFile) uploadFile!: File | null;

  @pfModule.Mutation(pfm.setUploadFile) setUploadFile!: (payload: File | null) => void;
  @pfModule.Mutation(pfm.setValidationData) setValidationData!: (
    payload: PartnerFundingValidationResult | null,
  ) => void;
  @pfModule.Mutation(pfm.setShowProcessing) setShowProcessing!: (payload: boolean) => void;

  @pfModule.Action(pfa.processPartnerFundingExcelFile) processPartnerFundingExcelFile!: (
    payload: File | null,
  ) => void;

  private cancelClicked() {
    this.setUploadFile(null);
    this.setValidationData(null);
  }

  private saveExceptionsClicked() {
    this.processPartnerFundingExcelFile(this.uploadFile);
    this.setShowProcessing(true);
    this.setValidationData(null);
  }

  private headers = [
    { text: 'ItemId', value: 'ItemId' },
    { text: 'SKU', value: 'Sku' },
    { text: 'Initial Percent Funded', value: 'InitialPercentFunded' },
    { text: 'On Going Percent Funded', value: 'OnGoingPercentFunded' },
  ];
}
</script>
