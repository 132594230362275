import { render, staticRenderFns } from "./FormSubmission.vue?vue&type=template&id=150d7850&scoped=true"
import script from "./FormSubmission.vue?vue&type=script&lang=js"
export * from "./FormSubmission.vue?vue&type=script&lang=js"
import style0 from "./FormSubmission.vue?vue&type=style&index=0&id=150d7850&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150d7850",
  null
  
)

export default component.exports