<template>
  <core-view-template title="Edit Store">
    <v-row>
      <v-col cols="12">
        <pcl-new-edit-store
          v-model="storeEditData"
          :mode="mode"
          ref="editStore"
        ></pcl-new-edit-store>
      </v-col>
    </v-row>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PclNewEditStore from '@/components/pcl/PclNewEditStore.vue';
import { PclStoreEditMode } from '@/store/modules/pcl/state';
import { StoreCreateUpdatePayload } from '@psp/pogona_pcl_client_js';
import * as ps from '@/store/modules/pcl/state';

const pclModule = namespace('pcl');
/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  components: {
    PclNewEditStore,
  },
})
export default class PclLanding extends Vue {
  @pclModule.State(ps.storeEditData) storeEditData!: StoreCreateUpdatePayload;

  mounted() {
    if (this.$refs.editStore) {
      (this.$refs.editStore as PclNewEditStore).init();
    }
  }

  public get mode() {
    return PclStoreEditMode.Edit;
  }
}
</script>
