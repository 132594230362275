<template>
  <div>
    <v-app-bar dark v-if="$vuetify.breakpoint.smAndDown">
      <v-toolbar-items>
        <v-btn class="mt-0" @click="showMenu = !showMenu" text>
          <v-icon x-large color="green">list</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-hover :disabled="$vuetify.breakpoint.smAndDown" :open-delay="300" v-model="expanded">
      <v-navigation-drawer
        id="app-drawer"
        v-model="showMenu"
        :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
        :mini-variant="!expanded"
        :class="expanded ? '' : 'overflow-hidden'"
        mini-variant-width="5.5em"
        app
        dark
        floating
        width="330"
      >
        <v-list>
          <v-list-item class="logo-ctn">
            <span
              :class="[
                'logo',
                'justify-center',
                $vuetify.breakpoint.smAndDown ? 'mobile' : '',
                expanded ? 'expanded' : '',
              ]"
              alt="PSP Group Logo"
            />
          </v-list-item>
          <v-divider></v-divider>
          <core-drawer-item v-for="(key, i) in menuDataView" :key="i" :menuItem="key" />
        </v-list>
      </v-navigation-drawer>
    </v-hover>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import menuData from '@/data/menu.json';
import Vue from 'vue';

export default {
  data() {
    return {
      menuData,
      showMenu: this.$vuetify.breakpoint.mdAndUp,
      expanded: this.$vuetify.breakpoint.smAndDown, // set to expanded by default when in mobile
    };
  },
  computed: {
    ...mapState('app', ['menuVisibilities']),
    menuDataView() {
      const viewLocal = this.menuData;

      /* eslint-disable */
      for (const section in viewLocal) {
        for (const detail in viewLocal[section]) {
          if (detail === 'entries') {
            for (const entryIx in viewLocal[section][detail]) {
              const entry = viewLocal[section][detail][entryIx];
              if (
                entry &&
                entry.route &&
                entry.route.name &&
                this.menuVisibilities[entry.route.name] === true
              ) {
                Vue.set(viewLocal[section][detail][entryIx], 'hide', true);
              }
            }
          } else if (detail === 'route' && section === 'storeList') {
            const entry = viewLocal[section][detail];
            if (entry && entry.name && this.menuVisibilities[entry.name] === true) {
              Vue.set(viewLocal[section], 'hide', true);
            }
          }
        }
      }
      return viewLocal;
    },
  },
  watch: {
    '$vuetify.breakpoint.mdAndUp': {
      handler: function (val) {
        this.showMenu = val;
        if (val === true) {
          this.expanded = false;
        }
      },
    },
    '$vuetify.breakpoint.smAndDown': {
      handler: function (val) {
        if (val === true) {
          this.expanded = val;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.theme--dark.v-toolbar {
  background-color: #424242;
}
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  div.v-responsive.v-image > div.v-responsive__content {
    overflow-y: auto;
  }

  .logo {
    background-image: url('../../assets/logo-mini.png');
    background-size: 45px 24px;
    width: 45px;
    height: 24px;
  }

  .logo.expanded,
  .logo.mobile {
    background-image: url('../../assets/logo.png');
    background-size: 157px 23px;
    width: 157px;
    height: 23px;
    margin: auto;
  }
}

.logo-ctn {
  padding-right: 0 !important;
}

.sm-and-down .core-view-wrap {
  padding-left: 0 !important;
}
.overflow-hidden .v-navigation-drawer__content {
  overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.v-list-item {
  padding-left: 0 !important;
}
</style>
