<template>
  <v-col cols="12">
    <v-form @submit.stop.prevent="createUser">
      <v-row>
        <v-col>
          <v-row>
            <v-col><span class="red-text">* All fields are required</span></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="email"
                label="Email"
                v-debounce:300="searchForUser"
                @change="searchForUser"
                :error-messages="emailErrors"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="displayName" label="Display Name (First Last)" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="firstName" label="First Name" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="lastName" label="Last Name" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="companyName" label="Company Name (ABC Inc.)" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="selectedProfile"
                :items="roleProfileKeys"
                label="Role Profile"
                clearable
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn
                      @click="createUser"
                      :disabled="canCreateUser === false || creatingUser"
                      :loading="creatingUser"
                      >Save</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn @click="cancelCreateUser">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import Vue from 'vue';

const AdCreateUser = Vue.component('AdCreateUser', {
  data() {
    return {
      email: '',
      displayName: '',
      firstName: '',
      lastName: '',
      companyName: '',
      searchingForEmail: false,
      selectedProfile: '',
      emailErrors: [],
      creatingUser: false,
      roleProfiles: {
        'Vendor Pet Partner': {
          roles: [],
          department: 'Pet Partner',
        },
      },
    };
  },
  computed: {
    canCreateUser() {
      return (
        this.emailErrors.length === 0 &&
        !!this.email &&
        this.email.length > 0 &&
        !!this.displayName &&
        this.displayName.length > 0 &&
        !!this.firstName &&
        this.firstName.length > 0 &&
        !!this.lastName &&
        this.lastName.length > 0 &&
        !!this.companyName &&
        this.companyName.length > 0 &&
        !!this.selectedProfile &&
        this.selectedProfile.length > 0
      );
    },
    roleProfileKeys() {
      return Object.keys(this.roleProfiles);
    },
  },
  methods: {
    async createUser() {
      this.creatingUser = true;
      try {
        let jobTitle = '';
        if (this.selectedProfile == 'Vendor Pet Partner') {
          jobTitle = `Vendor Portal-${this.companyName.trim()}`;
        }

        if (!jobTitle || jobTitle.length === 0) {
          this.$emit('snackbar-error', {
            text: 'Job title is empty.',
            id: '77a06b1c-f38d-4338-a7c4-547b48a3fe7d',
          });
          return;
        }

        const createdUser = (
          await this.$authApi.http.post('pogona/ad/users/guest', {
            accountEnabled: true,
            displayName: this.displayName,
            firstName: this.firstName,
            lastName: this.lastName,
            userPrincipalName: this.email,
            jobTitle,
            department:
              this.selectedProfile && this.selectedProfile.length > 0
                ? this.roleProfiles[this.selectedProfile]?.department ?? []
                : [],
          })
        ).data;

        if (
          this.selectedProfile &&
          this.selectedProfile.length > 0 &&
          this.roleProfiles[this.selectedProfile]?.roles?.length > 0
        ) {
          // get roles and map them
          const appRoles = (await this.$authApi.http.get('pogona/ad/service-principal/app-roles'))
            .data;
          const rolesToMap = this.roleProfiles[this.selectedProfile].roles;

          for (const roleToMap of rolesToMap) {
            const role = appRoles.find(r => r.value === roleToMap);
            if (!role) {
              throw new Error(`Role ${role} not found`);
            }

            const roleMappings = [];
            roleMappings.push(
              this.$authApi.http.post(`pogona/ad/users/${createdUser.id}/app-roles`, {
                appRoleId: role.id,
              }),
            );
            await Promise.all(roleMappings);
          }
        }
        this.$emit('saved');
        this.creatingUser = false;
        this.clearFields();
      } catch (err) {
        this.creatingUser = false;
        this.$emit('snackbar-error', {
          text: 'Error creating user',
          err,
          id: '7412663c-620d-45f6-8b72-88ecad31ec00',
        });
      }
    },
    cancelCreateUser() {
      this.clearFields();
      this.$emit('cancel');
    },
    clearFields() {
      this.email = null;
      this.displayName = null;
      this.selectedProfile = null;
    },
    async searchForUser() {
      try {
        if (this.searchingForEmail === true && this.email && this.email.length > 0) {
          return;
        }
        this.emailErrors = [];

        this.searchingForEmail = true;
        const userResults = (
          await this.$authApi.http.get(
            `pogona/ad/users?search=${this.email ? this.email.trim() : ''}`,
          )
        ).data;
        this.searchingForEmail = false;

        if (userResults && userResults.length > 0) {
          this.emailErrors = ['User already exists'];
        }
      } catch (err) {
        this.searchingForEmail = false;
        this.$emit('snackbar-error', {
          text: 'Error searching for users',
          err,
          id: '830d48b3-9362-4639-9433-c9b4c54e931c',
        });
      }
    },
  },
});

export default AdCreateUser;
export { AdCreateUser };
</script>

<style lang="scss" scoped>
.red-text {
  color: #f44336 !important;
}
</style>
