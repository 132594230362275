<template>
  <v-row>
    <v-col>
      <data-print-dialogv3
        v-if="jsprintVersion === 3"
        v-model="innerValue"
        :pdfUrl="pdfUrl"
        :partialUrls="partialUrls"
        :pdfFileName="pdfFileName"
        :isLandscape="isLandscape"
        :numPages="numPages"
        :loading="loading"
        :disabled="disabled"
        :showPartialsCheckbox="showPartialsCheckbox"
        @printed="printed"
        @print-finished="$emit('print-finished', $event)"
        @error-blocked="errorBlocked"
      ></data-print-dialogv3>
      <data-print-dialogv4
        v-if="jsprintVersion === 4"
        v-model="innerValue"
        :pdfUrl="pdfUrl"
        :partialUrls="partialUrls"
        :pdfFileName="pdfFileName"
        :isLandscape="isLandscape"
        :numPages="numPages"
        :loading="loading"
        :disabled="disabled"
        :showPartialsCheckbox="showPartialsCheckbox"
        @printed="printed"
        @print-finished="$emit('print-finished', $event)"
        @error-blocked="errorBlocked"
      ></data-print-dialogv4>
      <data-print-dialogv5
        v-if="jsprintVersion === 5"
        v-model="innerValue"
        :pdfUrl="pdfUrl"
        :partialUrls="partialUrls"
        :pdfFileName="pdfFileName"
        :isLandscape="isLandscape"
        :numPages="numPages"
        :loading="loading"
        :disabled="disabled"
        :showPartialsCheckbox="showPartialsCheckbox"
        @printed="printed"
        @print-finished="$emit('print-finished', $event)"
        @error-blocked="errorBlocked"
      ></data-print-dialogv5>
      <data-print-dialogv6
        v-if="jsprintVersion === 6"
        v-model="innerValue"
        :pdfUrl="pdfUrl"
        :partialUrls="partialUrls"
        :pdfFileName="pdfFileName"
        :isLandscape="isLandscape"
        :numPages="numPages"
        :loading="loading"
        :disabled="disabled"
        :showPartialsCheckbox="showPartialsCheckbox"
        @printed="printed"
        @print-finished="$emit('print-finished', $event)"
        @error-blocked="errorBlocked"
      ></data-print-dialogv6>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdfUrl: {
      type: String,
      required: false,
    },
    partialUrls: {
      type: Array,
      required: false,
    },
    pdfFileName: {
      type: String,
      required: false,
    },
    isLandscape: {
      type: Boolean,
      required: false,
      default: false,
    },
    numPages: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPartialsCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      innerValue: false,
      jsprintVersion: null,
      retriedProbes: false,
      supportedVersions: [3, 4, 5, 6],
    };
  },
  computed: {
    ...mapState('app', ['useJsPrintVersion', 'username', 'storeNumber']),
  },
  async mounted() {
    await this.probeForJsPrintPorts();
  },
  methods: {
    printed(event) {
      this.$appInsights.trackEvent({
        name: 'printed',
        properties: {
          username: this.username,
          storeNumber: this.storeNumber,
          version: this.jsprintVersion,
        },
      });

      this.$emit('printed', event);
    },
    async probeForJsPrintPorts() {
      // shortcut probing
      if (
        isNaN(this.useJsPrintVersion) === false &&
        this.supportedVersions.indexOf(this.useJsPrintVersion) > -1
      ) {
        this.jsprintVersion = this.useJsPrintVersion;
        return;
      }

      const jsprintVersions = [
        {
          version: 6,
          port: 26443,
        },
        {
          version: 5,
          port: 25443,
        },
        {
          version: 4,
          port: 24443,
        },
        {
          version: 3,
          port: 23443,
        },
      ];
      for (const ver of jsprintVersions) {
        let ws = null;
        var probe = new Promise(resolveInner => {
          ws = new WebSocket(`wss://localhost:${ver.port}`);
          ws.onopen = () => {
            resolveInner({ success: true, jsprint: ver });
          };
          ws.onclose = () => {
            resolveInner({ success: false });
          };
        });

        const result = await probe;
        if (ws) {
          ws.close();
        }

        if (result.success === true) {
          this.jsprintVersion = result.jsprint.version;
          this.$store.commit('app/useJsPrintVersion', this.jsprintVersion);
        }
      }
    },
    async errorBlocked() {
      if (this.retriedProbes === false) {
        this.retriedProbes = true;
        // when this happens we try to probe for ports again
        this.jsprintVersion = null;
        this.$store.commit('app/useJsPrintVersion', null);

        await this.probeForJsPrintPorts();
      }
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.innerValue = val;
      },
    },
    innerValue: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
