<template>
  <v-app :class="$vuetify.breakpoint.smAndDown ? ['sm-and-down'] : []">
    <core-loading-modal :show="showModalLoadingValue" />
    <core-drawer v-if="!showModalLoadingValue" />
    <core-view v-if="!showModalLoadingValue" :authorized="authorized" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import menuMixins from '@/mixins/menu-mixins';

export default {
  computed: {
    ...mapState('app', ['showModalLoading']),
    ...mapState(['storeInitialized']),
    showModalLoadingValue: {
      get() {
        return this.$store.state.app.showModalLoading;
      },
    },
  },
  mixins: [menuMixins],
  watch: {
    $route(to) {
      this.authorized = this.userHasRightsToRoute(to);
    },
    storeInitialized: {
      handler: async function (val) {
        if (val === true) {
          // Ensure the user has rights to the page
          this.authorized = await this.userHasRightsToRoute(this.$route);
        }
      },
    },
  },
  data() {
    return {
      authorized: true,
    };
  },
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
