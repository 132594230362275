<template>
  <div class="d-inline-block">
    <p class="center black--text" v-if="showName">{{ progressName }}</p>
    <v-progress-circular
      :indeterminate="progress.complete === 0 && errorState === false"
      :rotate="rotate"
      :size="size"
      :width="width"
      :value="progress.complete"
      :color="progressColor"
      >{{ progressText }}</v-progress-circular
    >
    <p class="center black--text" v-if="showDescription">{{ progressDesc }}</p>
  </div>
</template>

<script>
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    rotate: {
      type: Number,
      required: false,
      default: 360,
    },
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    width: {
      type: Number,
      required: false,
      default: 15,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    showName: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValue: {
      type: Number,
      required: false,
      default: null,
    },
    closeOnError: {
      type: Boolean,
      required: false,
      default: true,
    },
    firstRecTimeout: {
      type: Number,
      required: false,
      default: 60000,
    },
    errorState: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceComplete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      progress: {
        id: '',
        name: '',
        description: '',
        complete: 0,
        additionalData: null,
        error: null,
      },
      wsConn: null,
      adalHelper: new AdalHelpers(),
    };
  },
  computed: {
    progressColor() {
      return this.progress.error || this.errorState === true ? 'red' : 'green';
    },
    progressText() {
      return this.progress.error || this.errorState === true ? 0 : this.progress.complete;
    },
    progressDesc() {
      return this.progress.description ? this.progress.description : 'Starting';
    },
    progressName() {
      return this.name ? this.name : this.progress.name;
    },
  },
  methods: {
    async create() {
      this.progress = { complete: this.initialValue || 0 };

      const adalToken = await this.adalHelper.getJwtToken(this.$authApi);

      const onOpen = () => {
        if (this.progress.complete >= 100) {
          this.wsConn.close();
        }
      };

      const onclose = () => {
        if (this.progress.complete < 100) {
          this.$emit('error', { error: 'Websocket closed before complete.' });
        }
      };

      const onmessage = event => {
        if (this.forceComplete === false) {
          const progressTmp = JSON.parse(event.data);

          this.$emit('progressUpdated', progressTmp);

          // Only update the progress if the complete % has grown, it's an error, or we were in an error state, and now we aren't
          // Sometimes these messages come in out of order.
          if (
            progressTmp.complete >= this.progress.complete ||
            progressTmp.error ||
            (progressTmp.error === null && this.progress.error !== null)
          ) {
            this.progress = progressTmp;
          }

          if (this.progress.complete >= 100) {
            // Close websocket connection
            this.wsConn.close();
          }

          if (this.progress.error) {
            this.progress.complete = 100;
            this.progress.description = 'An error has occured.';

            if (this.closeOnError === true) {
              this.wsConn.close();
            }
          }
        }
      };

      this.wsConn = new PogonaWebSocket(
        this.$apiBasePath,
        'progress',
        this.id,
        adalToken,
        onclose,
        onmessage,
        this.firstRecTimeout,
        onOpen,
      );
    },
    setToComplete() {
      try {
        this.progress.complete = 100;
        this.progress.description = 'Complete.';
      } catch (err) {
        // just swallow these
      }
    },
  },
  async created() {
    await this.create();

    if (this.forceComplete === true) {
      this.setToComplete();
    }
  },
  watch: {
    id: {
      handler: async function () {
        await this.create();
      },
    },
    initialValue: {
      handler: function (val) {
        this.progress = { complete: val };
      },
    },
    forceComplete: {
      handler: function (val) {
        if (val === true) {
          this.setToComplete();
        }
      },
    },
  },
};
</script>

<style scoped>
.center {
  text-align: center;
}
</style>
