<template>
  <core-signs-labels-view-template title="Deactivations" :showStoreNumber="false">
    <core-info-blurb title="What are deactivations?">
      These are from the "Deactivations" data import, which are global exclusions. This page allows
      you to view and make changes to the imported data. You can also map items to stores here.
      Items listed here are excluded from the store's set, and can only be added back by an admin
      user.
    </core-info-blurb>
    <v-col cols="12">
      <core-view-section title="Deactivation Items">
        <v-row>
          <v-col>
            <data-stores v-model="selectedStore" @change="storeChanged" />
          </v-col>
        </v-row>
        <v-row v-if="storeDeactivations && this.selectedStore">
          <v-col>
            <v-data-table
              v-if="storeDeactivations"
              v-model="storeDeactivations.selected"
              :headers="storeDeactivations.headers"
              :items="storeDeactivations.items"
              :loading="storeDeactivations.loading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="storeDeactivations.options"
              @update:sort-by="storeDeactivations.get"
              @click:row="storeDeactivations.itemRowMultiClicked($event, storeDeactivations)"
              @item-selected="storeDeactivations.itemRowMultiSelected($event, storeDeactivations)"
              item-key="itemId"
              class="elevation-1 scrollable"
              show-select
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="storeDeactivations"
                  :colspan="storeDeactivations.headers.length"
                ></infinite-loader>
              </template>

              <template v-slot:top>
                <v-row class="ml-2">
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-dialog v-model="newItemMappingDialog" max-width="800px">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on">Deactivate Items</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Deactivate Items</span>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="9">
                              <v-form @submit.stop.prevent="appendItemToNewMappingGrid">
                                <v-text-field
                                  v-model="newItemToMap"
                                  label="Item #"
                                  :error-messages="itemIdErrors"
                                ></v-text-field>
                              </v-form>
                            </v-col>
                            <v-col cols="3">
                              <v-btn @click="appendItemToNewMappingGrid">Deactivate Item</v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-data-table
                                :headers="[
                                  {
                                    text: 'Item #',
                                    value: 'itemId',
                                  },
                                  {
                                    text: 'Remove',
                                    value: 'remove',
                                  },
                                ]"
                                :items="newItemsToMap"
                                :disable-pagination="true"
                                :fixed-header="true"
                                item-key="itemId"
                                class="elevation-1 scrollable"
                                :show-select="false"
                              >
                                <template v-slot:item.remove="{ item }">
                                  <v-icon @click="removeNewItemFromGrid(item)">delete</v-icon>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="addItemMappings"
                            :disabled="addItemMappingsLoading"
                            :loading="addItemMappingsLoading"
                            >Save</v-btn
                          >
                          <v-btn @click="newItemMappingDialog = false">Cancel</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-dialog v-model="deleteItemMappingDialog" max-width="500">
                      <template v-slot:activator="{ on }">
                        <v-btn :disabled="storeDeactivations.selected.length === 0" v-on="on"
                          >Remove Deactivation{{ selectedItemsPlural }}</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Remove deactivation{{ selectedItemsPlural }}</span>
                        </v-card-title>
                        <v-card-text
                          >Are you sure you want to remove {{ selectedItemsCount }} deactivation{{
                            selectedItemsPlural
                          }}
                          for store {{ selectedStore }}?</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="deleteItemMappings"
                            :disabled="deleteItemMappingLoading"
                            :loading="deleteItemMappingLoading"
                            >Yes</v-btn
                          >
                          <v-btn @click="deleteItemMappingDialog = false">No</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <infinite-paganation :pogonaTable="storeDeactivations"></infinite-paganation>
          </v-col>
        </v-row>
      </core-view-section>
    </v-col>
  </core-signs-labels-view-template>
</template>

<script>
import f from 'odata-filter-builder';
import PogonaDataTable from '@/utils/PogonaDataTable';

export default {
  data() {
    return {
      selectedStore: '',
      storeDeactivations: null,
      newItemMappingDialog: false,
      addItemMappingsLoading: false,
      newItemsToMap: [],
      newItemToMap: null,
      itemIdErrors: [],
      deleteItemMappingDialog: false,
      deleteItemMappingLoading: false,
    };
  },
  computed: {
    selectedItemsCount() {
      return this.storeDeactivations.allSelected ? 'all' : this.storeDeactivations.selected.length;
    },
    selectedItems() {
      return this.storeDeactivations.selected;
    },
    selectedItemsPlural() {
      return this.storeDeactivations &&
        (this.storeDeactivations.selected.length === 0 ||
          this.storeDeactivations.selected.length > 1)
        ? 's'
        : '';
    },
  },
  methods: {
    async deleteItemMappings() {
      try {
        this.deleteItemMappingLoading = true;
        if (this.storeDeactivations.allSelected === true) {
          await this.$authApi.http.delete(
            `label/storeexclusionsglobal/stores/${this.selectedStore}/all`,
          );
        } else {
          await this.$authApi.http.post(
            `label/storeexclusionsglobal/stores/${this.selectedStore}/delete`,
            this.storeDeactivations.selected,
          );
        }

        this.deleteItemMappingLoading = false;
        this.deleteItemMappingDialog = false;
        await this.initStoreDeactivations();
      } catch (err) {
        this.deleteItemMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error deleting item mappings for store.',
          err,
          id: 'afec7bfd-bbfc-4720-910b-b0ac4b5f685a',
        });
      }
    },
    async storeChanged() {
      await this.initStoreDeactivations();
    },
    async addItemMappings() {
      try {
        this.addItemMappingsLoading = true;

        await this.$authApi.http.post(
          `label/storeexclusionsglobal/stores/${this.selectedStore}`,
          this.newItemsToMap,
        );

        this.addItemMappingsLoading = false;
        this.newItemMappingDialog = false;
        this.newItemsToMap = [];
        await this.initStoreDeactivations();
      } catch (err) {
        this.addItemMappingsLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error adding new item global exclusions',
          err,
          id: '483a1020-2ad0-4828-a805-e70e735d01ff',
        });
      }
    },
    removeNewItemFromGrid(item) {
      if (this.newItemsToMap) {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in this.newItemsToMap) {
          if (item.itemId === this.newItemsToMap[i].itemId) {
            // splice out store number
            this.newItemsToMap.splice(i, 1);
          }
        }
      }
    },
    appendItemToNewMappingGrid() {
      this.itemIdErrors = [];
      if (this.newItemToMap) {
        // clean item string
        const cleanedItem = this.newItemToMap.trim();

        if (isNaN(cleanedItem) === true || cleanedItem.length === 0) {
          this.itemIdErrors.push(`Item number ${cleanedItem} is not valid.`);
          return;
        }

        // scan items if already exists, don't add
        let exists = false;
        this.newItemsToMap.forEach(x => {
          if (x.itemId === cleanedItem) {
            exists = true;
          }
        });

        this.storeDeactivations.items.forEach(x => {
          if (x.itemId === cleanedItem) {
            exists = true;
          }
        });

        if (exists === false) {
          // add to grid
          this.newItemsToMap.push({ itemId: cleanedItem });
        } else {
          this.itemIdErrors.push(`Item number ${cleanedItem} already mapped`);
        }
        // clear text box
        this.newItemToMap = null;
      }
    },
    async initStoreDeactivations() {
      this.storeDeactivations = new PogonaDataTable({
        headers: [
          {
            text: 'Store Number',
            value: 'storeNumber',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
        ],
        baseUrl: 'label/storeexclusionsglobal',
        defaultFilter: f('and').eq('storeNumber', this.selectedStore),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['itemId'] },
        isInfinite: true,
      });

      await this.storeDeactivations.get();
    },
  },
};
</script>
