<template>
  <div>
    <v-menu
      ref="tourMenu"
      v-model="showTour"
      absolute
      :allow-overflow="false"
      :close-on-content-click="false"
      :close-on-click="false"
      :origin="currPlacement"
    >
      <v-list class="pa-0 ma-0 tour-list">
        <v-list-item class="pa-0 ma-0">
          <v-icon v-show="showTour" id="arrow" :class="arrowClasses">{{ arrowIcon }}</v-icon>
          <v-card
            dark
            :max-width="maxWidth"
            min-width="10em"
            id="tourContent"
            :class="arrowClasses"
          >
            <v-card-title>{{ stepTitle }}</v-card-title>
            <v-card-subtitle>{{ stepSubtitle }}</v-card-subtitle>
            <v-card-text>{{ stepText }}</v-card-text>
            <video
              ref="videoPlayer"
              class="pa-2"
              width="100%"
              height="100%"
              v-if="stepVideos && stepVideos.length > 0"
              controls
              crossorigin
              :autoplay="autoplay"
              :muted="autoplay"
            >
              <source
                v-for="(video, vix) of stepVideos"
                :src="video.url"
                :type="video.type"
                :key="vix"
              />
              <track
                v-for="(sub, six) of stepSubtitles"
                :key="six"
                :src="sub.url"
                :label="sub.label"
                :kind="sub.kind || 'subtitles'"
                :srclang="sub.lang || 'en'"
                :default="sub.default || six === 0 ? 'default' : null"
              />
            </video>
            <v-card-actions>
              <v-container fluid>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-row class="ma-0 pa-0" align="end" justify="start">
                    <slot name="actions">
                      <v-btn
                        v-if="currStep === 0 && !(currStep === totalSteps - 1)"
                        @click="
                          showTooltip = false;
                          showTour = false;
                        "
                        >Skip</v-btn
                      >

                      <v-btn
                        v-if="currStep > 0 && totalSteps > 1"
                        @click="
                          currStep -= 1;
                          setStep();
                        "
                        >Previous</v-btn
                      >

                      <v-btn
                        v-if="currStep < totalSteps - 1"
                        @click="
                          currStep += 1;
                          setStep();
                        "
                        >Next</v-btn
                      >

                      <v-btn
                        v-if="currStep === totalSteps - 1"
                        @click="
                          showTooltip = false;
                          showTour = false;
                          currentStep = 0;
                        "
                        >Finish</v-btn
                      >
                    </slot>
                    <slot name="doNotShow">
                      <v-checkbox
                        dark
                        v-model="doNotShowCheckbox"
                        @change="doNotShow"
                        v-if="hideDoNotShowCheckbox === false"
                        label="Do not show again"
                        class="ml-3 do-not-show-again"
                      ></v-checkbox>
                    </slot>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-list>
    </v-menu>
    <div>
      <slot name="showTour">
        <v-icon
          class="help-again-clickable"
          @click="
            currStep = 0;
            setStep();
            showTour = true;
          "
          v-if="!showTour && hideHelpIcon === false"
          >help_outline</v-icon
        >
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  //
  // NOTE: you must create an action, mutation, and state object called <tourName>
  // for the "do not show again" checkbox to work
  //
  data: function () {
    return {
      currStep: this.currentStep,
      totalSteps: this.steps.length,
      showTooltip: this.show,
      stepText: '',
      stepTitle: '',
      stepSubtitle: '',
      stepVideos: [],
      stepSubtitles: [],
      currPlacement: this.placement,
      doNotShowCheckbox: false,
      disabledFromStore: false,
      videoLookup: [],
      isinit: false,
      showTour: false,
      arrowIcon: 'arrow_drop_down',
      arrowClasses: '',
      hasMoved: false,
    };
  },
  props: {
    steps: {
      type: Array,
      default: null,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    currentStep: {
      type: Number,
      default: 0,
      required: false,
    },
    placement: {
      type: String,
      default: 'left',
      required: false,
    },
    offset: {
      type: Array,
      default: null,
      required: false,
    },
    maxWidth: {
      type: String,
      default: '300px',
      required: false,
    },
    tourName: {
      type: String,
      default: null,
      required: true,
    },
    hideDoNotShowCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideHelpIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
      required: false,
    },
    scrollFirstStep: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  methods: {
    doNotShow() {
      this.$store.commit(`tours/${this.tourName}`, this.doNotShowCheckbox === false);
    },
    sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    async setStep() {
      if (this.steps[this.currStep]) {
        const step = this.steps[this.currStep];

        if (this.hasMoved === false) {
          this.hasMoved = this.currStep > 0;
        }

        if (step.placement) {
          this.currPlacement = step.placement;
        }
        if (step.content) {
          this.stepText = step.content;
        } else {
          this.stepText = '';
        }

        if (this.stepVideos.length > 0 && this.$refs.videoPlayer && this.$refs.videoPlayer.pause) {
          this.$refs.videoPlayer.pause();
        }

        this.stepVideos = [];
        const video = this.videoLookup[step.video];
        if (video && video.videos.length > 0) {
          // fill videos
          video.videos.forEach(v => {
            this.stepVideos.push({
              url: `${this.$storageHost}/videos/${video.videoGuid}/${v.fileName}`,
              type: v.mimeType,
            });
          });

          // fill subtitles if any
          if (video.videoSubtitles && video.videoSubtitles.length > 0) {
            video.videoSubtitles.forEach(s => {
              this.stepSubtitles.push({
                url: `${this.$storageHost}/videos/${video.videoGuid}/${s.fileName}`,
                label: s.label,
                kind: s.kind,
                lang: s.language,
                default: s.isDefault,
              });
            });
          }
        } else {
          this.stepVideos = [];
          this.stepSubtitles = [];
        }

        if (step.header && step.header.title) {
          this.stepTitle = step.header.title;
        } else {
          this.stepTitle = '';
        }

        if (step.header && step.header.subtitle) {
          this.stepSubtitle = step.header.subtitle;
        } else {
          this.stepSubtitle = '';
        }

        this.$refs.tourMenu.activate();

        this.$nextTick(() => {
          if (this.stepVideos.length > 0 && this.$refs.videoPlayer && this.$refs.videoPlayer.load) {
            this.$refs.videoPlayer.load();

            if (this.$refs.videoPlayer.play) {
              try {
                this.$refs.videoPlayer.play();
              } catch (err) {
                // swallow the error
              }
            }
          }
        });

        // this needs to be done on a different "thread" to get the size of the tour box
        setTimeout(async () => {
          let targetCords = document.querySelector(step.target).getBoundingClientRect();
          if (Math.abs(targetCords.y) > 50) {
            if (
              this.scrollFirstStep === true ||
              this.hasMoved === true ||
              (this.scrollFirstStep === false && this.currStep > 0)
            )
              window.scrollTo(0, targetCords.y);
          }

          targetCords = document.querySelector(step.target).getBoundingClientRect();

          let tourWidth = 0;
          let tourHeight = 0;
          let tries = 0;
          do {
            const tourContent = document.querySelector('#tourContent');
            if (tourContent) {
              tourWidth = tourContent.offsetWidth;
              tourHeight = tourContent.offsetHeight;
            }
            tries += 1;
            if (tourWidth === 0 || tourHeight === 0) {
              // eslint-disable-next-line no-await-in-loop
              await this.sleep(100);
            }
          } while ((tourWidth === 0 || tourHeight === 0) && tries < 16);

          let extraX = 0;
          let extraY = 0;

          // set the arrow icon
          if (this.currPlacement.indexOf('top') === 0) {
            this.arrowIcon = 'arrow_drop_down';
            this.arrowClasses = 'top';
            extraY = -(tourHeight + targetCords.width / 4);
            extraX = -(tourWidth / 2) + targetCords.width / 2;
          } else if (this.currPlacement.indexOf('bottom') === 0) {
            extraY = targetCords.height;
            extraX = -(tourWidth / 2) + targetCords.width / 2;
            this.arrowIcon = 'arrow_drop_up';
            this.arrowClasses = 'bottom';
          } else if (this.currPlacement.indexOf('left') === 0) {
            this.arrowIcon = 'arrow_right';
            this.arrowClasses = 'left';
            extraY = -(tourHeight / 2.5);
            extraX = -(targetCords.width / 2 + tourWidth);
          } else if (this.currPlacement.indexOf('right') === 0) {
            this.arrowIcon = 'arrow_left';
            this.arrowClasses = 'right';
            extraY = -(tourHeight / 2.5);
            extraX = targetCords.width;
          }

          this.$refs.tourMenu.absoluteX = targetCords.x + extraX;
          this.$refs.tourMenu.absoluteY = targetCords.y + extraY;

          this.$refs.tourMenu.activate();
        }, 100);
      }
    },
  },
  created() {
    if (this.hideDoNotShowCheckbox === true) {
      // make sure the tour is shown
      this.$store.commit(`tours/${this.tourName}`, true);
    }

    this.disabledFromStore = this.$store.state.tours[this.tourName] === false;
    this.doNotShowCheckbox = this.disabledFromStore;
  },
  mounted() {
    if (this.steps && this.steps.length > 0) {
      // get the video metadata
      const videoGets = [];
      const videoLookupTmp = [];
      this.steps.forEach(v => {
        if (v.video && v.video.length > 0) {
          const videoGet = this.$authApi.http.get(`pogona/videomaster/${v.video}/view`);
          videoGets.push(videoGet);
          videoLookupTmp.push(v.video);
        }
      });

      this.videoLookup = [];
      // wait for everything to finish
      Promise.all(videoGets)
        .then(d => {
          // eslint-disable-next-line no-restricted-syntax
          let ix = 0;
          // eslint-disable-next-line no-restricted-syntax
          for (const response of d) {
            const m = response.data;
            if (m && m.videos && m.videos.length > 0) {
              let webmVideo = null;
              const otherVideos = [];
              // eslint-disable-next-line no-restricted-syntax
              for (const video of m.videos) {
                // does it end with .webm?
                if (video.fileName.indexOf('.webm') === video.fileName.length - 5) {
                  webmVideo = video;
                } else {
                  otherVideos.push(video);
                }
              }

              const videos = [];
              if (webmVideo) {
                videos.push(webmVideo);
              }
              if (otherVideos && otherVideos.length > 0) {
                videos.push(...otherVideos);
              }

              m.videos = videos;

              this.videoLookup[videoLookupTmp[ix]] = m;
              ix += 1;
            }
          }
        })
        .finally(() => {
          this.isinit = true;
          this.showTour = this.showTooltip && this.disabledFromStore === false;
          if (this.showTour === true) {
            this.setStep();
          }
        });
    } else {
      this.isinit = true;
      this.showTour = this.showTooltip && this.disabledFromStore === false;
      // eslint-disable-next-line no-lonely-if
      if (this.showTour === true) {
        this.setStep();
      }
    }
  },
  watch: {
    currentStep: {
      handler: function (val) {
        this.currStep = val;
        this.setStep();
      },
    },
    show: {
      handler: function (val) {
        if (val === true) {
          this.setStep();

          if (this.disabledFromStore === false) {
            this.showTour = true;
          }
        } else {
          this.showTooltip = val;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
#arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  font-size: 4em;
  color: #424242;
  z-index: 200;
}

#arrow.top {
  bottom: 7px;
  left: 50%;
}

#tourContent.top {
  margin-bottom: 16px;
}

#arrow.bottom {
  top: 7px;
  left: 50%;
}

#tourContent.bottom {
  margin-top: 16px;
}

#arrow.left {
  right: 7px;
  bottom: 50%;
}

#tourContent.left {
  margin-right: 16px;
}

#arrow.right {
  left: 7px;
  bottom: 50%;
}

#tourContent.right {
  margin-left: 16px;
}

.theme--dark.v-card {
  background-color: #424242;
}

#tooltip {
  z-index: 2;
}

.v-card__text,
.v-card__subtitle {
  color: $white !important;
}

.help-again-clickable {
  cursor: pointer;
}

div[role='menu'] {
  overflow: hidden !important;
}

.tour-list {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: transparent !important;
}

.v-menu__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: transparent !important;
}

.do-not-show-again {
  vertical-align: top;
  margin-top: -10px;
}
</style>
