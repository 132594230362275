import axios from 'axios';
import AzureAdConfig from '@/data/AzureAdConfig';

export class AppConfig {
  constructor() {
    this.azureAdConfig = new AzureAdConfig();
    this.apiBasePath = '';
    this.publicPath = '';
    this.routerMode = '';
    this.maxConcurrentRequests = '';
  }
}

export let appConfig = null;
export default appConfig;

// eslint-disable-next-line wrap-iife
export const initAppConfig = (async function getAppConfig() {
  if (appConfig == null) {
    const response = await axios.get(
      `${process.env.BASE_URL}appconfig.json?_=${new Date().getTime()}`,
    );
    appConfig = response.data;
  }
  return Promise.resolve(appConfig);
})();
