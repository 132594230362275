<template>
  <core-view-template title="Divisions / Regions">
    <v-row class="pl-3">
      <v-col cols="4">
        <manage-division-region-popup
          :UseDefaultStyles="false"
          @save-complete="saveComplete"
          buttonText="Manage Divisions/Regions"
        />
      </v-col>
      <v-col cols="3">
        <pcl-employee @save-complete="saveComplete" />
      </v-col>
      <v-col>
        <v-btn @click="downloadClicked">Download</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-progress-linear indeterminate v-if="loading" />
    </v-row>
    <v-row v-for="dr in divisionRegions" :key="dr.DivisionRegionId">
      <v-col cols="12">
        <division-region-display-card
          :divreg="dr"
          :expanded="dr === divisionRegions[0]"
          @save-complete="saveComplete"
        >
        </division-region-display-card>
      </v-col>
    </v-row>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import DivisionRegionDisplayCard from '@/components/pcl/DivisionRegionDisplayCard.vue';
import PclEmployee from '@/components/pcl/PclEmployee.vue';
import * as pcldto from '@psp/pcldto';
import ManageDivisionRegionPopup from '@/components/pcl/ManageDivisionRegionPopup.vue';
import ManageDistrictPopup from '@/components/pcl/ManageDistrictPopup.vue';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {
    DivisionRegionDisplayCard,
    PclEmployee,
    ManageDivisionRegionPopup,
    ManageDistrictPopup,
  },
})
export default class DivisionRegionHome extends Vue {
  @pclModule.State(ps.divisionRegions) divisionRegions!: pcldto.pclDivisionRegionWithRelated[];

  private loading = false;

  @pclModule.Action(pa.getDivsionRegions) getDivsionRegions!: (
    drId: number | null,
  ) => Promise<pcldto.pclDivisionRegionWithRelated[]>;
  @pclModule.Action(pa.getRoleNames) getRoleNames!: () => Promise<pcldto.pclRoleName[]>;
  @pclModule.Action(pa.downloadDivisionRegion) downloadDivisionRegion!: () => Promise<unknown>;

  downloadClicked() {
    this.loading = true;
    this.downloadDivisionRegion().finally(() => (this.loading = false));
  }

  private saveComplete() {
    this.loading = true;
    this.getDivsionRegions(null).finally(() => (this.loading = false));
  }

  public mounted() {
    this.loading = true;
    this.getRoleNames().then(() => this.getDivsionRegions(null).then(() => (this.loading = false)));
  }
}
</script>
