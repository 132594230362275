const pm = {
  begin(key, value, json, options) {
    if (!json) {
      return null;
    }

    const maxDepth = (options && options.maxDepth) || 10;
    const continueAfterFirst = (options && options.continueAfterFirst) || false;

    return pm.recurseJson(key, value, json, maxDepth, continueAfterFirst, 0, []);
  },

  // eslint-disable-next-line consistent-return
  recurseJson(key, value, json, maxDepth, continueAfterFirst, currentDepth, foundValues) {
    if (currentDepth >= maxDepth) {
      return foundValues;
    }

    const strValue = value ? JSON.stringify(value) : null;

    const keys = Object.keys(json);
    // eslint-disable-next-line no-restricted-syntax
    for (const k of keys) {
      if (k === key && ((value && strValue === JSON.stringify(json[k])) || !value)) {
        if (value) {
          foundValues.push(json);
        } else {
          foundValues.push(json[k]);
        }

        if (continueAfterFirst === false) {
          return foundValues;
        }
      }

      if (typeof json[k] === 'object') {
        pm.recurseJson(
          key,
          value,
          json[k],
          maxDepth,
          continueAfterFirst,
          currentDepth + 1,
          foundValues,
        );
      }
    }
    return foundValues;
  },
};

export default class JsonHelpers {
  static findKeyValues(key, json, options) {
    return pm.begin(key, null, json, options);
  }

  static findKeyAndValue(key, value, json, options) {
    return pm.begin(key, value, json, options);
  }
}
