<template>
  <v-row>
    <v-col>
      <v-row align="start" justify="start">
        <v-col cols="12" xl="9" lg="9" md="9">
          <v-text-field @click:clear="emit('search-cleared')" v-debounce:300="debounce" prepend-icon="search"
            v-model="handHeldSearchR" label="Search (desc, batch ID, UPC, item, or item desc)"
            :clearable="true"></v-text-field>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3">
          <v-checkbox v-model="completedR" label="Completed" prepend-icon="playlist_add_check" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table v-if="handHeldHeaderData" v-model="selectedR" :headers="handHeldHeaderDataHeaders"
            :items="handHeldHeaderDataItems" :single-select="true" :loading="handHeldHeaderDataLoading"
            :items-per-page="50" :fixed-header="true" @click:row="emit('row-clicked', $event)"
            @item-selected="emit('item-selected', $event)" @update:sort-by="handHeldHeaderData.get()"
            item-key="handHeldMainId" show-select class="elevation-1 scrollable" :disable-pagination="true">
            <template v-slot:body.append>
              <infinite-loader :pogonaTable="handHeldHeaderData"
                :colspan="handHeldHeaderDataHeadersLength"></infinite-loader>
            </template>

            <template v-slot:item.headerDate="{ item }">
              {{ item.headerDate | formatDate }}
            </template>

            <template v-slot:item.description="{ item }">
              {{ item.automaticallyPickLabelType ? 'Automatic' : item.description }}
            </template>

            <template v-slot:item.setToCompleted="{ item }">
              <slot name="setToCompleted" v-bind="{ item }"></slot>
            </template>

            <template v-slot:item.labelType="{ item }">
              <v-autocomplete :value="item.labelType" :items="handHeldTypeToSvgTemplates" :loading="updatingLabelType"
                :disabled="updatingLabelType" @change="handHeldTypeToSvgTemplateUpdate($event, item.handHeldMainId)"
                autocomplete="off" :item-text="x => `${x.labelType} - ${x.description}`" item-value="labelType">
              </v-autocomplete>
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="handHeldHeaderData"></infinite-paganation>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import Vue, { ref, onMounted, defineComponent, defineProps, watch, computed } from 'vue';

defineComponent({
  name: 'HandHeldDataTable',
});

const emit = defineEmits([
  'update:handHeldSearch',
  'update:completed',
  'update:selected',
  'get-data',
  'search-cleared',
  'row-clicked',
  'item-selected',
  'snackbar-error',
]);

const handHeldSearchR = ref<string | undefined>('');
const completedR = ref(false);
const handHeldHeaderData = ref<any>({});
const selectedR = ref<any>([]);
const handHeldTypeToSvgTemplates = ref<any>([]);
const updatingLabelType = ref(false);

const props = defineProps({
  modelValue: null,
  handHeldSearch: {
    type: String,
    required: false,
  },
  completed: {
    type: Boolean,
    required: false,
  },
  selected: [],
});

onMounted(async () => {
  handHeldHeaderData.value = props.modelValue;
  handHeldSearchR.value = props.handHeldSearch;
  completedR.value = props.completed;
  await getHandheldSvgTemplates();
});

async function getHandheldSvgTemplates() {
  if (Vue.prototype.$authApi) {
    handHeldTypeToSvgTemplates.value = (
      await Vue.prototype.$authApi.http.get(
        'label/handheldtypetosvgtemplate/extended?$top=99999&$orderby=labelType',
      )
    ).data;
  }
}

async function handHeldTypeToSvgTemplateUpdate(labelType, handHeldMainId) {
  updatingLabelType.value = true;
  try {
    await Vue.prototype.$authApi.http.patch(`label/handheldmain/${handHeldMainId}`, {
      labelType,
    });
  } catch (err) {
    emit('snackbar-error', {
      err,
      text: 'Error updating label type.',
      id: 'f9a98c16-4af0-4da8-bf97-381fa3b89395',
    });
  } finally {
    updatingLabelType.value = false;
  }
}

const handHeldHeaderDataHeadersLength = computed(() => {
  return handHeldHeaderData.value?.headers?.length ?? 0;
});

const handHeldHeaderDataItems = computed(() => {
  if (handHeldHeaderData.value && handHeldHeaderData.value.items) {
    return handHeldHeaderData.value.items;
  }
  return [];
});

const handHeldHeaderDataHeaders = computed(() => {
  if (handHeldHeaderData.value && handHeldHeaderData.value.headers) {
    return handHeldHeaderData.value.headers;
  }
  return [];
});

const handHeldHeaderDataLoading = computed(() => {
  if (handHeldHeaderData.value && handHeldHeaderData.value.loading) {
    return handHeldHeaderData.value.loading;
  }
  return false;
});

watch(
  () => props.modelValue,
  val => {
    handHeldHeaderData.value = val;
  },
  { deep: true },
);

watch(
  () => handHeldSearchR.value,
  val => {
    emit('update:handHeldSearch', val);
  },
);

watch(
  () => completedR.value,
  val => {
    emit('update:completed', val);
    emit('get-data');
  },
);

watch(
  () => props.selected,
  val => {
    selectedR.value = val;
  },
);

watch(
  () => selectedR.value,
  val => {
    emit('update:selected', val);
  },
);

function debounce() {
  emit('get-data');
}
</script>
