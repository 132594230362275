<template>
  <v-col cols="12">
    <v-form @submit.stop.prevent="searchForUsers">
      <v-row>
        <v-col cols="10">
          <v-text-field
            prepend-icon="search"
            v-model="userSearch"
            label="Email, first, or last name"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn @click="searchForUsers" :disabled="usersSearchDisabled">Search</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <v-data-table
          v-if="users"
          v-model="users.selected"
          :headers="users.headers"
          :items="users.items"
          :loading="users.loading"
          :items-per-page="999"
          :disable-pagination="true"
          :fixed-header="true"
          :options.sync="users.options"
          @update:sort-by="() => {}"
          @click:row="users.itemRowSingleClicked($event, users)"
          @item-selected="users.itemRowSingleSelected($event, users)"
          item-key="id"
          class="elevation-1 scrollable"
          :show-select="true"
          :single-select="true"
        >
          <template v-slot:item.mail="{ item }">{{
            item.mail && item.mail.trim().length > 0 ? item.mail : item.userPrincipalName
          }}</template>

          <template v-slot:item.isGroup="{ value }">{{ value ? 'Yes' : 'No' }}</template>

          <template v-slot:item.viewGroups="{ item }">
            <v-btn v-if="item.isGroup === false" @click="viewGroupsForUser(item)"
              >View Mapped Groups</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="viewGroupsDialog && viewGroupsUser">
      <v-dialog v-model="viewGroupsDialog" max-width="900">
        <v-card>
          <v-card-title>
            <span class="headline">Mapped Groups for {{ viewGroupsUser.displayName }}</span>
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="groupsForUser"
              v-model="groupsForUser.selected"
              :headers="groupsForUser.headers"
              :items="groupsForUser.items"
              :loading="groupsForUser.loading"
              :items-per-page="999"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="groupsForUser.options"
              item-key="id"
              class="elevation-1 scrollable"
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="viewGroupsDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import PogonaDataTable from '@/utils/PogonaDataTable';
import Vue from 'vue';

const AdUsersSearch = Vue.component('AdUsersSearch', {
  props: {
    includeGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      userSearch: null,
      users: null,
      viewGroupsDialog: false,
      viewGroupsUser: null,
      groupsForUser: null,
    };
  },
  created() {
    this.users = new PogonaDataTable({
      headers: [
        {
          text: 'Display Name',
          value: 'displayName',
        },
        {
          text: 'First Name',
          value: 'givenName',
        },
        {
          text: 'Last Name',
          value: 'surname',
        },
        {
          text: 'Job Title',
          value: 'jobTitle',
        },
        {
          text: 'Email',
          value: 'mail',
        },
      ],
      baseUrl: `pogona/ad/users${this.includeGroups ? 'andgroups' : ''}`,
      httpClient: this.$authApi.http,
      options: { itemsPerPage: 999, sortBy: ['displayName'] },
      isInfinite: true,
    });

    if (this.includeGroups === true) {
      this.users.headers.push({ text: 'Is Group', value: 'isGroup' });
      this.users.headers.push({
        text: 'View Groups',
        value: 'viewGroups',
        sortable: false,
      });
    }

    this.users.on('error', err => {
      this.$emit('snackbar-error', {
        text: 'Error getting users',
        err,
        id: '6ef82e68-bda4-4b45-ae69-fa5975acb0c2',
      });
    });
    this.users.loading = false;
  },
  methods: {
    async searchForUsers() {
      if (this.usersSearchDisabled === true) {
        return;
      }

      try {
        this.users.selected = [];
        this.users.loading = true;
        const userResults = (
          await this.$authApi.http.get(
            `pogona/ad/users${this.includeGroups ? 'andgroups' : ''}?search=${
              this.userSearch ? this.userSearch.trim() : ''
            }`,
          )
        ).data;
        this.users.items = userResults;
        this.users.loading = false;
      } catch (err) {
        this.users.loading = false;
        this.$emit('snackbar-error', {
          text: 'Error searching for users',
          err,
          id: '2cb9ade6-dc74-4f08-ba72-1307ba7be5fe',
        });
      }
    },
    async viewGroupsForUser(item) {
      this.viewGroupsUser = item;
      this.viewGroupsDialog = true;

      this.groupsForUser = new PogonaDataTable({
        headers: [
          {
            text: 'Display Name',
            value: 'displayName',
          },
          {
            text: 'Description',
            value: 'description',
          },
        ],
        baseUrl: `pogona/ad/users/${item.id}/memberOf`,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 999, sortBy: ['displayName'] },
        isInfinite: true,
      });

      this.groupsForUser.loading = true;

      try {
        this.groupsForUser.items = (
          await this.$authApi.http.get(`pogona/ad/users/${item.id}/memberOf`)
        ).data;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error geeting groups for user',
          err,
          id: 'a006beb7-6748-440b-ab04-c64c21e80ed4',
        });
      }

      this.groupsForUser.loading = false;
    },
  },
  computed: {
    usersSearchDisabled() {
      return !this.userSearch || this.userSearch.trim().length < 3;
    },
  },
  watch: {
    'users.selected': {
      handler: function (val) {
        if (val && val.length === 1) {
          this.$emit('input', val[0]);
        } else {
          this.$emit('input', null);
        }
      },
    },
  },
});

export default AdUsersSearch;
export { AdUsersSearch };
</script>
