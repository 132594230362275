export default {
  methods: {
    handleToolbarHashToBlobUrl(blobUrl, printDialogActive) {
      let finalBlobUrl = blobUrl + (printDialogActive ? '#toolbar=0' : '');

      // if the print dialog isn't active, and it has the hash, remove it
      if (printDialogActive === false) {
        finalBlobUrl = finalBlobUrl.replace('#toolbar=0', '');
      }
      return finalBlobUrl;
    },
  },
};
