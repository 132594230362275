import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  batchInfoItems: set('batchInfoItems'),
  batchInfo: set('batchInfo'),
  selectedBatchInfoTypes: set('selectedBatchInfoTypes'),
  completedBatchInfos: set('completedBatchInfos'),
  selectedBatchInfo: set('selectedBatchInfo'),
  retailStartDate: set('retailStartDate'),
  search: set('search'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
  showZeroApproved: set('showZeroApproved'),
};
