<template>
  <v-container>
    <v-main v-if="!showValidationErrors && !showValidationReview && !showProcessing">
      <v-row>
        <v-col>
          <v-file-input
            v-model="file"
            label="Click to choose partner funding Excel file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn :loading="validating" @click="uploadClicked" :disabled="!file || validating"
            >Upload Excel File</v-btn
          >
        </v-col>
        <v-col>
          <v-btn @click="showUploadsClick">Show Uploads</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="downloadExcelTemplate">Download Excel Template</v-btn>
        </v-col>
      </v-row>
    </v-main>
    <partner-funding-validation-errors v-if="showValidationErrors" />
    <partner-funding-validation-review v-if="showValidationReview" />
    <partner-funding-import-process v-if="showProcessing" />
  </v-container>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pfa from '@/store/modules/partnerfunding/actions';
import * as pfs from '@/store/modules/partnerfunding/state';
import * as pfm from '@/store/modules/partnerfunding/mutations';
import { PartnerFundingValidationResult } from '@psp/pogona_ecom_client_js';
import PartnerFundingValidationErrors from './PartnerFundingValidationErrors.vue';
import PartnerFundingValidationReview from './PartnerFundingValidationReview.vue';
import PartnerFundingImportProcess from './PartnerFundingImportProcess.vue';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pfModule = namespace('partnerfunding');

@Component({
  components: {
    PartnerFundingValidationErrors,
    PartnerFundingValidationReview,
    PartnerFundingImportProcess,
  },
})
export default class PartnerFundingUpload extends Vue {
  @pfModule.Action(pfa.validatePartnerFundingExcelFile)
  validatePartnerFundingExcelFile!: (payload: File) => Promise<any>;
  @pfModule.Action(pfa.exportPartnerFundingTemplate)
  exportPartnerFundingTemplate!: () => Promise<any>;
  @pfModule.Mutation(pfm.setShowProcessing) setShowProcessing!: (payload: boolean) => void;
  @pfModule.State(pfs.validationData) validationData!: PartnerFundingValidationResult | null;
  @pfModule.State(pfs.showProcessing) showProcessing!: boolean;

  private file: File | null = null;
  private validating = false;

  get showValidationReview(): boolean {
    return (
      !this.showProcessing &&
      this.validationData !== null &&
      this.validationData?.Errors?.length == 0
    );
  }

  get showValidationErrors(): boolean {
    return (
      !this.showProcessing && this.validationData !== null && this.validationData.Errors?.length > 0
    );
  }

  private downloadExcelTemplate() {
    this.exportPartnerFundingTemplate();
  }

  private showUploadsClick() {
    this.setShowProcessing(true);
  }

  private uploadClicked() {
    this.validating = true;
    if (this.file) {
      this.validatePartnerFundingExcelFile(this.file).finally(() => {
        this.validating = false;
        this.file = null;
      });
    }
  }
}
</script>
