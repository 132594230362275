<template>
  <v-footer class="my-0 py-0 paganation-footer" v-if="pogonaTable && pogonaTable.items">
    <v-col>
      <v-row justify="end">{{ pogonaTable.items.length }} of {{ pogonaTable.totalItems }}</v-row>
    </v-col>
  </v-footer>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    pogonaTable: {
      type: Object,
      default: null,
    },
  },
});
</script>

<style lang="scss">
.paganation-footer {
  background-color: #f5f5f5 !important;
}
</style>
