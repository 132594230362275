<template>
  <v-dialog max-width="340" v-model="internalStartDateDialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn
          v-on="on"
          @click="
            internalStartDateDialog = true;
            printImmediatelyClicked = false;
          "
          :loading="queueLoading"
          :disabled="queueDisabled"
          class="mr-4"
          >{{ queueBtnText }}</v-btn
        >
        <v-btn
          v-if="createdBatchInfo !== undefined"
          v-on="on"
          @click="
            internalStartDateDialog = true;
            printImmediatelyClicked = true;
          "
          :loading="queueLoading"
          :disabled="queueDisabled"
          class="green"
          >{{ printImmediatelyBtnText }}</v-btn
        >
      </slot>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Choose a start date</span>
      </v-card-title>
      <v-col cols="12">
        <v-row>
          <v-date-picker class="mt-2 mx-auto" v-model="date"></v-date-picker>
        </v-row>

        <v-row class="mt-4">
          <slot></slot>
        </v-row>

        <v-row v-if="showCasePricing">
          <v-checkbox
            v-model="useCasePricingVal"
            label="Use Case Pricing"
            class="ml-8 mb-0 mt-1 py-0"
          ></v-checkbox>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="actions">
            <v-btn
              :class="[printImmediatelyClicked ? 'green' : '']"
              @click="queueCallback({ printImmediately: printImmediatelyClicked })"
              >{{ printImmediatelyClicked ? printImmediatelyBtnText : queueDialogBtnText }}</v-btn
            >
            <v-btn @click="internalStartDateDialog = false">Cancel</v-btn>
          </slot>
        </v-card-actions>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';

const RetailStartDateDialog = Vue.component('retail-start-date-dialog', {
  props: {
    queueLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    queueDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    startDateDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    queueCallback: {
      type: Function,
      default() {
        return {};
      },
      required: false,
    },
    value: {
      type: String,
      default() {
        return moment().startOf('day').format('YYYY-MM-DD');
      },
      required: true,
    },
    queueBtnText: {
      type: String,
      default: 'Queue Labels',
      required: false,
    },
    printImmediatelyBtnText: {
      type: String,
      default: 'Quick Render',
      required: false,
    },
    queueDialogBtnText: {
      type: String,
      default: 'Queue',
      required: false,
    },
    createdBatchInfo: {
      type: Object,
      default: undefined,
      required: false,
    },
    showCasePricing: {
      type: Boolean,
      default: false,
      required: false,
    },
    useCasePricing: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      internalStartDateDialog: this.startDateDialog,
      printImmediatelyClicked: false,
      date: this.value,
      useCasePricingVal: this.useCasePricing,
    };
  },
  mounted() {
    this.$emit('input', this.date);
  },
  methods: {
    startDateDialogHandler(val) {
      this.internalStartDateDialog = val;
      this.$emit('update:startDateDialog', val);
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.date = val;
      },
    },
    date: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
    internalStartDateDialog: {
      handler: function (val) {
        this.startDateDialogHandler(val);
      },
    },
    startDateDialog: {
      handler: function (val) {
        this.startDateDialogHandler(val);
      },
    },
    useCasePricingVal: {
      handler: function (val) {
        this.$emit('update:useCasePricing', val);
      },
    },
    useCasePricing: {
      handler: function (val) {
        this.useCasePricingVal = val;
      },
    },
  },
});
export default RetailStartDateDialog;
export { RetailStartDateDialog };
</script>
