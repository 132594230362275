<template>
  <core-signs-labels-view-template title="Unit Override">
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="currentTab" grow>
          <v-tab href="#handheld">Handheld</v-tab>
          <v-tab href="#allItems">All Items</v-tab>
          <v-tab href="#overrides">Overrides</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs-items v-model="currentTab">
          <v-tab-item value="handheld">
            <core-view-section title="Handheld Batches">
              <v-card v-if="
                determiningUnitOfMeasureOverrideTypeId === false &&
                unitOfMeasureOverrideTypeId <= 0
              ">
                <v-card-text>
                  This store cannot override the unit of measure for items
                </v-card-text>
              </v-card>
              <hand-held-data-table v-else-if="handHeldHeaderData" :selected.sync="handheldBatchSelected"
                :modelValue.sync="handHeldHeaderData" :handHeldSearch.sync="handHeldSearch"
                :completed.sync="handheldCompleted" @row-clicked="selectHandHeldHeaderRow"
                @item-selected="selectHandHeldHeaderRow" @search-cleared="
                  handHeldSearch = null;
                handHeldGetData();
                " @get-data="handHeldGetData">
              </hand-held-data-table>
            </core-view-section>
            <core-view-section title="Override" v-if="selectedHandheldBatches > 0">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-btn @click="overrideForHandheld"
                        :disabled="handHeldLineDataTable.selectedHandHeldLines.length === 0">Create Overrides For Batch
                        ({{
                          handHeldLineDataTable.handHeldLineCount - excludedHandHeldLines.length
                        }})</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <hand-held-batch-lines :modelValue.sync="handHeldLineDataTable"
                        :handHeldMainId="selectedHandheldBatchMainId"
                        @snackbar-error="emit('snackbar-error', $event)"></hand-held-batch-lines>
                    </v-col>
                  </v-row>
                  <v-row>
                    <retail-start-date-dialog v-model="retailStartDate" :queueLoading="handHeldToBatchLoading"
                      :queueDisabled="handHeldLineDataTable.selectedHandHeldLines.length === 0 ||
                        handHeldToBatchLoading === true
                        " :queueCallback="handheldToBatch" :startDateDialog.sync="startDateDialog"
                      :createdBatchInfo="createdBatchInfo" :showCasePricing="true"
                      :useCasePricing.sync="useCasePricing">
                      <v-card-actions>
                        <v-col class="my-0 py-0 ml-3">
                          <v-row>
                            <v-checkbox v-model="useHandHeldScanOrder" label="Use Hand Held Scan Order"
                              class="my-0 py-0"></v-checkbox>
                          </v-row>
                        </v-col>
                      </v-card-actions>
                    </retail-start-date-dialog>
                  </v-row>
                </v-col>
              </v-row>
            </core-view-section>
            <core-view-section v-if="createdBatchInfo && createdBatchInfo.batchInfoId"
              v-show="createdBatchInfo.printImmediately !== true" title="Queue Items">
              <batch-check-items v-model="batchInfoItems" :batchInfo.sync="createdBatchInfo"
                @dataBound="batchInfoItemsDataBound" @error="emit('snackbar-error', $event)"
                :footerBatchAndId="footerBatchAndId" :includeSetDelimters="false" :autoCompleteBatch="false"
                originatingPage="Unit Override Handheld"></batch-check-items>
            </core-view-section>
          </v-tab-item>
          <v-tab-item value="allItems">
            <core-view-section title="Items">
              <v-skeleton-loader v-if="determiningUnitOfMeasureOverrideTypeId === true"
                type="table-heading,list-item,table-heading,table-thead,table-tbody,table-tfoot"></v-skeleton-loader>
              <activated-labels v-else-if="
                determiningUnitOfMeasureOverrideTypeId === false &&
                unitOfMeasureOverrideTypeId > 0
              " :value="items" @input="items = $event" :showClearButton="false" :multiselect="false"
                :showNonActivatedToggle="true" :clearSelectedOnSearch="true" :nonActivatedToggle="nonActivatedToggle"
                @update:nonActivatedToggle="nonActivatedToggle = $event"
                @snackbar-error="emit('snackbar-error', $event)" :search="search" @update:search="search = $event"
                :selectedBarcodeSetups="selectedBarcodeSetups"
                @update:selectedBarcodeSetups="selectedBarcodeSetups = $event" :queuedItems="queuedItems"
                @update:queuedItems="queuedItems = $event"></activated-labels>
              <v-card v-else-if="
                determiningUnitOfMeasureOverrideTypeId === false &&
                unitOfMeasureOverrideTypeId <= 0
              ">
                <v-card-text>
                  This store cannot override the unit of measure for items
                </v-card-text>
              </v-card>
            </core-view-section>

            <core-view-section title="Override" v-if="selectedItemsLength > 0">
              <v-col>
                <v-row v-if="
                  unitOverrideWriter === true &&
                  selectedUnitOfMeasureOverride === null &&
                  gettingSelectedUnitOfMeasureOverride === false
                ">
                  <v-btn @click="createOverride">Create</v-btn>
                </v-row>
                <v-row>
                  <v-col>
                    <v-skeleton-loader v-if="gettingSelectedUnitOfMeasureOverride === true"
                      type="card"></v-skeleton-loader>

                    <v-card v-else>
                      <v-card-text v-if="
                        selectedUnitOfMeasureOverride === null ||
                        selectedUnitOfMeasureOverride.value === null ||
                        selectedUnitOfMeasureOverride.unitOfMeasureOverrideId === 0
                      ">Not overrides found.
                        {{
                          unitOverrideWriter === true
                            ? 'Click create button to add an override.'
                            : ''
                        }}
                      </v-card-text>
                      <v-card-text v-else>
                        <v-card-title>Unit Override for {{ selectedUnitOfMeasureOverride.upc }}</v-card-title>
                        <ul class="override-details">
                          <li>Item Number: {{ selectedUnitOfMeasureOverride.itemId }}</li>
                          <li>Package Size: {{ selectedUnitOfMeasureOverride.packageSize }}</li>
                          <li>
                            Package UOM: {{ selectedUnitOfMeasureOverride.packageUnitMeasure }}
                          </li>
                          <li>Converted Size: {{ selectedUnitOfMeasureOverride.size }}</li>
                          <li>Converted UOM: {{ selectedUnitOfMeasureOverride.unitMeasure }}</li>
                          <li v-if="askedForAdditionalInfo === true">
                            Dry?: {{ selectedUnitOfMeasureOverride.isDryWeight ? 'Yes' : 'No' }}
                          </li>
                          <li v-if="askedForAdditionalInfo === true">
                            Shampoo/conditioner/calming:
                            {{ selectedUnitOfMeasureOverride.isShampooConditioner ? 'Yes' : 'No' }}
                          </li>
                        </ul>
                      </v-card-text>
                      <v-card-actions v-if="unitOverrideWriter === true">
                        <v-row v-if="selectedUnitOfMeasureOverride !== null">
                          <v-col cols="6">
                            <v-btn @click="editOverride">Edit</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </core-view-section>
          </v-tab-item>

          <v-tab-item value="overrides">
            <v-card v-if="
              determiningUnitOfMeasureOverrideTypeId === false && unitOfMeasureOverrideTypeId <= 0
            ">
              <v-card-text> This store cannot override the unit of measure for items </v-card-text>
            </v-card>
            <unit-overrides v-else ref="unitOverrides" @editClicked="unitOverridesEditClicked"
              @deleteClicked="unitOverridesDeleteClicked"></unit-overrides>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <unit-override-form :modelValue.sync="unitOverride" :showOverrideForm.sync="showOverrideForm"
      :selectedItem="selectedItem" :selectedHandheldBatch="selectedHandheldBatch"
      :excludedHandHeldLines="excludedHandHeldLines" :unitOfMeasureOverrideTypeId="unitOfMeasureOverrideTypeId"
      :isEdit="isEdit" @saved="saved"></unit-override-form>

    <v-dialog v-model="deleteDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Override</span>
        </v-card-title>
        <v-card-text v-if="unitOverrideToDelete">
          Are you sure you want to delete override for UPC {{ unitOverrideToDelete.upc }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteConfirmed" :loading="savingUnitOfMeasureOverride"
            :disabled="savingUnitOfMeasureOverride">Yes</v-btn>
          <v-btn @click="deleteDialog = false" :disabled="savingUnitOfMeasureOverride">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <data-tour tourName="uomOverrideTour" :steps="tourSteps" maxWidth="600px" :show="true"></data-tour>
  </core-signs-labels-view-template>
</template>

<script setup lang="ts">
import Vue, { ref, onMounted, defineComponent, watch, computed } from 'vue';
import moment from 'moment';
import UnitOverrideForm from '@/components/label/UnitOverrideForm.vue';
import UnitOverrides from '@/components/label/UnitOverrides.vue';
import HandHeldDataTable from '@/components/label/HandHeldDataTable.vue';
import HandHeldBatchLines from '@/components/label/HandHeldBatchLines.vue';
import PogonaDataTable from '../../../utils/PogonaDataTable';
import HandHeldBatchCreator from '@/utils/HandHeldBatchCreator';
import { UnitOfMeasureOverride, useUnitOverrideStore } from '@/stores/unitoverridestore';
import { storeToRefs } from 'pinia';

defineComponent({
  name: 'UnitOverride',
  components: {
    UnitOverrideForm,
    UnitOverrides,
    HandHeldDataTable,
    HandHeldBatchLines,
  },
});

const emit = defineEmits(['snackbar-error', 'snackbar-success']);

const unitOverrideStore = useUnitOverrideStore();

const {
  handHeldHeaderData,
  handheldCompleted,
  items,
  nonActivatedToggle,
  search,
  selectedBarcodeSetups,
  queuedItems,
  unitOfMeasureOverrideTypeId,
  determiningUnitOfMeasureOverrideTypeId,
  selectedUnitOfMeasureOverride,
  gettingSelectedUnitOfMeasureOverride,
  savingUnitOfMeasureOverride,
  handHeldLineDataTable,
  handHeldSearch,
  currentTab,
} = storeToRefs(unitOverrideStore);

const lastUpc = ref<string | null>(null);
const showOverrideForm = ref(false);
const unitOverride = ref<UnitOfMeasureOverride | null>(null);
const unitOverrideToDelete = ref<UnitOfMeasureOverride | null>(null);
const selectedItemFromOverrides = ref<any | null>({ upc: '', itemId: '' });
const selectedHandheldBatch = ref<number | null>(null);
const isEdit = ref(false);
const unitOverrideWriter = ref(false);
const deleteDialog = ref(false);
const startDateDialog = ref(false);
const useCasePricing = ref(false);
const useHandHeldScanOrder = ref(true);
const retailStartDate = ref<string | Date>(moment.utc().format('YYYY-MM-DD'));
const handHeldToBatchLoading = ref(false);
const createdBatchInfo = ref<any | null>({});
const batchInfoItems = ref<any | null>({});
const handheldBatchSelected = ref<any>([]);
const selectedItem = ref<any>({ upc: '', itemId: '' });
let handHeldBatchWs: any | null = null;
const tourSteps = [
  {
    target: 'h4:first-child',
    header: {
      title: 'Unit Override Video Tour',
    },
    video: 'uom_override',
    placement: 'bottom left',
  },
];

const storeNumber = computed(() => {
  if (Vue.prototype.$store?.state?.app?.storeNumber) {
    return Vue.prototype.$store.state.app.storeNumber;
  }
  return null;
});

onMounted(async () => {
  unitOverrideWriter.value = await Vue.prototype.$authApi.roleHasRights('UnitOverrideWriter');
  await getDetermineUnitOfMeasureOverrideTypeId(storeNumber.value);
  handHeldGetData();
});

async function getDetermineUnitOfMeasureOverrideTypeId(storeNumber: string) {
  if (isNaN(Number(storeNumber)) === false && Number(storeNumber) > 0) {
    await unitOverrideStore.determineUnitOfMeasureOverrideTypeId(storeNumber);
  }
}

function createOverride() {
  selectedHandheldBatch.value = null;
  isEdit.value = false;
  showOverrideForm.value = true;
}

function editOverride() {
  selectedHandheldBatch.value = null;
  isEdit.value = true;
  showOverrideForm.value = true;
  unitOverride.value = selectedUnitOfMeasureOverride.value;
}

async function saved() {
  if (currentTab.value === 'allItems') {
    await unitOverrideStore.getUnitOfMeasureOverride(selectedItem.value.upc);
  }
  await getUnitOfMeasureOverridesData();
  await getSelectedUnitOfMeasureOverride();

  if (currentTab.value === 'handheld') {
    emit('snackbar-success', 'Handheld overrides complete.');
  }
}

async function getUnitOfMeasureOverridesData() {
  await unitOverrideStore.getUnitOfMeasureOverrides(null);
}

async function unitOverridesEditClicked(e) {
  selectedHandheldBatch.value = null;
  isEdit.value = true;
  showOverrideForm.value = true;
  unitOverride.value = e;
  selectedItemFromOverrides.value = { upc: e.upc, itemId: e.itemId };
  selectedItem.value = { upc: e.upc, itemId: e.itemId };
}

async function getSelectedUnitOfMeasureOverride() {
  if (
    selectedUnitOfMeasureOverride.value &&
    (selectedUnitOfMeasureOverride.value.upc?.length ?? 0) > 0
  ) {
    await unitOverrideStore.getUnitOfMeasureOverride(selectedUnitOfMeasureOverride.value.upc);
  }
}

async function unitOverridesDeleteClicked(e) {
  unitOverrideToDelete.value = e;
  deleteDialog.value = true;
}

async function deleteConfirmed() {
  if (unitOverrideToDelete.value !== null) {
    try {
      await unitOverrideStore.deleteUnitOfMeasureOverride(unitOverrideToDelete.value);
      await getSelectedUnitOfMeasureOverride();
      unitOverrideStore.removeQueuedItem(unitOverrideToDelete.value.upc);
    } finally {
      deleteDialog.value = false;
      unitOverrideToDelete.value = null;
      await getUnitOfMeasureOverridesData();
    }
  }
}

function selectHandHeldHeaderRow(event) {
  let item = event;
  if (
    event.value &&
    typeof event.value === 'boolean' &&
    event.item &&
    typeof event.item === 'object'
  ) {
    item = event.item;
  }

  handHeldHeaderData.value.selected = [];
  handHeldHeaderData.value.selected.push(item);
  handheldBatchSelected.value = [];
  handheldBatchSelected.value.push(item);
}

async function handHeldGetData() {
  //handHeldHeaderData.value = null;

  initHandHeldHeaders();
  if (handHeldHeaderData.value) {
    await handHeldHeaderData.value.get();
    handHeldHeaderData.value.loading = false;
  }
}

async function handheldToBatch(source) {
  const errorMessage = err => {
    handHeldToBatchLoading.value = false;
    emit('snackbar-error', {
      text: 'Error creating batch from hand held.',
      err,
      id: '25bca431-e3ce-4b24-a4a0-7648a09d053e',
    });
  };

  try {
    batchInfoItems.value = {};
    createdBatchInfo.value = null;
    startDateDialog.value = false;
    handHeldToBatchLoading.value = true;

    let batchCreated = false;

    const onClose = () => {
      useCasePricing.value = false;
      // If the web socket connection closed before
      // the batch was created throw an error message.
      if (batchCreated === false) {
        errorMessage(null);
      }
    };

    // Register on message handler before posting the message
    const onmessage = event => {
      const recMessage = JSON.parse(event.data);
      batchCreated = true;

      handHeldBatchWs.close();

      if (recMessage.success === false) {
        errorMessage(recMessage);
        return;
      }

      createdBatchInfo.value = recMessage.batchInfo;
      createdBatchInfo.value.printImmediately = (source && source.printImmediately) || false;
    };

    const hbc = new HandHeldBatchCreator(
      Vue.prototype.$authApi,
      Vue.prototype.$apiBasePath,
      storeNumber.value,
      retailStartDate.value,
      useCasePricing.value,
      selectedHandheldBatchMainId.value,
      useHandHeldScanOrder.value === false,
      (handHeldHeaderData.value && handHeldHeaderData.value.selected[0].fromAxView === true) ??
      false,
      excludedHandHeldLines.value,
    );
    handHeldBatchWs = await hbc.open(onClose, onmessage);
  } catch (err) {
    errorMessage(err);
  }
}

const excludedHandHeldLines = computed(() => {
  const excludedHandHeldLineIdsR = [] as number[];
  if (
    (handHeldLineDataTable.value?.selectedHandHeldLines?.length ?? 0) ===
    (handHeldLineDataTable.value?.handHeldLines?.length ?? 0)
  ) {
    return excludedHandHeldLineIdsR;
  }

  // iterate over all items and find the lines that are not selected
  if (
    handHeldLineDataTable.value?.handHeldLines &&
    handHeldLineDataTable.value?.selectedHandHeldLines
  ) {
    handHeldLineDataTable.value.handHeldLines.forEach(line => {
      if (
        handHeldLineDataTable.value.selectedHandHeldLines.findIndex(
          x => x.handHeldLineId === line.handHeldLineId,
        ) === -1
      ) {
        excludedHandHeldLineIdsR.push(line.handHeldLineId);
      }
    });
  }

  return excludedHandHeldLineIdsR;
});

function initHandHeldHeaders() {
  handHeldHeaderData.value = new PogonaDataTable({
    headers: [
      {
        text: 'Batch ID',
        value: 'handHeldMainId',
      },
      {
        text: 'Print Type',
        value: 'description',
      },
      {
        text: 'Description',
        value: 'headerDescription',
      },
      {
        text: 'Batch Date',
        value: 'headerDate',
      },
    ],
    baseUrl: `label/handheldmain/store/${storeNumber.value}/${handheldCompleted.value}`,
    httpClient: Vue.prototype.$authApi.http,
    options: { itemsPerPage: 50, sortBy: ['headerDate'], sortDesc: [true] },
    isInfinite: true,
  });

  handHeldHeaderData.value.queryStringParams = handHeldMainQueryStringParams();
  setupEvents();
}

function handHeldMainQueryStringParams() {
  if (handHeldSearch.value && handHeldSearch.value.trim().length > 0) {
    return {
      search: handHeldSearch.value,
    };
  }
  return null;
}

function setupEvents() {
  const error = err => {
    emit('snackbar-error', {
      text: 'Error getting hand held data',
      err,
      id: 'b02fb3fd-dd44-4db3-a969-c0a16b768f8c',
    });
  };

  if (handHeldHeaderData.value) {
    handHeldHeaderData.value.removeListener('error', error);
    handHeldHeaderData.value.on('error', error);
  }
}

function batchInfoItemsDataBound() {
  handHeldToBatchLoading.value = false;
}

const footerBatchAndId = computed(() => {
  return `UOMHH ${handHeldHeaderData.value &&
      handHeldHeaderData.value.selected &&
      handHeldHeaderData.value.selected.length > 0
      ? handHeldHeaderData.value.selected[0].handHeldMainId
      : ''
    }`;
});

function overrideForHandheld() {
  selectedItemFromOverrides.value = null;
  showOverrideForm.value = true;
  selectedHandheldBatch.value = handheldBatchSelected.value[0].handHeldMainId;
}

const selectedItemsLength = computed(() => {
  1;
  if (selectedItemFromOverrides.value !== null) {
    return 1;
  }
  return items.value?.selected?.length ?? 0;
});

const askedForAdditionalInfo = computed(() => {
  return (
    selectedUnitOfMeasureOverride.value &&
    (selectedUnitOfMeasureOverride.value.packageUnitMeasureConversionSymbol === 'oz' ||
      selectedUnitOfMeasureOverride.value.packageUnitMeasureConversionSymbol === 'qt' ||
      selectedUnitOfMeasureOverride.value.packageUnitMeasureConversionSymbol === 'pnt')
  );
});

const selectedHandheldBatches = computed(() => {
  return handHeldHeaderData.value?.selected?.length ?? 0;
});

const selectedHandheldBatchMainId = computed(() => {
  if (selectedHandheldBatches.value > 0) {
    return handHeldHeaderData.value.selected[0].handHeldMainId;
  }
  return null;
});

watch(
  () => items.value.selected,
  async () => {
    if ((items.value?.selected?.length ?? 0) === 1) {
      if (lastUpc.value !== items.value.selected[0].upc) {
        lastUpc.value = items.value.selected[0].upc;
        await unitOverrideStore.getUnitOfMeasureOverride(items.value.selected[0].upc);
        selectedItem.value = {
          upc: items.value.selected[0].upc,
          itemId: items.value.selected[0].itemId,
        };
      }
    } else {
      unitOverrideStore.setSelectedUnitOfMeasureOverride(null);
    }
  },
);

watch(
  () => storeNumber.value,
  async val => {
    await getDetermineUnitOfMeasureOverrideTypeId(val);
    handHeldGetData();
  },
);

watch(
  () => currentTab,
  async val => {
    if (val.value === 'allItems') {
      selectedItemFromOverrides.value = null;
    }
  },
);
</script>

<style scoped>
.override-details li {
  list-style-type: none;
}
</style>
