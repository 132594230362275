import { PreCountReportsState, FileShareFile } from './state';

export const setStores = 'setStores';
export const setSelectedStore = 'setSelectedStore';
export const setBatchId = 'setBatchId';
export const setReports = 'setReports';
export const setSearchInProgress = 'setSearchInProgress';
export const setDownloadInProgress = 'setDownloadInProgress';
export const setStoreGetInProgress = 'setStoreGetInProgress';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  [setStores](state: PreCountReportsState, payload: Array<string>): void {
    state.stores = payload;
  },
  [setSelectedStore](state: PreCountReportsState, payload: string | null): void {
    state.selectedStore = payload;
  },
  [setBatchId](state: PreCountReportsState, payload: string | null): void {
    state.selectedBatchId = payload;
  },
  [setReports](state: PreCountReportsState, payload: FileShareFile[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new FileShareFile(), payload[i]);
    }
    state.reports = payload;
  },
  [setSearchInProgress](state: PreCountReportsState, payload: boolean): void {
    state.searchInProgress = payload;
  },
  [setDownloadInProgress](state: PreCountReportsState, payload: boolean): void {
    state.downloadInProgress = payload;
  },
  [setStoreGetInProgress](state: PreCountReportsState, payload: boolean): void {
    state.storeGetInProgress = payload;
  },
};
