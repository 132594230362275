<template>
  <core-view-template title="Price Change Upload">
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title id="tour" class="text--black">Choose Effective Date</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <p>
                    PSP purchase orders will reflect new pricing beginning on the effective date.
                  </p>
                  <p>Effective date must be at least:</p>
                  <p class="pl-4">60 days from today for items shipped to Pet Depot.</p>
                  <p class="pl-4">30 days from today for items shipped directly to stores.</p>
                </v-col>
                <v-col>
                  <v-date-picker
                    v-model="effectiveDate"
                    :min="date30DaysFromNow"
                    :events="minDates"
                    event-color="green lighten-1"
                  ></v-date-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="text--black">
              <v-row>
                <v-col cols="auto"> How Does This Work? </v-col>
                <v-col cols="auto">
                  <data-tour
                    class="mb-3"
                    tourName="priceChangeTour"
                    maxWidth="600px"
                    :steps="tourSteps"
                    :show="showTour"
                    :hideHelpIcon="hideHelpIcon"
                    :autoplay="true"
                    :scrollFirstStep="false"
                    :hideDoNotShowCheckbox="true"
                  ></data-tour>
                </v-col> </v-row
            ></v-card-title>
            <v-card-subtitle
              >Please choose a item change file to upload, and then click the "Upload" button. The
              file will be processed, and our merchandising department will make the appropriate
              changes.
            </v-card-subtitle>
            <v-card-actions>
              <v-row>
                <v-col><a :href="templateDownload" target="_blank">Price Change Template</a></v-col>
                <v-col
                  ><a :href="distributorCatalogTemplateDownload" target="_blank"
                    >Distributor Catalog Template</a
                  ></v-col
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text
              ><v-file-input v-model="file" label="Click here to select a file"></v-file-input>
            </v-card-text>
            <v-card-actions
              ><v-btn @click="upload" :disabled="!file || uploading" :loading="uploading"
                >Upload</v-btn
              ></v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getDateString } from '@/utils/Utils';

const vpModule = namespace('vendorportal');

@Component({})
export default class UploadItemUpcateView extends Vue {
  private file: File | null = null;
  private uploading = false;
  private effectiveDate = this.date30DaysFromNow;
  private minDates = [this.date30DaysFromNow, this.date60DaysFromNow];

  private showTour = true;
  private hideHelpIcon = false;
  private tourSteps = [
    {
      target: '#tour',
      header: {
        title: 'Data Entry',
      },
      video: 'price_change_data_entry',
      placement: 'top',
    },
    {
      target: '#tour',
      header: {
        title: 'Upload Price Change Data',
      },
      video: 'price_change_paste',
      placement: 'top',
    },
  ];

  @vpModule.Action('uploadItemChange') uploadItemChange!: (data: any) => Promise<string>;

  async upload() {
    if (this.file) {
      this.uploading = true;
      try {
        await this.uploadItemChange({ File: this.file, EffectiveDate: this.effectiveDate });

        this.$emit('snackbar-success', 'Price change upload complete.');
        this.file = null;
        this.uploading = false;
      } catch (err) {
        this.uploading = false;
        this.$emit('snackbar-error', {
          text: 'Error uploading item change file',
          err,
          id: 'b5714a99-5859-46f0-b7cb-1e7f28cdc027',
        });
      }
    }
  }

  public get templateDownload() {
    return `${this.$storageHost}/vendorsamples/pspd-template.xlsx?_=${new Date().getTime()}`;
  }

  public get distributorCatalogTemplateDownload() {
    return `${
      this.$storageHost
    }/vendorsamples/distributor-catalog-2023-template.xlsx?_=${new Date().getTime()}`;
  }

  private getDaysFromNow(days: number): string {
    const sixtyDaysFromNow = new Date();
    sixtyDaysFromNow.setDate(new Date().getDate() + days);
    return getDateString(sixtyDaysFromNow);
  }

  private get date30DaysFromNow() {
    return this.getDaysFromNow(30);
  }

  private get date60DaysFromNow() {
    return this.getDaysFromNow(60);
  }
}
</script>
