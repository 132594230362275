/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BatchCreatorBase from './BatchCreatorBase';

export class LabelBatchCreator extends BatchCreatorBase {
  constructor(
    public authApi: any,
    public apiBasePath: string,
    public storeNumber: string,
    public retailStartDate: string | Date,
    public useCasePricing: boolean,
    public selectedItems: Array<LabelBatchItem>,
  ) {
    super('label_item_batch', authApi, apiBasePath, storeNumber, retailStartDate, useCasePricing);

    const onOpen = async () => {
      const labelItemsBatchData = {
        id: super.id,
        selectedItems: this.selectedItems,
        storeNumber: this.storeNumber,
        retailStartDate: this.retailStartDate,
        useCasePricing: this.useCasePricing,
      };

      await this.authApi.http.post('servicebus/labelitemsbatch', labelItemsBatchData);
    };

    super.onOpen = onOpen;
  }
}

export class LabelBatchItem {
  constructor(public itemId: string, public upc: string) {}
}
