<template>
  <vue-headful :title="finalTitle" />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      finalTitle: document.title,
    };
  },
  created() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      const branding = ' - PSP Group';

      if (this.title) {
        this.finalTitle = `${this.title}${branding}`;
      } else {
        this.finalTitle = `Pogona${branding}`;
      }
    },
  },
  watch: {
    title: {
      handler: function () {
        this.setTitle();
      },
    },
  },
};
</script>
