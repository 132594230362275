<template>
  <v-data-table
    :headers="computeHeaders"
    :items="computeItems"
    :options.sync="options"
    :loading="props.loading"
    :footer-props="{ 'items-per-page-options': [] }"
    :server-items-length="serverItemsLength"
    :hide-default-footer="props.hideDefaultFooter"
    @update:page="updatePage"
    :page="internalPage"
  >
    <template v-slot:item.PurchaseOrderNumber="{ item }">
      <router-link
        v-if="props.linkPO"
        :to="{
          name: 'vendorportal_podetail',
          params: { poNumber: item.PurchaseOrderNumber },
        }"
        class="no-wrap"
      >
        {{ item.PurchaseOrderNumber }}
      </router-link>
      <span v-else
        ><span class="no-wrap">{{ item.PurchaseOrderNumber }}</span></span
      >
    </template>
    <template v-slot:item.AccountingDate="{ item }">
      {{ formatters.formatDate(item.AccountingDate) }}</template
    >
    <template v-slot:item.RequestedDeliveryDate="{ item }">
      {{ formatters.formatDate(item.RequestedDeliveryDate) }}</template
    >
    <template v-slot:item.DataAreaId="{ item }">
      <span class="no-wrap">{{ getLegalEntityLookup(item.DataAreaId) }}</span>
    </template>
    <template v-slot:item.FormattedDeliveryAddress="{ item }">
      <span class="no-wrap">{{ item.FormattedDeliveryAddress }}</span></template
    >
    <template v-slot:item.DeliveryAddressDescription="{ item }">
      <span class="no-wrap">{{ item.DeliveryAddressDescription }}</span></template
    >
    <template v-slot:item.PurchaseOrderName="{ item }">
      <span class="no-wrap">{{ item.PurchaseOrderName }}</span></template
    >
    <template v-slot:item.DeliveryAddressName="{ item }">
      <span class="no-wrap">{{ item.DeliveryAddressName }}</span></template
    >
    <template v-slot:item.PurchaseOrderStatus="{ item }">
      <span class="no-wrap">{{
        financeMapper.mapPOStatus(item.PurchaseOrderStatus)
      }}</span></template
    >
    <template v-slot:item.PaymentStatus="{ item }">
      <span class="no-wrap">{{ item.PaymentStatus }}</span></template
    >
    <template v-slot:item.CheckNumber="{ item }">
      <span class="no-wrap">{{ item.CheckNumber }}</span></template
    >
    <template v-slot:footer.page-text="props">
      <span>{{ props.pageStart }} - {{ props.pageStop }}</span>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useFinanceMapper, useLegalEntityLookup } from '@/composables/financeComposables';
import {
  PODataResultDto,
  PurchaseOrderHeaderV2Dto,
  VendInvoiceJourBiEntityDto,
  VendTransBiEntityDto,
} from '@psp/pogona_vendor_api_lib';
import { useFormatters } from '@/composables/formatters';
import { getSome, isSome } from '@/utils/FableUtils.fs.js';

type PoHeaderGridEvents = {
  (event: 'load-pos', take: number, skip: number): void;
  (event: 'update-page', page: number): void;
};

const formatters = useFormatters();
const financeMapper = useFinanceMapper();
const emit = defineEmits<PoHeaderGridEvents>();
const emitLoadPos = (take: number, skip: number) => emit('load-pos', take, skip);
const emitUpdatePage = (page: number) => emit('update-page', page);

const updatePage = (page: number) => {
  internalPage.value = page;
  emitUpdatePage(page);
};
const { getLegalEntityLookup } = useLegalEntityLookup();
const options = ref({});

const headers = ref([
  { text: 'Order Number', value: 'PurchaseOrderNumber', sortable: false, detailCol: false },
  { text: 'Order Date', value: 'AccountingDate', sortable: false, detailCol: false },
  { text: 'Pet Partner Name', value: 'PurchaseOrderName', sortable: false, detailCol: false },
  { text: 'Approval Status', value: 'DocumentApprovalStatus', sortable: false, detailCol: false },
  { text: 'Order Status', value: 'PurchaseOrderStatus', sortable: false, detailCol: false },
  { text: 'Invoice Number', value: 'InvoiceNumber', sortable: false, detailCol: true },
  { text: 'Payment Status', value: 'PaymentStatus', sortable: false, detailCol: true },
  { text: 'Delivery Site ID', value: 'DefaultReceivingSiteId', sortable: false, detailCol: false },
  {
    text: 'Delivery Address Name',
    value: 'DeliveryAddressName',
    sortable: false,
    detailCol: false,
  },
  {
    text: 'Delivery Address',
    value: 'FormattedDeliveryAddress',
    sortable: false,
    detailCol: false,
  },
  { text: 'Mode of Delivery', value: 'DeliveryModeId', sortable: false, detailCol: false },
  { text: 'Delivery Terms', value: 'DeliveryTermsId', sortable: false, detailCol: false },
  { text: 'Payment Terms', value: 'PaymentTermsName', sortable: false, detailCol: false },
  { text: 'Payment Method', value: 'VendorPaymentMethodName', sortable: false, detailCol: false },
  { text: 'Check Number', value: 'CheckNumber', sortable: false, detailCol: true },
]);
const serverItemsLength = 9999;

defineComponent({
  name: 'POHeaderGrid',
});

interface POHeaderGridProps {
  poHeaderRows: PurchaseOrderHeaderV2Dto[];
  pos: PODataResultDto[];
  loading: boolean;
  hideDefaultFooter: boolean;
  linkPO: boolean;
  page: number;
  showDetailCols: boolean;
}

const props = withDefaults(defineProps<POHeaderGridProps>(), {
  hideDefaultFooter: false,
  linkPO: true,
  page: 1,
});

const computeHeaders = computed(() => {
  return headers.value.filter(h => !h.detailCol || props.showDetailCols);
});
const lookupPo = (poNumber: string) => {
  return props.pos.find(po => po.PurchaseOrderHeader.PurchaseOrderNumber === poNumber) ?? null;
};
const getPoInvoiceNumber = (poNumber: string) => {
  const po = lookupPo(poNumber);
  if (po && isSome(po.VendInvoiceJourBiEntity)) {
    const invoice = getSome(po.VendInvoiceJourBiEntity) as VendInvoiceJourBiEntityDto;
    return invoice.InvoiceId;
  } else {
    return '';
  }
};
const getPaymentStatus = (poNumber: string) => {
  const po = lookupPo(poNumber);
  const invoiceNumber = getPoInvoiceNumber(poNumber);
  if (po && invoiceNumber) {
    return po.VendTransBiEntities.some((v: VendTransBiEntityDto) => v.PaymReference !== '')
      ? 'Paid'
      : 'Not Paid';
  } else {
    return '';
  }
};
const getCheckNumber = (poNumber: string) => {
  const po = lookupPo(poNumber);
  if (po) {
    return (
      po.VendTransBiEntities.find(
        (v: VendTransBiEntityDto) =>
          v.PaymReference !== '' && v.PaymMode?.toLowerCase() === 'check',
      )?.PaymReference ?? ''
    );
  } else {
    return '';
  }
};
const computeItems = computed(() => {
  return props.poHeaderRows.map(row => {
    return {
      ...row,
      InvoiceNumber: getPoInvoiceNumber(row.PurchaseOrderNumber),
      PaymentStatus: getPaymentStatus(row.PurchaseOrderNumber),
      CheckNumber: getCheckNumber(row.PurchaseOrderNumber),
    };
  });
});

const internalPage = ref(props.page);

watch(
  () => props.page,
  newPage => {
    internalPage.value = newPage;
    if ('page' in options.value) {
      options.value.page = newPage;
    }
  },
);

watch(options, async (curr: any, prev: any) => {
  if ('page' in curr && 'page' in prev && curr.page !== prev.page) {
    emitLoadPos((curr.page - 1) * curr.itemsPerPage, curr.itemsPerPage);
  }
});
</script>
<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
