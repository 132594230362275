<template>
  <v-text-field
    v-model="positionValue"
    @change="handleInput"
    :rules="startingPositionRules"
    prepend-icon="grid_on"
    type="number"
    label="Starting Position"
  />
</template>
<script>
import Vue from 'vue';

const StartingPositionPicker = Vue.component('StartingPositionPicker', {
  props: {
    value: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      positionValue: this.value,
      startingPositionRules: [
        () => {
          if (this.positionValue < 1) {
            this.positionValue = 1;
          }
          return true;
        },
      ],
    };
  },
  methods: {
    handleInput() {
      this.$emit('input', Number(this.positionValue));
    },
  },
});

export default StartingPositionPicker;
export { StartingPositionPicker };
</script>
