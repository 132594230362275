<template>
  <v-col>
    <v-form @submit.prevent="startSave">
      <v-row>
        <v-col v-if="value.Store.LinkedStoreId && value.Store.LinkedStoreId > 0">
          <v-row>
            <router-link
              :to="{ name: 'pcl_editstore', params: { storeId: value.Store.LinkedStoreId } }"
            >
              Linked Store {{ value.Store.LinkedStoreId.toString().padStart(4, '0') }}
            </router-link>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Store Number</label>
        </v-col>
        <v-col cols="6">
          <v-row
            ><v-text-field
              v-model="value.Store.StoreNumber"
              :disabled="isEdit === true"
              autocomplete="off"
              maxlength="4"
              :error-messages="errorsMessages.StoreNumber"
              @blur="
                formatStoreNumberForPayload();
                validate('StoreNumber');
              "
              @change="
                formatStoreNumberForPayload();
                validate('StoreNumber');
              "
            ></v-text-field
          ></v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Store Name</label>
        </v-col>
        <v-col cols="6">
          <v-row
            ><v-text-field
              v-model="value.Store.Name"
              autocomplete="off"
              maxlength="100"
              :error-messages="errorsMessages.StoreName"
              @blur="validate('StoreName')"
              @change="validate('StoreName')"
            ></v-text-field
          ></v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Store Phone</label>
        </v-col>
        <v-col cols="6">
          <v-row
            ><v-text-field
              v-model="value.Store.Phone"
              autocomplete="off"
              maxlength="10"
              :error-messages="errorsMessages.StorePhone"
              @blur="validate('StorePhone')"
              @change="validate('StorePhone')"
            ></v-text-field
          ></v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Store Fax</label>
        </v-col>
        <v-col cols="6">
          <v-row
            ><v-text-field
              v-model="value.Store.Fax"
              autocomplete="off"
              maxlength="10"
              :error-messages="errorsMessages.StoreFax"
              @blur="validate('StoreFax')"
              @change="validate('StoreFax')"
            ></v-text-field
          ></v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Store Email</label>
        </v-col>
        <v-col cols="6">
          <v-row
            ><v-text-field
              v-model="value.Store.Email"
              autocomplete="off"
              maxlength="50"
              :error-messages="errorsMessages.StoreEmail"
              @blur="validate('StoreEmail')"
              @change="validate('StoreEmail')"
            ></v-text-field
          ></v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Status</label>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-select
              v-model="value.Store.StoreStatusId"
              :items="storeStatusIdTypesData"
              :loading="storeStatusesGetInProgress"
              :disabled="storeStatusesGetInProgress"
              :error-messages="errorsMessages.StoreStatusId"
              item-text="Name"
              item-value="StoreStatusId"
              autocomplete="off"
              @change="validate('StoreStatusId')"
            ></v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="showEffectiveDate">
        <v-col cols="2" class="input-label">
          <label>Effective Date</label>
        </v-col>
        <v-col cols="6">
          <v-row>
            <data-date-picker v-model="value.Store.EffectiveDate" :clearable="true" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Price Zone</label>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-select
              v-model="value.Store.PriceZoneId"
              :items="priceZonesData"
              :loading="priceZoneGetInProgress"
              :disabled="priceZoneGetInProgress"
              clearable
              item-text="Name"
              item-value="PriceZoneId"
              autocomplete="off"
            ></v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="input-label">
          <label>Note</label>
        </v-col>
        <v-col cols="6">
          <v-row class="mt-3">
            <v-textarea
              outlined
              v-model="value.Store.Note"
              counter="500"
              :error-messages="errorsMessages.Note"
              @blur="validate('Note')"
              @change="validate('Note')"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="isEdit === true">
        <v-col cols="2" class="input-label">
          <label>Open Date</label>
        </v-col>
        <v-col cols="6">
          <v-row>
            <data-date-picker v-model="value.Store.ActualOpenDate" :clearable="true" />
          </v-row>
        </v-col>
      </v-row>
      <core-view-section
        v-model="locationInfoExpanded"
        title="Location Information"
        :expandable="true"
        class="mt-2"
      >
        <v-col cols="12">
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Address</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.Address1"
                  autocomplete="off"
                  maxlength="100"
                  :error-messages="errorsMessages.Address1"
                  @blur="validate('Address1')"
                  @change="validate('Address1')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Address 2</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.Address2"
                  autocomplete="off"
                  maxlength="50"
                  :error-messages="errorsMessages.Address2"
                  @blur="validate('Address2')"
                  @change="validate('Address2')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>City</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.City"
                  autocomplete="off"
                  maxlength="100"
                  :error-messages="errorsMessages.City"
                  @blur="validate('City')"
                  @change="validate('City')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" class="input-label">
              <label>State</label>
            </v-col>
            <v-col cols="1">
              <v-row>
                <v-autocomplete
                  v-model="value.Store.Address.StateCode"
                  :items="stateData"
                  :loading="stateGetInProgress"
                  :disabled="stateGetInProgress"
                  @change="stateChanged"
                  item-text="abbreviation"
                  item-value="abbreviation"
                  autocomplete="off"
                />
              </v-row>
            </v-col>
            <v-col cols="1" class="input-label">
              <label>Zip</label>
            </v-col>
            <v-col cols="1">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.Zip"
                  autocomplete="off"
                  maxlength="5"
                  :error-messages="errorsMessages.Zip"
                  @blur="validate('Zip')"
                  @change="validate('Zip')"
                ></v-text-field
              ></v-row>
            </v-col>
            <v-col cols="1" class="zip-seperator">
              <label>-</label>
            </v-col>
            <v-col cols="1">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.ZipPlus4"
                  autocomplete="off"
                  maxlength="4"
                  :error-messages="errorsMessages.ZipPlus4"
                  @blur="validate('ZipPlus4')"
                  @change="validate('ZipPlus4')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" class="input-label">
              <label>County</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-autocomplete
                  v-model="value.Store.Address.County"
                  :items="countyData"
                  :loading="countySearchInProgress"
                  :disabled="countySearchInProgress || !value.Store.Address.StateCode"
                  item-text="County"
                  item-value="County"
                  autocomplete="off"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Longitude</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.Longitude"
                  autocomplete="off"
                  maxlength="19"
                  :error-messages="errorsMessages.Longitude"
                  @blur="validate('Longitude')"
                  @change="validate('Longitude')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Latitude</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.Address.Latitude"
                  autocomplete="off"
                  maxlength="19"
                  :error-messages="errorsMessages.Latitude"
                  @blur="validate('Latitude')"
                  @change="validate('Latitude')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Time Zone</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-select
                  v-model="value.Store.TimeZoneId"
                  :items="timeZonesData"
                  :loading="timeZoneGetInProgress"
                  :disabled="timeZoneGetInProgress"
                  clearable
                  item-text="Code"
                  item-value="TimeZoneId"
                  autocomplete="off"
                ></v-select>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Division/Regions</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-autocomplete
                  v-model="divisionRegionId"
                  :items="divisionRegionsData"
                  :loading="divisionRegionsGetInProgress"
                  :disabled="divisionRegionsGetInProgress"
                  @change="divisionRegionChange"
                  clearable
                  item-text="Name"
                  item-value="DivisionRegionId"
                  autocomplete="off"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>District</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-autocomplete
                  v-model="value.Store.DistrictId"
                  :items="districtData"
                  :loading="districtGetInProgress"
                  :disabled="districtGetInProgress || !districtData || districtData.length === 0"
                  :label="districtLabel"
                  @change="districtChange"
                  @click:clear="
                    value.Store.DistrictId = null;
                    divisionRegionId = null;
                  "
                  clearable
                  item-text="Name"
                  item-value="DistrictId"
                  autocomplete="off"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Market</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-autocomplete
                  v-model="value.Store.MarketId"
                  :items="marketData"
                  :loading="marketGetInProgress"
                  :disabled="marketGetInProgress || !marketData || marketData.length === 0"
                  :label="marketLabel"
                  @click:clear="value.Store.MarketId = null"
                  clearable
                  item-text="Name"
                  item-value="MarketId"
                  autocomplete="off"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>DMA</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-autocomplete
                  v-model="value.Store.DmaId"
                  :items="dmaData"
                  :loading="dmaGetInProgress"
                  :disabled="dmaGetInProgress"
                  clearable
                  :item-text="x => (x.Code && x.Code > -1 ? `${x.Code} - ${x.Name}` : '')"
                  item-value="DmaId"
                  autocomplete="off"
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Distribution Center</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-select
                  v-model="value.Store.DistributionCenterId"
                  :items="distributionCenterData"
                  :loading="distributionCenterGetInProgress"
                  :disabled="distributionCenterGetInProgress"
                  clearable
                  item-text="Name"
                  item-value="DistributionCenterId"
                  autocomplete="off"
                >
                </v-select>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </core-view-section>

      <core-view-section
        v-model="buildingInfoExpanded"
        title="Building Information"
        :expandable="true"
        class="mt-2"
      >
        <v-row>
          <v-col cols="2" class="input-label">
            <label>Backroom Size</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.BackRoomSize"
                autocomplete="off"
                maxlength="25"
                :error-messages="errorsMessages.BackRoomSize"
                @blur="validate('BackRoomSize')"
                @change="validate('BackRoomSize')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="input-label">
            <label>Ceiling Height</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.CeilingHeight"
                autocomplete="off"
                maxlength="25"
                :error-messages="errorsMessages.CeilingHeight"
                @blur="validate('CeilingHeight')"
                @change="validate('CeilingHeight')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="input-label">
            <label>Square Feet</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.SquareFeet"
                autocomplete="off"
                maxlength="25"
                :error-messages="errorsMessages.SquareFeet"
                @blur="validate('SquareFeet')"
                @change="validate('SquareFeet')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="input-label">
            <label>Racking Type</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.RackingType"
                autocomplete="off"
                maxlength="50"
                :error-messages="errorsMessages.RackingType"
                @blur="validate('RackingType')"
                @change="validate('RackingType')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="input-label">
            <label>Racking Color</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.RackingColor"
                autocomplete="off"
                maxlength="50"
                :error-messages="errorsMessages.RackingColor"
                @blur="validate('RackingColor')"
                @change="validate('RackingColor')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="input-label">
            <label>Racking Linear Feet</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.RackingLinearFeet"
                autocomplete="off"
                maxlength="50"
                :error-messages="errorsMessages.RackingLinearFeet"
                @blur="validate('RackingLinearFeet')"
                @change="validate('RackingLinearFeet')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="2" class="input-label">
            <label>Number of Entrances</label>
          </v-col>
          <v-col cols="6">
            <v-row
              ><v-text-field
                v-model="value.Store.NumberOfStoreEntrances"
                autocomplete="off"
                maxlength="25"
                :error-messages="errorsMessages.NumberOfStoreEntrances"
                @blur="validate('NumberOfStoreEntrances')"
                @change="validate('NumberOfStoreEntrances')"
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
      </core-view-section>

      <core-view-section
        v-model="ownerInfoExpanded"
        title="Owner Information"
        :expandable="true"
        class="mt-2"
      >
        <v-col cols="12">
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Business Unit</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.LegalEntity"
                  autocomplete="off"
                  maxlength="4"
                  :error-messages="errorsMessages.LegalEntity"
                  @blur="validate('LegalEntity')"
                  @change="validate('LegalEntity')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Franchise Entity</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.FranchiseEntity"
                  autocomplete="off"
                  maxlength="100"
                  :error-messages="errorsMessages.FranchiseEntity"
                  @blur="validate('FranchiseEntity')"
                  @change="validate('FranchiseEntity')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Owner Group</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.OwnerGroup"
                  autocomplete="off"
                  maxlength="100"
                  :error-messages="errorsMessages.OwnerGroup"
                  @blur="validate('OwnerGroup')"
                  @change="validate('OwnerGroup')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="input-label">
              <label>Owner Email</label>
            </v-col>
            <v-col cols="6">
              <v-row
                ><v-text-field
                  v-model="value.Store.OwnerEmail"
                  autocomplete="off"
                  maxlength="50"
                  :error-messages="errorsMessages.OwnerEmail"
                  @blur="validate('OwnerEmail')"
                  @change="validate('OwnerEmail')"
                ></v-text-field
              ></v-row>
            </v-col>
          </v-row>
        </v-col>
      </core-view-section>

      <core-view-section
        v-model="importantDatesExpanded"
        :title="isEdit ? 'Launch' : 'Important Dates'"
        :expandable="true"
        class="mt-2"
      >
        <v-col cols="12">
          <v-row>
            <v-col cols="3" class="input-label">
              <label>Planned Open Date</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <data-date-picker v-model="value.Store.OpenDate" :clearable="true" />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="input-label">
              <label>GoWeekend</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <data-date-picker v-model="value.Store.GoWeekend" :clearable="true" />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="input-label">
              <label>GoEvent2</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <data-date-picker v-model="value.Store.GoEvent2" :clearable="true" />
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isEdit === false">
            <v-col cols="3" class="input-label">
              <label>Closed Date</label>
            </v-col>
            <v-col cols="6">
              <v-row>
                <data-date-picker v-model="value.Store.ClosedDate" :clearable="true" />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </core-view-section>
      <core-view-section
        v-model="storeServicesExpanded"
        title="Store Services"
        :expandable="true"
        class="mt-2"
      >
        <v-col cols="12" v-if="gettingLookupData === false && initingEditData === false">
          <v-row>
            <v-col cols="6">
              <pcl-store-services
                v-model="value"
                :services="servicesDataCol1"
                :errorsMessages.sync="errorsMessages"
                :validate="validate"
              />
            </v-col>
            <v-col cols="6">
              <pcl-store-services
                v-model="value"
                :services="servicesDataCol2"
                :errorsMessages.sync="errorsMessages"
                :validate="validate"
              />
            </v-col>
          </v-row>
        </v-col>
      </core-view-section>

      <core-view-section
        v-model="socialMediaExpanded"
        title="Social Media"
        :expandable="true"
        class="mt-2"
      >
        <v-col cols="12">
          <v-row v-if="socialMediaGetInProgress == false">
            <pcl-social-media-edit
              v-model="value.Store.StoreSocialMedias"
              :socialMediaLookup="socialMediaLookup"
              :storeId="value.Store.StoreId"
              :errors.sync="errorsMessages.SocialMedias"
            ></pcl-social-media-edit>
          </v-row>
        </v-col>
      </core-view-section>

      <v-col>
        <v-row justify="start">
          <v-col cols="6">
            <v-btn @click="$router.back()" :disabled="createUpdateStoreInProgress">Cancel</v-btn>
          </v-col>
          <v-col cols="6">
            <v-tooltip top :disabled="gettingLookupData === false">
              <template v-slot:activator="{ on }">
                <v-row v-on="on" class="float-right">
                  <v-col>
                    <v-btn
                      @click="startSave"
                      :disabled="isValid === false || gettingLookupData === true"
                      :loading="createUpdateStoreInProgress"
                      >Save</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
              <span
                >We're still pulling data for this store. This button will be enabled once that is
                complete.</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-overlay :value="storeSearchInProgress || createUpdateStoreInProgress || gettingLookupData">
        <v-progress-circular indeterminate color="green" size="100" width="3" />
      </v-overlay>
      <v-dialog v-model="transferOwnershipRelocatedDialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >You must supply the new store number to connect this store to</span
            >
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <v-col cols="3" class="input-label">
                  <label>New Store Number</label><label class="red--text">*</label>
                </v-col>
                <v-col cols="5">
                  <v-row>
                    <v-text-field
                      v-model="value.CopyStore.StoreNumber"
                      autocomplete="off"
                      maxlength="4"
                      :error-messages="errorsMessages.StoreNumberStoreCopy"
                      @blur="
                        formatStoreNumberForStoreCopy();
                        validate('StoreNumberStoreCopy');
                      "
                      @change="
                        formatStoreNumberForStoreCopy();
                        validate('StoreNumberStoreCopy');
                      "
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="isEdit === true">
                <v-col cols="3" class="input-label">
                  <label>Effective Date</label>
                </v-col>
                <v-col cols="5">
                  <v-row>
                    <data-date-picker v-model="value.CopyStore.EffectiveDate" />
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="isRelocation === false">
                <v-col cols="3" class="input-label">
                  <label>Business Unit</label>
                </v-col>
                <v-col cols="5">
                  <v-row
                    ><v-text-field
                      v-model="value.CopyStore.LegalEntity"
                      autocomplete="off"
                      maxlength="4"
                      :error-messages="errorsMessages.LegalEntityStoreCopy"
                      @blur="validate('LegalEntityStoreCopy')"
                      @change="validate('LegalEntityStoreCopy')"
                    ></v-text-field
                  ></v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                transferOwnershipRelocatedDialog = false;
                save();
              "
              :disabled="transferOwnerIsValid === false"
              >Save and Make Store Copy</v-btn
            >
            <v-btn @click="transferOwnershipRelocatedDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="storeClosedDialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">You must supply the close date in order to close the store</span>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row v-if="isEdit === true">
                <v-col cols="3" class="input-label">
                  <label>Close Date</label><label class="red--text">*</label>
                </v-col>
                <v-col cols="5">
                  <v-row>
                    <data-date-picker
                      v-model="value.Store.ClosedDate"
                      :error-messages="errorsMessages.ClosedDate"
                      @change="validate('ClosedDate')"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                storeClosedDialog = false;
                save();
              "
              :disabled="closeStoreIsValid === false"
              >Save and Close Store</v-btn
            >
            <v-btn @click="storeClosedDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-col>
</template>
<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pm from '@/store/modules/pcl/mutations';
import {
  StateCountyResult,
  MarketResult,
  StoreStatusType,
  DistrictResult,
  SocialMedia,
} from '@psp/pogona_pcl_client_js';
import {
  StoreService,
  StoreCreateUpdatePayload,
  StoreStatusTypes,
} from '@psp/pogona_pcl_client_js';
import * as Yup from 'yup';
import 'yup-phone-lite';
import PclStoreServices from './PclStoreServices.vue';
import PclSocialMediaEdit from './PclSocialMediaEdit.vue';

const pclModule = namespace('pcl');

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({
  components: {
    PclStoreServices,
    PclSocialMediaEdit,
  },
})
export default class PclNewEditStore extends Vue {
  storeFormSchema = Yup.object().shape({
    StoreNumber: Yup.string()
      .required()
      .nullable()
      .test(
        'storeNotExists',
        this.isEdit ? this.storeNotExistsTestAlwaysTrue : this.storeNotExistsTest,
      )
      .notOneOf(['0000'])
      .matches(/^(\d{4}|)$/, 'Store number must be 4 digits')
      .max(4)
      .label('Store Number'),
    StoreName: Yup.string().max(100).label('Store Name').nullable(),
    StoreStatusId: Yup.number().label('Store Status').typeError('Store Status must be selected.'),
    StorePhone: Yup.string()
      .matches(/^(\d{10}|)$/, 'Store Phone must be 10 digits or blank')
      .max(10)
      .label('Store Phone')
      .nullable()
      .optional(),
    StoreFax: Yup.string()
      .matches(/^(\d{10}|)$/, 'Store Fax must be 10 digits or blank')
      .max(10)
      .label('Store Fax')
      .nullable()
      .optional(),
    StoreEmail: Yup.string().email().max(50).label('Store Email').nullable().optional(),
    Address1: Yup.string().max(100).label('Address').nullable().optional(),
    Address2: Yup.string().max(50).label('Address 2').nullable().optional(),
    City: Yup.string().max(100).label('City').nullable().optional(),
    Zip: Yup.string()
      .matches(/^(\d{5}|)$/, 'Zip must be 5 digits or blank')
      .max(5)
      .label('Zip')
      .nullable()
      .optional(),
    ZipPlus4: Yup.string()
      .matches(/^(\d{4}|)$/, 'Zip +4 must be 4 digits or blank')
      .max(4)
      .label('Zip +4')
      .nullable()
      .optional(),
    Longitude: Yup.string()
      .matches(/^\s*-?(\d+\.?\d*|)\s*$/, 'Longitude must be a decimal or blank')
      .max(19)
      .label('Longitude')
      .nullable()
      .optional(),
    Latitude: Yup.string()
      .matches(/^\s*-?(\d+\.?\d*|)\s*$/, 'Latitude must be a decimal or blank')
      .max(19)
      .label('Latitude')
      .nullable()
      .optional(),
    LegalEntity: Yup.string().max(4).label('Business Unit').nullable().optional(),
    FranchiseEntity: Yup.string().max(100).label('Franchise Entity').nullable().optional(),
    OwnerGroup: Yup.string().max(100).label('Owner Group').nullable().optional(),
    OwnerEmail: Yup.string().email().max(50).label('Owner Email').nullable().optional(),
    StoreNumberStoreCopy: Yup.string()
      .required('Store number is required')
      .notOneOf(['0000'])
      .matches(/^(\d{4}|)$/, 'Store number must be 4 digits')
      .test('storeNotExists', 'Store number already exists', this.storeNotExistsTest)
      .max(4)
      .label('Store Number'),
    LegalEntityStoreCopy: Yup.string().max(4).label('Business Unit').nullable().optional(),
    ClosedDate: Yup.date().label('Closed Date').required('Closed Date is required'),
    BackRoomSize: Yup.string().max(25).label('Backroom Size').nullable().optional(),
    CeilingHeight: Yup.string().max(25).label('Ceiling Height').nullable().optional(),
    SquareFeet: Yup.string()
      .matches(/^\s*\d*\s*$/, 'Square Feet must be a number')
      .label('Square Feet')
      .nullable()
      .optional(),
    RackingType: Yup.string().max(50).label('Racking Type').nullable().optional(),
    RackingColor: Yup.string().max(50).label('Racking Color').nullable().optional(),
    RackingLinearFeet: Yup.string().max(50).label('Racking Linear Feet').nullable().optional(),
    NumberOfStoreEntrances: Yup.string()
      .matches(/^\s*\d*\s*$/, 'Number of Entrances must be a number')
      .label('Number of Entrances')
      .nullable()
      .optional(),
    Note: Yup.string().max(500).label('Note').nullable().optional(),
    GroomingNumberOfTables: Yup.string()
      .matches(/^\s*\d*\s*$/, 'Number of Tables must be a number')
      .label('Number of Tables')
      .nullable()
      .optional(),
    GroomingNumberOfTubs: Yup.string()
      .matches(/^\s*\d*\s*$/, 'Number of Tubs must be a number')
      .label('Number of Tubs')
      .nullable()
      .optional(),
    DogWashNumberOfStations: Yup.string()
      .matches(/^\s*\d*\s*$/, 'Number of Stations must be a number')
      .label('Number of Stations')
      .nullable()
      .optional(),
  });

  @pclModule.Action(pa.searchCounties)
  searchCounties!: (stateCode: string) => Promise<any>;
  @pclModule.Action(pa.getDistricts)
  getDistricts!: () => Promise<any>;
  @pclModule.Action(pa.getMarkets)
  getMarkets!: (districtId: number) => Promise<any>;
  @pclModule.Action(pa.getStates)
  getStates!: () => Promise<any>;
  @pclModule.Action(pa.getStoreStatuses)
  getStoreStatuses!: () => Promise<any>;
  @pclModule.Action(pa.getServices)
  getServices!: () => Promise<any>;
  @pclModule.Action(pa.getDeliveryMethods)
  getDeliveryMethods!: () => Promise<any>;
  @pclModule.Action(pa.getTimeZones)
  getTimeZones!: () => Promise<any>;
  @pclModule.Action(pa.getPriceZones)
  getPriceZones!: () => Promise<any>;
  @pclModule.Action(pa.getDmas)
  getDmas!: () => Promise<any>;
  @pclModule.Action(pa.getDistributionCenters)
  getDistributionCenters!: () => Promise<any>;
  @pclModule.Action(pa.getDivisionRegions)
  getDivisionRegions!: () => Promise<any>;
  @pclModule.Action(pa.createStore)
  createStore!: (payload: StoreCreateUpdatePayload) => Promise<any>;
  @pclModule.Action(pa.updateStore)
  updateStore!: (payload: StoreCreateUpdatePayload) => Promise<any>;
  @pclModule.Action(pa.searchStoreToCheckExistence)
  searchStoreToCheckExistence!: (payload: string | number) => Promise<any>;
  @pclModule.Action(pa.getStoreForEdit)
  getStoreForEdit!: (storeId: number) => Promise<any>;
  @pclModule.Action(pa.getDeliveryAreaLookup)
  getDeliveryAreaLookup!: () => Promise<any>;
  @pclModule.Action(pa.getSocialMediaLookup)
  getSocialMediaLookup!: () => Promise<any>;
  @pclModule.Action(pa.getDogWashTubTypes)
  getDogWashTubTypes!: () => Promise<any>;

  @pclModule.Mutation(pm.initStoreEditData) initStoreEditData!: (storeNumber: string) => void;
  @pclModule.Mutation(pm.setCreateUpdateStoreInProgress) setCreateUpdateStoreInProgress!: (
    payload: boolean,
  ) => void;
  @pclModule.Mutation(pm.setMarketData) setMarketData!: (payload: MarketResult[]) => void;
  @pclModule.Mutation(pm.setDistrictData) setDistrictData!: (payload: DistrictResult[]) => void;

  @Model('modelChanged', { type: StoreCreateUpdatePayload })
  readonly value!: StoreCreateUpdatePayload;

  @Prop({ required: true, default: ps.PclStoreEditMode.New })
  readonly mode!: ps.PclStoreEditMode;

  @pclModule.State(ps.countyData) countyData!: StateCountyResult[];
  @pclModule.State(ps.countySearchInProgress) countySearchInProgress!: boolean;
  @pclModule.State(ps.storeStatusIdTypesData) storeStatusIdTypesData!: any;
  @pclModule.State(ps.timeZonesData) timeZonesData!: any;
  @pclModule.State(ps.priceZonesData) priceZonesData!: any;
  @pclModule.State(ps.servicesData) servicesData!: ps.StoreService[];
  @pclModule.State(ps.storeStatusesGetInProgress) storeStatusesGetInProgress!: boolean;
  @pclModule.State(ps.timeZoneGetInProgress) timeZoneGetInProgress!: boolean;
  @pclModule.State(ps.priceZoneGetInProgress) priceZoneGetInProgress!: boolean;
  @pclModule.State(ps.stateData) stateData!: any;
  @pclModule.State(ps.stateGetInProgress) stateGetInProgress!: boolean;
  @pclModule.State(ps.districtData) districtData!: DistrictResult[];
  @pclModule.State(ps.marketData) marketData!: any;
  @pclModule.State(ps.dmaData) dmaData!: any;
  @pclModule.State(ps.distributionCenterData) distributionCenterData!: any;
  @pclModule.State(ps.divisionRegionsData) divisionRegionsData!: any;
  @pclModule.State(ps.socialMediaLookup) socialMediaLookup!: SocialMedia[];
  @pclModule.State(ps.districtGetInProgress) districtGetInProgress!: boolean;
  @pclModule.State(ps.marketGetInProgress) marketGetInProgress!: boolean;
  @pclModule.State(ps.storeSearchInProgress) storeSearchInProgress!: boolean;
  @pclModule.State(ps.createUpdateStoreInProgress) createUpdateStoreInProgress!: boolean;
  @pclModule.State(ps.dmaGetInProgress) dmaGetInProgress!: boolean;
  @pclModule.State(ps.distributionCenterGetInProgress) distributionCenterGetInProgress!: boolean;
  @pclModule.State(ps.divisionRegionsGetInProgress) divisionRegionsGetInProgress!: boolean;
  @pclModule.State(ps.socialMediaGetInProgress) socialMediaGetInProgress!: boolean;

  private divisionRegionId: number | null = null;

  @Watch('$route.params.storeId') private onStoreNumberId() {
    this.init();
  }

  @Watch('value.Store.NumberOfStoreEntrances')
  private onNumberOfStoreEntrancesChanged(val: any) {
    this.value.Store.NumberOfStoreEntrances = this.handleNullableInts(val);
  }

  @Watch('value.Store.SquareFeet')
  private onSquareFeetChanged(val: any) {
    this.value.Store.SquareFeet = this.handleNullableInts(val);
  }

  @Watch('divisionRegionId')
  private async onDivisionRegionIdChanged(val: number | null) {
    if (val !== null) {
      await this.filterDistricts(val);
    } else {
      this.setDistrictData([]);
    }
    this.setMarketData([]);
  }

  private async filterDistricts(divisionRegionId) {
    // first, get all districts
    await this.getDistricts();
    // now, filter out by division
    const districts = [] as Array<DistrictResult>;
    for (const district of this.districtData) {
      if (district.DivisionRegionId === divisionRegionId) {
        districts.push(district);
      }
    }
    this.setDistrictData(districts);
  }

  private handleNullableInts(val): number | null {
    if (
      (typeof val === 'string' && ((!!val && val.trim().length === 0) || !val)) ||
      typeof val === 'object'
    ) {
      return null;
    } else if (typeof val === 'number') {
      return val;
    }
    return Number(val);
  }

  private locationInfoExpanded: boolean = this.isEdit === false;
  private buildingInfoExpanded: boolean = this.isEdit === false;
  private ownerInfoExpanded: boolean = this.isEdit === false;
  private importantDatesExpanded: boolean = this.isEdit === false;
  private storeServicesExpanded: boolean = this.isEdit === false;
  private socialMediaExpanded: boolean = this.isEdit === false;

  private gettingLookupData = true;
  private initingEditData = false;

  private storeStatusIdOnLoad: number | null = null;

  private transferOwnershipRelocatedDialog = false;
  private storeClosedDialog = false;

  private isRelocation = false;

  private errorsMessages: ps.NewEditStoreValidationMessages =
    new ps.NewEditStoreValidationMessages();

  public async init() {
    // reset the state
    this.initStoreEditData(
      this.isEdit ? this.$route.params.storeId.toString().padStart(4, '0') : '',
    );

    this.gettingLookupData = true;

    await Promise.all([
      this.getStates(),
      this.getStoreStatuses(),
      this.getServices(),
      this.getDeliveryMethods(),
      this.getTimeZones(),
      this.getPriceZones(),
      this.getDmas(),
      this.getDistributionCenters(),
      this.getDivisionRegions(),
      this.getDeliveryAreaLookup(),
      this.getSocialMediaLookup(),
      this.getDogWashTubTypes(),
    ]);

    if (this.isEdit === false) {
      this.value.Store.StoreStatusId = StoreStatusTypes.PreOpen;
      this.storeStatusIdOnLoad = this.value.Store.StoreStatusId;
    } else {
      this.storeStatusIdOnLoad = this.value.Store.StoreStatusId;

      if (this.value.Store.DistrictId) {
        await this.setDivisionRegion();
      }
    }

    this.gettingLookupData = false;

    if (this.isEdit === true) {
      this.initingEditData = true;
      // get the store
      await this.getStoreForEdit(Number(this.$route.params.storeId));

      this.storeStatusIdOnLoad = this.value.Store.StoreStatusId;

      if (this?.value?.Store?.StoreServices != null) {
        // iterate over each possible store service and check it, if the store has it mapped
        for (const service of this.servicesData) {
          const mappedService = this.value?.Store.StoreServices.filter(
            x => x.ServiceId == service.ServiceId,
          );

          if (mappedService && mappedService.length === 1) {
            Vue.set(service, 'Selected', true);
          } else {
            Vue.set(service, 'Selected', false);
          }
        }
      }

      if (this?.value?.Store?.Address !== null && this?.value?.Store?.Address?.StateCode !== null) {
        await this.searchCounties(this.value.Store.Address.StateCode);
      }

      if (this?.value?.Store?.DistrictId !== null) {
        await this.setDivisionRegion();
        await this.getMarkets(this.value.Store.DistrictId);
      }
    }

    this.initingEditData = false;
  }

  private modelChanged() {
    console.log('model changed');
  }

  private stateChanged() {
    this.searchCounties(this.value?.Store?.Address?.StateCode ?? '');
  }

  private async setDivisionRegion() {
    await this.getDistricts();

    const selectedDistrict = this.districtData.filter(
      x => x.DistrictId == this.value.Store.DistrictId,
    );

    if (selectedDistrict && selectedDistrict.length === 1) {
      this.divisionRegionId = selectedDistrict[0].DivisionRegionId;
    }
  }

  private formatStoreNumberForPayload() {
    this.value.Store.StoreNumber = this.formatStoreStoreNumber(
      this?.value?.Store?.StoreNumber ?? '',
    );
    this.validate('StoreNumber');
  }

  private formatStoreNumberForStoreCopy() {
    if (this.value.CopyStore) {
      this.value.CopyStore.StoreNumber = this.formatStoreStoreNumber(
        this?.value?.CopyStore?.StoreNumber ?? '',
      );
      this.validate('StoreNumber');
    }
  }

  private formatStoreStoreNumber(storeNumber: string): string {
    if (
      storeNumber !== null &&
      storeNumber.trim().length > 0 &&
      isNaN(Number(storeNumber)) === false
    ) {
      return storeNumber?.trim().padStart(4, '0');
    } else if (storeNumber.trim().length === 0) {
      return '';
    }
    return storeNumber;
  }

  private async startSave() {
    // check if this is an owner transfer
    switch (this.value.Store.StoreStatusId) {
      case StoreStatusTypes.OwnerTransfer as number:
        if (
          this.value.Store.LinkedStoreId === null ||
          this.storeStatusIdOnLoad !== this.value.Store.StoreStatusId
        ) {
          this.isRelocation = false;
          this.transferOwnershipRelocatedDialog = true;

          if (this.value.Store.LinkedStoreId && this.value.CopyStore) {
            this.value.CopyStore.StoreNumber = this.value.Store.LinkedStoreId.toString().padStart(
              4,
              '0',
            );
          }
        } else {
          this.save();
        }
        break;
      case StoreStatusTypes.Relocated as number:
        if (
          this.value.Store.LinkedStoreId === null ||
          this.storeStatusIdOnLoad !== this.value.Store.StoreStatusId
        ) {
          this.isRelocation = true;
          this.transferOwnershipRelocatedDialog = true;

          if (this.value.CopyStore) {
            this.value.CopyStore.LegalEntity = '';
          }

          if (this.value.Store.LinkedStoreId && this.value.CopyStore) {
            this.value.CopyStore.StoreNumber = this.value.Store.LinkedStoreId.toString().padStart(
              4,
              '0',
            );
          }
        } else {
          this.save();
        }
        break;
      case StoreStatusTypes.Closed as number:
        this.storeClosedDialog = true;
        break;
      default:
        this.save();
    }
  }

  private async save() {
    if (!this.value) {
      throw new Error('store state is null');
    }

    this.value.Store.StoreServices = [] as StoreService[];

    // set the selected services
    for (const service of this.servicesData) {
      if (service.Selected === true) {
        this.value.Store.StoreServices.push(new StoreService());

        this.value.Store.StoreServices[this.value.Store.StoreServices.length - 1].ServiceId =
          service.ServiceId;
      }
    }

    let zipBeforeSave = this.value?.Store?.Address?.Zip ?? null;
    if (this.value.Store.Address) {
      this.value.Store.Address.Zip = `${this.value.Store.Address.Zip ?? ''}${
        this.value.Store.Address.ZipPlus4 ?? ''
      }`;
    }

    try {
      if (this.isEdit === true) {
        await this.updateStore(this.value);
      } else {
        await this.createStore(this.value);
      }
      this.$router.push({
        name: 'pcl_listing_all',
      });
    } catch (err) {
      this.setCreateUpdateStoreInProgress(false);

      // reset the zip in case the save failed, otherwise we'll have a runaway zip code with +4's
      if (zipBeforeSave && this.value?.Store?.Address?.Zip) {
        this.value.Store.Address.Zip = zipBeforeSave;
      }
    }
  }

  private divisionRegionChange() {
    this.value.Store.DistrictId = null;
    this.value.Store.MarketId = null;
  }

  private async districtChange() {
    this.value.Store.MarketId = null;
    if (this.value.Store.DistrictId && this.value.Store.DistrictId > 0) {
      await this.getMarkets(this.value.Store.DistrictId);
    } else {
      this.setMarketData([] as MarketResult[]);
    }
  }

  private get storeNotExistsTestAlwaysTrue(): Yup.TestFunction<
    string | undefined | null,
    Record<string, any>
  > {
    return function () {
      return true;
    } as Yup.TestFunction<string | undefined | null, Record<string, any>>;
  }

  private get storeNotExistsTest(): Yup.TestFunction<
    string | undefined | null,
    Record<string, any>
  > {
    const searchStoreToCheckExistence = this.searchStoreToCheckExistence;
    return async function (storeNumber) {
      if (storeNumber && isNaN(Number(storeNumber)) === false) {
        try {
          const storeNumberResult = await searchStoreToCheckExistence(storeNumber as string);
          if (storeNumberResult && storeNumberResult.length > 0) {
            return this.createError({
              message: 'Store number already exists',
            });
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        return this.createError({
          message: 'Store number not valid',
        });
      }
      return true;
    } as Yup.TestFunction<string | undefined | null, Record<string, any>>;
  }

  private get servicesDataHalfway() {
    const servicesLen = this.servicesData.length;
    return Math.round(servicesLen / 2);
  }

  private get servicesDataCol1() {
    return this.servicesData.slice(0, this.servicesDataHalfway);
  }
  private get servicesDataCol2() {
    return this.servicesData.slice(
      this.servicesDataHalfway,
      this.servicesDataHalfway + (this.servicesData.length - this.servicesDataHalfway),
    );
  }

  private get isEdit() {
    return this.mode !== ps.PclStoreEditMode.New;
  }

  private get showEffectiveDate() {
    if (
      this.mode === ps.PclStoreEditMode.Edit &&
      (this.value.Store.StoreStatusId === StoreStatusType.OwnerTransfer ||
        this.value.Store.StoreStatusId === StoreStatusType.Relocated)
    ) {
      return true;
    }
    return false;
  }

  private validate(field) {
    this.errorsMessages[field] = [];
    this.storeFormSchema.validateAt(field, this.validateStoreState).catch(err => {
      this.errorsMessages[field] = err.errors;
    });
  }

  private get validateStoreState() {
    return {
      StoreNumber: this.value.Store.StoreNumber,
      StoreName: this.value.Store.Name,
      StoreStatusId: this.value.Store.StoreStatusId,
      StorePhone: this.value.Store.Phone,
      StoreFax: this.value.Store.Fax,
      StoreEmail: this.value.Store.Email,
      Address1: this.value.Store.Address?.Address1 ?? null,
      Address2: this.value.Store.Address?.Address2 ?? null,
      City: this.value.Store.Address?.City ?? null,
      Zip: this.value.Store.Address?.Zip ?? null,
      ZipPlus4: this.value.Store.Address?.ZipPlus4 ?? null,
      Longitude: this.value.Store.Address?.Longitude ?? null,
      Latitude: this.value.Store.Address?.Latitude ?? null,
      LegalEntity: this.value.Store.LegalEntity,
      FranchiseEntity: this.value.Store.FranchiseEntity,
      OwnerGroup: this.value.Store.OwnerGroup,
      OwnerEmail: this.value.Store.OwnerEmail,
      StoreNumberStoreCopy: this.value.CopyStore?.StoreNumber ?? null,
      LegalEntityStoreCopy: this.value.CopyStore?.LegalEntity ?? null,
      ClosedDate: this.value.Store.ClosedDate,
      BackRoomSize: this.value.Store.BackRoomSize,
      CeilingHeight: this.value.Store.CeilingHeight,
      SquareFeet: this.value.Store.SquareFeet,
      RackingType: this.value.Store.RackingType,
      RackingColor: this.value.Store.RackingColor,
      RackingCount: this.value.Store.RackingCount,
      RackingLinearFeet: this.value.Store.RackingLinearFeet,
      NumberOfStoreEntrances: this.value.Store.NumberOfStoreEntrances,
      Note: this.value.Store.Note,
      GroomingNumberOfTables: this.value?.GroomingStoreService?.NumberOfTables ?? null,
      GroomingNumberOfTubs: this.value?.GroomingStoreService?.NumberOfTubs ?? null,
      DogWashNumberOfStations: this.value?.DogWashService?.NumberOfStations ?? null,
    };
  }

  private get isValid() {
    if (this.gettingLookupData !== false || this.initingEditData !== false) {
      return false;
    }

    if (this.errorsMessages) {
      for (const key of Object.keys(this.errorsMessages)) {
        // we have to exclude the store copy stuff otherwise the save button will be disabled
        // and the user won't be able to access the popup
        if (
          key !== 'StoreNumberStoreCopy' &&
          key !== 'LegalEntityStoreCopy' &&
          this.errorsMessages[key] &&
          this.errorsMessages[key].length > 0
        ) {
          return false;
        }
      }
    }

    if (!this.value.Store.StoreNumber || this.value.Store.StoreNumber.trim().length === 0) {
      return false;
    }

    return true;
  }

  private get transferOwnerIsValid() {
    return (
      (this.errorsMessages.StoreNumberStoreCopy?.length ?? 0) === 0 &&
      (this.errorsMessages.LegalEntityStoreCopy?.length ?? 0) === 0 &&
      (this.value.CopyStore?.StoreNumber?.length ?? 0) > 0
    );
  }

  private get closeStoreIsValid() {
    return (
      (this.errorsMessages?.ClosedDate?.length ?? 0) === 0 &&
      (this.value.Store?.ClosedDate ?? null) != null
    );
  }

  private get districtLabel() {
    if (this.divisionRegionId === null) {
      return '(choose Division/Region)';
    }
    return '';
  }

  private get marketLabel() {
    if (this.value.Store.DistrictId === null) {
      return '(choose District)';
    }
    return '';
  }
}
</script>

<style lang="scss">
.input-label {
  margin-top: 1em;
  margin-right: 0.5em;
  text-align: right;
  color: black !important;
}
.input-social-media-prefix {
  margin-top: 1.7em;
  text-align: right;
  font-size: 0.9em;
  margin-left: 5em;
}
.zip-seperator {
  margin-top: 1em;
  margin-right: 0.5em;
  text-align: center;
  color: black !important;
}
.date-picker {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
</style>
