import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PogonaApiClient } from '@psp/pogona_api_client_js';
import store from '@/store';
import ODataProps from '@/utils/ODataProps';
import buildQuery from 'odata-query';
import f from 'odata-filter-builder';
import { AxiosResponse } from 'axios';
import FileDownloadHelper from '@/utils/FileDownloadHelper';
import dateFormat from 'dateformat';
import FileSaver from 'file-saver';

export class D365ImportValidatorResult {
  constructor(
    public message: string | null = null,
    public rowNumber: number = 0,
    public colNumber: number = 0,
    public isError: boolean = false,
  ) {}
}

export class HotDealItem {
  constructor(
    public hotDealItemId: number = 0,
    public itemNumber: string = '',
    public beginDate: Date = new Date(),
    public endDate: Date = new Date(),
  ) {}
}

export const useMerchStore = defineStore('merchStore', () => {
  const client = ref<PogonaApiClient | null>(null);
  const d365WorksheetUploadInProgress = ref<boolean>(false);
  const d365ImportValidatorResults = ref<D365ImportValidatorResult[]>(
    [] as D365ImportValidatorResult[],
  );
  const d365SourceBlobLocation = ref<string | null>(null);
  const gettingHotDeals = ref<boolean>(false);
  const gettingHotDealsCount = ref<boolean>(false);
  const hotDeals = ref<HotDealItem[]>([] as HotDealItem[]);
  const hotDealsCount = ref<number>(0);
  const exportingHotDeals = ref<boolean>(false);

  function init(http: any, baseUrl: string | null): void {
    client.value = new PogonaApiClient(null, http, baseUrl);
  }

  function getErrorMessage(fallbackMessage, err) {
    let errorMessage = fallbackMessage;
    try {
      let errorInfo = { message: '' };
      if (err.response.data && err.response.data.message) {
        errorInfo = err.response.data;
      } else {
        const enc = new TextDecoder('utf-8');
        errorInfo = JSON.parse(enc.decode(err.response.data));
      }

      const messageMatch = /Msg:([^.]+)./g.exec(errorInfo.message);
      if (messageMatch && messageMatch.length > 1) {
        errorMessage = `Error message: ${messageMatch[1]?.trim()}.`;
      }
    } catch (encErr) {
      // just swallow these exceptions
    }
    return errorMessage;
  }

  function validateD365ImportWorksheet(d365Worksheet: File | null): Promise<unknown> {
    if (!d365Worksheet) {
      throw new Error('No file selected');
    }

    d365WorksheetUploadInProgress.value = true;
    d365ImportValidatorResults.value = [] as D365ImportValidatorResult[];

    const formData = new FormData();
    formData.append('file', d365Worksheet);

    return client
      .value!.apiPost('./storeset/d365/validated365importworksheet', formData, undefined, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(x => {
        d365WorksheetUploadInProgress.value = false;
        d365ImportValidatorResults.value = x.data;
      })
      .catch(err => {
        d365WorksheetUploadInProgress.value = false;

        store.commit('setError', {
          err: err,
          text: getErrorMessage('Error validating D365 worksheet', err),
          id: 'e247e83a-43a1-441e-8abf-c3fdaa40dfea',
        });
        throw err;
      });
  }

  function uploadD365Worksheet(d365Worksheet: File | null): Promise<unknown> {
    if (!d365Worksheet) {
      throw new Error('No file selected');
    }

    d365WorksheetUploadInProgress.value = true;

    const formData = new FormData();
    formData.append('file', d365Worksheet);

    return client
      .value!.apiPost('./storeset/d365/processd365worksheet', formData, undefined, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(x => {
        d365SourceBlobLocation.value = x.data;
      })
      .catch(err => {
        d365WorksheetUploadInProgress.value = false;
        store.commit('setError', {
          err: err,
          text: getErrorMessage('Error uploading D365 worksheet', err),
          id: '3c087312-d0f1-4577-85b7-93f20f705f2c',
        });
        throw err;
      });
  }

  function getHotDeals(odata: ODataProps): Promise<any> {
    gettingHotDeals.value = true;
    const odataStr = buildQuery(odata).substring(1);
    return client
      .value!.apiGet(`./label/hotdealitem?${odataStr}`)
      .then(x => {
        if (odata.skip === 0) {
          hotDeals.value = x.data;
        } else {
          hotDeals.value.push(...x.data);
        }
        gettingHotDeals.value = false;
      })
      .catch(err => {
        gettingHotDeals.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error getting hot deals',
          id: 'dda6dca0-25d2-43f9-b0e1-3be71287b1cf',
        });
        throw err;
      });
  }

  function getHotDealsCount(odata: ODataProps): Promise<any> {
    gettingHotDealsCount.value = true;
    const odataStr = buildQuery(odata).substring(1);
    return client
      .value!.apiGet(`./label/hotdealitem/$count?${odataStr}`)
      .then(x => {
        hotDealsCount.value = x.data;
        gettingHotDealsCount.value = false;
      })
      .catch(err => {
        gettingHotDealsCount.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error getting hot deals count',
          id: 'bec52b4d-4fa3-4c0d-8088-a4ecf95ad632',
        });
        throw err;
      });
  }

  function deleteHotDeal(id: number): Promise<any> {
    return client.value!.apiDelete(`./label/hotdealitem/${id}`).catch(err => {
      store.commit('setError', {
        err: err,
        text: 'Error deleteing hot deal',
        id: 'd9885be7-4cb9-40ab-a2cd-92ff09e37896',
      });
      throw err;
    });
  }

  function exportHotDeals(odata: ODataProps): Promise<any> {
    exportingHotDeals.value = true;
    const odataStr = buildQuery(odata).substring(1);

    return client
      .value!.apiPost<AxiosResponse>(`label/hotdealitem/export?${odataStr}`, null, null, {
        responseType: 'blob',
      })
      .then(x => {
        exportingHotDeals.value = false;

        const fileName = FileDownloadHelper.getDownloadFileName(
          x,
          `hot-deal-export-${dateFormat(new Date(), 'yyyy-mm-dd-HHMMss')}.xlsx`,
        );
        FileSaver.saveAs(x.data, fileName);
      })
      .catch(err => {
        exportingHotDeals.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error exporting hot deals',
          id: 'ce5ee8c3-71c1-4b11-ba3b-4959ee80ccbb',
        });
        throw err;
      });
  }

  function createHotDeal(hotDealItem: HotDealItem): Promise<any> {
    const beginDate: Date =
      typeof hotDealItem.beginDate === 'string'
        ? new Date(hotDealItem.beginDate)
        : hotDealItem.beginDate;
    const endDate: Date =
      typeof hotDealItem.endDate === 'string' ? new Date(hotDealItem.endDate) : hotDealItem.endDate;

    let filter = f('and');
    filter = filter.eq('itemNumber', hotDealItem.itemNumber.trim());
    filter = filter.eq('beginDate', beginDate.toISOString(), false);
    filter = filter.eq('endDate', endDate.toISOString(), false);

    const odata = new ODataProps(99999, 0, filter.toString(), null);
    const odataStr = buildQuery(odata).substring(1);

    return new Promise<any>((resolve, reject) => {
      // check to see if it doesn't exist
      client
        .value!.apiGet(`label/hotdealitem?${odataStr}`)
        .catch(checkErr => {
          store.commit(
            'setError',
            {
              err: checkErr,
              text: 'Error creating hot deal',
              id: 'bf1be07f-cabe-4a76-8cc6-84c42c5e486b',
            },
            { root: true },
          );
          reject(checkErr);
        })
        .then(x => {
          if (x.data.length === 0) {
            client
              .value!.apiPost<AxiosResponse>('label/hotdealitem', hotDealItem)
              .then(() => {
                resolve('');
              })
              .catch(err => {
                store.commit(
                  'setError',
                  {
                    err: err,
                    text: 'Error creating hot deal',
                    id: 'bf1be07f-cabe-4a76-8cc6-84c42c5e486b',
                  },
                  { root: true },
                );
                reject(err);
              });
          } else {
            resolve('');
          }
        });
    });
  }

  return {
    client,
    d365WorksheetUploadInProgress,
    d365ImportValidatorResults,
    d365SourceBlobLocation,
    gettingHotDeals,
    gettingHotDealsCount,
    hotDeals,
    hotDealsCount,
    exportingHotDeals,
    init,
    validateD365ImportWorksheet,
    uploadD365Worksheet,
    getHotDeals,
    getHotDealsCount,
    deleteHotDeal,
    exportHotDeals,
    createHotDeal,
  };
});
