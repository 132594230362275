import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// this is mostly taken from https://github.com/latelierco/vue-application-insights,
// but updated the ApplicationInsights package to the new version
/**
 * Track route changes as page views with ApplicationInsights
 * @param options
 */
function setupPageTracking(options, Vue) {
  const { router } = options;

  const baseName = options.baseName || '(Vue App)';

  router.beforeEach((route, from, next) => {
    const name = `${baseName}/${route.name}`;
    Vue.appInsights.startTrackPage(name);
    next();
  });

  router.afterEach(route => {
    const name = `${baseName}/${route.name}`;
    const url = `${location.protocol}//${location.host}${route.fullPath}`;

    const storeNumber = document.getElementById('_selectedStoreNumber');
    const username = document.getElementById('_username');
    const version = document.getElementById('_version');

    Vue.appInsights.stopTrackPage(name, url, {
      storeNumber: storeNumber ? storeNumber.value : null,
      username: username ? username.value : null,
      version: version ? version.value : null,
    });
  });
}

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @param Vue
 * @param options
 */
function install(Vue, options) {
  const { id } = options;

  if (options.ApplicationInsights) {
    Vue.appInsights = options.appInsights;
    Vue.prototype.$appInsights = options.appInsights.appInsights;
  } else {
    const localAppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: id,
      },
    });

    localAppInsights.loadAppInsights();
    localAppInsights.trackPageView();

    Vue.appInsights = localAppInsights;
    Vue.prototype.$appInsights = localAppInsights.appInsights;
  }

  Vue.prototype.$error = function error(e) {
    if (Vue.appInsights) {
      Vue.appInsights.trackException({ exception: e });
    }
    // eslint-disable-next-line no-console
    if (console) console.error(e);
  };

  const { router } = options;

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, Vue);
    } else {
      router.onReady(() => setupPageTracking(options, Vue));
    }
  }

  Object.defineProperty(Vue.prototype, '$ApplicationInsights', {
    get: () => Vue.appInsights,
  });
}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install);
}

export default install;
