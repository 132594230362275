<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="paymentRef"
        ref="paymentRefField"
        label="Check # / Payment Ref"
        clearable
        @keydown.enter="navigate"
      />
    </v-col>
    <v-col>
      <CustomerSelection :custAcct="selectedCustomer" @customerSelected="customerSelected" />
    </v-col>
    <v-col>
      <TransStatusSelect
        :selected="selectedTransStatus"
        @update:selected="selectedTransStatus = $event"
      />
    </v-col>
    <v-col>
      <v-menu
        v-model="fromDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="fromDateFormatted"
            label="From Date"
            readonly
            v-on="on"
            persistent-hint
            clearable
          ></v-text-field>
        </template>
        <v-date-picker v-model="fromDate" no-title @input="fromDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="toDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="toDateFormatted"
            label="To Date"
            readonly
            v-on="on"
            persistent-hint
            clearable
          ></v-text-field>
        </template>
        <v-date-picker v-model="toDate" no-title @input="toDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-btn @click="navigate">Search</v-btn>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import CustomerSelection from './CustomerSelection.vue';
import TransStatusSelect from './TransStatusSelect.vue';
import { allTransStatus } from '@/utils/FableUtils.fs';
import { getRouteValue, getValueForRoute } from '@/utils/Utils';
defineComponent({
  name: 'CustTransSearch',
});
const router = useRouter();
const paymentRefField = ref<HTMLInputElement | null>(null);
const paymentRef = ref('');
const fromDateMenu = ref(false);
const fromDate = ref<Date | null>(null);
const fromDateFormatted = ref('');
const toDateMenu = ref(false);
const toDate = ref<Date | null>(null);
const toDateFormatted = ref('');
const selectedCustomer = ref('');
const selectedTransStatus = ref(allTransStatus);
const emit = defineEmits(['search']);
const emitSearch = () => {
  emit('search');
};
function customerSelected(vendor: string) {
  selectedCustomer.value = vendor;
}
function navigate() {
  const newRouteParams = {
    paymentRef: paymentRef.value ? encodeURIComponent(paymentRef.value) : '-',
    custAcct: selectedCustomer.value ? encodeURIComponent(selectedCustomer.value) : '-',
    fromDate: fromDateFormatted.value ? encodeURIComponent(fromDateFormatted.value) : '-',
    toDate: toDateFormatted.value ? encodeURIComponent(toDateFormatted.value) : '-',
    transStatus: getValueForRoute(selectedTransStatus.value),
  };
  if (
    router.currentRoute.name !== 'vendorportal_custtranssearchhome' ||
    JSON.stringify(router.currentRoute.params) !== JSON.stringify(newRouteParams)
  ) {
    router.push({ name: 'vendorportal_custtranssearchhome', params: newRouteParams });
  } else {
    emitSearch();
  }
}
function formatDate(date) {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}
watch(fromDate, newVal => {
  fromDateFormatted.value = formatDate(newVal) ?? '';
});
watch(toDate, newVal => {
  toDateFormatted.value = formatDate(newVal) ?? '';
});
onMounted(async () => {
  if (paymentRefField.value) {
    paymentRefField.value.focus();
  }
  if (router.currentRoute.name == 'vendorportal_custtranssearchhome') {
    paymentRef.value = getRouteValue(router.currentRoute.params.paymentRef);
    selectedCustomer.value = getRouteValue(router.currentRoute.params.custAcct);
    fromDateFormatted.value = getRouteValue(router.currentRoute.params.fromDate);
    toDateFormatted.value = getRouteValue(router.currentRoute.params.toDate);
    selectedTransStatus.value = parseInt(getRouteValue(router.currentRoute.params.transStatus, 0));
  }
});
</script>
