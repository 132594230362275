<template>
  <div v-if="impersonationSet">
    {{ impersonationMessage }}
  </div>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import { D365UserImpersonationDto } from '@psp/pogona_vendor_api_lib';

const vpFinanceStore = useVpFinanceStore();
const impersonationMessage = ref('');
const impersonationSet = computed(() => impersonationMessage.value.length > 0);

defineComponent({
  name: 'ShowImpersonation',
});

onMounted(async () => {
  if (await vpFinanceStore.isPspFinance()) {
    vpFinanceStore.getD365UserImpersonation().then((user: D365UserImpersonationDto) => {
      impersonationMessage.value = user?.ImpersonatedUser
        ? `Impersonating ${user.ImpersonatedUser}`
        : '';
    });
  }
});
</script>
