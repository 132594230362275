import StringHelpers from '@/utils/StringHelpers';

export default {
  methods: {
    fileNameFromFilePath(path) {
      return StringHelpers.fileNameFromFilePath(path);
    },
    formatNumberRanges: StringHelpers.formatNumberRanges,
  },
};
