<template>
  <core-view-template title="AutoShip Exceptions">
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="currentTab" grow>
          <v-tab href="#upload" @click="uploadFileClicked">Upload File</v-tab>
          <v-tab href="#search">Search AutoShip Exceptions</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs-items v-model="currentTab">
          <v-tab-item value="upload">
            <auto-ship-upload />
          </v-tab-item>
          <v-tab-item value="search">
            <auto-ship-search-exceptions ref="autoShipSearch"> </auto-ship-search-exceptions>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </core-view-template>
</template>
<script lang="ts">
import { Route } from 'vue-router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import AutoShipSearchExceptions from '@/components/ecom/AutoShipSearchExceptions.vue';
import AutoShipUpload from '@/components/ecom/AutoShipUpload.vue';
import { namespace } from 'vuex-class';
import * as em from '@/store/modules/ecom/mutations';

/* eslint-disable @typescript-eslint/no-explicit-any */

const ecomModule = namespace('ecom');

@Component({
  components: {
    AutoShipSearchExceptions,
    AutoShipUpload,
  },
})
export default class AutoShipExceptions extends Vue {
  public currentTab: string | null = null;

  @ecomModule.Mutation(em.setShowProcessing) setShowProcessing!: (payload: boolean) => void;
  @ecomModule.Mutation(em.setValidationData) setValidationData!: (payload: any) => void;
  @ecomModule.Mutation(em.clearExceptionData) clearExceptionData!: () => void;
  @ecomModule.Mutation(em.clearExceptionSearch) clearExceptionSearch!: () => void;

  private clearSearch() {
    this.clearExceptionSearch();
    this.clearExceptionData();
    if (this.$refs.autoShipSearch) {
      (this.$refs.autoShipSearch as AutoShipSearchExceptions).reset();
    }
  }

  private mounted() {
    this.currentTab = this.$route.params.tab;
  }

  private uploadFileClicked() {
    this.setShowProcessing(false);
    this.setValidationData(null);
  }

  @Watch('currentTab') onCurrentTabChanged(tab: string) {
    if (this.$router.currentRoute.path !== `/ecom/autoshipexceptions/${tab}`) {
      this.$router.replace(`/ecom/autoshipexceptions/${tab}`);
    }
    if (tab === 'search') {
      this.clearSearch();
    }
  }

  @Watch('$route') public onRouteChanged(to: Route) {
    this.currentTab = to.params.tab;
  }
}
</script>
