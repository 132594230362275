import { EcomProductState } from './state';

export const setClient = 'setClient';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  [setClient](state: EcomProductState, payload: any): void {
    state.init(payload.http, payload.baseUrl);
  },
};
