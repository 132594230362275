import Vue from 'vue';

Vue.filter('zip', value => {
  if (value) {
    // 48170-1234 -- 9
    // 48170 -- 5

    const zipTrimmed = value.trim();

    switch (zipTrimmed.length) {
      case 9:
        return `${zipTrimmed.substring(0, 5)}-${zipTrimmed.substring(5, 9)}`;
      default:
        return zipTrimmed;
    }
  }
  return null;
});
