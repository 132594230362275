<template>
  <core-signs-labels-view-template title="Ingredients">
    <core-view-section title="Items">
      <ingredient-print @snackbar-error="$emit('snackbar-error', $event)"></ingredient-print>
      <data-tour
        tourName="ingredientTour"
        :steps="tourSteps"
        maxWidth="600px"
        :show="true"
      ></data-tour>
    </core-view-section>
  </core-signs-labels-view-template>
</template>

<script>
import IngredientPrint from './IngredientPrintComponent.vue';

export default {
  components: { IngredientPrint },
  data() {
    return {
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Ingredient Video Tour',
          },
          video: 'ingredient',
          placement: 'bottom',
        },
      ],
    };
  },
};
</script>
