<template>
  <core-view-template title="Item Update">
    <v-container>
      <v-row v-if="!created && !createdMulti">
        <edit-item-update
          :IsNew="true"
          @request-data-multi="requestDataMulti"
          :Processing.sync="processing"
        />
      </v-row>
      <v-row v-if="created">
        <v-btn @click="newItemClick">Item Update</v-btn>
      </v-row>
      <v-row v-if="createdMulti"> Item update created.</v-row>
      <v-row v-if="createdMulti">
        <router-link :to="viewItemsLink">View Requests</router-link>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditItemUpdate from '@/components/vendorportal/EditItemUpdate.vue';
import { convertBatchType, extractNewItemRequest, isOk } from '@/utils/FableUtils.fs.js';
import { initBatch } from '@/utils/DomainExports.fs.js';
import { BatchType } from '@/utils/VendorDomainTypes';

const vpModule = namespace('vendorportal');

@Component({
  components: { EditItemUpdate },
})
export default class ItemUpdateHome extends Vue {
  @vpModule.Action('createItemUpdate') createItemUpdate!: (NewItemRequestData) => Promise<unknown>;

  @vpModule.Mutation('setNewItemRequest') setNewItemRequest!: (request: any) => void;
  @vpModule.Action('createBatch') createBatch!: (batch: any) => Promise<unknown>;

  private created = false;
  private createdMulti = false;
  private processing = false;
  private requestId = '';

  private get viewItemsLink(): any {
    return {
      name: 'vendorportal_batchsearch',
    };
  }

  private newItemClick() {
    this.created = false;
    this.createdMulti = false;
  }

  private async requestData(
    data: any,
    isMulti: boolean | null | undefined,
    isLast: boolean | null | undefined,
  ) {
    const request = {
      AggregateId: data.id,
      RequestID: 0,
      PrivateLabel: data.privateLabel,
      RequestType: data.requestType,
      Status: data.status,
      FullUnitBarcode: data.fullUnitBarcode,
      CaseBarcode: data.caseBarcode,
      OtherBarcode: data.otherBarcode,
      ProductName: data.productName,
      RetailPackageSize: data.retailPackageSize,
      RetailPackageUOM: data.retailPackageUOM,
      ManufacturerAccountNumber: data.manufacturerAccountNumber,
      Brand: data.brand,
      VendorId: data.vendorId,
      ExternalItemNumber: data.externalItemNumber,
      MasterCase: data.masterCase,
      InnerCasePack: data.innerCasePack,
      LayerQuantity: data.layerQuantity,
      PalletQuantity: data.palletQuantity,
      DistributionNetwork: data.distributionNetwork,
      DirectToStore: data.directToStore,
      DirectToStoreOrVendorIntoPspdPurchaseCost: data.directToStoreOrVendorIntoPspdPurchaseCost,
      MfgSuggestedRetail: data.mfgSuggestedRetail,
      MapPrice: data.mapPrice,
      IMap: data.imap,
      AutoShipEligible: data.autoShipEligible,
      InitialDiscountFundingPercent: data.initialDiscountFundingPercent,
      OnGoingFundingDiscountPercent: data.onGoingFundingDiscountPercent,
      MaterialSafetyDataSheetNeeded: data.materialSafetyDataSheetNeeded,
      MinimumOrderQuantity: data.minimumOrderQuantity,
      UnitWeight: data.unitWeight,
      UnitWeightUOM: data.unitWeightUOM,
      UnitHeight: data.unitHeight,
      UnitWidth: data.unitWidth,
      UnitDepth: data.unitDepth,
      CaseHeight: data.caseHeight,
      CaseWidth: data.caseWidth,
      CaseDepth: data.caseDepth,
      ProductAttribute: data.productAttribute,
      Images: data.images,
      ProgramName: data.programName,
      HtsCode: data.htsCode,
      DutyRate: data.dutyRate,
      FobPort: data.fobPort,
      PluScan: data.pluScan,
      PluAssigned: data.pluAssigned,
      DaxItemNumber: data.daxItemNumber,
      SignsAndLabelsDescription1: data.signsAndLabelsDescription1,
      SignsAndLabelsDescription2: data.signsAndLabelsDescription2,
      VendorNumber: data.vendorNumber,
      ShipsToStores: data.shipsToStores,
      ShipUnitCostFromPspd: data.shipUnitCostFromPspd,
      RetailBasePrice: data.retailBasePrice,
      RetailHighPrice: data.retailHighPrice,
      Hierarchy: data.hierarchy,
      CategoryManagerName: data.categoryManagerName,
      PspdInventoryManagerName: data.pspdInventoryManagerName,
      Reason: data.reason,
      Distributors: data.dsdDistributor,
      IsMulti: isMulti,
      IsLast: isLast,
      BatchId: data.batchId,
      NewUPC: data.newFullUnitBarcode,
      AnticipatedDateOfItemChange: data.dateOfChange,
      Season: data.season,
      Seasonal: data.seasonal,
      Year: data.year,
      Category: data.category,
    };
    this.processing = true;
    try {
      const response = await this.createItemUpdate(request);
      if (isOk(response)) {
        const request = extractNewItemRequest(response);
        this.requestId = request.Agg.Id;
        this.setNewItemRequest(null);

        if (isMulti !== true) {
          this.created = true;
        }
      }
    } catch {
      if (isMulti !== true) {
        this.processing = false;
      }
    }
    if (isMulti !== true) {
      this.processing = false;
    }
  }

  private mounted() {
    this.setNewItemRequest(null);
  }

  private async requestDataMulti(data: any[]) {
    try {
      let rix = 0;
      const batch = initBatch();
      for (const request of data) {
        request.batchId = batch.BatchId;
        await this.requestData(request, true, rix === data.length - 1);
        rix++;
      }
      await this.createBatch({
        ...batch,
        BatchType: convertBatchType(BatchType.PackageChangeBatch),
        CategoryManagerName: data[0].categoryManagerName,
      });
      this.createdMulti = true;
    } catch (err) {
      console.error(err);
      this.$emit('snackbar-error', {
        text: 'Error processing multi item update request',
        err,
        id: '4ca2773b-648f-4031-b1a1-a19282eea7e2',
      });
    } finally {
      this.processing = false;
    }
  }
}
</script>
