import { Record } from "../fable-library-js.4.16.0/Types.js";
import { record_type, list_type, string_type } from "../fable-library-js.4.16.0/Reflection.js";
import { removeAt, tryFindIndex, cons, sort, empty } from "../fable-library-js.4.16.0/List.js";
import { List_distinct } from "../fable-library-js.4.16.0/Seq2.js";
import { comparePrimitives, stringHash } from "../fable-library-js.4.16.0/Util.js";

export class ItemHierarchyLookup extends Record {
    constructor(id, LookupId, Hierarchy) {
        super();
        this.id = id;
        this.LookupId = LookupId;
        this.Hierarchy = Hierarchy;
    }
}

export function ItemHierarchyLookup_$reflection() {
    return record_type("pogona_vendor_domain.VendorLookup.ItemHierarchyLookup", [], ItemHierarchyLookup, () => [["id", string_type], ["LookupId", string_type], ["Hierarchy", list_type(string_type)]]);
}

export const initItemHierarchyLookup = new ItemHierarchyLookup("ItemHierarchyLookup", "ItemHierarchyLookup", empty());

export function addToHierarchy(hierarchy, hierarchyLookup) {
    return new ItemHierarchyLookup(hierarchyLookup.id, hierarchyLookup.LookupId, sort(List_distinct(cons(hierarchy, hierarchyLookup.Hierarchy), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), {
        Compare: comparePrimitives,
    }));
}

export function removeFromHierarchy(hierarchy, hierarchyLookup) {
    let _arg;
    return new ItemHierarchyLookup(hierarchyLookup.id, hierarchyLookup.LookupId, (_arg = tryFindIndex((h) => (hierarchy === h), hierarchyLookup.Hierarchy), (_arg == null) ? hierarchyLookup.Hierarchy : removeAt(_arg, hierarchyLookup.Hierarchy)));
}

export class PlanogramLocationGroup extends Record {
    constructor(id, LookupId, Groups) {
        super();
        this.id = id;
        this.LookupId = LookupId;
        this.Groups = Groups;
    }
}

export function PlanogramLocationGroup_$reflection() {
    return record_type("pogona_vendor_domain.VendorLookup.PlanogramLocationGroup", [], PlanogramLocationGroup, () => [["id", string_type], ["LookupId", string_type], ["Groups", list_type(string_type)]]);
}

export const initPlanogramLocationGroup = new PlanogramLocationGroup("ItemHierarchyLookup", "ItemHierarchyLookup", empty());

export function addPlanogramLocationGroup(group, planogramLocationGroup) {
    return new PlanogramLocationGroup(planogramLocationGroup.id, planogramLocationGroup.LookupId, sort(List_distinct(cons(group, planogramLocationGroup.Groups), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), {
        Compare: comparePrimitives,
    }));
}

export function removeFromPlanogramLocationGroup(group, planogramLocationGroup) {
    let _arg;
    return new PlanogramLocationGroup(planogramLocationGroup.id, planogramLocationGroup.LookupId, (_arg = tryFindIndex((g) => (group === g), planogramLocationGroup.Groups), (_arg == null) ? planogramLocationGroup.Groups : removeAt(_arg, planogramLocationGroup.Groups)));
}

