<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-data-table
            v-if="batchSearchResult"
            :headers="headers"
            :items="batchSearchResult.Results"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="searchInProgress"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options="options"
            :disable-pagination="true"
            class="elevation-1 scrollable mb-10"
            id="batchSearchDataTable"
            @update:options="updateSortBy"
            hide-default-footer
            item-key="BatchId"
            height="100%"
            :server-items-length="batchSearchResult.TotalCount"
          >
            <template v-slot:header.BatchId="{ header }">
              <span class="text-left">{{ header.text }}</span>
            </template>
            <template v-slot:item.BatchId="{ item }">
              <span class="text-left">{{ item.BatchId }}</span>
            </template>
            <template v-slot:item.downloadSource="{ item }">
              <v-btn
                v-if="item.BatchType === 'NewItemBatch'"
                :loading="downloading === true"
                :disabled="downloading === true"
                @click="exportData(item.BatchId)"
                >Download</v-btn
              >
              <v-btn
                v-if="item.BatchType === 'PackageChangeBatch'"
                :loading="downloading === true"
                :disabled="downloading === true"
                @click="exportPackageChange(item.BatchId)"
                >Download</v-btn
              >
            </template>
            <template v-slot:item.CreatedAt="{ value }">
              {{ value | formatUtcDateTime }}
            </template>
            <template v-slot:header.Archived="{ header }">
              <v-col>
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  :hideable="false"
                ></pcl-store-listing-all-header-text>

                <v-row>
                  <v-autocomplete
                    prepend-icon="playlist_add_check"
                    v-model="batchRequestAllFields.Archived.SearchValue"
                    :items="yesNoBools"
                    @blur="paramUpdate"
                    multiple
                    autocomplete="off"
                    item-text="text"
                    item-value="value"
                    clearable
                  >
                  </v-autocomplete>
                </v-row>
              </v-col>
            </template>

            <template v-slot:item.Archived="{ item }">
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <v-checkbox
                    v-model="item.Archived"
                    :bind="attrs"
                    :on="on"
                    @click.stop="
                      batchToArchive = item;
                      changeArchiveDialog = true;
                    "
                    class="changeArchiveCheckbox"
                  />
                </template>
                <span>Click here to flip archive status</span>
              </v-tooltip>
            </template>

            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="
                      batchSearchResult &&
                      batchSearchResult.Results &&
                      batchSearchResult.TotalCount > 0
                    "
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="refreshClicked">Refresh</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-dialog v-model="changeArchiveDialog" max-width="600px" persistent>
            <v-card>
              <v-card-title>
                <span class="headline">Change archive status</span>
              </v-card-title>
              <v-card-text>
                Are you sure you want to set this to
                <b style="font-size: 1em">{{ batchToArchive.Archived ? '' : 'un' }}archived</b>
                for this batch?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="changingArchiveStatus"
                  :loading="changingArchiveStatus"
                  @click="changeArchiveStatus"
                  >Yes</v-btn
                >
                <v-btn
                  :disabled="changingArchiveStatus"
                  :loading="changingArchiveStatus"
                  @click="
                    changeArchiveDialog = false;
                    batchToArchive.Archived = !batchToArchive.Archived;
                  "
                  >No</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import ODataProps from '@/utils/ODataProps';
import { BatchSearchResult, SearchParam, SearchType } from '@/utils/VendorDomainTypes';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import buildQuery from 'odata-query';
import BatchRequestAllFields from '@/store/modules/vendorportal/BatchRequestAllFields';
import { StoreListingAllSearchType } from '@psp/pogona_pcl_client_js';
import PclStoreListingAllHeaderText from '@/components/pcl/PclStoreListingAllHeaderText.vue';
import PclNumberRange from '@/components/pcl/PclNumberRange.vue';
import PclDateRange from '@/components/pcl/PclDateRange.vue';
import { PogovBatch } from '@psp/vendorapi';
import { convertBatchTypeString, raiseIfError } from '@/utils/FableUtils.fs.js';
import FileSaver from 'file-saver';
import dateFormat from 'dateformat';
import * as parseHttpHeader from 'parse-http-header';

const vpModule = namespace('vendorportal');

@Component({
  components: { PclStoreListingAllHeaderText, PclNumberRange, PclDateRange },
})
export default class BatchSearchDataTable extends Vue {
  @vpModule.State('batchSearchResult') batchSearchResult!: BatchSearchResult;
  @vpModule.Action('performSearchBatch') performSearchBatch!: (params: any) => Promise<void>;
  @vpModule.Action('exportSearchNewItemRequest') exportSearchNewItemRequest!: (
    data: any,
  ) => Promise<any>;
  @vpModule.Action('exportPackageChangeBatch') exportPackageChangeBatch!: (
    data: any,
  ) => Promise<any>;
  @vpModule.Action('exportD365Export') exportD365Export!: (data: any) => Promise<any>;
  @vpModule.Action('exportD365DistributorExport') exportD365DistributorExport!: (
    data: any,
  ) => Promise<any>;

  @vpModule.Action('archiveBatch') archiveBatch!: (data: PogovBatch) => Promise<any>;
  @vpModule.Action('unArchiveBatch') unArchiveBatch!: (data: PogovBatch) => Promise<any>;

  public headers = [
    { text: 'Batch ID', value: 'BatchId', sortable: false },
    { text: 'Batch Type', value: 'BatchType' },
    { text: 'Download', value: 'downloadSource', sortable: false },
    { text: 'Export D365', value: 'exportd365', sortable: false },
    { text: 'Category Manager', value: 'CategoryManagerName' },
    { text: 'Created By', value: 'CreatedBy' },
    { text: 'Created At', value: 'CreatedAt' },
    { text: 'Archived', value: 'Archived' },
  ];

  public yesNoBools = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  public searchInProgress = false;
  public options: any = { sortBy: ['CreatedAt'], sortDesc: [true] };
  public infiniteId = +new Date();
  public odataProps = new ODataProps();
  public defaultSkip = 50;
  public lastCount = 0;
  public batchRequestAllFields = new BatchRequestAllFields();
  public changeArchiveDialog = false;
  public changingArchiveStatus = false;
  public exportingData = false;
  public batchToArchive: PogovBatch = {} as PogovBatch;
  public downloading = false;
  public exportingD365 = false;

  public refreshClicked() {
    document.location.reload();
  }

  public async executeSearch(searchParams: SearchParam[], odata: ODataProps) {
    const odataStr = buildQuery(odata);

    this.searchInProgress = true;
    try {
      await this.performSearchBatch({
        Params: searchParams,
        Odata: odataStr,
        Skip: odata.skip,
      });
      this.odataProps.skip += this.defaultSkip;
    } catch (err) {
      console.error(err);
    } finally {
      this.searchInProgress = false;
    }
  }

  createSearchParams() {
    // if we don't use settimeout, cleared values will not be recongized
    return new Promise<SearchParam[]>((resolve, reject) => {
      setTimeout(() => {
        try {
          const searchParams = [] as SearchParam[];
          for (const fieldKey of Object.keys(this.batchRequestAllFields)) {
            if (
              (this.batchRequestAllFields[fieldKey] &&
                this.batchRequestAllFields[fieldKey].SearchValue &&
                this.batchRequestAllFields[fieldKey].SearchValue.length > 0) ||
              (this.batchRequestAllFields[fieldKey] &&
                this.batchRequestAllFields[fieldKey].SearchValue &&
                this.batchRequestAllFields[fieldKey].SearchType ===
                  StoreListingAllSearchType.DateRange)
            ) {
              searchParams.push(this.batchRequestAllFields[fieldKey]);
            }
          }
          resolve(searchParams);
        } catch (err) {
          reject(err);
        }
      }, 0);
    });
  }

  async paramUpdate() {
    this.odataProps.skip = 0;
    await this.executeSearch(await this.createSearchParams(), this.odataProps);
  }

  public async updateSortBy(event) {
    this.odataProps.skip = 0;

    let orderByArray: Array<string> = [] as Array<string>;
    if (event.sortBy && event.sortBy.length > 0) {
      for (const oix in event.sortBy) {
        const asc = event.sortDesc && event.sortDesc.length > oix && event.sortDesc[oix] === false;
        orderByArray.push(`${event.sortBy[oix]} ${asc ? 'asc' : 'desc'}`);
      }
    }

    this.infiniteId = this.infiniteId + 1;
    if (orderByArray && orderByArray.length > 0) {
      this.odataProps.orderBy = orderByArray;
    }

    await this.executeSearch(await this.createSearchParams(), this.odataProps);
  }

  public infiniteHandler($state: any) {
    this.createSearchParams().then(params => {
      this.executeSearch(params, this.odataProps)
        .then(() => {
          if (
            this.batchSearchResult.Results.length % this.odataProps.top === 0 &&
            this.batchSearchResult.Results.length !== this.lastCount
          ) {
            $state.loaded();
            this.odataProps.skip += this.defaultSkip;
          } else {
            $state.complete();
          }
          this.lastCount = this.batchSearchResult.Results.length;
        })
        .catch(() => {
          $state.complete();
        });
    });
  }

  public fixupBatch(batch: any) {
    return {
      ...batch,
      CreatedAt: new Date(batch.CreatedAt),
      LastModifiedAt: new Date(batch.LastModifiedAt),
      BatchType: convertBatchTypeString(batch.BatchType),
    };
  }

  public async handleArchive() {
    if (this.batchToArchive.Archived) {
      return await this.archiveBatch(this.fixupBatch(this.batchToArchive));
    } else {
      return await this.unArchiveBatch(this.fixupBatch(this.batchToArchive));
    }
  }

  public async changeArchiveStatus() {
    try {
      this.changingArchiveStatus = true;
      const result = await this.handleArchive();
      setTimeout(async () => {
        this.changingArchiveStatus = false;
        this.changeArchiveDialog = false;
        raiseIfError(result);
        await this.paramUpdate();
      }, 5000);
    } catch (err) {
      this.changingArchiveStatus = false;
      this.changeArchiveDialog = false;
      this.$emit('snackbar-error', {
        text: 'Error saving archive status.',
        err,
        id: '55d177dc-1dcf-4dfb-bb8e-df7a96bfea4a',
      });
    }
  }

  public async exportAndDownloadData(
    batchId: string,
    fileNamePart: string,
    exportAction: (data: any) => Promise<any>,
  ) {
    const odataProps = new ODataProps();
    odataProps.skip = 0;
    odataProps.top = 99999;
    odataProps.orderBy = ['NewItemRequestId asc'];

    const odataStr = buildQuery(odataProps);
    const searchParams = [
      { Field: 'BatchId', SearchValue: [batchId], SearchType: SearchType.In },
    ] as SearchParam[];

    const exportData = await exportAction({
      Params: searchParams,
      Odata: odataStr,
      Skip: 0,
      AxiosConfig: {
        responseType: 'arraybuffer',
      },
    });

    if (exportData && exportData.data && exportData.data.byteLength > 0) {
      let fileName = `${fileNamePart}-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`;
      if (exportData?.headers['content-disposition']) {
        const parsedHeader = parseHttpHeader(exportData.headers['content-disposition']);
        if (parsedHeader.filename) {
          fileName = parsedHeader.filename.replace(/"/g, '');
        }
      }

      const fileBlob = new Blob([new Uint8Array(exportData.data, 0, exportData.data.byteLength)]);
      FileSaver.saveAs(fileBlob, fileName);
    }
  }

  public async exportData(batchId: string) {
    this.exportingData = true;
    try {
      await this.exportAndDownloadData(
        batchId,
        'vendor-new-items-export',
        this.exportSearchNewItemRequest,
      );
      this.exportingData = false;
    } catch (err) {
      this.exportingData = false;
      this.$emit('snackbar-error', {
        text: 'Error exporting data',
        err,
        id: '281614fe-ebef-4dbd-8b18-053c58570107',
      });
    }
  }

  public async exportPackageChange(batchId: string) {
    this.exportingData = true;
    try {
      await this.exportAndDownloadData(batchId, 'package-change', this.exportPackageChangeBatch);
      this.exportingData = false;
    } catch (err) {
      this.exportingData = false;
      this.$emit('snackbar-error', {
        text: 'Error exporting data',
        err,
        id: '39e9bb56-7b4f-4c68-b44e-bc042565d2b0',
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.changeArchiveCheckbox {
  cursor: pointer;
}
</style>
