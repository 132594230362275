<template>
  <div>
    <v-dialog v-model="showDialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-col cols="12">
          <v-row align="start" class="pointer mt-0" v-on="on">
            <v-col>
              <v-text-field
                v-model="numberFormatted"
                readonly
                prepend-icon="format_list_numbered_rtl"
                :clearable="numberFormatted != defaultFormat"
                @click:clear.prevent.stop="cleared"
                class="pointer"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <v-card>
        <v-card-title v-if="dialogHeader && dialogHeader.length > 0">
          <span class="headline">{{ dialogHeader }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="innerValue.Start"
                  prepend-icon="grid_on"
                  type="number"
                  label="Start"
                  @change="onChange"
                  :clearable="true"
                  :error-messages="startErrorMessages"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="innerValue.End"
                  prepend-icon="grid_on"
                  type="number"
                  label="End"
                  @change="onChange"
                  :clearable="true"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="showDialog = false"
            :disabled="startErrorMessages && startErrorMessages.length > 0"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator';
import { NumberRange } from '@psp/pogona_pcl_client_js';
import moment from 'moment';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({})
export default class PclDateRange extends Vue {
  private innerValue!: any;
  private modelValue!: NumberRange;
  private showDialog = false;
  private startErrorMessages: Array<string> = [];

  private defaultFormat = '###';
  public numberFormatted = this.defaultFormat;

  @ModelSync('numberRange', 'change', { type: NumberRange })
  readonly value!: NumberRange;

  @Prop({ required: false })
  readonly dialogHeader!: string;

  @Watch('value', { deep: true }) valueChanged() {
    this.handleInnerValue();
  }

  public created() {
    this.handleInnerValue();
  }

  public cleared() {
    this.innerValue = { Start: null, End: null };
    this.modelValue = new NumberRange();

    this.onChange();

    setTimeout(() => {
      this.numberFormatted = this.defaultFormat;
    }, 0);
  }

  public onChange() {
    this.handleInnerValue();

    this.$emit('input', this.modelValue);
    this.$emit('change', this.modelValue);
  }

  public handleInnerValue() {
    if (!this.innerValue) {
      this.innerValue = { Start: null, End: null };
    }
    if (!this.modelValue) {
      this.modelValue = new NumberRange();
    }

    if (this.innerValue.Start) {
      this.modelValue.Start = parseFloat(this.innerValue.Start);
    } else {
      this.modelValue.Start = null;
    }
    if (this.innerValue.End) {
      this.modelValue.End = parseFloat(this.innerValue.End);
    } else {
      this.modelValue.End = null;
    }

    // is start greater than end?
    if (
      this.modelValue.Start &&
      this.modelValue.End &&
      this.modelValue.Start > this.modelValue.End
    ) {
      this.startErrorMessages = ['Start must be less than end.'];
    } else {
      this.startErrorMessages = [];
    }

    this.numberFormatted = this.formatNumber();
  }

  public formatNumber() {
    let formatted = '';
    if (this.value) {
      if (this.value.Start) {
        formatted = this.value.Start.toString();
        if (!this.modelValue.End) {
          formatted = `>= ${formatted}`;
        }
      }
      if (this.modelValue.End) {
        formatted += `${formatted.length > 0 ? ' - ' : '<= '}${this.value.End}`;
      }
    }

    if (formatted.length === 0) {
      return this.defaultFormat;
    }
    return formatted;
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer !important;
}
</style>
