import md5 from 'md5';
import { Base64 } from 'js-base64';

export default class RenderLabelData {
  constructor(
    batchInfo,
    startingPosition,
    forceNewRender,
    includeSetDelimters,
    approvedTypes,
    svgTemplateTypes,
    username,
    footerInformation,
    sampleRender,
    additionalCompleteUpdate,
    autoCompleteBatch,
    showCompleteToggle,
    pageBreakOnNewStoreSet,
    originatingPage,
    excludeFromRenderingProgress,
  ) {
    this.batchInfoId = (batchInfo && batchInfo.batchInfoId) || 0;
    this.startingPosition = startingPosition - 1; // arrays start at 0
    this.batchHash = '';
    this.force = forceNewRender;
    this.includeSetDelimters = includeSetDelimters;
    this.approvedTypes = approvedTypes;
    this.svgTemplateTypes = svgTemplateTypes;
    this.username = username;
    this.footerInformation = footerInformation;
    this.sampleRender = sampleRender;
    this.additionalCompleteUpdate = additionalCompleteUpdate;
    this.autoCompleteBatch =
      autoCompleteBatch === null || autoCompleteBatch === undefined ? false : autoCompleteBatch;
    this.showCompleteToggle =
      showCompleteToggle === null || showCompleteToggle === undefined ? true : showCompleteToggle;
    this.pageBreakOnNewStoreSet =
      pageBreakOnNewStoreSet === null || pageBreakOnNewStoreSet === undefined
        ? false
        : pageBreakOnNewStoreSet;
    this.originatingPage = originatingPage;
    this.excludeFromRenderingProgress = excludeFromRenderingProgress || false;

    this.setBatchHash();
  }

  setBatchHash() {
    const batchHash = md5(JSON.stringify(this));

    // Convert the md5 hash into something that looks like a guid
    const hashToGuid = new RegExp('(\\w{8})(\\w{4})(\\w{4})(\\w{4})(\\w{12})');
    const hashMatch = hashToGuid.exec(batchHash);

    this.batchHash = `${hashMatch[1]}-${hashMatch[2]}-${hashMatch[3]}-${hashMatch[4]}-${hashMatch[5]}`;
  }

  base64RenderData() {
    return Base64.encode(JSON.stringify(this));
  }

  static decodeBase64RenderData(s) {
    return JSON.parse(Base64.decode(s));
  }
}
