<template>
  <v-dialog v-model="submissionDialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <strong v-on="on">Submit issue</strong>
    </template>
    <v-card>
      <v-card-title> Submit Issue </v-card-title>
      <v-card-text>
        <v-text-field v-model="title" label="Brief description of issue"></v-text-field>
        <v-textarea
          v-model="body"
          max-length="1024"
          label="Describe the issue (required)"
        ></v-textarea>
        <v-row class="ml-1">
          <p class="screenshot-title">Screenshot</p>
        </v-row>
        <v-row>
          <core-screenshot
            class="ml-1"
            v-model="screenshot"
            @start="submissionDialog = false"
            @done="submissionDialog = true"
            @error="
              $emit('snackbar-error', {
                text: 'Error taking screenshot',
                err: $event,
                id: '45a3cac7-ab72-48ae-9add-b9d82a2f9c23',
              })
            "
          ></core-screenshot>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn @click="submitForm" :disabled="submitFormDisabled" :loading="submitFormLoading"
              >Submit</v-btn
            >
            <v-btn @click="closeDialog">Close</v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      submissionDialog: false,
      title: null,
      body: null,
      submitFormLoading: false,
      activatedScreenshot: false,
      screenshot: '',
    };
  },
  computed: {
    ...mapState('app', ['username']),
    submitFormDisabled() {
      return this.submitFormLoading || this.body === null || this.body.length === 0;
    },
  },
  methods: {
    closeDialog() {
      this.submissionDialog = false;
      this.title = null;
      this.body = null;
      this.screenshot = '';
    },
    async submitForm() {
      this.submitFormLoading = true;
      try {
        await this.$authApi.http.post('pogona/formsubmit', {
          title: this.title,
          body: this.body,
          username: this.username,
          screenshotDataUrl: this.screenshot,
          currentUrl: document.location.href,
        });
        this.submitFormLoading = false;

        this.$emit('snackbar-success', 'Submitted issue, thank you!');
        this.closeDialog();
      } catch (err) {
        this.submitFormLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error submitting issue',
          err,
          id: 'c7c234e9-ecdb-4bd5-8b74-be75ecdd6fe0',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
strong {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.screenshot-title {
  color: rgb(170, 170, 170);
}
</style>
