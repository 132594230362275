<template>
  <core-signs-labels-view-template title="Store Set Print">
    <core-view-section title="Sets">
      <v-row>
        <v-btn-toggle v-model="reportButtonGroup" group id="reportButtonGroup" class="mt-4">
          <v-dialog max-width="600px" v-model="movementReportDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="!enablePogReport || loadingReportData"
                >Movement Report</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Choose Date Range</span>
              </v-card-title>
              <v-card-text>
                <v-col>
                  <v-row>
                    <data-date-picker v-model="movementBegin" label="Report Start Date" />
                  </v-row>
                  <v-row>
                    <data-date-picker v-model="movementEnd" label="Report End Date" />
                  </v-row>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="openStoreSetMovementReport"
                  :disabled="!validMovementReportBeginAndEnd"
                  :loading="loadingReportData"
                  >Report</v-btn
                >
                <v-btn @click="movementReportDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn
            @click="openStoreSetOrderSheetReport"
            :disabled="!enablePogReport || loadingReportData"
            >POG Order Sheet</v-btn
          >

          <v-dialog max-width="600px" v-model="activatedStoreSetsReportDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="loadingReportData">Active POGS</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Active POGS</span>
              </v-card-title>
              <v-card-text>
                <v-col cols="12">
                  <v-row>
                    <v-checkbox
                      prepend-icon="sort"
                      v-model="orderByPrintSequenceActivatedReport"
                      label="Sort by print sequence"
                    ></v-checkbox>
                  </v-row>
                  <v-row>
                    <v-checkbox
                      prepend-icon="settings_input_composite"
                      v-model="isEndCapActivatedReport"
                      label="Endcap Data"
                    ></v-checkbox>
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="openActiveStoreSetsReport">Report</v-btn>
                    <v-btn @click="activatedStoreSetsReportDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-col>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog max-width="600px" v-model="allStoreSetsReportDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="loadingReportData">All Company POGs</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">All Company POGs</span>
              </v-card-title>
              <v-card-text>
                <v-col cols="12">
                  <v-row>
                    <v-checkbox
                      prepend-icon="sort"
                      v-model="excludedActivatedSets"
                      label="Exclude my current POGs"
                    ></v-checkbox>
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="openAllStoreSetsReport">Report</v-btn>
                    <v-btn @click="allStoreSetsReportDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-col>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-btn-toggle>
      </v-row>
      <v-row>
        <store-set
          :setType="1"
          v-model="storeSet"
          @snackbar-error="$emit('snackbar-error', $event)"
          @selectedChange="selectedChange"
          :selectedTab.sync="selectedTab"
          :selectedStoreSet.sync="selectedStoreSet"
        ></store-set>
      </v-row>
    </core-view-section>

    <core-view-section v-if="showStoreSetDataSection" title="Items" cols="12">
      <v-col id="storeSetData">
        <store-set-data
          :setType="1"
          v-model="storeSetData"
          :storeSet="storeSet"
          :selectedTab="selectedTab"
          :allSets="storeSet.allSelected"
          :selectedStoreSet="selectedStoreSet"
          @createdBatchInfoEvent="createdBatchInfoEvent"
          @snackbar-error="$emit('snackbar-error', $event)"
        >
        </store-set-data>
      </v-col>
    </core-view-section>

    <v-divider id="batchInfoItemsDivider" class="ma-8"></v-divider>

    <core-view-section
      v-if="createdBatchInfo"
      v-show="createdBatchInfo.printImmediately !== true"
      title="Queue Items"
    >
      <batch-check-items
        v-model="batchInfoItems"
        :batchInfo.sync="createdBatchInfo"
        @dataBound="batchInfoItemsDataBound"
        @error="$emit('snackbar-error', $event)"
        :footerBatchAndId="footerBatchAndId"
        :autoCompleteBatch="true"
        :showCompleteToggle="false"
        :showIncludeSetDelimtersCheckbox="true"
        :showChangeAllLabelTypesButton="false"
        originatingPage="Store Set"
      ></batch-check-items>
    </core-view-section>

    <data-tour tourName="storeSetTour" :steps="tourSteps" maxWidth="600px" :show="true"></data-tour>

    <data-report-dialog
      v-model="reportDialog"
      :reportData="reportData"
      :loading.sync="loadingReportData"
      :templateName="reportTemplateName"
      :supportsExcel="false"
      @snackbar-error="$emit('snackbar-error', $event)"
    ></data-report-dialog>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import BatchCheckItems from './BatchCheckItems.vue';
import StoreSet from './StoreSetComponent.vue';
import StoreSetData from './StoreSetDataComponent.vue';
import PogonaDataHelper from '@/utils/PogonaDataHelper';
import moment from 'moment';

export default {
  components: {
    BatchCheckItems,
    StoreSet,
    StoreSetData,
  },
  data() {
    return {
      storeSet: null,
      storeSetData: null,
      selectedTab: null,
      selectedStoreSet: null,
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Store Set Video Tour',
          },
          video: 'store_set',
          placement: 'bottom',
        },
      ],
      reportButtonGroup: null, // No Options Selected
      dataHelper: new PogonaDataHelper(),
      movementReportDialog: false,
      reportDialog: false,
      loadingReportData: false,
      reportData: {},
      movementBegin: moment().startOf('day').add(-13, 'weeks').format('YYYY-MM-DD'),
      movementEnd: moment().startOf('day').format('YYYY-MM-DD'),
      activatedStoreSetsReportDialog: false,
      allStoreSetsReportDialog: false,
      excludedActivatedSets: false,
      orderByPrintSequenceActivatedReport: true,
      isEndCapActivatedReport: false,
      reportTemplateName: '',
    };
  },
  computed: {
    ...mapState('app', ['storeNumber', 'username']),
    batchInfoItems: {
      get() {
        return this.$store.state.storeset.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit('storeset/batchInfoItems', val);
      },
    },
    createdBatchInfo: {
      get() {
        return this.$store.state.storeset.createdBatchInfo || null;
      },
      set(val) {
        this.$store.commit('storeset/createdBatchInfo', val);
      },
    },
    footerBatchAndId() {
      return `POG ${
        this.storeSet && this.storeSet.selected && this.storeSet.selected.length > 0
          ? this.storeSet.selected[0].setId
          : ''
      }`;
    },
    showStoreSetDataSection() {
      return (
        this.selectedStoreSet &&
        this.selectedStoreSet.selected &&
        this.selectedStoreSet.selected.length > 0
      );
    },
    enablePogReport() {
      return this.storeSet && this.storeSet.selected && this.storeSet.selected.length === 1;
    },
    validMovementReportBeginAndEnd() {
      let valid = true;

      if (!this.movementBegin || !this.movementEnd || this.movementBegin > this.movementEnd) {
        valid = false;
      }

      return valid;
    },
  },
  methods: {
    selectedChange() {
      this.createdBatchInfo = null;
      this.$emit('selectedChange');
    },
    async openStoreSetMovementReport() {
      this.movementReportDialog = false;

      await this.openReport(
        `label/report/store-set-movement/${this.storeNumber}/${this.storeSet.selected[0].setId}?startDate=${this.movementBegin}&endDate=${this.movementEnd}`,
        '/store-set-movement/main',
      );
    },
    async openStoreSetOrderSheetReport() {
      await this.openReport(
        `label/report/pog-order/${this.storeNumber}/${this.storeSet.selected[0].setId}`,
        '/pog-order-sheet-report/main',
      );
    },
    async openActiveStoreSetsReport() {
      this.activatedStoreSetsReportDialog = false;

      await this.openReport(
        `label/report/activated-store-sets/${this.storeNumber}/${this.isEndCapActivatedReport}/${this.orderByPrintSequenceActivatedReport}`,
        '/activated-store-sets/main',
      );
    },
    async openAllStoreSetsReport() {
      this.allStoreSetsReportDialog = false;

      await this.openReport(
        `label/report/all-store-sets/${this.storeNumber}/${this.excludedActivatedSets}?$orderby=setId`,
        '/all-store-sets/main',
      );
    },
    async openReport(url, templateName) {
      this.reportDialog = true;
      this.loadingReportData = true;
      this.reportTemplateName = templateName;

      try {
        this.reportData = await this.dataHelper.getAllReportData({
          $authApi: this.$authApi,
          url,
        });
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error loading report data.',
          err,
          id: 'fde0169e-4dd1-4faa-a722-88fa5e501a66',
        });
        this.reportDialog = false;
        this.loadingReportData = false;
      }
    },
    createdBatchInfoEvent(e) {
      this.batchInfoItems = {};
      this.createdBatchInfo = e;
    },
    batchInfoItemsDataBound() {
      // Jump to the batch data.
      this.setToBatchLoading = false;
      this.$vuetify.goTo('#batchInfoItemsDivider');
    },
    async initStoreSetData(e) {
      this.$emit;
    },
  },
  watch: {
    storeSet: {
      handler(val) {
        this.$store.commit('storeset/storeSet', val);
      },
      deep: true,
    },
    createdBatchInfo: {
      handler(val) {
        this.$store.commit('storeset/createdBatchInfo', val);
      },
      deep: true,
    },
    selectedTab: {
      handler() {
        this.createdBatchInfo = null;
      },
    },
  },
};
</script>
