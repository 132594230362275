<template>
  <v-container fill-height fluid>
    <v-layout justify-center align-center>
      <v-flex xs12>
        <material-card :color="color" :title="title" :text="text">
          <slot></slot>
        </material-card>
      </v-flex>
    </v-layout>
    <core-disable-page :disablePromise="disablePromise">
      <template>
        <slot name="diabled-dialog"></slot>
      </template>
    </core-disable-page>
  </v-container>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default() {
        return 'green';
      },
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    disablePromise: null,
  },
  beforeCreate() {
    // by default we hide the store number
    this.$store.commit('app/showStoreNumber', false);
  },
};
</script>
