/* eslint-disable */
import menuData from '@/data/menu.json';

export default {
  methods: {
    async userHasRightsToRoute(route) {
      const menuKeys = Object.keys(menuData);

      for (let vix = 0; vix < menuKeys.length; vix++) {
        const menuItem = menuData[menuKeys[vix]];

        // Check to see if route level menu item matches route and has rights
        if (
          menuItem.route &&
          (menuItem.route.name === route.name || menuItem.route.path === route.fullPath)
        ) {
          return await this.userHasRightsToMenuItem(menuItem);
        }

        // Check to see if dropdown list level menu items match route and has rights
        if (
          menuItem.entries &&
          typeof menuItem.entries == 'object' &&
          menuItem.entries.length > 0
        ) {
          for (let eix = 0; eix < menuItem.entries.length; eix++) {
            const entryRoute = menuItem.entries[eix].route;
            if (
              entryRoute &&
              (entryRoute.name === route.name || entryRoute.path === route.fullPath)
            ) {
              let innerMenuItem = menuItem.entries[eix];
              if (!innerMenuItem.requiredRole) {
                innerMenuItem = menuItem;
              }
              return await this.userHasRightsToMenuItem(innerMenuItem);
            }
          }
        }
      }

      return true;
    },
    async userHasRightsToMenuItem(menuItem) {
      if (menuItem && menuItem.requiredRole && this.$authApi) {
        // Check to see if the token has our required role
        return await this.$authApi.roleHasRights(menuItem.requiredRole);
      }
      return true;
    },
  },
};
