import { defineStore } from 'pinia';
import { ref } from 'vue';
import buildQuery from 'odata-query';
import { DwCoupon } from '@psp/pogona_ecom_client_js/DwCoupon';
import { PogonaEcomClient, CouponUploadPayload } from '@psp/pogona_ecom_client_js';
import ODataProps from '@/utils/ODataProps';
import blobToBuffer from 'blob-to-buffer';
import store from '@/store';

export enum ImageUploadState {
  waiting = 1,
  loading,
  complete,
  error,
  tooLarge,
}

export class CouponImage {
  constructor(
    public file: File | null = null,
    public uploadState: ImageUploadState = ImageUploadState.waiting,
    public url: string | null = null,
  ) {}
}

export const useCouponimageuploadstore = defineStore('couponimageuploadstore', () => {
  const client = ref<PogonaEcomClient | null>(null);
  const couponImages = ref<CouponImage[] | null>(null);
  const uploadInProgress = ref(false);
  const dwCoupons = ref<DwCoupon[]>([] as DwCoupon[]);
  const dwCouponsGetInProgress = ref(false);
  const dwCouponsCount = ref(0);
  const dwCouponsGetInProgressCount = ref(false);
  const dwCouponsOdata = ref<ODataProps | null>(null);

  function init(http: any, baseUrl: string | null): void {
    client.value = new PogonaEcomClient(null, http, baseUrl);
  }

  async function uploadCouponImages() {
    uploadInProgress.value = true;

    try {
      const convertBlobToBase64: any = async blob => {
        return new Promise((resolve, reject) => {
          blobToBuffer(blob, (err, buffer) => {
            if (err) {
              reject(err);
            }

            resolve(buffer.toString('base64'));
          });
        });
      };

      const uploadPromise = async couponImage => {
        let err = null;
        let fileData = '';

        try {
          fileData = await convertBlobToBase64(couponImage.file);
        } catch (e: any) {
          err = e;
        }

        if (!err) {
          try {
            const response = await client.value!.postCouponImage(
              new CouponUploadPayload(couponImage.file.name, fileData),
            );
            return { couponImage, response, err };
          } catch (e: any) {
            err = e;
          }
        }

        return { couponImage, response: null, err };
      };

      const uploadBlobs = async imageUploads => {
        for (const imageUpload of imageUploads) {
          const result = await imageUpload;

          if (result && !result.err) {
            result.couponImage.url = `/ecom2/couponimage/${
              result.response.data.blobUrl
            }?_=${new Date().getTime()}`;
            result.couponImage.uploadState = ImageUploadState.complete;
          } else {
            result.couponImage.uploadState = ImageUploadState.error;
          }
        }

        return [];
      };

      let imageUploads: Promise<any>[] = [];
      const maxImageUploads = 2;
      for (const couponImage of couponImages.value ?? []) {
        // skip complete ones
        if (
          couponImage.uploadState === ImageUploadState.complete ||
          couponImage.uploadState === ImageUploadState.tooLarge
        ) {
          continue;
        }

        couponImage.uploadState = ImageUploadState.loading;
        imageUploads.push(uploadPromise(couponImage));

        if (imageUploads.length >= maxImageUploads) {
          imageUploads = await uploadBlobs(imageUploads);
        }
      }

      if (imageUploads.length >= 0) {
        imageUploads = await uploadBlobs(imageUploads);
      }

      uploadInProgress.value = false;
    } catch (err) {
      store.commit('setError', {
        err: err,
        text: 'Error uploading coupon images',
        id: 'e1b1bd75-d32c-4c53-aae8-1428364a6870',
      });

      for (const couponImage of couponImages.value ?? []) {
        couponImage.uploadState = ImageUploadState.error;
      }
      uploadInProgress.value = false;
    }
  }

  function getDwCoupons(odata: ODataProps): Promise<any> {
    dwCouponsGetInProgress.value = true;
    dwCouponsOdata.value = odata;

    let odataStr = '';
    if (odata) {
      odataStr = buildQuery(odata).substring(1);
    } else {
      odataStr = buildQuery({ top: 50 }).substring(1);
    }

    return client
      .value!.getDwCoupons(odataStr)
      .then(x => {
        const arrData = Object.assign([] as DwCoupon[], x.data) || ([] as DwCoupon[]);
        if (odata.skip === 0 || !x.data) {
          dwCoupons.value = arrData;
        } else if (
          x.data &&
          arrData.length > 0 &&
          dwCoupons.value.findIndex(i => i.Id === arrData[0].Id) === -1
        ) {
          dwCoupons.value.push(...arrData);
        }
        dwCouponsGetInProgress.value = false;
      })
      .catch(err => {
        store.commit('setError', {
          err: err,
          text: 'Error retrieving coupons',
          id: '90e26a54-39a6-42f6-845a-5e673b155806',
        });
        throw err;
      });
  }

  function getDwCouponsCount(odata: ODataProps): Promise<any> {
    dwCoupons;
    dwCouponsGetInProgressCount.value = true;
    dwCouponsOdata.value = odata;

    let odataStr = '';
    if (odata) {
      odataStr = buildQuery(odata).substring(1);
    } else {
      odataStr = buildQuery({ top: 50 }).substring(1);
    }

    return client
      .value!.getDwCouponsCount(odataStr)
      .then(x => {
        dwCouponsCount.value = x.data;
        dwCouponsGetInProgressCount.value = false;
      })
      .catch(err => {
        store.commit('setError', {
          err: err,
          text: 'Error retrieving coupon count',
          id: 'b8fd041d-db24-4f52-921c-d1d96642d909',
        });
        throw err;
      });
  }

  function appendCouponImages(images: File[]): void {
    const couponImagesLocal: CouponImage[] = couponImages.value || [];
    for (let i = 0; i < images.length; i++) {
      const couponImageIx = couponImagesLocal.findIndex(x => x.file?.name === images[i].name);

      // remove it first for reactivity
      if (couponImageIx > -1) {
        removeCouponImage(couponImagesLocal[couponImageIx]);
      }

      let imageUploadState = ImageUploadState.waiting;
      // limit to 2mb
      if (images[i].size > 2097152) {
        imageUploadState = ImageUploadState.tooLarge;
      }
      couponImagesLocal.push(new CouponImage(images[i], imageUploadState));
    }
    couponImages.value = couponImagesLocal;
  }

  function removeCouponImage(image: CouponImage) {
    if (couponImages.value && image) {
      for (let i = 0; i < couponImages.value.length; i++) {
        const couponImageIx = couponImages.value.findIndex(x => x.file?.name === image?.file?.name);
        if (couponImageIx > -1) {
          couponImages.value.splice(couponImageIx, 1);
          break;
        }
      }
    }
  }

  return {
    client,
    couponImages,
    uploadInProgress,
    dwCoupons,
    dwCouponsGetInProgress,
    dwCouponsCount,
    dwCouponsGetInProgressCount,
    dwCouponsOdata,
    init,
    uploadCouponImages,
    getDwCoupons,
    getDwCouponsCount,
    appendCouponImages,
    removeCouponImage,
  };
});
