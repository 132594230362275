import * as pclclient from '@psp/pogona_pcl_client_js';

export default class BatchRequestAllFields {
  constructor(
    public CreatedAt: pclclient.StoreListingAllSearchParam | null = null,
    public Archived: pclclient.StoreListingAllSearchParam | null = null,
    public BatchId: pclclient.StoreListingAllSearchParam | null = null,
  ) {
    this.CreatedAt = new pclclient.StoreListingAllSearchParam(
      'CreatedAt',
      null,
      pclclient.StoreListingAllSearchType.DateRange,
    );
    this.Archived = new pclclient.StoreListingAllSearchParam(
      'Archived',
      [false],
      pclclient.StoreListingAllSearchType.In,
    );
    this.BatchId = new pclclient.StoreListingAllSearchParam(
      'BatchId',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
  }
}
