export default class HandHeldLine {
  constructor(
    public handHeldLineId: number,
    public handHeldMainId: number,
    public itemId: string,
    public upc: string,
    public qtyEntered: number,
  ) {}
}

export class HandHeldLineDataTable {
  constructor(
    public handHeldLines: HandHeldLine[] = [] as HandHeldLine[],
    public selectedHandHeldLines: HandHeldLine[] = [] as HandHeldLine[],
    public handHeldLineCount = 0,
    public search = '',
  ) {}
}
