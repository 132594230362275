<template>
  <core-signs-labels-view-template title="Progress">
    <core-view-section title="Render Progress">
      <render-progress
        :renderLabelsData="renderLabelsData"
        @snackbar-error="error"
      ></render-progress>
    </core-view-section>
  </core-signs-labels-view-template>
</template>

<script>
import RenderLabelData from '@/data/SignsAndLabels/RenderLabelData';
import RenderProgress from './RenderProgressComponent.vue';

export default {
  components: {
    RenderProgress,
  },
  data() {
    const rrenderLabelsData = RenderLabelData.decodeBase64RenderData(this.$route.params.batchInfo);
    return {
      renderLabelsData: rrenderLabelsData,
    };
  },
  methods: {
    error(e) {
      this.$emit('snackbar-error', e);
    },
  },
};
</script>
