<template>
  <core-signs-labels-view-template title="Store Info Bridge" :showStoreNumber="false">
    <core-info-blurb title="What is store info bridge?">
      The store info bridge page allows you to enable pricing for a store, and setup PPC pricing. A
      store's pricing will not work unless it appears here. Pricing for a newly added store will not
      appear until the next morning.
    </core-info-blurb>
    <v-row>
      <v-col cols="12">
        <core-view-section title="Stores">
          <v-row>
            <v-col>
              <v-text-field
                @click:clear="
                  storesSearch = null;
                  getStoresData();
                "
                v-debounce:300="getStoresData"
                prepend-icon="search"
                v-model="storesSearch"
                label="Search (store number)"
                :clearable="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <store-mappings
                v-model="newStoresToMap"
                @saved="addNewStoreMappings"
                :mappedStores="mappedStoresProjected"
                :loading="newStoreMappingLoading"
                :dialog.sync="newStoreMappingDialog"
                :additionalValidation="additionalStoreMappingValidation"
                newText="New Store"
              ></store-mappings>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                v-if="stores"
                v-model="stores.selected"
                :headers="stores.headers"
                :items="stores.items"
                :loading="stores.loading"
                :items-per-page="50"
                :disable-pagination="true"
                :fixed-header="true"
                :options.sync="stores.options"
                @update:sort-by="() => {}"
                @click:row="stores.itemRowMultiClicked($event, stores)"
                @item-selected="stores.itemRowMultiSelected($event, stores)"
                item-key="storeNumber"
                class="elevation-1 scrollable"
              >
                <template v-slot:item.buildOverRideBegin="{ value }">
                  {{ formatOverrideDate(value) }}
                </template>

                <template v-slot:item.buildOverRideEnd="{ value }">
                  {{ formatOverrideDate(value) }}
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn @click="clickEditStore(item)">Edit</v-btn>
                  <v-btn @click="clickResetStore(item)">Reset</v-btn>
                </template>

                <template v-slot:item.transfer="{ item }">
                  <v-btn @click="clickTransferToStore(item)">Transfer</v-btn>
                </template>

                <template v-slot:body.append>
                  <infinite-loader
                    :pogonaTable="stores"
                    :additionalFilter="storesAdditionalFilter"
                    :colspan="stores.headers.length"
                  ></infinite-loader>
                </template>
              </v-data-table>
              <infinite-paganation :pogonaTable="stores"></infinite-paganation>
            </v-col>
          </v-row>
        </core-view-section>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog max-width="600px" v-model="storeToEditDialog">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Store</span>
            </v-card-title>
            <v-card-text>
              <v-col>
                <v-row>
                  <data-date-picker v-model="buildOverRideBeginEdit" label="Override Begin" />
                </v-row>
                <v-row>
                  <data-date-picker v-model="buildOverRideEndEdit" label="Override End" />
                </v-row>
                <v-row>
                  <v-select
                    prepend-icon="local_offer"
                    :items="priceGroups"
                    v-model="overridePriceGroup"
                  ></v-select>
                </v-row>
                <v-row v-if="overridePriceGroup === this.priceGroups[this.priceGroups.length - 1]">
                  <v-text-field
                    prepend-icon="edit"
                    label="Price Group"
                    v-model="overridePriceGroupManual"
                  />
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="saveStoreEdit"
                :disabled="validStoreEdit === false || storeEditLoading"
                :loading="storeEditLoading"
                >Save</v-btn
              >
              <v-btn @click="saveStoreCancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog max-width="600px" v-model="storeResetDialog">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Reset Store {{ storeToReset ? storeToReset.storeNumber : '' }}</span
              >
            </v-card-title>
            <v-card-text
              >Are you sure you want to reset the override values for store
              {{ storeToReset ? storeToReset.storeNumber : '' }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="resetStore" :disabled="storeEditLoading" :loading="storeEditLoading"
                >Yes</v-btn
              >
              <v-btn @click="storeResetDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog max-width="600px" v-model="storeTransferDialog">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Transfer To Store {{ storeToTransfer ? storeToTransfer.storeNumber : '' }}</span
              >
            </v-card-title>
            <v-card-text>
              <v-col>
                <v-row>
                  This will transfer activations, and deactivations from the selected store to store
                  {{ storeToTransfer ? storeToTransfer.storeNumber : '' }}.
                  <strong
                    >The transferred activations, and deactivations will appear tomorrow.</strong
                  >
                  Do you want to continue?
                </v-row>
                <v-row>
                  <v-autocomplete
                    v-model="selectedTransferStore"
                    :items="storesForTransfer"
                    :loading="transferStoresLoading"
                    item-text="storeNumber"
                    item-value="storeNumber"
                  />
                </v-row>
                <v-row>
                  <v-checkbox
                    :label="`Clear activations and deactivations for store
                  ${storeToTransfer ? storeToTransfer.storeNumber : ''} first?`"
                    v-model="clearToStoreNumberFirst"
                  />
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="transferStore"
                :disabled="transferStoresLoading || storeTransferLoading || !selectedTransferStore"
                :loading="storeTransferLoading"
                >Yes</v-btn
              >
              <v-btn @click="storeTransferDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </core-signs-labels-view-template>
</template>

<script>
import PogonaDataTable from '@/utils/PogonaDataTable';
import f from 'odata-filter-builder';
import moment from 'moment';

export default {
  data() {
    return {
      stores: null,
      newStoresToMap: [],
      newStoreMappingLoading: false,
      newStoreMappingDialog: false,
      storesSearch: null,
      storeToEdit: null,
      storeToEditDialog: false,
      storeEditLoading: false,
      storeToReset: null,
      storeResetDialog: false,
      storeToTransfer: null,
      storeTransferDialog: false,
      storeTransferLoading: false,
      clearToStoreNumberFirst: false,
      selectedTransferStore: null,
      storesForTransfer: [],
      transferStoresLoading: false,
      priceGroupEdit: null,
      buildOverRideBeginEdit: this.defaultDate,
      buildOverRideEndEdit: this.defaultDate,
      overridePriceGroup: null,
      overridePriceGroupManual: null,
      priceGroups: ['PPC', 'Other'],
      todaysDate: moment(new Date()).format('YYYY-MM-DD'),
      defaultDate: '1900-01-01',
      defaultDateTime: new Date('1900-01-01T00:00').getTime(),
    };
  },
  async created() {
    await this.initStoreMappings();
  },
  computed: {
    validStoreEdit() {
      // do we have dates?
      // do we have a selected price group from priceGroups, or did they write one in?
      return (
        !!this.buildOverRideBeginEdit &&
        !!this.buildOverRideEndEdit &&
        !!this.overridePriceGroup &&
        (this.otherPriceGroupSelected === false ||
          (!!this.overridePriceGroupManual && this.overridePriceGroupManual.length > 0))
      );
    },
    otherPriceGroupSelected() {
      return this.overridePriceGroup === this.priceGroups[this.priceGroups.length - 1];
    },
    mappedStoresProjected() {
      return this.stores.items.map(x => x.storeNumber);
    },
    storesAdditionalFilter() {
      let filter = null;
      if (this.storesSearch && this.storesSearch.length > 0) {
        filter = f('and').contains("'storeNumber'", this.storesSearch.trim());
      }
      return filter;
    },
  },
  methods: {
    formatOverrideDate(value) {
      if (value) {
        const date = new Date(value);
        if (date.getTime() > this.defaultDateTime) {
          return this.$filters.formatDate(value);
        }
      }

      return null;
    },
    clickEditStore(item) {
      this.storeToEdit = item;

      this.buildOverRideBeginEdit =
        !item.buildOverRideBegin ||
        new Date(item.buildOverRideBegin).getTime() === this.defaultDateTime
          ? this.todaysDate
          : item.buildOverRideBegin;
      this.buildOverRideEndEdit =
        !item.buildOverRideEnd || new Date(item.buildOverRideEnd).getTime() === this.defaultDateTime
          ? this.todaysDate
          : item.buildOverRideEnd;
      this.overridePriceGroup = item.overRidePriceGroup;

      this.storeToEditDialog = true;
    },
    clickResetStore(item) {
      this.storeToReset = item;
      this.storeResetDialog = true;
    },
    async clickTransferToStore(item) {
      try {
        this.storeToTransfer = item;
        this.storeTransferDialog = true;
        this.selectedTransferStore = null;
        this.clearToStoreNumberFirst = false;
        this.storesForTransfer = [];

        this.transferStoresLoading = true;
        const stores = await this.$authApi.http.get(
          `label/storeinfobridge?$orderby=storeNumber&$top=99999&$select=storeNumber&$filter=storeNumber ne '${item.storeNumber}'`,
        );
        this.storesForTransfer = stores.data;
        this.transferStoresLoading = false;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to fetch transfer stores.',
          err,
          id: 'd0959f71-5fb1-43c2-95e7-ee53a7f1da14',
        });
      }
    },
    async transferStore() {
      try {
        this.storeTransferLoading = true;

        await this.$authApi.http.post('label/storeinfobridge/transferactivationsdeactivations', {
          fromStoreNumber: this.selectedTransferStore,
          toStoreNumber: this.storeToTransfer.storeNumber,
          clearToStoreNumberFirst: this.clearToStoreNumberFirst,
        });

        this.storeTransferLoading = false;
        this.storeTransferDialog = false;
        this.clearToStoreNumberFirst = false;
      } catch (err) {
        this.storeTransferLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to transfer store.',
          err,
          id: 'bff559a7-e355-466e-9191-74ae2a1cca15',
        });
      }
    },
    async resetStore() {
      try {
        this.storeEditLoading = true;

        await this.$authApi.http.patch(`label/storeinfobridge/${this.storeToReset.storeNumber}`, {
          overRidePriceGroup: '',
          buildOverRideBegin: `${this.defaultDate}T00:00:00`,
          buildOverRideEnd: `${this.defaultDate}T00:00:00`,
        });

        this.storeToReset = null;
        this.storeResetDialog = false;
        this.storeEditLoading = false;

        this.resetEditData();
        await this.initStoreMappings();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to save store.',
          err,
          id: '5fb91d33-b104-43ae-840d-4960279ab1d6',
        });
      }
    },
    async saveStoreEdit() {
      try {
        this.storeEditLoading = true;

        const priceGroup = this.otherPriceGroupSelected
          ? this.overridePriceGroupManual.trim()
          : this.overridePriceGroup;

        if (!this.buildOverRideBeginEdit) {
          this.buildOverRideBeginEdit = this.defaultDate;
        }
        if (!this.buildOverRideEndEdit) {
          this.buildOverRideEndEdit = this.defaultDate;
        }

        await this.$authApi.http.patch(`label/storeinfobridge/${this.storeToEdit.storeNumber}`, {
          overRidePriceGroup: priceGroup,
          buildOverRideBegin: `${this.buildOverRideBeginEdit}T00:00:00`,
          buildOverRideEnd: `${this.buildOverRideEndEdit}T00:00:00`,
        });

        this.storeToEdit = null;
        this.storeToEditDialog = false;
        this.storeEditLoading = false;

        this.resetEditData();
        await this.initStoreMappings();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to save store.',
          err,
          id: '3b19160d-d67c-4ec6-bec4-893d58d49551',
        });
      }
    },
    saveStoreCancel() {
      this.storeToEditDialog = false;

      this.resetEditData();
    },
    resetEditData() {
      this.buildOverRideBeginEdit = this.defaultDate;
      this.buildOverRideEndEdit = this.defaultDate;
      this.overridePriceGroup = null;
    },
    async getStoresData(force) {
      await this.stores.get(this.storesAdditionalFilter, force);
    },
    async addNewStoreMappings() {
      try {
        this.newStoreMappingLoading = true;

        const posts = [];
        this.newStoresToMap.forEach(store => {
          posts.push(
            this.$authApi.http.post('label/storeinfobridge/new', {
              storeNumber: store.storeNumber,
            }),
          );
        });

        await Promise.all(posts);

        this.newStoreMappingLoading = false;
        this.newStoreMappingDialog = false;

        this.newStoresToMap = [];
        await this.initStoreMappings();
      } catch (err) {
        this.newStoreMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to save store mappings',
          err,
          id: '9d78c753-d8c3-4076-a55b-4f71724f81b3',
        });
      }
    },
    async initStoreMappings() {
      this.stores = new PogonaDataTable({
        headers: [
          {
            text: 'Store Number',
            value: 'storeNumber',
          },
          {
            text: 'Override Price Group',
            value: 'overRidePriceGroup',
          },
          {
            text: 'Override Begin',
            value: 'buildOverRideBegin',
          },
          {
            text: 'Override End',
            value: 'buildOverRideEnd',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
          {
            text: 'Transfer',
            value: 'transfer',
            sortable: false,
          },
        ],
        baseUrl: 'label/storeinfobridge',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['storeNumber'] },
        defaultFilter: f('and').ne('storeNumber', ''),
        onPageFilter: () => this.storesAdditionalFilter,
        isInfinite: true,
        multiselect: false,
        keys: ['storeNumber'],
      });

      this.stores.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting mapped stores',
          err,
          id: 'c3182ff6-415c-4cc6-baea-b47bbfd24e63',
        });
      });

      await this.stores.get(this.storesAdditionalFilter);
    },
    async additionalStoreMappingValidation(storeNumber) {
      const validationResponse = await this.$authApi.http.get(
        `label/storeinfobridge?$filter=${f('and').eq('storeNumber', storeNumber)}`,
      );

      return { exists: validationResponse.data && validationResponse.data.length > 0 };
    },
  },
};
</script>

<style lang="scss" scoped>
strong {
  font-size: 1em;
}
</style>
