import * as Yup from 'yup';

export default class UpcYupValidator {
  public static get skipUpcValidation(): Yup.TestFunction<
    string | undefined | null,
    Record<string, any>
  > {
    return async function () {
      return true;
    };
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static get validUpc(): Yup.TestFunction<string | undefined | null, Record<string, any>> {
    return async function (upc: string) {
      return UpcYupValidator.isUpc(upc);
    } as Yup.TestFunction<string | undefined | null, Record<string, any>>;
  }

  public static isUpc(upc: string): boolean {
    if ((upc?.length ?? 0) === 0) {
      return true;
    }
    if (/[a-zA-Z]/g.exec(upc) !== null) {
      return false;
    }

    const formattedUpc = upc.trim().toUpperCase();
    switch (formattedUpc.length) {
      case 12:
      case 14:
        return this.isUpc12(formattedUpc);
      case 13:
        return this.isUpc13(formattedUpc);
      default:
        return false;
    }
  }

  public static isUpc12(upc: string): boolean {
    return this.upc12CheckDigit(upc) == Number(upc[upc.length - 1].toString());
  }

  public static upc12CheckDigit(upc: string): number {
    // Calculate the check digit for UPCA
    const multpliers = [3, 1];
    let checkDigitSum = 0;
    for (let uix = 0; uix < upc.length - 1; uix++) {
      // Convert to int
      const ui = Number(upc[uix].toString());

      // Get the multplier
      const multplier = multpliers[uix % 2];
      checkDigitSum += multplier * ui;
    }

    // Find the nearest multiple of 10
    const csmDivTen = checkDigitSum / 10.0;
    const nearestMulpOfTen = Math.ceil(csmDivTen) * 10;

    // Subtract nearest multiple from check digit sum
    const checkDigit = nearestMulpOfTen - checkDigitSum;

    return checkDigit;
  }

  public static isUpc13(upc: string): boolean {
    return this.upc13CheckDigit(upc) === Number(upc[upc.length - 1].toString());
  }

  public static upc13CheckDigit(upc: string): number {
    // Calculate the check digit for UPCA
    const multpliers = [1, 3];
    let checkDigitSum = 0;
    for (let uix = 0; uix < upc.length - 1; uix++) {
      // Convert to int
      const ui = Number(upc[uix].toString());

      // Get the multplier
      const multplier = multpliers[uix % 2];
      checkDigitSum += multplier * ui;
    }

    // Find the nearest multiple of 10
    const csmDivTen = checkDigitSum / 10.0;
    const nearestMulpOfTen = Math.ceil(csmDivTen) * 10;

    // Subtract nearest multiple from check digit sum
    const checkDigit = nearestMulpOfTen - checkDigitSum;

    return checkDigit;
  }
}
