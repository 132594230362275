<template>
  <div>
    <data-authed-image
      v-if="extension === 'png'"
      @load="ditem.showPreviewDialogLoaded = true"
      :src="previewUrl"
      :lazy-src="shelfLabelPreview.src"
      :headers="headers"
      :max-width="maxWidth"
      :class="'background-color-white'"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            v-if="ditem.showPreviewDialogLoaded !== true || loading === true"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </template>
    </data-authed-image>
    <data-authed-svg
      v-if="extension === 'svg'"
      @loading="ditem.showPreviewDialogLoaded = false"
      @load="ditem.showPreviewDialogLoaded = true"
      :src="previewUrl"
      :lazy-src="shelfLabelPreview.src"
      :headers="headers"
      :max-width="maxWidth"
      v-model="ditem.showPreviewDialogLoaded"
      class="background-color-white"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            v-if="ditem.showPreviewDialogLoaded !== true || loading === true"
            indeterminate
            color="primary"
            style="margin-top: -7em"
          ></v-progress-circular>
        </v-row>
      </template>
    </data-authed-svg>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import imagePreloadMixins from '@/mixins/image-preload-mixins';

const LabelPreviewImage = Vue.component('LabelPreviewImage', {
  mixins: [imagePreloadMixins],
  props: {
    value: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    url: {
      type: String,
      required: true,
      default: null,
    },
    headers: {
      type: Object,
      required: false,
      default: null,
    },
    maxWidth: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ditem: this.value,
      extension: '',
    };
  },
  computed: {
    ...mapState('app', ['isNewJerseyStore']),
    previewUrl() {
      // fake out the url to reload
      return this.url + (this.ditem.reloadPreview ? ' ' : '');
    },
  },
  created() {
    this.shelfLabelPreview = this.preloadImage(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`../../../assets/signs-labels/shelf_label_preview${
        this.isNewJerseyStore === true ? '_nj' : ''
      }.jpg`),
    );
  },
  mounted() {
    this.setExtension();
  },
  methods: {
    setExtension() {
      this.extension = this.url.split(/#|\?/)[0].split('.').pop().trim();
    },
  },
  watch: {
    previewUrl: {
      handler() {
        this.ditem.showPreviewDialogLoaded = false;
        this.setExtension();
      },
    },
    ditem: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    loading: {
      handler(val) {
        if (val === true) {
          this.ditem.showPreviewDialogLoaded = false;
        }
      },
    },
  },
});

export default LabelPreviewImage;
export { LabelPreviewImage };
</script>

<style lang="scss" scoped>
.background-color-white {
  background-color: white;
}
</style>
