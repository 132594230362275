/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BatchCreatorBase from './BatchCreatorBase';

export default class HandHeldBatchCreator extends BatchCreatorBase {
  constructor(
    public authApi: any,
    public apiBasePath: string,
    public storeNumber: string,
    public retailStartDate: string | Date,
    public useCasePricing: boolean,
    public recId: number,
    public useStoreSetOrder: boolean,
    public fromAxView: boolean,
    public excludedLineIds: number[],
  ) {
    super('hand_held_batch', authApi, apiBasePath, storeNumber, retailStartDate, useCasePricing);

    const onOpen = async () => {
      const handheldBatchData = {
        id: super.id,
        recId: this.recId,
        storeNumber: this.storeNumber,
        retailStartDate: this.retailStartDate,
        useStoreSetOrder: this.useStoreSetOrder,
        useCasePricing: this.useCasePricing,
        fromAxView: this.fromAxView,
        excludedLineIds: this.excludedLineIds,
      };

      await this.authApi.http.post('servicebus/handheldBatch', handheldBatchData);
    };

    super.onOpen = onOpen;
  }
}

export class LabelBatchItem {
  constructor(public itemId: string, public upc: string) {}
}
