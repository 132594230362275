<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        :xl="showNonActivatedToggle ? 7 : 12"
        :lg="showNonActivatedToggle ? 7 : 12"
        :md="showNonActivatedToggle ? 7 : 12"
      >
        <v-text-field
          prepend-icon="search"
          v-model="labelItemsSearch"
          label="Search (UPC, item, desc)"
          :clearable="true"
          @click:clear="
            labelItemsSearch = null;
            getLabelItemsData();
          "
          v-debounce:300="getLabelItemsData"
        ></v-text-field>
      </v-col>
      <v-col v-if="showNonActivatedToggle" cols="12" xl="5" lg="5" md="5">
        <v-checkbox
          prepend-icon="playlist_add_check"
          label="Show Not In Set &amp; Deactivated Items"
          v-model="nonActivatedToggleR"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-combobox
          prepend-icon="playlist_add_check"
          v-model="selectedBarcodeSetupsInner"
          :items="barcodeSetupsDescriptions"
          @blur="getLabelItemsData"
          label="Barcode Setups"
          multiple
          chips
          autocomplete="off"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-bind="attrs" :input-value="selected" label>
              <span class="pr-2">{{ item }}</span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row v-if="showClearButton">
      <v-col>
        <v-btn @click="clearSelected" :disabled="trackedDataSelectedCount === 0"
          >Clear Selected{{
            trackedDataSelectedCount > 0 ? ` (${trackedDataSelectedCount})` : ''
          }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-if="labelItems"
          v-model="labelItems.selected"
          :headers="labelItems.headers"
          :items="labelItems.items"
          :loading="labelItems.loading"
          :disable-pagination="true"
          :options.sync="labelItems.options"
          :fixed-header="true"
          @click:row="labelItemsClicked"
          @item-selected="labelItemsSelected"
          @update:sort-by="labelItems.get(labelItemsAdditionalFilter)"
          item-key="rowId"
          show-select
          class="elevation-1 scrollable"
        >
          <template v-slot:header.data-table-select></template>
          <template v-slot:body.append>
            <infinite-loader
              v-if="labelItems && labelItems.inited === true"
              :pogonaTable="labelItems"
              :additionalFilter="labelItemsAdditionalFilter"
              :colspan="labelItems.headers.length"
            ></infinite-loader>
          </template>
        </v-data-table>
        <infinite-paganation :pogonaTable="labelItems"></infinite-paganation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import PogonaDataTable from '@/utils/PogonaDataTable';

const ActivatedLabels = Vue.component('activated-labels', {
  props: {
    value: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClearButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    scaleProduct: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    includeExceptions: {
      type: Boolean,
      required: false,
      default: true,
    },
    showNonActivatedToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    nonActivatedToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: String,
      required: false,
      default: null,
    },
    queuedItems: {
      type: Object,
      required: false,
      default: null,
    },
    clearSelectedOnSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedBarcodeSetups: {
      type: Array,
      required: false,
      default() {
        return ['PrimaryUPC', 'PLU'];
      },
    },
  },
  data() {
    return {
      labelItems: this.value,
      labelItemsSearch: this.search,
      trackedDataSelectedCount: 0,
      nonActivatedToggleR: this.nonActivatedToggle,
      queuedItemsR: this.queuedItems || { activated: [], nonActivated: [] },
      barcodeSetups: [
        { value: 1, text: 'PrimaryUPC' },
        { value: 2, text: 'PLU' },
        { value: 3, text: 'Case UPC' },
        { value: 4, text: 'Other UPC' },
      ],
      barcodeSetupsDescriptions: ['PrimaryUPC', 'PLU', 'Case UPC', 'Other UPC'],
      selectedBarcodeSetupsInner: this.selectedBarcodeSetups,
    };
  },
  created() {
    if (!this.labelItems || !this.labelItems.inited) {
      this.initLabelItemsTable();
    } else {
      // set the tracked data count
      this.setTrackedDataSelectedCount();
      this.setupEvents();
    }
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    labelItemsAdditionalFilter() {
      let filter = f('and');
      if (this.labelItemsSearch && this.labelItemsSearch.length > 0) {
        const trimmedSearch = this.labelItemsSearch.trim();
        filter = filter.and(
          f('or')
            .contains("'upc'", trimmedSearch)
            .contains("'itemId'", trimmedSearch)
            .contains("'fulldescription1'", trimmedSearch)
            .contains("'fulldescription2'", trimmedSearch),
        );
      }

      if (this.selectedBarcodeSetupsInner && this.selectedBarcodeSetupsInner.length > 0) {
        const mappedBarcodeSetups = [];
        for (const selectedBarcodeSetup of this.selectedBarcodeSetupsInner) {
          const mappedBarcodeSetup = this.barcodeSetups.filter(
            x => x.text === selectedBarcodeSetup,
          )[0];
          if (mappedBarcodeSetup) {
            mappedBarcodeSetups.push(mappedBarcodeSetup.value);
          }
        }

        filter = filter.and(f('and').in('barcodeSetupTypeId', mappedBarcodeSetups));
      } else {
        filter = filter.and(f('and').eq('barcodeSetupTypeId', 0));
      }

      return filter;
    },
  },
  methods: {
    labelItemsClicked(event) {
      this.multiselect
        ? this.labelItems.itemRowMultiClicked(event, this.labelItems)
        : this.labelItems.itemRowSingleClicked(event, this.labelItems);

      const selectedTest = this.labelItems.selected.filter(x => x.upc === event.upc);
      const selected = selectedTest && selectedTest.length > 0;
      this.queuedItemSelected(event, selected);
    },
    labelItemsSelected(event) {
      this.multiselect
        ? this.labelItems.itemRowMultiSelected(event, this.labelItems)
        : this.labelItems.itemRowSingleSelected(event, this.labelItems);

      this.queuedItemSelected(event.item, event.value);
    },
    queuedItemSelected(item, selected) {
      if (!this.queuedItemsR) {
        this.queuedItemsR = { activated: [], nonActivated: [] };
      }

      if (this.multiselect === false) {
        this.queuedItemsR.activated = [];
        this.queuedItemsR.nonActivated = [];
      }

      if (this.nonActivatedToggleR === true) {
        const indexOfItem = this.queuedItemsR.nonActivated.findIndex(x => x.upc === item.upc);
        if (selected === true) {
          // make sure it doesn't already exist
          if (indexOfItem === -1) {
            this.queuedItemsR.nonActivated.push(item);
          }
        } else {
          if (indexOfItem > -1) {
            this.queuedItemsR.nonActivated.splice(indexOfItem, 1);
          }
        }
      } else {
        const indexOfItem = this.queuedItemsR.activated.findIndex(x => x.upc === item.upc);
        if (selected === true) {
          // make sure it doesn't already exist
          if (indexOfItem === -1) {
            this.queuedItemsR.activated.push(item);
          }
        } else {
          if (indexOfItem > -1) {
            this.queuedItemsR.activated.splice(indexOfItem, 1);
          }
        }
      }
    },
    setTrackedDataSelectedCount() {
      if (this.queuedItems && this.queuedItems.activated && this.queuedItems.nonActivated) {
        this.trackedDataSelectedCount =
          this.queuedItems.activated.length + this.queuedItems.nonActivated.length;
      } else {
        this.trackedDataSelectedCount = 0;
      }
    },
    setupEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting labels',
          err,
          id: '537d2a39-1305-4324-a032-425db936f3a4',
        });
      };

      const updateTrackedDataSelectedCount = () => {
        this.setTrackedDataSelectedCount();
      };

      const dataBound = () => {
        // check to see if we have any previously selected items in queuedItemsR
        let selected = [];
        if (this.queuedItemsR) {
          if (this.nonActivatedToggleR === true) {
            if (this.queuedItemsR.nonActivated && this.queuedItemsR.nonActivated.length > 0) {
              selected = this.queuedItemsR.nonActivated;
            }
          } else {
            if (this.queuedItemsR.activated && this.queuedItemsR.activated.length > 0) {
              selected = this.queuedItemsR.activated;
            }
          }
        }

        if (selected.length > 0) {
          this.labelItems.selected = selected;
        }
      };

      this.labelItems.removeListener('error', error);
      this.labelItems.removeListener('trackedDataSelectedCount', updateTrackedDataSelectedCount);
      this.labelItems.removeListener('dataBound', dataBound);

      this.labelItems.on('error', error);
      this.labelItems.on('trackedDataSelectedCount', updateTrackedDataSelectedCount);
      this.labelItems.on('dataBound', dataBound);
    },
    clearSelected() {
      this.labelItemsSearch = null;
      this.labelItems.clearSelectedItems(this.labelItems);
      this.trackedDataSelectedCount = 0;

      this.queuedItemsR = {
        activated: [],
        nonActivated: [],
      };
      this.$emit('update:queuedItems', this.queuedItemsR);
    },
    getLabelItemsData(query, force) {
      if (
        this.clearSelectedOnSearch === true &&
        this.labelItems &&
        this.labelItems.selected &&
        this.labelItems.selected.length > 0
      ) {
        this.clearSelected();
      }

      this.labelItems.get(this.labelItemsAdditionalFilter, force);
    },
    initLabelItemsTable() {
      this.labelItems = new PogonaDataTable({
        headers: [
          {
            text: 'UPC #',
            value: 'upc',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
          {
            text: 'Description 1',
            value: 'fulldescription1',
          },
          {
            text: 'Description 2',
            value: 'fulldescription2',
          },
          {
            text: 'Size',
            value: 'unitSize',
          },
          {
            text: 'UOM',
            value: 'unit',
          },
          {
            text: 'R/Type',
            value: 'salesUom',
          },
          {
            text: 'Barcode Setup',
            value: 'barcodeSetupId',
          },
          {
            text: 'Vendor ID',
            value: 'vendorId',
          },
        ],
        keys: ['rowId'],
        defaultAllSelected: false,
        multiselect: this.multiselect,
        baseUrl: this.nonActivatedToggleR
          ? `label/labelitem/${this.storeNumber}/5`
          : `label/labelitem/activated/${this.storeNumber}`,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['itemId'] },
        isInfinite: true,
        onPageFilter: () => this.labelItemsAdditionalFilter,
        defaultFilter: () => {
          if (
            this.scaleProduct !== undefined &&
            this.scaleProduct !== null &&
            this.nonActivatedToggleR === false
          ) {
            return f('and').eq('isIngredientItem', this.scaleProduct);
          }
          return null;
        },
        queryStringParams: {
          includeExceptions: this.includeExceptions,
        },
      });

      this.setupEvents();
      this.getLabelItemsData();
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
    labelItems: {
      handler: function (val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    storeNumber: {
      handler: function () {
        this.clearSelected();
        this.initLabelItemsTable();
      },
    },
    nonActivatedToggle: {
      handler: function (val) {
        this.nonActivatedToggleR = val;
      },
    },
    nonActivatedToggleR: {
      handler: function (val) {
        this.initLabelItemsTable();
        this.$emit('update:nonActivatedToggle', val);
      },
    },
    labelItemsSearch: {
      handler: function (val) {
        if (this.labelItems) {
          this.labelItems.onPageFilter = () => this.labelItemsAdditionalFilter;
        }
        this.$emit('update:search', val);
      },
    },
    search: {
      handler: function (val) {
        this.labelItemsSearch = val;
      },
    },
    selectedBarcodeSetupsInner: {
      handler: function (val) {
        this.$emit('update:selectedBarcodeSetups', val);
      },
    },
    selectedBarcodeSetups: {
      handler: function (val) {
        this.selectedBarcodeSetupsInner = val;
      },
    },
  },
});
export default ActivatedLabels;
export { ActivatedLabels };
</script>
