<template>
  <core-view-template title="Invoice Home">
    <div>
      <show-impersonation />
      <invoice-search class="mb-4"></invoice-search>
      <v-data-table
        :headers="headers"
        :items="vpFinanceStore.salesInvoiceHeaders"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [] }"
        :server-items-length="serverItemsLength"
      >
        <template v-slot:item.InvoiceDate="{ item }"> {{ formatDate(item.InvoiceDate) }}</template>
        <template v-slot:item.InvoiceNumber="{ item }">
          <router-link
            :to="{
              name: 'vendorportal_invoicedetail',
              params: { invoiceNumber: item.InvoiceNumber },
            }"
            class="no-wrap"
          >
            {{ item.InvoiceNumber }}
          </router-link>
        </template>
        <template v-slot:item.TotalInvoiceAmount="{ item }">
          {{ formatCurrency(item.TotalInvoiceAmount) }}</template
        >
        <template v-slot:item.TotalTaxAmount="{ item }">
          {{ formatCurrency(item.TotalTaxAmount) }}</template
        >
        <template v-slot:item.DataAreaId="{ item }">
          {{ getLegalEntityLookup(item.DataAreaId) }}
        </template>
        <template v-slot:footer.page-text="props">
          <span>{{ props.pageStart }} - {{ props.pageStop }}</span>
        </template>
      </v-data-table>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import InvoiceSearch from '@/components/vendorportal/InvoiceSearch.vue';
import { useLegalEntityLookup } from '@/composables/financeComposables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';

defineComponent({
  name: 'InvoiceHome',
});

const vpFinanceStore = useVpFinanceStore();
const { getLegalEntityLookup } = useLegalEntityLookup();
const loading = ref(false);
const options = ref({});
const headers = [
  { text: 'Company', value: 'DataAreaId', sortable: false },
  { text: 'Customer Account Number', value: 'InvoiceCustomerAccountNumber', sortable: false },
  { text: 'Invoice Number', value: 'InvoiceNumber', sortable: false },
  { text: 'Invoice Date', value: 'InvoiceDate', sortable: false },
  { text: 'Total Invoice Amount', value: 'TotalInvoiceAmount', sortable: false, align: 'end' },
  { text: 'Total Tax Amount', value: 'TotalTaxAmount', sortable: false, align: 'end' },
];
const serverItemsLength = 9999;

// Create options watch
watch(
  options,
  async (curr: any) => {
    if ('page' in curr) {
      loading.value = true;
      vpFinanceStore
        .getSalesInvoiceHeaders((curr.page - 1) * curr.itemsPerPage, curr.itemsPerPage)
        .finally(() => {
          loading.value = false;
        });
    }
  },
  { immediate: true },
);

// Format a number as currency
const formatCurrency = (value: any) => {
  return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

// Format a date string as mm/dd/yyyy
const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString();
};
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
