<template>
  <core-view-template :title="title">
    <ShowImpersonation />
    <VendorSelection :vendorAcct="selectedVendor" @vendorSelected="vendorSelected" />
    <v-card outlined>
      <v-card-text
        >Vendor Balance:
        {{ formatters.formatCurrency(balance.totalAmount - balance.totalSettlement) }}</v-card-text
      >
    </v-card>
    <VendTransGrid
      :headers="headers"
      :vendTrans="vpFinanceStore.vendTransResult"
      :loading="loading"
      :hideDefaultFooter="true"
      :page="1"
      class="mt-3"
    />
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import VendTransGrid from '@/components/vendorportal/VendTransGrid.vue';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import VendorSelection from '@/components/vendorportal/VendorSelection.vue';
import { useFormatters } from '@/composables/formatters';
import { useTransGridShared } from '@/composables/TransGridShared';
defineComponent({
  name: 'vendBalance',
});
const gridShared = useTransGridShared();
const headers = [
  gridShared.dataAreaIdHeader,
  gridShared.custAcctHeader,
  gridShared.invoiceHeader,
  gridShared.txtHeader,
  gridShared.documentDateHeader,
  gridShared.amountCurHeader,
  gridShared.balanceHeader,
  gridShared.dueDateHeader,
  gridShared.paymTermIdHeader,
  gridShared.paymModeHeader,
];
const formatters = useFormatters();
const title = ref('Vendor Balance');
const vpFinanceStore = useVpFinanceStore();
const loading = ref(false);
const selectedVendor = ref('');
function loadVendBalance(account: string) {
  if (account) {
    loading.value = true;
    vpFinanceStore.getVendBalance(account).finally(() => {
      loading.value = false;
    });
  }
}
function vendorSelected(vendor: string) {
  selectedVendor.value = vendor;
}
watch(selectedVendor, newVal => {
  if (newVal) {
    loadVendBalance(newVal);
  }
});
const balance = computed(() => {
  return vpFinanceStore.vendTransResult.reduce(
    (acc: { totalAmount: number; totalSettlement: number }, item) => ({
      totalAmount: acc.totalAmount + Number(item.AmountCur),
      totalSettlement: acc.totalSettlement + Number(item.SettleAmountCur),
    }),
    { totalAmount: 0, totalSettlement: 0 },
  );
});
watch(
  () => vpFinanceStore.vendorSearchResultWithRestriction,
  newVal => {
    if (newVal?.length === 1) {
      selectedVendor.value = newVal[0].VendorAccountNumber;
    }
  },
);
</script>
