<template>
  <v-row v-if="ignoreItemResultsCount > 0"
    ><v-col>
      <v-row class="mt-2"
        ><v-col>
          <span style="color: red"
            >The following item{{ ignoreItemResultsCount > 1 ? 's' : '' }} failed to update:</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="resultHeaders"
            :items="ignoreItemResultErrors"
            :items-per-page="-1"
            :fixed-header="true"
            class="elevation-1 scrollable"
            hide-default-footer
          ></v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { ContentHubIgnoreItemResult } from '@/stores/ecomstore';

const props = defineProps({
  value: {
    type: Array<ContentHubIgnoreItemResult>,
    required: true,
  },
});

const resultHeaders = ref<any>([
  { value: 'ItemId', text: 'Item #' },
  { value: 'Upc', text: 'UPC' },
  { value: 'ErrorMessage', text: 'Reason' },
]);

const ignoreItemResultsCount = computed(() => {
  return ignoreItemResultErrors.value?.length ?? 0;
});

const ignoreItemResultErrors = computed(() => {
  return props.value?.filter(x => x.Success === false);
});
</script>
