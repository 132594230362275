<template>
  <core-view-template title="Confirm Log Out">
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          <span class="headline">Are you sure you want to logout?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmLogout(true)">Yes</v-btn>
          <v-btn @click="confirmLogout(false)">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p v-if="logout">Logging out...</p>
  </core-view-template>
</template>

<script>
export default {
  data() {
    return {
      logout: false,
      showDialog: true,
    };
  },
  methods: {
    confirmLogout(confirmed) {
      this.showDialog = false;
      if (confirmed === true) {
        this.$store.commit('clearStore');
        this.logout = true;
        this.$authApi.logout();
      } else {
        this.logout = false;
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
strong {
  font-size: 1em;
  font-weight: bold;
}
</style>
