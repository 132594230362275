<template>
  <div>
    <v-row :class="cssClasses">
      <v-col :cols="textCol">
        <v-icon class="mr-2" v-if="!header.sortable || header.sortable === true" small>{{
          options.sortBy && options.sortBy.length === 1 && options.sortBy[0] === header.value
            ? options.sortDesc && options.sortDesc.length === 1 && options.sortDesc[0] === true
              ? 'mdi-arrow-down'
              : 'mdi-arrow-up'
            : ''
        }}</v-icon>
        <span>{{ header.text }}</span>
      </v-col>
      <v-col cols="3" class="float-right" v-if="hideable">
        <v-icon text @click.stop.prevent="hideHeader(header.value)">visibility_off</v-icon>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pm from '@/store/modules/pcl/mutations';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({})
export default class PclStoreListingAllHeaderText extends Vue {
  @Prop({ required: true })
  readonly header!: any;

  @Prop({ required: true })
  readonly options!: any;

  @Prop({ required: false })
  readonly cssClass!: Array<string> | string;

  @Prop({ required: false, default: true })
  readonly hideable!: boolean;

  @Prop({ required: false, default: null })
  readonly col!: string | null;

  @pclModule.Mutation(pm.removePclListingAllHeader) removePclListingAllHeader!: (
    payload: string,
  ) => void;

  public hideHeader(col: string) {
    this.removePclListingAllHeader(col);
  }

  public get cssClasses() {
    const classes = ['header-text-hide'];
    if (this.cssClass) {
      if (typeof this.cssClass === 'object' && this.cssClass.length > 0) {
        classes.push(...this.cssClass);
      } else if (typeof this.cssClass === 'string' && this.cssClass.length > 0) {
        classes.push(this.cssClass);
      }
    }
    return classes;
  }

  private get textCol() {
    if (this.col) {
      return this.col;
    }
    return this.hideable ? '9' : '12';
  }
}
</script>
