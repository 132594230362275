/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface TransGridHeader {
  text: string;
  value: string;
  sortable: boolean;
  align?: string;
}

export function useTransGridShared() {
  const detailsHeader = { text: 'Details', value: 'Details', sortable: false };
  const dataAreaIdHeader = { text: 'Company Name', value: 'DataAreaId', sortable: false };
  const vendAcctHeader = { text: 'Vendor Acct#', value: 'AccountNum', sortable: false };
  const custAcctHeader = { text: 'Cust Acct#', value: 'AccountNum', sortable: false };
  const paymReferenceHeader = {
    text: 'Check# / Payment Ref',
    value: 'PaymReference',
    sortable: false,
  };
  const paymModeHeader = { text: 'Method of Payment', value: 'PaymMode', sortable: false };
  const invoiceHeader = { text: 'Invoice #', value: 'Invoice', sortable: false };
  const documentDateHeader = { text: 'Invoice Date', value: 'DocumentDate', sortable: false };
  const closedHeader = { text: 'Payment Date', value: 'Closed', sortable: false };
  const amountCurHeader = {
    text: 'Transaction Amt',
    value: 'AmountCur',
    sortable: false,
    align: 'end',
  };
  const balanceHeader = { text: 'Balance', value: 'Balance', sortable: false, align: 'end' };
  const dueDateHeader = {
    text: 'Invoice Due Date',
    value: 'DueDate',
    sortable: false,
    align: 'end',
  };
  const paymTermIdHeader = { text: 'Payment Terms', value: 'PaymTermId', sortable: false };
  const txtHeader = { text: 'Description', value: 'Txt', sortable: false };
  const createdOnHeader = { text: 'Created On', value: 'CreatedOn', sortable: false };
  const settleAmountCurHeader = {
    text: 'Settle Amount',
    value: 'SettleAmountCur',
    sortable: false,
    align: 'end',
  };
  const transTypeHeader = { text: 'Transaction Type', value: 'TransType', sortable: false };
  const transDateHeader = { text: 'Transaction Date', value: 'TransDate', sortable: false };
  const closedDateHeader = { text: 'Closed Date', value: 'ClosedDate', sortable: false };

  function sortable(header: any) {
    return {
      ...header,
      sortable: true,
    };
  }

  return {
    detailsHeader,
    dataAreaIdHeader,
    vendAcctHeader,
    custAcctHeader,
    paymReferenceHeader,
    paymModeHeader,
    invoiceHeader,
    documentDateHeader,
    closedHeader,
    amountCurHeader,
    balanceHeader,
    dueDateHeader,
    paymTermIdHeader,
    txtHeader,
    createdOnHeader,
    settleAmountCurHeader,
    transTypeHeader,
    transDateHeader,
    closedDateHeader,
    sortable,
  };
}
