<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col>
          <v-text-field
            label="Search by item ID, or UPC."
            v-model="dataTable.search"
            clearable
            @click:clear="
              skip = 0;
              dataTable.search = null;
              getLineData(true);
            "
            v-debounce:300="reset"
            prepend-icon="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-model="dataTable.selectedHandHeldLines"
            :headers="headers"
            :items="dataTable.handHeldLines"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="loading"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="dataTable.handHeldLineCount"
            @click:row="handhedLineClicked"
            @update:sort-by="setOrderBy"
            @update:sort-desc="setOrderBy"
            class="elevation-1 scrollable"
            hide-default-footer
            item-key="handHeldLineId"
            height="100%"
            show-select
          >
            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="dataTable.handHeldLines && dataTable.handHeldLines.length > 0"
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>

            <template v-slot:footer="{}">
              <div class="my-3 ml-2">
                {{ dataTable.handHeldLines.length }} / {{ dataTable.handHeldLineCount }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import Vue, { ref, onMounted, defineComponent, defineProps, watch } from 'vue';
import HandHeldLine, { HandHeldLineDataTable } from '@/data/SignsAndLabels/HandHeldLine';
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';
import ODataProps from '@/utils/ODataProps';

defineComponent({
  name: 'HandHeldBatchLines',
});

const infiniteId = ref(-1);
const skip = ref(0);
const loading = ref(false);
let firstGet = true;

const props = defineProps({
  modelValue: null,
  handHeldMainId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'snackbar-error']);
const dataTable = ref<HandHeldLineDataTable>(
  new HandHeldLineDataTable([] as HandHeldLine[], [] as HandHeldLine[], 0, ''),
);

const headers = [
  {
    text: 'ID',
    value: 'handHeldLineId',
  },
  {
    text: 'Item #',
    value: 'itemId',
  },
  {
    text: 'UPC',
    value: 'upc',
  },
  {
    text: 'Qty',
    value: 'qtyEntered',
  },
];

const options = ref({ itemsPerPage: 50, sortBy: ['handHeldLineId'], sortDesc: [false] });

onMounted(async () => {
  await getLineData(true);
});

async function reset() {
  skip.value = 0;
  firstGet = true;
  await getLineData(true);
}

async function getLineData(includeCount = false) {
  try {
    if (props.handHeldMainId) {
      loading.value = true;
      let filter = f('and').eq('handHeldMainId', props.handHeldMainId);

      if ((dataTable.value?.search?.length ?? 0) > 0) {
        const trimmedSearch = dataTable.value.search;
        filter = filter.and(
          f('or').contains("'itemId'", trimmedSearch).contains("'upc'", trimmedSearch),
        );
      }

      const orderBy = new Array<string>();
      if (options.value.sortBy.length > 0) {
        orderBy.push(`${options.value.sortBy[0]} ${options.value.sortDesc[0] ? 'desc' : 'asc'}`);
      }

      const odata = new ODataProps(
        options.value.itemsPerPage,
        skip.value,
        filter.toString(),
        orderBy,
      );
      const odataQuery = buildQuery(odata);

      const getLines = Vue.prototype.$authApi.http.get(`label/handheldline${odataQuery}`);

      let results: any | null = null;
      if (includeCount === true) {
        const getLinesCount = Vue.prototype.$authApi.http.get(
          `label/handheldline/$count${odataQuery}`,
        );
        results = await Promise.all([getLines, getLinesCount]);
      } else {
        results = await Promise.all([getLines]);
      }

      if (skip.value === 0) {
        dataTable.value.handHeldLines = Object.assign([], results[0].data as HandHeldLine[]);
      } else {
        skip.value += options.value.itemsPerPage;
        dataTable.value.handHeldLines.push(...results[0].data);
      }

      if (results.length === 2) {
        dataTable.value.handHeldLineCount = results[1].data;
      }

      // if this is the first get, select all by default
      if (firstGet === true) {
        dataTable.value.selectedHandHeldLines = Object.assign(
          [],
          dataTable.value.handHeldLines as HandHeldLine[],
        );
      } else {
        // add the newly added items by default
        dataTable.value.selectedHandHeldLines.push(...dataTable.value.handHeldLines);
      }
    }
  } catch (err) {
    emit('snackbar-error', {
      err,
      text: 'Error getting handheld lines.',
      id: 'b40ba332-3e97-4251-a462-855bb08325a2',
    });
  } finally {
    loading.value = false;
  }
}

function handhedLineClicked(e: HandHeldLine) {
  // not in the selected array, need to add it
  const selectedIx = dataTable.value.selectedHandHeldLines.findIndex(
    x => x.handHeldLineId === e.handHeldLineId,
  );
  if (selectedIx === -1) {
    dataTable.value.selectedHandHeldLines.push(e);
  } else {
    dataTable.value.selectedHandHeldLines.splice(selectedIx, 1);
  }
}

async function setOrderBy() {
  await getLineData();
}

function infiniteHandler($state: any) {
  getLineData()
    .then(() => {
      if (dataTable.value.handHeldLines.length % options.value.itemsPerPage === 0) {
        $state.loaded();
      } else {
        $state.complete();
      }
    })
    .catch(() => {
      $state.complete();
    });
}

watch(
  () => props.handHeldMainId,
  async () => {
    firstGet = true;
    skip.value = 0;
    await getLineData(true);
  },
);

watch(
  () => dataTable.value,
  async () => {
    emit('update:modelValue', dataTable.value);
  },
  { deep: true },
);
</script>
