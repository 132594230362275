/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import buildQuery from 'odata-query';
import axios from 'axios';

export default class PogonaDataHelper {
  constructor() {
    this.cancelToken = axios.CancelToken;
    this.cancelTokenSources = [];
  }

  cancelInFlightRequests() {
    if (this.cancelTokenSources) {
      this.cancelTokenSources.forEach(token => {
        token.cancel(this.cancelMessage);
      });
      this.cancelTokenSources = [];
    }
  }

  async getAllData(options) {
    let finalData = [];
    for await (const data of this.yieldAllData(options, false)) {
      finalData = finalData.concat(data);
    }
    return finalData;
  }

  async getAllReportData(options) {
    let finalData = null;
    for await (const data of this.yieldAllData(options, true)) {
      if (finalData === null) {
        finalData = data;
      } else {
        finalData.items = finalData.items.concat(data.items);
      }
    }
    return finalData;
  }

  // Generator function
  // eslint-disable-next-line class-methods-use-this
  async *yieldAllData(options, isReport) {
    const { $authApi, url, orderBy, pageSize, hasCount, filter } = options;
    const takeSize = pageSize || 100;
    let skip = 0;
    let pagedData = null;
    const canCount = hasCount || false;
    const filterStr = filter ? filter.toString() : null;

    const source = this.cancelToken.source();
    this.cancelTokenSources.push(source);

    let count = Number.MAX_SAFE_INTEGER;
    // If it's possible, get the total count to prevent extra gets
    if (canCount) {
      const odataCountParams = {
        top: takeSize,
        skip,
        orderBy,
        filter: filterStr,
      };
      count = (
        await $authApi.http.get(`${url}/$count${this.buildQuery(url, odataCountParams)}`, {
          cancelToken: source.token,
        })
      ).data;
    }

    let retrievedCount = 0;
    do {
      const odataParams = {
        top: takeSize,
        skip,
        orderBy,
        filter: filterStr,
      };
      // eslint-disable-next-line no-await-in-loop
      pagedData = (
        await $authApi.http.get(`${url}${this.buildQuery(url, odataParams)}`, {
          cancelToken: source.token,
        })
      ).data;
      yield pagedData;

      skip += takeSize;
      retrievedCount += isReport ? pagedData.items.length : pagedData.length;
    } while (
      (canCount && retrievedCount < count) ||
      (!canCount &&
        ((isReport && (pagedData === null || pagedData.items.length > 0)) ||
          (!isReport && (pagedData === null || pagedData.length > 0))))
    );

    // Remove token
    this.cancelTokenSources.splice(this.cancelTokenSources.indexOf(source), 1);
  }

  // eslint-disable-next-line class-methods-use-this
  buildQuery(url, odataParams) {
    let query = buildQuery(odataParams);
    if (url.indexOf('?') > -1) {
      query = `&${query.substring(1, query.length)}`;
    }
    return query;
  }
}
