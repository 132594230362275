/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import uuid from 'uuid';

export default class BatchCreatorBase {
  public idR: string | null = null;
  public adalHelper: AdalHelpers;
  public onOpen!: () => Promise<void>;

  constructor(
    public declaredMessageType: string,
    public authApi: any,
    public apiBasePath: string,
    public storeNumber: string,
    public retailStartDate: string | Date,
    public useCasePricing: boolean,
  ) {
    this.adalHelper = new AdalHelpers();
  }

  get id() {
    if (this.idR === null) {
      this.idR = uuid();
    }
    return this.idR;
  }

  public async open(onClose: () => any, onmessage: (event: any) => void): Promise<PogonaWebSocket> {
    const adalToken = await this.adalHelper.getJwtToken(this.authApi);

    const batchWs = new PogonaWebSocket(
      this.apiBasePath,
      this.declaredMessageType,
      this.id,
      adalToken,
      onClose,
      onmessage,
      null,
      this.onOpen,
    );
    return batchWs;
  }
}
