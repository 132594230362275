<template>
  <core-view-template :title="title" :disablePromise="disablePromise">
    <slot></slot>
    <template v-slot:diabled-dialog>
      <v-card>
        <v-card-title>
          <span class="headline">Not Available</span>
        </v-card-title>
        <v-card-text>
          <p class="mb-5">
            The signs and label data is being processed right now. The application will not be
            available during this time. Please check back later.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link :to="{ name: 'home' }">
            <a>Go Home</a>
          </router-link>
        </v-card-actions>
      </v-card>
    </template>
  </core-view-template>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    showStoreNumber: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    // by default we want to show the store number
    this.$store.commit('app/showStoreNumber', true && this.showStoreNumber);
  },
  data() {
    return {
      disablePromise: new Promise(resolve => {
        if (process.env.VUE_APP_DISABLE_LABEL_GO_NO_GO !== '1') {
          this.$authApi.http
            .get('/label/gonogo?$top=1&$orderby=id desc&$select=readytoRun')
            .then(res => {
              let disable = false;
              if (res && res.data && res.data.length === 1) {
                disable = res.data[0].readytoRun === false;
              }

              resolve(disable);
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          resolve(false);
        }
      }),
    };
  },
};
</script>
