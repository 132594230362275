<template>
  <core-view-template title="Owner Listing">
    <pcl-owner-groups ref="owners"> </pcl-owner-groups>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PclOwnerGroups from '@/components/pcl/PclOwnerGroups.vue';
import { namespace } from 'vuex-class';
import * as ps from '@/store/modules/pcl/state';
import * as pcldto from '@psp/pcldto';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {
    PclOwnerGroups,
  },
})
export default class PclLanding extends Vue {
  @pclModule.State(ps.ownersData) ownersData!: pcldto.pclOwner[];

  private mounted() {
    if (this.$refs.owners && (this.ownersData === null || this.ownersData.length === 0)) {
      (this.$refs.owners as PclOwnerGroups).reset();
    }
  }
}
</script>
