import * as pclclient from '@psp/pogona_pcl_client_js';

export default class ItemUpdateFileAllFields {
  constructor(
    public ProcessingProfileId: pclclient.StoreListingAllSearchParam | null = null,
    public ProcessFileStatusId: pclclient.StoreListingAllSearchParam | null = null,
    public TimeInserted: pclclient.StoreListingAllSearchParam | null = null,
    public TimeProcessed: pclclient.StoreListingAllSearchParam | null = null,
    public UploadedBy: pclclient.StoreListingAllSearchParam | null = null,
    public Archived: pclclient.StoreListingAllSearchParam | null = null,
  ) {
    this.ProcessingProfileId = new pclclient.StoreListingAllSearchParam(
      'ProcessingProfileId',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
    this.ProcessFileStatusId = new pclclient.StoreListingAllSearchParam(
      'ProcessFileStatusId',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
    this.TimeInserted = new pclclient.StoreListingAllSearchParam(
      'TimeInserted',
      null,
      pclclient.StoreListingAllSearchType.DateRange,
    );
    this.TimeProcessed = new pclclient.StoreListingAllSearchParam(
      'TimeProcessed',
      null,
      pclclient.StoreListingAllSearchType.DateRange,
    );
    this.UploadedBy = new pclclient.StoreListingAllSearchParam(
      'UploadedBy',
      null,
      pclclient.StoreListingAllSearchType.Like,
    );
    this.Archived = new pclclient.StoreListingAllSearchParam(
      'Archived',
      [false],
      pclclient.StoreListingAllSearchType.In,
    );
  }
}
