export default class BatchCheckItems {
  constructor() {
    this.batchInfoItems = {};
    this.approvedTypes = [];
    this.svgTemplates = [];
    this.availableSvgTemplates = [];
    this.search = null;
  }

  get inited() {
    return (
      !this.batchInfoItems ||
      (this.batchInfoItems.inited !== undefined &&
        this.batchInfoItems.inited !== null &&
        this.batchInfoItems.inited !== false)
    );
  }
}
