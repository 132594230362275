// https://vuex.vuejs.org/en/mutations.html
import { version } from '../../package.json';

export const setError = 'setError';
export const setSuccess = 'setSuccess';
export const clearError = 'clearError';
export const clearSuccess = 'clearSuccess';
export const setStoreInitialized = 'setStoreInitialized';
export const setVisibilitiesInitialized = 'setVisibilitiesInitialized';

export default {
  initializeStore(state) {
    // Check if the store exists
    const lsStore = localStorage.getItem('store');
    if (lsStore) {
      const store = JSON.parse(lsStore);

      // Check the version stored against current. If different, don't
      // load the cached version, just set the new version number.
      if (store.version === version) {
        this.replaceState(Object.assign(state, store));
      } else {
        state.version = version;

        // transfer their tour settings
        if (store.tours) {
          state.tours = Object.assign({}, store.tours);
        }
        // transfer their printer settings
        if (store.app) {
          if (store.app.selectedPrinter) {
            state.app.selectedPrinter = store.app.selectedPrinter;
          }
          if (store.app.printerTray) {
            state.app.printerTray = store.app.printerTray;
          }
          if (store.app.availablePrinters) {
            state.app.availablePrinters = store.app.availablePrinters;
          }
          state.app.showPreviewMode = store.app.showPreviewMode;
          state.app.useJsPrintVersion = store.app.useJsPrintVersion;
        }

        // transfer their pcl pclListingAllHeaders settings
        if (store.pcl && store.pcl.pclListingAllHeaders) {
          state.pcl.pclListingAllHeaders = store.pcl.pclListingAllHeaders;
        }

        // transfer their pcl pclRemovedHeaders settings
        if (store.pcl && store.pcl.pclRemovedHeaders) {
          state.pcl.pclRemovedHeaders = store.pcl.pclRemovedHeaders;
        }
      }
    }
  },
  clearStore() {
    // Check if the store exists
    if (localStorage.getItem('store')) {
      // Clear the store
      this.replaceState({});
    }
  },
  [setError](state, err) {
    // do not set error when request was canceled
    if (err && err.err && err.err.canceled && err.err.canceled === true) {
      return;
    }
    state.err = err;
  },
  [setSuccess](state, sccss) {
    state.success = sccss;
  },
  [clearError](state) {
    state.err = null;
  },
  [clearSuccess](state) {
    state.success = null;
  },
  [setStoreInitialized](state, initialized) {
    state.storeInitialized = initialized;
  },
  [setVisibilitiesInitialized](state, initialized) {
    state.visibilitiesInitialized = initialized;
  },
};
