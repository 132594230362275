<template>
  <v-row class="ma-1">
    <v-row justify="center" align="center" class="ma-2" @click.stop="showPreviewDialog = true">
      <label-preview-image
        v-model="ditem"
        :url="url"
        :headers="headers"
        :loading="loading"
        class="pointer"
        max-width="240px"
      ></label-preview-image>
    </v-row>
    <v-dialog v-model="showPreviewDialog" width="unset" :scrollable="false">
      <v-card class="justify-center d-inline-block">
        <v-card-actions class="mx-1">
          <v-row class="my-1">
            <label-preview-image
              v-model="ditemClone"
              :url="url"
              :headers="headers"
            ></label-preview-image>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue';
import LabelPreviewImage from './LabelPreviewImage.vue';

const LabelPreview = Vue.component('LabelPreview', {
  components: {
    LabelPreviewImage,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
    url: {
      type: String,
      required: true,
      default: null,
    },
    headers: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ditem: this.item,
      extension: '',
      showPreviewDialog: false,
      ditemClone: Object.assign({}, this.item), // required so that the dialog doesn't affect ditem
    };
  },
  watch: {
    item: {
      handler(val) {
        this.ditem = val;
      },
    },
    ditem: {
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
});

export default LabelPreview;
export { LabelPreview };
</script>

<style lang="scss" scoped>
.background-color-white {
  background-color: white;
}
.pointer {
  cursor: pointer;
}
</style>
