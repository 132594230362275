<template>
  <v-col>
    <v-dialog max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }">
          <div>
            <v-img
              :class="['ml-1', 'mb-1', hover ? 'elevation-5' : '']"
              v-if="value"
              :max-width="maxWidth"
              :src="value"
              v-on="on"
            >
              <v-fade-transition>
                <v-icon class="zoom-in" v-if="hover">zoom_in</v-icon>
              </v-fade-transition>
            </v-img>
          </div>
        </v-hover>
      </template>
      <v-img :src="value"></v-img>
    </v-dialog>
    <v-btn
      :loading="screenshotLoading"
      :title="(value ? 'Re-t' : 'T') + 'ake screenshot'"
      @click="takeScreenshot"
    >
      <v-icon>photo_camera</v-icon>
    </v-btn>
    <v-dialog v-model="preparing" hide-overlay persistent width="300">
      <v-card color="primary">
        <v-card-text>
          <p class="my-2 white--text">Preparing screenshot</p>
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import * as html2canvas from 'html2canvas';

export default {
  props: {
    value: null,
    timeout: {
      type: Number,
      required: false,
      default: 1000,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100px',
    },
  },
  data() {
    return {
      screenshotLoading: false,
      preparing: false,
    };
  },
  methods: {
    takeScreenshot: function () {
      this.screenshotLoading = true;
      this.preparing = true;

      this.$emit('start');
      setTimeout(() => {
        try {
          this.preparing = false;

          setTimeout(() => {
            html2canvas(document.querySelector('body')).then(canvas => {
              // now capture the image data on a new thread
              document.createElement('canvas');

              const imageUrl = canvas.toDataURL();

              this.screenshotLoading = false;
              this.$emit('input', imageUrl);
              this.$emit('done');
            });
          }, 100);
        } catch (e) {
          this.$emit('error', e);
          this.$emit('done');
          this.screenshotLoading = false;
        }
      }, this.timeout);
    },
  },
};
</script>

<style lang="scss">
.zoom-in {
  font-size: 3em !important;
  position: relative;
  top: 40%;
  left: 40%;
  cursor: pointer;
}
.screenshot-progress {
  position: absolute;
}
</style>
