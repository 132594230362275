export default class StoreNumberParser {
  static parse(username) {
    const result = /.*?(\d+)@/.exec(username);

    if (result && result.length === 2) {
      return result[1];
    }

    return null;
  }
}
