<template>
  <v-dialog v-if="checkEnabled" persistent v-model="showDialog" max-width="50%">
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    disablePromise: null,
  },
  computed: {
    checkEnabled() {
      return this.disablePromise !== null;
    },
  },
  async mounted() {
    if (this.disablePromise) {
      this.showDialog = await Promise.resolve(this.disablePromise);
    }
  },
};
</script>
