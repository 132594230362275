<template>
  <v-row>
    <v-col xl="3" lg="3" md="4" sm="6" xs="6">
      <v-dialog
        max-width="600px"
        v-if="props.couponImage && props.couponImage.url"
        v-model="showImageDialog"
      >
        <template v-slot:activator="{}">
          <a
            class="text-decoration-underline"
            :href="props.couponImage.url"
            @click.stop.prevent="showImageDialog = true"
            >{{ props.couponImage && props.couponImage.file ? props.couponImage.file.name : '' }}</a
          >
        </template>
        <v-row :class="[loaded ? 'checkerboard' : '', 'ma-0']">
          <data-authed-image
            @load="loaded = true"
            :src="props.couponImage.url"
            :headers="imageHeaders"
            transition="none"
          />
        </v-row>
      </v-dialog>
      <span v-else class="text--black">
        {{ props.couponImage && props.couponImage.file ? props.couponImage.file.name : '' }}
      </span>
    </v-col>
    <v-col xl="4" lg="4" md="4" sm="5" xs="5">
      <v-progress-linear
        :indeterminate="isLoading"
        :value="isComplete ? 100 : undefined"
        :color="isError || isTooLarge ? 'error' : undefined"
        :background-opacity="isError || isTooLarge ? 1 : undefined"
        v-if="isLoading || isComplete || isError || isTooLarge"
        height="1.5em"
        >{{ loadingText }}</v-progress-linear
      >
    </v-col>
    <v-col cols="1">
      <v-icon class="pointer" @click="onRemove">close</v-icon>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import Vue, { computed, ref, defineComponent, defineEmits } from 'vue';
import { CouponImage, ImageUploadState } from '@/stores/couponimageuploadstore';

defineComponent({
  name: 'CouponImageUploadRow',
});

const props = defineProps({
  couponImage: {
    type: Object as () => CouponImage,
    required: true,
  },
});

const emit = defineEmits(['remove']);

const showImageDialog = ref(false);
const loaded = ref(false);
const imageHeaders = {
  'x-api-host': Vue.prototype.$apiHostAndProtocol,
};

const loading: number = ImageUploadState.loading;
const complete: number = ImageUploadState.complete;
const error: number = ImageUploadState.error;
const tooLarge: number = ImageUploadState.tooLarge;

function onRemove() {
  emit('remove', props.couponImage);
}

const isLoading = computed(() => {
  return props.couponImage && props.couponImage.uploadState === loading;
});

const isComplete = computed(() => {
  return props.couponImage && props.couponImage.uploadState === complete;
});

const isError = computed(() => {
  return props.couponImage && props.couponImage.uploadState === error;
});

const isTooLarge = computed(() => {
  return props.couponImage && props.couponImage.uploadState === tooLarge;
});

const loadingText = computed(() => {
  if (isComplete.value) {
    return 'Complete';
  } else if (isError.value) {
    return 'Error';
  } else if (isTooLarge.value) {
    return 'Too Large';
  }
  return '';
});
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.checkerboard {
  background-image: url('../../assets/checkboard.png');
  background-repeat: repeat;
  overflow: hidden;
  max-width: 600px;
}
</style>
