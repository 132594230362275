<template>
  <v-data-table
    :headers="props.headers"
    :items="props.vendTrans"
    :options.sync="options"
    :loading="props.loading"
    :footer-props="{ 'items-per-page-options': [] }"
    :server-items-length="isServerSide ? serverItemsLength : undefined"
    :hide-default-footer="props.hideDefaultFooter"
    @update:page="updatePage"
    :page="internalPage"
    :items-per-page="props.itemsPerPage"
  >
    <template v-slot:item.Details="{ item }">
      <router-link
        v-if="item.TransType === 'Payment'"
        :to="{
          name: 'vendorportal_vendtranspayrefdetail',
          params: { dataAreaId: item.DataAreaId, voucher: item.Voucher },
        }"
        >Details</router-link
      ></template
    >
    <template v-slot:item.DataAreaId="{ item }">
      <span class="no-wrap">{{ getLegalEntityLookup(item.DataAreaId) }}</span>
    </template>
    <template v-slot:item.DocumentDate="{ item }">
      {{ formatters.formatDate(item.DocumentDate) }}
    </template>
    <template v-slot:item.CreatedOn="{ item }">
      {{ formatters.formatDate(item.CreatedOn) }}
    </template>
    <template v-slot:item.DueDate="{ item }">
      {{ formatters.formatDate(item.DueDate) }}
    </template>
    <template v-slot:item.Closed="{ item }">
      {{ formatters.formatDate(item.Closed) }}
    </template>
    <template v-slot:item.TransDate="{ item }">
      {{ formatters.formatDate(item.TransDate) }}
    </template>
    <template v-slot:item.ClosedDate="{ item }">
      {{ formatters.formatDate(item.ClosedDate) }}
    </template>
    <template v-slot:item.AmountCur="{ item }">
      {{ formatters.formatCurrency(item.AmountCur) }}
    </template>
    <template v-slot:item.Balance="{ item }">
      {{ formatters.formatCurrency(Number(item.AmountCur) - Number(item.SettleAmountCur)) }}
    </template>
    <template v-slot:item.SettleAmountCur="{ item }">
      {{ formatters.formatCurrency(item.SettleAmountCur) }}
    </template>
    <template v-slot:item.PaymReference="{ item }">
      <span v-if="item.PaymReference" class="no-wrap">{{ item.PaymReference }}</span>
    </template>
    <template v-slot:item.Invoice="{ item }">
      <span class="no-wrap">{{ item.Invoice }}</span>
    </template>
    <template v-slot:item.PaymMode="{ item }">
      <span class="no-wrap">{{ item.PaymMode }}</span>
    </template>
    <template v-slot:footer.page-text="props">
      <span>{{ props.pageStart }} - {{ props.pageStop }}</span>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useLegalEntityLookup } from '@/composables/financeComposables';
import { VendTransBiEntityDto } from '@psp/pogona_vendor_api_lib';
import { useFormatters } from '@/composables/formatters';
import { TransGridHeader } from '@/composables/TransGridShared';
type VendTransGridEvents = {
  (event: 'load-vend-trans', take: number, skip: number): void;
  (event: 'update-page', page: number): void;
};
interface VendTransGridProps {
  headers: TransGridHeader[];
  vendTrans: VendTransBiEntityDto[];
  loading: boolean;
  hideDefaultFooter: boolean;
  page: number;
  isServerSide?: boolean;
  itemsPerPage?: number;
}
const props = withDefaults(defineProps<VendTransGridProps>(), {
  vendTrans: () => [],
  loading: false,
  hideDefaultFooter: false,
  page: 1,
  isServerSide: true,
  itemsPerPage: 10,
});
const emit = defineEmits<VendTransGridEvents>();
const emitLoadVendTrans = (take: number, skip: number) => emit('load-vend-trans', take, skip);
const emitUpdatePage = (page: number) => emit('update-page', page);
const formatters = useFormatters();
const { getLegalEntityLookup } = useLegalEntityLookup();
const options = ref({});
const serverItemsLength = 9999;
const internalPage = ref(props.page);
const updatePage = (page: number) => {
  internalPage.value = page;
  emitUpdatePage(page);
};
defineComponent({
  name: 'VendTransGrid',
});
watch(
  () => props.page,
  newPage => {
    if (props.itemsPerPage !== -1) {
      internalPage.value = newPage;
      if ('page' in options.value) {
        options.value.page = newPage;
      }
    }
  },
);
watch(options, async (curr: any, prev: any) => {
  if ('itemsPerPage' in curr) {
    curr.itemsPerPage = props.itemsPerPage;
  }
  if (props.itemsPerPage !== -1 && 'page' in curr && 'page' in prev && curr.page !== prev.page) {
    emitLoadVendTrans((curr.page - 1) * curr.itemsPerPage, curr.itemsPerPage);
  }
});
</script>
<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
