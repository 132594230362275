import Vue from 'vue';

function formatCurrency(value, zeroIfNull) {
  if (value) {
    const formattedVal = (value / 1).toFixed(2);
    return `$${formattedVal.toString()}`;
  }

  return zeroIfNull ? '$0.00' : '';
}

Vue.filter('formatCurrency', value => {
  return formatCurrency(value, false);
});

Vue.filter('formatCurrencyZeroIfNull', value => {
  return formatCurrency(value, true);
});

function formatDecimal(value, places, includePercentSymbol, zeroIfNull) {
  let placesR = 2;
  if (isNaN(places) === false) {
    placesR = places;
  }

  if (zeroIfNull && (value === null || value === undefined)) {
    value = 0;
  }

  if (value !== null && value !== undefined) {
    return `${(value / 1).toFixed(placesR).toString()}${includePercentSymbol === true ? '%' : ''}`;
  }

  return '';
}

Vue.filter('formatPercent', (value, places) => formatDecimal(value, places, true, false));
Vue.filter('formatDecimal', (value, places) => formatDecimal(value, places, false, false));

Vue.filter('formatPercentZeroIfNull', (value, places) => formatDecimal(value, places, true, true));
Vue.filter('formatDecimalZeroIfNull', (value, places) => formatDecimal(value, places, false, true));
