<template>
  <core-view-template :title="title">
    <div>
      <show-impersonation />
      <invoice-search class="mb-4"></invoice-search>
      <v-data-table
        :headers="headers"
        :items="vpFinanceStore.salesInvoiceSearchResults"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [] }"
        :server-items-length="serverItemsLength"
      >
        <template v-slot:item.InvoiceDate="{ item }"> {{ formatDate(item.InvoiceDate) }}</template>
        <template v-slot:item.InvoiceNumber="{ item }">
          <router-link
            :to="{
              name: 'vendorportal_invoicedetail',
              params: { invoiceNumber: item.InvoiceNumber },
            }"
            class="no-wrap"
          >
            {{ item.InvoiceNumber }}
          </router-link>
        </template>
        <template v-slot:item.TotalInvoiceAmount="{ item }">
          {{ formatCurrency(item.TotalInvoiceAmount) }}</template
        >
        <template v-slot:item.TotalTaxAmount="{ item }">
          {{ formatCurrency(item.TotalTaxAmount) }}</template
        >
        <template v-slot:item.DataAreaId="{ item }">
          {{ getLegalEntityLookup(item.DataAreaId) }}
        </template>
        <template v-slot:footer.page-text="props">
          <span>{{ props.pageStart }} - {{ props.pageStop }}</span>
        </template>
      </v-data-table>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import InvoiceSearch from '@/components/vendorportal/InvoiceSearch.vue';
import { useLegalEntityLookup } from '@/composables/financeComposables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';

defineComponent({
  name: 'InvoiceSearchResult',
});

const vpFinanceStore = useVpFinanceStore();
const { getLegalEntityLookup } = useLegalEntityLookup();
const route = useRoute();
const loading = ref(false);
const options = ref({});
const headers = [
  { text: 'Company', value: 'DataAreaId', sortable: false },
  { text: 'Customer Account Number', value: 'InvoiceCustomerAccountNumber', sortable: false },
  { text: 'Invoice Number', value: 'InvoiceNumber', sortable: false },
  { text: 'Invoice Date', value: 'InvoiceDate', sortable: false },
  { text: 'Total Invoice Amount', value: 'TotalInvoiceAmount', sortable: false, align: 'end' },
  { text: 'Total Tax Amount', value: 'TotalTaxAmount', sortable: false, align: 'end' },
];
const serverItemsLength = 9999;

// Load invoices
function loadInvoices(skip: number, take: number) {
  loading.value = true;
  vpFinanceStore
    .searchInvoices(invoiceSearch.value, fromDate.value, toDate.value, skip, take)
    .finally(() => {
      loading.value = false;
    });
}

// Create options watch
watch(options, async (curr: any) => {
  if ('page' in curr) {
    loadInvoices((curr.page - 1) * curr.itemsPerPage, curr.itemsPerPage);
  }
});

// Watch the route invoice search parameter and update the search results on change
watch(
  () => route.params,
  () => {
    invoiceSearch.value = invoiceSearchRoute.value;
    fromDate.value = routeFromDate.value;
    toDate.value = routerToDate.value;
    if ('page' in options.value && options.value.page !== 1) {
      options.value.page = 1;
    } else {
      loadInvoices(0, 10);
    }
  },
);

const invoiceSearchRoute = computed(() => {
  return route.params.invoiceSearch === '-' ? '' : decodeURIComponent(route.params.invoiceSearch);
});
const routeFromDate = computed(() => {
  return route.params.fromDate === '-' ? null : decodeURIComponent(route.params.fromDate);
});
const routerToDate = computed(() => {
  return route.params.toDate === '-' ? null : decodeURIComponent(route.params.toDate);
});
const title = computed(() => {
  // Make title with search and dates if they exist
  return (
    (invoiceSearchRoute.value
      ? `Invoice Search Results For Search ${invoiceSearchRoute.value}`
      : 'Invoice Search Results') +
    (routeFromDate.value ? ` From ${routeFromDate.value}` : '') +
    (routerToDate.value ? ` To ${routerToDate.value}` : '')
  );
});
const invoiceSearch = ref(invoiceSearchRoute.value);
const fromDate = ref<string | null>(routeFromDate.value);
const toDate = ref<string | null>(routerToDate.value);

function formatCurrency(value: any) {
  return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formatDate(date: string) {
  return new Date(date).toLocaleDateString();
}
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
