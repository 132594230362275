<template>
  <v-container>
    <v-main v-if="!showValidationErrors && !showValidationReview && !showProcessing">
      <v-row>
        <v-col>
          <v-file-input
            v-model="file"
            label="Click to choose exceptions Excel file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn :loading="validating" @click="uploadClicked" :disabled="!file || validating"
            >Upload Excel File</v-btn
          >
        </v-col>
        <v-col>
          <v-btn @click="showUploadsClick">Show Uploads</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="downloadExcelTemplate">Download Excel Template</v-btn>
        </v-col>
      </v-row>
    </v-main>
    <auto-ship-validation-errors v-if="showValidationErrors" />
    <auto-ship-validation-review v-if="showValidationReview" />
    <auto-ship-import-process v-if="showProcessing" />
  </v-container>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as ea from '@/store/modules/ecom/actions';
import * as es from '@/store/modules/ecom/state';
import * as em from '@/store/modules/ecom/mutations';
import { SubscriptionValidationResult } from '@psp/pogona_ecom_client_js/SubscriptionValidationResult';
import AutoShipValidationErrors from './AutoShipValidationErrors.vue';
import AutoShipValidationReview from './AutoShipValidationReview.vue';
import AutoShipImportProcess from './AutoShipImportProcess.vue';

/* eslint-disable @typescript-eslint/no-explicit-any */

const ecomModule = namespace('ecom');

@Component({
  components: {
    AutoShipImportProcess,
    AutoShipValidationErrors,
    AutoShipValidationReview,
  },
})
export default class AutoShipUpload extends Vue {
  @ecomModule.Action(ea.validateExcelFile)
  validateExcelFile!: (payload: File) => Promise<any>;
  @ecomModule.Action(ea.exportTemplate)
  exportTemplate!: () => Promise<any>;
  @ecomModule.Mutation(em.setShowProcessing) setShowProcessing!: (payload: boolean) => void;
  @ecomModule.State(es.validationData) validationData!: SubscriptionValidationResult | null;
  @ecomModule.State(es.showProcessing) showProcessing!: boolean;

  private file: File | null = null;
  private validating = false;

  get showValidationReview(): boolean {
    return (
      !this.showProcessing &&
      this.validationData !== null &&
      this.validationData?.Errors?.length == 0
    );
  }

  get showValidationErrors(): boolean {
    return (
      !this.showProcessing && this.validationData !== null && this.validationData.Errors?.length > 0
    );
  }

  private downloadExcelTemplate() {
    this.exportTemplate();
  }

  private showUploadsClick() {
    this.setShowProcessing(true);
  }

  private uploadClicked() {
    this.validating = true;
    if (this.file) {
      this.validateExcelFile(this.file).finally(() => {
        this.validating = false;
        this.file = null;
      });
    }
  }
}
</script>
