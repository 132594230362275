<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-row class="mx-0 override-dialog">
      <v-col class="px-0">
        <v-card>
          <v-card-title>Unit Override</v-card-title>
          <v-card-subtitle>Input the weight listed on the item package. Do not weigh the item manually on a scale.
            If the packaging doesn't display a weight, do not override it's UOM.</v-card-subtitle>
          <v-card-text v-if="override">
            <v-col>
              <v-row>
                <v-col cols="3" class="pt-8">Package Weight</v-col>
                <v-col cols="8"><v-text-field v-model="override.packageSize" @change="tryConvert"
                    :error-messages="packageSizeErrors"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="pt-8">Package Unit</v-col>
                <v-col cols="8">
                  <v-select v-model="override.packageUnitMeasureConversionSymbol" :items="units" item-text="text"
                    item-value="value" @change="
                      unitChanged();
                    tryConvert();
                    " label="Unit"></v-select></v-col>
              </v-row>
              <v-row v-if="askIfDry">
                <v-col cols="8"><v-checkbox v-model="override.isDryWeight" :disabled="askIfDryDisabled"
                    @change="tryConvert" label="Is this dry weight?"></v-checkbox></v-col>
              </v-row>
              <v-row v-if="askIfDry">
                <v-col cols="8"><v-checkbox v-model="override.isShampooConditioner"
                    :disabled="override.isDryWeight === true" @change="tryConvert"
                    label="Shampoo, conditioner, or calming?"></v-checkbox></v-col>
              </v-row>

              <v-row>
                <v-card>
                  <v-card-title>Converted Unit{{
                    noConversionNeeded ? ' (No Conversion Needed)' : ''
                  }}:</v-card-title>
                  <v-card-text v-if="converted">{{ converted.size }} {{ converted.unitMeasure }}
                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="readyToSave === false" :loading="savingUnitOfMeasureOverride" @click="save">Save</v-btn>
            <v-btn @click="dialog = false" :disabled="savingUnitOfMeasureOverride">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script setup lang="ts">
import Vue, { ref, onMounted, defineComponent, defineProps, watch, computed } from 'vue';
import {
  UnitOfMeasureOverride,
  UnitOfMeasureOverrideHandheldPayload,
  useUnitOverrideStore,
} from '@/stores/unitoverridestore';
import * as rs from '@/store/modules/app/state';
import * as convert from 'convert-units';
import moment from 'moment';
import HandHeldLine from '@/data/SignsAndLabels/HandHeldLine';

const unitOverrideStore = useUnitOverrideStore();

defineComponent({
  name: 'UnitOverrideForm',
});

const props = defineProps({
  modelValue: null,
  showOverrideForm: {
    type: Boolean,
    required: false,
  },
  selectedItem: {
    type: Object as () => any,
    required: false,
  },
  selectedHandheldBatch: {
    type: Number,
    required: false,
  },
  unitOfMeasureOverrideTypeId: {
    type: Number,
    required: false,
  },
  isEdit: {
    type: Boolean,
    required: false,
  },
  excludedHandHeldLines: {
    type: Array<number>,
    required: false,
  },
});

const override = ref<UnitOfMeasureOverride>(new UnitOfMeasureOverride());
const converted = ref<UnitOfMeasureOverride | null>(null);
const dialog = ref(false);
const noConversionNeeded = ref(false);
const readyToSave = ref(false);
const units = [
  { text: 'CI', value: 'in3' },
  { text: 'FT', value: 'ft-us' },
  { text: 'GL', value: 'gal' },
  { text: 'GM', value: 'g' },
  { text: 'LB', value: 'lb' },
  { text: 'LT', value: 'l' },
  { text: 'ML', value: 'ml' },
  { text: 'OZ', value: 'oz' },
  { text: 'QT', value: 'qt' },
  { text: 'PT', value: 'pnt' },
];
const askIfDry = ref(false);
const askIfDryDisabled = ref(false);
const packageSizeErrors = ref<string[]>([] as string[]);

const savingUnitOfMeasureOverride = computed(() => {
  return unitOverrideStore.savingUnitOfMeasureOverride;
});

const storeNumber = computed(() => {
  return Vue.prototype.$store.state.app.storeNumber;
});

const emit = defineEmits(['update:modelValue', 'saved', 'update:showOverrideForm']);

onMounted(() => {
  override.value = props.modelValue;
  if (override.value) {
    unitChanged();
  }
});

function unitChanged() {
  if (!override.value) {
    return;
  }

  askIfDry.value =
    override.value.packageUnitMeasureConversionSymbol === 'oz' ||
    override.value.packageUnitMeasureConversionSymbol === 'qt' ||
    override.value.packageUnitMeasureConversionSymbol === 'pnt';

  if (
    override.value.packageUnitMeasureConversionSymbol === 'qt' ||
    override.value.packageUnitMeasureConversionSymbol === 'pnt'
  ) {
    askIfDryDisabled.value = true;
  } else {
    askIfDryDisabled.value = false;
  }
}

function tryConvert() {
  converted.value = null;
  noConversionNeeded.value = false;
  readyToSave.value = false;
  packageSizeErrors.value = [];

  if (askIfDry.value === true) {
    if (override.value.isDryWeight === true) {
      override.value.isShampooConditioner = false;
    }
  }

  if (
    override.value &&
    (override.value.packageUnitMeasureConversionSymbol?.length ?? 0) > 0 &&
    isNaN(override.value.packageSize) == false
  ) {
    const size = override.value.packageSize;
    let fromUnit = override.value.packageUnitMeasureConversionSymbol;
    let toUnit = fromUnit;

    if (fromUnit === 'oz' && override.value.isDryWeight === false) {
      fromUnit = 'fl-oz';
      toUnit = override.value.isShampooConditioner ? 'pnt' : 'qt';
    } else if (fromUnit === 'qt' && override.value.isShampooConditioner === true) {
      toUnit = 'pnt';
    } else if (fromUnit === 'pnt' && override.value.isShampooConditioner === false) {
      toUnit = 'qt';
    } else {
      switch (fromUnit) {
        case 'in3': {
          toUnit = 'ft3';
          break;
        }
        case 'l':
        case 'ml':
        case 'gal': {
          toUnit = 'qt';
          break;
        }
        case 'g':
        case 'oz': {
          toUnit = 'lb';
          break;
        }
      }
    }

    let convertedSize = size;
    if (fromUnit !== toUnit) {
      convertedSize = convert(size).from(fromUnit).to(toUnit).toFixed(3);
    } else {
      noConversionNeeded.value = true;
    }

    // find the text value for the toUnit
    const convertedUnits = units.filter(x => x.value === toUnit);
    let toUnitText = toUnit;
    if (convertedUnits && convertedUnits.length === 1) {
      toUnitText = convertedUnits[0].text;
    }

    converted.value = new UnitOfMeasureOverride(
      0,
      toUnitText.toUpperCase(),
      convertedSize,
      uomValueToText(toUnit).toUpperCase(),
      toUnit,
      size,
      '',
      '',
      1,
      false,
      false,
      null,
      null,
      storeNumber.value,
    );

    if (convertedSize > 0) {
      readyToSave.value = true;
    } else {
      readyToSave.value = false;
      packageSizeErrors.value.push('Converted size must be greater than zero.');
    }
  } else if (override.value && isNaN(override.value.packageSize) == true) {
    packageSizeErrors.value.push('Please enter a numeric value.');
  }
}

async function save() {
  unitOverrideStore.savingUnitOfMeasureOverride = true;

  if (converted.value) {
    const unitOfMeasureOverride = new UnitOfMeasureOverride(
      override.value?.unitOfMeasureOverrideId,
      converted.value.unitMeasure,
      converted.value.size,
      uomValueToText(override.value.packageUnitMeasureConversionSymbol),
      override.value.packageUnitMeasureConversionSymbol,
      override.value.packageSize,
      props.selectedItem?.upc ?? '',
      props.selectedItem?.itemId ?? '',
      props.unitOfMeasureOverrideTypeId,
      override.value.isDryWeight,
      override.value.isShampooConditioner,
      props.isEdit === true ? undefined : moment.utc().format(),
      props.isEdit === true ? moment.utc().toDate() : null,
      storeNumber.value,
    );

    // trim off the Z from the end of the date string because the json parsing converts it to local
    if (
      unitOfMeasureOverride.timeInserted &&
      unitOfMeasureOverride.timeInserted[unitOfMeasureOverride.timeInserted.length - 1] === 'Z'
    ) {
      unitOfMeasureOverride.timeInserted = unitOfMeasureOverride.timeInserted.substring(
        0,
        unitOfMeasureOverride.timeInserted.length - 1,
      );
    }

    if (props.selectedHandheldBatch !== null) {
      const handheldOverridePayload = new UnitOfMeasureOverrideHandheldPayload(
        props.selectedHandheldBatch,
        unitOfMeasureOverride,
        props.excludedHandHeldLines,
      );
      await unitOverrideStore.createUnitOfMeasureOverridesFromHandheldBatch(
        handheldOverridePayload,
      );
    } else {
      if (props.isEdit === true) {
        delete unitOfMeasureOverride.timeInserted;
        await unitOverrideStore.editUnitOfMeasureOverride(unitOfMeasureOverride);
      } else {
        await unitOverrideStore.createUnitOfMeasureOverride(unitOfMeasureOverride);
      }
    }

    noConversionNeeded.value = false;
    dialog.value = false;

    emit('update:showOverrideForm', false);
    emit('saved');
  }
}
const formattedUnitMeasure = computed(() => {
  if (override.value) {
    const text = uomValueToText(override.value.unitMeasure);

    if (text && text.length > 0) {
      return text.toUpperCase();
    }
  }
  return '';
});

function uomTextToValue(unitMeasure: string) {
  // take the "text" from the db and set it to the value
  const convertedUnits = units.filter(x => x.text === unitMeasure);
  if (convertedUnits && convertedUnits.length === 1) {
    return convertedUnits[0].value;
  }
  return '';
}

function uomValueToText(unitMeasure: string) {
  // take the "text" from the db and set it to the value
  const convertedUnits = units.filter(x => x.value === unitMeasure);
  if (convertedUnits && convertedUnits.length === 1) {
    return convertedUnits[0].text;
  }
  return '';
}

watch(
  () => override.value,
  val => {
    override.value = val;
    unitChanged();
    emit('update:modelValue', val);
  },
);

watch(
  () => props.modelValue,
  val => {
    override.value = val;
  },
);

watch(
  () => dialog.value,
  val => {
    emit('update:showOverrideForm', val);
  },
);

watch(
  () => props.showOverrideForm,
  val => {
    dialog.value = val;
    unitOverrideStore.savingUnitOfMeasureOverride = false;
    if (val === true) {
      if (props.isEdit === true) {
        tryConvert();
      } else {
        override.value = new UnitOfMeasureOverride();
        converted.value = null;
        noConversionNeeded.value = false;
      }
    }
  },
);
</script>

<style scoped>
>>>.v-dialog {
  overflow-y: visible;
}
</style>
