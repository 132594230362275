import { render, staticRenderFns } from "./CouponImageUploadRow.vue?vue&type=template&id=ad5f8d20&scoped=true"
import script from "./CouponImageUploadRow.vue?vue&type=script&setup=true&lang=ts"
export * from "./CouponImageUploadRow.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CouponImageUploadRow.vue?vue&type=style&index=0&id=ad5f8d20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad5f8d20",
  null
  
)

export default component.exports