/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';

// Store functionality
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import modules from './modules';
import state from './state';

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  modules,
  state,
});

// Subscribe to store updates
store.subscribe((mutation, currState) => {
  // Store the state object as a JSON string
  // don't do anything until the store is initialized from local storage
  if (currState.storeInitialized === true) {
    const state = {
      app: currState.app,
      tours: currState.tours,
      pcl: {
        pclListingAllHeaders: currState.pcl.pclListingAllHeaders,
        pclRemovedHeaders: currState.pcl.pclRemovedHeaders,
      },
    };

    localStorage.setItem('store', JSON.stringify(state));
  }
});

export default store;
