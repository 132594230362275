<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="text--black">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>
