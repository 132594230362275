<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="dialogClick"
          @click.stop=""
          v-bind:style="UseDefaultStyles ? styleDefault : null"
        >
          {{ buttonText }}
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Manage Division/Regions</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedDivisionRegionId"
                  label="Select Division/Region"
                  :items="divisionRegionItems"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Division/Region Name"
                  v-model="dr.Name"
                  :error-messages="errorMessages.Name"
                  @blur="validate('Name')"
                  @change="validate('Name')"
                  counter="100"
                  maxlength="100"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedVicePresidentEmployeeId"
                  :items="employeeDropDownData"
                  label="Vice President"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedDirectorRoleEmployeeId"
                  :items="employeeDropDownData"
                  label="Director"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip top :disabled="deleteEnabled">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn
                  v-if="selectedDivisionRegionId !== 0"
                  color="blue darken-1"
                  text
                  @click="deleteClicked"
                  :disabled="!deleteEnabled"
                  v-on="on"
                  >Delete</v-btn
                ></span
              >
            </template>
            <span>Division cannot be deleted because it has assigned districts</span>
          </v-tooltip>
          <v-btn color="blue darken-1" text @click="closeClicked">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveClicked" :loading="loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline"> Are you sure you want to delete </v-card-title>
        <v-card-actions>
          <v-btn @click="showConfirmDelete = false">No</v-btn>
          <v-btn @click="deleteConfirmed">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <p v-for="e in validationErrors" v-bind:key="e">
        {{ e }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import * as pcldto from '@psp/pcldto';
import * as pclclient from '@psp/pogona_pcl_client_js';
import { mixins } from 'vue-class-component';
import DivisionRegionEditMixin from './DivisionRegionEditMixin';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */

const pclModule = namespace('pcl');

@Component({
  components: {},
})
export default class ManageDivisionRegionPopup extends mixins(DivisionRegionEditMixin) {
  @Prop({ default: 0 }) DrId!: number;
  @Prop({ default: true }) UseDefaultStyles!: boolean;
  @Prop({ default: 'Edit Division/Region' }) buttonText!: string;

  @pclModule.Action(pa.deleteDivisionRegion) deleteDivisionRegion!: (
    divisionRegionId: number,
  ) => Promise<unknown>;

  private dialog = false;
  private showConfirmDelete = false;
  private confirmDelete = false;
  private selectedDivisionRegionId = 0;
  private styleDefault = {
    color: 'white',
    'border-width': '1px',
    'border-style': 'solid',
    'border-color': 'white',
    'background-color': '#4caf50 !important',
  };

  private get deleteEnabled() {
    return this.selectedDivisionRegionId !== 0 && this.dr.Districts?.length === 0;
  }

  private dialogClick() {
    this.loading = true;
    this.getEmployees().then(() => (this.loading = false));
  }

  private reset() {
    this.selectedDivisionRegionId = 0;
  }

  private closeClicked() {
    this.dialog = false;
  }

  private deleteClicked() {
    this.loading = true;
    this.deleteDivisionRegion(this.dr.DivisionRegionId)
      .then(() => {
        this.reset();
        this.$emit('save-complete');
        this.dialog = false;
      })
      .finally(() => (this.loading = false));
  }

  private saveClicked() {
    this.save()
      .then(() => {
        this.reset();
        this.$emit('save-complete');
        this.dialog = false;
      })
      .finally(() => (this.loading = false));
  }

  get divisionRegionItems() {
    const data = [] as unknown[];
    data.push({ name: '(Add New)', value: 0 });
    this.divisionRegions.map(d => data.push({ name: d.Name, value: d.DivisionRegionId }));
    return data;
  }

  private deleteConfirmed() {
    console.log('deleteConfirmed');
  }

  @Watch('selectedDivisionRegionId') selectedDivisionRegionIdChanged(next: number): void {
    this.loadDivisionRegion(next);
  }

  @Watch('selectedVicePresidentEmployeeId') selectedVicePresidentEmployeeIdChanged(
    next: number,
    current: number,
  ): void {
    if (next !== current) {
      if (next === 0) {
        this.dr.VicePresidentRole = null;
      } else {
        if (this.dr.VicePresidentRole?.EmployeeId !== next) {
          this.dr.VicePresidentRole = new pcldto.pclDivisionRegionWithRelatedTypes.VicePresidentRolepclVicePresidentRole(
            this.dr.DivisionRegionId,
            null,
            next,
            0,
            pclclient.RoleName.VicePresidentOfStores,
          );
        }
      }
    }
  }

  @Watch('selectedDirectorRoleEmployeeId') selectedDirectorRoleEmployeeIdChange(
    next: number,
    current: number,
  ): void {
    if (next !== current) {
      if (next === 0) {
        this.dr.DirectorRole = null;
      } else {
        if (this.dr.DirectorRole?.EmployeeId !== next) {
          this.dr.DirectorRole = new pcldto.pclDivisionRegionWithRelatedTypes.DirectorRolepclDirectorRole(
            this.dr.DivisionRegionId,
            null,
            next,
            0,
            pclclient.RoleName.Director,
          );
        }
      }
    }
  }

  @Watch('dialog') dialogChanged(next: boolean) {
    if (next === true) {
      this.initiailize();
    }
  }

  private initiailize() {
    this.selectedDivisionRegionId = this.DrId;
    this.loadDivisionRegion(this.DrId);
  }
}
</script>
