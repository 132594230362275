import * as vx from 'vuex';
import { PclState, StoreListingAllSearch, PclStoreExportPayload } from './state';
import * as m from '@/store/mutations';
import * as em from './mutations';
import {
  Address,
  SaveDivisionRegionData,
  StoreCopy,
  StoreCreateUpdatePayload,
  GroomingStoreService,
  DogWashService,
  CurbsidePickupStoreService,
  DeliveryStoreService,
  AutoShipStoreService,
  PclStoreExport,
  DeliveryMethodResult,
} from '@psp/pogona_pcl_client_js';
import * as pcldto from '@psp/pcldto';
import buildQuery from 'odata-query';
import ODataProps from '@/utils/ODataProps';
import FileSaver from 'file-saver';
import dateFormat from 'dateformat';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const searchStores = 'searchStores';
export const getStore = 'getStore';
export const getStoreForEdit = 'getStoreForEdit';
export const searchStoreToCheckExistence = 'searchStoreToCheckExistence';
export const searchCounties = 'searchCounties';
export const getDistricts = 'getDistricts';
export const getMarkets = 'getMarkets';
export const getStates = 'getStates';
export const getStoreStatuses = 'getStoreStatuses';
export const getServices = 'getServices';
export const getDivsionRegions = 'getDivsionRegions';
export const getRoleNames = 'getRoleNames';
export const getDeliveryMethods = 'getDeliveryMethods';
export const getTimeZones = 'getTimeZones';
export const getPriceZones = 'getPriceZones';
export const getDmas = 'getDmas';
export const getStoreListingAll = 'getStoreListingAll';
export const getDistributionCenters = 'getDistributionCenters';
export const getDogWashTubTypes = 'getDogWashTubTypes';
export const createStore = 'createStore';
export const updateStore = 'updateStore';
export const getEmployees = 'getEmployees';
export const createEmployee = 'createEmployee';
export const updateEmployee = 'updateEmployee';
export const deleteEmployee = 'deleteEmployee';
export const getDivisionRegionForEdit = 'getDivisionRegionForEdit';
export const saveDivisionRegion = 'saveDivisionRegion';
export const getDivisionRegionsNoRelated = 'getDivisionRegionsNoRelated';
export const getDivisionRegions = 'getDivisionRegions';
export const getOwners = 'getOwners';
export const updateOwner = 'updateOwner';
export const createOwner = 'createOwner';
export const deleteOwner = 'deleteOwner';
export const exportListingAll = 'exportListingAll';
export const exportOwners = 'exportOwners';
export const downloadDivisionRegion = 'downloadDivisionRegion';
export const getDivisionRegionResults = 'getDivisionRegionResults';
export const getDistrictResults = 'getDistrictResults';
export const getMarketResults = 'getMarketResults';
export const deleteDivisionRegion = 'deleteDivisionRegion';
export const getDeliveryAreaLookup = 'getDeliveryAreaLookup';
export const getSocialMediaLookup = 'getSocialMediaLookup';

export default {
  [searchStores]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setStoreSearchInProgress, true);

    return state
      .client!.searchStores(state.storeSearch)
      .then(x => {
        commit(em.setStoreData, x.data);
        commit(em.setStoreSearchInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving stores',
            id: '78ed75f7-3437-46ff-95bb-92c56f1cb8be',
          },
          { root: true },
        );
        throw err;
      });
  },

  [getStoreForEdit](
    { commit, state }: vx.ActionContext<PclState, any>,
    storeId: number,
  ): Promise<any> {
    commit(em.setStoreSearchInProgress, true);

    return state
      .client!.getStoreForEdit(storeId)
      .then(x => {
        if (x.data.CopyStore === null) {
          x.data.CopyStore = new StoreCopy();
        }

        if (x.data.GroomingStoreService === null) {
          x.data.GroomingStoreService = new GroomingStoreService();
        }
        if (x.data.DogWashService === null) {
          x.data.DogWashService = new DogWashService();
        }
        if (x.data.CurbsidePickupStoreService === null) {
          x.data.CurbsidePickupStoreService = new CurbsidePickupStoreService();
        }
        if (x.data.DeliveryStoreService === null) {
          x.data.DeliveryStoreService = new DeliveryStoreService();
        }
        if (x.data.AutoShipStoreService === null) {
          x.data.AutoShipStoreService = new AutoShipStoreService();
        }

        if (x.data.Store.Address === null) {
          x.data.Store.Address = new Address();
        } else {
          // set the zip and plus 4
          if (x.data.Store.Address.Zip && x.data.Store.Address.Zip.length > 5) {
            const fullZip = x.data.Store.Address.Zip;
            x.data.Store.Address.Zip = fullZip.substring(0, 5);
            x.data.Store.Address.ZipPlus4 = fullZip.substring(5, 9);
          }
        }

        commit(em.setStoreEditData, x.data);
        commit(em.setStoreSearchInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: `Error retrieving store ${storeId}`,
            id: '33747d5e-6708-4d82-990c-7b58148ea958',
          },
          { root: true },
        );
        throw err;
      });
  },
  [searchStoreToCheckExistence](
    { state }: vx.ActionContext<PclState, any>,
    storeNumber: string | number,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let storeNumberToSearch = 0;
      if (typeof storeNumber === 'string') {
        storeNumberToSearch = Number(storeNumber);
      } else {
        storeNumberToSearch = storeNumber;
      }

      state
        .client!.searchStoreToCheckExistence(storeNumberToSearch)
        .then(x => {
          resolve(x?.data ?? null);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [searchCounties](
    { commit, state }: vx.ActionContext<PclState, any>,
    stateCode: string,
  ): Promise<any> {
    commit(em.setCountySearchInProgress, true);

    return state
      .client!.searchCounties(stateCode)
      .then(x => {
        commit(em.setCountyData, x.data);
        commit(em.setCountySearchInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving counties',
            id: '61e3ef4f-043b-4cd0-b179-53b413d60baf',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDistricts]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDistrictGetInProgress, true);

    return state
      .client!.getDistricts()
      .then(x => {
        commit(em.setDistrictData, x.data);
        commit(em.setDistrictGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving districts',
            id: '8d91908a-b085-49fa-ae0e-eaf0107245af',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getMarkets](
    { commit, state }: vx.ActionContext<PclState, any>,
    districtId: number | null | undefined,
  ): Promise<any> {
    commit(em.setMarketGetInProgress, true);

    return state
      .client!.getMarkets(districtId)
      .then(x => {
        commit(em.setMarketData, x.data);
        commit(em.setMarketGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving markets',
            id: '64669ccf-3f2a-4875-85ef-da02fb7728f3',
          },
          { root: true },
        );
        throw err;
      });
  },

  [getStates]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setStateGetInProgress, true);

    return state
      .client!.getStates()
      .then(x => {
        commit(em.setStateData, x.data);
        commit(em.setStateGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving states',
            id: 'ca7e0826-c9cf-49c7-afa8-4dae40d7219e',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getStoreStatuses]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setStoreStatusesGetInProgress, true);

    return state
      .client!.getStoreStatuses()
      .then(x => {
        commit(em.setStoreStatusesData, x.data);
        commit(em.setStoreStatusesGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving store statuses',
            id: '3d548c10-a45d-4cd7-b087-dfb2930978fe',
          },
          { root: true },
        );
        throw err;
      });
  },

  [getServices]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setServicesGetInProgress, true);

    return state
      .client!.getServices()
      .then(x => {
        commit(em.setServicesData, x.data);
        commit(em.setServicesGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving services',
            id: '9bd21351-5703-4264-b590-e17674f070c2',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDivsionRegions](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: number | null,
  ): Promise<any> {
    return state
      .client!.getDivsionRegions(payload)
      .then(x => {
        commit(em.setDivisionRegions, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving divsion/regions',
            id: '314f5107-a581-427e-8d8c-b4e2318f51f3',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getRoleNames]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    return state
      .client!.getRoleNames()
      .then(x => {
        commit(em.setRoleNames, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving role names',
            id: '5fc2336e-1109-49e5-996b-f3a83b94f08f',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDeliveryMethods]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDeliveryMethodsGetInProgress, true);

    return state
      .client!.getDeliveryMethods('?$orderby=Name&$top=99999')
      .then(x => {
        commit(em.setDeliveryMethodData, x.data);
        commit(em.setDeliveryMethodsGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving delivery methods',
            id: '9c069a33-2d1c-4b0d-b38b-6b1bd07d0ced',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getTimeZones]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setTimeZoneGetInProgress, true);

    return state
      .client!.getTimeZones()
      .then(x => {
        commit(em.setTimeZoneData, x.data);
        commit(em.setTimeZoneGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving time zones',
            id: '0c2089c2-e0ad-45f5-bc74-65bbee3120cb',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getPriceZones]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setPriceZoneGetInProgress, true);

    return state
      .client!.getPriceZones()
      .then(x => {
        commit(em.setPriceZoneData, x.data);
        commit(em.setPriceZoneGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving price zones',
            id: '054f96ed-0652-43ab-8042-e8513df0031d',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDmas]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDmasGetInProgress, true);

    return state
      .client!.getDmas()
      .then(x => {
        commit(em.setDmaData, x.data);
        commit(em.setDmasGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving DMAs',
            id: '05c74fa7-1198-48a6-8296-c79886d1f069',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getStoreListingAll](
    { commit, state }: vx.ActionContext<PclState, any>,
    searchParams: StoreListingAllSearch,
  ): Promise<any> {
    commit(em.setStoreListingAllDataGetInProgress, true);

    return state
      .client!.getListingAll(
        searchParams.searchParams,
        searchParams.orderBy as [],
        searchParams.top,
        searchParams.skip,
      )
      .then(x => {
        commit(em.setStoreListingAllData, x.data);
        commit(em.setStoreListingAllDataGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving store listing',
            id: '9c64b8d0-2eb7-456f-a871-289291d8b8f1',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDistributionCenters]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDistributionCenterGetInProgress, true);

    return state
      .client!.getDistributionCenters()
      .then(x => {
        commit(em.setDistributionCenterDataData, x.data);
        commit(em.setDistributionCenterGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving get distribution centers',
            id: 'e018ca6e-2355-48f5-896c-4282ff9ae9a9',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDogWashTubTypes]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDogWashTubTypesGetInProgress, true);

    return state
      .client!.getDogWashTubTypes()
      .then(x => {
        commit(em.setDogWashTubTypesData, x.data);
        commit(em.setDogWashTubTypesGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving dog wash tub types',
            id: 'dd2dfd7c-11bc-4a74-a2e9-9c7b5f1671c3',
          },
          { root: true },
        );
        throw err;
      });
  },
  [createStore](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: StoreCreateUpdatePayload,
  ): Promise<any> {
    commit(em.setCreateUpdateStoreInProgress, true);
    commit(em.setCreateUpdateStorePayload, payload);

    return state
      .client!.createStore(state.createUpdateStorePayload)
      .then(() => {
        commit(em.setCreateUpdateStoreInProgress, false);
        commit(m.setSuccess, `Created Store ${payload.Store.StoreNumber}`, { root: true });
      })
      .catch(err => {
        commit(em.setCreateUpdateStoreInProgress, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error creating store',
            id: 'a9892c2a-34ec-411b-81ed-7842ba466a35',
          },
          { root: true },
        );
        throw err;
      });
  },

  [updateStore](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: StoreCreateUpdatePayload,
  ): Promise<any> {
    commit(em.setCreateUpdateStoreInProgress, true);
    commit(em.setCreateUpdateStorePayload, payload);

    return state
      .client!.updateStore(state.createUpdateStorePayload)
      .then(() => {
        commit(em.setCreateUpdateStoreInProgress, false);
        commit(m.setSuccess, `Updated Store ${payload.Store.StoreNumber}`, { root: true });
      })
      .catch(err => {
        commit(em.setCreateUpdateStoreInProgress, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error updating store',
            id: 'f3b15fbd-6ccd-4551-99bc-d2ee7159d36f',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getEmployees]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    return state
      .client!.getEmployees()
      .then(x => {
        commit(em.setEmployees, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving employees',
            id: 'eee3473a-f9f9-4cc1-9f8d-ef10c3a8dd0c',
          },
          { root: true },
        );
        throw err;
      });
  },
  [createEmployee](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: pcldto.pclEmployee,
  ): Promise<any> {
    return state.client!.createEmployee(payload).catch(err => {
      commit(
        m.setError,
        {
          err,
          text: 'Error saving employee',
          id: 'cba3abfa-5abf-4d44-8eac-3a38e00c40f3',
        },
        { root: true },
      );
      throw err;
    });
  },
  [updateEmployee](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: pcldto.pclEmployee,
  ): Promise<any> {
    return state.client!.updateEmployee(payload).catch(err => {
      commit(
        m.setError,
        {
          err,
          text: 'Error updating employee',
          id: '136e4912-ff10-4f44-b15c-4f5a786ef43d',
        },
        { root: true },
      );
      throw err;
    });
  },
  [deleteEmployee](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: number,
  ): Promise<any> {
    return state.client!.deleteEmployee(payload).catch(err => {
      commit(
        m.setError,
        {
          err,
          text: 'Error deleting employee',
          id: '79376676-f5a3-452f-837c-72d0dfc05651',
        },
        { root: true },
      );
      throw err;
    });
  },
  [getDivisionRegionForEdit](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: number | null,
  ): Promise<any> {
    if (payload === 0) {
      commit(em.clearDivisionRegionEdit);
      return Promise.resolve();
    } else {
      return state
        .client!.getDivsionRegions(payload)
        .then(x => {
          if (x.data.length === 1) {
            commit(em.setDivisionRegionEdit, x.data[0]);
          } else {
            throw new Error('getDivisionRegionForEdit returned more than one division/region');
          }
        })
        .catch(err => {
          commit(
            m.setError,
            {
              err,
              text: 'Error retrieving divsion/region for edit',
              id: '49134a68-f7cd-4ade-9368-48b61252f4d8',
            },
            { root: true },
          );
          throw err;
        });
    }
  },

  [getDivisionRegions]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDivisionRegionsGetInProgress, true);

    return state
      .client!.getDivisionRegionsNoRelated()
      .then(x => {
        commit(em.setDivisionRegionsGetInProgress, false);
        commit(em.setDivisionRegionsData, x.data);
      })
      .catch(err => {
        commit(em.setDivisionRegionsGetInProgress, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error getting division regions',
            id: '46e1956a-d59d-42df-8f42-515b182c3493',
          },
          { root: true },
        );
        throw err;
      });
  },

  [saveDivisionRegion](
    { commit, state, dispatch }: vx.ActionContext<PclState, any>,
    payload: SaveDivisionRegionData,
  ): Promise<any> {
    return state
      .client!.saveDivisionRegion(payload)
      .then(x => {
        return dispatch(getDivisionRegionForEdit, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error saving divsion/region data',
            id: 'c9902ce2-29b4-49f8-859f-188a148ad08a',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDivisionRegionsNoRelated]({ commit, state }: vx.ActionContext<PclState, any>): Promise<any> {
    return state
      .client!.getDivisionRegionsNoRelated()
      .then(x => {
        return commit(em.setDivisionRegionsNoRelated, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving divsion/region data',
            id: '33e08e55-49b2-4c4d-8195-819def22fb0b',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getOwners]({ commit, state }: vx.ActionContext<PclState, any>, odata: ODataProps): Promise<any> {
    commit(em.setOwnersGetInProgress, true);

    const odataStr = buildQuery(odata).substring(1);

    return Promise.all([state.client!.getOwners(odataStr), state.client!.getOwnersCount(odataStr)])
      .then(x => {
        commit(em.setOwnersData, { data: x[0].data, skip: odata.skip });
        commit(em.setOwnersDataCount, x[1].data);
        commit(em.setOwnersGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving owners',
            id: '9db57b8b-98bc-4a75-abfb-431da0812008',
          },
          { root: true },
        );
        throw err;
      });
  },
  [updateOwner](
    { commit, state }: vx.ActionContext<PclState, any>,
    owner: pcldto.pclOwner,
  ): Promise<any> {
    commit(em.setOwnersSaveInProgress, true);

    return state
      .client!.updateOwner(owner)
      .then(x => {
        commit(em.setOwnersSaveInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error saving owner',
            id: '7710c91c-9e0b-4ac5-a8f1-ed5dc5e81319',
          },
          { root: true },
        );
        throw err;
      });
  },
  [createOwner](
    { commit, state }: vx.ActionContext<PclState, any>,
    owner: pcldto.pclOwner,
  ): Promise<any> {
    commit(em.setOwnersSaveInProgress, true);

    return state
      .client!.createOwner(owner)
      .then(x => {
        commit(em.setOwnersSaveInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error creating owner',
            id: '010ab504-98de-4603-bf49-71e500bd9375',
          },
          { root: true },
        );
        throw err;
      });
  },
  [deleteOwner]({ commit, state }: vx.ActionContext<PclState, any>, ownerId: number): Promise<any> {
    commit(em.setOwnersDeleteInProgress, true);

    return state
      .client!.deleteOwner(ownerId)
      .then(x => {
        commit(em.setOwnersDeleteInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error deleting owner',
            id: 'e5250c3f-0ad5-4dbf-8da7-7af3713cf289',
          },
          { root: true },
        );
        throw err;
      });
  },
  [exportListingAll](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: PclStoreExportPayload,
  ): Promise<any> {
    commit(em.setExportListingAllInProgress, true);

    const exportBody = new PclStoreExport(payload.SearchParams.searchParams, payload.Headers);

    return state
      .client!.exportListingAll(exportBody, payload.SearchParams.orderBy)
      .then(x => {
        commit(em.setExportListingAllInProgress, false);

        FileSaver.saveAs(
          x.data,
          `store-export-all-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(em.setExportListingAllInProgress, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error exporting listing all',
            id: '7661e2b7-f880-4a75-a167-933125ba36cb',
          },
          { root: true },
        );
        throw err;
      });
  },
  [exportOwners](
    { commit, state }: vx.ActionContext<PclState, any>,
    payload: ODataProps,
  ): Promise<any> {
    commit(em.setExportOwnersInProgress, true);

    const odata = Object.assign({}, payload);
    odata.top = 99999;
    const odataString = buildQuery(odata).substring(1);

    return state
      .client!.exportOwners(odataString)
      .then(x => {
        commit(em.setExportOwnersInProgress, false);

        FileSaver.saveAs(x.data, `owner-export-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`);
      })
      .catch(err => {
        commit(em.setExportOwnersInProgress, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error exporting owners',
            id: '6970a343-6e02-4c23-b3a6-0c08ef65c398',
          },
          { root: true },
        );
        throw err;
      });
  },
  [downloadDivisionRegion]({ commit, state }: vx.ActionContext<PclState, any>): Promise<unknown> {
    return state
      .client!.downloadDivisionRegion()
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `division-region-data-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error division region Excel file',
            id: 'fcac3777-43fd-48a5-a158-9be36c8453c3',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDivisionRegionResults](
    { commit, state }: vx.ActionContext<PclState, any>,
    odata: ODataProps,
  ): Promise<any> {
    const odataStr = buildQuery(odata).substring(1);
    return state
      .client!.getDivisionRegionByOData(odataStr)
      .then(x => {
        commit(em.setDivisionRegionResults, x.data);
        return x.data;
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving division region data',
            id: '209b4b0e-90e0-4b0a-8d14-fa21bd75b4f8',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getDistrictResults](
    { commit, state }: vx.ActionContext<PclState, any>,
    odata: ODataProps,
  ): Promise<any> {
    const odataStr = buildQuery(odata).substring(1);
    return state
      .client!.getDistrictByOData(odataStr)
      .then(x => {
        commit(em.setDistrictResults, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving district data',
            id: 'c93e0855-cdae-4df3-8c14-9bffe932c790',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getMarketResults](
    { commit, state }: vx.ActionContext<PclState, any>,
    odata: ODataProps,
  ): Promise<any> {
    const odataStr = buildQuery(odata).substring(1);
    return state
      .client!.getMarketByOData(odataStr)
      .then(x => {
        commit(em.setMarketResults, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving district data',
            id: '53d46fba-ecd5-4bc7-ae89-5e1f101f2a57',
          },
          { root: true },
        );
        throw err;
      });
  },
  [deleteDivisionRegion](
    { state, commit }: vx.ActionContext<PclState, any>,
    divisionRegionId: number,
  ): Promise<any> {
    return state.client!.deleteDivisionRegion(divisionRegionId).catch(err => {
      commit(
        m.setError,
        {
          err,
          text: 'Delete division region failed',
          id: '2b48cf2f-a9b9-442b-aa12-eb6e14ab27ab',
        },
        { root: true },
      );
      throw err;
    });
  },
  [getDeliveryAreaLookup]({ state, commit }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setDeliveryAreaLookupGetInProgress, true);
    return state
      .client!.getDeliveryAreaLookup('?$orderby=DeliveryAreaMiles&$top=99999')
      .then(x => {
        commit(em.setDeliveryAreaLookupGetInProgress, false);
        commit(em.setDeliveryAreaLookup, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Get delivery area lookup failed',
            id: '5881d4cd-ce07-4423-89aa-b852d7fceda6',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getSocialMediaLookup]({ state, commit }: vx.ActionContext<PclState, any>): Promise<any> {
    commit(em.setSocialMediaGetInProgress, true);
    return state
      .client!.getSocialMedia()
      .then(x => {
        commit(em.setSocialMediaGetInProgress, false);
        commit(em.setSocialMediaLookup, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Get social media lookup failed',
            id: 'e3ca7cd0-1bb8-408b-957e-da0a7387665e',
          },
          { root: true },
        );
        throw err;
      });
  },
};
