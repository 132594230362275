<template>
  <iframe :style="{ width, height }" :src="dsrc" v-if="loaded"></iframe>
</template>

<script>
import AxiosHelpers from '@/utils/AxiosHelpers';

export default {
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    authApi: {
      type: Object,
      required: false,
    },
    height: {
      type: String,
      required: false,
      default: '100%',
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    presentationModeButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    downloadButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    printButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    openFileButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewBookmarkButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    zoom: {
      type: String,
      required: false,
      default: null,
    },
    // not always needed
    // this was implemented for the carousel in render progress component
    // if a user quickly paged through the carousel, sometimes, pdf's would show as loading forever
    cacheBuster: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      dsrc: '',
      basePath: `${this.$publicPath || '/'}lib/pdfjs/web/viewer.html`,
      loaded: false,
      axiosHelpers: new AxiosHelpers(),
    };
  },
  methods: {
    async setSrc() {
      try {
        let pdfSrc = '';
        if (this.authApi) {
          const response = await this.authApi.http.get(this.src, {
            responseType: 'arraybuffer',
            headers: {
              'x-ms-version': '2017-11-09',
              'Content-Type': 'application/json',
              accept: 'application/pdf',
            },
          });

          pdfSrc = `${this.basePath}?file=${this.axiosHelpers.axiosResponseToDataUri(response)}`;
        } else {
          pdfSrc = `${this.basePath}?file=${this.src}`;
        }

        if (this.presentationModeButton === true) {
          pdfSrc += `&presentationModeButton=false`;
        }
        if (this.downloadButton === true) {
          pdfSrc += `&download=false`;
        }
        if (this.printButton === true) {
          pdfSrc += `&print=false`;
        }
        if (this.openFileButton === true) {
          pdfSrc += `&openFile=false`;
        }
        if (this.viewBookmarkButton === true) {
          pdfSrc += `&viewBookmark=false`;
        }
        if (this.zoom) {
          pdfSrc += `#zoom=${this.zoom}`;
        }

        this.dsrc = pdfSrc;

        if (this.cacheBuster && this.cacheBuster.trim().length > 0) {
          this.dsrc += '&cachebuster=' + this.cacheBuster;
        }
      } catch (e) {
        this.$error(e);
      } finally {
        this.loaded = true;
      }
    },
  },
  async mounted() {
    await this.setSrc();
  },
  watch: {
    src: {
      handler: async function () {
        await this.setSrc();
      },
    },
    presentationModeButton: {
      handler: async function () {
        await this.setSrc();
      },
    },
    downloadButton: {
      handler: async function () {
        await this.setSrc();
      },
    },
    printButton: {
      handler: async function () {
        await this.setSrc();
      },
    },
    openFileButton: {
      handler: async function () {
        await this.setSrc();
      },
    },
    viewBookmarkButton: {
      handler: async function () {
        await this.setSrc();
      },
    },
    zoom: {
      handler: async function () {
        await this.setSrc();
      },
    },
    cacheBuster: {
      handler: async function () {
        await this.setSrc();
      },
    },
  },
};
</script>
