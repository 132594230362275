<template>
  <core-view-template title="New Item Batch Search">
    <batch-search-data-table
      @snackbar-warning="$emit('snackbar-warning', $event)"
      @snackbar-error="$emit('snackbar-error', $event)"
    />
  </core-view-template>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import BatchSearchDataTable from '@/components/vendorportal/BatchSearchDataTable.vue';

@Component({
  components: { BatchSearchDataTable },
})
export default class BatchSearch extends Vue {}
</script>
