/* eslint-disable @typescript-eslint/no-explicit-any */
import { IExcelData, Lookup } from '@/utils/IExcelData';
import { Manufacturer, Brand, Vendor, UnitOfMeasure } from '@/utils/VendorDomainTypes';
import NumberHelpers from '../../../utils/NumberHelpers';

export default class PetPartnerExcelData implements IExcelData {
  constructor(
    public manufacturers: Manufacturer[],
    public brands: Brand[],
    public vendors: Vendor[],
    public unitOfMeasures: UnitOfMeasure[],
    public daxItemNumber = '',
    public fullUnitBarcode = '',
    public caseBarcode = '',
    public otherBarcode = '',
    public productName = '',
    public retailPackageSize = '',
    public retailPackageUOM = '',
    public manufacturerAccountNumber: string | null = '',
    public brand: number | null = null,
    public vendorId: string | null = '',
    public externalItemNumber = '',
    public masterCase = 0,
    public innerCasePack = 0,
    public layerQuantity = 0,
    public palletQuantity = 0,
    public directToStore = '',
    public directToStoreOrVendorIntoPspdPurchaseCost = 0,
    public mfgSuggestedRetail = 0,
    public mapPrice = 0,
    public imap = 0,
    public autoShipEligible = false,
    public initialDiscountFundingPercent = 0,
    public onGoingFundingDiscountPercent = 0,
    public materialSafetyDataSheetNeeded = false,
    public minimumOrderQuantity = 0,
    public unitWeight = 0,
    public unitHeight = 0,
    public unitWidth = 0,
    public unitDepth = 0,
    public caseHeight = 0,
    public caseWidth = 0,
    public caseDepth = 0,
    public productAttribute = '',
    public distributionNetwork = '',
    public shipsToStores = '',
    public shipUnitCostFromPspd = 0,
    public storeCaseCost = 0,
    public retailBasePrice = 0,
    public grossPercentage = 0,
    public retailHighPrice = 0,
    public hierarchy = '',
    public categoryManagerName = '',
    public pspdInventoryManagerName = '',
    public reason = '',
    public seasonal = '',
    public season = '',
    public year = '',
    public category = '',
    public fobCost = 0,
    public caseCbm = 0,
    public itemCbm = 0,
    public pvDim = '',
    public htsCode = 0,
    public dutyRate = 0,
    public fobPort = 0,
    public estFreightCost = 0,
    public duty = 0,
    public elc = 0,
    public pluScan = '',
    public pluAssigned = '',
    public signsAndLabelsDescription1 = '',
    public signsAndLabelsDescription2 = '',
    public aggId = '',
  ) {}

  private _errors = [] as string[];
  public get errors(): Array<string> {
    return this._errors;
  }
  public pushError(val: string): void {
    this._errors.push(val);
  }

  public clearErrors(): void {
    this._errors = [];
  }

  private _dateFieldIndexes = [] as number[];
  public get dateFieldIndexes(): Array<number> {
    return this._dateFieldIndexes;
  }

  createType(rowIx: number, parameters: Array<string>): IExcelData | null {
    try {
      console.log('PetPartnerExcelData.createType parameters', parameters);
      if (
        !parameters ||
        parameters.length === 0 ||
        parameters.length < 3 ||
        !parameters[1] ||
        parameters[1].length === 0
      ) {
        return null;
      }

      const brandId = this.getLookUpDbValue('brand', parameters[8]);

      const newInstance = new PetPartnerExcelData(
        this.manufacturers, // manufacturers
        this.brands, // brands
        this.vendors, // vendors
        this.unitOfMeasures, // unitOfMeasures
        parameters[0], // daxItemNumber
        parameters[1], // fullUnitBarcode
        parameters[2], // caseBarcode
        parameters[3], // otherBarcode
        parameters[4], // productName
        parameters[5], // retailPackageSize
        parameters[6], // retailPackageUOM
        this.getLookUpDbValue('manufacturerAccountNumber', parameters[7])?.toString() ?? '', // manufacturerAccountNumber
        brandId && isNaN(Number(brandId)) === false ? Number(brandId) : null, // brand
        this.getLookUpDbValue('vendorId', parameters[9])?.toString() ?? '', // vendorId
        parameters[10], // externalItemNumber
        NumberHelpers.convertParameterToNumber(parameters[11]), // masterCase
        NumberHelpers.convertParameterToNumber(parameters[12]), // innerCasePack
        NumberHelpers.convertParameterToNumber(parameters[13]), // layerQuantity
        NumberHelpers.convertParameterToNumber(parameters[14]), // palletQuantity
        parameters[15], // directToStore
        NumberHelpers.convertParameterToNumber(parameters[16]), // directToStoreOrVendorIntoPspdPurchaseCost
        NumberHelpers.convertParameterToNumber(parameters[17]), // mfgSuggestedRetail
        NumberHelpers.convertParameterToNumber(parameters[18]), // mapPrice
        NumberHelpers.convertParameterToNumber(parameters[19]), // imap
        parameters[20] === 'Yes', // autoShipEligible
        NumberHelpers.convertParameterToNumber(parameters[21]), // initialDiscountFundingPercent
        NumberHelpers.convertParameterToNumber(parameters[22]), // onGoingFundingDiscountPercent
        parameters[23] === 'Yes', // materialSafetyDataSheetNeeded
        NumberHelpers.convertParameterToNumber(parameters[24]), // minimumOrderQuantity
        NumberHelpers.convertParameterToNumber(parameters[25]), // unitWeight
        NumberHelpers.convertParameterToNumber(parameters[26]), // unitHeight
        NumberHelpers.convertParameterToNumber(parameters[27]), // unitWidth
        NumberHelpers.convertParameterToNumber(parameters[28]), // unitDepth
        NumberHelpers.convertParameterToNumber(parameters[29]), // caseHeight
        NumberHelpers.convertParameterToNumber(parameters[30]), // caseWidth
        NumberHelpers.convertParameterToNumber(parameters[31]), // caseDepth
        parameters[32], // productAttribute
        parameters[33], // distributionNetwork
        parameters[34], // shipsToStores
        NumberHelpers.convertParameterToNumber(parameters[35]), // shipUnitCostFromPspd
        NumberHelpers.convertParameterToNumber(parameters[36]), // storeCaseCost
        NumberHelpers.convertParameterToNumber(parameters[37]), // retailBasePrice
        NumberHelpers.convertParameterToNumber(parameters[38]), // grossPercentage
        NumberHelpers.convertParameterToNumber(parameters[39]), // retailHighPrice
        parameters[40], // hierarchy
        parameters[41], // categoryManagerName
        parameters[42], // pspdInventoryManagerName
        parameters[43], // reason
        parameters[44], // seasonal
        parameters[45], // season
        parameters[46], // year
        parameters[47], // category
        NumberHelpers.convertParameterToNumber(parameters[48]), // fobCost
        NumberHelpers.convertParameterToNumber(parameters[49]), // caseCbm
        NumberHelpers.convertParameterToNumber(parameters[50]), // itemCbm
        parameters[51] ?? '', // pvDim
        NumberHelpers.convertParameterToNumber(parameters[52]), // htsCode
        NumberHelpers.convertParameterToNumber(parameters[53]), // dutyRate
        NumberHelpers.convertParameterToNumber(parameters[54]), // fobPort
        NumberHelpers.convertParameterToNumber(parameters[55]), // estFreightCost
        NumberHelpers.convertParameterToNumber(parameters[56]), // duty
        NumberHelpers.convertParameterToNumber(parameters[57]), // elc
        parameters[58], // pluScan
        parameters[59], // pluAssigned
        parameters[60], // signsAndLabelsDescription1
        parameters[61], // signsAndLabelsDescription2
        parameters.length >= 63 ? parameters[62] : '', // aggId
      );
      console.log('PetPartnerExcelData.createType newInstance', newInstance);
      return newInstance;
    } catch (e) {
      console.error('PetPartnerExcelData.createType error', e);
      return null;
    }
  }

  equals(other: IExcelData): boolean {
    if (!other) {
      return false;
    }
    const otherPetPartner = other as PetPartnerExcelData;
    return otherPetPartner.fullUnitBarcode === this.fullUnitBarcode;
  }

  get lookups(): Record<string, Lookup[]> {
    return {
      manufacturerAccountNumber: this.manufacturers.map(
        x => new Lookup(`${x.Number} ${x.Name}`, `${x.Number} ${x.Name}`, x.Number),
      ),
      brand:
        this.brands.map(
          x => new Lookup(`${x.Number} ${x.Name}`, `${x.Number} ${x.Name}`, x.Number),
        ) ?? [],
      vendorId: this.vendors.map(
        x => new Lookup(`${x.Number} ${x.Name}`, `${x.Number} ${x.Name}`, x.Number),
      ),
      retailPackageUOM: this.unitOfMeasures.map(
        x => new Lookup(x.UnitOfMeasure, x.UnitOfMeasure, x.UnitOfMeasure),
      ),
    };
  }

  public getLookUpDbValue(fieldName: string, value: string): string | number | null {
    try {
      if (this.lookups[fieldName] && value && value.length > 0) {
        const dbValueIx = this.lookups[fieldName].findIndex(x => x.value === value);
        if (dbValueIx > -1) {
          return this.lookups[fieldName][dbValueIx].dbValue;
        }
      }
    } catch (e) {
      console.error('getLookUpDbValue error', e);
    }
    return null;
  }

  get excludedColumns(): string[] {
    return ['manufacturers', 'brands', 'vendors', 'unitOfMeasures'];
  }

  get headers(): any[] {
    return [
      { value: 'daxItemNumber', text: 'PSP Item #', width: '140px' },
      { value: 'fullUnitBarcode', text: 'Full Unit Barcode', width: '140px' },
      { value: 'caseBarcode', text: 'Case Barcode', width: '140px' },
      { value: 'otherBarcode', text: 'Other Barcode', width: '140px' },
      { value: 'productName', text: 'Product Name', width: '140px' },
      { value: 'retailPackageSize', text: 'Retail Package Size' },
      { value: 'retailPackageUOM', text: 'Retail UOM' },
      { value: 'manufacturerAccountNumber', text: 'Manufacturer Account #' },
      { value: 'brand', text: 'Brand' },
      { value: 'vendorId', text: 'Vendor ID' },
      { value: 'externalItemNumber', text: 'Exteral Item Number' },
      { value: 'masterCase', text: 'Master Case (# units in entire case)' },
      { value: 'innerCasePack', text: 'Inner/Case Pack (# units to store)' },
      { value: 'layerQuantity', text: 'Layer Quantity (# of units/layer)' },
      { value: 'palletQuantity', text: 'Pallet Quantity (# of units/pallet)' },
      { value: 'directToStore', text: 'Direct to Store (ships unit or case)' },
      {
        value: 'directToStoreOrVendorIntoPspdPurchaseCost',
        text: 'Direct to store or vendor into PSPD purchase cost (PSPD purchase cost must be in unit)',
      },
      { value: 'mfgSuggestedRetail', text: 'MFG Suggested Retail' },
      { value: 'mapPrice', text: 'Map Price' },
      { value: 'imap', text: 'IMAP' },
      { value: 'autoShipEligible', text: 'Autoship Eligible' },
      { value: 'initialDiscountFundingPercent', text: 'Initial Discount Funding %' },
      { value: 'onGoingFundingDiscountPercent', text: 'Outgoing Discount Funding %' },
      { value: 'materialSafetyDataSheetNeeded', text: 'Material Safety Data Sheet Needed' },
      { value: 'minimumOrderQuantity', text: 'Minimum Order Quantity (MOQ)' },
      { value: 'unitWeight', text: 'Unit Weight' },
      { value: 'unitHeight', text: 'Unit Height' },
      { value: 'unitWidth', text: 'Unit Width' },
      { value: 'unitDepth', text: 'Unit Depth' },
      { value: 'caseHeight', text: 'Case Height' },
      { value: 'caseWidth', text: 'Case Width' },
      { value: 'caseDepth', text: 'Case Depth' },
      { value: 'productAttribute', text: 'Product Attribute' },
      { value: 'distributionNetwork', text: 'Ships To Stores Via' },
      { value: 'shipsToStores', text: 'Ships To Stores Unit or Case' },
      { value: 'shipUnitCostFromPspd', text: 'Store Unit Cost' },
      { value: 'storeCaseCost', text: 'Store Case Cost' },
      { value: 'retailBasePrice', text: 'Retail Base Price' },
      { value: 'grossPercentage', text: 'Gross Percentage' },
      { value: 'retailHighPrice', text: 'Retail High' },
      { value: 'hierarchy', text: 'Hierarchy' },
      { value: 'categoryManagerName', text: 'Category Manager' },
      { value: 'pspdInventoryManagerName', text: 'InventoryManager' },
      { value: 'reason', text: 'Reason' },
      { value: 'seasonal', text: 'Seasonal' },
      { value: 'season', text: 'Season' },
      { value: 'year', text: 'Year' },
      { value: 'category', text: 'Category' },
      { value: 'fobCost', text: 'FOB Cost' },
      { value: 'caseCbm', text: 'Case CBM' },
      { value: 'pvDim', text: 'PV Dim' },
      { value: 'htsCode', text: 'HTS Code' },
      { value: 'dutyRate', text: 'Duty Rate' },
      { value: 'fobPort', text: 'FOB Port' },
      { value: 'estFreightCost', text: 'Estimate Freight Cost' },
      { value: 'duty', text: 'Duty' },
      { value: 'elc', text: 'ELC (SEYMOUR, IN)' },
      { value: 'pluScan', text: 'PLU Scan' },
      { value: 'pluAssigned', text: 'PLU Assigned' },
      { value: 'signsAndLabelsDescription1', text: 'S+L Description 1' },
      { value: 'signsAndLabelsDescription2', text: 'S+L Description 2' },
    ];
  }
}
