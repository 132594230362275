<template>
  <v-layout
    align-center
    justify-center
    row
    fill-height
    :style="modalStyle"
    id="modalBackground"
    class="ma-0"
  >
    <v-progress-circular
      id="progress"
      :width="width"
      :color="loadingColor"
      :size="size"
      indeterminate
    ></v-progress-circular>
  </v-layout>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: true,
    },
    width: {
      type: Number,
      required: false,
      default: 3,
    },
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#424242',
    },
    loadingColor: {
      type: String,
      required: false,
      default: 'green',
    },
    opacity: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    modalStyle() {
      return `display:${this.show ? 'inherit' : 'none'};background-color:${
        this.backgroundColor
      };opacity:${this.opacity}`;
    },
  },
  methods: {
    hideModal() {
      this.show = true;
    },
    showModal() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#modalBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
#progress {
  top: 50%;
  margin: 0 auto;
  position: absolute;
  transform: translateY(-50%);
}
</style>
