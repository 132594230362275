import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import { Ripple } from 'vuetify/lib/directives';

import theme from './theme';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'md',
  theme,
  directives: {
    Ripple,
  },
});
