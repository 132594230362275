import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  search: set('search'),
  pricingHistoryExpanded: set('pricingHistoryExpanded'),
  costHistoryExpanded: set('costHistoryExpanded'),
  movementHistoryExpanded: set('movementHistoryExpanded'),
  bmsExpanded: set('bmsExpanded'),
  labelItems: set('labelItems'),
  basePriceHistory: set('basePriceHistory'),
  swcHistory: set('swcHistory'),
  sncHistory: set('sncHistory'),
  bmsHistory: set('bmsHistory'),
  dailyMovementHistory: set('dailyMovementHistory'),
  baseCost: set('baseCost'),
  nonActivatedToggle: set('nonActivatedToggle'),
  selectedBarcodeSetups: set('selectedBarcodeSetups'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
};
