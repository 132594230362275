<template>
  <v-container class="mb-4">
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <p v-for="e in validationErrors" v-bind:key="e">
        {{ e }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="text--black">
                <v-row>
                  <v-col cols="auto"> How Does This Work? </v-col>
                  <v-col cols="auto">
                    <data-tour
                      class="mb-3"
                      tourName="vendorItemUpdateTour"
                      maxWidth="600px"
                      :steps="tourSteps"
                      :show="showTour"
                      :hideHelpIcon="hideHelpIcon"
                      :autoplay="true"
                      :scrollFirstStep="false"
                      :hideDoNotShowCheckbox="true"
                    ></data-tour>
                  </v-col> </v-row
              ></v-card-title>
              <v-card-subtitle
                >Choose the Category Manager from the drop down, then download the "Item Update
                Template" below, fill out your required fields including the required fields noted
                with an *, then simply copy and paste the data into the field below.
              </v-card-subtitle>
              <v-card-actions>
                <a id="dataEntry" :href="importSample" target="_blank"
                  >Item Update Excel Template</a
                ></v-card-actions
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row id="pasteUpdateItem">
          <v-col>
            <v-card>
              <v-card-title class="text--black">Category Manager</v-card-title>
              <v-card-actions>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="categoryManager"
                  :items="categoryManagers"
                  :loading="processing"
                  :disabled="processing"
                  autocomplete="off"
                  :item-text="x => `${x.FirstName} ${x.LastName}`"
                  :item-value="x => `${x.FirstName} ${x.LastName}`"
                  clearable
                >
                </v-autocomplete>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="text--black">Item Update Form</v-card-title>
              <v-card-subtitle
                >Copy all the data from your sheet, including the headers and click the paste
                button. All data will feed below.
              </v-card-subtitle>
              <data-paste-from-excel
                v-model="excelData"
                :typePrototype="typePrototype"
                :errorsLoading="processing"
                :errorHelperText="errorHelperText"
                @noClipboardData="showSnackbarMessage($event.text, 'warning')"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              @click="submitClickedMulti"
              :loading="processing"
              :disabled="
                processing ||
                multiSaveDisabled ||
                zeroPasteItems ||
                !categoryManager ||
                categoryManager.length === 0
              "
              >Save</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                Click SAVE once complete. Once SAVE is clicked, you can no longer edit.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as yup from 'yup';
import pLimit from 'p-limit';
import {
  Vendor,
  ReasonForNewItem,
  UnitOfMeasure,
  VendorRequestBarcodeInfo,
  PspEmployee,
} from '@/utils/VendorDomainTypes';
import UpcYupValidator from '@/utils/UpcYupValidation';
import ItemUpdateExcelData from '@/store/modules/vendorportal/ItemUpdateExcelData';
import ItemRequestImageUpload from '@/components/vendorportal/ItemRequestImageUpload.vue';

const vpModule = namespace('vendorportal');

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({
  components: { ItemRequestImageUpload },
})
export default class EditItemUpdate extends Vue {
  @Prop({ default: true }) public IsNew!: boolean;
  @Prop({ default: false }) public Processing!: boolean;

  @vpModule.Action('generateAggregateId') generateAggregateId!: () => Promise<string>;
  @vpModule.Action('getReasonsForNewItem') getReasonsForNewItem!: () => Promise<any>;
  @vpModule.Action('getUnitOfMeasures') getUnitOfMeasures!: () => Promise<any>;
  @vpModule.Action('checkForUpcInBarcodeMaster') checkForUpcInBarcodeMaster!: (
    upc: string,
  ) => Promise<VendorRequestBarcodeInfo[]>;
  @vpModule.Action('getCategoryManagers') getCategoryManagers!: () => Promise<any>;

  @vpModule.State('vendors') vendors!: Vendor[];
  @vpModule.State('reasonsForNewItem') reasonsForNewItem!: ReasonForNewItem[];
  @vpModule.State('unitOfMeasures') unitOfMeasures!: UnitOfMeasure[];
  @vpModule.State('categoryManagers') categoryManagers!: PspEmployee[];

  private defaultStatus = 'PendingReview';

  private showSnackbar = false;
  private snackbarMessage = '';
  private snackbarColor = '';
  private validationErrors = [];
  private id = '';
  private status = 'InProgress';
  private privateLabel = false;
  private saveComplete = false;
  private saveError = false;
  private showConfirmSubmit = false;
  private showRejectDialog = false;
  private multiSaveDisabled = false;
  private errorHelperText =
    'If an error is found, it will show here.  Please go back to your file, correct the error(s), come back to the above "Item Update Form" section, hit the clear button and then hit the paste button again till no errors occur.';

  private excelData: Array<ItemUpdateExcelData> = [] as Array<ItemUpdateExcelData>;
  private typePrototype: ItemUpdateExcelData | null = null;

  private updateUpc = '';

  private requestType = 'PackageChange';

  private processing = false;
  private isPetPartner = false;
  private isPspMerchServices = false;
  private isPspPrivateLabel = false;
  private isPspCategoryManager = false;
  private multiBarcodeOptions = [] as VendorRequestBarcodeInfo[];
  private selectedBarcodeInfo: VendorRequestBarcodeInfo | null = null;
  private dupeCheckUpcs = [] as string[];
  private categoryManager = '';

  private showTour = true;
  private hideHelpIcon = false;
  private tourSteps = [
    {
      target: '#dataEntry',
      header: {
        title: 'Data Entry',
      },
      video: 'item_update_data_entry',
      placement: 'bottom',
    },
    {
      target: '#pasteUpdateItem',
      header: {
        title: 'Paste Item Update Data',
      },
      video: 'item_update_paste',
      placement: 'bottom',
    },
  ];

  private schema: any = yup.object().shape({
    status: yup.string().required(),
    updateUpc: yup
      .string()
      .required()
      .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
    fullUnitBarcode: yup
      .string()
      .required()
      .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
    newFullUnitBarcode: yup
      .string()
      .notRequired()
      .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
    caseBarcode: yup
      .string()
      .notRequired()
      .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
    productName: yup.string().required(),
    retailPackageSize: yup.string().notRequired(),
    retailPackageUOM: yup.string().notRequired(),
    externalItemNumber: yup.string().required(),
    masterCase: yup.number().required().min(1),
    innerCasePack: yup.number().min(1).required(),
    palletQuantity: yup.number().min(1).required(),
    unitWeight: yup.number().notRequired(),
    unitWeightUOM: yup.string().notRequired(),
    daxItemNumber: yup.string().notRequired(),
    reason: yup.string().notRequired(),
    dateOfChange: yup.string().required(),
  });

  @Watch('Processing') processingPropChange(curr) {
    this.processing = curr;
  }

  private get zeroPasteItems() {
    return !this.excelData || this.excelData.length === 0;
  }

  private async validateMultiInput() {
    let allAreValid = true;
    const requestData = [] as any[];

    let lineIx = 0;
    for (const line of this.excelData) {
      lineIx++;
      line.clearErrors();
      const validatedState = await this.createStateFromMultiLineInput(line);
      requestData.push(validatedState);
      try {
        await this.schema.validate(validatedState);
      } catch (validationError: any) {
        line.pushError(`Row ${lineIx} has the following error: ${validationError.errors}`);
        allAreValid = false;
      }
    }

    return { allAreValid, requestData };
  }

  private async submitClickedMulti() {
    this.processing = true;
    const { allAreValid, requestData } = await this.validateMultiInput();
    if (allAreValid === true) {
      // set the category manager
      for (const r of requestData) {
        r.categoryManagerName = this.categoryManager;
      }

      this.$emit('request-data-multi', requestData);
    }

    this.processing = false;
  }

  private showSnackbarMessage(message: string, color: string) {
    this.snackbarMessage = message;
    this.snackbarColor = color;
    this.showSnackbar = true;
  }

  private async createStateFromMultiLineInput(line: ItemUpdateExcelData) {
    const multiId = await this.generateAggregateId();
    return {
      isNew: this.IsNew,
      id: multiId,
      status: this.defaultStatus,
      requestType: 'PackageChange',
      privateLabel: false,
      fullUnitBarcode: line.fullUnitBarcode,
      updateUpc: line.fullUnitBarcode,
      caseBarcode: line.caseBarcode ?? '',
      otherBarcode: '',
      productName: line.productName,
      retailPackageSize: line.retailPackageSize ?? '',
      retailPackageUOM: line.retailPackageUOM ?? '',
      manufacturerAccountNumber: '',
      brand: 0,
      vendorId: '',
      externalItemNumber: line.externalItemNumber ?? '',
      masterCase: line.masterCase ?? 0,
      innerCasePack: line.innerCasePack ?? '',
      layerQuantity: '',
      palletQuantity: line.palletQuantity ?? '',
      directToStore: '',
      directToStoreOrVendorIntoPspdPurchaseCost: 0,
      mfgSuggestedRetail: 0,
      mapPrice: 0,
      imap: 0,
      autoShipEligible: false,
      initialDiscountFundingPercent: 0,
      onGoingFundingDiscountPercent: 0,
      materialSafetyDataSheetNeeded: false,
      minimumOrderQuantity: 0,
      unitWeight: line.unitWeight ?? 0,
      unitWeightUOM: line.unitWeightUOM ?? '',
      unitHeight: 0,
      unitWidth: 0,
      unitDepth: 0,
      caseHeight: 0,
      caseWidth: 0,
      caseDepth: 0,
      productAttribute: '',
      images: [],
      programName: '',
      htsCode: 0,
      dutyRate: 0,
      fobPort: '',
      pluScan: '',
      pluAssigned: '',
      daxItemNumber: '',
      signsAndLabelsDescription1: '',
      signsAndLabelsDescription2: '',
      vendorNumber: '',
      shipsToStores: '',
      shipUnitCostFromPspd: 0,
      retailBasePrice: 0,
      retailHighPrice: 0,
      hierarchy: '',
      categoryManagerName: '',
      pspdInventoryManagerName: '',
      reason: '',
      newFullUnitBarcode: line.newFullUnitBarcode ?? '',
      dateOfChange: line.dateOfChange ?? '',
    };
  }

  private async mounted() {
    this.processing = true;

    this.isPetPartner = await this.$authApi.roleHasRights('PetPartner');
    this.isPspMerchServices = await this.$authApi.roleHasRights('PSPMerchServices');
    this.isPspPrivateLabel = await this.$authApi.roleHasRights('PSPPrivateLabel');
    this.isPspCategoryManager = await this.$authApi.roleHasRights('PSPCategoryManager');

    await Promise.all([this.getReasonsForNewItem(), this.getUnitOfMeasures()]);

    if (this.IsNew) {
      this.generateAggregateId().then(id => (this.id = id));
    }

    await Promise.all([this.getCategoryManagers()]);

    this.typePrototype = new ItemUpdateExcelData(this.unitOfMeasures);
    this.processing = false;
  }

  private async checkUpcInCurrentData(upc: string): Promise<any> {
    const requests = await Promise.all<any>([this.checkForUpcInBarcodeMaster(upc)]);
    let inBarcodeMaster = requests[0] as VendorRequestBarcodeInfo[];
    return { upc, inBarcodeMaster };
  }

  private get importSample() {
    return `${this.$storageHost}/vendorsamples/item_update_template.xlsx?_=${new Date().getTime()}`;
  }

  @Watch('excelData') async excelDataChanged() {
    this.processing = true;
    this.multiSaveDisabled = false;
    try {
      const { allAreValid } = await this.validateMultiInput();
      if (this.excelData && this.excelData.length > 0) {
        // check for duplicates within the dataset
        const upcCounts = this.excelData.reduce((p, c) => {
          if (!p[c.fullUnitBarcode]) {
            p[c.fullUnitBarcode] = 0;
          }
          p[c.fullUnitBarcode] += 1;
          return p;
        }, {});

        const dupeUpcCounts = Object.keys(upcCounts).filter(key => upcCounts[key] > 1);
        if (dupeUpcCounts?.length > 0) {
          for (const dupeUpc of dupeUpcCounts) {
            this.excelData[0].pushError(
              `UPC ${dupeUpc} appears multiple times in the data. Please ensure each UPC is unique.`,
            );
          }
        }

        const limit = pLimit(5);
        const dupeChecks = [] as Promise<any>[];
        // check for dupes
        for (const line of this.excelData) {
          if (this.dupeCheckUpcs.indexOf(line.fullUnitBarcode) === -1) {
            this.dupeCheckUpcs.push(line.fullUnitBarcode);
            dupeChecks.push(
              limit(async () => {
                return await this.checkUpcInCurrentData(line.fullUnitBarcode);
              }),
            );
          }
        }

        await Promise.all(dupeChecks);

        let lineIx = 1;
        let hasUpcError = false;
        for (const dupeCheck of dupeChecks) {
          const dupeCheckResult = await dupeCheck;
          if (dupeCheckResult.inBarcodeMaster?.length === 0) {
            let errorMessage = `Row ${lineIx} has the following error: `;
            if (dupeCheckResult.inBarcodeMaster?.length === 0) {
              errorMessage += `${dupeCheckResult.upc} is not in our product data. Please remove from Excel data.`;
            }
            this.excelData[0].pushError(errorMessage);
            hasUpcError = true;
          }

          lineIx += 1;
        }

        if (allAreValid !== true || hasUpcError === true) {
          this.multiSaveDisabled = true;
        }
      }

      this.processing = false;
    } catch (err) {
      console.error('excelDataChanged', err);
      this.multiSaveDisabled = true;
      this.processing = false;
      this.showSnackbarMessage('Failed to validate multiple item input.', 'error');
    }
  }
}
</script>

<style lang="scss" scoped>
.required {
  color: red !important;
  max-width: 10px;
  margin-top: 20px;
  padding-left: 0;
}

.required.key {
  max-width: 100% !important;
  margin-top: 0;
  padding-top: 0;
  margin-left: 20px;
}

.multiBarcode > div {
  display: inline-block;
}

.multiBarcode {
  border: 1px solid #a2a2a2;
  margin: 0 10px;
  padding-top: 10px;
}
</style>
