// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFormatters() {
  // Format a number as currency
  const formatCurrency = (value: any) => {
    return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  // Format a date string as mm/dd/yyyy
  const formatDate = (date: string) => {
    const parsedDate = new Date(date);
    if (parsedDate.getFullYear() === 1900) {
      return '';
    }
    return parsedDate.toLocaleDateString();
  };

  return {
    formatCurrency,
    formatDate,
  };
}
