<template>
  <core-signs-labels-view-template title="Store Set Compare">
    <core-view-section class="mb-4" title="Compare">
      <v-row>
        <v-col class="pb-0">
          <v-checkbox
            class="mt-0 pt-0"
            v-model="currentStoresSetsOnly"
            :disabled="allStoreSetsGetInProgress || storeStoreSetsGetInProgress"
            label="Active POGs Only"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="source-target">
        <v-col cols="6" class="pt-0">
          <v-card>
            <v-card-title class="black--text">Source</v-card-title>
            <v-card-text>
              <v-data-table
                v-model="selectedSource"
                :headers="storeSetHeaders"
                :items="storeSetSource"
                :loading="allStoreSetsGetInProgress || storeStoreSetsGetInProgress"
                :search="sourceStoreSetSearch"
                :fixed-header="true"
                :disable-pagination="true"
                @click:row="sourceRowClicked"
                item-key="setId"
                show-select
                single-select
                class="elevation-1 scrollable"
                style="max-height=35vh"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="sourceStoreSetSearch"
                    label="Search (POG ID or description)"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-card>
            <v-card-title class="black--text">Target</v-card-title>
            <v-card-text>
              <v-data-table
                v-model="selectedTarget"
                :headers="storeSetHeaders"
                :items="allStoreSets"
                :loading="allStoreSetsGetInProgress"
                :search="targetStoreSetSearch"
                :fixed-header="true"
                :disable-pagination="true"
                @click:row="targetRowClicked"
                item-key="setId"
                show-select
                single-select
                class="elevation-1 scrollable"
                style="max-height=35vh"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="targetStoreSetSearch"
                    label="Search (POG ID or description)"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="comparedStoreSetHeaders"
            :items="comparedStoreSet"
            :loading="compareStoreSetsInProgress"
            :search="comparedSearch"
            :fixed-header="true"
            :disable-pagination="true"
            item-key="key"
            class="elevation-1 scrollable"
            style="overflow: hidden"
            id="comparedStoreSetData"
          >
            <template v-slot:top>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="comparedSearch"
                    label="Search (UPC, item #, or description)"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.isRemoved="{ value }">
              {{ value ? 'Removed' : 'Added' }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="float-right"
            @click="report"
            :disabled="!comparedStoreSet || comparedStoreSet.length === 0"
            >Store Set Compare Report</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="reportDialog">
        <data-report-dialog
          v-model="reportDialog"
          :reportData="reportData"
          templateName="/pog-compare/main"
          :loading.sync="loadingReportData"
          @snackbar-error="$emit('snackbar-error', $event)"
        ></data-report-dialog>
      </v-row>
    </core-view-section>
  </core-signs-labels-view-template>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as s from '@/store/modules/storesetcompare/state';
import { default as appState } from '@/store/modules/app/state';
import * as m from '@/store/modules/storesetcompare/mutations';
import * as a from '@/store/modules/storesetcompare/actions';
import PogonaDataHelper from '@/utils/PogonaDataHelper';

const storesetcompareModule = namespace('storesetcompare');
const appModule = namespace('app');

@Component({})
export default class StoreSetCompare extends Vue {
  private storeSetHeaders = [
    { text: 'POG ID', value: 'setId', filterable: true },
    { text: 'Name', value: 'description', filterable: true },
    { text: 'Size', value: 'setSize' },
  ];

  private comparedStoreSetHeaders = [
    { text: 'UPC', value: 'upc', filterable: true },
    { text: 'Item ID', value: 'itemId', filterable: true },
    { text: 'Desc 1', value: 'fulldescription1', filterable: true },
    { text: 'Desc 2', value: 'fulldescription2', filterable: true },
    { text: 'On Hand', value: 'onHand', filterable: false },
    { text: 'Movement', value: 'movementStore', filterable: false },
    { text: 'Retail', value: 'retailStore', filterable: false },
    { text: 'Movement', value: 'movementAvg', filterable: false },
    { text: 'Retail', value: 'retailAvg', filterable: false },
    { text: 'Size', value: 'size', filterable: false },
    { text: 'UM', value: 'salesUom', filterable: false },
    { text: 'Added/Removed', value: 'isRemoved', filterable: false },
  ];

  private sourceStoreSetSearch: string | null = null;
  private targetStoreSetSearch: string | null = null;
  private comparedSearch: string | null = null;

  private selectedSource: any = [];
  private selectedTarget: any = [];
  private currentStoresSetsOnly = false;
  private reportDialog = false;
  private loadingReportData = false;
  private reportData: any = {};
  private dataHelper: PogonaDataHelper = new PogonaDataHelper();
  private appendedHeader = false;

  @storesetcompareModule.Mutation(m.setStoreSetCompareParams) setStoreSetCompareParams!: (
    params: s.StoreSetCompareParams,
  ) => void;
  @storesetcompareModule.Mutation(m.setComparedStoreSet) setComparedStoreSet!: (
    payload: s.StoreSetDataCompared[],
  ) => void;

  @storesetcompareModule.Mutation(m.setSourceStoreSetSearch) setSourceStoreSetSearch!: (
    payload: string,
  ) => void;
  @storesetcompareModule.Mutation(m.setTargetStoreSetSearch) setTargetStoreSetSearch!: (
    payload: string,
  ) => void;
  @storesetcompareModule.Mutation(m.setComparedSearch) setComparedSearch!: (
    payload: string,
  ) => void;
  @storesetcompareModule.Mutation(m.setSelectedSource) setSelectedSource!: (
    payload: any | null,
  ) => void;
  @storesetcompareModule.Mutation(m.setSelectedTarget) setSelectedTarget!: (
    payload: any | null,
  ) => void;

  @storesetcompareModule.Action(a.getAllStoreSets)
  getAllStoreSets!: () => Promise<any>;
  @storesetcompareModule.Action(a.getStoreStoreSets)
  getStoreStoreSets!: (storeNumber: string) => Promise<any>;
  @storesetcompareModule.Action(a.getComparedStoreSet)
  getComparedStoreSet!: (payload: s.StoreSetCompareParams) => Promise<any>;

  @storesetcompareModule.State(s.allStoreSets) allStoreSets!: Array<s.StoreSet>;

  @storesetcompareModule.State(s.storeStoreSets) storeStoreSets!: Array<s.StoreSet>;
  @storesetcompareModule.State(s.comparedStoreSet) comparedStoreSet!: Array<s.StoreSetDataCompared>;
  @storesetcompareModule.State(s.storeSetCompareParams)
  storeSetCompareParams!: s.StoreSetCompareParams;

  @storesetcompareModule.State(s.allStoreSetsGetInProgress) allStoreSetsGetInProgress!: boolean;
  @storesetcompareModule.State(s.storeStoreSetsGetInProgress) storeStoreSetsGetInProgress!: boolean;
  @storesetcompareModule.State(s.compareStoreSetsInProgress) compareStoreSetsInProgress!: boolean;

  @storesetcompareModule.State(s.sourceStoreSetSearch) sourceStoreSetSearchState!: string;
  @storesetcompareModule.State(s.targetStoreSetSearch) targetStoreSetSearchState!: string;
  @storesetcompareModule.State(s.comparedSearch) comparedSearchState!: string;

  @storesetcompareModule.State(s.selectedSource) selectedSourceState!: any | null;
  @storesetcompareModule.State(s.selectedTarget) selectedTargetState!: any | null;

  @appModule.State('storeNumber') storeNumber!: string;

  async mounted() {
    this.appendExtraHeader();

    s.default.init(this.$authApi.http, '');

    this.sourceStoreSetSearch = this.sourceStoreSetSearchState;
    this.targetStoreSetSearch = this.targetStoreSetSearchState;
    this.comparedSearch = this.comparedSearchState;

    this.selectedSource = this.selectedSourceState;
    this.selectedTarget = this.selectedTargetState;

    const gets = [this.getAllStoreSets()];
    if (this.storeNumber && this.storeNumber !== '0000') {
      gets.push(this.getStoreStoreSets(this.storeNumber));
    }
    await Promise.all(gets);
  }

  private appendExtraHeader() {
    // look, i know this isn't the "right" way of doing things.
    // vuetify didn't let me put another tr above the header, and this was the least brittle solution I could come up with.
    if (this.appendedHeader === false) {
      const tableHead = document.querySelectorAll('#comparedStoreSetData table thead');
      const aboveHeader = document.querySelectorAll(
        '#comparedStoreSetData table thead .above-header',
      );

      if (tableHead && tableHead.length === 1 && (!aboveHeader || aboveHeader.length === 0)) {
        const child = new DOMParser().parseFromString(
          `<table><thead><tr class='above-header'><th colspan='4'></th><th colspan='3' class='header no-right-border'>Active Store Only</th><th colspan='2' class='header'>Average All Stores</th><th colspan='5'></th></tr></thead></table>`,
          'text/html',
        ).body.firstChild?.firstChild?.firstChild as Node;

        tableHead[0].prepend(child);
        this.appendedHeader = true;
      }
    }
  }

  @Watch('storeNumber') async storeChanged(val: string) {
    await this.getStoreStoreSets(val);
  }

  @Watch('selectedSource') async selectedSourceChanged(val: any) {
    this.setSelectedSource(val);
    await this.compareStoreSets();
  }

  @Watch('selectedTarget') async selectedTargetChanged(val: any) {
    this.setSelectedTarget(val);
    await this.compareStoreSets();
  }

  @Watch('sourceStoreSetSearch') async sourceStoreSetSearchChanged(val: any) {
    this.setSourceStoreSetSearch(val);
  }

  @Watch('targetStoreSetSearch') async targetStoreSetSearchChanged(val: any) {
    this.setTargetStoreSetSearch(val);
  }

  @Watch('comparedSearch') async comparedSearchChanged(val: any) {
    this.setComparedSearch(val);
  }

  private async compareStoreSets() {
    if (
      this.selectedSource &&
      this.selectedSource.length === 1 &&
      this.selectedTarget &&
      this.selectedTarget.length === 1
    ) {
      // clear results
      this.setComparedStoreSet([]);

      const params = new s.StoreSetCompareParams(
        this.storeNumber,
        this.selectedSource[0].setId,
        this.selectedTarget[0].setId,
      );
      // set params
      this.setStoreSetCompareParams(params);
      // compare
      await this.getComparedStoreSet(params);
    } else {
      // clear results
      this.setComparedStoreSet([]);
    }
  }

  private async report() {
    this.reportDialog = true;
    this.loadingReportData = true;
    this.reportData = {};

    try {
      this.reportData = await this.dataHelper.getAllReportData({
        $authApi: this.$authApi,
        url: `label/report/pog-compare/${this.storeNumber}/${this.storeSetCompareParams.SourceSetId}/${this.storeSetCompareParams.TargetSetId}`,
        pageSize: 500,
      });
    } catch (err) {
      this.$emit('snackbar-error', {
        text: 'Error loading report data.',
        err,
        id: 'a3110995-84ce-4581-ac4c-f79ae5511361',
      });
    }
  }

  private get storeSetSource() {
    if (this.currentStoresSetsOnly === true) {
      return this.storeStoreSets;
    }
    return this.allStoreSets;
  }

  private get comparedStoreSetLength() {
    if (this.comparedStoreSet) {
      return this.comparedStoreSet.length;
    }
    return 0;
  }

  public sourceRowClicked(e) {
    if (!this.selectedSource || (e && e.setId !== this.selectedSource[0].setId)) {
      this.selectedSource = [e];
    } else {
      this.selectedSource = [];
    }
  }

  public targetRowClicked(e) {
    if (
      !this.selectedTarget ||
      this.selectedTarget.length === 0 ||
      (e && e.setId !== this.selectedTarget[0].setId)
    ) {
      this.selectedTarget = [e];
    } else {
      this.selectedTarget = [];
    }
  }
}
</script>

<style lang="scss">
$grey: rgba(0, 0, 0, 0.3);

.source-target .v-data-table__wrapper {
  max-height: 26vh !important;
}
#comparedStoreSetData table thead .above-header th.header {
  border-top: thin solid $grey;
  border-left: thin solid $grey;
  border-right: thin solid $grey;
  border-radius: 2px;
  text-align: center;
}
#comparedStoreSetData table thead .no-right-border {
  border-right: none !important;
}
#comparedStoreSetData table thead tr th[role='columnheader'] {
  border-top: thin solid $grey !important;
  border-bottom: thin solid $grey !important;
}
</style>
