<template>
  <core-signs-labels-view-template title="Labels">
    <core-view-section title="Items">
      <activated-labels
        v-model="labelItems"
        :showClearButton="true"
        :search.sync="search"
        :queuedItems.sync="queuedItems"
        @snackbar-error="$emit('snackbar-error', $event)"
        :showNonActivatedToggle="true"
        :nonActivatedToggle.sync="nonActivatedToggle"
        :selectedBarcodeSetups.sync="selectedBarcodeSetups"
      ></activated-labels>
      <v-row>
        <v-col>
          <retail-start-date-dialog
            v-model="retailStartDate"
            :queueLoading="labelItemsToBatchLoading"
            :queueDisabled="queueBatchDisabled"
            :queueCallback="labelItemsToBatch"
            :createdBatchInfo="createdBatchInfo"
            :startDateDialog.sync="startDateDialog"
            :showCasePricing="true"
            :useCasePricing.sync="useCasePricing"
          ></retail-start-date-dialog>
        </v-col>
      </v-row>
    </core-view-section>
    <v-divider id="batchInfoItemsDivider" class="ma-8"></v-divider>

    <core-view-section
      v-if="createdBatchInfo"
      v-show="createdBatchInfo.printImmediately !== true"
      title="Queue Items"
    >
      <batch-check-items
        v-model="batchInfoItems"
        :batchInfo.sync="createdBatchInfo"
        @dataBound="batchInfoItemsDataBound"
        @error="$emit('snackbar-error', $event)"
        :footerBatchAndId="footerBatchAndId"
        :includeSetDelimters="false"
        :autoCompleteBatch="true"
        :showCompleteToggle="false"
        originatingPage="Labels"
      ></batch-check-items>
    </core-view-section>

    <data-tour tourName="labelTour" :steps="tourSteps" maxWidth="600px" :show="true"></data-tour>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import BatchCheckItems from './BatchCheckItems.vue';
import RetailStartDateDialog from './RetailStartDateDialog.vue';
import ActivatedLabels from '../ActivatedLabelsComponent.vue';
import { LabelBatchCreator, LabelBatchItem } from '@/utils/LabelBatchCreator';

export default {
  components: {
    BatchCheckItems,
    RetailStartDateDialog,
    ActivatedLabels,
  },
  data() {
    return {
      labelItems: this.$store.state.labelprint.labelItems || {},
      retailStartDate: undefined, // undefined so the default in the component will be used
      startDateDialog: false,
      labelItemsToBatchLoading: false,
      labelItemsBatchWs: null,
      useCasePricing: false,
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Labels Video Tour',
          },
          video: 'labels',
          placement: 'bottom',
        },
      ],
    };
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    batchInfoItems: {
      get() {
        return this.$store.state.labelprint.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit('labelprint/batchInfoItems', val);
      },
    },
    createdBatchInfo: {
      get() {
        return this.$store.state.labelprint.createdBatchInfo || null;
      },
      set(val) {
        this.$store.commit('labelprint/createdBatchInfo', val);
      },
    },
    nonActivatedToggle: {
      get() {
        return this.$store.state.labelprint.nonActivatedToggle || false;
      },
      set(val) {
        this.$store.commit('labelprint/nonActivatedToggle', val);
      },
    },
    search: {
      get() {
        return this.$store.state.labelprint.search || null;
      },
      set(val) {
        this.$store.commit('labelprint/search', val);
      },
    },
    queuedItems: {
      get() {
        return this.$store.state.labelprint.queuedItems || { activated: [], nonActivated: [] };
      },
      set(val) {
        this.$store.commit('labelprint/queuedItems', val);
      },
    },
    selectedBarcodeSetups: {
      get() {
        return this.$store.state.labelprint.selectedBarcodeSetups || ['PrimaryUPC'];
      },
      set(val) {
        this.$store.commit('labelprint/selectedBarcodeSetups', val);
      },
    },
    queueBatchDisabled() {
      return (
        this.labelItemsToBatchLoading ||
        !this.queuedItems ||
        !this.queuedItems.activated ||
        !this.queuedItems.nonActivated ||
        (this.queuedItems.activated.length === 0 && this.queuedItems.nonActivated.length === 0)
      );
    },
    footerBatchAndId() {
      return `LBL ${
        this.createdBatchInfo && this.createdBatchInfo.batchInfoId
          ? this.createdBatchInfo.batchInfoId
          : ''
      }`;
    },
  },
  methods: {
    async labelItemsToBatch(source) {
      const errorMessage = err => {
        this.labelItemsToBatchLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error creating batch from labels.',
          err,
          id: 'c00a2e17-2cb2-4aa2-9202-2e5b0951b8b2',
        });
      };

      try {
        this.batchInfoItems = {};
        this.createdBatchInfo = null;
        this.startDateDialog = false;
        this.labelItemsToBatchLoading = true;

        let batchCreated = false;

        const onClose = () => {
          this.useCasePricing = false;
          // If the web socket connection closed before
          // the batch was created throw an error message.
          if (batchCreated === false) {
            errorMessage(new Error('WS connection closed before batch was created.'));
          }
        };

        // Register on message handler before posting the message
        const onmessage = event => {
          const recMessage = JSON.parse(event.data);

          batchCreated = true;

          this.labelItemsBatchWs.close();

          if (recMessage.success === false) {
            errorMessage(new Error('Received a non-successful WS response.'));
            return;
          }

          this.createdBatchInfo = recMessage.batchInfo;
          this.createdBatchInfo.printImmediately = (source && source.printImmediately) || false;
        };

        const selectedItems = [];

        if (this.queuedItems) {
          if (this.queuedItems.activated && this.queuedItems.activated.length > 0) {
            for (const activated of this.queuedItems.activated) {
              selectedItems.push(new LabelBatchItem(activated.itemId, activated.upc));
            }
          }

          if (this.queuedItems.nonActivated && this.queuedItems.nonActivated.length > 0) {
            for (const nonActivated of this.queuedItems.nonActivated) {
              selectedItems.push(new LabelBatchItem(nonActivated.itemId, nonActivated.upc));
            }
          }

          const lbc = new LabelBatchCreator(
            this.$authApi,
            this.$apiBasePath,
            this.storeNumber,
            this.retailStartDate,
            this.useCasePricing,
            selectedItems,
          );
          this.labelItemsBatchWs = await lbc.open(onClose, onmessage);
        }
      } catch (e) {
        this.$error(e);
        errorMessage(e);
      }
    },
    batchInfoItemsDataBound() {
      // Jump to the batch data.
      this.labelItemsToBatchLoading = false;
      this.$vuetify.goTo('#batchInfoItemsDivider');
    },
  },
  watch: {
    labelItems: {
      handler: function (val) {
        this.$store.commit('labelprint/labelItems', val);
      },
      deep: true,
    },
    storeNumber: {
      handler: function () {
        this.batchInfoItems = {};
        this.createdBatchInfo = null;
      },
    },
  },
};
</script>
