<template>
  <v-row
    v-if="
      ((imagesToShow && imagesToShow.length > 0) || showAll) &&
      sheetSpecificationPaperLoadImages.length > 0
    "
  >
    <v-dialog v-model="showDialog" width="unset" max-width="600px">
      <template v-slot:activator="{ on }">
        <slot name="activate" v-bind:on="on">
          <a v-on="on" :class="['ml-3', 'text-h6', 'font-weight-bold']"
            >Not printing correctly? Click here.</a
          >
        </slot>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">How to Load Paper Stock</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <label>Click image to expand</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="img in imagesToShow"
              :key="img.specId"
              class="d-flex child-flex"
              :cols="imageCols"
            >
              <v-row>
                <v-col>
                  <v-row class="paper-load-image">
                    <v-img
                      :src="`${$storageHost}${img.blobUrl}`"
                      :lazy-src="`${$storageHost}${
                        img.blobUrlsmallImage && img.blobUrlsmallImage.length > 0
                          ? img.blobUrlsmallImage
                          : img.blobUrl
                      }`"
                      @click="
                        zoomedImg = img;
                        showZoomDialog = true;
                      "
                    ></v-img>
                  </v-row>
                  <v-row justify="center">
                    <label class="">{{ img.description }}</label>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showZoomDialog" max-width="900px">
      <v-card>
        <v-card-title>
          <v-row justify="start">
            <v-col>
              <span class="headline inline-block float-left">Paper Loading Detail</span>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col>
              <v-btn class="float-right" @click="showZoomDialog = false">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-img :src="`${$storageHost}${zoomedImg.blobUrl}`"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue';

const PaperStockLoadingImages = Vue.component('paper-stock-loading-images', {
  props: {
    showAll: {
      default: false,
      type: Boolean,
      required: false,
    },
    specIds: {
      default: null,
      type: Array,
      required: false,
    },
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sheetSpecificationPaperLoadImages: [],
      showDialog: this.value,
      showZoomDialog: false,
      zoomedImg: {},
      imagesInited: false,
    };
  },
  computed: {
    imagesToShow() {
      if (this.showAll === true) {
        return this.sheetSpecificationPaperLoadImages;
      }

      const foundSpecs = [];
      const filteredImages = [];
      this.specIds.forEach(specId => {
        const foundImages = this.sheetSpecificationPaperLoadImages.filter(x => x.specId === specId);
        if (!foundSpecs[specId] && foundImages && foundImages.length === 1) {
          filteredImages.push(foundImages[0]);
          foundSpecs[specId] = true;
        }
      });
      return filteredImages;
    },
    imageCols() {
      switch (this.imagesToShow.length) {
        case 1:
          return 12;
        case 2:
          return 6;
        default:
          return 4;
      }
    },
  },
  async created() {
    this.sheetSpecificationPaperLoadImages = (
      await this.$authApi.http.get('label/sheetspecificationpaperloadimage')
    ).data;
  },
  watch: {
    value: {
      handler: function (val) {
        this.showDialog = val;
      },
    },
    showDialog: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
  },
});

export default PaperStockLoadingImages;
export { PaperStockLoadingImages };
</script>

<style lang="scss" scoped>
.paper-load-image {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>
