<template>
  <v-expansion-panels v-model="expanded" :disabled="!expandable" :flat="true">
    <v-expansion-panel :style="{ 'background-color': backgroundColor }">
      <v-card elevation="5" :width="width" :disabled="cardDisabled">
        <v-expansion-panel-header :style="{ 'background-color': backgroundColor }">
          <v-row>
            <v-col :cols="titleCols">
              <v-card-title
                class="section-card-title"
                :style="{ color, 'font-weight': fontWeight }"
                elevation="10"
                v-if="title"
                >{{ title }}</v-card-title
              >
            </v-col>
            <slot name="title"> </slot>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-progress-linear v-if="loading && value" indeterminate></v-progress-linear>
          <v-row class="px-5 pt-1 pb-3" align="start">
            <v-col cols="12">
              <slot></slot>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-card>
    </v-expansion-panel>
    <v-progress-linear v-if="loading && !value" indeterminate></v-progress-linear>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    titleCols: {
      type: String,
      required: false,
      default: '12',
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#d8d8d8',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
    fontWeight: {
      type: String,
      required: false,
      default: '500',
    },
    cardDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.value ? 0 : null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.expanded = val ? 0 : null;
      },
    },
    expanded: {
      handler: function (val) {
        const expanded = val === 0;
        this.$emit('input', expanded);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.section-card-title {
  max-height: 2em;
  font-size: 1.3em;
  padding-top: 0.4em;
}

.v-expansion-panel-header {
  padding: 0;
}
</style>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-expansion-panel-header__icon i {
  padding-right: 10px !important;
}
.v-expansion-panels {
  margin-bottom: 1em;
}
</style>
