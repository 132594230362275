<template>
  <core-view-template title="Pre-Count Reports">
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6">
        <v-autocomplete
          prepend-icon="playlist_add_check"
          v-model="storeNumber"
          :items="stores"
          @change="setStoreState"
          autocomplete="off"
          clearable
          :loading="storeGetInProgress"
          :disabled="storeGetInProgress"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6">
        <v-text-field
          label="Enter Batch ID"
          v-model="batchId"
          clearable
          @click:clear="batchId = null"
          v-debounce:300="setBatchIdState"
          autocomplete="off"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="reports"
        :items-per-page="-1"
        :fixed-header="true"
        :loading="searchInProgress"
        :footer-props="{
          disableItemsPerPage: true,
          itemsPerPageText: '',
        }"
        :options.sync="options"
        :disable-pagination="true"
        hide-default-footer
        item-key="id"
        style="width: 100%"
      >
        <template v-slot:item.createdOn="{ value }">
          {{ value | formatDateTime }}
        </template>
        <template v-slot:item.download="{ item }">
          <v-btn
            @click="downloadReport(item.name)"
            :loading="downloadInProgress"
            :disabled="downloadInProgress"
            >Download</v-btn
          >
        </template>
      </v-data-table>
    </v-row>
  </core-view-template>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as ps from '@/store/modules/precountreports/state';
import * as pm from '@/store/modules/precountreports/mutations';
import * as pa from '@/store/modules/precountreports/actions';

const preCountReportsModule = namespace('precountreports');

@Component({})
export default class PreCountReports extends Vue {
  private storeNumber: string | null = null;
  private batchId: string | null = null;

  private options = {};
  private headers: any = [
    {
      text: 'File Name',
      value: 'name',
    },
    {
      text: 'Created',
      value: 'createdOn',
    },
    {
      text: 'Download',
      value: 'download',
    },
  ];

  @preCountReportsModule.Mutation(pm.setSelectedStore) setSelectedStore!: (
    storeNumber: string | null,
  ) => void;
  @preCountReportsModule.Mutation(pm.setBatchId) setBatchId!: (batchId: string | null) => void;

  @preCountReportsModule.Action(pa.searchPreCountReports)
  searchPreCountReports!: () => Promise<any>;
  @preCountReportsModule.Action(pa.getStores) getStores!: () => Promise<any>;
  @preCountReportsModule.Action(pa.downloadReport) downloadReport!: (
    fileName: string,
  ) => Promise<any>;

  @preCountReportsModule.State(ps.stores) stores!: Array<string>;
  @preCountReportsModule.State(ps.reports) reports!: Array<ps.FileShareFile>;
  @preCountReportsModule.State(ps.selectedStore) selectedStore!: string;
  @preCountReportsModule.State(ps.selectedBatchId) selectedBatchId!: string;
  @preCountReportsModule.State(ps.storeGetInProgress) storeGetInProgress!: boolean;
  @preCountReportsModule.State(ps.searchInProgress) searchInProgress!: boolean;
  @preCountReportsModule.State(ps.downloadInProgress) downloadInProgress!: boolean;

  async created() {
    ps.default.init(this.$authApi.http, '');

    this.storeNumber = this.selectedStore;
    this.batchId = this.selectedBatchId;

    this.getStores();
  }

  private setBatchIdState() {
    this.setBatchId(this.batchId);
    this.searchForReports();
  }

  private setStoreState() {
    this.setSelectedStore(this.storeNumber);
    this.searchForReports();
  }

  private async searchForReports() {
    if (this.selectedStore && this.selectedBatchId) {
      await this.searchPreCountReports();
    }
  }
}
</script>
