<template>
  <v-row>
    <v-col>
      <v-row v-for="(textInput, index) in textInputs" :key="index">
        <v-col cols="10">
          <v-text-field v-model="textInputs[index]" :label="props.label"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-icon @click="addOrRemove(index)">{{ index === 0 ? 'add_box' : 'remove_box' }}</v-icon>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, defineComponent, watch, onMounted } from 'vue';

defineComponent({
  name: 'MultiTextInput',
});

const props = defineProps({
  value: {
    type: Array<string>,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['update:value']);
const textInputs = ref<string[]>(['']);

onMounted(() => {
  textInputs.value = (props.value?.length ?? 0) > 0 ? props.value : [''];
});

watch(
  () => textInputs.value,
  () => {
    emit('update:value', textInputs.value);
  },
  { deep: true },
);

watch(
  () => props.value,
  () => {
    textInputs.value = (props.value?.length ?? 0) > 0 ? props.value : [''];
  },
  { deep: true },
);

function addOrRemove(index) {
  if (index === 0) {
    textInputs.value.push('');
  } else {
    textInputs.value.splice(index, 1);
  }

  if (textInputs.value.length === 0) {
    textInputs.value = [''];
  }
}
</script>
