const pm = {
  onopen(ws, declaredType, id, adalToken) {
    ws.send(`jwt:${adalToken}`);
    ws.send(`${declaredType}:${id}`);
  },
};

export default class PogonaWebSocket extends WebSocket {
  constructor(
    apiBasePath,
    declaredType,
    id,
    adalToken,
    onclose,
    onmessage,
    firstRecTimeout,
    onopen,
  ) {
    // Init websocket
    super(`ws${document.location.protocol === 'http:' ? '' : 's'}://${apiBasePath}servicebus`);
    const _firstRecTimeout = firstRecTimeout || 60000;
    let firstRecTimeoutId = null;
    let recFirstMessage = false;

    // Init onopen method
    super.onopen = () => {
      pm.onopen(this, declaredType, id, adalToken);

      if (onopen && typeof onopen === 'function') {
        onopen();
      }

      // set a timeout if we don't receieve a message in a certain amount of time.
      // if the timeout is hit, close the connection
      firstRecTimeoutId = setTimeout(() => {
        if (recFirstMessage === false) {
          this.close();
        }
      }, _firstRecTimeout);
    };

    // Init onclose method
    super.onclose = () => {
      if (onclose && typeof onclose === 'function') {
        onclose();
      }
    };

    // Init onmessage method
    super.onmessage = event => {
      if (firstRecTimeoutId) {
        recFirstMessage = true;
        clearTimeout(firstRecTimeoutId);
      }

      if (onmessage && typeof onmessage === 'function') {
        onmessage(event);
      }
    };

    // Init properties
    this.declaredType = declaredType;
    this.id = id;
  }
}
