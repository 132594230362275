<template>
  <tr>
    <td :colspan="colspan + 10" class="ma-0 pa-0" style="height: 10px">
      <infinite-loading
        v-if="pogonaTable && pogonaTable.totalItems && pogonaTable.totalItems > 0"
        @infinite="infiniteHandler"
      >
        <template v-slot:spinner>
          <v-progress-linear v-if="showSpinner" indeterminate></v-progress-linear>
          <span v-else></span>
        </template>
      </infinite-loading>
    </td>
  </tr>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    pogonaTable: {
      type: Object,
      required: true,
      default: null,
    },
    additionalFilter: {
      default: null,
    },
    colspan: {
      type: Number,
      required: false,
      default: 1,
    },
    showSpinner: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async infiniteHandler($state) {
      await this.pogonaTable.infiniteHandler($state, this.additionalFilter);
    },
  },
});
</script>

<style lang="scss">
.infinite-status-prompt {
  padding: 0 !important;
}
</style>
