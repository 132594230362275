<template>
  <div>
    <v-col cols="12">
      <v-form @submit.stop.prevent="searchForText">
        <v-row>
          <v-col cols="9">
            <v-text-field
              label="Search by store number, store name, or address"
              v-model="searchText"
              clearable
              @click:clear="clearClicked"
              v-debounce:300="searchForText"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-radio-group @change="searchForOpenAll" v-model="onlyOpenStores">
              <v-radio label="Open Stores" :value="true"></v-radio>
              <v-radio label="All Stores" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col>
          <v-row>
            <v-data-table
              :headers="headers"
              :items="storeData"
              :items-per-page="-1"
              :fixed-header="true"
              :loading="storeSearchInProgress"
              :footer-props="{
                disableItemsPerPage: true,
                itemsPerPageText: '',
              }"
              :options.sync="options"
              :disable-pagination="true"
              :server-items-length="storeData.length"
              @update:options="updateSortBy"
              hide-default-footer
              item-key="StoreId"
              style="width: 100%"
            >
              <template v-slot:item.City="{ item }">
                <v-col v-if="item.Address">
                  <v-row>
                    {{ item.Address.City }}
                  </v-row>
                </v-col>
              </template>

              <template v-slot:item.State="{ item }">
                <v-col v-if="item.Address">
                  <v-row>
                    {{ item.Address.StateCode }}
                  </v-row>
                </v-col>
              </template>

              <template v-slot:item.Zip="{ item }">
                <v-col v-if="item.Address">
                  <v-row>
                    {{ item.Address.Zip | zip }}
                  </v-row>
                </v-col>
              </template>

              <template v-slot:item.StoreStatusId="{ item }">
                {{ item.StoreStatus ? item.StoreStatus.Name : '' }}
              </template>
            </v-data-table>
          </v-row>
          <v-row> </v-row>
        </v-col>
      </v-row>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
        <template v-slot:no-more> End of results </template>
        <template v-slot:no-results> End of results </template>
      </infinite-loading>
    </v-col>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { StoreSearchParams, StoreSearchResult } from '@psp/pogona_pcl_client_js';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pm from '@/store/modules/pcl/mutations';
import InfiniteLoading from 'vue-infinite-loading';

const pclModule = namespace('pcl');

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({})
export default class PclStoreSearch extends Vue {
  @pclModule.Action(pa.searchStores)
  searchStores!: () => Promise<any>;

  @pclModule.Mutation(pm.incrementStoreSearchPage) incrementStoreSearchPage!: (
    payload: number,
  ) => void;
  @pclModule.Mutation(pm.setStoreSearchGenericText) setStoreSearchGenericText!: (
    payload: string,
  ) => void;
  @pclModule.Mutation(pm.setStoreSearchOnlyOpenStores) setStoreSearchOnlyOpenStores!: (
    payload: boolean,
  ) => void;
  @pclModule.Mutation(pm.clearStoreData) clearStoreData!: () => void;
  @pclModule.Mutation(pm.clearStoreSearch) clearStoreSearch!: () => void;
  @pclModule.Mutation(pm.setStoreSearchFirstPage) setStoreSearchFirstPage!: () => void;
  @pclModule.Mutation(pm.setStoreSearchOrderBy) setStoreSearchOrderBy!: (payload: any) => void;

  @pclModule.State(ps.storeData) storeData!: StoreSearchResult[];
  @pclModule.State(ps.storeSearch) storeSearch!: StoreSearchParams;
  @pclModule.State(ps.storeSearchInProgress) storeSearchInProgress!: boolean;

  private searchText = '';
  private onlyOpenStores = true;
  public lastCount = -1;
  private infiniteId = +new Date();
  private options: any = { sortBy: ['StoreNumber'], sortDesc: [false] };

  private headers: any = [
    { text: 'Pet Center', value: 'StoreNumber' },
    { text: 'Store Name', value: 'Name', sortable: false },
    { text: 'City', value: 'City', sortable: false },
    { text: 'State', value: 'State', sortable: false },
    { text: 'Zip', value: 'Zip', sortable: false },
    { text: 'Status', value: 'StoreStatusId' },
  ];

  public infiniteHandler($state: any) {
    this.search()
      .then(() => {
        if (
          this.storeData.length % this.storeSearch.Take === 0 &&
          this.storeData.length !== this.lastCount
        ) {
          $state.loaded();
          this.incrementStoreSearchPage(1);
        } else {
          $state.complete();
        }
      })
      .catch(() => {
        $state.complete();
      });
  }

  get searchDisabled(): boolean {
    return this.storeSearchInProgress;
  }

  private searchForText() {
    this.clearStoreSearch();
    this.setStoreSearchGenericText(this.searchText);
    this.newSearch();
  }

  private searchForOpenAll() {
    this.clearStoreSearch();
    this.setStoreSearchGenericText(this.searchText);
    this.setStoreSearchOnlyOpenStores(this.onlyOpenStores);
    this.newSearch();
  }

  public clearClicked() {
    this.clearStoreSearch();
    this.setStoreSearchGenericText('');
    this.newSearch();
  }

  public reset() {
    this.searchText = '';
    this.setStoreSearchOrderBy(this.options);
    this.newSearch();
  }

  public newSearch() {
    this.setStoreSearchFirstPage();
    this.clearStoreData();
    this.infiniteId = this.infiniteId + 1;
  }

  private search() {
    this.lastCount = this.storeData.length;
    return this.searchStores();
  }

  public updateSortBy(event) {
    this.setStoreSearchOrderBy(event);
    this.newSearch();
  }
}
</script>
