<template>
  <div>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-text-field
            label="Search by Item ID, SKU, or Brand"
            v-model="searchText"
            clearable
            @click:clear="clearClicked"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn @click="searchForText" :disabled="searchDisabled"><v-icon>search</v-icon></v-btn>
        </v-col>
        <v-col>
          <partner-funding-search-refinement
            v-on:search-clicked="refineSearchClicked"
            @snackbar-error="$emit('snackbar-error', $event)"
          />
        </v-col>
        <v-col>
          <v-btn type="submit" @click="downloadClicked" :loading="downloading">Download</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="partnerFundingData"
          :items-per-page="-1"
          :fixed-header="true"
          :loading="searchInProgress"
          group-by="categoryHierarchy"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: '',
          }"
          hide-default-footer
          disable-sort
          :custom-sort="customGridSort"
        >
          <template v-slot:group.header="{ group, headers }">
            <td :colspan="headers.length">
              <span class="font-weight-bold">{{ group }}</span>
            </td>
          </template>
          <template v-slot:item.FoundOnImport="{ item }">
            {{ item.FoundOnImport ? 'Yes' : 'No' }}
          </template>
        </v-data-table>
      </v-row>
      <v-row> </v-row>
    </v-col>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <template v-slot:no-more> End of results </template>
      <template v-slot:no-results> End of results </template>
    </infinite-loading>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PartnerFundingSearchParams, PartnerFundingSearchResult } from '@psp/pogona_ecom_client_js';
import PartnerFundingSearchRefinement from './PartnerFundingSearchRefinement.vue';
import * as pfa from '@/store/modules/partnerfunding/actions';
import * as pfs from '@/store/modules/partnerfunding/state';
import * as pfm from '@/store/modules/partnerfunding/mutations';
import InfiniteLoading from 'vue-infinite-loading';

const pfModule = namespace('partnerfunding');

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  components: {
    PartnerFundingSearchRefinement,
  },
})
export default class PartnerFundingSearch extends Vue {
  @pfModule.Action(pfa.searchPartnerFunding)
  searchPartnerFunding!: () => Promise<any>;
  @pfModule.Action(pfa.downloadPartnerFundingSearchResults)
  downloadPartnerFundingSearchResults!: () => Promise<any> | undefined;

  @pfModule.Mutation(pfm.setFirstPage) setFirstPage!: () => void;
  @pfModule.Mutation(pfm.incrementSearchPage) incrementSearchPage!: (payload: number) => void;
  @pfModule.Mutation(pfm.setSearchText) setSearchText!: (payload: string) => void;
  @pfModule.Mutation(pfm.clearPartnerFundingData) clearPartnerFundingData!: () => void;
  @pfModule.Mutation(pfm.clearPartnerFundingSearch) clearPartnerFundingSearch!: () => void;

  @pfModule.State(pfs.partnerFundingData) partnerFundingData!: PartnerFundingSearchResult[];
  @pfModule.State(pfs.partnerFundingSearch) partnerFundingSearch!: PartnerFundingSearchParams;

  private searchText = '';
  public lastCount = 0;
  private infiniteId = +new Date();
  private searchInProgress = false;

  private headers: any = [
    { text: 'Item ID', value: 'ItemID' },
    { text: 'SKU', value: 'Sku' },
    { text: 'Brand', value: 'BrandName' },
    { text: 'Description', value: 'SkuName' },
    { text: 'Initial % Funded', value: 'InitialPercentFunded' },
    { text: 'On Going % Funded', value: 'OnGoingPercentFunded' },
    { text: 'Found on Import', value: 'FoundOnImport' },
  ];

  private downloading = false;

  public infiniteHandler($state: any) {
    this.search()
      .then(() => {
        if (
          this.partnerFundingData.length % this.partnerFundingSearch.PageSize === 0 &&
          this.partnerFundingData.length !== this.lastCount
        ) {
          $state.loaded();
          this.incrementSearchPage(1);
        } else {
          $state.complete();
        }
      })
      .catch(() => {
        $state.complete();
      });
  }

  private customGridSort(items: any[]): any[] {
    return items.sort((a: PartnerFundingSearchResult, b: PartnerFundingSearchResult) => {
      var e = 1;
      var compare = this.compareString(a.Category1Name, b.Category1Name);
      if (compare !== 0) {
        e = compare;
      } else {
        compare = this.compareString(a.Category2Name, b.Category2Name);
        if (compare !== 0) {
          e = compare;
        } else {
          compare = this.compareString(a.Category3Name, b.Category3Name);
          if (compare !== 0) {
            e = compare;
          } else {
            compare = this.compareString(a.Category4Name, b.Category4Name);
            if (compare !== 0) {
              e = compare;
            } else {
              e = a.ItemID < b.ItemID ? -1 : a.ItemID === b.ItemID ? 0 : 1;
            }
          }
        }
      }

      return e;
    });
  }

  private compareString(a: string | null, b: string | null): number {
    var compare = 1;
    if (a === b) {
      compare = 0;
    } else {
      if (b === null) {
        compare = -1;
      } else if (a !== null && a < b) {
        compare = -1;
      }
    }

    return compare;
  }

  private refineSearchClicked() {
    this.newSearch();
  }

  private downloadClicked() {
    this.downloading = true;
    this.downloadPartnerFundingSearchResults()?.finally(() => (this.downloading = false));
  }

  get searchDisabled(): boolean {
    return this.searchInProgress;
  }

  private searchForText() {
    this.clearPartnerFundingSearch();
    this.setSearchText(this.searchText);
    this.newSearch();
  }

  public clearClicked() {
    this.clearPartnerFundingSearch();
    this.setSearchText('');
    this.newSearch();
  }

  public reset() {
    this.searchText = '';
    this.newSearch();
  }

  public newSearch() {
    this.setFirstPage();
    this.clearPartnerFundingData();
    this.infiniteId = this.infiniteId + 1;
  }

  private search() {
    this.lastCount = this.partnerFundingData.length;
    this.searchInProgress = true;
    return this.searchPartnerFunding().finally(() => (this.searchInProgress = false));
  }
}
</script>
