var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    ((_vm.imagesToShow && _vm.imagesToShow.length > 0) || _vm.showAll) &&
    _vm.sheetSpecificationPaperLoadImages.length > 0
  )?_c('v-row',[_c('v-dialog',{attrs:{"width":"unset","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_vm._t("activate",function(){return [_c('a',_vm._g({class:['ml-3', 'text-h6', 'font-weight-bold']},on),[_vm._v("Not printing correctly? Click here.")])]},{"on":on})]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("How to Load Paper Stock")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('label',[_vm._v("Click image to expand")])])],1),_c('v-row',_vm._l((_vm.imagesToShow),function(img){return _c('v-col',{key:img.specId,staticClass:"d-flex child-flex",attrs:{"cols":_vm.imageCols}},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"paper-load-image"},[_c('v-img',{attrs:{"src":`${_vm.$storageHost}${img.blobUrl}`,"lazy-src":`${_vm.$storageHost}${
                      img.blobUrlsmallImage && img.blobUrlsmallImage.length > 0
                        ? img.blobUrlsmallImage
                        : img.blobUrl
                    }`},on:{"click":function($event){_vm.zoomedImg = img;
                      _vm.showZoomDialog = true;}}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('label',{},[_vm._v(_vm._s(img.description))])])],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.showZoomDialog),callback:function ($$v) {_vm.showZoomDialog=$$v},expression:"showZoomDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',[_c('span',{staticClass:"headline inline-block float-left"},[_vm._v("Paper Loading Detail")])])],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('v-btn',{staticClass:"float-right",on:{"click":function($event){_vm.showZoomDialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-img',{attrs:{"src":`${_vm.$storageHost}${_vm.zoomedImg.blobUrl}`}})],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }