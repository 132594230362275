<template>
  <core-view-template title="Quick Store List">
    <v-row v-if="isPclReaderOrWriter">
      <router-link
        :to="{
          name: 'pcl_listing_all',
        }"
      >
        <v-btn>Go to full Store Listings</v-btn>
      </router-link>
    </v-row>
    <v-row>
      <v-col cols="12">
        <pcl-store-search ref="storeSearch"> </pcl-store-search>
      </v-col>
    </v-row>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PclStoreSearch from '@/components/pcl/PclStoreSearch.vue';
import { namespace } from 'vuex-class';
import * as pm from '@/store/modules/pcl/mutations';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {
    PclStoreSearch,
  },
})
export default class PclLanding extends Vue {
  @pclModule.Mutation(pm.setStoreSearchInProgress) setShowProcessing!: (payload: boolean) => void;
  @pclModule.Mutation(pm.setStoreData) setValidationData!: (payload: any) => void;

  private isPclReaderOrWriter = false;

  private async mounted() {
    this.isPclReaderOrWriter =
      (await this.$authApi.roleHasRights('PCLReader')) ||
      (await this.$authApi.roleHasRights('PCLWriter'));

    if (this.$refs.storeSearch) {
      await (this.$refs.storeSearch as PclStoreSearch).reset();
    }
  }
}
</script>
