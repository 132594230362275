<template>
  <core-view-template title="Username Activity">
    <core-view-section title="Users">
      <v-row>
        <v-col>
          <v-dialog max-width="600px" v-model="activityReportDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on"> Activity Report </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">Report Start End Date</span>
              </v-card-title>
              <v-card-text>
                <v-col>
                  <v-row>
                    <data-date-picker v-model="activityReportStart" label="Report Start" />
                  </v-row>
                  <v-row>
                    <data-date-picker v-model="activityReportEnd" label="Report End" />
                  </v-row>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="loadReport"
                  :disabled="loadingReportData"
                  :loading="loadingReportData"
                  >Report</v-btn
                >
                <v-btn @click="activityReportDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            @click:clear="
              activitySearch = null;
              getUsernameActivity();
            "
            v-debounce:300="getUsernameActivity"
            prepend-icon="search"
            v-model="activitySearch"
            label="Search (username, or store number)"
            :clearable="true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12">
          <v-data-table
            v-if="usernameActivity"
            v-model="usernameActivity.selected"
            :headers="usernameActivity.headers"
            :items="usernameActivity.items"
            :loading="usernameActivity.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="usernameActivity.options"
            @update:sort-by="getUsernameActivity"
            item-key="username"
            class="elevation-1 scrollable"
          >
            >
            <template v-slot:body.append>
              <infinite-loader
                :pogonaTable="usernameActivity"
                :additionalFilter="usernameActivityAdditionalFilter"
                :colspan="usernameActivity.headers.length"
              ></infinite-loader>
            </template>

            <template v-slot:item.lastActivityDateTimeUtc="{ value }">
              {{ value | formatDateTime }}
            </template>

            <template v-slot:item.lastActivityDateTimeUserTime="{ value }">
              {{ value | formatDateTime }}
            </template>

            <template v-slot:item.route="{ value }">
              {{ value | truncate(100, '...') }}
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="usernameActivity"></infinite-paganation>
        </v-col>
      </v-row>
      <v-row>
        <data-report-dialog
          v-model="reportDialog"
          :reportData="reportData"
          templateName="/username-activity/main"
          :loading.sync="loadingReportData"
          :supportsExcel="true"
          @snackbar-error="$emit('snackbar-error', $event)"
        ></data-report-dialog>
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script>
import PogonaDataTable from '@/utils/PogonaDataTable';
import PogonaDataHelper from '@/utils/PogonaDataHelper';
import f from 'odata-filter-builder';
import moment from 'moment';

export default {
  data() {
    return {
      usernameActivity: null,
      reportDialog: false,
      reportData: {},
      loadingReportData: false,
      activitySearch: null,
      activityReportDialog: false,
      activityReportStart: moment().add(-7, 'days').format('YYYY-MM-DD'),
      activityReportEnd: moment().format('YYYY-MM-DD'),
    };
  },
  async created() {
    this.dataHelper = new PogonaDataHelper();
    await this.initUsernameActivity();
  },
  computed: {
    usernameActivityAdditionalFilter() {
      let filter = f('and');

      if (this.activitySearch && this.activitySearch.length > 0) {
        const trimmedSearch = this.activitySearch.trim();
        filter = filter.and(
          f('or').contains("'username'", trimmedSearch).contains("'storeNumer'", trimmedSearch),
        );
      }

      return filter;
    },
  },
  methods: {
    async initUsernameActivity() {
      this.usernameActivity = new PogonaDataTable({
        headers: [
          {
            text: 'Username',
            value: 'username',
          },
          {
            text: 'StoreNumber',
            value: 'storeNumer',
          },
          {
            text: 'Last Activity UTC',
            value: 'lastActivityDateTimeUtc',
          },
          {
            text: 'Last Activity Local',
            value: 'lastActivityDateTimeUserTime',
          },
          {
            text: 'Last Activity',
            value: 'route',
          },
        ],
        baseUrl: 'pogona/usernameactivity',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['lastActivityDateTimeUtc'], sortDesc: [true] },
        isInfinite: true,
        keys: ['username'],
      });

      this.usernameActivity.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting user activity',
          err,
          id: 'f06ecab4-9c5c-4756-be13-83a4986da3a6',
        });
      });

      await this.getUsernameActivity();
    },
    async getUsernameActivity() {
      if (this.usernameActivity) {
        await this.usernameActivity.get(this.usernameActivityAdditionalFilter);
      }
    },
    async loadReport() {
      this.loadingReportData = true;
      this.reportDialog = true;
      this.activityReportDialog = false;

      try {
        this.reportData = await this.dataHelper.getAllReportData({
          $authApi: this.$authApi,
          url: 'pogona/report/usernameactivity',
          orderBy: ['lastActivityDateTimeUtc desc'],
          filter: f('and')
            .ge('lastActivityDateTimeUtc', new Date(this.activityReportStart))
            .lt(
              'lastActivityDateTimeUtc',
              new Date(moment(this.activityReportEnd).add(1, 'days').format('MM/DD/YYYY')),
            ),
        });
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error loading report data.',
          err,
          id: 'c2168817-49e9-4adc-a51b-5ea9ad696a3a',
        });
      }
    },
  },
};
</script>
