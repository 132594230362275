import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  batchInfoItems: set('batchInfoItems'),
  ingredientItems: set('ingredientItems'),
  createdBatchInfo: set('createdBatchInfo'),
  ingredientItemSearch: set('ingredientItemSearch'),
  selectedItems: set('selectedItems'),
  trackedDataSelectedCount: set('trackedDataSelectedCount'),
  selectedStore: set('selectedStore'),
  allItems: set('allItems'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
};
