import {
  FileUploadProcessingStateInfo,
  IncludeItemsType,
  ProcessingStateInfo,
  SubscriptionExceptionSearchParams,
  SubscriptionExceptionSearchResult,
} from '@psp/pogona_ecom_client_js';
import { DwBrand } from '@psp/pogona_ecom_client_js/DwBrand';
import { DwCategory } from '@psp/pogona_ecom_client_js/DwCategory';
import { SubscriptionValidationResult } from '@psp/pogona_ecom_client_js/SubscriptionValidationResult';
import { IgnoredItemsUploadResult } from '@psp/pogona_ecom_client_js/IgnoredItemsUploadResult';
import { EcomState } from './state';
import { PspEcomIgnoreItemOld } from '@psp/pspecomdto';

export const setClient = 'setClient';
export const setExceptionData = 'setExceptionData';
export const clearExceptionData = 'clearExceptionData';
export const setExceptionDataSearch = 'setExceptionDataSearch';
export const clearExceptionSearch = 'clearExceptionSearch';
export const setExceptionSearchText = 'setExceptionSearchText';
export const incrementExceptionSearchPage = 'incrementExceptionSearchPage';
export const setExceptionSearchInProgress = 'setExceptionSearchInProgress';
export const setExceptionFirstPage = 'setExceptionFirstPage';
export const setBrands = 'setBrands';
export const setDepartments = 'setDepartments';
export const setSubDepartments = 'setSubDepartments';
export const setCategories = 'setCategories';
export const setSubCategories = 'setSubCategories';
export const setUploadFile = 'setUploadFile';
export const setValidationData = 'setValidationData';
export const setProcessingStateInfo = 'setProcessingStateInfo';
export const setShowProcessing = 'setShowProcessing';
export const setIgnoredItems = 'setIgnoredItems';
export const setIgnoredItemsCount = 'setIgnoredItemsCount';
export const setGettingIgnoredItems = 'setGettingIgnoredItems';
export const clearIgnoredItems = 'clearIgnoredItems';
export const setIgnoredItemsUploadInProgress = 'setIgnoredItemsUploadInProgress';
export const setIgnoredItemsUploadResult = 'setIgnoredItemsUploadResult';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  [setClient](state: EcomState, payload: any): void {
    state.init(payload.http, payload.baseUrl);
  },
  [setExceptionData](state: EcomState, payload: SubscriptionExceptionSearchResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new SubscriptionExceptionSearchResult(), payload[i]);
    }
    if (state.exceptionSearch.PageNumber === 1) {
      state.exceptionData = payload;
    } else {
      state.exceptionData.push(...payload);
    }
  },
  [clearExceptionData](state: EcomState): void {
    state.exceptionData = [];
  },
  [setExceptionDataSearch](state: EcomState, payload: SubscriptionExceptionSearchParams): void {
    state.exceptionSearch = payload;
  },
  [clearExceptionSearch](state: EcomState): void {
    state.exceptionSearch.BrandIds = [];
    state.exceptionSearch.CategoryLevel1Id = null;
    state.exceptionSearch.CategoryLevel2Id = null;
    state.exceptionSearch.CategoryLevel3Ids = [];
    state.exceptionSearch.CategoryLevel4Ids = [];
    state.exceptionSearch.GenericText = null;
    state.exceptionSearch.ItemId = null;
    state.exceptionSearch.Sku = null;
    state.exceptionSearch.PageNumber = 1;
    state.exceptionSearch.Include = IncludeItemsType.All;
  },
  [setExceptionSearchText](state: EcomState, payload: string): void {
    state.exceptionSearch.GenericText = payload;
  },
  [incrementExceptionSearchPage](state: EcomState, payload: number): void {
    state.exceptionSearch.PageNumber += payload;
  },
  [setExceptionSearchInProgress](state: EcomState, payload: boolean): void {
    state.exceptionSearchInProgress = payload;
  },
  [setExceptionFirstPage](state: EcomState): void {
    state.exceptionSearch.PageNumber = 1;
  },
  [setBrands](state: EcomState, payload: DwBrand[]): void {
    state.brands = payload;
  },
  [setDepartments](state: EcomState, payload: DwCategory[]): void {
    state.departments = payload;
  },
  [setSubDepartments](state: EcomState, payload: DwCategory[]): void {
    state.subDepartments = payload;
  },
  [setCategories](state: EcomState, payload: DwCategory[]): void {
    state.categories = payload;
  },
  [setSubCategories](state: EcomState, payload: DwCategory[]): void {
    state.subCategories = payload;
  },
  [setUploadFile](state: EcomState, payload: File | null): void {
    state.uploadFile = payload;
  },
  [setValidationData](state: EcomState, payload: SubscriptionValidationResult): void {
    state.validationData = payload;
  },
  [setProcessingStateInfo](state: EcomState, payload: FileUploadProcessingStateInfo[]): void {
    state.processingData = payload;
  },
  [setShowProcessing](state: EcomState, payload: boolean): void {
    state.showProcessing = payload;
  },
  [setIgnoredItems](state: EcomState, payload: PspEcomIgnoreItemOld[]): void {
    if (!state.ignoredItems || state.ignoredItems.length === 0) {
      state.ignoredItems = payload;
    } else {
      state.ignoredItems.push(...payload);
    }
  },
  [setIgnoredItemsCount](state: EcomState, payload: number): void {
    state.ignoredItemsCount = payload;
  },
  [setGettingIgnoredItems](state: EcomState, payload: boolean): void {
    state.gettingIgnoredItems = payload;
  },
  [clearIgnoredItems](state: EcomState): void {
    state.ignoredItems = [] as PspEcomIgnoreItemOld[];
  },
  [setIgnoredItemsUploadInProgress](state: EcomState, payload: boolean): void {
    state.ignoredItemsUploadInProgress = payload;
  },
  [setIgnoredItemsUploadResult](state: EcomState, payload: IgnoredItemsUploadResult | null): void {
    state.ignoredItemsUploadResult = payload;
  },
};
