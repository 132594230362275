/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import EventEmitter from 'events';
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';
import vue from 'vue';
import uuidv3 from 'uuid/v3';
import uuidv4 from 'uuid/v4';
import axios from 'axios';
import querystringify from 'querystringify';

const _onOptions = (options, pdt) => {
  if (pdt.firstBind === false) {
    pdt.options = options;
  }
};

const _uuidNamespace = 'fb8bdb7a-b6c1-4e2c-b62b-d3afa86a4330';

// Possible options properties:
/*
    headers,
    baseUrl,
    defaultFilter,
    multiselect,
    httpClient,
    options,
    onPageFilter,
    alwaysGetData,
    calculcalculateKey,
    keys,
    queryStringParams
*/
export default class PogonaDataTable extends EventEmitter {
  constructor(options) {
    super();

    if (!options && typeof options !== 'object') {
      throw new Error('An object in the ctr must be provided');
    }

    if (!options.baseUrl) {
      throw new Error('A baseUrl property must be provided.');
    }

    if (!options.httpClient) {
      throw new Error('A httpClient property must be provided.');
    }

    if (!options.headers) {
      throw new Error('A headers property must be provided.');
    }

    this.loading = true;
    this.firstBind = true;
    this._selected = [];
    this.httpOperations = [];
    this.baseUrl =
      options.baseUrl + (options.baseUrl[options.baseUrl.length - 1] === '/' ? '' : '/');
    this.httpClient = options.httpClient;
    this._options = options.options || { itemsPerPage: 10, page: 1 };
    if (!this._options.page) {
      this._options.page = 1;
    }
    this.defaultSortBy = options.options.sortBy;
    this.defaultSortByDesc =
      options.options.sortDesc && options.options.sortDesc.length > 0
        ? options.options.sortDesc
        : null;
    this.defaultFilter = options.defaultFilter;
    this.onPageFilter = options.onPageFilter;
    this.alwaysGetData = options.alwaysGetData || false;
    this.lastGetUrl = '';
    this.lastId = '';
    this._lastAdditionalFilter = null;
    this._lastQueryString = null;
    this._lastOrderBy = null;
    this.calculateKey = options.calculateKey || false;
    this.keys = options.keys || null;
    this.selectedKey = options.selectedKey || null;
    this.trackedData = [];
    this.cancelToken = axios.CancelToken;
    this.cancelTokens = [];

    this._allSelected = options.allSelected || false;
    this.defaultAllSelected = this._allSelected;
    this.allSelectedIndeterminate = options.allSelectedIndeterminate || false;
    this.multiselect = options.multiselect || false;
    this.isInfinite = options.isInfinite || false;
    this.resetInfiniteState = false;
    this.countSet = false;
    this.skipCountQuery = options.skipCountQuery || false;
    this.completedInfiniteState = false;
    this.retrievedSkips = [];
    this.queryStringParams = options.queryStringParams || null;

    this.onOptions = event => {
      _onOptions(event, this);
    };

    this.headers = options.headers;

    this.items = [];
    this.totalItems = 0;
  }

  get inited() {
    return this.headers && this.headers.length > 0;
  }

  get options() {
    return this._options;
  }

  set options(val) {
    this._options = val;

    if (this.firstBind === false) {
      let additionalFilter = null;
      if (this.onPageFilter) {
        additionalFilter = this.onPageFilter();
      }

      this.get(additionalFilter);
    }
  }

  get trackedDataKeys() {
    return Object.keys(this.trackedData);
  }

  get trackedDataSelectedCount() {
    let selectedCount = 0;

    if (this.trackedDataKeys && this.trackedDataKeys.length > 0) {
      this.trackedDataKeys.forEach(key => {
        if (this.trackedData[key]._selected === true) {
          selectedCount += 1;
        }
      });
    }

    return selectedCount;
  }

  get allSelected() {
    return this._allSelected;
  }

  set allSelected(val) {
    this._allSelected = val;
    this.defaultAllSelected = val;

    this.trackedDataKeys.forEach(key => {
      this.trackedData[key]._selected = val;
    });

    this.emit('trackedDataSelectedCount', this.trackedDataSelectedCount);
  }

  get selected() {
    return this._selected;
  }

  set selected(val) {
    const selectedBefore = this._selected;
    this._selected = val;

    // Collect the items selected/unselected from the grid.
    // Only include items that the user can see in the current data table page.
    const itemKeys = this.items.map(item => this.itemKey(item));
    // If defaultAllSelected is false, it makes things much easier
    if (this.defaultAllSelected === false) {
      const keysAdded = this._selected
        .map(item => this.itemKey(item))
        .filter(k => itemKeys.indexOf(k) > -1);

      // Iterate over all the keys that the user can see, and set appropriately.
      itemKeys.forEach(k => {
        if (this.trackedData[k]) {
          this.trackedData[k]._selected = keysAdded.indexOf(k) > -1;
        }
      });
    } else {
      const keysBefore = selectedBefore
        .map(item => this.itemKey(item))
        .filter(k => itemKeys.indexOf(k) > -1);
      const keysAfter = val.map(item => this.itemKey(item)).filter(k => itemKeys.indexOf(k) > -1);

      // First, determine which keys were removed, and set trackedData.
      const removed = keysBefore.filter(x => !keysAfter.includes(x));
      removed.forEach(k => {
        if (this.trackedData[k]) {
          this.trackedData[k]._selected = false;
        }
      });

      // Now, determine which keys were added, and set trackedData.
      const added = keysAfter.filter(x => !keysBefore.includes(x));
      added.forEach(k => {
        if (this.trackedData[k]) {
          this.trackedData[k]._selected = true;
        }
      });

      // Did we "add or remove" all the items suddenly?
      // Meaning, the "allSelected" checkbox was selected or deselected
      // Also, was an additionalFilter applied?
      if (
        !this._lastAdditionalFilter &&
        ((removed.length > 0 && this.items.length === removed.length) ||
          (added.length > 0 && this.items.length === added.length))
      ) {
        let selectedVal = true;
        if (removed.length > 0 && this.items.length === removed.length) {
          selectedVal = false;
        }

        if (selectedVal === false) {
          this._allSelected = selectedVal;
        }
        this.allSelectedIndeterminate = this._allSelected === false && this._selected.length > 0;
        this.defaultAllSelected = selectedVal;

        this.trackedDataKeys.forEach(key => {
          this.trackedData[key]._selected = selectedVal;
        });
      } else {
        if (added.length === 0 && removed.length === 0) {
          itemKeys.forEach(key => {
            this.trackedData[key]._selected =
              this._selected.findIndex(x => this.itemKey(x) === key) > -1;
          });
        }

        const allTrackedAreSelected = this.trackedDataKeys
          .map(k => this.trackedData[k]._selected)
          .every(v => v === true);

        const allTrackedAreUnselected = this.trackedDataKeys
          .map(k => this.trackedData[k]._selected)
          .every(v => v === false);

        if (allTrackedAreSelected === false) {
          this._allSelected = allTrackedAreSelected;
        }

        this.allSelectedIndeterminate =
          this._allSelected === false &&
          (this._selected.length > 0 ||
            (allTrackedAreUnselected === false && allTrackedAreSelected === false));
      }
    }

    if (this.defaultAllSelected === true || this.allSelectedIndeterminate === true) {
      this._allSelected = this.selected.length === this.items.length;
    }
    this.emit('selected', this.selected);
    this.emit('trackedDataSelectedCount', this.trackedDataSelectedCount);
  }

  itemKey(item) {
    let itemKey = '';
    if (this.keys && this.keys.length > 0) {
      itemKey = this.keys.map(k => item[k].toString()).join('|');
    } else {
      itemKey = JSON.stringify(item);
    }
    return uuidv3(itemKey, _uuidNamespace);
  }

  trackBoundData(items) {
    items.forEach(item => {
      const itemKey = this.itemKey(item);

      if (!this.trackedData[itemKey]) {
        this.trackedData[itemKey] = {
          _selected: !!(
            this.defaultAllSelected ||
            this._allSelected ||
            (this.selectedKey && item[this.selectedKey] === true)
          ),
          item,
        };
      } else {
        this.trackedData[itemKey].item = item;
      }
    });
  }

  buildODataOptions() {
    if (this.options) {
      const { page, itemsPerPage, sortBy, sortDesc, select } = this.options;

      const odataOptions = {
        top: itemsPerPage,
        skip: (page - 1) * itemsPerPage || 0,
        orderBy: null,
        filter: null,
        select: null,
      };

      const orderBy = [];
      if (sortBy && sortBy.length > 0) {
        for (let sortIx = 0; sortIx < sortBy.length; sortIx += 1) {
          orderBy.push(`${sortBy[sortIx]}${sortDesc && sortDesc[sortIx] ? ' desc' : ''}`);
        }
      }
      if (orderBy && orderBy.length > 0) {
        odataOptions.orderBy = orderBy;
      }

      if (select && select.length > 0) {
        odataOptions.select = select;
      }

      return odataOptions;
    }
    return { filter: null };
  }

  // eslint-disable-next-line class-methods-use-this
  async infiniteHandler($state, additionalFilter) {
    if (this.completedInfiniteState === true) {
      $state.complete();
      return;
    }

    const beforeItemCount = this.items.length;

    this._options.page += 1;

    await this.get(additionalFilter, true, $state);

    // no more data
    if (beforeItemCount === this.items.length) {
      $state.complete();
    } else {
      $state.loaded();
    }
  }

  async get(additionalFilter, force, $state) {
    if (this.isInfinite === true && this.completedInfiniteState === true && $state) {
      this.completedInfiniteState = false;
      $state.complete();

      this.loading = false;
      return Promise.all([]);
    }

    this.loading = true;
    let odataFilter = f('and');

    const additionalFilterString = additionalFilter
      ? odataFilter.and(additionalFilter).toString()
      : null;

    let queryString = null;
    if (this.queryStringParams) {
      queryString = querystringify.stringify(this.queryStringParams);
    }

    let odataOptions = this.buildODataOptions();
    // If no sort by is specified, fall back on our default sort by
    if (
      this.defaultSortBy &&
      this.defaultSortBy.length > 0 &&
      odataOptions &&
      (!odataOptions.orderBy || odataOptions.orderBy.length === 0)
    ) {
      this._options.sortBy = this.defaultSortBy;
      this._options.sortDesc = this.defaultSortByDesc || [];
    }

    odataOptions = this.buildODataOptions();

    const orderBy = odataOptions.orderBy.join(',') || '';
    if (
      this._lastAdditionalFilter !== additionalFilterString ||
      this._lastOrderBy !== orderBy ||
      this._lastQueryString !== queryString
    ) {
      // Reset the page, because a search query or something similar has changed
      this._options.page = 1;
      odataOptions.skip = 0;
      this.totalItems = 0;
      this._lastAdditionalFilter = additionalFilterString;
      this._lastQueryString = queryString;
      this.countSet = false;
      this.retrievedSkips = [];

      if (this.isInfinite && this.firstBind === false) {
        this.items = [];

        if ($state) {
          this.resetState($state);
        }
      }
    } else {
      // Because of the options.sync in the grid, this can get called with previous pages.
      // This will prevent previous pages from getting called.
      if (this.retrievedSkips.indexOf(odataOptions.skip) > -1) {
        // Calculate the last page
        this._options.page = Math.max(...this.retrievedSkips) / this._options.itemsPerPage + 1;
        this.loading = false;
        return Promise.all([]);
      }
      this.retrievedSkips.push(odataOptions.skip);
    }

    if (this.defaultFilter) {
      odataFilter = odataFilter.and(this.defaultFilter);
    }

    this._lastOrderBy = orderBy;

    // additionalFilter would have been already added above via the additionalFilterString

    // Replace any "+" with "ᚯ". Pluses cause issues as they're converted to spaces.
    // We'll convert it back to a plus on the server
    let filter = odataFilter.toString();
    filter = filter.replace(/\+/g, 'ᚯ');

    odataOptions.filter = filter;

    const odataQuery = buildQuery(odataOptions);

    let getUrl = `${this.baseUrl}${odataQuery}`;

    if (queryString) {
      // if the url already has a query string, add an '&', else '?'
      if (getUrl && getUrl.indexOf('?') > -1) {
        getUrl += '&';
      } else {
        getUrl += '?';
      }
      getUrl += queryString;
    }

    if (force === true || this.alwaysGetData || this.lastGetUrl !== getUrl) {
      // Only get the data if the URL has changed, or we've explicitly requested to do so.
      // create new cancel token source
      const id = uuidv4();
      this.lastId = id;
      const ctsGet = this.cancelToken.source();
      const ctsGetCount = this.cancelToken.source();

      // cancel any in-flight requests
      this.cancelTokens.forEach(tokenSource => {
        tokenSource.cancel('Operation canceled by the user.');
      });
      // clear tokens and add new ones
      this.cancelTokens = [];
      this.cancelTokens.push(ctsGet);
      this.cancelTokens.push(ctsGetCount);

      this.lastGetUrl = getUrl;

      // this has to be done on another thread
      // javascript: ¯\_(ツ)_/¯
      setImmediate(() => {
        this.loading = true;
      });
      // Get the data from the server
      const dataPromise = new Promise(resolve => {
        this.httpClient
          .get(getUrl, {
            cancelToken: ctsGet.token,
          })
          .then(response => {
            const items = response.data;

            // If the data has no key defined, then we need to give it one.
            // Also we need to make sure there isn't a property already defined named "id"
            if (
              this.calculateKey === true &&
              this.keys.length > 0 &&
              items.length > 0 &&
              Object.keys(items[0]).indexOf('id') === -1
            ) {
              items.forEach(item => {
                // Calculate the row using UUID v3.
                // This way the UUID will be the same between paging.
                vue.set(item, 'id', this.itemKey(item));
              });
            }

            resolve({
              items,
              id,
            });
          })
          .catch(e => {
            resolve({
              items: [],
              id,
            });
            if (axios.isCancel(e) !== true) {
              this.emit('error', e);
            }
          });
      }).finally(() => {
        const ctsIx = this.cancelTokens.findIndex(x => x === ctsGet);
        if (ctsIx > -1) {
          this.cancelTokens.splice(ctsIx, 1);
        }
      });

      let countPromise = null;
      if (this.countSet === false && this.skipCountQuery === false) {
        // Get the data count from the server
        let countUrl = `${this.baseUrl}$count${odataQuery}`;

        if (queryString) {
          // if the url already has a query string, add an '&', else '?'
          if (countUrl && countUrl.indexOf('?') > -1) {
            countUrl += '&';
          } else {
            countUrl += '?';
          }
          countUrl += queryString;
        }

        countPromise = new Promise(resolve => {
          this.httpClient
            .get(countUrl, {
              cancelToken: ctsGetCount.token,
            })
            .then(response => {
              const count = response.data;
              resolve({
                count,
                id,
              });
            })
            .catch(() => {
              resolve({
                count: 0,
                id,
              });
            });
        }).finally(() => {
          const ctsIx = this.cancelTokens.findIndex(x => x === ctsGetCount);
          if (ctsIx > -1) {
            this.cancelTokens.splice(ctsIx, 1);
          }
        });
      } else {
        countPromise = new Promise(resolve => {
          resolve({
            count: this.totalItems,
          });
        });
      }

      return Promise.all([dataPromise, countPromise]).then(values => {
        // only continue if this is the most recent get we're doing
        if (this.lastId === id) {
          this.loading = false;
          this.firstBind = false;

          if (this.isInfinite === true) {
            this.items = this.items.concat(values[0].items);
          } else {
            this.items = values[0].items;
          }

          this.totalItems = values[1].count;
          // If the number of items is somehow larger than our total number of items,
          // then something has changed and we should get the total count again
          this.countSet = this.totalItems > this.items.length;
          this.completedInfiniteState = this.items.length >= this.totalItems;

          if (this.defaultAllSelected === false) {
            this._selected = [];
          }

          if (this._allSelected === true) {
            if (this.isInfinite === true) {
              this._selected = this._selected.concat(this.items);
            } else {
              this.selected = this.items;
            }
          } else if ((this.trackedData && this.trackedDataKeys.length > 0) || this.selectedKey) {
            // Iterate over newly bound items and determine if it was selected/unselected
            // in a previous bind or if the data has the selected flag set to true.
            this.items.forEach(item => {
              const itemKey = this.itemKey(item);
              if (
                (this.trackedData[itemKey] && this.trackedData[itemKey]._selected === true) ||
                (!this.trackedData[itemKey] &&
                  (this.defaultAllSelected === true || this._allSelected === true)) ||
                (this.selectedKey &&
                  item[this.selectedKey] === true &&
                  (!this.trackedData[itemKey] ||
                    (this.trackedData[itemKey] && this.trackedData[itemKey]._selected === true)))
              ) {
                this.selected.push(item);
              }
            });
          }

          this.trackBoundData(this.items);
          this.emit('dataBound');
        }
      });
    }

    this.loading = false;
    return Promise.all([]);
  }

  resetState($state) {
    if ($state) {
      $state.reset();
      this.resetInfiniteState = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  selectedIndex(item, table) {
    const itemKey = table.itemKey(item);
    return table._selected.findIndex(x => table.itemKey(x) === itemKey);
  }

  // eslint-disable-next-line class-methods-use-this
  itemRowMultiClicked(event, table) {
    // Check to see if it's already selected
    const selectedIndex = table.selectedIndex(event, table);
    table.itemRowMultiSelected({ item: event, value: selectedIndex === -1 }, table);
  }

  // eslint-disable-next-line class-methods-use-this
  itemRowMultiSelected(event, table) {
    if (event.value === true) {
      table._selected.push(event.item);
    } else {
      const selectedIndex = table.selectedIndex(event.item, table);
      table._selected.splice(selectedIndex, 1);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  itemRowSingleClicked(event, table) {
    this.selected = [];
    this.itemRowMultiClicked(event, table);
  }

  // eslint-disable-next-line class-methods-use-this
  itemRowSingleSelected(event, table) {
    this.selected = [];
    this.itemRowMultiSelected(event, table);
  }

  // eslint-disable-next-line class-methods-use-this
  clearSelectedItems(table) {
    table.selected = [];
    Object.keys(table.trackedData).forEach(key => {
      table.trackedData[key]._selected = false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  toggleSelectAll(table, e) {
    table._allSelected = e.value;
  }
}
