<template>
  <core-view-template title="User Vendor Account Setup">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            ref="userLookupTextField"
            label="User lookup"
            v-model="userLookup"
            @keyup.enter="lookupUser"
          />
        </v-col>
        <v-col>
          <v-btn @click="lookupUser" :disabled="!userLookupSet" :loading="loading">Lookup</v-btn>
        </v-col>
      </v-row>
      <div v-if="userData">
        <v-row>
          <v-col> User name: {{ userData?.Email }} </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-list>
                <v-subheader>Stores</v-subheader>
                <v-list-item-group>
                  <v-list-item v-for="store in userData.StoreNumbers" :key="store">
                    <v-list-item-content>
                      <v-list-item-title>{{ store }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-subheader>Customer Accounts</v-subheader>
              <v-card-text>
                <v-row>
                  <v-col>
                    Assigned Customer Accounts:
                    <template v-for="account in customerAccounts">
                      <v-chip
                        v-if="isDefaultCustomerAccountNumber(account)"
                        color="gray"
                        v-bind:key="`default-${account}`"
                        class="ma-1"
                        >{{ account }}</v-chip
                      >
                      <v-chip
                        v-else
                        @click:close="custAccountRemoveClick(account)"
                        :color="determineCustomerAccountColor(account)"
                        close
                        v-bind:key="account"
                        class="ma-1"
                        >{{ account }}</v-chip
                      >
                    </template>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      @click="saveCustomerAccounts"
                      :loading="loading"
                      :disabled="!addedCustomerAccounts.length && !removeCustomerAccounts.length"
                      >Save</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
                <v-row class="mt-2">
                  <v-col>
                    <v-text-field
                      v-model="customerAccountSearch"
                      @keyup.enter="searchCustomerClick"
                      label="Search Customer Accounts"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click="searchCustomerClick" :loading="loading">Search</v-btn>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="custLegalEntities"
                      :items="vpFinanceStore.legalEntityLookup"
                      label="Legal Entities"
                      chips
                      multiple
                      item-value="LegalEntityId"
                      :item-text="legalEntityText"
                    />
                  </v-col>
                </v-row>
                <v-data-table
                  :items="vpFinanceStore.customerSearchResult"
                  :headers="customerHeaders"
                  class="mt-2 cursor-pointer"
                  :server-items-length="serverItemsLength"
                  :options.sync="custOptions"
                  :loading="loading"
                  :footer-props="{ 'items-per-page-options': [] }"
                  @click:row="custRowClick"
                >
                  <template v-slot:footer.page-text="props">
                    <span>{{ props.pageStart }} - {{ props.pageStop }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-subheader>Vendor Accounts</v-subheader>
              <v-card-text>
                <v-row>
                  <v-col>
                    Assigned Vendor Accounts:
                    <template v-for="account in vendorAccounts">
                      <v-chip
                        v-if="isDefaultVendorAccountNumber(account)"
                        color="gray"
                        v-bind:key="`default-${account}`"
                        class="ma-1"
                        >{{ account }}</v-chip
                      >
                      <v-chip
                        v-else
                        @click:close="vendorAccountRemoveClick(account)"
                        :color="determineVendorAccountColor(account)"
                        close
                        v-bind:key="account"
                        class="ma-1"
                        >{{ account }}</v-chip
                      >
                    </template>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      @click="saveVendorAccounts"
                      :loading="loading"
                      :disabled="!addedVendorAccounts.length && !removeVendorAccounts.length"
                      >Save</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
                <v-row class="mt-2">
                  <v-col>
                    <v-text-field
                      v-model="vendorAccountSearch"
                      @keyup.enter="searchVendorClick"
                      label="Search Vendor Accounts"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click="searchVendorClick" :loading="loading">Search</v-btn>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="vendorLegalEntities"
                      :items="vpFinanceStore.legalEntityLookup"
                      label="Legal Entities"
                      chips
                      multiple
                      item-value="LegalEntityId"
                      :item-text="legalEntityText"
                    />
                  </v-col>
                </v-row>
                <v-data-table
                  :items="vpFinanceStore.vendorSearchResult"
                  :headers="vendorHeaders"
                  class="mt-2 cursor-pointer"
                  :server-items-length="serverItemsLength"
                  :options.sync="vendOptions"
                  :loading="loading"
                  :footer-props="{ 'items-per-page-options': [] }"
                  @click:row="vendRowClick"
                >
                  <template v-slot:footer.page-text="props">
                    <span>{{ props.pageStart }} - {{ props.pageStop }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>{{
        snackbarMessage
      }}</v-snackbar>
    </v-container>
  </core-view-template>
</template>

<script setup lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import {
  CustomerSearchDto,
  D365UserDto,
  LegalEntityLookupDto,
  VendorSearchDto,
} from '@psp/pogona_vendor_api_lib';
import { existsInList } from '@/utils/FableUtils.fs.js';

defineComponent({
  name: 'UserVendorAccounts',
});

const customerHeaders = [
  { text: 'Data Area Id', value: 'DataAreaId', sortable: false },
  { text: 'Customer Account', value: 'CustomerAccount', sortable: false },
  { text: 'Organization Name', value: 'OrganizationName', sortable: false },
];
const vendorHeaders = [
  { text: 'Data Area Id', value: 'DataAreaId', sortable: false },
  { text: 'Vendor Account Number', value: 'VendorAccountNumber', sortable: false },
  { text: 'Organization Name', value: 'VendorOrganizationName', sortable: false },
];

const serverItemsLength = 99999;
const vpFinanceStore = useVpFinanceStore();
const userLookup = ref('');
const userData = ref<D365UserDto | null>(null);
const loading = ref(false);
const userLookupTextField = ref<HTMLInputElement | null>(null);
const originalCustomerAccounts = ref<string[]>([]);
const custLegalEntities = ref<string[]>(['0020']);
const vendorLegalEntities = ref<string[]>(['0020']);
const customerAccountSearch = ref('');
const custOptions = ref<{ page: number; itemsPerPage: number }>({ page: 1, itemsPerPage: 10 });
const existingCustomerAccounts = ref<string[]>([]);
const addedCustomerAccounts = ref<string[]>([]);
const removeCustomerAccounts = ref<string[]>([]);
const showSnackbar = ref(false);
const snackbarMessage = ref('');
const snackbarColor = ref('');
const originalVendorAccounts = ref<string[]>([]);
const vendorAccountSearch = ref('');
const vendOptions = ref<{ page: number; itemsPerPage: number }>({ page: 1, itemsPerPage: 10 });
const existingVendorAccounts = ref<string[]>([]);
const addedVendorAccounts = ref<string[]>([]);
const removeVendorAccounts = ref<string[]>([]);

const userLookupSet = computed(() => userLookup.value.length);

function isDefaultCustomerAccountNumber(account: string) {
  // Default account numbers follow the formats
  // 10000{storeNumber} check against userData.StoreNumbers
  // Starts with 199999 or 188888
  const checkStoreNumber =
    account.startsWith('10000') && existsInList(account.slice(5), userData.value?.StoreNumbers);
  const checkDefaultAccount = account.startsWith('199999') || account.startsWith('188888');
  return checkStoreNumber || checkDefaultAccount;
}

function isDefaultVendorAccountNumber(account: string) {
  // Default account numbers follow the formats
  // 211{storeNumber} check against userData.StoreNumbers
  const checkVendorNumber =
    account.startsWith('211') && existsInList(account.slice(3), userData.value?.StoreNumbers);
  return checkVendorNumber;
}

function legalEntityText(item: LegalEntityLookupDto) {
  return `${item.LegalEntityId} - ${item.Name}`;
}

function determineCustomerAccountColor(account: string) {
  if (isDefaultCustomerAccountNumber(account)) {
    return 'gray';
  }
  if (existingCustomerAccounts.value.includes(account)) {
    return 'gray';
  }
  if (addedCustomerAccounts.value.includes(account)) {
    return 'green';
  }
  if (removeCustomerAccounts.value.includes(account)) {
    return 'red';
  }
  return 'gray';
}

watch(
  custOptions,
  async (curr: any) => {
    if ('page' in curr) {
      const skip = (curr.page - 1) * curr.itemsPerPage;
      searchCustomerAccounts(skip, curr.itemsPerPage);
    }
  },
  { immediate: true },
);

watch(
  vendOptions,
  async (curr: any) => {
    if ('page' in curr) {
      const skip = (curr.page - 1) * curr.itemsPerPage;
      searchVendorAccounts(skip, curr.itemsPerPage);
    }
  },
  { immediate: true },
);

function lookupUser() {
  loading.value = true;
  vpFinanceStore
    .getUserData(userLookup.value)
    .then(data => {
      userData.value = data;
      originalCustomerAccounts.value = [...(data?.CustomerAccountNumbers ?? [])];
      existingCustomerAccounts.value = originalCustomerAccounts.value;
      originalVendorAccounts.value = [...(data?.VendorAccountNumbers ?? [])];
      existingVendorAccounts.value = originalVendorAccounts.value;
    })
    .finally(() => {
      loading.value = false;
    });
}

function custAccountRemoveClick(account: string) {
  if (existingCustomerAccounts.value.includes(account)) {
    existingCustomerAccounts.value = existingCustomerAccounts.value.filter(x => x !== account);
    removeCustomerAccounts.value.push(account);
  } else if (addedCustomerAccounts.value.includes(account)) {
    addedCustomerAccounts.value = addedCustomerAccounts.value.filter(x => x !== account);
  } else if (removeCustomerAccounts.value.includes(account)) {
    if (originalCustomerAccounts.value.includes(account)) {
      existingCustomerAccounts.value.push(account);
    }
    removeCustomerAccounts.value = removeCustomerAccounts.value.filter(x => x !== account);
  }
}

function custRowClick(item: CustomerSearchDto) {
  if (customerAccounts.value.includes(item.CustomerAccount)) {
    snackbarMessage.value = 'Customer account already added';
    snackbarColor.value = 'error';
    showSnackbar.value = true;
    return;
  }
  addedCustomerAccounts.value.push(item.CustomerAccount);
}

function vendRowClick(item: VendorSearchDto) {
  if (vendorAccounts.value.includes(item.VendorAccountNumber)) {
    snackbarMessage.value = 'Vendor account already added';
    snackbarColor.value = 'error';
    showSnackbar.value = true;
    return;
  }
  addedVendorAccounts.value.push(item.VendorAccountNumber);
}

function searchCustomerClick() {
  searchCustomerAccounts(0, 10);
}

function searchVendorClick() {
  searchVendorAccounts(0, 10);
}

function searchCustomerAccounts(skip: number = 0, take: number = 10) {
  loading.value = true;
  vpFinanceStore
    .searchCustomers(customerAccountSearch.value, custLegalEntities.value, skip, take)
    .finally(() => {
      loading.value = false;
    });
}

function searchVendorAccounts(skip: number = 0, take: number = 10) {
  loading.value = true;
  vpFinanceStore
    .searchVendors(vendorAccountSearch.value, vendorLegalEntities.value, skip, take)
    .finally(() => {
      loading.value = false;
    });
}

async function saveCustomerAccounts() {
  loading.value = true;
  try {
    // Call vpFinanceStore.addCustomerAccount with addedCustomerAccounts
    // Call vpFinanceStore.removeCustomerAccount with removeCustomerAccounts
    // Call in parallel
    const calls = [] as Promise<any>[];
    addedCustomerAccounts.value.forEach(account => {
      calls.push(vpFinanceStore.addCustomerAccount(userData.value?.Email ?? '', account));
    });
    removeCustomerAccounts.value.forEach(account => {
      calls.push(vpFinanceStore.removeCustomerAccount(userData.value?.Email ?? '', account));
    });
    const results = await Promise.allSettled(calls);
    if (results.some(x => x.status === 'rejected')) {
      snackbarMessage.value = 'Failed to save customer accounts';
      snackbarColor.value = 'error';
      showSnackbar.value = true;
    } else {
      snackbarMessage.value = 'Customer accounts saved';
      snackbarColor.value = 'success';
      showSnackbar.value = true;
    }
    await vpFinanceStore.deleteUserCacheEntry(userData.value?.Email ?? '');
    userLookup.value = userData.value?.Email ?? '';
    addedCustomerAccounts.value = [];
    removeCustomerAccounts.value = [];
    userData.value = null;
    nextTick(() => lookupUser());
  } finally {
    loading.value = false;
  }
}

const customerAccounts = computed(() => {
  return [...(userData.value?.CustomerAccountNumbers ?? []), ...addedCustomerAccounts.value].sort();
});

const vendorAccounts = computed(() => {
  return [...(userData.value?.VendorAccountNumbers ?? []), ...addedVendorAccounts.value].sort();
});

function vendorAccountRemoveClick(account: string) {
  if (existingVendorAccounts.value.includes(account)) {
    existingVendorAccounts.value = existingVendorAccounts.value.filter(x => x !== account);
    removeVendorAccounts.value.push(account);
  } else if (addedVendorAccounts.value.includes(account)) {
    addedVendorAccounts.value = addedVendorAccounts.value.filter(x => x !== account);
  } else if (removeVendorAccounts.value.includes(account)) {
    if (originalVendorAccounts.value.includes(account)) {
      existingVendorAccounts.value.push(account);
    }
    removeVendorAccounts.value = removeVendorAccounts.value.filter(x => x !== account);
  }
}

function determineVendorAccountColor(account: string) {
  if (existingVendorAccounts.value.includes(account)) {
    return 'gray';
  }
  if (addedVendorAccounts.value.includes(account)) {
    return 'green';
  }
  if (removeVendorAccounts.value.includes(account)) {
    return 'red';
  }
  return 'gray';
}

async function saveVendorAccounts() {
  loading.value = true;
  try {
    // Call vpFinanceStore.addVendorAccount with addedVendorAccounts
    // Call vpFinanceStore.removeVendorAccount with removeVendorAccounts
    // Call in parallel
    const calls = [] as Promise<any>[];
    addedVendorAccounts.value.forEach(account => {
      calls.push(vpFinanceStore.addVendorAccount(userData.value?.Email ?? '', account));
    });
    removeVendorAccounts.value.forEach(account => {
      calls.push(vpFinanceStore.removeVendorAccount(userData.value?.Email ?? '', account));
    });
    const results = await Promise.allSettled(calls);
    if (results.some(x => x.status === 'rejected')) {
      snackbarMessage.value = 'Failed to save vendor accounts';
      snackbarColor.value = 'error';
      showSnackbar.value = true;
    } else {
      snackbarMessage.value = 'Vendor accounts saved';
      snackbarColor.value = 'success';
      showSnackbar.value = true;
    }
    await vpFinanceStore.deleteUserCacheEntry(userData.value?.Email ?? '');
    userLookup.value = userData.value?.Email ?? '';
    addedVendorAccounts.value = [];
    removeVendorAccounts.value = [];
    userData.value = null;
    nextTick(() => lookupUser());
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  userLookupTextField.value?.focus();
  vpFinanceStore.getLegalEntityLookup();
  searchCustomerAccounts();
});
</script>
<style scoped>
.cursor-pointer ::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
