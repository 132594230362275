<template>
  <core-view-template title="Price Change Search">
    <item-update-search-data-table
      @snackba-warning="$emit('snackbar-warning', $event)"
      @snackbar-error="$emit('snackbar-error', $event)"
    />
  </core-view-template>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import ItemUpdateSearchDataTable from '@/components/vendorportal/ItemUpdateSearchDataTable.vue';

@Component({
  components: { ItemUpdateSearchDataTable },
})
export default class ItemUpdateSearch extends Vue {}
</script>
