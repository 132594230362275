<template>
  <v-autocomplete
    v-model="selectedItem"
    :items="items"
    :loading="loading"
    item-text="text"
    item-value="VendorAccountNumber"
    clearable
    :search-input.sync="search"
    :label="vendorLabel"
  ></v-autocomplete>
</template>
<script setup lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import debounce from 'lodash/debounce';
defineComponent({
  name: 'VendorSelection',
});
type VendorSelectionProps = {
  vendorAcct: string;
};
const props = withDefaults(defineProps<VendorSelectionProps>(), {
  vendorAcct: '',
});
const emit = defineEmits(['vendorSelected']);
const vpFinanceStore = useVpFinanceStore();
const isPspFinance = ref(false);
const impersonating = ref(false);
const selectedItem = ref<string | null>(null);
const loading = ref(false);
const search = ref('');
const maxVendorLength = 50;
watch(
  () => props.vendorAcct,
  (newVal: string) => {
    nextTick(() => (selectedItem.value = newVal));
  },
);
const vendorLabel = computed(() => {
  let label = 'Vendor';
  const isPsp = isPspFinance.value && !impersonating.value;
  const resultsLength = vpFinanceStore.vendorSearchResultWithRestriction.length;
  if (isPsp && resultsLength === 0) {
    label = 'Vendor (Search Required)';
  } else if (resultsLength === maxVendorLength) {
    label = 'Vendor (Max results reached, refine search)';
  }
  return label;
});
const items = computed(() => {
  const results = vpFinanceStore.vendorSearchResultWithRestriction ?? [];
  return results.map((v: any) => {
    let text = '';
    switch (v.VendorOrganizationName) {
      case '':
        text = v.VendorAccountNumber;
        break;
      default:
        text = `${v.VendorOrganizationName} - ${v.VendorAccountNumber}`;
    }
    return {
      ...v,
      ...{ text },
    };
  });
});
const findItemByText = (text: string) => {
  return items.value.find((v: any) => v.text === text);
};
const fetchItems = async search => {
  loading.value = true;
  try {
    return await vpFinanceStore.searchVendorsWithRestriction(search, 0, maxVendorLength);
  } finally {
    loading.value = false;
  }
};
const debouncedFetchItems = debounce(async search => {
  return await fetchItems(search);
}, 500);
function impersonatingAndNoItems() {
  return (!isPspFinance.value || impersonating.value) && items.value.length === 0;
}
function isNotPspFinacneAndNotImpersonating() {
  return !isPspFinance.value && !impersonating.value;
}
watch(search, async newSearch => {
  if (impersonatingAndNoItems() || ((newSearch?.length ?? 0) > 0 && !findItemByText(newSearch))) {
    await debouncedFetchItems(newSearch);
  }
});
watch(selectedItem, async value => {
  if (!isNotPspFinacneAndNotImpersonating() && value && items.value.length === 0) {
    await fetchItems(value);
  }
  emit('vendorSelected', value ?? '');
});
const processImpersonation = async () => {
  isPspFinance.value = await vpFinanceStore.isPspFinance();
  if (isPspFinance.value) {
    const user = await vpFinanceStore.getD365UserImpersonation();
    impersonating.value = user?.ImpersonatedUser ? true : false;
  }
};
onMounted(async () => {
  const process = await processImpersonation();
  return process;
});
</script>
