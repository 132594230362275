<template>
  <v-main class="core-view-wrap">
    <core-page-title :title="finalPageTitle" />
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view
          v-if="authorized"
          @snackbar-success="showSuccessSnackbar"
          @snackbar-warning="showWarningSnackbar"
          @snackbar-error="showErrorSnackbar"
          @snackbar-hide="hideSnackbar"
        />
        <core-unauthorized v-if="!authorized"></core-unauthorized>
      </v-fade-transition>
      <data-snackbar
        :text="snackbar.text"
        :color="snackbar.color"
        ref="mainSnackbar"
      ></data-snackbar>
    </div>
    <core-footer
      @snackbar-error="showErrorSnackbar"
      @snackbar-success="showSuccessSnackbar"
      @snackbar-warning="showWarningSnackbar"
      @snackbar-hide="hideSnackbar"
    ></core-footer>
  </v-main>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { setTimeout } from 'timers';
import menuData from '@/data/menu.json';
import JsonHelpers from '@/utils/JsonHelpers';
import * as m from '@/store/mutations';
import * as s from '@/store/state';

const snackbarMixin = {
  methods: {
    showSnackbar(text, color) {
      this.$refs.mainSnackbar.hide();

      this.snackbar.color = color;
      this.snackbar.text = text;

      // We do this to make the snackbar "flicker" a little bit
      // to show something has happened again,
      // in case the snackbar is already being shown.
      setTimeout(() => {
        this.$refs.mainSnackbar.show();
      }, 100);
    },
  },
};

export default {
  mixins: [snackbarMixin],
  props: {
    authorized: {
      required: true,
      default: false,
    },
    pageTitle: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      snackbar: {
        text: '',
        color: '',
      },
      menuData,
      finalPageTitle: this.pageTitle,
      showLoading: false,
    };
  },
  computed: {
    ...mapState([s.err, s.success]),
    ...mapState('app', ['username', 'storeNumber']),
  },
  created() {
    this.setPageTitle();
  },
  methods: {
    ...mapMutations([m.clearError, m.clearSuccess]),
    showSuccessSnackbar(text) {
      this.showSnackbar(text, '#4caf50');
    },
    showWarningSnackbar(text) {
      this.showSnackbar(text, 'warning');
    },
    showErrorSnackbar(errorDetails) {
      try {
        let text = null;
        let err = null;
        let id = null;

        if (typeof errorDetails === 'string') {
          text = errorDetails;
        } else {
          text = errorDetails.text;
          err = errorDetails.err;
          id = errorDetails.id;
        }

        this.showSnackbar(text, 'red');
        if (err) {
          this.$appInsights.trackException({
            exception: err,
            properties: {
              text: text,
              username: this.username,
              storeNumber: this.storeNumber,
              route:
                !this.$route || typeof this.$route === 'string'
                  ? this.$route
                  : this.$route.fullPath,
              id,
            },
          });
        }
      } catch (outerErr) {
        this.$appInsights.trackException({
          exception: outerErr,
          properties: {
            text: 'error tracking exception',
            username: this.username,
            storeNumber: this.storeNumber,
            route: this.$route,
            id: 'c4a7be5e-206d-41be-b0c0-df16aea6a1a5',
          },
        });
      }
    },
    hideSnackbar() {
      this.$refs.mainSnackbar.hide();
    },
    setPageTitle() {
      if (this.pageTitle) {
        this.finalPageTitle = this.pageTitle;
      } else {
        const foundMenuRoute = JsonHelpers.findKeyAndValue(
          'route',
          { name: this.$route.name },
          this.menuData,
        );
        if (
          foundMenuRoute &&
          foundMenuRoute.length > 0 &&
          foundMenuRoute[0] &&
          foundMenuRoute[0].title
        ) {
          this.finalPageTitle = foundMenuRoute[0].title;
        } else {
          this.finalPageTitle = null;
        }
      }
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.setPageTitle();
      },
      deep: true,
    },
    err(e) {
      if (e !== null) {
        this.showErrorSnackbar(e);
        this.clearError();
      }
    },
    success(e) {
      if (e !== null) {
        this.showSuccessSnackbar(e);
        this.clearSuccess();
      }
    },
  },
};
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
.core-view-wrap {
  padding-left: 88px !important;
}
</style>
