import Vue from 'vue';

export default Vue.extend({
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    createRequestDataPayload(
      data: any,
      isMulti: boolean | null | undefined,
      isLast: boolean | null | undefined,
    ): any {
      return {
        AggregateId: data.id,
        RequestID: 0,
        PrivateLabel: data.privateLabel,
        RequestType: data.requestType,
        Status: data.status,
        FullUnitBarcode: data.fullUnitBarcode,
        CaseBarcode: data.caseBarcode,
        OtherBarcode: data.otherBarcode,
        ProductName: data.productName,
        RetailPackageSize: data.retailPackageSize,
        RetailPackageUOM: data.retailPackageUOM,
        ManufacturerAccountNumber: data.manufacturerAccountNumber,
        Brand: data.brand,
        VendorId: data.vendorId,
        ExternalItemNumber: data.externalItemNumber,
        MasterCase: data.masterCase,
        InnerCasePack: data.innerCasePack,
        LayerQuantity: data.layerQuantity,
        PalletQuantity: data.palletQuantity,
        DistributionNetwork: data.distributionNetwork,
        DirectToStore: data.directToStore,
        DirectToStoreOrVendorIntoPspdPurchaseCost: data.directToStoreOrVendorIntoPspdPurchaseCost,
        MfgSuggestedRetail: data.mfgSuggestedRetail,
        MapPrice: data.mapPrice,
        IMap: data.imap,
        AutoShipEligible: data.autoShipEligible,
        InitialDiscountFundingPercent: data.initialDiscountFundingPercent,
        OnGoingFundingDiscountPercent: data.onGoingFundingDiscountPercent,
        MaterialSafetyDataSheetNeeded: data.materialSafetyDataSheetNeeded,
        MinimumOrderQuantity: data.minimumOrderQuantity,
        UnitWeight: data.unitWeight,
        UnitWeightUOM: data.unitWeightUOM,
        UnitHeight: data.unitHeight,
        UnitWidth: data.unitWidth,
        UnitDepth: data.unitDepth,
        CaseHeight: data.caseHeight,
        CaseWidth: data.caseWidth,
        CaseDepth: data.caseDepth,
        ProductAttribute: data.productAttribute,
        Images: data.images,
        ProgramName: data.programName,
        HtsCode: data.htsCode,
        DutyRate: data.dutyRate,
        FobPort: data.fobPort,
        PluScan: data.pluScan,
        PluAssigned: data.pluAssigned,
        DaxItemNumber: data.daxItemNumber,
        SignsAndLabelsDescription1: data.signsAndLabelsDescription1,
        SignsAndLabelsDescription2: data.signsAndLabelsDescription2,
        VendorNumber: data.vendorNumber,
        ShipsToStores: data.shipsToStores,
        ShipUnitCostFromPspd: data.shipUnitCostFromPspd,
        RetailBasePrice: data.retailBasePrice,
        RetailHighPrice: data.retailHighPrice,
        Hierarchy: data.hierarchy,
        CategoryManagerName: data.categoryManagerName,
        PspdInventoryManagerName: data.pspdInventoryManagerName,
        Reason: data.reason,
        Distributors: data.dsdDistributor,
        IsMulti: isMulti,
        IsLast: isLast,
        BatchId: data.batchId,
        Seasonal: data.seasonal,
        Season: data.season,
        Year: data.year,
        Category: data.category,
      };
    },
  },
});
