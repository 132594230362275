import { Record } from "../fable-library-js.4.16.0/Types.js";
import { record_type, class_type, option_type, int32_type, string_type, bool_type } from "../fable-library-js.4.16.0/Reflection.js";

export class ProcessFile extends Record {
    constructor(Archived, ProcessedFileBlobPath, ProcessedProfileFriendlyName, ProcessedProfileId, ProcessedRunId, ProcessFileId, ProcessFileStatusDescription, ProcessFileStatusId, ProcessingProfileFriendlyName, ProcessingProfileId, RecordsProcessed, SourceFileBlobPath, TimeInserted, TimeProcessed, UploadedBy) {
        super();
        this.Archived = Archived;
        this.ProcessedFileBlobPath = ProcessedFileBlobPath;
        this.ProcessedProfileFriendlyName = ProcessedProfileFriendlyName;
        this.ProcessedProfileId = ProcessedProfileId;
        this.ProcessedRunId = ProcessedRunId;
        this.ProcessFileId = (ProcessFileId | 0);
        this.ProcessFileStatusDescription = ProcessFileStatusDescription;
        this.ProcessFileStatusId = (ProcessFileStatusId | 0);
        this.ProcessingProfileFriendlyName = ProcessingProfileFriendlyName;
        this.ProcessingProfileId = ProcessingProfileId;
        this.RecordsProcessed = (RecordsProcessed | 0);
        this.SourceFileBlobPath = SourceFileBlobPath;
        this.TimeInserted = TimeInserted;
        this.TimeProcessed = TimeProcessed;
        this.UploadedBy = UploadedBy;
    }
}

export function ProcessFile_$reflection() {
    return record_type("pogona_vendor_api_lib.VendorDtos.ProcessFile", [], ProcessFile, () => [["Archived", bool_type], ["ProcessedFileBlobPath", string_type], ["ProcessedProfileFriendlyName", string_type], ["ProcessedProfileId", option_type(int32_type)], ["ProcessedRunId", class_type("System.Guid")], ["ProcessFileId", int32_type], ["ProcessFileStatusDescription", string_type], ["ProcessFileStatusId", int32_type], ["ProcessingProfileFriendlyName", string_type], ["ProcessingProfileId", option_type(int32_type)], ["RecordsProcessed", int32_type], ["SourceFileBlobPath", string_type], ["TimeInserted", class_type("System.DateTime")], ["TimeProcessed", option_type(class_type("System.DateTime"))], ["UploadedBy", string_type]]);
}

export class NewItemTemplateFile extends Record {
    constructor(Name, Current, CreatedOn, LastModified, Who) {
        super();
        this.Name = Name;
        this.Current = Current;
        this.CreatedOn = CreatedOn;
        this.LastModified = LastModified;
        this.Who = Who;
    }
}

export function NewItemTemplateFile_$reflection() {
    return record_type("pogona_vendor_api_lib.VendorDtos.NewItemTemplateFile", [], NewItemTemplateFile, () => [["Name", string_type], ["Current", bool_type], ["CreatedOn", class_type("System.DateTime")], ["LastModified", class_type("System.DateTime")], ["Who", string_type]]);
}

