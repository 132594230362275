<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="isPclWriter">
        <router-link
          :to="{
            name: 'pcl_newstore',
          }"
        >
          <v-btn>Add New Store</v-btn>
        </router-link>
      </v-row>

      <v-row>
        <v-btn @click="resetFull">Reset Full Spreadsheet</v-btn>
        <v-btn @click="clearAllFilters(true)">Clear All Filters</v-btn>
      </v-row>

      <v-row class="table-row">
        <v-data-table
          v-if="
            pclListingAllHeaders &&
            pclListingAllHeaders.headers &&
            pclListingAllHeaders.headers.length > 0
          "
          :headers="pclListingAllHeaders.headers"
          :items="storeListingAllData.Results"
          :items-per-page="-1"
          :fixed-header="true"
          :loading="storeListingAllDataGetInProgress"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: '',
          }"
          :options.sync="options"
          :disable-pagination="true"
          :server-items-length="storeListingAllData.TotalCount"
          class="elevation-1 scrollable mb-10"
          id="listAllTable"
          @update:options="updateSortBy"
          hide-default-footer
          item-key="StoreId"
          height="100%"
        >
          <template v-slot:header.StoreNumber="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                :hideable="false"
                cssClass="store-number"
              ></pcl-store-listing-all-header-text>
              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.StoreNumber.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.StoreNumber="{ item }">
            <router-link
              v-if="isPclWriter"
              :to="{ name: 'pcl_editstore', params: { storeId: item.StoreId } }"
            >
              <v-icon class="mr-2">edit</v-icon>
            </router-link>
            {{ item.StoreNumber }}
          </template>

          <template v-slot:header.StoreName="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.StoreName.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.StoreStatusId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.StoreStatusId.SearchValue"
                  :items="storeStatusIdTypesDataWithBlank"
                  :loading="storeStatusesGetInProgress"
                  :disabled="storeStatusesGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.StoreStatusId="{ value }">
            {{ storeStatusName(value) }}
          </template>

          <template v-slot:header.Phone="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Phone.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Phone="{ value }">
            {{ value | phone }}
          </template>

          <template v-slot:header.Email="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Email.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.LegalEntity="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.LegalEntity.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.FranchiseEntity="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.FranchiseEntity.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.OwnerGroup="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.OwnerGroup.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.IsCorporate="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.IsCorporate.SearchValue"
                  :items="nullableBools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.IsCorporate="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.OwnerEmail="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.OwnerEmail.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.TimeZoneId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.TimeZoneId.SearchValue"
                  :items="timeZonesDataWithBlank"
                  :loading="timeZoneGetInProgress"
                  :disabled="timeZoneGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Code"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.TimeZoneId="{ value }">
            {{ timeZoneCode(value) }}
          </template>

          <template v-slot:header.Address="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Address.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Address2="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Address2.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.City="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.City.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.State="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                col="7"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.State.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Zip="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                col="7"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Zip.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Zip="{ value }">
            {{ value | zip }}
          </template>

          <template v-slot:header.Fax="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Fax.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Fax="{ value }">
            {{ value | phone }}
          </template>

          <template v-slot:header.PlannedOpenDate="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.PlannedOpenDate.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.PlannedOpenDate="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.ActualOpenDate="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.ActualOpenDate.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.ActualOpenDate="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.ClosedDate="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.ClosedDate.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.ClosedDate="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.EffectiveDateOfChange="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.EffectiveDateOfChange.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.EffectiveDateOfChange="{ value }">{{
            value | formatDate
          }}</template>

          <template v-slot:header.LinkedStore="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.LinkedStore.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.LinkedStore="{ value }">
            {{ value ? value.toString().padStart(4, '0') : '' }}
          </template>

          <template v-slot:header.Note="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Note.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.County="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.County.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Latitude="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.Latitude.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Longitude="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.Longitude.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.DmaId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DmaId.SearchValue"
                  :items="dmaDataWithBlank"
                  :loading="dmaGetInProgress"
                  :disabled="dmaGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  :item-text="x => (x.Code && x.Code > -1 ? `${x.Code} - ${x.Name}` : '(Blank)')"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DmaId="{ item }">
            {{ item.DmaId !== null ? `${item.DmaCode} - ${item.DmaName}` : '' }}
          </template>

          <template v-slot:header.DistributionCenterId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DistributionCenterId.SearchValue"
                  :items="distributionCenterDataWithBlank"
                  :loading="distributionCenterGetInProgress"
                  :disabled="distributionCenterGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DistributionCenterId="{ value }">
            {{ distributionCenterName(value) }}
          </template>

          <template v-slot:header.DistrictId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DistrictId.SearchValue"
                  :items="districtDataWithBlank"
                  :loading="districtGetInProgress"
                  :disabled="districtGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>
          <template v-slot:item.DistrictId="{ value }">
            {{ districtName(value) }}
          </template>

          <template v-slot:header.DivisionRegionId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DivisionRegionId.SearchValue"
                  :items="divisionRegionsDataWithBlank"
                  :loading="divisionRegionsGetInProgress"
                  :disabled="divisionRegionsGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DivisionRegionId="{ value }">
            {{ divisionRegionName(value) }}
          </template>

          <template v-slot:header.VpStore="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.VpStore.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Director="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Director.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Dtl="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Dtl.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Market="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                col="8"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Market.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Mtl="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                col="8"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.Mtl.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.PriceZoneId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.PriceZoneId.SearchValue"
                  :items="priceZonesDataWithBlank"
                  :loading="priceZoneGetInProgress"
                  :disabled="priceZoneGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.PriceZoneId="{ value }">
            {{ priceZoneName(value) }}
          </template>

          <template v-slot:header.GoEvent2="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.GoEvent2.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.GoEvent2="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.GoWeekend="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.GoWeekend.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.GoWeekend="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.BackRoomSize="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.BackRoomSize.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.CeilingHeight="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.CeilingHeight.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.NumberOfStoreEntrances="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.NumberOfStoreEntrances.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.RackingType="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.RackingType.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.RackingColor="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.RackingColor.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.RackingCount="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.RackingCount.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.RackingLinearFeet="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.RackingLinearFeet.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.SquareFeet="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.SquareFeet.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.Grooming="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Grooming.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Grooming="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.GroomingTables="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.GroomingTables.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.GroomingTubs="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.GroomingTubs.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.DogWash="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DogWash.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DogWash="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.DogWashStations="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="number-filter"
              ></pcl-store-listing-all-header-text>

              <v-row align="end">
                <pcl-number-range
                  v-model="storeListingAllFields.DogWashStations.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-number-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DogWashTubTypeId="{ value }">
            {{ dogWashTubTypeName(value) }}
          </template>

          <template v-slot:header.DogWashTubTypeId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DogWashTubTypeId.SearchValue"
                  :items="dogWashTubDataWithBlank"
                  :loading="dogWashTubTypesGetInProgress"
                  :disabled="dogWashTubTypesGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:header.EnhancedBakery="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.EnhancedBakery.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.EnhancedBakery="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.VipClinics="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.VipClinics.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.VipClinics="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.VipWellnessCenter="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.VipWellnessCenter.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.VipWellnessCenter="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.CommunityRoom="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.CommunityRoom.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.CommunityRoom="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.DogTraining="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DogTraining.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DogTraining="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.SaltWaterFish="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.SaltWaterFish.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.SaltWaterFish="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.Boutique="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Boutique.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Boutique="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.OnlineGrooming="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.OnlineGrooming.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.OnlineGrooming="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.CurbsidePickup="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.CurbsidePickup.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.CurbsidePickup="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.CurbsidePickupStartDate="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.CurbsidePickupStartDate.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.CurbsidePickupStartDate="{ value }">{{
            value | formatDate
          }}</template>

          <template v-slot:header.Delivery="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Delivery.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Delivery="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.DeliveryStartDate="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.DeliveryStartDate.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DeliveryStartDate="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.DeliveryMethodId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DeliveryMethodId.SearchValue"
                  :items="deliveryMethodDataWithBlank"
                  :loading="deliveryMethodsGetInProgress"
                  :disabled="deliveryMethodsGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DeliveryMethodId="{ value }">
            {{ deliveryMethodName(value) }}
          </template>

          <template v-slot:header.SecondaryDeliveryMethodId="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.SecondaryDeliveryMethodId.SearchValue"
                  :items="deliveryMethodDataWithBlank"
                  :loading="deliveryMethodsGetInProgress"
                  :disabled="deliveryMethodsGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="Name"
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.SecondaryDeliveryMethodId="{ value }">
            {{ deliveryMethodName(value) }}
          </template>

          <template v-slot:header.DeliveryAreaMiles="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.DeliveryAreaMiles.SearchValue"
                  :items="deliveryAreaLookupBlank"
                  :loading="deliveryAreaLookupGetInProgress"
                  :disabled="deliveryAreaLookupGetInProgress"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  :item-text="
                    x =>
                      x && x.DeliveryAreaMiles && x.DeliveryAreaMiles > -1
                        ? x.DeliveryAreaMiles
                        : '(Blank)'
                  "
                  :item-value="header.value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.DeliveryAreaMiles="{ value }">
            {{ value }}
          </template>

          <template v-slot:header.Autoship="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Autoship.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Autoship="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.AutoshipStartDate="{ header }">
            <v-col class="date-range-col">
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
                cssClass="date-filter"
              ></pcl-store-listing-all-header-text>

              <v-row class="date-range-row">
                <pcl-date-range
                  v-model="storeListingAllFields.AutoshipStartDate.SearchValue"
                  @change="paramUpdate"
                  :dialogHeader="header.text"
                ></pcl-date-range>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.AutoshipStartDate="{ value }">{{ value | formatDate }}</template>

          <template v-slot:header.Nailbar="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Nailbar.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Nailbar="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.Betta="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Betta.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Betta="{ value }">
            {{ value | bool }}
          </template>
          <template v-slot:header.TropicalFish="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.TropicalFish.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.TropicalFish="{ value }">
            {{ value | bool }}
          </template>
          <template v-slot:header.Reptile="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Reptile.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Reptile="{ value }">
            {{ value | bool }}
          </template>
          <template v-slot:header.Bird="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Bird.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.Bird="{ value }">
            {{ value | bool }}
          </template>
          <template v-slot:header.SmallAnimal="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.SmallAnimal.SearchValue"
                  :items="bools"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  item-text="text"
                  item-value="value"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.SmallAnimal="{ value }">
            {{ value | bool }}
          </template>

          <template v-slot:header.HashSocialMedia="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.HashSocialMedia.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.HashSocialMedia="{ value }">
            {{ value }}
          </template>

          <template v-slot:header.FacebookSocialMedia="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.FacebookSocialMedia.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.FacebookSocialMedia="{ value }">
            <a :href="value" target="_blank">{{ value }}</a>
          </template>

          <template v-slot:header.TwitterSocialMedia="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.TwitterSocialMedia.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.TwitterSocialMedia="{ value }">
            <a :href="value" target="_blank">{{ value }}</a>
          </template>

          <template v-slot:header.InstagramSocialMedia="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.InstagramSocialMedia.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.InstagramSocialMedia="{ value }">
            <a :href="value" target="_blank">{{ value }}</a>
          </template>

          <template v-slot:header.YelpSocialMedia="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.YelpSocialMedia.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.YelpSocialMedia="{ value }">
            <a :href="value" target="_blank">{{ value }}</a>
          </template>

          <template v-slot:header.GoogleSocialMedia="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row>
                <v-text-field
                  v-model="storeListingAllFields.GoogleSocialMedia.SearchValue"
                  clearable
                  @click:clear.stop="paramUpdate"
                  @click.stop
                  v-debounce:300="paramUpdate"
                  prepend-icon="search"
                ></v-text-field>
              </v-row>
            </v-col>
          </template>

          <template v-slot:item.GoogleSocialMedia="{ value }">
            <a :href="value" target="_blank">{{ value }}</a>
          </template>

          <template v-slot:header.Cobrand="{ header }">
            <v-col>
              <pcl-store-listing-all-header-text
                :header="header"
                :options="options"
              ></pcl-store-listing-all-header-text>

              <v-row @click.stop>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="storeListingAllFields.Cobrand.SearchValue"
                  :items="cobrandsWithBlank"
                  @blur="paramUpdate"
                  multiple
                  autocomplete="off"
                  clearable
                >
                </v-autocomplete>
              </v-row>
            </v-col>
          </template>

          <template v-slot:footer="{}">
            <div class="my-3 ml-2">
              {{ storeListingAllData.Results.length }} / {{ storeListingAllData.TotalCount }}
            </div>
          </template>

          <template v-slot:body.append="{}">
            <tr>
              <td
                :colspan="pclListingAllHeaders.headers.length + 10"
                class="ma-0 pa-0"
                style="height: 10px"
              >
                <infinite-loading
                  v-if="
                    storeListingAllData &&
                    storeListingAllData.Results &&
                    storeListingAllData.TotalCount > 0
                  "
                  @infinite="infiniteHandler"
                  :identifier="infiniteId"
                >
                </infinite-loading>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>

      <v-row justify="end">
        <v-btn
          :disabled="exportListingAllInProgress"
          :loading="exportListingAllInProgress"
          @click="exportListingAllData"
          >Export</v-btn
        >
      </v-row>
      <v-row v-if="pclRemovedHeaders && pclRemovedHeaders.length > 0" justify="start">
        <v-col>
          <span>Hidden Columns:</span>
          <v-chip
            v-for="(item, index) in pclRemovedHeadersOrdered"
            v-bind:key="index"
            class="ma-2"
            close
            @click:close="addPclListingAllHeader(item.value)"
          >
            {{ item.text }}
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pm from '@/store/modules/pcl/mutations';
import * as ps from '@/store/modules/pcl/state';
import * as pa from '@/store/modules/pcl/actions';
import {
  StoreListingAllResult,
  StoreListingAllSearchParam,
  StoreListingAllFields,
  StoreListingAllSearchType,
  StoreStatusType,
  DmaResult,
} from '@psp/pogona_pcl_client_js';
import PclDateRange from './PclDateRange.vue';
import PclNumberRange from './PclNumberRange.vue';
import PclStoreListingAllHeaderText from './PclStoreListingAllHeaderText.vue';
import {
  PogonaPclClient,
  StoreStatusResult,
  TimeZoneResult,
  DistributionCenterResult,
  DistrictResult,
  PriceZoneResult,
  DeliveryMethodResult,
} from '@psp/pogona_pcl_client_js';
import * as pcldto from '@psp/pcldto';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {
    PclDateRange,
    PclNumberRange,
    PclStoreListingAllHeaderText,
  },
})
export default class PclStoreListingAllComponent extends Vue {
  public bools: Array<any> = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  public nullableBools: Array<any> = [
    { text: '(Blank)', value: -1 },
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  public cobrandsWithBlank: Array<any> = ['PSP', 'WNW'];

  public allDigitFormattedFields: Array<string> = ['Phone', 'Fax', 'Zip'];
  private infiniteId = +new Date();

  private lastCount = -1;
  private resetComplete = false;

  private isPclWriter = false;

  private options: any = {};

  @pclModule.State(ps.storeListingAllData) storeListingAllData!: StoreListingAllResult;
  @pclModule.State(ps.storeListingAllParams) storeListingAllParams!: ps.StoreListingAllSearch;
  @pclModule.State(ps.storeListingAllDataGetInProgress) storeListingAllDataGetInProgress!: boolean;
  @pclModule.State(ps.storeListingAllFields) storeListingAllFields!: StoreListingAllFields;
  @pclModule.State(ps.pclListingAllHeaders) pclListingAllHeaders!: ps.StoreListingAllHeaders;
  @pclModule.State(ps.pclRemovedHeaders) pclRemovedHeaders!: Array<any>;
  @pclModule.State(ps.pclListingAllOptions) pclListingAllOptions!: any;
  @pclModule.State(ps.storeStatusIdTypesData) storeStatusIdTypesData!: any;
  @pclModule.State(ps.timeZonesData) timeZonesData!: any;
  @pclModule.State(ps.priceZonesData) priceZonesData!: any;
  @pclModule.State(ps.dmaData) dmaData!: any;
  @pclModule.State(ps.districtData) districtData!: any;
  @pclModule.State(ps.divisionRegionsData) divisionRegionsData!: any;
  @pclModule.State(ps.deliveryMethodData) deliveryMethodData!: any;
  @pclModule.State(ps.distributionCenterData) distributionCenterData!: any;
  @pclModule.State(ps.storeStatusesGetInProgress) storeStatusesGetInProgress!: boolean;
  @pclModule.State(ps.timeZoneGetInProgress) timeZoneGetInProgress!: boolean;
  @pclModule.State(ps.priceZoneGetInProgress) priceZoneGetInProgress!: boolean;
  @pclModule.State(ps.dmaGetInProgress) dmaGetInProgress!: boolean;
  @pclModule.State(ps.districtGetInProgress) districtGetInProgress!: boolean;
  @pclModule.State(ps.divisionRegionsGetInProgress) divisionRegionsGetInProgress!: boolean;
  @pclModule.State(ps.deliveryMethodsGetInProgress) deliveryMethodsGetInProgress!: boolean;
  @pclModule.State(ps.exportListingAllInProgress) exportListingAllInProgress!: boolean;
  @pclModule.State(ps.distributionCenterGetInProgress) distributionCenterGetInProgress!: boolean;
  @pclModule.State(ps.pclListingAllLoaded) pclListingAllLoaded!: boolean;
  @pclModule.State(ps.deliveryAreaLookupGetInProgress) deliveryAreaLookupGetInProgress!: boolean;
  @pclModule.State(ps.deliveryAreaLookup) deliveryAreaLookup!: any;
  @pclModule.State(ps.dogWashTubTypes) dogWashTubTypes!: any;
  @pclModule.State(ps.dogWashTubTypesGetInProgress) dogWashTubTypesGetInProgress!: any;

  @pclModule.State(ps.client) client!: PogonaPclClient;

  @pclModule.Mutation(pm.setStoreListingAllParams) setStoreListingAllParams!: (
    payload: any,
  ) => void;
  @pclModule.Mutation(pm.setStoreListingAllData) setStoreListingAllData!: (
    payload: StoreListingAllResult,
  ) => void;
  @pclModule.Mutation(pm.incrementStoreListingAllSkip) incrementStoreListingAllSkip!: (
    payload: any,
  ) => void;
  @pclModule.Mutation(pm.setStoreListingAllSkip) setStoreListingAllSkip!: (payload: any) => void;

  @pclModule.Mutation(pm.removePclListingAllHeader) removePclListingAllHeader!: (
    payload: string,
  ) => void;
  @pclModule.Mutation(pm.addPclListingAllHeader) addPclListingAllHeader!: (payload: string) => void;
  @pclModule.Mutation(pm.resetPclListingAllHeader) resetPclListingAllHeader!: () => void;
  @pclModule.Mutation(pm.setStoreListingAllOrderBy) setStoreListingAllOrderBy!: (
    payload: Array<string>,
  ) => void;
  @pclModule.Mutation(pm.resetStoreListingAllParams) resetStoreListingAllParams!: () => void;
  @pclModule.Mutation(pm.resetStoreListingAllFields) resetStoreListingAllFields!: () => void;
  @pclModule.Mutation(pm.setPclListingAllLoaded) setPclListingAllLoaded!: (
    payload: boolean,
  ) => void;
  @pclModule.Mutation(pm.setPclListingAllOptions) setPclListingAllOptions!: (payload: any) => void;

  @pclModule.Action(pa.getStoreListingAll)
  getStoreListingAll!: (searchParams: ps.StoreListingAllSearch) => Promise<any>;

  @pclModule.Action(pa.getStoreStatuses) getStoreStatuses!: () => Promise<any>;
  @pclModule.Action(pa.getTimeZones) getTimeZones!: () => Promise<any>;
  @pclModule.Action(pa.getDmas) getDmas!: () => Promise<any>;
  @pclModule.Action(pa.getDistricts) getDistricts!: () => Promise<any>;
  @pclModule.Action(pa.getDivisionRegions) getDivisionRegions!: () => Promise<any>;
  @pclModule.Action(pa.getPriceZones) getPriceZones!: () => Promise<any>;
  @pclModule.Action(pa.getDeliveryMethods) getDeliveryMethods!: () => Promise<any>;
  @pclModule.Action(pa.exportListingAll)
  exportListingAll!: (payload: ps.PclStoreExportPayload) => Promise<any>;
  @pclModule.Action(pa.getDistributionCenters) getDistributionCenters!: () => Promise<any>;
  @pclModule.Action(pa.getDeliveryAreaLookup) getDeliveryAreaLookup!: () => Promise<any>;
  @pclModule.Action(pa.getDogWashTubTypes) getDogWashTubTypes!: () => Promise<any>;

  public async mounted() {
    this.isPclWriter = await this.$authApi.roleHasRights('PCLWriter');

    // we always want to get the latest data
    if (this.pclListingAllLoaded === true) {
      await this.getStoreListingAll(this.storeListingAllParams);
    }
  }

  public async reset() {
    this.options = this.pclListingAllOptions;
    this.resetComplete = false;

    await Promise.all([
      this.getStoreStatuses(),
      this.getTimeZones(),
      this.getDmas(),
      this.getDistricts(),
      this.getDivisionRegions(),
      this.getPriceZones(),
      this.getDeliveryMethods(),
      this.getDistributionCenters(),
      this.getDeliveryAreaLookup(),
      this.getDogWashTubTypes(),
    ]);

    await this.clearAllFilters(false);
    this.setPclListingAllLoaded(true);
    this.resetComplete = true;
  }

  public async exportListingAllData() {
    const headersToExport = [] as Array<any>;

    for (let i = 0; i < this.pclListingAllHeaders.headers.length; i++) {
      const removedIx = this.pclRemovedHeaders.findIndex(
        x => x.value === this.pclListingAllHeaders.headers[i].value,
      );
      if (removedIx === -1) {
        headersToExport.push(Object.assign({}, this.pclListingAllHeaders.headers[i]));
      }
    }

    await this.exportListingAll(
      new ps.PclStoreExportPayload(this.storeListingAllParams, headersToExport),
    );
  }

  public updateSortBy(event) {
    if (this.resetComplete === true) {
      let orderByArray: Array<string> = [] as Array<string>;
      if (event.sortBy && event.sortBy.length > 0) {
        for (const oix in event.sortBy) {
          const asc =
            event.sortDesc && event.sortDesc.length > oix && event.sortDesc[oix] === false;
          orderByArray.push(`${event.sortBy[oix]} ${asc ? 'asc' : 'desc'}`);
        }
      }

      this.infiniteId = this.infiniteId + 1;
      this.setStoreListingAllData(new StoreListingAllResult());
      this.setStoreListingAllSkip(0);
      this.setStoreListingAllOrderBy(orderByArray);
      this.paramUpdate();
    }
  }

  public hideHeader(col: string) {
    this.removePclListingAllHeader(col);
  }

  public storeStatusName(storeStatusId) {
    const status = this.storeStatusIdTypesData.filter(x => x.StoreStatusId === storeStatusId);
    if (status && status.length === 1 && status[0].StoreStatusId) {
      return status[0].Name;
    }
    return '';
  }

  public timeZoneCode(timeZoneId) {
    const timeZone = this.timeZonesData.filter(x => x.TimeZoneId === timeZoneId);
    if (timeZone && timeZone.length === 1) {
      return timeZone[0].Code;
    }
    return '';
  }

  public distributionCenterName(distributionCenterId) {
    const dist = this.distributionCenterData.filter(
      x => x.DistributionCenterId === distributionCenterId,
    );
    if (dist && dist.length === 1) {
      return dist[0].Name;
    }
    return '';
  }

  public districtName(districtId) {
    const district = this.districtData.filter(x => x.DistrictId === districtId);
    if (district && district.length === 1) {
      return district[0].Name;
    }
    return '';
  }

  public divisionRegionName(divisionRegionId) {
    const divisionRegion = this.divisionRegionsData.filter(
      x => x.DivisionRegionId === divisionRegionId,
    );
    if (divisionRegion && divisionRegion.length === 1) {
      return divisionRegion[0].Name;
    }
    return '';
  }

  public priceZoneName(priceZoneId) {
    const priceZone = this.priceZonesData.filter(x => x.PriceZoneId === priceZoneId);
    if (priceZone && priceZone.length === 1) {
      return priceZone[0].Name;
    }
    return '';
  }

  public deliveryMethodName(deliveryMethodId) {
    const deliveryMethod = this.deliveryMethodData.filter(
      x => x.DeliveryMethodId === deliveryMethodId,
    );
    if (deliveryMethod && deliveryMethod.length === 1) {
      return deliveryMethod[0].Name;
    }
    return '';
  }

  public dogWashTubTypeName(dogWashTubTypeId) {
    const dogWashTubType = this.dogWashTubTypes.filter(
      x => x.DogWashTubTypeId === dogWashTubTypeId,
    );
    if (dogWashTubType && dogWashTubType.length === 1) {
      return dogWashTubType[0].Name;
    }
    return '';
  }

  public async resetFull() {
    this.resetPclListingAllHeader();
    await this.clearAllFilters(true);
  }

  public async clearAllFilters(forceClear: boolean) {
    if (forceClear === true || this.pclListingAllLoaded === false) {
      this.infiniteId = this.infiniteId + 1;
      this.resetStoreListingAllParams();
      this.resetStoreListingAllFields();

      const searchParams = [] as StoreListingAllSearchParam[];
      searchParams.push(
        new StoreListingAllSearchParam(
          'StoreStatusId',
          [StoreStatusType.Open, StoreStatusType.PreOpen] as Array<number>,
          StoreListingAllSearchType.In,
        ),
      );
      this.setStoreListingAllParams(searchParams);
      this.setStoreListingAllData(new StoreListingAllResult());
      this.setStoreListingAllSkip(0);
      this.setStoreListingAllOrderBy(['StoreNumber asc']);

      if (this.storeListingAllFields.StoreStatusId) {
        this.storeListingAllFields.StoreStatusId.SearchValue = [
          StoreStatusType.Open,
          StoreStatusType.PreOpen,
        ] as Array<number>;
      }

      await this.getStoreListingAll(this.storeListingAllParams);
    }
  }

  public paramUpdate() {
    setTimeout(async () => {
      this.infiniteId = this.infiniteId + 1;

      const params = [] as StoreListingAllSearchParam[];
      const paramsKeys = Object.keys(this.storeListingAllFields);

      for (const key of paramsKeys) {
        const param: StoreListingAllSearchParam = this.storeListingAllFields[key];
        if (param && param.SearchValue) {
          if (this.allDigitFormattedFields.indexOf(key) > -1) {
            param.SearchValue = (param.SearchValue as string).replace(/[^\d]+/g, '').trim();
          } else if (key === 'LinkedStore') {
            param.SearchValue = parseInt(param.SearchValue as string);
          }
          params.push(param);
        }
      }

      this.setStoreListingAllData(new StoreListingAllResult());
      this.setStoreListingAllSkip(0);
      this.setStoreListingAllParams(params);
      await this.getStoreListingAll(this.storeListingAllParams);
    }, 0);
  }

  public infiniteHandler($state: any) {
    this.getStoreListingAll(this.storeListingAllParams)
      .then(() => {
        if (
          this.storeListingAllData.Results.length % this.storeListingAllParams.top === 0 &&
          this.storeListingAllData.Results.length !== this.lastCount
        ) {
          $state.loaded();
          this.incrementStoreListingAllSkip(50);
        } else {
          $state.complete();
        }
        this.lastCount = this.storeListingAllData.Results.length;
      })
      .catch(() => {
        $state.complete();
      });
  }

  public get pclRemovedHeadersOrdered() {
    return this.pclRemovedHeaders.sort((a, b) => (a.text > b.text && 1) || -1);
  }

  // comboboxes with blank first option
  private get storeStatusIdTypesDataWithBlank() {
    const storeStatuses = Object.assign([], this.storeStatusIdTypesData);
    storeStatuses.unshift(new StoreStatusResult(-1, '(Blank)'));
    return storeStatuses;
  }

  private get timeZonesDataWithBlank() {
    const timeZonesData = Object.assign([], this.timeZonesData);
    timeZonesData.unshift(new TimeZoneResult(-1, '(Blank)'));
    return timeZonesData;
  }

  private get dmaDataWithBlank() {
    const dmaData = Object.assign([], this.dmaData);
    dmaData.unshift(new DmaResult(-1, -1, '(Blank)'));
    return dmaData;
  }

  private get distributionCenterDataWithBlank() {
    const distributionCenterData = Object.assign([], this.distributionCenterData);
    distributionCenterData.unshift(new DistributionCenterResult(-1, '(Blank)'));
    return distributionCenterData;
  }

  private get districtDataWithBlank() {
    const districtData = Object.assign([], this.districtData);
    districtData.unshift(new DistrictResult(-1, -1, '(Blank)'));
    return districtData;
  }

  private get divisionRegionsDataWithBlank() {
    const divisionRegionsData = Object.assign([], this.divisionRegionsData);
    divisionRegionsData.unshift(new pcldto.pclDivisionRegion(-1, '(Blank)'));
    return divisionRegionsData;
  }

  private get priceZonesDataWithBlank() {
    const priceZonesData = Object.assign([], this.priceZonesData);
    priceZonesData.unshift(new PriceZoneResult(-1, '(Blank)'));
    return priceZonesData;
  }
  private get deliveryMethodDataWithBlank() {
    const deliveryMethodData = Object.assign([], this.deliveryMethodData);
    deliveryMethodData.unshift(new DeliveryMethodResult('(Blank)', -1));
    return deliveryMethodData;
  }

  private get dogWashTubDataWithBlank() {
    const dogWashTubTypesData = Object.assign([], this.dogWashTubTypes);
    dogWashTubTypesData.unshift(new DeliveryMethodResult('(Blank)', -1));
    return dogWashTubTypesData;
  }

  private get deliveryAreaLookupBlank() {
    if (this.deliveryAreaLookup && this.deliveryAreaLookup.length > 0) {
      const deliveryAreaLookup = Object.assign([], this.deliveryAreaLookup);
      (deliveryAreaLookup[0] as pcldto.PclDeliveryAreaLookup).DeliveryAreaMiles = -1;
      return deliveryAreaLookup;
    }
    return this.deliveryAreaLookup;
  }

  @Watch('options') optionsChanged(newVal: any) {
    this.setPclListingAllOptions(newVal);
  }
}
</script>

<style lang="scss">
#listAllTable {
  overflow: inherit;

  thead i.v-data-table-header__icon {
    display: none;
  }

  thead th {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .header-text-hide {
    background-color: rgb(118, 118, 118, 0.3);
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    .float-right {
      text-align: right;
    }
  }

  .store-number-col {
    margin-top: -15px;
  }

  .store-number-filter {
    margin-top: 7px !important;
  }

  .header-text-hide.store-number {
    margin-top: -12px;
    margin-bottom: -2px !important;
    padding: 3px 0;
  }

  .header-text-hide.date-filter {
    margin-top: 1px;
  }

  .header-text-hide.number-filter {
    margin-top: 0px;
    margin-bottom: -36px !important;
  }
}

#listAllTable td:nth-child(1),
#listAllTable th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 4;
  border-right: 1px solid rgb(118, 118, 118);
  background-color: white;
}
#listAllTable td,
#listAllTable th {
  border-right: 1px solid rgb(118, 118, 118);
}

#listAllTable th:nth-child(1) {
  z-index: 5;
}

#listAllTable.scrollable .v-data-table__wrapper {
  max-height: 100%;
}

.dummy-space {
  height: 56px;
}

#listAllTable .date-range-col {
  margin-top: -24px !important;
}
#listAllTable .date-range-row > div:first-child {
  width: 100%;
}

.table-row {
  width: 100%;
}
</style>
