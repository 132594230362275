<template>
  <div v-if="!menuItem.hide">
    <v-divider class="ma-0" v-if="menuItem.seperateTop && menuLinkHasRights"></v-divider>
    <v-list-item
      v-if="menuLinkHasRights && (!menuEntries || menuEntries.length === 0)"
      :to="menuItem.route"
    >
      <v-list-item-action>
        <v-icon>{{ menuItem.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
    </v-list-item>

    <v-list-group
      v-else-if="menuLinkHasRights && menuEntries && menuEntries.length > 0"
      :prepend-icon="menuItem.icon"
      append-icon="arrow_drop_down"
    >
      <template v-slot:activator>
        <v-list-item class="mt-0 ml-0 mr-6">
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item
        v-for="(entry, eix) in menuEntries"
        :key="menuItem.title + eix"
        :to="entry.route"
      >
        <v-icon class="ml-6">{{ entry.icon }}</v-icon>
        <v-list-item-title class="ml-6">{{ entry.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider class="ma-0" v-if="menuItem.seperate && menuLinkHasRights"></v-divider>
  </div>
</template>

<script>
import Vue from 'vue';
import menuMixins from '@/mixins/menu-mixins';

export default {
  props: {
    menuItem: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      menuLinkHasRights: false,
      menuEntries: null,
    };
  },
  async created() {
    this.menuEntries = await this.filteredEntries();
    // eslint-disable-next-line
    const local = this;
    this.userHasRightsToMenuItem(this.menuItem).then(allowed => {
      local.menuLinkHasRights = allowed;
    });
  },
  methods: {
    async filteredEntries() {
      // eslint-disable-next-line
      const local = this;
      if (this.menuItem && this.menuItem.entries) {
        const entries = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of this.menuItem.entries) {
          // eslint-disable-next-line no-await-in-loop
          const allowed = await local.userHasRightsToMenuItem(entry);
          if (
            allowed === true &&
            (entry.hide === null || entry.hide === undefined || entry.hide === false)
          ) {
            entries.push(entry);
          }
        }

        // if there are zero entries after hiding, then we should hide the whole group
        if (this.menuItem.entries.length > 0 && entries.length === 0) {
          Vue.set(this.menuItem, 'hide', true);
        }
        return entries;
      }
      return [];
    },
  },
  mixins: [menuMixins],
  computed: {
    menuItemIsLink() {
      return this.menuItem && !(this.menuItem.entries && this.menuItem.entries.length > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer .v-list .v-divider {
  width: 100%;
}
.v-list-group .v-list-item {
  padding: 0;
  margin: 0;
}
.v-list-group__header .v-list-item {
  padding: 0;
}
</style>
