const initState = (): unknown => {
  return {
    labelItemsAll: null,
    selectedItemStatus: null,
    labelItemsAllSearch: null,
  };
};

export { initState };

export default {
  labelItemsAll: null,
  selectedItemStatus: null,
  labelItemsAllSearch: null,
};
