import * as parseHttpHeader from 'parse-http-header';
import { AxiosResponse } from 'axios';

export default class FileDownloadHelper {
  static getDownloadFileName(response: AxiosResponse, fallbackName: string): string {
    let fileName = fallbackName;
    if (response?.headers['content-disposition']) {
      const parsedHeader = parseHttpHeader(response.headers['content-disposition']);
      if (parsedHeader.filename) {
        fileName = parsedHeader.filename.replace(/"/g, '');
      }
    }
    return fileName;
  }
}
