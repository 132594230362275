<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="dialogClick"
          @click.stop=""
          v-bind:small="small"
          style="
            color: white;
            border-width: 1px;
            border-style: solid;
            border-color: white;
            background-color: #4caf50 !important;
          "
        >
          {{ buttonText }}
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Manage Market</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedMarketId"
                  label="Select Market"
                  :items="marketData"
                  item-text="Name"
                  item-value="MarketId"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="District"
                  v-model="selectedDistrictId"
                  :items="allDistricts"
                  item-text="Name"
                  item-value="DistrictId"
                  :error-messages="marketErrorMessages.DistrictId"
                  @blur="validateMarket('DistrictId')"
                  @change="validateMarket('DistrictId')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="currentMarket.Name"
                  label="Market Name"
                  :error-messages="marketErrorMessages.Name"
                  @blur="validateMarket('Name')"
                  @change="validateMarket('Name')"
                  counter="50"
                  maxlength="50"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="marketSelections.selectedMarketLeader"
                  :items="employeeDropDownData"
                  label="Market Team Leader"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-dialog v-if="currentMarket.Stores.length > 0" v-model="storesDialog" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-1"
                text
                v-bind="attrs"
                v-on="on"
                @click="storesDialog = true"
                >Show Stores</v-btn
              >
            </template>
            <v-card>
              <v-card-title>Stores in Market {{ currentMarket.Name }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row v-for="s in currentMarket.Stores" :key="s.StoreId">
                    {{ s.StoreId }} - {{ s.Name }}
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="storesDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="blue darken-1" text @click="showConfirmDelete = true" v-if="deleteEnabled"
            >Delete</v-btn
          >
          <v-btn color="blue darken-1" text @click="closeClicked">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveClicked" :loading="loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmDelete" max-width="320px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete market {{ currentMarket.Name }}
        </v-card-title>
        <v-card-actions>
          <v-btn @click="showConfirmDelete = false">No</v-btn>
          <v-btn @click="deleteConfirmed">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <p v-for="e in validationErrors" v-bind:key="e">
        {{ e }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pcldto from '@psp/pcldto';
import * as pclclient from '@psp/pogona_pcl_client_js';
import * as yup from 'yup';
import { mixins } from 'vue-class-component';
import DivisionRegionEditMixin from './DivisionRegionEditMixin';
import { MarketResult } from '@psp/pogona_pcl_client_js';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {},
})
export default class ManageMarketPopup extends mixins(DivisionRegionEditMixin) {
  @Prop({ default: 0 }) marketId!: number;
  @Prop({ default: 0 }) districtId!: number;
  @Prop({ default: 0 }) divisionRegionId!: number;
  @Prop({ default: null }) small!: string | null;
  @Prop({ default: 'Edit Market' }) buttonText!: string;

  @pclModule.Action(pa.getDistricts) getDistricts!: () => Promise<unknown>;
  @pclModule.Action(pa.getMarkets) getMarkets!: () => Promise<unknown>;
  @pclModule.Action(pa.getMarketResults) getMarketResults!: (odata: ODataProps) => Promise<unknown>;
  @pclModule.State(ps.districtData) allDistricts!: pclclient.DistrictResult[];
  @pclModule.State(ps.marketData) marketData!: MarketResult[];
  @pclModule.State(ps.marketResults) marketResults!: pcldto.pclMarket[];

  private originalMarket: pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market | null = null;
  private dialog = false;
  private storesDialog = false;
  private showConfirmDelete = false;
  private confirmDelete = false;
  private selectedDistrictId = 0;
  private selectedMarketId = 0;
  private marketErrorMessages: ps.PclMarketMessages = new ps.PclMarketMessages();
  private currentMarket: pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market = new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market(
    0,
    0,
    null,
    '',
    [],
  );
  private marketSelections: ps.MarketSelections = new ps.MarketSelections(0, 0, '', 0);

  private marketSchema: any = yup.object().shape({
    Name: yup.string().required().test('unique', this.testNameUnique),
    DistrictId: yup.string().required(),
    MarketId: yup.string().required(),
  });

  public async testNameUnique(
    value: string | undefined,
    context: yup.TestContext<Record<string, any>>,
  ): Promise<boolean | yup.ValidationError> {
    const check = this.originalMarket?.MarketId === 0 || value !== this.originalMarket?.Name;
    if (check) {
      let odata = new ODataProps();
      odata.filter = f().eq('Name', value).toString();
      await this.getMarketResults(odata);
    }
    return !check || this.$store.state.pcl.marketResults.length === 0
      ? true
      : context.createError({ message: 'The market name already exists' });
  }

  private closeDialog() {
    this.clear();
    this.deleteEntities = [];
    this.dialog = false;
  }

  private closeClicked() {
    this.closeDialog();
  }

  private dialogClick() {
    this.loading = true;
    this.getEmployees().then(() => (this.loading = false));
  }

  get deleteEnabled(): boolean {
    return this.currentMarket.MarketId !== 0 && this.currentMarket.Stores.length === 0;
  }

  private processMarket(): boolean {
    let success = true;
    const district = this.dr.Districts.find(d => d.DistrictId === this.selectedDistrictId);
    let market = district?.Markets.find(m => m.MarketId === this.currentMarket.MarketId);
    if (district && market) {
      market = this.currentMarket;
    } else if (district) {
      // Check for move of district within division/region
      const moved = this.dr.Districts.find(
        d =>
          d.DistrictId !== district.DistrictId &&
          d.Markets.some(m => m.MarketId === this.currentMarket.MarketId),
      );
      if (moved) {
        moved.Markets.splice(
          moved.Markets.findIndex(m => m.MarketId === this.currentMarket.MarketId),
          1,
        );
      }
      district.Markets.push(this.currentMarket);
    } else {
      success = false;
      this.showSnackbarMessage(
        `processMarket district not found ${this.selectedDistrictId}`,
        'error',
      );
    }

    return success;
  }

  private saveClicked() {
    this.marketSchema
      .validate(this.currentMarket)
      .then(() => {
        this.mapUiToMarket();
        let save = true;
        if (this.deleteEntities.length === 0) {
          save = this.processMarket();
        }
        if (save) {
          this.save('Market')
            .then(() => {
              this.closeDialog();
              this.$emit('save-complete');
            })
            .finally(() => (this.loading = false));
        }
      })
      .catch(err => {
        this.marketErrorMessages[err.path] = err.errors;
        this.showSnackbarMessage('Cannot save market, please fix errors', 'error');
        throw err;
      });
  }

  private deleteConfirmed() {
    this.showConfirmDelete = false;
    this.removeMarket(this.currentMarket);
    this.saveClicked();
  }

  clear() {
    this.selectedMarketId = 0;
    this.currentMarket = new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market(
      0,
      0,
      null,
      '',
      [],
    );
  }

  mapUiToMarket() {
    if (this.marketSelections.selectedMarketLeader === 0) {
      this.currentMarket.MarketTeamLeaderRole = null;
    } else {
      if (
        this.marketSelections.selectedMarketLeader !==
        this.currentMarket.MarketTeamLeaderRole?.EmployeeId
      ) {
        this.currentMarket.MarketTeamLeaderRole = new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.MarketTypes.MarketTeamLeaderRolepclMarketTeamLeaderRole(
          null,
          this.marketSelections.selectedMarketLeader,
          this.currentMarket.MarketId,
          0,
          pclclient.RoleName.MarketTeamLeader,
        );
      }
    }
    if (this.selectedMarketId === 0) {
      this.currentMarket.DistrictId = this.selectedDistrictId;
    }
  }

  mapMarketToUi() {
    this.marketSelections.marketId = this.currentMarket.MarketId;
    this.marketSelections.districtId = this.currentMarket.DistrictId;
    this.marketSelections.name = this.currentMarket.Name;
    this.marketSelections.selectedMarketLeader =
      this.currentMarket.MarketTeamLeaderRole === null
        ? 0
        : this.currentMarket.MarketTeamLeaderRole.EmployeeId;
  }

  mapDrMarketToCurrentMarket() {
    const marketData = this.marketData.find(m => m.MarketId === this.selectedMarketId);
    if (marketData) {
      const market = this.dr.Districts.find(
        d => d.DistrictId === marketData.DistrictId,
      )?.Markets.find(m => m.MarketId === this.selectedMarketId);

      if (market) {
        this.currentMarket = market;
      } else {
        this.clear();
      }
      this.originalMarket = { ...this.currentMarket };
      this.marketErrorMessages = new ps.PclMarketMessages();
    } else {
      this.showSnackbarMessage(
        `mapDrMarketToCurrentMarket market not found ${this.selectedMarketId}`,
        'error',
      );
    }
  }

  @Watch('selectedDistrictId') selectedDistrictIdChanged(next: number): void {
    if (next === 0) {
      this.loading = true;
      this.loadDivisionRegion(0).finally(() => (this.loading = false));
    } else {
      const district = this.allDistricts.find(d => d.DistrictId === next);
      if (district) {
        if (this.dr.DivisionRegionId !== district.DivisionRegionId) {
          this.loading = true;
          this.loadDivisionRegion(district.DivisionRegionId).finally(() => (this.loading = false));
        }
      } else {
        this.showSnackbarMessage(`selectedDistrictIdChanged district not found ${next}`, 'error');
      }
    }
  }

  @Watch('selectedMarketId') selectedMarketIdChanged(next: number): void {
    if (next === 0) {
      this.loadDivisionRegion(0).then(() => {
        this.mapDrMarketToCurrentMarket();
        this.mapMarketToUi();
        this.selectedDistrictId = 0;
      });
    } else {
      const market = this.marketData.find(m => m.MarketId === next);
      const district = this.allDistricts.find(d => d.DistrictId === market?.DistrictId);
      if (market && district) {
        this.loadDivisionRegion(district.DivisionRegionId)
          .then(() => {
            this.mapDrMarketToCurrentMarket();
            this.mapMarketToUi();
            this.selectedDistrictId = district.DistrictId;
          })
          .catch(err =>
            this.showSnackbarMessage(
              `Error loading Division/Region ${district.DivisionRegionId} err: ${err}`,
              'error',
            ),
          )
          .finally(() => (this.loading = false));
      } else {
        this.showSnackbarMessage(
          `selectedMarketIdChanged market or district not found ${next}`,
          'error',
        );
      }
    }
  }

  @Watch('dialog') dialogChanged(next: boolean): void {
    if (next) {
      this.initialize();
    }
  }

  private validateMarket(field: any): any {
    this.marketErrorMessages[field] = [];
    this.marketSchema
      .validateAt(field, this.currentMarket)
      .catch(err => (this.marketErrorMessages[field] = err.errors));
  }

  private initialize() {
    this.loading = true;
    this.loadDivisionRegion(this.divisionRegionId)
      .then(() => {
        this.getDistricts().then(() => {
          this.getMarkets().then(() => {
            this.marketData.unshift(new pclclient.MarketResult(0, 0, '(Add New)'));
            this.selectedMarketId = this.marketId;
            this.selectedDistrictId = this.districtId;
          });
        });
      })
      .finally(() => (this.loading = false));
  }
}
</script>
