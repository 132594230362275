const initState = (): unknown => {
  return {
    batchInfoItems: {},
    ingredientItems: null,
    createdBatchInfo: null,
    ingredientItemSearch: null,
    selectedItems: [],
    trackedDataSelectedCount: 0,
    selectedStore: null,
    allItems: false,
  };
};

export { initState };

export default {
  batchInfoItems: {},
  ingredientItems: null,
  createdBatchInfo: null,
  ingredientItemSearch: null,
  selectedItems: [],
  trackedDataSelectedCount: 0,
  selectedStore: null,
  allItems: false,
};
