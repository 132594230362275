<template>
  <core-view-template title="New Item Request">
    <v-container>
      <v-row v-if="!created && !createdMulti">
        <edit-new-item-request
          :IsNew="true"
          @request-data-multi="requestDataMulti"
          :Processing.sync="processing"
        />
      </v-row>
      <v-row v-if="created"> New item request created for id: {{ this.requestId }} </v-row>
      <v-row v-if="created">
        <v-btn @click="newItemClick">New Item Request</v-btn>
      </v-row>
      <v-row v-if="createdMulti"> New item requests created.</v-row>
      <v-row v-if="createdMulti">
        <router-link :to="viewItemsLink">View Requests</router-link>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditNewItemRequest from '@/components/vendorportal/EditNewItemRequest.vue';
import { convertBatchType, extractNewItemRequest, isOk } from '@/utils/FableUtils.fs.js';
import { initBatch } from '@/utils/DomainExports.fs.js';
import NewRequestMixins from '@/mixins/vendor/newItemRequestMixins';
import { BatchType } from '@/utils/VendorDomainTypes';

const vpModule = namespace('vendorportal');

@Component({
  components: { EditNewItemRequest },
})
export default class NewItemRequestHome extends NewRequestMixins {
  @vpModule.Action('createNewItemRequest') createNewItemRequest!: (
    NewItemRequestData,
  ) => Promise<unknown>;
  @vpModule.Action('generateAggregateId') generateAggregateId!: () => Promise<string>;
  @vpModule.Action('createBatch') createBatch!: (batch: any) => Promise<unknown>;

  private created = false;
  private createdMulti = false;
  private processing = false;
  private requestId = '';

  private get viewItemsLink(): any {
    return {
      name: 'vendorportal_batchsearch',
    };
  }

  private newItemClick() {
    this.created = false;
    this.createdMulti = false;
  }

  private async requestData(
    data: any,
    isMulti: boolean | null | undefined,
    isLast: boolean | null | undefined,
  ) {
    const request = this.createRequestDataPayload(data, isMulti, isLast);
    this.processing = true;
    try {
      const response = await this.createNewItemRequest(request);

      if (isOk(response)) {
        const request = extractNewItemRequest(response);
        this.requestId = request.Agg.Id;

        if (isMulti !== true) {
          this.created = true;
        }
      }
    } catch (err) {
      this.$emit('snackbar-error', {
        text: 'Error creating new item request',
        err,
        id: '3263025c-fd3a-485f-9031-e8eb25aadaa6',
      });
      if (isMulti !== true) {
        this.processing = false;
      }
    }
    if (isMulti !== true) {
      this.processing = false;
    }
  }

  private async requestDataMulti(data: any[]) {
    try {
      let rix = 0;
      const batch = initBatch();
      for (const request of data) {
        request.AggregateId = await this.generateAggregateId();
        request.id = request.AggregateId;
        request.requestType = 'NewItem';
        request.batchId = batch.BatchId;

        await this.requestData(request, true, rix === data.length - 1);
        rix++;
      }
      await this.createBatch({
        ...batch,
        BatchType: convertBatchType(BatchType.NewItemBatch),
        CategoryManagerName: data[0].categoryManagerName,
      });
    } catch (err) {
      this.$emit('snackbar-error', {
        text: 'Error creating new item request',
        err,
        id: 'ba19370a-6f99-4e59-990f-ceff86a488e5',
      });
    }
    this.createdMulti = true;
    this.processing = false;
  }
}
</script>
