<template>
  <core-view-template title="D365 Discount Worksheet Upload">
    <core-view-section title="Upload">
      <v-row>
        <v-col cols="12" xl="8" lg="8" md="8">
          <v-file-input
            prepend-icon="image"
            label="Click Here to Add File"
            v-model="localFile"
            accept=".xlsx"
          ></v-file-input>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4">
          <v-btn
            @click="onUpload"
            :disabled="disabledUpload"
            :loading="d365WorksheetUploadInProgress"
            >Upload</v-btn
          >
          <v-btn @click="onClearIgnoredItemFile" :disabled="disabledClear">Clear</v-btn>
        </v-col>
        <v-col>
          <v-row class="mb-0 pb-0 ml-1">
            <v-col class="mb-0 py-0">
              <a
                :href="`${$storageHost}/discountsamples/simple_discount_sample.xlsx?_=${new Date().getTime()}`"
                >Download <b>Simple Discount</b> Sample to Upload</a
              >
            </v-col>
          </v-row>
          <v-row class="mb-0 pb-0 ml-1 mt-0">
            <v-col class="mt-0 py-0">
              <a
                :href="`${$storageHost}/discountsamples/mix_and_match_sample.xlsx?_=${new Date().getTime()}`"
                >Download <b>Mix and Match Discount</b> Sample to Upload</a
              >
            </v-col>
          </v-row>
          <v-row class="mb-2 mt-0 pt-0 ml-1">
            <v-col class="mt-0 pt-0">
              <a
                :href="`${$storageHost}/discountsamples/threshold_sample.xlsx?_=${new Date().getTime()}`"
                >Download <b>Threshold</b> Sample to Upload</a
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="d365ImportValidatorResults.length > 0">
        <v-col class="text--black">
          <v-card>
            <v-card-title class="text--black">File validation issue(s)</v-card-title>
            <v-card-text>
              <ul>
                <li v-for="(item, index) in d365ImportValidatorResults" :key="index">
                  {{ item.message }}{{ item.rowNumber > 0 ? ` Row: ${item.rowNumber}` : ''
                  }}{{ item.colNumber > 0 ? ` Column: ${item.colNumber}` : '' }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script setup lang="ts">
import { computed, ref, defineComponent } from 'vue';
import Vue from 'vue';
import { useMerchStore } from '@/stores/merchstore';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import uuid from 'uuid';
import AdalHelpers from '@/utils/AdalHelpers';
import FileSaver from 'file-saver';

const emit = defineEmits(['snackbar-error']);

defineComponent({
  name: 'D365DiscountWorksheet',
});

const localFile: File | null = ref<File | null>(null);
let ws: PogonaWebSocket | null = null;

const merchStore = useMerchStore();

const d365WorksheetUploadInProgress = computed(() => {
  return merchStore.d365WorksheetUploadInProgress;
});

const d365ImportValidatorResults = computed(() => {
  return merchStore.d365ImportValidatorResults;
});

const d365SourceBlobLocation = computed(() => {
  return merchStore.d365SourceBlobLocation;
});

const disabledClear = computed(() => {
  return d365WorksheetUploadInProgress.value === true || !localFile.value;
});

const disabledUpload = computed(() => {
  return !localFile.value || d365WorksheetUploadInProgress.value === true;
});

function onClearIgnoredItemFile(): void {
  localFile.value = null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function onUpload(): Promise<any> {
  await merchStore.validateD365ImportWorksheet(localFile.value);
  if (d365ImportValidatorResults.value.length === 0) {
    await merchStore.uploadD365Worksheet(localFile.value);
    setupWebsocket();
  }
  localFile.value = null;
}

async function setupWebsocket() {
  const errorMessage = err => {
    emit('snackbar-error', {
      text: err && typeof err === 'string' ? err : 'Error uploading D365 sheet.',
      err: err && typeof err === 'string' ? new Error(err) : err,
      id: 'a0059027-8d0c-4405-8b0c-ddeb6c0004f5',
    });
  };

  try {
    const adalToken = await new AdalHelpers().getJwtToken(Vue.prototype.$authApi);
    const id = uuid();
    let created = false;

    const onClose = () => {
      // If the web socket connection closed before
      // the batch was created throw an error message.
      if (created === false) {
        errorMessage(null);
      }
    };

    // Register on message handler before posting the message
    const onmessage = event => {
      const recMessage = JSON.parse(event.data);

      created = true;

      ws?.close();

      if (recMessage.success === false) {
        errorMessage(recMessage.exceptionMessage);
        merchStore.d365WorksheetUploadInProgress = false;
        return;
      }

      const fileParts = recMessage.outputBlobLocation.split('/');
      FileSaver.saveAs(
        recMessage.outputBlobLocation,
        decodeURIComponent(fileParts[fileParts.length - 1]),
      );
      merchStore.d365WorksheetUploadInProgress = false;
    };

    const onOpen = async () => {
      const createD365DiscountData = {
        id,
        sourceBlobLocation: d365SourceBlobLocation.value,
      };
      const servicebusUrl = 'servicebus/d365discountupload';
      await Vue.prototype.$authApi.http.post(servicebusUrl, createD365DiscountData);
    };

    ws = new PogonaWebSocket(
      Vue.prototype.$apiBasePath,
      'd365_discount_upload',
      id,
      adalToken,
      onClose,
      onmessage,
      600000,
      onOpen,
    );
  } catch (e) {
    errorMessage(e);
  }
}
</script>
