/* eslint-disable @typescript-eslint/no-explicit-any */
import { IExcelData, Lookup } from '@/utils/IExcelData';
import { UnitOfMeasure } from '@/utils/VendorDomainTypes';
import NumberHelpers from '../../../utils/NumberHelpers';

export default class ItemUpdateExcelData implements IExcelData {
  constructor(
    public unitOfMeasures: UnitOfMeasure[],
    public fullUnitBarcode = '',
    public newFullUnitBarcode = '',
    public caseBarcode = '',
    public productName = '',
    public retailPackageSize = '',
    public retailPackageUOM = '',
    public externalItemNumber = '',
    public masterCase = 0,
    public innerCasePack = 0,
    public palletQuantity = 0,
    public unitWeight = 0,
    public unitWeightUOM = '',
    public dateOfChange = '',
  ) {}

  private _errors = [] as string[];
  public get errors(): Array<string> {
    return this._errors;
  }
  public pushError(val: string): void {
    this._errors.push(val);
  }

  public clearErrors(): void {
    this._errors = [];
  }

  private _dateFieldIndexes = [] as number[];
  public get dateFieldIndexes(): Array<number> {
    return this._dateFieldIndexes;
  }

  createType(rowIx: number, parameters: Array<string>): IExcelData | null {
    if (
      !parameters ||
      parameters.length === 0 ||
      parameters.length < 2 ||
      !parameters[0] ||
      parameters[0].length === 0
    ) {
      return null;
    }

    const newInstance = new ItemUpdateExcelData(
      this.unitOfMeasures,
      parameters[0],
      parameters[1],
      parameters[2],
      parameters[3],
      parameters[4],
      parameters[5],
      parameters[6],
      NumberHelpers.convertParameterToNumber(parameters[7]),
      NumberHelpers.convertParameterToNumber(parameters[8]),
      NumberHelpers.convertParameterToNumber(parameters[9]),
      NumberHelpers.convertParameterToNumber(parameters[10]),
      parameters[11],
      parameters[12],
    );
    return newInstance;
  }

  equals(other: IExcelData): boolean {
    if (!other) {
      return false;
    }
    const otherPetPartner = other as ItemUpdateExcelData;
    return otherPetPartner.fullUnitBarcode === this.fullUnitBarcode;
  }

  get lookups(): Record<string, Lookup[]> {
    return {
      retailPackageUOM: this.unitOfMeasures.map(
        x => new Lookup(x.UnitOfMeasure, x.UnitOfMeasure, x.UnitOfMeasure),
      ),
    };
  }

  public getLookUpDbValue(fieldName: string, value: string): string | number | null {
    try {
      if (this.lookups[fieldName] && value && value.length > 0) {
        const dbValueIx = this.lookups[fieldName].findIndex(x => x.value === value);
        if (dbValueIx > -1) {
          return this.lookups[fieldName][dbValueIx].dbValue;
        }
      }
    } catch (e) {
      console.error('getLookUpDbValue error', e);
    }
    return null;
  }

  get excludedColumns(): string[] {
    return ['unitOfMeasures'];
  }

  get headers(): any[] {
    return [
      { value: 'fullUnitBarcode', text: 'Full Unit Barcode', width: '140px' },
      { value: 'newFullUnitBarcode', text: 'New Full Unit Barcode', width: '140px' },
      { value: 'caseBarcode', text: 'Case Barcode', width: '140px' },
      { value: 'productName', text: 'Product Name', width: '140px' },
      { value: 'retailPackageSize', text: 'Retail Package Size' },
      { value: 'retailPackageUOM', text: 'Retail UOM' },
      { value: 'externalItemNumber', text: 'Exteral Item Number' },
      { value: 'masterCase', text: 'Master Case (# units in entire case)' },
      { value: 'innerCasePack', text: 'Inner/Case Pack (# units to store)' },
      { value: 'palletQuantity', text: 'Pallet Quantity (# of units/pallet)' },
      { value: 'unitWeight', text: 'Unit Weight' },
      { value: 'unitWeightUOM', text: 'Unit Weight UOM' },
      { value: 'dateOfChange', text: 'Date of Change' },
    ];
  }
}
