<template>
  <v-footer absolute height="auto">
    <v-container>
      <v-card>
        <v-row full-width class="mx-2">
          <v-col cols="12" md="4" :class="$vuetify.breakpoint.mdAndUp ? ['text-start'] : []">
            <strong class="subheading mr-3">{{ username }}</strong>
          </v-col>
          <v-col
            cols="12"
            md="4"
            ref="storeNumberSelection"
            :class="$vuetify.breakpoint.mdAndUp ? ['text-center'] : []"
          >
            <v-dialog v-model="showStoreSelections" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Edit Selected Store</span>
                </v-card-title>

                <v-card-text>
                  <v-container v-if="storeMappings && storeMappings.length > 0">
                    <v-select
                      v-model="selectedStoreNumberFromDropDown"
                      :items="storeMappings"
                      item-text="storeNumber"
                      item-value="storeNumber"
                      @change="enteredStoreNumber = null"
                    ></v-select>
                  </v-container>
                  <v-container v-if="isStoreSupport">
                    <v-row full-width>
                      <v-col>
                        <v-form @submit.stop.prevent="setStoreNumber">
                          <v-text-field
                            label="Store Number (e.g. 0044, 44, 0001, 1, etc.)"
                            v-model="enteredStoreNumber"
                            autocomplete="off"
                            @input="selectedStoreNumberFromDropDown = null"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="setStoreNumber">Save</v-btn>
                  <v-btn @click="closeSetStoreNumber">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <strong
              v-if="showStoreNumber"
              :class="storeNumberCssClasses"
              @click="clickStoreNumber"
              text
              >{{ formattedStoreNumber }}</strong
            >
          </v-col>

          <v-col cols="12" md="4" :class="$vuetify.breakpoint.mdAndUp ? ['text-end'] : []">
            <strong class="subheading">
              <span class="version">{{ version }}</span>
              {{ copyright }}
            </strong>
          </v-col>
        </v-row>
        <v-row full-width class="mx-2" v-if="$showIssueSubmitLink">
          <v-col cols="12" class="pt-0 mt-0">
            <core-form-submission
              @snackbar-error="$emit('snackbar-error', $event)"
              @snackbar-success="$emit('snackbar-success', $event)"
            ></core-form-submission>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-dialog v-model="noStoreDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>Store Number Not Found</v-card-title>
        <v-card-text>
          <p>
            Login "{{ username }}" is not associated with any stores. Please submit a ticket for
            further assistance - make sure to include your PSP login and which store(s) it should be
            mapped to.
          </p>
          <router-link :to="{ name: 'home' }">
            <a>Go Home</a>
          </router-link>
        </v-card-text>
      </v-card>
    </v-dialog>
    <input type="hidden" v-model="selectedStoreNumber" id="_selectedStoreNumber" />
    <input type="hidden" v-model="username" id="_username" />
    <input type="hidden" v-model="version" id="_version" />

    <v-snackbar v-model="newVersionSnackBar" bottom :timeout="-1">
      🎉 A new version of Pogona has been released. Click reload to get the newest version.
      <template v-slot:action="{ attrs }">
        <v-btn color="green" v-bind="attrs" @click="$router.go()">
          <!-- eslint-disable-next-line -->
          Reload
        </v-btn>
      </template>
    </v-snackbar>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import compareVersions from 'compare-versions';
import moment from 'moment';

export default {
  data() {
    return {
      copyright: `PSP Group ${new Date().getFullYear()}`,
      showStoreSelections: false,
      selectedStoreNumber: this.storeNumber,
      selectedStoreNumberFromDropDown: this.storeNumber,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      version: require('@/../package.json').version,
      noStoreDialog: false,
      loggingOut: false,
      adalHelper: new AdalHelpers(),
      serverVersionWs: null,
      newVersionSnackBar: false,
      isStoreSupport: false,
      enteredStoreNumber: null,
      storeMappingsCalled: false,
    };
  },
  async created() {
    if (this.$showServerUpdateMessage === true) {
      await this.setupVersionWebsocket();
    }
    this.isStoreSupport = await this.$authApi.roleHasRights('PogonaStoreSupport');
  },
  computed: {
    ...mapState('app', ['username', 'storeNumber', 'storeMappings', 'showStoreNumber']),
    formattedStoreNumber() {
      if (this.selectedStoreNumber && this.selectedStoreNumber.trim().length > 0) {
        return `Store ${this.selectedStoreNumber || ''}`;
      } else {
        return '';
      }
    },
    storeNumberCssClasses() {
      return [
        'subheading',
        'store-number',
        this.hasStoreMappings || this.isStoreSupport ? 'store-number-clickable' : '',
      ];
    },
    hasStoreMappings() {
      return this.storeMappings && this.storeMappings.length > 0;
    },
  },
  async mounted() {
    // If the token is expired, we'll need to wait for the token-success event,
    // otherwise, just init the mappings right away.
    if (this.username && this.username.length > 0) {
      await this.getStoreMappings();
    } else {
      this.$adalClient.on('token-success', async () => {
        this.selectedStoreNumber = this.storeNumber;
        await this.getStoreMappings();
      });
    }
    await this.logUserActivity(this.$route);
    await this.checkNewJerseyStore();
  },
  methods: {
    async setupVersionWebsocket() {
      const onClose = () => {
        // if it closes, set it up again
        this.setupVersionWebsocket();
      };

      // Register on message handler before posting the message
      const onmessage = event => {
        const recVersion = JSON.parse(event.data);

        // compare the rec version against the server version
        // is the rec version larger?
        if (compareVersions(recVersion.versionNumber, this.$serverVersion) === 1) {
          // wait a little time to make sure we get an updated server in the cluster
          setTimeout(() => {
            this.newVersionSnackBar = true;
          }, 10000);
        }
      };

      const adalToken = await this.adalHelper.getJwtToken(this.$authApi);
      this.serverVersionWs = new PogonaWebSocket(
        this.$apiBasePath,
        'new_ui_version',
        '00000000-0000-0000-0000-000000000001',
        adalToken,
        onClose,
        onmessage,
        3600000, // refresh the connection every hour
      );
    },
    async setStoreNumber() {
      let selectedStoreNumber = this.selectedStoreNumberFromDropDown;
      if (this.enteredStoreNumber && this.enteredStoreNumber.length > 0) {
        this.enteredStoreNumber = this.enteredStoreNumber.padStart(4, '0');
        selectedStoreNumber = this.enteredStoreNumber;
      }
      this.$store.dispatch('app/resetStore', this.$store);
      this.$store.dispatch('app/setStoreNumber', selectedStoreNumber);
      this.closeSetStoreNumber();
      await this.logUserActivity(this.$route);
    },
    closeSetStoreNumber() {
      this.showStoreSelections = false;
    },
    clickStoreNumber() {
      if (this.hasStoreMappings === true || this.isStoreSupport === true) {
        this.showStoreSelections = true;
      } else {
        this.showStoreSelections = false;
      }
    },
    checkStoreNumber() {
      // This is where we detect if the login isn't a "store" login and we have to take
      // additional steps to figure out which store to use
      if (
        (!this.storeNumber || this.storeNumber === this.$defaultStore) &&
        this.storeMappings.length > 0
      ) {
        // Mutate the store number in the store
        const defaultStore = this.storeMappings.filter(s => s.isDefault === true);
        let tmpStoreNumber = this.storeMappings[0].storeNumber;
        if (defaultStore && defaultStore.length > 0) {
          tmpStoreNumber = defaultStore[0].storeNumber;
        }
        this.$store.dispatch('app/setStoreNumber', tmpStoreNumber);
        this.selectedStoreNumber = this.storeNumber;
      } else if (
        (!this.storeNumber || this.storeNumber === this.$defaultStore) &&
        this.isStoreSupport === true
      ) {
        this.$store.dispatch('app/setStoreNumber', this.$defaultStore);
        this.selectedStoreNumber = this.storeNumber;
      } else if (this.storeNumber && this.storeNumber !== this.$defaultStore) {
        this.$store.dispatch('app/setStoreNumber', this.storeNumber);
        this.selectedStoreNumber = this.storeNumber;
      } else {
        // display something to the user that they need to map themselves to a store
        // or have an admin do it
        this.noStoreDialog = this.showStoreNumber === true;
        this.selectedStoreNumber = '';
      }
    },
    async getStoreMappings() {
      if (this.username === null || this.username === undefined || this.username.length === 0) {
        return;
      }

      const storeMappings = (
        await this.$authApi.http.get(`pogona/storeloginstoremapping/user/${this.username}`)
      ).data;
      this.$store.dispatch('app/storeMappings', storeMappings);

      this.storeMappingsCalled = true;

      this.checkStoreNumber();

      this.selectedStoreNumber = this.storeNumber;
    },
    async checkNewJerseyStore() {
      let isNewJerseyStore = false;
      try {
        const newJerseyStores = (
          await this.$authApi.http.get(
            `label/newjerseystore?$filter=storeNumber eq '${this.storeNumber}'`,
          )
        ).data;

        isNewJerseyStore = newJerseyStores && newJerseyStores.length === 1;
      } catch (err) {
        // just swallow exceptions
      }
      this.$store.commit('app/isNewJerseyStore', isNewJerseyStore);
    },
    logout() {
      this.loggingOut = true;
      this.$store.commit('clearStore');
      this.$authApi.logout();
    },
    async logUserActivity(route) {
      try {
        if (!route || !this.username || this.username.length === 0) {
          return;
        }

        const routePath =
          route.fullPath && route.fullPath.length > 1024
            ? route.fullPath.substring(0, 1024)
            : route.fullPath;

        const utcDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
        const localDate = moment(utcDate)
          .add(moment().utcOffset(), 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss');

        await this.$authApi.http.put(`pogona/usernameactivity/${this.username}`, {
          lastActivityDateTimeUtc: utcDate,
          lastActivityDateTimeUserTime: localDate,
          route: routePath,
          storeNumer: this.storeNumber,
          version: this.version,
        });
      } catch (e) {
        this.$appInsights.trackException({
          exception: e,
          properties: {
            text: 'error trying to put usernameactivity',
            username: this.username,
            storeNumber: this.storeNumber,
            route: this.$route,
            id: 'e644e301-c9ad-4cbb-9585-aec0e2ccacca',
          },
        });
      }
    },
  },
  watch: {
    $route: {
      async handler(val) {
        if (val) {
          await this.logUserActivity(val);
        }
      },
    },
    storeNumber: {
      async handler(val) {
        this.selectedStoreNumber = val;
        await this.checkNewJerseyStore();
      },
    },
    showStoreNumber: {
      handler() {
        if (this.storeMappingsCalled === true) {
          this.checkStoreNumber();
        }
      },
    },
  },
};
</script>

<style scoped>
.store-number-clickable {
  cursor: pointer;
  text-decoration: underline;
}
.version {
  font-size: 0.8em;
}
.subheading {
  font-weight: bold;
}
</style>
