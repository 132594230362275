<template>
  <div>
    <v-progress-linear absolute indeterminate v-if="rloading"></v-progress-linear>
    <v-btn class="pt-1" v-if="showDownloadExcel" @click="downloadExcel">Download Excel</v-btn>
    <data-pdf
      v-if="showReport"
      :disabled="rloading"
      :src="pdfSrc"
      :downloadButton="true"
      :printButton="true"
      :zoom="'page-width'"
    ></data-pdf>
  </div>
</template>

<script>
import { err } from '@/store/state';
export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    templateName: {
      type: String,
      required: true,
      default: null,
    },
    supportsExcel: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileShareReport: {
      type: Object,
      required: false,
      default: null,
    },
  },
  created() {
    this.rloading = true;
    this.loadPdfReport();
  },
  data() {
    return {
      pdfSrc: '',
      rloading: this.loading,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.loadPdfReport();
      },
      deep: true,
    },
    templateName: {
      handler: function () {
        this.loadPdfReport();
      },
    },
    rloading: {
      handler: function (val) {
        this.$emit('update:loading', val);
      },
    },
    loading: {
      handler: function (val) {
        this.rloading = val;
      },
    },
  },
  computed: {
    showDownloadExcel() {
      return this.supportsExcel && this.showReport;
    },
    showReport() {
      return this.value && this.pdfSrc && this.pdfSrc.length > 0;
    },
  },
  methods: {
    async loadReport(isExcel) {
      if (
        this.value &&
        Object.keys(this.value).length > 0 &&
        this.templateName &&
        this.templateName.length > 0
      ) {
        try {
          this.rloading = true;

          const reportBody = {
            template: { name: this.templateName },
            options: { reports: { save: true }, reportName: null },
            data: this.value,
            fileShareReport: this.fileShareReport,
          };

          if (this.value.options && this.value.options.reportName) {
            reportBody.options.reportName = this.value.options.reportName;
          }

          let fileSharePromise = null;

          if (this.fileShareReport && this.fileShareReport.isExcel === true && isExcel !== true) {
            // if this is a non-excel report, and they want the excel version, we do two renders
            fileSharePromise = this.$authApi.http.post(
              'report/render/excel',
              Object.assign({}, reportBody),
              { timeout: 300000 },
            );
            // this also means, we do not want the fileShareReport in reportBody for the pdf
            reportBody.fileShareReport = null;
          }

          let reportResponse = null;
          try {
            // eslint-disable-next-line no-unreachable
            reportResponse = await this.$authApi.http.post(
              `report/render${isExcel ? '/excel' : ''}`,
              reportBody,
              { timeout: 300000 },
            );

            // this can happen if the browser just kills the connection after a period of time
            if (
              !reportResponse ||
              !reportResponse.data ||
              !reportResponse.data.url ||
              reportResponse.data.url.length === 0
            ) {
              // if it fails, just try again after 10 second sleep
              await this.wait(10000);
              // eslint-disable-next-line no-unreachable
              reportResponse = await this.$authApi.http.post(
                `report/render${isExcel ? '/excel' : ''}`,
                reportBody,
                { timeout: 300000 },
              );
            }
          } catch (err) {
            // if it fails, just try again after 10 second sleep
            await this.wait(10000);
            reportResponse = await this.$authApi.http.post(
              `report/render${isExcel ? '/excel' : ''}`,
              reportBody,
              { timeout: 300000 },
            );
          }

          if (fileSharePromise !== null) {
            try {
              await fileSharePromise;
            } catch (err) {
              console.error(err);
            }
          }

          if (!reportResponse.data.url || reportResponse.data.url.length === 0) {
            throw new Error('Report failed to render.');
          }

          return reportResponse.data.url;
        } finally {
          this.rloading = false;
        }
      }

      return '';
    },
    async wait(n) {
      return await new Promise(resolve => {
        setTimeout(resolve, n);
      });
    },
    async loadPdfReport() {
      try {
        this.pdfSrc = await this.loadReport();
      } catch (err) {
        this.$error(err);
        this.errorMessage('Error getting report.', err, 'cc2f15d6-3d9f-420d-968e-6470347cdc64');
      }
    },
    async downloadExcel() {
      try {
        const excelSrc = await this.loadReport(true);
        if (excelSrc && excelSrc.length > 0) {
          window.open(excelSrc);
        }
      } catch (err) {
        this.$error(err);
        this.errorMessage('Error getting excel file.', err, '3d5eb28e-542d-4100-8a13-1f229e0382f8');
      }
    },
    errorMessage(text, err, id) {
      this.$emit('snackbar-error', { text, err, id });
    },
  },
};
</script>
