<template>
  <core-signs-labels-view-template
    :title="`New Store ${selectedStoreFormatted} Set Print`"
    :showStoreNumber="false"
  >
    <core-info-blurb title="What is store set staging?">
      The store set staging page allows you to view and make changes to data imported via the data
      import page, from the "Full set import" type. The data here will be applied to the actual
      store set data the next morning.
    </core-info-blurb>
    <v-row>
      <v-col cols="12" class="pt-0">
        <core-view-section title="Sets">
          <v-combobox
            prepend-icon="store"
            v-model="selectedStore"
            :items="storesFromImport"
            :label="`Store${storesFromImport.length === 1 ? '' : 's'} in Import`"
          ></v-combobox>
          <store-set
            v-if="selectedStore"
            :setType="2"
            :importId="importId"
            :storeNumberOverride="selectedStore"
            v-model="storeSet"
            @snackbar-error="$emit('snackbar-error', $event)"
            @selectedChange="selectedChange"
            :selectedStoreSet.sync="selectedStoreSet"
          ></store-set>
        </core-view-section>
      </v-col>
      <v-col cols="12" class="pt-0">
        <core-view-section v-if="showStoreSetDataSection" title="Items" cols="12">
          <v-col id="storeSetData">
            <store-set-data
              :setType="2"
              :importId="importId"
              :storeNumberOverride="selectedStore"
              v-model="storeSetData"
              :storeSet="storeSet"
              :setSections="setSections"
              :allSets="storeSet.allSelected"
              :selectedStoreSet="selectedStoreSet"
              @createdBatchInfoEvent="createdBatchInfoEvent"
              @snackbar-error="$emit('snackbar-error', $event)"
            >
            </store-set-data>
          </v-col>
        </core-view-section>
      </v-col>
    </v-row>
    <v-divider id="batchInfoItemsDivider" class="ma-8"></v-divider>
    <v-col cols="12">
      <core-view-section
        v-if="createdBatchInfo"
        v-show="createdBatchInfo.printImmediately !== true"
        title="Queue Items"
      >
        <batch-check-items
          v-model="batchInfoItems"
          :batchInfo.sync="createdBatchInfo"
          @dataBound="batchInfoItemsDataBound"
          @error="$emit('snackbar-error', $event)"
          :footerBatchAndId="footerBatchAndId"
          :autoCompleteBatch="true"
          :showCompleteToggle="false"
          :overrideStoreNumber="selectedStore"
          :pageBreakOnNewStoreSet="true"
          :hideBatchNameInFooter="true"
          :askToRecalculate="true"
          :showChangeAllLabelTypesButton="false"
          originatingPage="New Store Set"
        ></batch-check-items>
      </core-view-section>
    </v-col>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import BatchCheckItems from '../BatchCheckItems.vue';
import StoreSet from '../StoreSetComponent.vue';
import StoreSetData from '../StoreSetDataComponent.vue';

export default {
  components: {
    BatchCheckItems,
    StoreSet,
    StoreSetData,
  },
  data() {
    return {
      storeSet: null,
      storeSetData: null,
      setSections: [],
      importId: Number(this.$route.params.importId),
      storesFromImport: [],
      selectedStore: null,
      selectedStoreSet: null,
    };
  },
  computed: {
    ...mapState('app', ['storeNumber', 'username']),
    batchInfoItems: {
      get() {
        return this.$store.state.newstoreset.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit('newstoreset/batchInfoItems', val);
      },
    },
    createdBatchInfo: {
      get() {
        return this.$store.state.newstoreset.createdBatchInfo || null;
      },
      set(val) {
        this.$store.commit('newstoreset/createdBatchInfo', val);
      },
    },
    footerBatchAndId() {
      return '{{ POG_ID }}|{{ POG_NAME }}';
    },
    showStoreSetDataSection() {
      return this.storeSet && this.storeSet.selected && this.storeSet.selected.length > 0;
    },
    selectedStoreFormatted() {
      return this.selectedStore || '';
    },
  },
  async created() {
    // clear the store if they're looking at a different import
    if (this.importId !== this.$store.state.newstoreset.importId) {
      this.$store.commit('newstoreset/clear');
      this.$store.commit('newstoreset/importId', this.importId);
    }

    this.storesFromImport = (
      await this.$authApi.http.get(`label/storeset/newstore/${this.importId}/stores`)
    ).data;

    this.selectedStore = this.storesFromImport[0];
  },
  methods: {
    selectedChange() {
      this.createdBatchInfo = null;
    },
    createdBatchInfoEvent(e) {
      this.batchInfoItems = {};
      this.createdBatchInfo = e;
    },
    batchInfoItemsDataBound() {
      // Jump to the batch data.
      this.setToBatchLoading = false;
      this.$vuetify.goTo('#batchInfoItemsDivider');
    },
  },
  watch: {
    storeSet: {
      handler(val) {
        this.$store.commit('newstoreset/storeSet', val);
      },
      deep: true,
    },
    createdBatchInfo: {
      handler(val) {
        this.$store.commit('newstoreset/createdBatchInfo', val);
      },
      deep: true,
    },
  },
};
</script>
