const initState = (): unknown => {
  return {
    batchInfoItems: {},
    storeSet: {},
    pendingStoreSet: {},
    storeSetEndCaps: null,
    allPogItems: null,
    selectedTab: '',
    setSections: [],
    setIdSearch: '',
    assignedSets: [],
    allStoreSets: [],
    allStoreSetsEndCaps: [],
    setDataSearch: '',
    createdBatchInfo: null,
    storeSetData: {},
  };
};

export { initState };

export default {
  batchInfoItems: {},
  storeSet: {},
  pendingStoreSet: {},
  storeSetEndCaps: null,
  allPogItems: null,
  selectedTab: '',
  setSections: [],
  setIdSearch: '',
  assignedSets: [],
  allStoreSets: [],
  allStoreSetsEndCaps: [],
  setDataSearch: '',
  createdBatchInfo: null,
  storeSetData: {},
};
