<template>
  <core-view-template title="Hot Deals">
    <core-view-section title="Search">
      <v-row>
        <v-col cols="4">
          <v-text-field
            prepend-icon="search"
            v-model="search"
            label="Search for item"
            :clearable="true"
            @click:clear="
              search = null;
              getHotDealsDataFromSearch();
            "
            v-debounce:300="getHotDealsDataFromSearch"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <data-date-picker
            v-model="beginDateSearch"
            label="Begin Date"
            @change="dateChanged"
            :clearable="true"
          ></data-date-picker>
        </v-col>
        <v-col cols="4">
          <data-date-picker
            v-model="endDateSearch"
            label="End Date"
            @change="dateChanged"
            :clearable="true"
          ></data-date-picker>
        </v-col>
      </v-row>
    </core-view-section>
    <core-view-section title="Hot Deal Items">
      <v-row v-if="userCanDelete">
        <v-col
          ><v-btn @click="showCreateNewHotDealDialog = true">Create New Hot Deal</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hotDeals">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="hotDeals"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="gettingHotDeals || gettingHotDealsCount"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="hotDealsCount"
            class="elevation-1 scrollable"
            hide-default-footer
            item-key="hotDealItemId"
            @update:options="updateSortBy"
            height="100%"
          >
            <template v-slot:item.beginDate="{ value }">
              {{ value | formatDate }}
            </template>

            <template v-slot:item.endDate="{ value }">
              {{ value | formatDate }}
            </template>

            <template v-slot:item.delete="{ item }">
              <v-btn @click="showDeleteDialog(item)"><v-icon>delete</v-icon></v-btn>
            </template>

            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="hotDeals && hotDeals.length > 0"
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="hotItemDealToDelete">
        <v-dialog v-model="showConfirmDelete" max-width="400px">
          <v-card>
            <v-card-title class="headline">
              Are you sure you want to delete hot ID
              {{ hotItemDealToDelete.hotDealItemId }}</v-card-title
            >
            <v-card-actions>
              <v-btn
                :loading="deletingHotDeal"
                :disabled="deletingHotDeal"
                @click="showConfirmDelete = false"
                >No</v-btn
              >
              <v-btn :loading="deletingHotDeal" :disabled="deletingHotDeal" @click="deleteConfirmed"
                >Yes</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row v-if="showCreateNewHotDealDialog">
        <v-col>
          <v-dialog v-model="showCreateNewHotDealDialog" max-width="800px">
            <v-card>
              <v-card-title class="headline">Create New Hot Deal</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field v-model="newHotDealItemNumber" label="Item Number"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <data-date-picker
                      v-model="newHotDealBeginDate"
                      label="Begin"
                      :clearable="true"
                      :errors="beginErrorMessages"
                    ></data-date-picker>
                  </v-col>
                  <v-col cols="6">
                    <data-date-picker
                      v-model="newHotDealEndDate"
                      label="End"
                      :clearable="true"
                      :errors="endErrorMessages"
                    ></data-date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :loading="creatingHotDeal"
                  :disabled="creatingHotDeal"
                  @click="showCreateNewHotDealDialog = false"
                  >Cancel</v-btn
                >
                <v-btn
                  :loading="creatingHotDeal"
                  :disabled="creatingHotDeal"
                  @click="createConfirmed"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ><span class="text--black">{{ hotDeals.length }} / {{ hotDealsCount }} </span></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><v-btn
            @click="startExportHotDeals"
            :loading="exportingHotDeals"
            :disabled="exportingHotDeals"
            >Export</v-btn
          ></v-col
        >
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script setup lang="ts">
import Vue, { computed, ref, onMounted, defineComponent } from 'vue';
import { useMerchStore, HotDealItem } from '@/stores/merchstore';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';

defineComponent({
  name: 'HotDeals',
});

const merchStore = useMerchStore();

const search = ref<string | null>(null);
const beginDateSearch = ref<string | null>(null);
const endDateSearch = ref<string | null>(null);
const options = ref<any>({ itemsPerPage: 50, sortBy: ['hotDealItemId'], sortDesc: [false] });
const showConfirmDelete = ref(false);
const userCanDelete = ref(false);
const showCreateNewHotDealDialog = ref(false);
const beginErrorMessages = ref<Array<string>>([]);
const endErrorMessages = ref<Array<string>>([]);
const infiniteId = ref(0);
const deletingHotDeal = ref(false);
const hotItemDealToDelete = ref<HotDealItem | null>(null);
const newHotDealItemNumber = ref('');
const newHotDealBeginDate = ref<Date | null>(null);
const newHotDealEndDate = ref<Date | null>(null);
const creatingHotDeal = ref(false);

const headers: any = ref<any>([
  { value: 'hotDealItemId', text: 'ID' },
  { value: 'itemNumber', text: 'Item #' },
  { value: 'beginDate', text: 'Begin Date' },
  { value: 'endDate', text: 'End Date' },
]);

let isMounted = false;
let skip = 0;
let orderBy: Array<string> = [] as Array<string>;
let firstGetComplete = false;
let lastOdata = '';

const gettingHotDeals = computed(() => {
  return merchStore.gettingHotDeals;
});
const gettingHotDealsCount = computed(() => {
  return merchStore.gettingHotDealsCount;
});
const hotDeals = computed(() => {
  return merchStore.hotDeals;
});
const hotDealsCount = computed(() => {
  return merchStore.hotDealsCount;
});
const exportingHotDeals = computed(() => {
  return merchStore.exportingHotDeals;
});

async function dateChanged() {
  await getHotDealsDataFromSearch();
}

async function getHotDealsDataFromSearch() {
  await resetHotDealsOdata();

  await merchStore.getHotDealsCount(createOdata());
  await getHotDealsData(false, true);
  incrementSkip();
}

async function getHotDealsData(reset: boolean, force = false) {
  const odata = createOdata();
  const odataStr = buildQuery(odata).substring(1);

  if (force === false && lastOdata === odataStr) {
    lastOdata = odataStr;
    return;
  }

  lastOdata = odataStr;

  if (reset === true) {
    resetHotDealsOdata();
  }

  await merchStore.getHotDeals(odata);
}

async function resetHotDealsOdata() {
  skip = 0;
  merchStore.hotDeals = [];
}

function dateIsNaN(date: string) {
  return isNaN(new Date(date).getTime());
}

function createOdata() {
  let filter = f('and');
  if (search.value && search.value.length > 0) {
    const trimmedSearch = search.value.trim();
    filter = filter.contains("'itemNumber'", trimmedSearch);
  }

  if (
    beginDateSearch.value &&
    beginDateSearch.value.length > 0 &&
    dateIsNaN(beginDateSearch.value) === false
  ) {
    filter = filter.eq('beginDate', new Date(beginDateSearch.value).toISOString(), false);
  }

  if (
    endDateSearch.value &&
    endDateSearch.value.length > 0 &&
    dateIsNaN(endDateSearch.value) === false
  ) {
    filter = filter.eq('endDate', new Date(endDateSearch.value).toISOString(), false);
  }

  const odata = new ODataProps(options.value.itemsPerPage, skip, filter.toString(), orderBy);
  return odata;
}

function infiniteHandler($state: any) {
  if (firstGetComplete === false) {
    return;
  }

  if (hotDeals.value.length >= hotDealsCount.value) {
    $state.complete();
    return;
  }

  getHotDealsData(false)
    .then(() => {
      if (hotDeals.value.length < hotDealsCount.value) {
        $state.loaded();
        incrementSkip();
      } else {
        $state.complete();
      }
    })
    .catch(() => {
      $state.complete();
    });
}

function incrementSkip() {
  skip += options.value.itemsPerPage;
}

async function updateSortBy() {
  if (isMounted === false) {
    return;
  }

  orderBy = [];
  if (options.value.sortBy && options.value.sortBy.length === 1) {
    orderBy.push(`${options.value.sortBy[0]} ${options.value.sortDesc[0] ? 'desc' : 'asc'}`);
  } else {
    orderBy.push('hotDealItemId asc');
  }

  infiniteId.value += 1;

  await merchStore.getHotDealsCount(createOdata());
  await getHotDealsData(true);

  incrementSkip();

  firstGetComplete = true;
}

function showDeleteDialog(hotDealItem: HotDealItem) {
  hotItemDealToDelete.value = hotDealItem;
  showConfirmDelete.value = true;
}

async function deleteConfirmed() {
  try {
    deletingHotDeal.value = true;
    await merchStore.deleteHotDeal(hotItemDealToDelete.value?.hotDealItemId);
    deletingHotDeal.value = false;
    showConfirmDelete.value = false;
  } catch (e) {
    deletingHotDeal.value = false;
    showConfirmDelete.value = false;
  }

  await resetHotDealsOdata();

  await merchStore.getHotDealsCount(createOdata());
  await getHotDealsData(false, true);
}

async function startExportHotDeals() {
  await merchStore.exportHotDeals(createOdata());
}

async function createConfirmed() {
  if (newHotDealBeginDate.value !== null && newHotDealEndDate.value !== null) {
    creatingHotDeal.value = true;
    try {
      const hotDealItem = new HotDealItem(
        0,
        newHotDealItemNumber.value,
        newHotDealBeginDate.value,
        newHotDealEndDate.value,
      );

      await merchStore.createHotDeal(hotDealItem);
      showCreateNewHotDealDialog.value = false;
    } finally {
      creatingHotDeal.value = false;
      await resetHotDealsOdata();

      await merchStore.getHotDealsCount(createOdata());
      await getHotDealsData(false, true);

      newHotDealItemNumber.value = '';
      newHotDealBeginDate.value = null;
      newHotDealEndDate.value = null;
    }
  }
}

onMounted(async () => {
  userCanDelete.value = await Vue.prototype.$authApi.roleHasRights('MerchandisingWriter');

  if (userCanDelete.value === true) {
    headers.value.push({ value: 'delete', text: 'Delete' });
  }

  isMounted = true;
  await updateSortBy();
});
</script>
