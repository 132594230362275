<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          :disabled="disabled"
          persistent-hint
          prepend-icon="event"
          v-bind="attrs"
          @blur="dateR = parseDate(dateR)"
          v-on="on"
          readonly
          :error-messages="errors"
          class="date-picker"
        ></v-text-field>
        <v-icon
          v-if="clearable"
          :disabled="disabled"
          @click.stop.prevent="cleared"
          class="clear-btn"
          >clear</v-icon
        >
      </v-row>
    </template>
    <v-date-picker v-model="dateR" no-title @input="show = false"></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      required: false,
      default: '',
    },
    date: {
      required: false,
      type: Date,
      default: () => {
        return null;
      },
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    errors: {
      required: false,
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      dateR: this.value ? this.value : this.date ? moment(this.date).format('YYYY-MM-DD') : '',
      dateFormatted: '',
    };
  },
  mounted() {
    this.dateR = this.value ? this.value : this.date ? moment(this.date).format('YYYY-MM-DD') : '';
    this.setDateFormatted();
  },
  methods: {
    cleared() {
      this.show = false;
      this.dateR = '';
      this.dateFormatted = '';

      this.valueChanged('');
      this.dateChanged(null);
    },
    parseDate(dateR) {
      if (this.dateR && this.dateR.length > 0) {
        return moment(dateR).format('YYYY-MM-DD');
      }
      return '';
    },
    setDateFormatted() {
      if (this.dateR && this.dateR.length > 0 && this.dateIsNaN(this.dateR) === false) {
        this.dateFormatted = moment(this.dateR).format('MM/DD/YYYY');
      } else if (this.date && this.date instanceof Date && isNaN(this.date) === false) {
        this.dateFormatted = moment(this.date).format('MM/DD/YYYY');
      } else {
        this.dateFormatted = '';
      }
    },
    valueChanged(val) {
      let newDate = val;
      if (this.dateIsNaN(val) === true) {
        val = null;
      }

      this.$emit('input', newDate);
      this.$emit('change', newDate);
      this.setDateFormatted();
    },
    dateChanged(val) {
      let newDate = val;

      if (val !== null) {
        if (val instanceof Date === false) {
          newDate = moment(val).toDate();
        }
        this.dateR = moment(newDate).format('YYYY-MM-DD');
      } else {
        this.dateR = null;
      }

      // check to see if date is invalid
      if (this.dateIsNaN(this.dateR)) {
        newDate = null;
      }

      this.$emit('update:date', newDate);
      this.$emit('change', newDate);
      this.setDateFormatted();
    },
    dateIsNaN(date) {
      return isNaN(new Date(date));
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.dateR = val;
      },
    },
    date: {
      handler: function (val) {
        this.dateR = moment(val).format('YYYY-MM-DD');
      },
    },
    dateR: {
      handler: function (val) {
        if (this.value) {
          this.valueChanged(val);
        } else if (this.date) {
          this.dateChanged(val);
        } else {
          this.valueChanged(val);
          this.dateChanged(val);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.v-dateR-picker-years li {
  color: $black-color !important;
}
.clear-btn {
  cursor: pointer;
}
</style>
