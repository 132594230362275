import { set } from '@/utils/vuex';

export default {
  setShowModalLoading: set('showModalLoading'),
  username: set('username'),
  storeNumber: set('storeNumber'),
  menuVisibilities: set('menuVisibilities'),
  selectedPrinter: set('selectedPrinter'),
  printerTray: set('printerTray'),
  availablePrinters: set('availablePrinters'),
  showLabelPreviewMode: set('showLabelPreviewMode'),
  storeMappings: set('storeMappings'),
  showStoreNumber: set('showStoreNumber'),
  useJsPrintVersion: set('useJsPrintVersion'),
  isNewJerseyStore: set('isNewJerseyStore'),
};
