export default {
  renderTour({ commit }, payload) {
    commit('renderTour', payload);
  },
  batchCheckTour({ commit }, payload) {
    commit('batchCheckTour', payload);
  },
  handheldPrintTour({ commit }, payload) {
    commit('handheldPrintTour', payload);
  },
  printerAlignTour({ commit }, payload) {
    commit('printerAlignTour', payload);
  },
  labelTour({ commit }, payload) {
    commit('labelTour', payload);
  },
  ingredientTour({ commit }, payload) {
    commit('ingredientTour', payload);
  },
  storeSetTour({ commit }, payload) {
    commit('storeSetTour', payload);
  },
  activateDeactivateTour({ commit }, payload) {
    commit('activateDeactivateTour', payload);
  },
  renderingsProgressTour({ commit }, payload) {
    commit('renderingsProgressTour', payload);
  },
  uomOverrideTour({ commit }, payload) {
    commit('uomOverrideTour', payload);
  },
  newItemRequestTour({ commit }, payload) {
    commit('newItemRequestTour', payload);
  },
  vendorItemUpdateTour({ commit }, payload) {
    commit('vendorItemUpdateTour', payload);
  },
  priceChangeTour({ commit }, payload) {
    commit('priceChangeTour', payload);
  },
};
