export default {
  themes: {
    light: {
      primary: '#4caf50',
      secondary: '#4caf50',
      tertiary: '#495057',
      accent: '#80c883',
      error: '#f55a4e',
      info: '#2196f3',
      success: '#5cb860',
      warning: '#ffa21a',
    },
  },
};
