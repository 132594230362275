<template>
  <core-view-template title="D365 User Impersonation">
    <v-container>
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <v-row>
        <v-col>
          {{ currentUserImpersonation }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="impersonationUser"
            label="Impersonate User"
            outlined
            dense
          ></v-text-field>
          <v-btn @click="impersonateUser" :disabled="!impersonationUserSet">Impersonate</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="stopImpersonation">Stop Impersonating</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import { D365UserImpersonationDto } from '@psp/pogona_vendor_api_lib';

const vpFinanceStore = useVpFinanceStore();
const loading = ref(false);
const currentUserImpersonation = ref('');
const impersonationUser = ref('');

defineComponent({
  name: 'ManageD365Impersonation',
});

const impersonationUserSet = computed(() => impersonationUser.value.length > 0);

function stopImpersonation() {
  loading.value = true;
  vpFinanceStore
    .stopImpersonatingD365User()
    .then(() => {
      return getImpersonationUser();
    })
    .finally(() => {
      loading.value = false;
    });
}

function impersonateUser() {
  loading.value = true;
  vpFinanceStore
    .impersonateD365User(impersonationUser.value)
    .then(() => {
      return getImpersonationUser();
    })
    .finally(() => {
      loading.value = false;
    });
}

function getImpersonationUser() {
  loading.value = true;
  return vpFinanceStore
    .getD365UserImpersonation()
    .then((user: D365UserImpersonationDto) => {
      currentUserImpersonation.value = user?.ImpersonatedUser
        ? `Impersonating ${user.ImpersonatedUser}`
        : 'Not impersonating';
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(() => {
  getImpersonationUser();
});
</script>
