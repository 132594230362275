<template>
  <div>
    <v-dialog v-model="showDialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-col cols="12">
          <v-row align="start" class="pointer date-range d-block" v-on="on">
            <v-col class="mt-1 ml-1">
              <v-row class="pointer date-formatted-ctn">
                <v-col cols="1">
                  <v-row justify="start">
                    <v-icon>calendar_today</v-icon>
                  </v-row>
                </v-col>
                <v-col cols="8">
                  <v-row justify="start" class="ml-1">
                    <div v-text="dateFormatted" class="date-formatted"></div>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row justify="end">
                    <v-icon v-if="dateFormatted != defaultFormat" @click.prevent.stop="cleared"
                      >close</v-icon
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <v-card>
        <v-card-title v-if="dialogHeader && dialogHeader.length > 0">
          <span class="headline">{{ dialogHeader }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <data-date-picker
                  v-model="innerValue.Start"
                  label="Start"
                  @change="onChange"
                  :clearable="true"
                  :errors="startErrorMessages"
                ></data-date-picker>
              </v-col>
              <v-col cols="6">
                <data-date-picker
                  v-model="innerValue.End"
                  label="End"
                  @change="onChange"
                  :clearable="true"
                ></data-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="showDialog = false"
            :disabled="startErrorMessages && startErrorMessages.length > 0"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator';
import { DateRange } from '@psp/pogona_pcl_client_js';
import moment from 'moment';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({})
export default class PclDateRange extends Vue {
  private innerValue!: any;
  private modelValue!: DateRange;
  private showDialog = false;
  private startErrorMessages: Array<string> = [];

  private defaultFormat = 'MM/DD/YYYY';
  public dateFormatted = this.defaultFormat;

  @ModelSync('dateRange', 'change', { type: DateRange })
  readonly value!: DateRange;

  @Prop({ required: false })
  readonly dialogHeader!: string;

  public created() {
    this.handleInnerValue();
  }

  @Watch('value', { deep: true }) valueChanged() {
    this.dateFormatted = this.formatDate();
  }

  public cleared() {
    this.innerValue = { Start: null, End: null };
    this.modelValue = new DateRange();

    this.onChange();

    setTimeout(() => {
      this.dateFormatted = this.defaultFormat;
    }, 0);
  }

  public onChange() {
    this.handleInnerValue();

    this.$emit('input', this.modelValue);
    this.$emit('change', this.modelValue);
  }

  public handleInnerValue() {
    if (!this.innerValue) {
      this.innerValue = { Start: null, End: null };
    }
    if (!this.modelValue) {
      this.modelValue = new DateRange();
    }

    if (this.innerValue.Start) {
      this.modelValue.Start = new Date(this.innerValue.Start);
    } else {
      this.modelValue.Start = null;
    }
    if (this.innerValue.End) {
      this.modelValue.End = new Date(this.innerValue.End);
    } else {
      this.modelValue.End = null;
    }

    // is start greater than end?
    if (
      this.modelValue.Start &&
      this.modelValue.End &&
      this.modelValue.Start.getTime() > this.modelValue.End.getTime()
    ) {
      this.startErrorMessages = ['Start must be less than end.'];
    } else {
      this.startErrorMessages = [];
    }

    this.dateFormatted = this.formatDate();
  }

  public formatDate() {
    let formatted = '';
    if (this.value) {
      if (this.value.Start) {
        formatted = moment.utc(this.value.Start).format(this.defaultFormat);
        if (!this.modelValue.End) {
          formatted = `>= ${formatted}`;
        }
      }
      if (this.modelValue.End) {
        formatted += `${formatted.length > 0 ? ' - ' : '<= '}${moment
          .utc(this.value.End)
          .format(this.defaultFormat)}`;
      }
    }

    if (formatted.length === 0) {
      return this.defaultFormat;
    }
    return formatted;
  }
}
</script>

<style lang="scss" scoped>
.range-input {
  height: 54px;
  margin-top: 16px;
  padding-top: 12px;
}
.date-range {
  margin-top: 23px;

  .date-formatted {
    font-size: 14px !important;
  }
  .date-formatted-ctn {
    margin-top: -27px !important;
  }
}
</style>
