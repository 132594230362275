import { set } from '@/utils/vuex';

export default {
  renderTour: set('renderTour'),
  batchCheckTour: set('batchCheckTour'),
  handheldPrintTour: set('handheldPrintTour'),
  printerAlignTour: set('printerAlignTour'),
  labelTour: set('labelTour'),
  ingredientTour: set('ingredientTour'),
  storeSetTour: set('storeSetTour'),
  activateDeactivateTour: set('activateDeactivateTour'),
  renderingsProgressTour: set('renderingsProgressTour'),
  uomOverrideTour: set('uomOverrideTour'),
  newItemRequestTour: set('newItemRequestTour'),
  vendorItemUpdateTour: set('vendorItemUpdateTour'),
  priceChangeTour: set('priceChangeTour'),
};
