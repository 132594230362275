/* eslint-disable @typescript-eslint/no-explicit-any */
import UpcYupValidator from '@/utils/UpcYupValidation';
import * as yup from 'yup';

export const defaultStatus = 'PendingReview';

export const schema: any = yup.object().shape({
  status: yup.string().required(),
  requestType: yup.string().required(),
  fullUnitBarcode: yup
    .string()
    .required()
    .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
  caseBarcode: yup
    .string()
    .notRequired()
    .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
  otherBarcode: yup
    .string()
    .notRequired()
    .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
  productName: yup.string().required(),
  retailPackageSize: yup.string().notRequired(),
  retailPackageUOM: yup.string().notRequired(),
  manufacturerAccountNumber: yup.string().required(),
  brand: yup.number().required().min(1, 'brand is required'),
  vendorId: yup.string().required(),
  externalItemNumber: yup.string().required(),
  masterCase: yup.number().required().min(1),
  innerCasePack: yup.number().min(1).required(),
  layerQuantity: yup.number().min(0).notRequired(),
  palletQuantity: yup.number().min(1).required(),
  distributionNetwork: yup.string(),
  directToStore: yup.string(),
  directToStoreOrVendorIntoPspdPurchaseCost: yup.number().notRequired(),
  mfgSuggestedRetail: yup.number().notRequired(),
  mapPrice: yup.number().notRequired(),
  imap: yup.number().notRequired(),
  autoShipEligible: yup.boolean(),
  initialDiscountFundingPercent: yup.number().min(0).notRequired(),
  onGoingFundingDiscountPercent: yup.number().min(0).notRequired(),
  materialSafetyDataSheetNeeded: yup.boolean(),
  minimumOrderQuantity: yup.number().min(0).notRequired(),
  unitWeight: yup.number().notRequired(),
  unitWeightUOM: yup.string().notRequired(),
  unitHeight: yup.number().notRequired(),
  unitWidth: yup.number().notRequired(),
  unitDepth: yup.number().notRequired(),
  caseHeight: yup.number().notRequired(),
  caseWidth: yup.number().notRequired(),
  caseDepth: yup.number().notRequired(),
  productAttribute: yup.string().notRequired(),
  htsCode: yup.number().notRequired(),
  dutyRate: yup.number().notRequired(),
  pluScan: yup
    .string()
    .notRequired()
    .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
  pluAssigned: yup
    .string()
    .notRequired()
    .test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
  daxItemNumber: yup.string().notRequired(),
  images: yup.array().notRequired(),
  signsAndLabelsDescription1: yup.string().notRequired(),
  signsAndLabelsDescription2: yup.string().notRequired(),
  vendorNumber: yup.string().notRequired(),
  shipsToStores: yup.string().notRequired(),
  shipUnitCostFromPspd: yup.number().notRequired(),
  retailBasePrice: yup.number().notRequired(),
  retailHighPrice: yup.number().nullable().notRequired(),
  hierarchy: yup.string().notRequired(),
  categoryManagerName: yup.string().notRequired(),
  pspdInventoryManagerName: yup.string().notRequired(),
  reason: yup.string().notRequired(),
  dsdDistributor: yup.object().shape({
    DaxItemNumber: yup.string().notRequired().label('DaxItemNumber'),
    Distributors: yup.array().of(
      yup.object().shape({
        DistributorId: yup.string().required(),
        ExternalItemId: yup.string().required(),
        StoreCost: yup.number().required(),
        ShipToStoreAs: yup.string().required(),
      }),
    ),
  }),
});

export const dsdSchema: any = yup.object().shape({
  daxItemNumber: yup.string().notRequired(),
  upc: yup.string().required().test('upcValidation', 'Not a valid UPC', UpcYupValidator.validUpc),
  vendor: yup.string().required(),
  externalItemId: yup.string().required(),
  storeCost: yup.number().positive().moreThan(0).required(),
  shipsAsCaseOrUnit: yup.string().required(),
});
