import * as vx from 'vuex';
import { PartnerFundingState } from './state';
import * as m from '@/store/mutations';
import * as pfm from './mutations';
import { DwCategoryRequest } from '@psp/pogona_ecom_client_js/DwCategoryRequest';
import FileSaver from 'file-saver';
import dateFormat from 'dateformat';

export const searchPartnerFunding = 'searchPartnerFunding';
export const getBrands = 'getBrands';
export const getDepartments = 'getDepartments';
export const getSubDepartments = 'getSubDepartments';
export const getCategories = 'getCategories';
export const getSubCategories = 'getSubCategories';
export const downloadPartnerFundingSearchResults = 'downloadPartnerFundingSearchResults';
export const validatePartnerFundingExcelFile = 'validatePartnerFundingExcelFile';
export const processPartnerFundingExcelFile = 'processPartnerFundingExcelFile';
export const getPartnerFundingImportProcesses = 'getPartnerFundingImportProcesses';
export const exportPartnerFundingErrors = 'exportPartnerFundingErrors';
export const exportPartnerFundingTemplate = 'exportPartnerFundingTemplate';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

export default {
  [searchPartnerFunding]({
    commit,
    state,
  }: vx.ActionContext<PartnerFundingState, any>): Promise<any> {
    return state
      .client!.searchPartnerFunding(state.partnerFundingSearch)
      .then(x => {
        commit(pfm.setPartnerFundingData, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving partner funding data',
            id: 'e7bc12c1-5c6f-45f0-b9f4-ff4df976b285',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getBrands]({ commit, state }: vx.ActionContext<PartnerFundingState, any>): Promise<any> {
    if (state.brands.length > 0) {
      return Promise.resolve();
    } else {
      return state
        .client!.getDwBrands()
        .then(x => {
          commit(pfm.setBrands, x.data);
        })
        .catch(err => {
          commit(
            m.setError,
            {
              err,
              text: 'Error retrieving brands for partner funding',
              id: '0f9a56b9-9b89-42c2-bba1-2e535e4d6b9d',
            },
            { root: true },
          );
          throw err;
        });
    }
  },
  [getDepartments]({ commit, state }: vx.ActionContext<PartnerFundingState, any>): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(1);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(pfm.setDepartments, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving departments for partner funding',
            id: 'b985588b-9d1a-4e7a-bdb9-f263fce903bb',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getSubDepartments](
    { commit, state }: vx.ActionContext<PartnerFundingState, any>,
    parentId: number | null,
  ): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(2, parentId);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(pfm.setSubDepartments, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving sub departments for partner funding',
            id: '8e2adfb1-299b-4fb6-a6e9-0e746597b119',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getCategories](
    { commit, state }: vx.ActionContext<PartnerFundingState, any>,
    parentId: number | null,
  ): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(3, parentId);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(pfm.setCategories, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving categories for partner funding',
            id: 'd091b42a-3aa5-44bf-8c8a-5634caa96a0b',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getSubCategories](
    { commit, state }: vx.ActionContext<PartnerFundingState, any>,
    parentId: number | null,
  ): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(4, parentId);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(pfm.setSubCategories, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving sub categories for partner funding',
            id: '34a0221c-0a18-4b4c-87fe-9e2f9a09090d',
          },
          { root: true },
        );
        throw err;
      });
  },
  [downloadPartnerFundingSearchResults]({
    commit,
    state,
  }: vx.ActionContext<PartnerFundingState, any>): Promise<any> {
    return state
      .client!.downloadPartnerFundingSearchResults(state.partnerFundingSearch)
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `autoship-partner-funding-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error downloading partner funding data',
            id: 'a4971a15-b13d-4845-8383-ca66467eb477',
          },
          { root: true },
        );
        throw err;
      });
  },
  [validatePartnerFundingExcelFile](
    { state, commit }: vx.ActionContext<PartnerFundingState, any>,
    payload: File,
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', payload, payload.name);
    return state
      .client!.validatePartnerFundingExcelFile(formData)
      .then(x => {
        commit(pfm.setUploadFile, payload);
        commit(pfm.setValidationData, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error validating partner funding data Excel file',
            id: 'b56e15a4-b2b7-4091-8b44-f72725a820a8',
          },
          { root: true },
        );
        throw err;
      });
  },
  [processPartnerFundingExcelFile](
    { state, commit }: vx.ActionContext<PartnerFundingState, any>,
    payload: File,
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append('file', payload, payload.name);
    return state.client!.processPartnerFundingExcelFile(formData).catch(err => {
      commit(
        m.setError,
        {
          err,
          text: 'Error processing partner funding Excel file',
          id: 'fdba615a-1f7d-4460-9d16-a9d20aa551ed',
        },
        { root: true },
      );
      throw err;
    });
  },
  [getPartnerFundingImportProcesses]({
    state,
    commit,
  }: vx.ActionContext<PartnerFundingState, any>): Promise<unknown> {
    return state
      .client!.getPartnerFundingImportProcesses()
      .then(x => {
        commit(pfm.setProcessingData, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving partner funding processing data',
            id: '1478d44c-bf33-4c9b-be5f-f8d1dfdb87b8',
          },
          { root: true },
        );
        throw err;
      });
  },
  [exportPartnerFundingErrors](
    { commit, state }: vx.ActionContext<PartnerFundingState, any>,
    correlationId: string,
  ): Promise<any> {
    return state
      .client!.exportPartnerFundingErrors(correlationId)
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `partner-funding-errors-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving partner funding errors',
            id: 'adb4daa9-cde3-41ca-a9e1-2c8c81a9f3b5',
          },
          { root: true },
        );
        throw err;
      });
  },
  [exportPartnerFundingTemplate]({
    commit,
    state,
  }: vx.ActionContext<PartnerFundingState, any>): Promise<any> {
    return state
      .client!.exportPartnerFundingTemplate()
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `partner-funding-template-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving partner funding template',
            id: 'e8e39057-f050-4c57-8989-bce6a1d1df97',
          },
          { root: true },
        );
        throw err;
      });
  },
};
