import {
  PogonaEcomClient,
  FileUploadProcessingStateInfo,
  SubscriptionExceptionSearchParams,
  SubscriptionExceptionSearchResult,
} from '@psp/pogona_ecom_client_js';
import { DwBrand } from '@psp/pogona_ecom_client_js/DwBrand';
import DwCategory from '@psp/pogona_ecom_client_js/DwCategory';
import { SubscriptionValidationResult } from '@psp/pogona_ecom_client_js/SubscriptionValidationResult';
import { ProcessingStateInfo } from '@psp/pogona_ecom_client_js/ProcessingStateInfo';
import { IgnoredItemsUploadResult } from '@psp/pogona_ecom_client_js/IgnoredItemsUploadResult';
import { PspEcomIgnoreItemOld } from '@psp/pspecomdto';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const client = 'client';
export const exceptionData = 'exceptionData';
export const exceptionSearch = 'exceptionSearch';
export const exceptionSearchInProgress = 'exceptionSearchInProgress';
export const brands = 'brands';
export const departments = 'departments';
export const subDepartments = 'subDepartments';
export const categories = 'categories';
export const subCategories = 'subCategories';
export const uploadFile = 'uploadFile';
export const validationData = 'validationData';
export const processingData = 'processingData';
export const showProcessing = 'showProcessing';
export const ignoredItems = 'ignoredItems';
export const ignoredItemsCount = 'ignoredItemsCount';
export const gettingIgnoredItems = 'gettingIgnoredItems';
export const ignoredItemsUploadInProgress = 'ignoredItemsUploadInProgress';
export const ignoredItemsUploadResult = 'ignoredItemsUploadResult';

export class EcomState {
  constructor(
    public client: PogonaEcomClient | null = null,
    public exceptionData: SubscriptionExceptionSearchResult[] = [] as SubscriptionExceptionSearchResult[],
    public exceptionSearch: SubscriptionExceptionSearchParams = new SubscriptionExceptionSearchParams(),
    public exceptionSearchInProgress: boolean = false,
    public brands: DwBrand[] = [] as DwBrand[],
    public departments: DwCategory[] = [] as DwCategory[],
    public subDepartments: DwCategory[] = [] as DwCategory[],
    public categories: DwCategory[] = [] as DwCategory[],
    public subCategories: DwCategory[] = [] as DwCategory[],
    public uploadFile: File | null = null,
    public validationData: SubscriptionValidationResult | null = null,
    public processingData: FileUploadProcessingStateInfo[] = [] as FileUploadProcessingStateInfo[],
    public showProcessing: boolean = false,
    public ignoredItems: PspEcomIgnoreItemOld[] = [] as PspEcomIgnoreItemOld[],
    public ignoredItemsCount: number = 0,
    public gettingIgnoredItems: boolean = false,
    public ignoredItemsUploadInProgress: boolean = false,
    public ignoredItemsUploadResult: IgnoredItemsUploadResult | null = null,
  ) {
    exceptionSearch.PageSize = 50;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public init(http: any, baseUrl: string | null): void {
    this.client = new PogonaEcomClient(null, http, baseUrl);
  }
}

export const ecomState: EcomState = new EcomState();

export default ecomState;
