export default {
  renderTour: true,
  batchCheckTour: true,
  handheldPrintTour: true,
  printerAlignTour: true,
  labelTour: true,
  ingredientTour: true,
  storeSetTour: true,
  activateDeactivateTour: true,
  renderingsProgressTour: true,
  uomOverrideTour: true,
  newItemRequestTour: true,
  vendorItemUpdateTour: true,
  priceChangeTour: true,
};
