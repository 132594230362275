import Vue from 'vue';

Vue.filter('bool', value => {
  if (value === true) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  }
  return null;
});
