<template>
  <core-view-template title="Website Exclusions Manager *NEW*">
    <core-view-section title="Upload">
      <ecom-ignored-item-results v-if="fileUploading" :value="ignoreItemResults" />
      <v-row>
        <v-col>
          <v-file-input
            multiple
            prepend-icon="attachment"
            label="Click here to upload exclusions data"
            v-model="ignoreItemsFile"
            accept=".xlsx"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col>
          <a :href="importSample"> <v-icon class="mr-2">get_app</v-icon>Download Sample</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="uploadIgnoreItemComments"
            label="Comments (any blank comments in uploaded file will default to these comments)"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ><v-btn
            @click="uploadFile"
            :loading="updatingIgnoreItems"
            :disabled="updatingIgnoreItems || !ignoreItemsFile"
            >Upload</v-btn
          ></v-col
        >
      </v-row>
    </core-view-section>
    <core-view-section title="Website Item Exclusions">
      <ecom-ignored-item-results v-if="fileUploading === false" :value="ignoreItemResults" />
      <v-row
        ><v-col>
          <v-text-field
            label="Search by item #, UPC, or description"
            v-model="searchText"
            clearable
            @click:clear="
              searchText = '';
              textSearch();
            "
            v-debounce:300="textSearch"
            prepend-icon="search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-btn @click="createItemDialog = true">Create New Excluded Item</v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            @click="confirmDelete()"
            :disabled="selectedItemsCount === 0 || updatingIgnoreItems"
            :loading="updatingIgnoreItems"
            >Delete {{ selectedItemsCount }} item{{
              selectedItemsCount > 1 || selectedItemsCount === 0 ? 's' : ''
            }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selectedItems"
            :headers="headers"
            :items="ignoredItems"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="gettingIgnoredItems || gettingIgnoredItemsCount"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="ignoredItemsCount"
            @click:row="ignoredItemClicked"
            @update:sort-by="setOrderBy"
            @update:sort-desc="setOrderBy"
            class="elevation-1 scrollable"
            hide-default-footer
            item-key="IgnoreItemId"
            height="100%"
            show-select
          >
            <template v-slot:header.data-table-select></template>

            <template v-slot:item.Delete="{ item }">
              <v-btn
                @click.stop.prevent="confirmDelete(item)"
                :loading="updatingIgnoreItems"
                :disabled="updatingIgnoreItems"
                >Delete</v-btn
              >
            </template>

            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="ignoredItems && ignoredItems.length > 0"
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>

            <template v-slot:footer="{}">
              <div class="my-3 ml-2">{{ ignoredItems.length }} / {{ ignoredItemsCount }}</div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn
            @click="exportIgnoredItems"
            :disabled="exportingIgnoredItems"
            :loading="exportingIgnoredItems"
            >Export</v-btn
          >
        </v-col>
      </v-row>
      <v-dialog v-model="createItemDialog" max-width="600px">
        <v-card>
          <v-card-title>Create excluded item</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="newIgnoreItemValid" lazy-validation>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newIgnoreItem.ItemId"
                    label="Item #"
                    :rules="itemIdRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newIgnoreItem.Upc"
                    label="UPC"
                    :rules="upcRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea v-model="newIgnoreItem.Comments" label="Comments"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  @click="createNewIgnoreItem"
                  :loading="updatingIgnoreItems"
                  :disabled="newIgnoreItemValid === false || updatingIgnoreItems"
                  class="mr-2"
                  >Save</v-btn
                >
                <v-btn @click="createItemDialog = false">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showProgressPopup" max-width="600px" persistent>
        <v-card style="overflow: hidden">
          <v-row>
            <v-col>
              <v-card-title>{{ progressDescription }}</v-card-title>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col>
                <data-circular-progress
                  class="d-flex justify-center"
                  :size="100"
                  :width="5"
                  :id="progressId"
                  :showDescription="false"
                  :showName="false"
                  :initialValue="0"
                  :closeOnError="false"
                  @progressUpdated="progressUpdated"
                ></data-circular-progress>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmDeleteDialog" max-width="600px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete
            {{
              confirmDeleteDialogForMulti ? `${selectedItemsCount} item(s)` : 'this item'
            }}?</v-card-title
          >
          <v-card-actions>
            <v-btn @click="confirmDeleteDialog = false">No</v-btn>
            <v-btn @click="deleteConfirmed">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </core-view-section>
  </core-view-template>
</template>

<script setup lang="ts">
import Vue, { computed, ref, onMounted, watch } from 'vue';
import { useEcomStore, IgnoreItem } from '@/stores/ecomstore';
import { storeToRefs } from 'pinia';
import f from 'odata-filter-builder';
import UpcYupValidator from '../../../utils/UpcYupValidation';
import uuidv4 from 'uuid/v4';
import AdalHelpers from '@/utils/AdalHelpers';

const ecomStore = useEcomStore();

const {
  ignoredItems,
  ignoredItemsCount,
  odataProps,
  gettingIgnoredItems,
  gettingIgnoredItemsCount,
  updatingIgnoreItems,
  ignoreItemResults,
  progressId,
  progressDescription,
  exportingIgnoredItems,
} = storeToRefs(ecomStore);

const infiniteId = -1;
const pageSize = 50;

const headers = ref<any>([
  { value: 'ItemId', text: 'Item #' },
  { value: 'Upc', text: 'UPC' },
  { value: 'Description', text: 'Description' },
  { value: 'Comments', text: 'Comments' },
  { value: 'Delete', text: 'Delete', sortable: false },
]);

const options = ref<any>({ sortBy: ['ItemId'], sortDesc: [false] });
const selectedItems = ref<IgnoreItem[]>([] as IgnoreItem[]);
const searchText = ref('');
const createItemDialog = ref(false);
const newIgnoreItem = ref<IgnoreItem>(new IgnoreItem());
const newIgnoreItemValid = ref(true);
const itemIdRules = ref([v => !!v || 'Item # is required', v => !isNaN(v) || 'Must be a number']);
const upcRules = ref([
  v => !!v || 'UPC is required',
  v => UpcYupValidator.isUpc(v) || 'Must be a valid UPC',
]);
const ignoreItemsFile = ref<File>();
const uploadIgnoreItemComments = ref('');
const showProgressPopup = ref(false);
const fileUploading = ref(false);
const confirmDeleteDialog = ref(false);
const confirmDeleteDialogForMulti = ref(false);
const itemToDelete = ref<IgnoreItem | null>();
const form = ref();

const selectedItemsCount = computed(() => {
  return selectedItems.value?.length ?? 0;
});

const importSample = computed(() => {
  return `${Vue.prototype.$storageHost}/templatesamples/exclusionitemstemplate.xlsx`;
});

onMounted(async () => {
  odataProps.value.top = pageSize;
  await getData();
});

async function getData() {
  await Promise.all([ecomStore.getIgnoredItems(), ecomStore.getIgnoredItemsCount()]);
}

function ignoredItemClicked(e: IgnoreItem) {
  const checkSelectedIx = selectedItems.value.findIndex(x => x.IgnoreItemId === e.IgnoreItemId);
  if (checkSelectedIx === -1) {
    selectedItems.value.push(e);
  } else {
    selectedItems.value.splice(checkSelectedIx, 1);
  }
}

async function createNewIgnoreItem() {
  if (newIgnoreItemValid.value === true) {
    newIgnoreItem.value.IgnoreItemId = uuidv4();
    const adalToken = await getJwtToken();
    await ecomStore.createIgnoredItem(newIgnoreItem.value, Vue.prototype.$apiBasePath, adalToken);

    createItemDialog.value = false;

    await getData();

    newIgnoreItem.value = new IgnoreItem();
    form.value?.reset();
  }
}

async function setOrderBy() {
  odataProps.value.skip = 0;

  const orderBy = new Array<string>();
  if (options.value.sortBy.length > 0) {
    orderBy.push(`${options.value.sortBy[0]} ${options.value.sortDesc[0] ? 'desc' : 'asc'}`);
  }
  if (orderBy && orderBy.length > 0) {
    odataProps.value.orderBy = orderBy;
  }

  await getData();
}

async function textSearch() {
  odataProps.value.skip = 0;

  if (searchText.value && searchText.value.trim().length > 0) {
    const trimmedSearch = searchText.value.trim();

    const filter = f('and').and(
      f('or')
        .contains("'Upc'", trimmedSearch)
        .contains("'ItemId'", trimmedSearch)
        .contains("'Description'", trimmedSearch),
    );
    odataProps.value.filter = filter.toString();
  } else {
    odataProps.value.filter = null;
  }

  await getData();
}

async function confirmDelete(item: IgnoreItem | undefined) {
  if (item) {
    confirmDeleteDialogForMulti.value = false;
    itemToDelete.value = item;
  } else {
    confirmDeleteDialogForMulti.value = true;
    itemToDelete.value = null;
  }

  confirmDeleteDialog.value = true;
}

async function deleteConfirmed() {
  if (confirmDeleteDialogForMulti.value === true) {
    await deleteSelected();
  } else if (itemToDelete.value) {
    await deleteItem(itemToDelete.value);
  }
  confirmDeleteDialog.value = false;
  await getData();
}

async function deleteItem(item: IgnoreItem) {
  await deleteItems([item]);
}

async function deleteSelected() {
  await deleteItems(selectedItems.value);
}

async function deleteItems(items) {
  const adalToken = await getJwtToken();
  await ecomStore.deleteIgnoredItems(items, Vue.prototype.$apiBasePath, adalToken);
  selectedItems.value = [] as IgnoreItem[];
  await getData();
}

function infiniteHandler($state: any) {
  getData()
    .then(x => {
      if (ignoredItems.value.length < ignoredItemsCount.value) {
        $state.loaded();
        odataProps.value.skip += pageSize;
      } else {
        $state.complete();
      }
    })
    .catch(() => {
      $state.complete();
    });
}

async function uploadFile() {
  if (ignoreItemsFile.value) {
    fileUploading.value = true;
    try {
      const adalToken = await getJwtToken();
      await ecomStore.createIgnoredItems(
        ignoreItemsFile.value,
        uploadIgnoreItemComments.value,
        Vue.prototype.$apiBasePath,
        adalToken,
      );
      ignoreItemsFile.value = undefined;
      uploadIgnoreItemComments.value = '';

      await getData();
    } catch (err) {
      fileUploading.value = false;
    }
  }
}

function progressUpdated(e) {
  if (e && e.complete && e.complete >= 100) {
    showProgressPopup.value = false;
  }
}

async function getJwtToken() {
  return await new AdalHelpers().getJwtToken(Vue.prototype.$authApi);
}

async function exportIgnoredItems() {
  await ecomStore.exportIgnoredItems();
  exportingIgnoredItems.value = false;
}

watch(
  () => progressId.value,
  () => {
    showProgressPopup.value = true;
  },
);
</script>
