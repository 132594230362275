<template>
  <core-view-template title="User or Group Roles">
    <core-view-section title="Users or Groups">
      <v-row class="mb-4">
        <v-col cols="2">
          <v-dialog v-model="createNewUserDialog" max-width="700px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">New Guest User</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">New Guest User</span>
              </v-card-title>
              <v-card-text>
                <ad-create-user
                  @saved="createNewUserDialog = false"
                  @cancel="createNewUserDialog = false"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-btn @click="clearTokens" :disabled="clearLoading" :loading="clearLoading"
            >Clear My Auth Tokens</v-btn
          >
        </v-col>
      </v-row>
      <ad-users-search
        v-model="selectedUser"
        :includeGroups="true"
        @snackbar-error="$emit('snackbar-error', $event)"
      ></ad-users-search>
    </core-view-section>
    <core-view-section class="mt-4" title="Roles">
      <v-row class="mb-4">
        <v-col>
          <v-data-table
            v-if="appRolesForUser"
            v-model="appRolesForUser.selected"
            :headers="appRolesForUser.headers"
            :items="appRolesForUser.items"
            :loading="appRolesForUser.loading"
            :items-per-page="999"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="appRolesForUser.options"
            @update:sort-by="() => {}"
            @click:row="appRolesForUser.itemRowSingleClicked($event, appRolesForUser)"
            @item-selected="appRolesForUser.itemRowSingleSelected($event, appRolesForUser)"
            item-key="objectId"
            class="elevation-1 scrollable"
          >
            <template v-slot:top>
              <v-toolbar>
                <v-dialog v-model="newRoleMappingDialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">New Mappings</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">New mappings</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        v-if="appRolesForServicePrincipal"
                        v-model="appRolesForServicePrincipal.selected"
                        :headers="appRolesForServicePrincipal.headers"
                        :items="appRolesForServicePrincipalFiltered"
                        :loading="appRolesForServicePrincipal.loading"
                        :items-per-page="999"
                        :disable-pagination="true"
                        :fixed-header="true"
                        :options.sync="appRolesForServicePrincipal.options"
                        @update:sort-by="() => {}"
                        @click:row="
                          appRolesForServicePrincipal.itemRowMultiClicked(
                            $event,
                            appRolesForServicePrincipal,
                          )
                        "
                        @item-selected="
                          appRolesForServicePrincipal.itemRowMultiSelected(
                            $event,
                            appRolesForServicePrincipal,
                          )
                        "
                        item-key="id"
                        class="elevation-1 scrollable"
                        show-select
                        :single-select="false"
                      ></v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="addNewRoleMappings"
                        :disabled="newRoleMappingLoading"
                        :loading="newRoleMappingLoading"
                        >Save</v-btn
                      >
                      <v-btn @click="newRoleMappingDialog = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-icon v-if="canDelete(item)" @click="showDeleteDialog(item)">delete</v-icon>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="disabled-icon">delete</v-icon>
                </template>
                <span class="white--text">{{ cannotDeleteText(item) }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="deleteAppRoleMappingDialog">
        <v-dialog v-model="deleteAppRoleMappingDialog" max-width="500">
          <v-card>
            <v-card-title>
              <span class="headline">Delete mapping</span>
            </v-card-title>
            <v-card-text
              >Are you sure you want to delete mapping '{{
                deleteAppRoleItem.appRoleDisplayName
              }}'?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="deleteAppRoleMap(deleteAppRoleItem)"
                :disabled="deleteAppRoleMappingLoading"
                :loading="deleteAppRoleMappingLoading"
                >Yes</v-btn
              >
              <v-btn @click="deleteAppRoleMappingDialog = false">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script>
import AdUsersSearch from './AdUsersSearch.vue';
import AdCreateUser from './AdCreateUser.vue';
import PogonaDataTable from '@/utils/PogonaDataTable';

export default {
  components: {
    AdUsersSearch,
    AdCreateUser,
  },
  data() {
    return {
      selectedUser: null,
      appRolesForUser: null,
      deleteAppRoleMappingDialog: false,
      deleteAppRoleMappingLoading: false,
      deleteAppRoleItem: null,
      appRolesForServicePrincipal: null,
      newRoleMappingDialog: false,
      newRoleMappingLoading: false,
      allAppRolesForServicePrincipalSelected: false,
      appRolesForServicePrincipalFiltered: [],
      clearLoading: false,
      createNewUserDialog: false,
    };
  },
  async created() {
    await this.initAppRolesForServicePrincipal();
  },
  methods: {
    showDeleteDialog(item) {
      this.deleteAppRoleItem = item;
      this.deleteAppRoleMappingDialog = true;
    },
    async deleteAppRoleMap() {
      try {
        this.deleteAppRoleMappingLoading = true;

        await this.$authApi.http.delete(
          `pogona/ad/${this.selectedUser.isGroup ? 'groups' : 'users'}/${
            this.selectedUser.id
          }/app-roles/${this.deleteAppRoleItem.objectId}`,
        );

        this.deleteAppRoleMappingLoading = false;
        this.deleteAppRoleMappingDialog = false;
        await this.initUserAppRoles();
      } catch (err) {
        this.deleteAppRoleMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to delete mapping.',
          err,
          id: '4bb4c408-771c-453e-9f4b-3906ef637b2d',
        });
      }
    },
    async addNewRoleMappings() {
      try {
        this.newRoleMappingLoading = true;

        const appRolePosts = [];
        this.appRolesForServicePrincipal.selected.forEach(async item => {
          appRolePosts.push(
            this.$authApi.http.post(
              `pogona/ad/${this.selectedUser.isGroup ? 'groups' : 'users'}/${
                this.selectedUser.id
              }/app-roles`,
              {
                appRoleId: item.id,
              },
            ),
          );
        });

        await Promise.all(appRolePosts);

        this.newRoleMappingDialog = false;
        this.newRoleMappingLoading = false;

        await this.initUserAppRoles();
      } catch (err) {
        this.newRoleMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to delete mapping.',
          err,
          id: 'c4443387-0476-433f-b5ab-891879d1ee57',
        });
      }
    },
    async initUserAppRoles() {
      this.appRolesForUser = new PogonaDataTable({
        headers: [
          {
            text: 'Id',
            value: 'objectId',
          },
          {
            text: 'Name',
            value: 'appRoleDisplayName',
          },
          {
            text: 'Value',
            value: 'appRoleValue',
          },
          {
            text: 'Description',
            value: 'appRoleDescription',
          },
          { text: 'Delete', value: 'delete', sortable: false },
        ],
        baseUrl: `pogona/ad/${this.selectedUser.isGroup ? 'groups' : 'users'}/${
          this.selectedUser.id
        }/app-roles`,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 999, sortBy: ['displayName'] },
        isInfinite: true,
        skipCountQuery: true,
      });
      this.appRolesForUser.on('error', err => {
        this.$emit('snackbar-error', {
          text: `Error getting ${this.selectedUser.isGroup ? 'groups' : 'users'}`,
          err,
          id: 'efb3992d-84f3-4cc8-985a-b6f33401ffa9',
        });
      });
      // only bind roles that are not mapped to user
      this.appRolesForUser.on('dataBound', () => {
        this.appRolesForServicePrincipalFiltered = [];
        this.appRolesForServicePrincipal.items.forEach(item => {
          const appRoleNotMappedToUser =
            this.appRolesForUser.items.filter(x => x.id === item.id).length === 0;
          if (appRoleNotMappedToUser) {
            this.appRolesForServicePrincipalFiltered.push(item);
          }
        });
      });
      this.appRolesForUser.loading = false;
      this.appRolesForServicePrincipal.selected = [];
      await this.appRolesForUser.get();
    },
    async initAppRolesForServicePrincipal() {
      this.appRolesForServicePrincipal = new PogonaDataTable({
        headers: [
          {
            text: 'Name',
            value: 'displayName',
          },
          {
            text: 'Description',
            value: 'description',
          },
        ],
        baseUrl: 'pogona/ad/service-principal/app-roles',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 999, sortBy: ['displayName'] },
        isInfinite: true,
        skipCountQuery: true,
        multiselect: true,
        keys: ['id'],
      });

      this.appRolesForServicePrincipal.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting app roles',
          err,
          id: '1ffbc214-779f-451d-962a-27c6f9e422b9',
        });
      });

      await this.appRolesForServicePrincipal.get();
    },
    canDelete(item) {
      if (
        (item.principalType === 'User' && this.selectedUser.isGroup === false) ||
        (item.principalType === 'Group' && this.selectedUser.isGroup === true)
      ) {
        return true;
      }
      return false;
    },
    cannotDeleteText(item) {
      if (item.principalType === 'Group' && this.selectedUser.isGroup === false) {
        return `This role cannot be deleted here. It is inherited from the group(s): ${
          item.memberOf ? item.memberOf.join(', ') : ''
        }.`;
      } else if (item.principalType === 'Both' && this.selectedUser.isGroup === false) {
        return `This role is mapped to both the user and the group(s): ${
          item.memberOf ? item.memberOf.join(', ') : ''
        }. You will need to remove the user from the AD group first, then delete it here.`;
      }
      return null;
    },
    clearTokens() {
      this.clearLoading = true;
      this.$authApi.clearTokens();

      // need to wait 15 seconds before getting new token
      // this gives the AD stuff time to update
      setTimeout(async () => {
        await this.$authApi.getToken();
      }, 15000);
    },
  },
  watch: {
    selectedUser: {
      handler: async function (val) {
        if (val === null) {
          this.appRolesForUser = null;
        } else {
          await this.initUserAppRoles();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>
