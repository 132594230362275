<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title class="text--black">Distribution Network</v-card-title>
          <v-card-subtitle
            >If you require DSD, please complete the second tab of the New Item Excel Template”.
            Copy all the data from your sheet, including the headers and click the paste button. All
            data will feed below.
          </v-card-subtitle>
          <v-card-text>
            <data-paste-from-excel
              v-model="excelData"
              :typePrototype="typePrototype"
              :errorsLoading="processing"
              :editable="false"
              :deleteable="false"
              :errorHelperText="errorHelperText"
              @noClipboardData="
                $emit('showSnackbarMessage', { text: $event.text, color: 'warning' })
              "
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, ModelSync } from 'vue-property-decorator';
import DsdExcelData from '@/store/modules/vendorportal/DsdExcelData';
import { Vendor } from '@/utils/VendorDomainTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({})
export default class DsdPasteFromExcel extends Vue {
  @ModelSync('value', 'textChange', { type: Array })
  private excelData!: Array<DsdExcelData>;

  @Prop({ required: true, default: false })
  readonly processing!: boolean;

  @Prop({ required: true, default: false })
  readonly multiSaveDisabled!: boolean;

  @Prop({ required: true })
  readonly id!: string;

  @Prop({ required: true })
  readonly vendors!: Vendor[];

  private typePrototype: DsdExcelData | null = null;
  private errorHelperText =
    'If an error is found, it will show here.  Please go back to your file, correct the error(s), come back to the above “DSD Distributor” section, click the clear button and then click the paste button again till no errors occur.';

  @Watch('excelData', { deep: true }) excelDataChanged(newVal: Array<DsdExcelData>) {
    this.$emit('input', newVal);
  }

  mounted() {
    this.typePrototype = new DsdExcelData(this.vendors);
  }

  public async validateMultiInput(schema: any) {
    let allAreValid = true;
    const requestData = [] as any[];

    let lineIx = 0;
    for (const line of this.excelData) {
      lineIx++;
      line.clearErrors();

      const validatedState = await this.createStateFromMultiLineInput(line);
      requestData.push(validatedState);

      try {
        await schema.validate(validatedState);
      } catch (validationError: any) {
        line.pushError(`Row ${lineIx} has the following error: ${validationError.errors}`);
        allAreValid = false;
      }
    }

    return { allAreValid, requestData };
  }

  private async createStateFromMultiLineInput(line: DsdExcelData) {
    return {
      daxItemNumber: line.daxItemNumber,
      upc: line.upc,
      vendor: line.vendor ?? '',
      externalItemId: line.externalItemId ?? '',
      storeCost: line.storeCost,
      shipsAsCaseOrUnit: line.shipsAsCaseOrUnit ?? '',
    };
  }
}
</script>
