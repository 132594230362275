/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  PogonaPclClient,
  StoreSearchParams,
  StoreSearchResult,
  StateCountyResult,
  DistrictResult,
  MarketResult,
  StoreStatusResult,
  ServiceResult,
  DeliveryMethodResult,
  TimeZoneResult,
  PriceZoneResult,
  StoreCreateUpdatePayload,
  Address,
  StoreCopy,
  DmaResult,
  DistributionCenterResult,
  StoreListingAllResult,
  StoreListingAllSearchParam,
  StoreListingAllFields,
  SocialMedia,
} from '@psp/pogona_pcl_client_js';
import * as pcldto from '@psp/pcldto';
import ODataProps from '@/utils/ODataProps';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const client = 'client';
export const storeSearch = 'storeSearch';

export const storeData = 'storeData';
export const storeListingAllData = 'storeListingAllData';
export const storeListingAllParams = 'storeListingAllParams';
export const storeListingAllFields = 'storeListingAllFields';

export const countyData = 'countyData';
export const stateData = 'stateData';
export const storeStatusIdTypesData = 'storeStatusIdTypesData';
export const districtData = 'districtData';
export const marketData = 'marketData';
export const servicesData = 'servicesData';
export const timeZonesData = 'timeZonesData';
export const priceZonesData = 'priceZonesData';
export const deliveryMethodData = 'deliveryMethodData';
export const dmaData = 'dmaData';
export const distributionCenterData = 'distributionCenterData';
export const divisionRegionsData = 'divisionRegionsData';
export const storeEditData = 'storeEditData';
export const ownersData = 'ownersData';
export const ownersDataCount = 'ownersDataCount';
export const ownersDataOData = 'ownersDataOData';
export const ownersDataSearchText = 'ownersDataSearchText';
export const storeSearchInProgress = 'storeSearchInProgress';
export const countySearchInProgress = 'countySearchInProgress';
export const stateGetInProgress = 'stateGetInProgress';
export const storeStatusesGetInProgress = 'storeStatusesGetInProgress';
export const districtGetInProgress = 'districtGetInProgress';
export const marketGetInProgress = 'marketGetInProgress';
export const servicesGetInProgress = 'servicesGetInProgress';
export const divisionRegions = 'divisionRegions';
export const roleNames = 'roleNames';
export const deliveryMethodsGetInProgress = 'deliveryMethodsGetInProgress';
export const createUpdateStoreInProgress = 'createUpdateStoreInProgress';
export const timeZoneGetInProgress = 'timeZoneGetInProgress';
export const priceZoneGetInProgress = 'priceZoneGetInProgress';
export const dmaGetInProgress = 'dmaGetInProgress';
export const distributionCenterGetInProgress = 'distributionCenterGetInProgress';
export const storeListingAllDataGetInProgress = 'storeListingAllDataGetInProgress';
export const divisionRegionsGetInProgress = 'divisionRegionsGetInProgress';
export const socialMediaGetInProgress = 'socialMediaGetInProgress';
export const ownersGetInProgress = 'ownersGetInProgress';
export const ownersSaveInProgress = 'ownersSaveInProgress';
export const ownersDeleteInProgress = 'ownersDeleteInProgress';
export const exportListingAllInProgress = 'exportListingAllInProgress';
export const deliveryAreaLookupGetInProgress = 'deliveryAreaLookupGetInProgress';
export const exportOwnersInProgress = 'exportOwnersInProgress';

export const employees = 'employees';
export const divisionRegionEdit = 'divisionRegionEdit';
export const pclListingAllHeaders = 'pclListingAllHeaders';
export const pclListingAllLoaded = 'pclListingAllLoaded';
export const pclListingAllOptions = 'pclListingAllOptions';
export const pclRemovedHeaders = 'pclRemovedHeaders';
export const divisionRegionsNoRelated = 'divisionRegionsNoRelated';
export const divisionRegionResults = 'divisionRegionResults';
export const districtResults = 'districtResults';
export const marketResults = 'marketResults';
export const deliveryAreaLookup = 'deliveryAreaLookup';
export const socialMediaLookup = 'socialMediaLookup';
export const dogWashTubTypes = 'dogWashTubTypes';
export const dogWashTubTypesGetInProgress = 'dogWashTubTypesGetInProgress';

export class PclState {
  constructor(
    public client: PogonaPclClient | null = null,
    public storeSearch: StoreSearchParams = new StoreSearchParams(),

    public storeData: StoreSearchResult[] = [] as StoreSearchResult[],
    public storeListingAllData: StoreListingAllResult = new StoreListingAllResult(),
    public storeListingAllParams: StoreListingAllSearch | null = null,
    public storeListingAllFields: StoreListingAllFields | null = null,
    public countyData: StateCountyResult[] = [] as StateCountyResult[],
    public districtData: DistrictResult[] = [] as DistrictResult[],
    public marketData: MarketResult[] = [] as MarketResult[],
    public stateData: string[] = [] as string[],
    public storeStatusIdTypesData: StoreStatusResult[] = [] as StoreStatusResult[],
    public servicesData: StoreService[] = [] as StoreService[],
    public deliveryMethodData: DeliveryMethodResult[] = [] as DeliveryMethodResult[],
    public timeZonesData: TimeZoneResult[] = [] as TimeZoneResult[],
    public priceZonesData: PriceZoneResult[] = [] as PriceZoneResult[],
    public dmaData: DmaResult[] = [] as DmaResult[],
    public distributionCenterData: DistributionCenterResult[] = [] as DistributionCenterResult[],
    public divisionRegionsData: pcldto.pclDivisionRegion[] = [] as pcldto.pclDivisionRegion[],
    public createUpdateStorePayload: StoreCreateUpdatePayload = {} as StoreCreateUpdatePayload,
    public storeEditData: StoreCreateUpdatePayload = new StoreCreateUpdatePayload(),
    public storeSearchInProgress: boolean = false,
    public countySearchInProgress: boolean = false,
    public districtGetInProgress: boolean = false,
    public marketGetInProgress: boolean = false,
    public stateGetInProgress: boolean = false,
    public storeStatusesGetInProgress: boolean = false,
    public servicesGetInProgress: boolean = false,
    public deliveryMethodsGetInProgress: boolean = false,
    public createUpdateStoreInProgress: boolean = false,
    public timeZoneGetInProgress: boolean = false,
    public priceZoneGetInProgress: boolean = false,
    public ownersGetInProgress: boolean = false,
    public ownersSaveInProgress: boolean = false,
    public ownersDeleteInProgress: boolean = false,
    public exportListingAllInProgress: boolean = false,
    public deliveryAreaLookupGetInProgress: boolean = false,
    public exportOwnersInProgress: boolean = false,
    public socialMediaGetInProgress: boolean = false,

    public dmaGetInProgress: boolean = false,
    public distributionCenterGetInProgress: boolean = false,
    public storeListingAllDataGetInProgress: boolean = false,
    public divisionRegionsGetInProgress: boolean = false,
    public divisionRegions: pcldto.pclDivisionRegionWithRelated[] = [] as pcldto.pclDivisionRegionWithRelated[],
    public roleNames: pcldto.pclRoleName[] = [] as pcldto.pclRoleName[],
    public employees: pcldto.pclEmployee[] = [] as pcldto.pclEmployee[],
    public divisionRegionEdit: pcldto.pclDivisionRegionWithRelated = new pcldto.pclDivisionRegionWithRelated(
      null,
      [],
      0,
      '',
      null,
    ),
    public pclListingAllHeaders: StoreListingAllHeaders | null = null,
    public pclListingAllLoaded: boolean = false,
    public pclListingAllOptions: any = { sortBy: ['StoreNumber'], sortDesc: [false] },
    public pclRemovedHeaders: Array<any> = [] as Array<any>,
    public divisionRegionsNoRelated: pcldto.pclDivisionRegion[] = [] as pcldto.pclDivisionRegion[],
    public ownersData: pcldto.pclOwner[] = [] as pcldto.pclOwner[],
    public ownersDataCount: number = 0,
    public ownersDataOData: ODataProps | null = null,
    public ownersDataSearchText: string | null = null,
    public divisionRegionResults: pcldto.pclDivisionRegion[] = [] as pcldto.pclDivisionRegion[],
    public districtResults: pcldto.pclDistrict[] = [] as pcldto.pclDistrict[],
    public marketResults: pcldto.pclMarket[] = [] as pcldto.pclMarket[],
    public deliveryAreaLookup: pcldto.PclDeliveryAreaLookup[] = [] as pcldto.PclDeliveryAreaLookup[],
    public socialMediaLookup: SocialMedia[] = [] as SocialMedia[],
    public dogWashTubTypes: pcldto.PclDogWashTubType[] = [] as pcldto.PclDogWashTubType[],
    public dogWashTubTypesGetInProgress: boolean = false,
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public init(http: any, baseUrl: string | null): void {
    this.client = new PogonaPclClient(null, http, baseUrl);

    this.storeSearch.PageNumber = 1;
    this.storeSearch.Take = 50;
    this.storeSearch.OnlyOpenStores = true;
    this.storeSearch.GenericText = '';
    this.storeSearch.OrderBy = ['StoreNumber asc'];

    this.storeEditData.Store = new StoreSearchResult();
    this.storeEditData.Store.Address = new Address();
    this.storeEditData.CopyStore = new StoreCopy();

    this.storeListingAllParams = new StoreListingAllSearch();
    this.storeListingAllParams.skip = 0;
    this.storeListingAllParams.top = 50;

    this.storeListingAllFields = new StoreListingAllFields();

    this.ownersDataOData = new ODataProps();

    if (
      this.pclListingAllHeaders === null ||
      this.pclListingAllHeaders.version === -1 ||
      this.pclListingAllHeaders.version !== new StoreListingAllHeaders().version
    ) {
      this.pclListingAllHeaders = new StoreListingAllHeaders();
      this.pclRemovedHeaders = [
        { text: 'Fax', value: 'Fax' },
        { text: 'Planned Open Date', value: 'PlannedOpenDate' },
        { text: 'Closed Date', value: 'ClosedDate' },
        { text: 'Effective Date of Change', value: 'EffectiveDateOfChange' },
        { text: 'Linked Store', value: 'LinkedStore' },
        { text: 'Note', value: 'Note' },
        { text: 'County', value: 'County' },
        { text: 'Latitude', value: 'Latitude' },
        { text: 'Longitude', value: 'Longitude' },
        { text: 'DMA', value: 'DmaId' },
        { text: 'Divisions/Region', value: 'DivisionRegionId' },
        { text: 'VP Store', value: 'VpStore' },
        { text: 'Director', value: 'Director' },
        { text: 'District', value: 'DistrictId' },
        { text: 'DTL', value: 'Dtl' },
        { text: 'Market', value: 'Market' },
        { text: 'MTL', value: 'Mtl' },
        { text: 'Price Zone', value: 'PriceZoneId' },
        { text: 'Go Event 2', value: 'GoEvent2' },
        { text: 'Go Weekend', value: 'GoWeekend' },
        { text: 'Back Room Size', value: 'BackRoomSize' },
        { text: 'Ceiling Height', value: 'CeilingHeight' },
        { text: 'Number of Store Entrances', value: 'NumberOfStoreEntrances' },
        { text: 'Racking Type', value: 'RackingType' },
        { text: 'Racking Color', value: 'RackingColor' },
        { text: 'Racking Linear Feet', value: 'RackingLinearFeet' },
        { text: 'Square Feet', value: 'SquareFeet' },
        { text: 'Grooming', value: 'Grooming' },
        { text: 'Grooming Tables', value: 'GroomingTables' },
        { text: 'Grooming Tubs', value: 'GroomingTubs' },
        { text: 'Dog Wash', value: 'DogWash' },
        { text: 'Dog Wash Stations', value: 'DogWashStations' },
        { text: 'Enhanced Bakery', value: 'EnhancedBakery' },
        { text: 'VIP Clinics', value: 'VipClinics' },
        { text: 'VIP Wellness Center', value: 'VipWellnessCenter' },
        { text: 'Community Room', value: 'CommunityRoom' },
        { text: 'Dog Training', value: 'DogTraining' },
        { text: 'Salt Water Fish', value: 'SaltWaterFish' },
        { text: 'Boutique', value: 'Boutique' },
        { text: 'Online Grooming', value: 'OnlineGrooming' },
        { text: 'Curbside Pickup', value: 'CurbsidePickup' },
        { text: 'Curbside Pickup Start Date', value: 'CurbsidePickupStartDate' },
        { text: 'Delivery', value: 'Delivery' },
        { text: 'Delivery Start Date', value: 'DeliveryStartDate' },
        { text: 'Delivery Method', value: 'DeliveryMethodId' },
        { text: 'Delivery Area', value: 'DeliveryAreaMiles' },
        { text: 'Autoship', value: 'Autoship' },
        { text: 'Autoship Start Date', value: 'AutoshipStartDate' },
        { text: 'Franchise Entity', value: 'FranchiseEntity' },
        { text: 'Owner Group', value: 'OwnerGroup' },
        { text: 'Distribution Center', value: 'DistributionCenterId' },
        { text: 'Betta', value: 'Betta' },
        { text: 'Tropical Fish', value: 'TropicalFish' },
        { text: 'Reptile', value: 'Reptile' },
        { text: 'Bird', value: 'Bird' },
        { text: 'Small Animal', value: 'SmallAnimal' },
        { text: 'Hashtag', value: 'HashSocialMedia' },
        { text: 'Facebook', value: 'FacebookSocialMedia' },
        { text: 'Twitter', value: 'TwitterSocialMedia' },
        { text: 'Instagram', value: 'InstagramSocialMedia' },
        { text: 'Yelp', value: 'YelpSocialMedia' },
        { text: 'Google', value: 'GoogleSocialMedia' },
        { text: 'Dog Wash Tub Type', value: 'DogWashTubTypeId' },
        { text: 'Nailbar', value: 'Nailbar' },
        { text: 'Backup Delivery Method', value: 'SecondaryDeliveryMethodId' },
        { text: 'Cobrand', value: 'Cobrand' },
      ] as Array<any>;
    }
  }
}

export enum PclStoreEditMode {
  New = 1,
  Edit,
}

export class PclStoreNewEditState {
  constructor(
    public mode: PclStoreEditMode = PclStoreEditMode.New,
    public store: StoreSearchResult | null = null,
  ) {}
}

export const pclState: PclState = new PclState();

export class StoreService extends ServiceResult {
  constructor(
    public Selected: boolean = false,
    public DeliveryMethodId: number | null = null,
  ) {
    super();
  }
}

export class NewEditStoreValidationMessages {
  constructor(
    public StoreNumber: string[] = [] as string[],
    public StoreName: string[] = [] as string[],
    public StoreStatusId: string[] = [] as string[],
    public StorePhone: string[] = [] as string[],
    public StoreFax: string[] = [] as string[],
    public StoreEmail: string[] = [] as string[],
    public Address1: string[] = [] as string[],
    public Address2: string[] = [] as string[],
    public City: string[] = [] as string[],
    public Zip: string[] = [] as string[],
    public ZipPlus4: string[] = [] as string[],
    public Longitude: string[] = [] as string[],
    public Latitude: string[] = [] as string[],
    public LegalEntity: string[] = [] as string[],
    public OwnerEmail: string[] = [] as string[],
    public StoreNumberStoreCopy: string[] = [] as string[],
    public LegalEntityStoreCopy: string[] = [] as string[],
    public ClosedDate: string[] = [] as string[],
    public BackRoomSize: string[] = [] as string[],
    public Note: string[] = [] as string[],
    public CeilingHeight: string[] = [] as string[],
    public SquareFeet: string[] = [] as string[],
    public RackingType: string[] = [] as string[],
    public RackingColor: string[] = [] as string[],
    public RackingCount: string[] = [] as string[],
    public RackingLinearFeet: string[] = [] as string[],
    public NumberOfStoreEntrances: string[] = [] as string[],
    public GroomingNumberOfTables: string[] = [] as string[],
    public GroomingNumberOfTubs: string[] = [] as string[],
    public DogWashNumberOfStations: string[] = [] as string[],
    public FranchiseEntity: string[] = [] as string[],
    public OwnerGroup: string[] = [] as string[],
    public SocialMedias: string[] = [] as string[],
  ) {}
}

export class PclEmployeeValidationMessages {
  constructor(
    public FirstName: string[] = [] as string[],
    public LastName: string[] = [] as string[],
    public Email: string[] = [] as string[],
    public Cell: string[] = [] as string[],
  ) {}
}

export class PclDivisionRegionMessages {
  constructor(
    public Name: string[] = [] as string[],
    public VicePresidentRole: string[] = [] as string[],
  ) {}
}

export class PclDistrictMessages {
  constructor(
    public Name: string[] = [],
    public DivisionRegionId: string[] = [],
  ) {}
}

export class PclMarketMessages {
  constructor(
    public Name: string[] = [],
    public DistrictId: string[] = [],
  ) {}
}

export class MarketSelections {
  constructor(
    public marketId: number = 0,
    public districtId: number = 0,
    public name: string = '',
    public selectedMarketLeader: number = 0,
  ) {}
}

export class DistrictSelections {
  constructor(
    public districtId: number = 0,
    public selectedDistrictTeamLeaderId: number = 0,
    public selectedFieldTrainers: number[] = [] as number[],
    public selectedSSTLIds: number[] = [] as number[],
    public selectCDLIds: number[] = [] as number[],
    public markets: MarketSelections[] = [] as MarketSelections[],
  ) {}
}

export class MarketStoreDialog {
  constructor(
    public marketId: number,
    public showDialog: boolean = false,
  ) {}
}

export class DistrictStoreDialog {
  constructor(
    public districtId: number,
    public showDialog: boolean = false,
  ) {}
}

export class DistrictTab {
  constructor(
    public districtId: number,
    public tab: any,
  ) {}
}

export class StoreListingAllSearch {
  constructor(
    public searchParams: StoreListingAllSearchParam[] = [] as StoreListingAllSearchParam[],
    public top: number = 50,
    public skip: number = 0,
    public orderBy: Array<string> = [] as Array<string>,
  ) {}
}

export class StoreListingAllHeaders {
  constructor(
    // change this version number when the headers change,
    // this will force a refresh of the client's state
    public version = 40,
    public headers: Array<any> = [
      { text: 'Store Number', value: 'StoreNumber', width: '130px' },
      { text: 'Store Name', value: 'StoreName', width: '240px' },
      { text: 'Store Status', value: 'StoreStatusId', width: '210px' },
      { text: 'Phone', value: 'Phone', width: '140px' },
      { text: 'Email', value: 'Email', width: '250px' },
      { text: 'Business Unit', value: 'LegalEntity', width: '150px' },
      { text: 'Franchise Entity', value: 'FranchiseEntity', width: '160px', align: ' d-none' },
      { text: 'Owner Group', value: 'OwnerGroup', width: '150px', align: ' d-none' },
      { text: 'Is Corporate', value: 'IsCorporate', width: '160px' },
      { text: 'Owner Email', value: 'OwnerEmail', width: '250px' },
      { text: 'Time Zone', value: 'TimeZoneId', width: '150px' },
      { text: 'Address', value: 'Address', width: '210px' },
      { text: 'Address2', value: 'Address2', width: '170px' },
      { text: 'City', value: 'City', width: '170px' },
      { text: 'State', value: 'State', width: '140px' },
      { text: 'Zip', value: 'Zip', width: '120px' },
      { text: 'Fax', value: 'Fax', width: '140px', align: ' d-none' },
      {
        text: 'Planned Open Date',
        value: 'PlannedOpenDate',
        width: '210px',
        align: ' d-none',
      },
      { text: 'Actual Open Date', value: 'ActualOpenDate', width: '195px' },
      { text: 'Closed Date', value: 'ClosedDate', width: '160px', align: ' d-none' },
      {
        text: 'Effective Date of Change',
        value: 'EffectiveDateOfChange',
        width: '250px',
        align: ' d-none',
      },
      { text: 'Linked Store', value: 'LinkedStore', width: '160px', align: ' d-none' },
      { text: 'Note', value: 'Note', width: '220px', align: ' d-none' },
      { text: 'County', value: 'County', width: '170px', align: ' d-none' },
      { text: 'Latitude', value: 'Latitude', width: '155px', align: ' d-none' },
      { text: 'Longitude', value: 'Longitude', width: '155px', align: ' d-none' },
      { text: 'DMA', value: 'DmaId', width: '160px', align: ' d-none' },
      {
        text: 'Distribution Center',
        value: 'DistributionCenterId',
        width: '180px',
        align: ' d-none',
      },
      {
        text: 'Divisions/Region',
        value: 'DivisionRegionId',
        width: '190px',
        align: ' d-none',
      },
      { text: 'VP Store', value: 'VpStore', width: '165px', align: ' d-none' },
      { text: 'Director', value: 'Director', width: '165px', align: ' d-none' },
      { text: 'District', value: 'DistrictId', width: '165px', align: ' d-none' },
      { text: 'DTL', value: 'Dtl', width: '165px', align: ' d-none' },
      { text: 'Market', value: 'Market', width: '140px', align: ' d-none' },
      { text: 'MTL', value: 'Mtl', width: '165px', align: ' d-none' },
      { text: 'Price Zone', value: 'PriceZoneId', width: '150px', align: ' d-none' },
      { text: 'Go Event 2', value: 'GoEvent2', width: '155px', align: ' d-none' },
      { text: 'Go Weekend', value: 'GoWeekend', width: '160px', align: ' d-none' },
      { text: 'Back Room Size', value: 'BackRoomSize', width: '185px', align: ' d-none' },
      { text: 'Ceiling Height', value: 'CeilingHeight', width: '170px', align: ' d-none' },
      {
        text: 'Number of Store Entrances',
        value: 'NumberOfStoreEntrances',
        width: '265px',
        align: ' d-none',
      },
      { text: 'Racking Type', value: 'RackingType', width: '165px', align: ' d-none' },
      { text: 'Racking Color', value: 'RackingColor', width: '170px', align: ' d-none' },
      {
        text: 'Racking Linear Feet',
        value: 'RackingLinearFeet',
        width: '210px',
        align: ' d-none',
      },
      { text: 'Square Feet', value: 'SquareFeet', width: '160px', align: ' d-none' },
      { text: 'Grooming', value: 'Grooming', width: '150px', align: ' d-none' },
      { text: 'Grooming Tables', value: 'GroomingTables', width: '190px', align: ' d-none' },
      { text: 'Grooming Tubs', value: 'GroomingTubs', width: '180px', align: ' d-none' },
      { text: 'Dog Wash', value: 'DogWash', width: '150px', align: ' d-none' },
      {
        text: 'Dog Wash Stations',
        value: 'DogWashStations',
        width: '205px',
        align: ' d-none',
      },
      {
        text: 'Dog Wash Tub Type',
        value: 'DogWashTubTypeId',
        width: '205px',
        align: ' d-none',
      },
      { text: 'Enhanced Bakery', value: 'EnhancedBakery', width: '195px', align: ' d-none' },
      { text: 'VIP Clinics', value: 'VipClinics', width: '150px', align: ' d-none' },
      {
        text: 'VIP Wellness Center',
        value: 'VipWellnessCenter',
        width: '215px',
        align: ' d-none',
      },
      { text: 'Community Room', value: 'CommunityRoom', width: '200px', align: ' d-none' },
      { text: 'Dog Training', value: 'DogTraining', width: '160px', align: ' d-none' },
      { text: 'Nailbar', value: 'Nailbar', width: '160px', align: ' d-none' },
      { text: 'Salt Water Fish', value: 'SaltWaterFish', width: '180px', align: ' d-none' },
      { text: 'Boutique', value: 'Boutique', width: '150px', align: ' d-none' },
      { text: 'Online Grooming', value: 'OnlineGrooming', width: '190px', align: ' d-none' },
      { text: 'Curbside Pickup', value: 'CurbsidePickup', width: '190px', align: ' d-none' },
      {
        text: 'Curbside Pickup Start Date',
        value: 'CurbsidePickupStartDate',
        width: '260px',
        align: ' d-none',
      },
      { text: 'Delivery', value: 'Delivery', width: '150px', align: ' d-none' },
      {
        text: 'Delivery Start Date',
        value: 'DeliveryStartDate',
        width: '200px',
        align: ' d-none',
      },
      {
        text: 'Delivery Method',
        value: 'DeliveryMethodId',
        width: '185px',
        align: ' d-none',
      },
      {
        text: 'Backup Delivery Method',
        value: 'SecondaryDeliveryMethodId',
        width: '250px',
        align: ' d-none',
      },
      {
        text: 'Delivery Area',
        value: 'DeliveryAreaMiles',
        width: '185px',
        align: ' d-none',
      },
      { text: 'Autoship', value: 'Autoship', width: '150px', align: ' d-none' },
      {
        text: 'Autoship Start Date',
        value: 'AutoshipStartDate',
        width: '210px',
        align: ' d-none',
      },
      { text: 'Betta', value: 'Betta', width: '150px', align: ' d-none' },
      { text: 'Tropical Fish', value: 'TropicalFish', width: '150px', align: ' d-none' },
      { text: 'Reptile', value: 'Reptile', width: '150px', align: ' d-none' },
      { text: 'Bird', value: 'Bird', width: '150px', align: ' d-none' },
      { text: 'Small Animal', value: 'SmallAnimal', width: '150px', align: ' d-none' },

      { text: 'Hashtag', value: 'HashSocialMedia', width: '150px', align: ' d-none' },
      {
        text: 'Facebook',
        value: 'FacebookSocialMedia',
        width: '150px',
        align: ' d-none',
      },
      { text: 'Twitter', value: 'TwitterSocialMedia', width: '150px', align: ' d-none' },
      {
        text: 'Instagram',
        value: 'InstagramSocialMedia',
        width: '150px',
        align: ' d-none',
      },
      { text: 'Yelp', value: 'YelpSocialMedia', width: '150px', align: ' d-none' },
      { text: 'Google', value: 'GoogleSocialMedia', width: '150px', align: ' d-none' },
      { text: 'Cobrand', value: 'Cobrand', width: '150px', align: ' d-none' },
    ],
  ) {}
}

export class EditOwnerValidationMessages {
  constructor(
    public OwnerGroup: string[] = [] as string[],
    public FirstName: string[] = [] as string[],
    public LastName: string[] = [] as string[],
    public PersonalEmail: string[] = [] as string[],
    public PersonalPhone: string[] = [] as string[],
    public PersonalPhoneExtension: string[] = [] as string[],
    public OwnerEmail: string[] = [] as string[],
    public Entity: string[] = [] as string[],
    public Notes: string[] = [] as string[],
  ) {}
}

export class PclStoreExportPayload {
  constructor(
    public SearchParams: StoreListingAllSearch = new StoreListingAllSearch(),
    public Headers: Array<any> = [],
  ) {}
}

export default pclState;
