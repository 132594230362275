<template>
  <core-view-template title="Manage New Item Template">
    <v-row>
      <v-col>
        <v-file-input
          v-model="file"
          truncate-length="50"
          label="Click to choose new item template file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /></v-col
      ><v-col>
        <v-btn :loading="loading" @click="uploadClicked" :disabled="!file || validating"
          >Upload New Item Template</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="vpNewItemStore.newItemTemplates"
      :loading="loading"
      :hide-default-footer="true"
    >
      <template v-slot:item.CreatedOn="{ item }">
        {{ formatters.formatDate(item.CreatedOn) }}</template
      >
      <template v-slot:item.LastModified="{ item }">
        {{ formatters.formatDate(item.LastModified) }}</template
      >
      <template v-slot:item.Name="{ item }">
        <v-btn @click="blobClicked(item)" class="normal-case" text underline color="green">{{
          getFileName(item.Name)
        }}</v-btn>
      </template>
    </v-data-table>
  </core-view-template>
</template>
<script setup lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useVpNewItemStore } from '@/stores/vpnewitemstore';
import { useFormatters } from '@/composables/formatters';
import { getFileName } from '@/utils/FableUtils.fs.js';
import { NewItemTemplateFile } from '@psp/pogona_vendor_api_lib';

defineComponent({
  name: 'ManageNewItemTemplate',
});

const headers = [
  { text: 'Name', value: 'Name', sortable: false },
  { text: 'Current', value: 'Current', sortable: false },
  { text: 'Created On', value: 'CreatedOn', sortable: false },
  { text: 'Last Modified', value: 'LastModified', sortable: false },
  { text: 'Who Modified', value: 'Who', sortable: false },
];
const vpNewItemStore = useVpNewItemStore();
const formatters = useFormatters();
const loading = ref(false);
const file = ref<File | null>(null);

function uploadClicked() {
  if (file.value) {
    loading.value = true;
    vpNewItemStore
      .uploadNewItemTemplate(file.value)
      .then(() => {
        vpNewItemStore
          .getNewItemTemplates()
          .then(() => {
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
          });
      })
      .catch(() => {
        loading.value = false;
      });
  }
}
function blobClicked(item: NewItemTemplateFile) {
  vpNewItemStore.getNewItemTemplateBlob(item.Name);
}

onMounted(() => {
  loading.value = true;
  vpNewItemStore.getNewItemTemplates().then(() => {
    loading.value = false;
  });
});
</script>
<style scoped>
.normal-case {
  text-transform: none;
}
</style>
