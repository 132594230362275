<template>
  <v-dialog scrollable max-width="1024" v-model="reportDialog" @click:outside="closeReportDialog">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark height="24" class="py-0">
          <v-icon @click.stop="closeReportDialog">close</v-icon>
        </v-toolbar>
      </v-card-title>
      <v-card-text style="height: 50em">
        <data-report
          :style="{ width: '100%', height: '45em' }"
          v-model="internalReportData"
          :templateName="templateName"
          :supportsExcel="supportsExcel"
          :loading.sync="rloading"
          :fileShareReport="fileShareReport"
          @snackbar-error="$emit('snackbar-error', $event)"
        ></data-report>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    reportData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    templateName: {
      type: String,
      required: true,
      default: null,
    },
    supportsExcel: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileShareReport: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      reportDialog: this.value,
      internalReportData: this.reportData,
      rloading: this.loading,
    };
  },
  methods: {
    closeReportDialog() {
      this.reportDialog = false;
      this.internalReportData = {};
      this.$emit('input', this.reportDialog);
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.reportDialog = val;
        this.$emit('input', val);
      },
    },
    reportData: {
      handler: function (val) {
        this.internalReportData = val;
      },
    },
    rloading: {
      handler: function (val) {
        this.$emit('update:loading', val);
      },
    },
    loading: {
      handler: function (val) {
        this.rloading = val;
      },
    },
    reportDialog: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
