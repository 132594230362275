import { render, staticRenderFns } from "./InvoiceDetail.vue?vue&type=template&id=3abe5056&scoped=true"
import script from "./InvoiceDetail.vue?vue&type=script&setup=true&lang=ts"
export * from "./InvoiceDetail.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./InvoiceDetail.vue?vue&type=style&index=0&id=3abe5056&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3abe5056",
  null
  
)

export default component.exports