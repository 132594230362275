import {
  PogonaEcomClient,
  FileUploadProcessingStateInfo,
  PartnerFundingSearchResult,
  PartnerFundingSearchParams,
  PartnerFundingValidationResult,
} from '@psp/pogona_ecom_client_js';
import { DwBrand } from '@psp/pogona_ecom_client_js/DwBrand';
import DwCategory from '@psp/pogona_ecom_client_js/DwCategory';

export const partnerFundingData = 'partnerFundingData';
export const partnerFundingSearch = 'partnerFundingSearch';
export const brands = 'brands';
export const departments = 'departments';
export const subDepartments = 'subDepartments';
export const categories = 'categories';
export const subCategories = 'subCategories';
export const validationData = 'validationData';
export const uploadFile = 'uploadFile';
export const showProcessing = 'showProcessing';
export const processingData = 'processingData';

export class PartnerFundingState {
  constructor(
    public client: PogonaEcomClient | null = null,
    public partnerFundingData: PartnerFundingSearchResult[] = [] as PartnerFundingSearchResult[],
    public partnerFundingSearch: PartnerFundingSearchParams = new PartnerFundingSearchParams(),
    public brands: DwBrand[] = [] as DwBrand[],
    public departments: DwCategory[] = [] as DwCategory[],
    public subDepartments: DwCategory[] = [] as DwCategory[],
    public categories: DwCategory[] = [] as DwCategory[],
    public subCategories: DwCategory[] = [] as DwCategory[],
    public validationData: PartnerFundingValidationResult | null = null,
    public uploadFile: File | null = null,
    public showProcessing: boolean = false,
    public processingData: FileUploadProcessingStateInfo[] = [] as FileUploadProcessingStateInfo[],
  ) {
    this.partnerFundingSearch.PageSize = 50;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public init(http: any, baseUrl: string | null): void {
    this.client = new PogonaEcomClient(null, http, baseUrl);
  }
}

export const partnerFundingState: PartnerFundingState = new PartnerFundingState();

export default partnerFundingState;
