<template>
  <core-view-template title="Not Authorized">
    The logged in user
    <strong>{{ username }}</strong> does not have rights to view this page.
  </core-view-template>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'unauthorized',
  computed: {
    ...mapState('app', ['username']),
  },
};
</script>

<style scoped lang="scss">
strong {
  font-size: 1em;
  font-weight: bold;
}
</style>
