var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{ref:"tourMenu",attrs:{"absolute":"","allow-overflow":false,"close-on-content-click":false,"close-on-click":false,"origin":_vm.currPlacement},model:{value:(_vm.showTour),callback:function ($$v) {_vm.showTour=$$v},expression:"showTour"}},[_c('v-list',{staticClass:"pa-0 ma-0 tour-list"},[_c('v-list-item',{staticClass:"pa-0 ma-0"},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTour),expression:"showTour"}],class:_vm.arrowClasses,attrs:{"id":"arrow"}},[_vm._v(_vm._s(_vm.arrowIcon))]),_c('v-card',{class:_vm.arrowClasses,attrs:{"dark":"","max-width":_vm.maxWidth,"min-width":"10em","id":"tourContent"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.stepTitle))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.stepSubtitle))]),_c('v-card-text',[_vm._v(_vm._s(_vm.stepText))]),(_vm.stepVideos && _vm.stepVideos.length > 0)?_c('video',{ref:"videoPlayer",staticClass:"pa-2",attrs:{"width":"100%","height":"100%","controls":"","crossorigin":"","autoplay":_vm.autoplay},domProps:{"muted":_vm.autoplay}},[_vm._l((_vm.stepVideos),function(video,vix){return _c('source',{key:vix,attrs:{"src":video.url,"type":video.type}})}),_vm._l((_vm.stepSubtitles),function(sub,six){return _c('track',{key:six,attrs:{"src":sub.url,"label":sub.label,"kind":sub.kind || 'subtitles',"srclang":sub.lang || 'en',"default":sub.default || six === 0 ? 'default' : null}})})],2):_vm._e(),_c('v-card-actions',[_c('v-container',{attrs:{"fluid":""}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"end","justify":"start"}},[_vm._t("actions",function(){return [(_vm.currStep === 0 && !(_vm.currStep === _vm.totalSteps - 1))?_c('v-btn',{on:{"click":function($event){_vm.showTooltip = false;
                        _vm.showTour = false;}}},[_vm._v("Skip")]):_vm._e(),(_vm.currStep > 0 && _vm.totalSteps > 1)?_c('v-btn',{on:{"click":function($event){_vm.currStep -= 1;
                        _vm.setStep();}}},[_vm._v("Previous")]):_vm._e(),(_vm.currStep < _vm.totalSteps - 1)?_c('v-btn',{on:{"click":function($event){_vm.currStep += 1;
                        _vm.setStep();}}},[_vm._v("Next")]):_vm._e(),(_vm.currStep === _vm.totalSteps - 1)?_c('v-btn',{on:{"click":function($event){_vm.showTooltip = false;
                        _vm.showTour = false;
                        _vm.currentStep = 0;}}},[_vm._v("Finish")]):_vm._e()]}),_vm._t("doNotShow",function(){return [(_vm.hideDoNotShowCheckbox === false)?_c('v-checkbox',{staticClass:"ml-3 do-not-show-again",attrs:{"dark":"","label":"Do not show again"},on:{"change":_vm.doNotShow},model:{value:(_vm.doNotShowCheckbox),callback:function ($$v) {_vm.doNotShowCheckbox=$$v},expression:"doNotShowCheckbox"}}):_vm._e()]})],2)],1)],1)],1)],1)],1)],1)],1),_c('div',[_vm._t("showTour",function(){return [(!_vm.showTour && _vm.hideHelpIcon === false)?_c('v-icon',{staticClass:"help-again-clickable",on:{"click":function($event){_vm.currStep = 0;
          _vm.setStep();
          _vm.showTour = true;}}},[_vm._v("help_outline")]):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }