import { PogonaEcomClient } from '@psp/pogona_ecom_client_js';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class EcomProductState {
  constructor(public client: PogonaEcomClient | null = null) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public init(http: any, baseUrl: string | null): void {
    this.client = new PogonaEcomClient(null, http, baseUrl);
  }
}

export const ecomProductState: EcomProductState = new EcomProductState();

export default ecomProductState;
