<template>
  <core-view-template title="Random UPC Generator">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            v-model="numberOfUpcs"
            label="Number of UPCs"
            number
            v-debounce:300="generateUpcs"
            :error-messages="generatedUpcErrors"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea v-model="generatedUpcs" :rows="maxRows" label="UPCs" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="generateUpcs">Generate</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ref, defineComponent } from 'vue';
import UpcYupValidator from '@/utils/UpcYupValidation';

defineComponent({
  name: 'UpcGenerator',
});

const numberOfUpcs = ref(10);
const generatedUpcs = ref('');
let generatedUpcErrors = [] as string[];
const maxUpcs = 1000;

function generateUpcs() {
  generatedUpcErrors = [];
  if (numberOfUpcs.value > maxUpcs) {
    generatedUpcErrors.push(`Cannot generate more than ${maxUpcs.toLocaleString()} UPCs`);
    return;
  }

  generatedUpcs.value = '';
  for (let upcIx = 0; upcIx < numberOfUpcs.value; upcIx++) {
    const randomNumber = Math.floor(Math.random() * 1000000000000)
      .toString()
      .padStart(12, '0');
    const checkDigit = UpcYupValidator.upc12CheckDigit(randomNumber);
    generatedUpcs.value += `${randomNumber.substring(0, randomNumber.length - 1)}${checkDigit}\n`;
  }
}

const maxRows = computed(() => {
  return Math.min(15, numberOfUpcs.value);
});

generateUpcs();
</script>
