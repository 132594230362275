import Vue from 'vue';

Vue.filter('initalCaps', value => {
  if (value) {
    const cappedWords = [];
    const words = value.split(' ');

    words.forEach(word => {
      cappedWords.push(`${word[0].toUpperCase()}${word.substring(1, word.length).toLowerCase()}`);
    });

    return cappedWords.join(' ');
  }
  return null;
});

Vue.filter('truncate', (text, length, truncatorText) => {
  const truncator = truncatorText || '...';
  const content = text;
  return content.length > length ? content.slice(0, length) + truncator : content;
});
