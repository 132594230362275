<script>
/* eslint-disable no-unused-expressions */
import VImg from 'vuetify/es5/components/VImg/VImg';
import AxiosHelpers from '@/utils/AxiosHelpers';

export default {
  extends: VImg,
  props: {
    headers: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      axiosHelpers: new AxiosHelpers(),
    };
  },
  methods: {
    loadImage() {
      this.$authApi.http
        .get(this.src, {
          responseType: 'arraybuffer',
          headers: this.headers,
        })
        .then(r => {
          this.image = new Image();
          this.image.src = this.axiosHelpers.axiosResponseToDataUri(r);

          // Set sizes
          this.sizes && (this.image.sizes = this.sizes);
          this.normalisedSrc.srcset && (this.image.srcset = this.normalisedSrc.srcset);
          this.aspectRatio || this.pollForSize(this.image);

          // Fire the onload method
          this.onLoad();
        })
        .catch(err => {
          this.$emit('error', err);
        });
    },
  },
};
</script>
