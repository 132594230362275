import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PogonaApiClient } from '@psp/pogona_api_client_js';
import store from '@/store';

export const useKeyTrackingStore = defineStore('keyTrackingStore', () => {
    const client = ref<PogonaApiClient | null>(null);
    const trackingKeysGetInProgress = ref<boolean>(false);
    const generatingTrackingKeyValueInProgress = ref<boolean>(false);
    const trackingKeys = ref([]);
    const generatedKey = ref('');

    function init(http: any, baseUrl: string | null): void {
        client.value = new PogonaApiClient(null, http, baseUrl);
    }

    async function getTrackingKeys(): Promise<any> {
        trackingKeysGetInProgress.value = true;
        return client
            .value!.apiGet('./pogona/keytracking')
            .then(x => {
                trackingKeys.value = x.data;
                trackingKeysGetInProgress.value = false;
            })
            .catch(err => {
                store.commit(
                    'setError',
                    {
                        err: err,
                        text: 'Error getting tracking keys.',
                        id: '55f25a69-8ef1-4066-9855-e392e8136b02',
                    },
                    { root: true },
                );
                throw err;
            });
    }

    async function generateNextTrackingKeyValue(key: string): Promise<any> {
        generatingTrackingKeyValueInProgress.value = true;
        return client
            .value!.apiGet(`./pogona/keytracking/${key}`)
            .then(x => {
                generatedKey.value = x.data;
                generatingTrackingKeyValueInProgress.value = false;
            })
            .catch(err => {
                store.commit(
                    'setError',
                    {
                        err: err,
                        text: 'Error generating next key.',
                        id: '6b4c7e62-efe5-41a0-8630-6bb3b6717f9a',
                    },
                    { root: true },
                );
                throw err;
            });
    }

    return {
        client,
        trackingKeysGetInProgress,
        trackingKeys,
        generatingTrackingKeyValueInProgress,
        generatedKey,
        generateNextTrackingKeyValue,
        init,
        getTrackingKeys
    };
});