const initState = (): unknown => {
  return {
    batchInfoItems: {},
    approvedTypes: [],
    labelItems: {},
    createdBatchInfo: null,
    selectedItems: [],
    search: null,
    nonActivatedToggle: false,
    queuedItems: { activated: [], nonActivated: [] },
    selectedBarcodeSetups: null,
  };
};

export { initState };

export default {
  batchInfoItems: {},
  approvedTypes: [],
  labelItems: {},
  createdBatchInfo: null,
  selectedItems: [],
  search: null,
  nonActivatedToggle: false,
  queuedItems: { activated: [], nonActivated: [] },
  selectedBarcodeSetups: null,
};
