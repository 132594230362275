<template>
  <core-signs-labels-view-template title="Store Set Staging" :showStoreNumber="false">
    <core-info-blurb title="What is store set staging?">
      The store set staging page allows you to view and make changes to data imported via the data
      import page, from the "Full set import" type. The data here will be applied to the actual
      store set data the next morning.
    </core-info-blurb>
    <v-row>
      <v-col cols="12">
        <v-progress-linear v-if="initLoading" indeterminate></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <core-view-section title="Sets">
          <v-row>
            <v-col>
              <v-btn
                @click="exportAllDesignations"
                :disabled="exportAllDesignationsLoading"
                :loading="exportAllDesignationsLoading"
                >Export All Store Designations</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-combobox
                prepend-icon="store"
                v-model="selectedStore"
                :items="storesInStaging"
                :label="`Store${storesInStaging.length === 1 ? '' : 's'} in Staging`"
              ></v-combobox>
            </v-col>
            <v-col cols="3">
              <v-dialog v-model="mappedSetManageDialog" max-width="1024" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="!selectedStore" v-on="on">Manage Mapped Sets</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Manage Mapped Sets Store {{ selectedStore }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <span>Mapped Sets</span>
                        </v-row>
                        <v-row>
                          <v-text-field
                            prepend-icon="search"
                            v-model="mappedSetsSearch"
                            v-debounce:300="filterMappedStoreSets"
                            @click:clear="
                              mappedSetsSearch = null;
                              filterMappedStoreSets();
                            "
                            label="Search (POG ID, Description)"
                            :clearable="true"
                          ></v-text-field>
                        </v-row>
                        <v-row>
                          <v-data-table
                            :headers="mappedHeaders"
                            :items="mappedStoreSetsView"
                            :loading="mappedStoreSetsLoading"
                            :options="{ itemsPerPage: 9999 }"
                            item-key="setId"
                            class="elevation-1 scrollable"
                          >
                            <template v-slot:item.action="{ item }">
                              <v-btn @click="setUpMappingToRemove(item)">Remove</v-btn>
                            </template>
                          </v-data-table>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <span>Unmapped Sets</span>
                        </v-row>
                        <v-row>
                          <v-text-field
                            prepend-icon="search"
                            v-model="unmappedSetsSearch"
                            v-debounce:300="filterUnmappedStoreSets"
                            @click:clear="
                              unmappedSetsSearch = null;
                              filterUnmappedStoreSets();
                            "
                            label="Search (POG ID, Description)"
                            :clearable="true"
                          ></v-text-field>
                        </v-row>
                        <v-row>
                          <v-data-table
                            :headers="unmappedHeaders"
                            :items="unmappedStoreSetsView"
                            :loading="unmappedStoreSetsLoading"
                            :options="{ itemsPerPage: 9999 }"
                            item-key="setId"
                            class="elevation-1 scrollable"
                          >
                            <template v-slot:item.action="{ item }">
                              <v-btn @click="setUpMappingToAdd(item)">Add</v-btn>
                            </template>
                          </v-data-table>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeSetsManager">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="3">
              <v-dialog v-model="deleteStoreDialog" max-width="700" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="!selectedStore" v-on="on">Delete Selected Store</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Delete Store {{ selectedStore }}</span>
                  </v-card-title>
                  <v-card-text>
                    Are you sure you want to DELETE store {{ selectedStore }} from staging? This
                    cannot be undone.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="deleteStoreFromStaging"
                      :disabled="deleteStoreLoading"
                      :loading="deleteStoreLoading"
                      :color="deleteStoreLoading ? '' : 'red'"
                      :dark="!deleteStoreLoading"
                      >Delete</v-btn
                    >
                    <v-btn @click="deleteStoreDialog = false" :disabled="deleteStoreLoading"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <store-set
                v-if="selectedStore"
                :setType="setType"
                :storeNumberOverride="selectedStore"
                v-model="storeSet"
                @snackbar-error="$emit('snackbar-error', $event)"
                @selectedChange="selectedChange"
                @swappedSet="getDataForMappingDataTables"
                :selectedTab.sync="selectedTab"
                :selectedStoreSet.sync="selectedStoreSet"
              ></store-set>
            </v-col>
          </v-row>
        </core-view-section>
      </v-col>
      <v-col cols="12" class="pt-0">
        <core-view-section v-if="showStoreSetDataSection" title="Items" cols="12">
          <v-col id="storeSetData">
            <store-set-data
              :setType="setType"
              :storeNumberOverride="selectedStore"
              v-model="storeSetData"
              :storeSet="storeSet"
              :setSections="setSections"
              :selectedTab="selectedTab"
              :allSets="storeSet.allSelected"
              :selectedStoreSet="selectedStoreSet"
              @createdBatchInfoEvent="createdBatchInfoEvent"
              @snackbar-error="$emit('snackbar-error', $event)"
            >
            </store-set-data>
          </v-col>
        </core-view-section>
      </v-col>
    </v-row>
    <v-divider id="batchInfoItemsDivider" class="ma-8"></v-divider>
    <v-col cols="12">
      <core-view-section
        v-if="createdBatchInfo"
        v-show="createdBatchInfo.printImmediately !== true"
        title="Queue Items"
      >
        <batch-check-items
          v-model="batchInfoItems"
          :batchInfo.sync="createdBatchInfo"
          @dataBound="batchInfoItemsDataBound"
          @error="$emit('snackbar-error', $event)"
          :footerBatchAndId="footerBatchAndId"
          :autoCompleteBatch="true"
          :showCompleteToggle="false"
          :overrideStoreNumber="selectedStore"
          :pageBreakOnNewStoreSet="true"
          :hideBatchNameInFooter="true"
          :askToRecalculate="true"
          :showChangeAllLabelTypesButton="false"
          originatingPage="Store Set Staging"
        ></batch-check-items>
      </core-view-section>
    </v-col>
    <v-row v-if="processingDataDialog">
      <v-dialog v-model="processingDataDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Processing Full Store Set Import</span>
          </v-card-title>
          <v-card-text>
            The full store set data is being processed right now. Please check on the status at the
            <router-link :to="{ name: 'sl_admin_import_data' }">
              <a>Store Set Import</a> </router-link
            >&nbsp;page.
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-if="newStoreSetAssignmentDialog">
      <v-dialog v-model="newStoreSetAssignmentDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Enter print sequence</span>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="printSequence" :rules="printSequenceRules"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addMapping" :loading="savingMappedSets" :disabled="savingMappedSets"
              >Add</v-btn
            >
            <v-btn @click="newStoreSetAssignmentDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-if="removeMappingDialog">
      <v-dialog v-model="removeMappingDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Remove Store Set Mapping</span>
          </v-card-title>
          <v-card-text>
            Are you sure you want to remove mapping
            {{ mappingToBeRemoved ? mappingToBeRemoved.setId : '' }} '{{
              mappingToBeRemoved ? mappingToBeRemoved.description : ''
            }}'?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="removeMapping" :loading="savingMappedSets" :disabled="savingMappedSets"
              >Remove</v-btn
            >
            <v-btn @click="removeMappingDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import BatchCheckItems from '../BatchCheckItems.vue';
import StoreSet from '../StoreSetComponent.vue';
import StoreSetData from '../StoreSetDataComponent.vue';
import { ContainerClient } from '@azure/storage-blob';
import { saveAs } from 'file-saver';

export default {
  components: {
    BatchCheckItems,
    StoreSet,
    StoreSetData,
  },
  data() {
    return {
      selectedTab: null,
      initLoading: true,
      storeSet: null,
      storeSetData: null,
      setSections: [],
      storesInStaging: [],
      selectedStore: null,
      setType: 3,
      processingDataDialog: false,
      mappedSetManageDialog: false,
      savingMappedSets: false,
      allStoreSets: [],
      mappedStoreSets: [],
      mappedStoreSetsView: [],
      unmappedStoreSets: [],
      unmappedStoreSetsView: [],
      mappedSetsSearch: null,
      mappedStoreSetsLoading: false,
      unmappedStoreSetsLoading: false,
      unmappedSetsSearch: null,
      newStoreSetAssignmentDialog: false,
      mappingToBeAdded: null,
      mappingToBeRemoved: null,
      removeMappingDialog: false,
      exportAllDesignationsLoading: false,
      deleteStoreDialog: false,
      deleteStoreLoading: false,
      printSequence: 1,
      selectedStoreSet: null,
      mappedHeaders: [
        {
          text: 'ID',
          value: 'setId',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Size',
          value: 'setSize',
        },
        {
          text: 'P/Seq',
          value: 'printSequence',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],
      unmappedHeaders: [
        {
          text: 'ID',
          value: 'setId',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Size',
          value: 'setSize',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],
      printSequenceRules: [
        v => {
          if (isNaN(v) === true) {
            return 'Print sequence must be a number.';
          }

          if (v < 1) {
            v = 1;
          }
          return true;
        },
      ],
    };
  },
  computed: {
    ...mapState('app', ['storeNumber', 'username']),
    batchInfoItems: {
      get() {
        return this.$store.state.storesetstage.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit('storesetstage/batchInfoItems', val);
      },
    },
    createdBatchInfo: {
      get() {
        return this.$store.state.storesetstage.createdBatchInfo || null;
      },
      set(val) {
        this.$store.commit('storesetstage/createdBatchInfo', val);
      },
    },
    footerBatchAndId() {
      return '{{ POG_ID }}|{{ POG_NAME }}';
    },
    showStoreSetDataSection() {
      return (
        this.selectedStoreSet &&
        this.selectedStoreSet.selected &&
        this.selectedStoreSet.selected.length > 0
      );
    },
  },
  async created() {
    const processing = (
      await this.$authApi.http.get(
        'label/storesetdataimport/$count?$filter=storeSetDataImportTypeId eq 2 and storeSetDataImportStatusId in (1,2)',
      )
    ).data;

    if (processing > 0) {
      this.processingDataDialog = true;
    } else {
      const initData = await Promise.all([
        this.$authApi.http.get('label/storesetassignstage/stores?$top=99999'),
        this.$authApi.http.get('label/storesetstage?$top=99999&$orderBy=setId'),
      ]);

      this.storesInStaging = initData[0].data;
      this.allStoreSets = initData[1].data;
      // if the currently logged in store is in staging, use that
      if (this.storesInStaging.indexOf(this.storeNumber) > -1) {
        this.selectedStore = this.storeNumber;
      }
    }

    this.initLoading = false;
  },
  methods: {
    selectedChange() {
      this.createdBatchInfo = null;
    },
    createdBatchInfoEvent(e) {
      this.batchInfoItems = {};
      this.createdBatchInfo = e;
    },
    batchInfoItemsDataBound() {
      // Jump to the batch data.
      this.setToBatchLoading = false;
      this.$vuetify.goTo('#batchInfoItemsDivider');
    },
    async addMapping() {
      this.savingMappedSets = true;
      try {
        await this.$authApi.http.post('label/storesetassignstage/now', {
          storeNumber: this.selectedStore,
          setId: this.mappingToBeAdded.setId,
          printSequence: this.printSequence,
        });
        this.newStoreSetAssignmentDialog = false;
        this.savingMappedSets = false;
        this.getDataForMappingDataTables();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error adding mapping.',
          err,
          id: '8a2339bf-5db5-4f74-a8eb-50ae2d9dac68',
        });
        this.savingMappedSets = false;
      }
    },
    async removeMapping() {
      this.savingMappedSets = true;
      try {
        await this.$authApi.http.delete(
          `label/storesetassignstage/${this.mappingToBeRemoved.storeSetAssignStageId}`,
        );
        this.removeMappingDialog = false;
        this.savingMappedSets = false;
        this.getDataForMappingDataTables();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error deleting mapping.',
          err,
          id: '78fb4630-a505-4c80-82c1-870bf9eb8fa1',
        });
        this.savingMappedSets = false;
      }
    },
    setUpMappingToAdd(item) {
      this.mappingToBeAdded = item;
      this.newStoreSetAssignmentDialog = true;
    },
    setUpMappingToRemove(item) {
      this.mappingToBeRemoved = item;
      this.removeMappingDialog = true;
    },
    filterMappedStoreSets() {
      if (this.mappedSetsSearch && this.mappedSetsSearch.trim().length > 0) {
        const searchValue = this.mappedSetsSearch.trim().toUpperCase();
        const filteredMappedStoreSets = this.mappedStoreSets.filter(
          x =>
            // eslint-disable-next-line implicit-arrow-linebreak
            x.setId.toString().indexOf(searchValue) > -1 ||
            (x.description && x.description.toUpperCase().indexOf(searchValue)) > -1,
        );
        this.mappedStoreSetsView = filteredMappedStoreSets;
      } else {
        this.mappedStoreSetsView = this.mappedStoreSets;
      }
    },
    filterUnmappedStoreSets() {
      if (this.unmappedSetsSearch && this.unmappedSetsSearch.trim().length > 0) {
        const searchValue = this.unmappedSetsSearch.trim().toUpperCase();
        const filteredUnmappedStoreSets = this.unmappedStoreSets.filter(
          x =>
            // eslint-disable-next-line implicit-arrow-linebreak
            x.setId.toString().indexOf(searchValue) > -1 ||
            (x.description && x.description.toUpperCase().indexOf(searchValue)) > -1,
        );
        this.unmappedStoreSetsView = filteredUnmappedStoreSets;
      } else {
        this.unmappedStoreSetsView = this.mappedStoreSets;
      }
    },
    async getDataForMappingDataTables() {
      this.mappedStoreSetsLoading = true;
      this.unmappedStoreSetsLoading = true;
      this.mappedStoreSets = [];
      this.unmappedStoreSets = [];

      try {
        const mappedSetsToStore = (
          await this.$authApi.http.get(
            `label/storesetassignstage?$filter=storeNumber eq '${this.selectedStore}' and setId gt 0&$select=setId,storeSetAssignStageId,printSequence&$top=99999&$orderBy=setId`,
          )
        ).data;

        // iterate over the assigned sets, and grab the description from allStoreSets
        mappedSetsToStore.forEach(m => {
          const storeSetInfo = this.allStoreSets.filter(x => x.setId === m.setId);
          const mappedStoreSet = {
            setId: m.setId,
            storeSetAssignStageId: m.storeSetAssignStageId,
            printSequence: m.printSequence,
          };
          if (storeSetInfo && storeSetInfo.length === 1) {
            mappedStoreSet.description = storeSetInfo[0].description;
            mappedStoreSet.setSize = storeSetInfo[0].setSize;
          }
          this.mappedStoreSets.push(mappedStoreSet);
        });
        this.mappedStoreSetsView = this.mappedStoreSets;

        // now, figure out what isn't mapped
        this.allStoreSets.forEach(n => {
          if (this.mappedStoreSets.findIndex(mm => n.setId === mm.setId) === -1) {
            this.unmappedStoreSets.push(n);
          }
        });
        this.unmappedStoreSetsView = this.unmappedStoreSets;

        this.mappedStoreSetsLoading = false;
        this.unmappedStoreSetsLoading = false;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to load store set mappings',
          err,
          id: '0f3c11ab-2b47-482d-874d-a214a6305ebe',
        });
        this.mappedStoreSetsLoading = false;
        this.unmappedStoreSetsLoading = false;
      }
    },
    closeSetsManager() {
      this.mappedSetManageDialog = false;
      // force refresh of store set grid
      const tmpSelectedStore = this.selectedStore;
      this.selectedStore = null;
      this.storeSet = {};
      this.mappedSetsSearch = null;
      this.unmappedSetsSearch = null;

      setImmediate(() => {
        this.selectedStore = tmpSelectedStore;
      });
    },
    async exportAllDesignations() {
      try {
        this.exportAllDesignationsLoading = true;
        const exportedDesignations = (
          await this.$authApi.http.get('storeset/storesetassignstage/all')
        ).data;

        const urlParts = exportedDesignations.blobLocation.split('/');

        const containerClient = new ContainerClient(`${this.$storageHost}/${urlParts[0]}`);
        const blobClient = containerClient.getBlobClient(`${urlParts[1]}/${urlParts[2]}`);
        const download = await blobClient.download();
        const blobBody = await download.blobBody;

        // save the file to disk
        saveAs(blobBody, urlParts[urlParts.length - 1]);

        this.exportAllDesignationsLoading = false;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to export all designations',
          err,
          id: '0f3c11ab-2b47-482d-874d-a214a6305ebe',
        });
        this.exportAllDesignationsLoading = false;
      }
    },
    async deleteStoreFromStaging() {
      try {
        this.deleteStoreLoading = true;

        await this.$authApi.http.delete(`label/storesetassignstage/store/${this.selectedStore}`);
        this.storesInStaging = (
          await this.$authApi.http.get('label/storesetassignstage/stores')
        ).data;

        // either set the newly selected store back to what's in the footer, or just the first one
        this.selectedStore =
          this.storeNumber !== this.selectedStore ? this.storeNumber : this.storesInStaging[0];

        this.deleteStoreLoading = false;
        this.deleteStoreDialog = false;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to delete store from staging.',
          err,
          id: '4ec361b1-b0f5-431f-a49a-b1b5cd284c10',
        });
        this.deleteStoreLoading = false;
      }
    },
  },
  watch: {
    selectedStore: {
      handler: async function (val) {
        if (val) {
          await this.getDataForMappingDataTables();
        }
      },
    },
    storeSet: {
      handler(val) {
        this.$store.commit('storesetstage/storeSet', val);
      },
      deep: true,
    },
    createdBatchInfo: {
      handler(val) {
        this.$store.commit('storesetstage/createdBatchInfo', val);
      },
      deep: true,
    },
    selectedTab: {
      handler() {
        this.createdBatchInfo = null;
      },
    },
  },
};
</script>
