/* eslint-disable @typescript-eslint/no-explicit-any */

import { PogovBatch } from '@psp/vendorapi';

export enum BatchType {
  NewItemBatch,
  PackageChangeBatch,
}

export interface Aggregate {
  AggregateType: string;
  Changes: Array<any>;
  ExpectedVersion: any;
  Id: string;
  Version: any;
}

export interface PrivateLabelData {
  Images: string[];
  ProgramName: string;
  HtsCode: number;
  DutyRate: number;
  FobPort: string;
}

export interface BarCodeData {
  FullUnitBarcode: string;
  CaseBarcode: string | null;
  OtherBarcode: string | null;
}

export interface ProductData {
  ProductName: string;
  RetailPackageSize: string;
  RetailPackageUOM: any;
}

export interface PartPartnerItemData {
  ManufacturerAccountNumber: string;
  Brand: string;
  VendorId: string;
  ExternalItemNumber: string;
  MasterCase: number;
  InnerCasePack: number;
  LayerQuantity: number;
  PalletQuantity: number;
  DistributionNetwork: any;
  DirectToStore: any;
  DirectToStoreOrVendorIntoPspdPurchaseCost: number;
  MfgSuggestedRetail: number;
  MapPrice: number;
  IMap: number;
  MaterialSafetyDataSheetNeeded: boolean;
  MinimumOrderQuantity: number;
  UnitWeight: number;
  UnitWeightUOM: string;
  UnitHeight: number;
  UnitWidth: number;
  UnitDepth: number;
  CaseHeight: number;
  CaseWidth: number;
  CaseDepth: number;
  ProductAttribute: string;
}

export interface PetPartnerData {
  RequestType: any;
  Barcode: BarCodeData;
  Product: ProductData | null;
  ItemData: PartPartnerItemData | null;
}

export interface PluData {
  PluScan: string;
  PluAssigned: string;
  DaxItemNumber: string;
}

export interface SignsAndLabelData {
  Description1: string;
  Description2: string;
}

export interface CategoryData {
  VendorNumber: string;
  ShipsToStores: any;
  ShipUnitCostFromPspd: number;
  RetailBasePrice: number;
  RetailHighPrice: number | null;
  Hierarchy: string;
  PlanogramLocationGroup: string;
  CategoryManagerName: string;
  PspdInventoryManagerName: string;
  Reason: any;
  Reason2: string | null;
  Season: string | null;
  Seasonal: string | null;
  Year: string | null;
  Category: string | null;
}

export interface PetPartnerInsetData {
  AutoShipEligible: boolean;
  InitialDiscountFundingPercent: number;
  OnGoingFundingDiscountPercent: number;
}

export interface Distributor {
  DistributorId: string;
  ExternalItemId: string;
  StoreCost: number;
  ShipToStoreAs: any;
}

export interface DsdDistributorData {
  DaxItemNumber: string;
  Upc: string;
  ItemDescription: string;
  Distributors: any;
}

export interface NewItemRequest {
  Agg: Aggregate;
  RequestID: number;
  PrimaryUPC: string;
  ItemDescription: string;
  Status: any;
  RequestType: any;
  PrivateLabel: PrivateLabelData | null;
  PetPartnerData: PetPartnerData | null;
  PetPartnerInsetData: PetPartnerInsetData | null;
  Plu: PluData | null;
  SignsAndLabels: SignsAndLabelData | null;
  Category: CategoryData | null;
  Distributors: DsdDistributorData | null;
  RejectReasons: any;
}

export interface Manufacturer {
  ManufacturerAccountNumberId: number;
  Name: string;
  Number: string;
}

export interface Brand {
  BrandId: number;
  Name: string;
  Number: number;
}

export interface Vendor {
  VendorId: number;
  Name: string;
  Number: string;
}

export interface DistributionCenter {
  DistributionCenterId: number;
  Name: string;
}

export interface PlanogramLocationGroup {
  PlanogramLocationGroupId: number;
  Name: string;
}

export interface ReasonForNewItem {
  ReasonForNewItemId: number;
  Reason: string;
}

export interface PspEmployee {
  PspEmployeeId: number;
  FirstName: string;
  LastName: string;
}

export enum SearchType {
  Equals = 1,
  Like = 2,
  In = 3,
  DateRange = 4,
  NumberRange = 5,
}

export interface SearchParam {
  Field: string;
  SearchValue: any;
  SearchType: SearchType;
}

export interface DateRange {
  Start: Date | null;
  End: Date | null;
}

export interface DoubleRange {
  Start: number | null;
  End: number | null;
}

export interface PogovNewItemRequest {
  AggregateId: string | null;
  AutoShipEligible: boolean | null;
  Barcode: string;
  Brand: string;
  CaseBarcode: string;
  CaseDepth: number | null;
  CaseHeight: number | null;
  CaseWidth: number | null;
  CategoryManagerName: string;
  DaxItemNumber: string;
  Description1: string;
  Description2: string;
  DirectToStore: string;
  DirectToStoreOrVendornumberoPspdPurchaseCost: number | null;
  DistributionId: string;
  Distributors: string;
  DutyRate: number | null;
  ExternalItemNumber: string;
  FobPort: string;
  FullUnitBarcode: string;
  Hierarchy: string;
  HtsCode: number | null;
  Imap: number | null;
  InitialDiscountFundingPercent: number | null;
  InnerCasePack: number | null;
  ItemData: string;
  ItemDescription: string;
  LayerQuantity: number | null;
  ManufacturerAccountNumber: string;
  MapPrice: number | null;
  MasterCase: string;
  MaterialSafetyDataSheetNeeded: boolean | null;
  MfgSuggestedRetail: number | null;
  MinimumOrderQuantity: number | null;
  NewItemRequestId: number;
  OnGoingFundingDiscountPercent: number | null;
  OtherBarcode: string;
  PalletQuantity: number | null;
  PluAssigned: string;
  PluScan: string;
  PrimaryUpc: string;
  Product: string;
  ProductAttribute: string;
  ProductName: string;
  ProgramName: string;
  PspdInventoryManagerName: string;
  Reason: string;
  RequestId: number;
  RequestType: string;
  RetailBasePrice: number | null;
  RetailHighPrice: number | null;
  RetailPackageSize: string;
  RetailPackageUom: string;
  ShipsToStores: string;
  ShipToStoreAs: string;
  ShipUnitCostFromPspd: number | null;
  Status: string;
  StoreCost: number | null;
  UnitDepth: number | null;
  UnitHeight: number | null;
  UnitWeight: number | null;
  UnitWidth: number | null;
  VendorId: string;
  VendorNumber: string;
  Version: number;
  Images: string[];
}

export interface NewItemSearchResult {
  Results: PogovNewItemRequest[];
  TotalCount: number;
}

export interface BatchSearchResult {
  Results: PogovBatch[];
  TotalCount: number;
}

export interface UnitOfMeasure {
  UnitOfMeasureId: number;
  UnitOfMeasure: string;
}

export interface VendorRequestBarcodeInfo {
  Upc: string;
  CaseUpc: string;
  OtherUpc: string;
  PluUpc: string;
  Plu: string;
  ItemNumber: string;
  Size: string;
  Uom: string;
  SignsLabelsDescription1: string;
  SignsLabelsDescription2: string;
}

export interface CHProductSearchResults {
  Items: CHProductSearchItem[];
  TotalItems: number;
  ReturnedItems: number;
}

export interface CHProductSearchItem {
  Id: number;
  Identifier: string;
  Properties: CHProductSearchProperties;
}

export interface CHProductSearchProperties {
  ProductName: string;
  ProductNumber: string;
  MasterCase?: number;
  CasePackToStore?: number;
  PrimaryUpc: string;
  CaseUpc: string;
  OtherBarcode: string;
  Plu: string;
  ScanPlu: string;
  RetailUnit: string;
  FullSignLabelDescription: string;
  SignsLabelsDescription1: string;
  SignsLabelsDescription2: string;
  LayerQuantity?: number;
  PalletQuantity?: number;
  UnitWeight?: number;
  UnitHeight?: number;
  UnitWidth?: number;
  UnitDepth?: number;
  CaseHeight?: number;
  CaseDepth?: number;
  CaseWidth?: number;
  ProgramNamePrivateLabel?: number;
  FobCost?: number;
  CaseCbms?: number;
  ItemCbms?: number;
  PvDim?: number;
  HtsCode?: number;
  DutyRate?: number;
  FobPort?: number;
  EstimatedFreightCost?: number;
  Duty?: number;
  Elc?: number;
  MinimumOrderQuantity: number;
  StoreCostUnit: number;
  StoreCostCase?: number;
  DirectToStoreOrVendorIntoPspD: string;
  GrossPercentage: string;
  MfgSuggestedRetail: string;
  Map?: number;
  Imap?: number;
  RetailBasePrice: number;
  Retail: string;
  ExternalItemId: string;
  VendorExternalItemNumber: string;
}

export interface PogovProcessFile {
  ProcessedFileBlobPath: string;
  ProcessedProfileFriendlyName: string;
  ProcessedProfileId: number | null;
  ProcessFileId: number;
  ProcessFileStatusDescription: string;
  ProcessFileStatusId: number;
  ProcessingProfileFriendlyName: string;
  ProcessingProfileId: number | null;
  RecordsProcessed: number;
  SourceFileBlobPath: string;
  TimeInserted: string;
  TimeProcessed: string | null;
  UploadedBy: string;
  Archived: boolean;
  ProcessedRunId: string;
  EffectiveDate: Date | null;
}

export interface ItemUpdateFileSearchResult {
  Results: PogovProcessFile[];
  TotalCount: number;
}

export interface ProcessFileStatus {
  ProcessFileStatusId: number;
  Description: string;
}

export interface ProcessingProfile {
  ProcessingProfileId: number;
  FriendlyName: string;
  PipelineName: string;
}
