<template>
  <core-view-template title="Ignored Items Upload">
    <core-view-section title="Upload">
      <v-row>
        <v-col cols="12" xl="8" lg="8" md="8">
          <v-file-input
            prepend-icon="image"
            label="Click Here to Add File"
            v-model="localFile"
            accept=".xlsx"
            @change="clearResult"
          ></v-file-input>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4">
          <v-btn
            @click="onUpload"
            :disabled="disabledUpload"
            :loading="ignoredItemsUploadInProgress"
            >Upload</v-btn
          >
          <v-btn @click="onClearIgnoredItemFile" :disabled="disabledClear">Clear</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="ignoredItemsUploadResult">
        <v-col>
          <span :class="ignoreItemResultCssClass">{{ ignoreItemResultText }}</span>
        </v-col>
      </v-row>
    </core-view-section>
    <core-view-section title="Ignored Items">
      <v-row class="mt-1">
        <v-text-field
          label="Search by item ID, UPC, or description."
          v-model="searchText"
          clearable
          @click:clear="
            searchText = null;
            reset();
          "
          v-debounce:300="reset"
          prepend-icon="search"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="ignoredItems"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="gettingIgnoredItems"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="ignoredItemsCount"
            class="elevation-1 scrollable"
            hide-default-footer
            item-key="ItemId"
            @update:options="updateSortBy"
            height="100%"
          >
            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="ignoredItems && ignoredItems.length > 0"
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ><span class="text--black"
            >{{ ignoredItems.length }} / {{ ignoredItemsCount }}
          </span></v-col
        >
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as es from '@/store/modules/ecom/state';
import * as em from '@/store/modules/ecom/mutations';
import * as ea from '@/store/modules/ecom/actions';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';
import { PspEcomIgnoreItemOld } from '@psp/pspecomdto';
import { IgnoredItemsUploadResult } from '@psp/pogona_ecom_client_js/IgnoredItemsUploadResult';

const ecomModule = namespace('ecom');

@Component({})
export default class IgnoreItemsUpload extends Vue {
  private localFile: File | null = null;
  private options: any = {};
  private isMounted = false;
  private ignoredItemsOData: ODataProps = {} as ODataProps;
  private infiniteId = 0;
  private lastCount = -1;
  private searchText: string | null = null;

  private headers: any = [
    { value: 'ItemId', text: 'ItemId' },
    { value: 'Upc', text: 'Upc' },
    { value: 'Description', text: 'Description' },
  ];

  private defaultSort = { sortBy: ['ItemId'], sortDesc: [false] };

  @ecomModule.Action(ea.uploadIgnoredItems) uploadIgnoredItems!: (file) => Promise<any>;
  @ecomModule.Action(ea.getIgnoredItems) getIgnoredItems!: (odata) => Promise<any>;
  @ecomModule.Action(ea.getIgnoredItemsCount) getIgnoredItemsCount!: (odata) => Promise<any>;

  @ecomModule.Mutation(em.clearIgnoredItems) clearIgnoredItems!: () => Promise<any>;
  @ecomModule.Mutation(em.setIgnoredItemsUploadResult)
  setIgnoredItemsUploadResult!: (result) => Promise<any>;

  @ecomModule.State(es.ignoredItems) ignoredItems!: PspEcomIgnoreItemOld[];
  @ecomModule.State(es.ignoredItemsCount) ignoredItemsCount!: number;
  @ecomModule.State(es.gettingIgnoredItems) gettingIgnoredItems!: boolean;
  @ecomModule.State(es.ignoredItemsUploadInProgress) ignoredItemsUploadInProgress!: boolean;
  @ecomModule.State(es.ignoredItemsUploadResult)
  ignoredItemsUploadResult!: IgnoredItemsUploadResult | null;

  onClearIgnoredItemFile(): void {
    this.localFile = null;
    this.clearResult();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onUpload(): Promise<any> {
    this.clearResult();
    await this.uploadIgnoredItems(this.localFile);

    this.localFile = null;
    this.clearIgnoredItems();
    await this.getIgnoredItemData();
  }

  private async mounted() {
    this.setODataOrderBy();
    this.isMounted = true;
  }

  public async updateSortBy() {
    if (this.isMounted == false) {
      this.setODataOrderBy();
    }

    if (this.options.sortBy.length === 1) {
      this.ignoredItemsOData.orderBy = [
        `${this.options.sortBy[0]} ${this.options.sortDesc[0] === true ? 'desc' : 'asc'}`,
      ];
    } else {
      this.ignoredItemsOData.orderBy = ['ItemId desc'];
    }

    this.ignoredItemsOData.top = 50;
    this.ignoredItemsOData.skip = 0;

    this.infiniteId += 1;

    if (this.isMounted == true) {
      this.clearIgnoredItems();
      await this.getIgnoredItemData();
    }
  }

  private getIgnoredItemData() {
    return Promise.all([
      this.getIgnoredItems(this.ignoredItemsOData),
      this.getIgnoredItemsCount(this.ignoredItemsOData),
    ]);
  }

  private setODataOrderBy() {
    if (this.ignoredItemsOData.orderBy && this.ignoredItemsOData.orderBy.length === 1) {
      const splitOrder = this.ignoredItemsOData.orderBy[0].split(' ');
      this.options = { sortBy: [splitOrder[0]], sortDesc: [splitOrder[1] === 'desc'] };
    } else {
      this.options = Object.assign({}, this.defaultSort);
    }
  }

  public infiniteHandler($state: any) {
    this.getIgnoredItems(this.ignoredItemsOData)
      .then(() => {
        if (this.ignoredItems.length < this.ignoredItemsCount) {
          $state.loaded();
          this.incrementSkip();
        } else {
          $state.complete();
        }

        this.lastCount = this.ignoredItems.length;
      })
      .catch(() => {
        $state.complete();
      });
  }

  public reset(doNotSetOrderBy = false) {
    this.$nextTick(async () => {
      this.clearIgnoredItems();

      this.infiniteId += 1;

      const ignoredItemsOData = new ODataProps();
      ignoredItemsOData.top = 50;
      ignoredItemsOData.skip = 0;

      if (doNotSetOrderBy !== true) {
        ignoredItemsOData.orderBy = [
          `${this.defaultSort.sortBy[0]} ${this.defaultSort.sortDesc[0] === true ? 'desc' : 'asc'}`,
        ];
      } else {
        ignoredItemsOData.orderBy = this.ignoredItemsOData.orderBy;
      }

      // clear the grid data
      this.clearIgnoredItems();

      const trimmedSearch = this.searchText?.trim()?.toUpperCase() ?? null;

      if (trimmedSearch && trimmedSearch.length > 0) {
        ignoredItemsOData.filter = f('or')
          .contains("'ItemId'", trimmedSearch)
          .contains("'Upc'", trimmedSearch)
          .contains("'Description'", trimmedSearch)
          .toString();
      } else {
        ignoredItemsOData.filter = null;
      }

      this.ignoredItemsOData = ignoredItemsOData;

      await this.getIgnoredItemData();
      this.incrementSkip();
    });
  }

  private incrementSkip() {
    this.ignoredItemsOData.skip += this.ignoredItemsOData.top;
  }

  get disabledClear() {
    return this.ignoredItemsUploadInProgress === true || !this.localFile;
  }

  get disabledUpload() {
    return !this.localFile || this.ignoredItemsUploadInProgress === true;
  }

  get ignoreItemResultCssClass() {
    if (this.ignoredItemsUploadResult) {
      if (this.ignoredItemsUploadResult.Success) {
        return ['text--black'];
      }
      return ['error', 'pa-1'];
    }
    return [];
  }

  get ignoreItemResultText() {
    if (this.ignoredItemsUploadResult) {
      let text = `Ignored items upload ${
        this.ignoredItemsUploadResult.Success ? 'succeeded' : 'failed'
      }.`;

      if (this.ignoredItemsUploadResult.Success) {
        text += ` ${this.ignoredItemsUploadResult.ProcessedItems} item(s) processed. ${
          this.ignoredItemsUploadResult.ErrorMessage?.length > 0
            ? this.ignoredItemsUploadResult.ErrorMessage
            : ''
        }`;
      } else {
        text += ` Failure message: ${this.ignoredItemsUploadResult.ErrorMessage}`;
      }
      return text;
    }

    return '';
  }

  private clearResult() {
    this.setIgnoredItemsUploadResult(null);
  }
}
</script>
