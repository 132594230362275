<template>
  <v-container>
    <v-row>
      <span>File name: {{ uploadFile.name }}</span>
    </v-row>
    <v-row v-if="validationData.Count > 0">
      <span>Valid rows found: {{ validationData.Count }}</span>
    </v-row>
    <v-row>
      <span>Errors found: {{ validationData.Errors.length }}</span>
    </v-row>
    <v-row>
      <v-data-table :headers="headers" :items="items"> </v-data-table>
    </v-row>
    <v-row>
      <v-btn @click="backToUploadClicked">Back to Upload Screen</v-btn>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pfm from '@/store/modules/ecom/mutations';
import * as pfs from '@/store/modules/ecom/state';
import { PartnerFundingValidationResult } from '@psp/pogona_ecom_client_js';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pfModule = namespace('partnerfunding');

@Component({})
export default class PartnerFundingValidationErrors extends Vue {
  @pfModule.State(pfs.validationData) validationData!: PartnerFundingValidationResult | null;
  @pfModule.State(pfs.uploadFile) uploadFile!: File | null;

  @pfModule.Mutation(pfm.setUploadFile) setUploadFile!: (payload: File | null) => void;
  @pfModule.Mutation(pfm.setValidationData) setValidationData!: (
    payload: PartnerFundingValidationResult | null,
  ) => void;

  get items() {
    if (this.validationData?.Errors) {
      return this.validationData.Errors.map(error => ({ error }));
    } else {
      return [];
    }
  }

  private backToUploadClicked() {
    this.setUploadFile(null);
    this.setValidationData(null);
  }

  private headers = [{ text: 'Error', value: 'error' }];
}
</script>
