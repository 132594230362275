import {
  StoreSetCompareState,
  StoreSetDataCompared,
  StoreSet,
  StoreSetCompareParams,
} from './state';

export const setAllStoreSets = 'setAllStoreSets';
export const setStoreStoreSets = 'setStoreStoreSets';
export const setComparedStoreSet = 'setComparedStoreSet';
export const setStoreSetCompareParams = 'setStoreSetCompareParams';

export const setSourceStoreSetSearch = 'setSourceStoreSetSearch';
export const setTargetStoreSetSearch = 'setTargetStoreSetSearch';
export const setComparedSearch = 'setComparedSearch';

export const setSelectedSource = 'setSelectedSource';
export const setSelectedTarget = 'setSelectedTarget';

export const setAllStoreSetsGetInProgress = 'setAllStoreSetsGetInProgress';
export const setStoreStoreSetsGetInProgress = 'setStoreStoreSetsGetInProgress';
export const setCompareStoreSetsInProgress = 'setCompareStoreSetsInProgress';

export const setCompareStoreSetsGetInProgress = 'setCompareStoreSetsGetInProgress';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  [setAllStoreSets](state: StoreSetCompareState, payload: StoreSet[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new StoreSet(), payload[i]);
    }
    state.allStoreSets = payload;
  },
  [setStoreStoreSets](state: StoreSetCompareState, payload: StoreSet[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new StoreSet(), payload[i]);
    }
    state.storeStoreSets = payload;
  },
  [setComparedStoreSet](state: StoreSetCompareState, payload: StoreSetDataCompared[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new StoreSetDataCompared(), payload[i]);
    }
    state.comparedStoreSet = payload;
  },
  [setSourceStoreSetSearch](state: StoreSetCompareState, payload: string | null): void {
    state.sourceStoreSetSearch = payload;
  },
  [setTargetStoreSetSearch](state: StoreSetCompareState, payload: string | null): void {
    state.targetStoreSetSearch = payload;
  },
  [setComparedSearch](state: StoreSetCompareState, payload: string | null): void {
    state.comparedSearch = payload;
  },
  [setSelectedSource](state: StoreSetCompareState, payload: any | null): void {
    state.selectedSource = payload;
  },
  [setSelectedTarget](state: StoreSetCompareState, payload: any | null): void {
    state.selectedTarget = payload;
  },
  [setStoreSetCompareParams](state: StoreSetCompareState, payload: StoreSetCompareParams): void {
    state.storeSetCompareParams = Object.assign(new StoreSetDataCompared(), payload);
  },
  [setAllStoreSetsGetInProgress](state: StoreSetCompareState, payload: boolean): void {
    state.allStoreSetsGetInProgress = payload;
  },
  [setStoreStoreSetsGetInProgress](state: StoreSetCompareState, payload: boolean): void {
    state.storeStoreSetsGetInProgress = payload;
  },
  [setCompareStoreSetsInProgress](state: StoreSetCompareState, payload: boolean): void {
    state.compareStoreSetsInProgress = payload;
  },
  [setCompareStoreSetsGetInProgress](state: StoreSetCompareState, payload: any): void {
    state.compareStoreSetsGetInProgress = payload;
  },
};
