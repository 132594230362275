<template>
  <!-- Create invoice number search -->
  <v-row>
    <v-col>
      <v-text-field
        v-model="invoiceNumber"
        ref="invoiceNumberTextField"
        label="Invoice Number"
        clearable
        @keyup.enter="navigate"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-menu
        v-model="fromDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="fromDateFormatted"
            label="From Date"
            readonly
            v-on="on"
            persistent-hint
            clearable
          ></v-text-field>
        </template>
        <v-date-picker v-model="fromDate" no-title @input="fromDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="toDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="toDateFormatted"
            label="To Date"
            readonly
            v-on="on"
            persistent-hint
            clearable
          ></v-text-field>
        </template>
        <v-date-picker v-model="toDate" no-title @input="toDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <!-- Create search button -->
    <v-col>
      <v-btn @click="navigate">Search</v-btn>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';

defineComponent({
  name: 'InvoiceSearch',
});

const invoiceNumber = ref<string | null>('');
const router = useRouter();
const invoiceNumberTextField = ref<HTMLInputElement | null>(null);
const fromDateMenu = ref(false);
const fromDate = ref<Date | null>(null);
const fromDateFormatted = ref('');
const toDateMenu = ref(false);
const toDate = ref<Date | null>(null);
const toDateFormatted = ref('');

// Navigate
function navigate() {
  if (
    router.currentRoute.name !== 'vendorportal_invoicesearchresult' ||
    searchFromRoute.value !== invoiceNumber.value ||
    routeFromDate.value !== fromDateFormatted.value ||
    routerToDate.value !== toDateFormatted.value
  ) {
    router.push({
      name: 'vendorportal_invoicesearchresult',
      params: {
        invoiceSearch: invoiceNumber.value ? encodeURIComponent(invoiceNumber.value) : '-',
        fromDate: fromDateFormatted.value ? encodeURIComponent(fromDateFormatted.value) : '-',
        toDate: toDateFormatted.value ? encodeURIComponent(toDateFormatted.value) : '-',
      },
    });
  }
}

const searchFromRoute = computed(() => {
  if (router.currentRoute.params.invoiceSearch) {
    return router.currentRoute.params.invoiceSearch === '-'
      ? ''
      : decodeURIComponent(router.currentRoute.params.invoiceSearch);
  } else {
    return '';
  }
});
const routeFromDate = computed(() => {
  if (router.currentRoute.params.fromDate) {
    return router.currentRoute.params.fromDate === '-'
      ? null
      : decodeURIComponent(router.currentRoute.params.fromDate);
  } else {
    return null;
  }
});
const routerToDate = computed(() => {
  if (router.currentRoute.params.toDate) {
    return router.currentRoute.params.toDate === '-'
      ? null
      : decodeURIComponent(router.currentRoute.params.toDate);
  } else {
    return null;
  }
});

function formatDate(date) {
  if (!date) return null;

  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}

watch(fromDate, curr => {
  if (curr) {
    fromDateFormatted.value = formatDate(curr) ?? '';
  }
});
watch(toDate, curr => {
  if (curr) {
    toDateFormatted.value = formatDate(curr) ?? '';
  }
});

onMounted(() => {
  invoiceNumber.value = searchFromRoute.value;
  fromDateFormatted.value = routeFromDate.value ?? '';
  toDateFormatted.value = routerToDate.value ?? '';
  invoiceNumberTextField.value?.focus();
});
</script>
