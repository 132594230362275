export default class StringHelpers {
  static formatNumberRanges(range, rangeTuples) {
    if (!range || range.length === 0) {
      return '';
    }

    const appendRangeText = (start, end, rt) => {
      let selectedText = '';
      if (start) {
        selectedText += start;

        if (end) {
          if (end > start) {
            if (end - 1 > start || rt === true) {
              selectedText += `-${end}`;
            } else {
              selectedText += `,${end}`;
            }
          }
        }
      }
      return selectedText;
    };

    const rangeTuplesOption =
      rangeTuples === null || rangeTuples === undefined || typeof rangeTuples !== 'boolean'
        ? true
        : rangeTuples;
    const orderedRange = range.sort((a, b) => a - b);

    let rangeStart = null;
    let rangeEnd = null;
    let selectedText = '';

    for (let six = 0; six < orderedRange.length; six += 1) {
      if ((!rangeStart && !rangeEnd) || (rangeEnd && rangeEnd === orderedRange[six] - 1)) {
        if (!rangeStart) {
          rangeStart = orderedRange[six];
        }

        rangeEnd = orderedRange[six];
      } else {
        selectedText += `${appendRangeText(rangeStart, rangeEnd, rangeTuplesOption)},`;
        rangeStart = orderedRange[six];
        rangeEnd = orderedRange[six];
      }
    }

    selectedText += `${appendRangeText(rangeStart, rangeEnd, rangeTuplesOption)},`;

    if (selectedText[selectedText.length - 1] === ',') {
      selectedText = selectedText.substr(0, selectedText.length - 1);
    }
    return selectedText;
  }

  static fileNameFromFilePath(filePath) {
    if (filePath) {
      const pdfUrlParts = filePath.split('/');
      return pdfUrlParts[pdfUrlParts.length - 1];
    }
    return null;
  }
}
