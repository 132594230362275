<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title class="text--black">Merchandising NIF</v-card-title>
          <v-card-subtitle
            >Copy all the data from your sheet, including the headers and click the paste button.
            All data will feed below.
          </v-card-subtitle>
          <v-card-text>
            <data-paste-from-excel
              v-model="excelData"
              :typePrototype="typePrototype"
              :errorsLoading="processing"
              :numberOfHeaders="3"
              :mergedHeaderRows="1"
              :editable="false"
              :deleteable="false"
              :errorHelperText="errorHelperText"
              @noClipboardData="
                $emit('showSnackbarMessage', { text: $event.text, color: 'warning' })
              "
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, ModelSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PetPartnerExcelData from '@/store/modules/vendorportal/PetPartnerExcelData';
import { Manufacturer, Brand, Vendor, UnitOfMeasure } from '@/utils/VendorDomainTypes';

const vpModule = namespace('vendorportal');

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({})
export default class NewItemPasteFromExcel extends Vue {
  @ModelSync('value', 'textChange', { type: Array })
  public excelData!: Array<PetPartnerExcelData>;
  public errorHelperText =
    'If an error is found, it will show here.  Please go back to your file, correct the error(s), come back to the above "Merchandising NIF" section, click the clear button and then click the paste button again till no errors occur.';

  @Prop({ required: true, default: false })
  readonly processing!: boolean;

  @Prop({ required: true, default: false })
  readonly multiSaveDisabled!: boolean;

  @Prop({ required: true })
  readonly id!: string;

  @Prop({ required: true })
  readonly manufacturers!: Manufacturer[];

  @Prop({ required: true })
  readonly brands!: Brand[];

  @Prop({ required: true })
  readonly vendors!: Vendor[];

  @Prop({ required: true })
  readonly unitOfMeasures!: UnitOfMeasure[];

  @vpModule.Action('generateAggregateId') generateAggregateId!: () => Promise<string>;
  @vpModule.Action('uploadImageFromByteArray') uploadImageFromByteArray!: (
    data: any,
  ) => Promise<string>;

  public typePrototype: PetPartnerExcelData | null = null;

  @Watch('excelData', { deep: true }) excelDataChanged(newVal: Array<PetPartnerExcelData>) {
    this.$emit('input', newVal);
  }

  mounted() {
    this.typePrototype = new PetPartnerExcelData(
      this.manufacturers,
      this.brands,
      this.vendors,
      this.unitOfMeasures,
    );
  }

  public async validateMultiInput(isNew: boolean, defaultStatus: string, schema: any) {
    let allAreValid = true;
    const requestData = [] as any[];

    const multiId = await this.generateAggregateId();

    let lineIx = 0;
    for (const line of this.excelData) {
      lineIx++;
      line.clearErrors();

      const validatedState = await this.createStateFromMultiLineInput(
        line,
        multiId,
        isNew,
        defaultStatus,
        this.privateLabelDataSetFromMultiInput(line),
      );
      requestData.push(validatedState);
      try {
        await schema.validate(validatedState);
      } catch (validationError: any) {
        line.pushError(`Row ${lineIx} has the following error: ${validationError.errors}`);
        allAreValid = false;
      }
    }

    return { allAreValid, requestData };
  }

  public privateLabelDataSetFromMultiInput(line: PetPartnerExcelData) {
    return line.htsCode !== 0 || line.dutyRate !== 0 || line.fobPort !== 0;
  }

  public async createStateFromMultiLineInput(
    line: PetPartnerExcelData,
    multiId: string,
    isNew: boolean,
    defaultStatus: string,
    privateLabel: boolean,
  ) {
    return {
      isNew: isNew,
      id: multiId,
      status: defaultStatus,
      requestType: 'New Item',
      privateLabel: privateLabel,
      fullUnitBarcode: line.fullUnitBarcode,
      caseBarcode: line.caseBarcode ?? '',
      otherBarcode: line.otherBarcode ?? '',
      productName: line.productName,
      retailPackageSize: line.retailPackageSize ?? '',
      retailPackageUOM: line.retailPackageUOM ?? '',
      manufacturerAccountNumber: line.manufacturerAccountNumber ?? '',
      brand: line.brand ?? 0,
      vendorId: line.vendorId ?? '',
      externalItemNumber: line.externalItemNumber ?? '',
      masterCase: line.masterCase ?? 0,
      innerCasePack: line.innerCasePack ?? '',
      layerQuantity: line.layerQuantity ?? '',
      palletQuantity: line.palletQuantity ?? '',
      directToStore: line.directToStore ?? '',
      directToStoreOrVendorIntoPspdPurchaseCost:
        line.directToStoreOrVendorIntoPspdPurchaseCost ?? 0,
      mfgSuggestedRetail: line.mfgSuggestedRetail ?? 0,
      mapPrice: line.mapPrice ?? 0,
      imap: line.imap ?? 0,
      autoShipEligible: line.autoShipEligible ?? false,
      initialDiscountFundingPercent: line.initialDiscountFundingPercent ?? 0,
      onGoingFundingDiscountPercent: line.onGoingFundingDiscountPercent ?? 0,
      materialSafetyDataSheetNeeded: line.materialSafetyDataSheetNeeded ?? false,
      minimumOrderQuantity: line.minimumOrderQuantity ?? 0,
      unitWeight: line.unitWeight ?? 0,
      unitHeight: line.unitHeight ?? 0,
      unitWidth: line.unitWidth ?? 0,
      unitDepth: line.unitDepth ?? 0,
      caseHeight: line.caseHeight ?? 0,
      caseWidth: line.caseWidth ?? 0,
      caseDepth: line.caseDepth ?? 0,
      productAttribute: line.productAttribute,
      htsCode: line.htsCode,
      dutyRate: line.dutyRate,
      fobPort: line.fobPort,
      pluScan: line.pluScan ?? '',
      pluAssigned: line.pluAssigned ?? '',
      daxItemNumber: line.daxItemNumber ?? '',
      signsAndLabelsDescription1: line.signsAndLabelsDescription1 ?? '',
      signsAndLabelsDescription2: line.signsAndLabelsDescription2 ?? '',
      vendorNumber: '',
      shipsToStores: line.shipsToStores ?? '',
      shipUnitCostFromPspd: line.shipUnitCostFromPspd ?? 0,
      retailBasePrice: line.retailBasePrice ?? 0,
      retailHighPrice: line.retailHighPrice ?? 0,
      hierarchy: line.hierarchy ?? '',
      categoryManagerName: line.categoryManagerName ?? '',
      pspdInventoryManagerName: line.pspdInventoryManagerName ?? '',
      reason: line.reason ?? '',
      dsdDistributor: {},
      aggId: line.aggId,
      season: line.season ?? '',
      seasonal: line.seasonal ?? '',
      year: line.year ?? '',
      category: line.category ?? '',
    };
  }
}
</script>
