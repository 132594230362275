<template>
  <core-signs-labels-view-template title="Enable Item Deactivation" :showStoreNumber="false">
    <core-info-blurb title="What is enable item deactivation?">
      The enable item deactivation page allows a store, or all stores to deactivate an item that has
      been blocked from deactivation. Once an item is mapped here, the item can be deactivated via
      the "Activate/Deactivate" page.
    </core-info-blurb>
    <v-col cols="12">
      <v-row v-if="allowStoreDeactivations">
        <v-col>
          <v-data-table
            v-if="allowStoreDeactivations"
            v-model="allowStoreDeactivations.selected"
            :headers="allowStoreDeactivations.headers"
            :items="allowStoreDeactivations.items"
            :loading="allowStoreDeactivations.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="allowStoreDeactivations.options"
            @update:sort-by="allowStoreDeactivations.get"
            @click:row="
              allowStoreDeactivations.itemRowMultiClicked($event, allowStoreDeactivations)
            "
            @item-selected="
              allowStoreDeactivations.itemRowMultiSelected($event, allowStoreDeactivations)
            "
            item-key="itemId"
            class="elevation-1 scrollable"
          >
            <template v-slot:body.append>
              <infinite-loader
                :pogonaTable="allowStoreDeactivations"
                :colspan="allowStoreDeactivations.headers.length"
              ></infinite-loader>
            </template>

            <template v-slot:top>
              <v-row class="ml-2">
                <v-col cols="12" xl="6" lg="6" md="6">
                  <v-dialog v-model="newItemMappingDialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on">Add New Item</v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">New Item</span>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <v-form @submit.stop.prevent="appendItemToNewMappingGrid">
                              <v-text-field
                                v-model="newItemToMap"
                                label="Item #"
                                :rules="[itemIdRule]"
                                autocomplete="off"
                              ></v-text-field>
                            </v-form>
                          </v-col>
                          <v-col>
                            <v-combobox
                              label="Store #"
                              :disabled="newItemForAllStores"
                              v-model="newItemStore"
                              :items="stores"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-model="newItemForAllStores"
                              label="All Stores"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="addItemMapping"
                          :disabled="addItemMappingLoading || !addNewItemEnabled"
                          :loading="addItemMappingLoading"
                          >Save</v-btn
                        >
                        <v-btn @click="newItemMappingDialog = false">Cancel</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.storeNumber="{ value }">
              {{ value || 'All' }}
            </template>

            <template v-slot:item.delete="{ item }">
              <v-icon
                @click="
                  itemToDelete = item;
                  deleteItemMappingDialog = true;
                "
                >delete</v-icon
              >
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="allowStoreDeactivations"></infinite-paganation>
        </v-col>
      </v-row>
      <v-row v-if="deleteItemMappingDialog">
        <v-dialog v-model="deleteItemMappingDialog" max-width="500">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Allowed Deactivation</span>
            </v-card-title>
            <v-card-text
              >Are you sure you want to delete allowed deactivation item #{{ itemToDelete.itemId }}
              for
              {{
                itemToDelete.storeNumber ? `store ${itemToDelete.storeNumber}` : 'all stores'
              }}?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="deleteItem(itemToDelete)"
                :disabled="deleteAllowedDeactivationItemLoading"
                :loading="deleteAllowedDeactivationItemLoading"
                >Yes</v-btn
              >
              <v-btn @click="deleteItemMappingDialog = false">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </core-signs-labels-view-template>
</template>

<script>
/* eslint-disable no-await-in-loop */
import PogonaDataTable from '@/utils/PogonaDataTable';

export default {
  data() {
    return {
      allowStoreDeactivations: null,
      newItemMappingDialog: false,
      addItemMappingLoading: false,
      newItemToMap: null,
      newItemStore: null,
      newItemForAllStores: false,
      stores: [],
      itemToDelete: null,
      deleteItemMappingDialog: false,
      deleteAllowedDeactivationItemLoading: false,
      itemIdRule: v => {
        if (!v || v.length <= 0 || isNaN(v) === true) {
          return 'Please enter a number';
        }

        if (Number(v) < 0) {
          return 'Please enter a positive number';
        }

        return true;
      },
    };
  },
  computed: {
    addNewItemEnabled() {
      return (
        this.itemIdRule(this.newItemToMap) === true &&
        ((this.newItemStore && this.newItemStore.length > 0) || this.newItemForAllStores === true)
      );
    },
  },
  async created() {
    await this.initAllowStoreDeactivations();
    await this.initStores();
  },
  methods: {
    async addItemMapping() {
      this.addItemMappingLoading = true;

      try {
        // first check to see if the mapping already exists
        // all stores
        let existsForAllStores = false;
        try {
          const allStoresResponse = await this.$authApi.http.get(
            `label/restricteditemsfromdeactivationoverride/${this.newItemToMap}`,
          );
          existsForAllStores = !!allStoresResponse.data;
        } catch (errAllStores) {
          // if error, doesn't exist
          existsForAllStores = false;
        }

        // if they're doing a store specific mapping check that too
        let existsForStore = false;
        if (this.newItemForAllStores === false) {
          try {
            const storeResponse = await this.$authApi.http.get(
              `label/restrictedstoreitemsfromdeactivationoverride/${this.newItemToMap}/${this.newItemStore}`,
            );
            existsForStore = !!storeResponse.data;
          } catch (errStore) {
            // if error, doesn't exist
            existsForStore = false;
          }
        }

        let existsMessage = 'Mapping already exists for';
        const mappingExists = existsForAllStores || existsForStore;

        if (existsForAllStores) {
          existsMessage += ' all stores';
        }

        if (existsForStore === true) {
          if (existsForAllStores === true) {
            existsMessage += `, and store ${this.newItemStore}.`;
          } else {
            existsMessage += ` store ${this.newItemStore}.`;
          }
        } else {
          existsMessage += '.';
        }

        if (mappingExists === true) {
          this.$emit('snackbar-error', existsMessage);
        }

        if (mappingExists === false) {
          if (this.newItemForAllStores === true) {
            await this.$authApi.http.post('label/restricteditemsfromdeactivationoverride', {
              itemId: this.newItemToMap,
            });
          } else {
            await this.$authApi.http.post('label/restrictedstoreitemsfromdeactivationoverride', {
              itemId: this.newItemToMap,
              storeNumber: this.newItemStore,
            });
          }

          // turn off loading, and close dialog
          this.addItemMappingLoading = false;
          this.newItemMappingDialog = false;

          // clear inputs
          this.newItemToMap = null;
          this.newItemStore = null;
          this.newItemForAllStores = false;

          // get the grid data
          await this.initAllowStoreDeactivations();
        } else {
          this.addItemMappingLoading = false;
        }
      } catch (err) {
        this.addItemMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error creating allowed deactivation',
          err,
          id: 'b85fb62d-2268-4741-b315-fb69adda51e5',
        });
      }
    },
    async deleteItem(item) {
      this.deleteAllowedDeactivationItemLoading = true;
      try {
        if (item.storeNumber === null) {
          await this.$authApi.http.delete(
            `label/restricteditemsfromdeactivationoverride/${item.itemId}`,
          );
        } else {
          await this.$authApi.http.delete(
            `label/restrictedstoreitemsfromdeactivationoverride/${item.itemId}/${item.storeNumber}`,
          );
        }
        this.deleteAllowedDeactivationItemLoading = false;
        this.deleteItemMappingDialog = false;
        this.initAllowStoreDeactivations();
      } catch (err) {
        this.deleteAllowedDeactivationItemLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error deleting allowed deactivation',
          err,
          id: 'c94a3764-baf7-4f5b-ad9c-875febc783c8',
        });
      }
    },
    async initStores() {
      const storeCount = (
        await this.$authApi.http.get("label/storeinfobridge/$count?$filter=storeNumber ne ''")
      ).data;

      const top = 200;
      let skip = 0;
      while (this.stores.length < storeCount) {
        const stores = (
          await this.$authApi.http.get(
            `label/storeinfobridge?$order-by=itemId&$filter=storeNumber ne ''&$top=${top}&$skip=${skip}`,
          )
        ).data;
        skip += top;

        stores.forEach(v => {
          this.stores.push(v.storeNumber);
        });
      }
    },
    async initAllowStoreDeactivations() {
      this.allowStoreDeactivations = new PogonaDataTable({
        headers: [
          {
            text: 'Store Number',
            value: 'storeNumber',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
          {
            text: 'Delete',
            value: 'delete',
            sortable: false,
          },
        ],
        baseUrl: 'label/restrictedstoreitemsfromdeactivationoverride/stores-and-all',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['itemId'] },
        isInfinite: true,
      });

      await this.allowStoreDeactivations.get();
    },
  },
};
</script>
