import Vue from 'vue';

Vue.filter('phone', value => {
  if (value) {
    const phoneTrimmed = value.trim();

    switch (phoneTrimmed.length) {
      case 11:
        return `${phoneTrimmed[0]}-${phoneTrimmed.substring(1, 4)}-${phoneTrimmed.substring(
          4,
          7,
        )}-${phoneTrimmed.substring(7, 11)}`;
      case 10:
        return `${phoneTrimmed.substring(0, 3)}-${phoneTrimmed.substring(
          3,
          6,
        )}-${phoneTrimmed.substring(6, 10)}`;
      case 7:
        return `${phoneTrimmed.substring(0, 3)}-${phoneTrimmed.substring(3, 7)}`;
      default:
        return phoneTrimmed;
    }
  }
  return null;
});
