const initState = (): unknown => {
  return {
    search: '',
    pricingHistoryExpanded: false,
    costHistoryExpanded: false,
    movementHistoryExpanded: false,
    labelItems: {},
    basePriceHistory: {},
    swcHistory: {},
    sncHistory: {},
    bmsHistory: {},
    dailyMovementHistory: {},
    baseCost: {},
    bmsExpanded: [],
    nonActivatedToggle: false,
    selectedBarcodeSetups: null,
  };
};

export { initState };

export default {
  search: '',
  pricingHistoryExpanded: false,
  costHistoryExpanded: false,
  movementHistoryExpanded: false,
  labelItems: {},
  basePriceHistory: {},
  swcHistory: {},
  sncHistory: {},
  bmsHistory: {},
  dailyMovementHistory: {},
  baseCost: {},
  bmsExpanded: [],
  nonActivatedToggle: false,
  selectedBarcodeSetups: null,
};
