export const showModalLoading = 'showModalLoading';
export const username = 'username';
export const storeNumber = 'storeNumber';
export const menuVisibilities = 'menuVisibilities';
export const selectedPrinter = 'selectedPrinter';
export const printerTray = 'printerTray';
export const availablePrinters = 'availablePrinters';
export const showLabelPreviewMode = 'showLabelPreviewMode';
export const storeMappings = 'storeMappings';
export const showStoreNumber = 'showStoreNumber';
export const useJsPrintVersion = 'useJsPrintVersion';
export const isNewJerseyStore = 'isNewJerseyStore';

export default {
  showModalLoading: true,
  username: '',
  storeNumber: '',
  menuVisibilities: [],
  selectedPrinter: null,
  printerTray: null,
  availablePrinters: null,
  showLabelPreviewMode: true,
  storeMappings: [],
  showStoreNumber: false,
  useJsPrintVersion: null,
  isNewJerseyStore: false,
};
