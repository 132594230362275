import moment from 'moment';
import Vue from 'vue';

const format = (value, isUtc) => {
  if (value) {
    let time = null;
    if (isUtc) {
      time = moment.utc(String(value)).local();
    } else {
      time = moment(String(value));
    }
    return time.format('MM/DD/YYYY hh:mm A');
  }
  return '';
};

Vue.filter('formatDateTime', value => format(value));

Vue.filter('formatUtcDateTime', value => format(value, true));
