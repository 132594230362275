<template>
  <v-row>
    <v-col>
      <core-view-section title="Overriden Items">
        <v-row class="my-2">
          <v-text-field label="Search by UPC or item #." v-model="search" clearable @click:clear="
            search = null;
          getUnitOfMeasureOverridesData();
          " v-debounce:300="getUnitOfMeasureOverridesData" prepend-icon="search"></v-text-field>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-btn @click="clearSelectedOverrides">Clear Selected{{ selectedFormatted }}</v-btn>
          </v-col>
          <v-col cols="8"> </v-col>
          <v-col cols="2">
            <v-btn @click="getUnitOfMeasureOverridesData" class="float-right">Refresh</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table v-model="selectedOverridesR" :headers="headers" :items="overrides" :items-per-page="-1"
              :fixed-header="true" :loading="gettingOverrides || gettingOverridesCount || gettingOverridesAllCount"
              :footer-props="{
                disableItemsPerPage: true,
                itemsPerPageText: '',
              }" :options.sync="options" :disable-pagination="true" :server-items-length="overridesCount"
              @click:row="overrideItemsClicked" @update:sort-by="setOrderBy" @update:sort-desc="setOrderBy"
              @toggle-select-all="toggleSelectAll" class="elevation-1 scrollable" hide-default-footer
              item-key="unitOfMeasureOverrideId" height="100%" show-select>
              <template v-slot:item.isDryWeight="{ value }">
                {{ value ? 'Yes' : 'No' }}
              </template>
              <template v-slot:item.isShampooConditioner="{ value }">
                {{ value ? 'Yes' : 'No' }}
              </template>

              <template v-slot:item.edit="{ item }">
                <v-btn @click.stop.prevent="emit('editClicked', item)">Edit</v-btn>
              </template>

              <template v-slot:item.delete="{ item }">
                <v-btn @click.stop.prevent="emit('deleteClicked', item)">Delete</v-btn>
              </template>

              <template v-slot:body.append="{}">
                <tr>
                  <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                    <infinite-loading v-if="overrides && overrides.length > 0" @infinite="infiniteHandler"
                      :identifier="infiniteId">
                    </infinite-loading>
                  </td>
                </tr>
              </template>

              <template v-slot:footer="{}">
                <div class="my-3 ml-2">{{ overrides.length }} / {{ overridesCount }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <retail-start-date-dialog v-model="retailStartDate" :queueLoading="labelItemsToBatchLoading"
            :queueDisabled="queueBatchDisabled" :queueCallback="labelItemsToBatch" :createdBatchInfo="createdBatchInfo"
            :startDateDialog.sync="startDateDialog" :showCasePricing="true"
            :useCasePricing.sync="useCasePricing"></retail-start-date-dialog>
        </v-row>
      </core-view-section>
      <core-view-section v-if="createdBatchInfo" v-show="createdBatchInfo.printImmediately !== true"
        title="Queue Items">
        <batch-check-items v-model="batchInfoItems" :batchInfo.sync="createdBatchInfo"
          @error="emit('snackbar-error', $event)" :footerBatchAndId="footerBatchAndId" :includeSetDelimters="false"
          :autoCompleteBatch="true" :showCompleteToggle="false" :showApprovedFilter="true"
          :showIncludeSetDelimtersCheckbox="true" originatingPage="Unit Override"></batch-check-items>
      </core-view-section>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import Vue, { ref, onMounted, computed } from 'vue';
import moment from 'moment';
import uuid from 'uuid';
import { LabelBatchCreator, LabelBatchItem } from '@/utils/LabelBatchCreator';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import { useUnitOverrideStore, UnitOfMeasureOverride } from '@/stores/unitoverridestore';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['editClicked', 'deleteClicked', 'snackbar-error']);

const headers = ref<any>([
  { value: 'unitOfMeasureOverrideId', text: 'ID' },
  { value: 'unitMeasure', text: 'UOM' },
  { value: 'size', text: 'Size' },
  { value: 'upc', text: 'UPC' },
  { value: 'itemId', text: 'Item #' },
  { value: 'packageUnitMeasure', text: 'Package UOM' },
  { value: 'packageSize', text: 'Package Size' },
  { value: 'isDryWeight', text: 'Dry Weight?' },
  { value: 'isShampooConditioner', text: 'Shampoo/Conditioner?' },
]);
const options = ref<any>({ sortBy: ['unitOfMeasureOverrideId'], sortDesc: [false] });
const search = ref('');
let lastSearch = '';
const infiniteId = -1;
const retailStartDate = ref<string | Date>(moment.utc().format('YYYY-MM-DD'));
const labelItemsToBatchLoading = ref(false);
const startDateDialog = ref(false);
const useCasePricing = ref(false);
let labelItemsBatchWs: PogonaWebSocket | null = null;
let unitOverrideWriter = false;
let selectAllOverrides = false;

const unitOverrideStore = useUnitOverrideStore();

const {
  overrides,
  lastOverridePage,
  overridesCount,
  overridesAllCount,
  gettingOverrides,
  gettingOverridesCount,
  gettingOverridesAllCount,
  overridesOData,
  client,
  selectedOverrides: selectedOverridesR,
  batchInfoItems,
  createdBatchInfo,
} = storeToRefs(unitOverrideStore);

onMounted(async () => {
  unitOverrideWriter = await Vue.prototype.$authApi.roleHasRights('UnitOverrideWriter');

  if (unitOverrideWriter === true) {
    headers.value.push({
      text: 'Edit',
      value: 'edit',
      sortable: false,
    });
    headers.value.push({
      text: 'Delete',
      value: 'delete',
      sortable: false,
    });
  }

  await getUnitOfMeasureOverridesData();
});

const storeNumber = computed(() => {
  if (Vue.prototype.$store?.state?.app?.storeNumber) {
    return Vue.prototype.$store.state.app.storeNumber;
  }
  return null;
});

function clearSelectedOverrides() {
  selectAllOverrides = false;
  selectedOverridesR.value = [] as UnitOfMeasureOverride[];
}

const selectedFormatted = computed(() => {
  if (selectAllOverrides === true) {
    return ` (${overridesAllCount.value - excludedAllOverrides.value.length}) `;
  } else {
    if (selectedOverridesR && selectedOverridesR.value.length > 0) {
      return ` (${selectedOverridesR.value.length})`;
    }
  }
  return '';
});

async function getUnitOfMeasureOverridesData() {
  if (lastSearch !== search.value) {
    reset();
  }
  lastSearch = search.value;

  client.value?.abortInFlightRequests();
  overridesOData.value.filter = search.value;
  await Promise.all([
    unitOverrideStore.getUnitOfMeasureOverrides(overridesOData.value),
    unitOverrideStore.getUnitOfMeasureOverridesCount(overridesOData.value),
    unitOverrideStore.getUnitOfMeasureOverridesAllCount(),
  ]);
}

function infiniteHandler($state: any) {
  const excludedOverrides = Object.assign([], excludedAllOverrides.value);

  getUnitOfMeasureOverridesData()
    .then(x => {
      if (overrides.value.length < overridesCount.value) {
        $state.loaded();
        incrementSkip();
      } else {
        $state.complete();
      }

      if (selectAllOverrides === true) {
        const toAddToSelected = [] as any[];
        for (const lastPageItem of lastOverridePage.value) {
          if (excludedOverrides.value.indexOf(lastPageItem.unitOfMeasureOverrideId) === -1) {
            toAddToSelected.push(lastPageItem);
          }
        }

        selectedOverridesR.value.push(...toAddToSelected);
      }
    })
    .catch(() => {
      $state.complete();
    });
}

function incrementSkip() {
  overridesOData.value.skip += overridesOData.value.top;
}

function reset() {
  overridesOData.value.skip = 0;
}

function overrideItemsClicked(event) {
  const itemSelectedIx = selectedOverridesR.value.findIndex(
    x => x.unitOfMeasureOverrideId === event.unitOfMeasureOverrideId,
  );

  if (itemSelectedIx === -1) {
    selectedOverridesR.value.push(event);
  } else {
    selectedOverridesR.value.splice(itemSelectedIx, 1);
  }
}

async function setOrderBy() {
  let orderBy: string[] | null = null;
  if (options.value && options.value.sortBy && options.value.sortBy.length > 0) {
    orderBy = [] as string[];
    for (const oix in options.value.sortBy) {
      orderBy.push(`${options.value.sortBy[oix]} ${options.value.sortDesc[oix] ? 'desc' : 'asc'}`);
    }
  }

  overridesOData.value.orderBy = orderBy;
  reset();
  await getUnitOfMeasureOverridesData();
}

async function labelItemsToBatch(source) {
  const errorMessage = err => {
    labelItemsToBatchLoading.value = false;
    emit('snackbar-error', {
      text: 'Error creating batch from labels.',
      err,
      id: 'c00a2e17-2cb2-4aa2-9202-2e5b0951b8b2',
    });
  };

  try {
    batchInfoItems.value = {};
    createdBatchInfo.value = null;
    startDateDialog.value = false;
    labelItemsToBatchLoading.value = true;

    let batchCreated = false;

    const onClose = () => {
      useCasePricing.value = false;
      labelItemsToBatchLoading.value = false;
      // If the web socket connection closed before
      // the batch was created throw an error message.
      if (batchCreated === false) {
        errorMessage(new Error('WS connection closed before batch was created.'));
      }
    };

    // Register on message handler before posting the message
    const onmessage = event => {
      const recMessage = JSON.parse(event.data);

      batchCreated = true;

      if (labelItemsBatchWs !== null) {
        labelItemsBatchWs.close();
      }

      if (recMessage.success === false) {
        errorMessage(new Error('Received a non-successful WS response.'));
        return;
      }

      createdBatchInfo.value = recMessage.batchInfo;
      createdBatchInfo.value.printImmediately = (source && source.printImmediately) || false;
    };

    if (selectAllOverrides === true) {
      const uomOverrideAllBatchId = uuid();

      const onOpen = async () => {
        const uomOverrideAllBatchData = {
          id: uomOverrideAllBatchId,
          storeNumber: storeNumber.value,
          retailStartDate: retailStartDate.value,
          useCasePricing: useCasePricing.value,
          excludedOverrideIds: excludedAllOverrides.value,
        };

        await Vue.prototype.$authApi.http.post(
          'servicebus/unitmeasureoverrideallitemsbatch',
          uomOverrideAllBatchData,
        );
      };

      const adalToken = await new AdalHelpers().getJwtToken(Vue.prototype.$authApi);
      labelItemsBatchWs = new PogonaWebSocket(
        Vue.prototype.$apiBasePath,
        'unit_measure_override_all_items',
        uomOverrideAllBatchId,
        adalToken,
        onClose,
        onmessage,
        null,
        onOpen,
      );
    } else {
      const selectedItems: LabelBatchItem[] = [] as LabelBatchItem[];

      if (selectedOverridesR.value && selectedOverridesR.value.length > 0) {
        for (const item of selectedOverridesR.value) {
          selectedItems.push(new LabelBatchItem(item.itemId, item.upc));
        }

        const lbc = new LabelBatchCreator(
          Vue.prototype.$authApi,
          Vue.prototype.$apiBasePath,
          storeNumber.value,
          retailStartDate.value,
          useCasePricing.value,
          selectedItems,
        );
        labelItemsBatchWs = await lbc.open(onClose, onmessage);
      }
    }
  } catch (e) {
    // $error(e);
    errorMessage(e);
  }
}

function toggleSelectAll(e) {
  selectAllOverrides = e.value;
}

const queueBatchDisabled = computed(() => {
  return (
    labelItemsToBatchLoading.value ||
    !selectedOverridesR.value ||
    selectedOverridesR.value.length === 0
  );
});

const footerBatchAndId = computed(() => {
  return `UOV ${createdBatchInfo && createdBatchInfo.value.batchInfoId ? createdBatchInfo.value.batchInfoId : ''
    }`;
});

const excludedAllOverrides = computed(() => {
  const selectedOverrideIds = selectedOverridesR.value.map(x => x.unitOfMeasureOverrideId);
  const tableOverrideIds = overrides.value.map(x => x.unitOfMeasureOverrideId);

  const excludedOverrides = tableOverrideIds.filter(
    id => selectedOverrideIds.includes(id) === false,
  );
  return excludedOverrides;
});
</script>
