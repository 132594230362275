import { PreCountReportsState, FileShareFile } from './state';
import * as pm from './mutations';
import * as m from '@/store/mutations';
import * as vx from 'vuex';
import FileSaver from 'file-saver';
import { AxiosRequestConfig } from 'axios';

export const searchPreCountReports = 'searchPreCountReports';
export const getStores = 'getStores';
export const downloadReport = 'downloadReport';

export default {
  [getStores]({ commit, state }: vx.ActionContext<PreCountReportsState, any>): Promise<unknown> {
    commit(pm.setStoreGetInProgress, true);

    return state.client
      ?.apiGet('label/storeinfobridge?$orderby=storeNumber asc&$select=storeNumber&$top=99999')
      .then(x => {
        commit(
          pm.setStores,
          x.data.map(x => x.storeNumber),
        );
        commit(pm.setStoreGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error getting stores.',
            id: 'ba3052a5-3c49-40f5-92f4-bc1202e4508a',
          },
          { root: true },
        );
        throw err;
      });
  },
  [searchPreCountReports]({
    commit,
    state,
  }: vx.ActionContext<PreCountReportsState, any>): Promise<unknown> {
    commit(pm.setSearchInProgress, true);

    return state.client
      ?.apiGet(`pogona/fileshare/precount/${state.selectedStore}/${state.selectedBatchId}`)
      .then(x => {
        commit(pm.setReports, x.data);
        commit(pm.setSearchInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error searching for reports.',
            id: '4bb5c6b1-3bd6-4357-87c4-d4c6f26187e8',
          },
          { root: true },
        );
        throw err;
      });
  },
  [downloadReport](
    { commit, state }: vx.ActionContext<PreCountReportsState, any>,
    payload: string,
  ): Promise<any> {
    commit(pm.setDownloadInProgress, true);

    return state.client
      ?.apiGet(`pogona/fileshare/reports/Pre-Count/${payload}`, null, '', {
        responseType: 'blob',
      } as AxiosRequestConfig)
      .then(x => {
        commit(pm.setDownloadInProgress, false);
        FileSaver.saveAs(x.data, payload);
      })
      .catch(err => {
        commit(pm.setDownloadInProgress, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error downloading pre-count report.',
            id: '6f78e6c5-42ff-4f8b-8491-f92a66701ae8',
          },
          { root: true },
        );
        throw err;
      });
  },
};
