<template>
  <div class="text-center">
    <core-view-section
      class="mt-2 section"
      :expandable="true"
      :title="divRegTitle"
      v-model="localExpanded"
      backgroundColor="#A3A3A3"
      color="white"
      fontWeight="600"
      titleCols="6"
    >
      <template v-slot:title>
        <v-col cols="6">
          <v-row>
            <manage-division-region-popup
              :DrId="divreg.DivisionRegionId"
              class="mr-2"
              @save-complete="saveComplete"
            />
            <manage-district-popup
              @save-complete="saveComplete"
              :divisionRegionId="divreg ? divreg.DivisionRegionId : 0"
              buttonText="Manage Districts"
            ></manage-district-popup>
          </v-row>
        </v-col>
      </template>
      <v-row>
        <v-col>
          <v-card style="border: none; box-shadow: none">
            <v-card-text style="border: none; box-shadow: none">
              <p class="text-center" v-if="vpRole !== null">
                <span class="font-weight-bold">VICE PRESIDENT</span><br />
                <span>{{ vpName }}</span
                ><br />
                <span>Cell: {{ vpCell | phone }}</span
                ><br />
                <span>Email: {{ vpEmail }}</span>
              </p>
              <p class="text-center" v-else>
                <span class="font-weight-bold">VICE PRESIDENT</span><br />
                <span>Not Chosen</span>
              </p></v-card-text
            >
          </v-card>
        </v-col>
        <v-col v-if="dirRole !== null">
          <v-card style="border: none; box-shadow: none">
            <v-card-text
              ><p class="text-center">
                <span class="font-weight-bold">DIRECTOR</span><br />
                <span>{{ dirName }}</span
                ><br />
                <span>Cell: {{ dirCell | phone }}</span
                ><br />
                <span>Email: {{ dirEmail }}</span>
              </p></v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-card
        v-for="district in divreg.Districts"
        :key="district.DistrictId"
        class="black--text mt-4 grey lighten-2"
      >
        <v-card-title class="text--black py-1">
          <v-row
            ><v-col cols="2">District {{ district.Name }} </v-col>
            <v-col cols="6" />
            <v-col cols="4">
              <v-row>
                <manage-district-popup
                  @save-complete="saveComplete"
                  small
                  :districtId="district.DistrictId"
                  class="mr-2 mt-1"
                ></manage-district-popup>
                <manage-market-popup
                  @save-complete="saveComplete"
                  :districtId="district.DistrictId"
                  :divisionRegionId="district.DivisionRegionId"
                  small
                  class="mt-1"
                  buttonText="Manage Markets"
                ></manage-market-popup>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-tabs
          v-model="getTab(district.DistrictId).tab"
          :centered="true"
          :grow="true"
          color="black"
        >
          <v-tab :href="`#teamMembers${district.DistrictId}`">Team Members</v-tab>
          <v-tab :href="`#stores${district.DistrictId}`">Stores</v-tab>
          <v-tab :href="`#markets${district.DistrictId}`">Markets</v-tab>
        </v-tabs>
        <v-tabs-items v-model="getTab(district.DistrictId).tab">
          <v-tab-item :value="`teamMembers${district.DistrictId}`" class="text--black"
            ><v-data-table
              :items="teamMembers(district)"
              :headers="teamMemberHeaders"
              disable-sort
              hide-default-footer
              class="scrollable"
              :disable-pagination="true"
            ></v-data-table
          ></v-tab-item>
          <v-tab-item :value="`stores${district.DistrictId}`" class="text--black">
            <v-data-table
              :headers="storeHeaders"
              :items="district.Stores"
              disable-sort
              hide-default-footer
              class="scrollable"
              :disable-pagination="true"
            ></v-data-table>
          </v-tab-item>
          <v-tab-item :value="`markets${district.DistrictId}`" class="text--black">
            <v-card
              v-for="market in district.Markets"
              v-bind:key="market.MarketId"
              class="pa-3"
              style="border: none; box-shadow: none"
            >
              <v-card-title class="text--black grey lighten-2"
                >Market {{ market.Name }}</v-card-title
              >
              <v-card-text class="mt-1">
                <p
                  class="text-center"
                  v-if="
                    market.MarketTeamLeaderRole !== null &&
                    market.MarketTeamLeaderRole.EmployeeId !== 0
                  "
                >
                  <span class="font-weight-bold">MARKET TEAM LEADER</span><br />
                  <span>{{
                    `${market.MarketTeamLeaderRole.Employee.FirstName} ${market.MarketTeamLeaderRole.Employee.LastName}`
                  }}</span
                  ><br />
                  <span>Cell: {{ market.MarketTeamLeaderRole.Employee.Cell | phone }}</span
                  ><br />
                  <span>Email: {{ market.MarketTeamLeaderRole.Employee.Email }}</span>
                </p>
                <p v-else>
                  <span class="font-weight-bold">MARKET TEAM LEADER</span><br /><span
                    >Not Chosen</span
                  >
                </p></v-card-text
              >
              <v-data-table
                :headers="storeHeaders"
                :items="market.Stores"
                disable-sort
                hide-default-footer
                class="scrollable"
                :disable-pagination="true"
              >
              </v-data-table>
            </v-card>
            <p v-if="district.Markets && district.Markets.length > 0 ? false : true">
              There are no markets for this district
            </p>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </core-view-section>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pm from '@/store/modules/pcl/mutations';
import * as ps from '@/store/modules/pcl/state';
import * as pcldto from '@psp/pcldto';
import ManageDivisionRegionPopup from '@/components/pcl/ManageDivisionRegionPopup.vue';
import ManageDistrictPopup from '@/components/pcl/ManageDistrictPopup.vue';
import ManageMarketPopup from '@/components/pcl/ManageMarketPopup.vue';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {
    ManageDivisionRegionPopup,
    ManageDistrictPopup,
    ManageMarketPopup,
  },
})
export default class DivisionRegionDisplayCard extends Vue {
  @Prop() public divreg!: pcldto.pclDivisionRegionWithRelated;
  @Prop({ default: false }) public expanded!: boolean;

  @pclModule.State(ps.roleNames) roleNames!: pcldto.pclRoleName[];

  private isPclWriter = false;
  private localExpanded = this.expanded;
  private tab: ps.DistrictTab[] = [] as ps.DistrictTab[];
  private teamMemberHeaders = [
    {
      text: 'Title',
      value: 'title',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Cell',
      value: 'cell',
    },
    {
      text: 'Email',
      value: 'email',
    },
  ];
  private storeHeaders = [
    { text: 'Store Number', value: 'StoreNumber' },
    { text: 'Name', value: 'Name' },
  ];

  private saveComplete() {
    this.$emit('save-complete');
  }

  public editDivisionRegion(e) {
    e.preventDefault();
    return false;
  }

  public getTab(districtId: number) {
    var index = this.tab.findIndex(t => t.districtId === districtId);
    if (index === -1) {
      index = this.tab.push(new ps.DistrictTab(districtId, `teamMembers${districtId}`)) - 1;
    }
    return this.tab[index];
  }

  public teamMembers(district: any): any {
    const members: any[] = [];

    if (district.DistrictTeamLeaderRole?.RoleNameId) {
      members.push({
        title: this.getTitle(district.DistrictTeamLeaderRole.RoleNameId),
        name: `${district.DistrictTeamLeaderRole.Employee.FirstName} ${district.DistrictTeamLeaderRole.Employee.LastName}`,
        cell: district.DistrictTeamLeaderRole.Employee.Cell,
        email: district.DistrictTeamLeaderRole.Employee.Email,
      });
    }

    district.SeniorStoreTeamLeaderRoles.forEach(s => {
      members.push({
        title: this.getTitle(s.RoleNameId),
        name: `${s.Employee.FirstName} ${s.Employee.LastName}`,
        cell: s.Employee?.Cell,
        email: s.Employee?.Email,
      });
    });

    district.FieldTrainerRoles.forEach(s => {
      members.push({
        title: this.getTitle(s.RoleNameId),
        name: `${s.Employee.FirstName} ${s.Employee.LastName}`,
        cell: s.Employee?.Cell,
        email: s.Employee?.Email,
      });
    });

    district.CritterDistrictLeadRoles.forEach(s => {
      members.push({
        title: this.getTitle(s.RoleNameId),
        name: `${s.Employee.FirstName} ${s.Employee.LastName}`,
        cell: s.Employee?.Cell,
        email: s.Employee?.Email,
      });
    });

    return members;
  }

  getTitle(roleNameId: number): string {
    const role = this.roleNames.find(r => {
      return r.RoleNameId === roleNameId;
    });
    let title = '';
    if (role) {
      title = `${role.Name} (${role.Abbreviation})`;
    }
    return title;
  }

  get divRegTitle(): string {
    return `DIVISION/REGION ${this.divreg.Name}`;
  }

  get vpName(): string {
    return (this.vpRole?.Employee?.FirstName ?? '') + ' ' + (this.vpRole?.Employee?.LastName ?? '');
  }

  get vpCell(): string {
    return this.vpRole?.Employee?.Cell ?? '';
  }

  get vpEmail(): string {
    return this.vpRole?.Employee?.Email ?? '';
  }

  get vpRole(): any | null {
    if (this.divreg.VicePresidentRole == null || this.divreg.VicePresidentRole.RoleId == 0) {
      return null;
    } else {
      return this.divreg.VicePresidentRole;
    }
  }

  get dirName(): string {
    return (
      (this.dirRole?.Employee?.FirstName ?? '') + ' ' + (this.dirRole?.Employee?.LastName ?? '')
    );
  }

  get dirCell(): string {
    return this.dirRole?.Employee?.Cell ?? '';
  }

  get dirEmail(): string {
    return this.dirRole?.Employee?.Email ?? '';
  }

  get dirRole(): any | null {
    if (this.divreg.DirectorRole == null || this.divreg.DirectorRole.RoleId == 0) {
      return null;
    } else {
      return this.divreg.DirectorRole;
    }
  }

  private async mounted() {
    this.isPclWriter = await this.$authApi.roleHasRights('PCLWriter');
  }
}
</script>
