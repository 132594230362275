import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  batchInfoItems: set('batchInfoItems'),
  storeSet: set('storeSet'),
  pendingStoreSet: set('pendingStoreSet'),
  storeSetEndCaps: set('storeSetEndCaps'),
  allPogItems: set('allPogItems'),
  selectedTab: set('selectedTab'),
  setSections: set('setSections'),
  setIdSearch: set('setIdSearch'),
  assignedSets: set('assignedSets'),
  allStoreSets: set('allStoreSets'),
  allStoreSetsEndCaps: set('allStoreSetsEndCaps'),
  setDataSearch: set('setDataSearch'),
  createdBatchInfo: set('createdBatchInfo'),
  storeSetData: set('storeSetData'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
};
