<template>
  <div @click="expand = !expand" class="pointer">
    {{ formatedNotes }}
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({})
export default class PclOwnerGroupNotes extends Vue {
  @Prop()
  readonly notes!: string;

  private expand = false;

  private get formatedNotes() {
    if (this.expand === true) {
      return this.notes;
    } else {
      return this.notes && this.notes.length > 10
        ? this.notes.substring(0, 10) + '...'
        : this.notes;
    }
  }
}
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
