import { Record, Union } from "../fable-library-js.4.16.0/Types.js";
import { record_type, class_type, string_type, union_type, int32_type } from "../fable-library-js.4.16.0/Reflection.js";
import { FSharpResult$2 } from "../fable-library-js.4.16.0/Result.js";
import { op_Addition, compare, op_Division, round, fromParts, op_Multiply } from "../fable-library-js.4.16.0/Decimal.js";
import Decimal from "../fable-library-js.4.16.0/Decimal.js";
import { substring } from "../fable-library-js.4.16.0/String.js";

export class UPCValidationResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ValidUPC", "Invalid_UPC_Length", "Invalid_UPC_CheckDigit"];
    }
}

export function UPCValidationResult_$reflection() {
    return union_type("pogona_vendor_domain.VendorDomain.UPCValidationResult", [], UPCValidationResult, () => [[], [["lenght", int32_type]], []]);
}

export function validateUPC(upc) {
    let length;
    const matchValue = upc.length | 0;
    if ((length = (matchValue | 0), (length >= 12) && (length <= 14))) {
        return new FSharpResult$2(0, [upc]);
    }
    else {
        return new FSharpResult$2(1, [new UPCValidationResult(1, [matchValue])]);
    }
}

export class EventMetaData extends Record {
    constructor(User, When) {
        super();
        this.User = User;
        this.When = When;
    }
}

export function EventMetaData_$reflection() {
    return record_type("pogona_vendor_domain.VendorDomain.EventMetaData", [], EventMetaData, () => [["User", string_type], ["When", class_type("System.DateTime")]]);
}

export function computeFobCost(directToStoreOrVendorIntoPspdPurchaseCost) {
    return directToStoreOrVendorIntoPspdPurchaseCost;
}

export function computeCaseCbm(unitHeight, unitWidth, unitDepth) {
    const unitHeightCalc = op_Multiply(unitHeight, fromParts(254, 0, 0, false, 2));
    const unitWidthCalc = op_Multiply(unitWidth, fromParts(254, 0, 0, false, 2));
    const unitDepthCalc = op_Multiply(unitDepth, fromParts(254, 0, 0, false, 2));
    return round(op_Division(op_Multiply(op_Multiply(unitHeightCalc, unitWidthCalc), unitDepthCalc), fromParts(1000000, 0, 0, false, 0)), 3);
}

export function computeItemCbm(masterCase, caseCbm) {
    if (masterCase > 0) {
        return op_Division(caseCbm, new Decimal(masterCase));
    }
    else {
        return fromParts(0, 0, 0, false, 0);
    }
}

export function computePvDim(caseWidth) {
    if (compare(caseWidth, fromParts(1, 0, 0, false, 2)) > 0) {
        return "CUMETER";
    }
    else {
        return "";
    }
}

export function roundCaseCbm(caseCbm) {
    return round(caseCbm, 3);
}

export function computeEstimatedFreightCost(fobCost, caseCbm, innerCasePack) {
    if ((compare(fobCost, fromParts(0, 0, 0, false, 0)) > 0) && (compare(caseCbm, fromParts(0, 0, 0, false, 0)) > 0)) {
        const caseInnerCalc = op_Multiply(op_Division(fromParts(6000, 0, 0, false, 0), op_Multiply(caseCbm, fromParts(100, 0, 0, false, 0))), new Decimal(innerCasePack));
        if (compare(caseInnerCalc, fromParts(0, 0, 0, false, 0)) > 0) {
            return round(op_Division(fromParts(11400, 0, 0, false, 0), caseInnerCalc), 3);
        }
        else {
            return fromParts(0, 0, 0, false, 0);
        }
    }
    else {
        return fromParts(0, 0, 0, false, 0);
    }
}

export function computeDuty(fobCost, dutyRate) {
    if (compare(fobCost, fromParts(0, 0, 0, false, 0)) > 0) {
        return op_Multiply(fobCost, dutyRate);
    }
    else {
        return fromParts(0, 0, 0, false, 0);
    }
}

export function computeElc(fobCost, estimatedFreightCost, duty) {
    if (compare(fobCost, fromParts(0, 0, 0, false, 0)) > 0) {
        return round(op_Addition(op_Addition(op_Addition(fobCost, estimatedFreightCost), duty), fromParts(5, 0, 0, false, 2)), 2);
    }
    else {
        return fromParts(0, 0, 0, false, 0);
    }
}

export function computeStoreCostCase(masterCase, shipUnitCostFromPspd) {
    return op_Multiply(new Decimal(masterCase), shipUnitCostFromPspd);
}

export function computeGrossPercent(shipUnitCostFromPspd, retailBasePrice) {
    if (compare(retailBasePrice, fromParts(0, 0, 0, false, 0)) > 0) {
        return op_Division(shipUnitCostFromPspd, retailBasePrice);
    }
    else {
        return fromParts(0, 0, 0, false, 0);
    }
}

export function extractDomain(email) {
    const matchValue = email.lastIndexOf("@") | 0;
    if (matchValue === -1) {
        return email;
    }
    else {
        const index = matchValue | 0;
        return substring(email, index + 1, (email.length - 1) - index);
    }
}

