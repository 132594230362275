import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pm from '@/store/modules/pcl/mutations';
import * as pcldb from '@psp/pcldb';
import * as pcldto from '@psp/pcldto';
import * as pclclient from '@psp/pogona_pcl_client_js';
import * as yup from 'yup';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */

const pclModule = namespace('pcl');

@Component({
  components: {},
})
export default class DivisionRegionEditMixin extends Vue {
  @pclModule.State(ps.divisionRegionEdit) divisionRegionEdit!: pcldto.pclDivisionRegionWithRelated;
  @pclModule.State(ps.employees) employees!: pcldto.pclEmployee[];
  @pclModule.State(ps.divisionRegions) divisionRegions!: pcldto.pclDivisionRegionWithRelated[];
  @pclModule.Mutation(pm.clearDivisionRegionEdit) clearDivisionRegionEdit!: () => void;
  @pclModule.Action(pa.getDivisionRegionForEdit) getDivisionRegionForEdit!: (
    payload: number,
  ) => Promise<unknown>;
  @pclModule.Action(pa.getEmployees) getEmployees!: () => Promise<any>;
  @pclModule.Action(pa.saveDivisionRegion) saveDivisionRegion!: (
    payload: pclclient.SaveDivisionRegionData,
  ) => Promise<any>;
  @pclModule.Action(pa.getDivisionRegionResults) getDivisionRegionResults!: (
    odata: ODataProps,
  ) => Promise<pcldto.pclDivisionRegion>;
  @pclModule.State(ps.divisionRegionResults) divisionRegionResults!: pcldto.pclDivisionRegion[];

  protected deleteEntities: any[] = [];
  protected marketStoreDialog: ps.MarketStoreDialog[] = [] as ps.MarketStoreDialog[];
  protected districtStoreDialog: ps.DistrictStoreDialog[] = [] as ps.DistrictStoreDialog[];
  protected showSnackbar = false;
  protected snackbarMessage = '';
  protected snackbarColor = '';
  protected validationErrors = [];
  protected selectedVicePresidentEmployeeId = 0;
  protected selectedDirectorRoleEmployeeId = 0;
  protected loading = false;
  protected errorMessages: ps.PclDivisionRegionMessages = new ps.PclDivisionRegionMessages();
  protected dr: pcldto.pclDivisionRegionWithRelated = new pcldto.pclDivisionRegionWithRelated(
    null,
    [],
    0,
    '',
    null,
  );

  protected drSchema: any = yup.object().shape({
    Name: yup.string().required().test('unique', this.testDivisionNameUnique),
    Districts: yup.array().of(
      yup.object().shape({
        Name: yup.string().required(),
        Markets: yup.array().of(
          yup.object().shape({
            Name: yup.string().required(),
          }),
        ),
      }),
    ),
  });

  public get getDr() {
    return this.dr;
  }

  public async testDivisionNameUnique(
    value: string | undefined,
    context: yup.TestContext<Record<string, any>>,
  ): Promise<boolean | yup.ValidationError> {
    const check = value !== this.$store.state.pcl.divisionRegionEdit?.Name;
    if (check) {
      const odata = new ODataProps();
      odata.filter = f().eq('Name', value).toString();
      await this.getDivisionRegionResults(odata);
    }
    return !check || this.$store.state.pcl.divisionRegionResults.length === 0
      ? true
      : context.createError({ message: 'The division/region name already exists' });
  }

  districtHasStore(districtId: number): boolean {
    let found = false;
    const index = this.dr.Districts.findIndex(d => d.DistrictId === districtId);

    if (index > -1) {
      found = this.dr.Districts[index].Stores.length > 0;
      if (!found) {
        found = this.dr.Districts[index].Markets.some(m => m.Stores.length > 0);
      }
    }

    return found;
  }

  protected removeDistrict(district: pcldto.pclDivisionRegionWithRelatedTypes.District) {
    if (district.DistrictId > 0) {
      district.Markets.forEach(m => this.addMarketDeltion(m));
      district.CritterDistrictLeadRoles.forEach(r => {
        const role = new pcldb.CritterDistrictLeadRoleEntity();
        role.RoleId = r.RoleId;
        this.deleteEntities.push(new pcldb.SerializedDistrictTeamLeaderRole(JSON.stringify(role)));
      });
      if (district.DistrictTeamLeaderRole && district.DistrictTeamLeaderRole.RoleId > 0) {
        const role = new pcldb.DistrictTeamLeaderRoleEntity();
        role.RoleId = district.DistrictTeamLeaderRole.RoleId;
        this.deleteEntities.push(new pcldb.SerializedDistrictTeamLeaderRole(JSON.stringify(role)));
      }
      district.FieldTrainerRoles.forEach(r => {
        const role = new pcldb.FieldTrainerRoleEntity();
        role.RoleId = r.RoleId;
        this.deleteEntities.push(new pcldb.SerializedFieldTrainerRole(JSON.stringify(role)));
      });
      district.SeniorStoreTeamLeaderRoles.forEach(r => {
        const role = new pcldb.SeniorStoreTeamLeaderRoleEntity();
        role.RoleId = r.RoleId;
        this.deleteEntities.push(
          new pcldb.SerializedSeniorStoreTeamLeaderRole(JSON.stringify(role)),
        );
      });
      const dDelete = new pcldb.DistrictEntity();
      dDelete.DistrictId = district.DistrictId;
      this.deleteEntities.push(new pcldb.SerializedDistrict(JSON.stringify(dDelete)));
    }
    const index = this.dr.Districts.findIndex(d => d.DistrictId === district.DistrictId);
    if (index >= 0) {
      this.dr.Districts.splice(index, 1);
    }
  }

  protected addMarketDeltion(
    market: pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market,
  ) {
    if (market.MarketId > 0) {
      if (market.MarketTeamLeaderRole && market.MarketTeamLeaderRole.RoleId > 0) {
        const role = new pcldb.MarketTeamLeaderRoleEntity();
        role.RoleId = market.MarketTeamLeaderRole.RoleId;
        this.deleteEntities.push(new pcldb.SerializedMarketTeamLeaderRole(JSON.stringify(role)));
      }
      const mdelete = new pcldb.MarketEntity();
      mdelete.MarketId = market.MarketId;
      this.deleteEntities.push(new pcldb.SerializedMarket(JSON.stringify(mdelete)));
    }
  }

  protected removeMarket(market: pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market) {
    this.addMarketDeltion(market);
    const index = this.dr.Districts.findIndex(d => d.DistrictId === market.DistrictId);
    if (index >= 0) {
      const mIndex = this.dr.Districts[index].Markets.findIndex(
        m => m.MarketId === market.MarketId,
      );
      if (mIndex >= 0) {
        this.dr.Districts[index].Markets.splice(mIndex, 1);
      }
    }
  }

  public setErrorMessages(field: any, values: string[]): any {
    this.$set(this.errorMessages, field, values);
  }

  get employeeDropDownData(): any {
    const data = [] as unknown[];
    data.push({ name: '(None)', value: 0 });
    this.employees.map(e =>
      data.push({ name: `${e.FirstName} ${e.LastName}`, value: e.EmployeeId }),
    );
    return data;
  }

  protected showSnackbarMessage(message: string, color: string) {
    this.snackbarMessage = message;
    this.snackbarColor = color;
    this.showSnackbar = true;
  }

  protected initDrValues() {
    this.selectedVicePresidentEmployeeId =
      this.dr.VicePresidentRole === null ? 0 : this.dr.VicePresidentRole.EmployeeId;
    this.selectedDirectorRoleEmployeeId =
      this.dr.DirectorRole === null ? 0 : this.dr.DirectorRole.EmployeeId;
  }

  protected loadDivisionRegion(id: number): Promise<any> {
    this.loading = true;
    return this.getEmployees().then(() => {
      if (id === 0) {
        this.clearDivisionRegionEdit();
        this.dr = { ...this.divisionRegionEdit };
        this.initDrValues();
        this.loading = false;
      } else {
        return this.getDivisionRegionForEdit(id)
          .then(() => {
            this.dr = { ...this.divisionRegionEdit };
            this.initDrValues();
          })
          .finally(() => (this.loading = false));
      }
    });
  }

  private validate(field: any): any {
    this.setErrorMessages(field, []);
    this.drSchema.validateAt(field, this.dr).catch(err => this.setErrorMessages(field, err.errors));
  }

  protected save(msgTarget = 'Division/Region') {
    return this.drSchema
      .validate(this.dr)
      .then((dr: pcldto.pclDivisionRegionWithRelated) => {
        this.dr = dr;
        this.validationErrors = [];
        this.loading = true;
        const data = new pclclient.SaveDivisionRegionData();
        data.DivisionRegion = this.dr;
        data.DeleteEntities = this.deleteEntities;
        return this.saveDivisionRegion(data)
          .then(() => {
            this.showSnackbarMessage(`${msgTarget} saved`, 'success');
          })
          .catch(err => {
            this.showSnackbarMessage(`Error saving ${msgTarget}`, 'error');
            throw err;
          })
          .finally(() => (this.loading = false));
      })
      .catch(err => {
        this.validationErrors = err.errors;
        this.showSnackbarMessage(`Cannot save ${msgTarget}, please fix the errors.`, 'error');
        throw err;
      });
  }
}
