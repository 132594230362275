// https://vuex.vuejs.org/en/state.html

export const err = 'err';
export const success = 'success';
export const storeInitialized = 'storeInitialized';
export const visibilitiesInitialized = 'visibilitiesInitialized';

export default {
  [err]: null,
  [success]: null,
  [storeInitialized]: false,
  [visibilitiesInitialized]: false,
};
