var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"text--black"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" How Does This Work? ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('data-tour',{staticClass:"mb-3",attrs:{"tourName":"newItemRequestTour","maxWidth":"600px","steps":_vm.tourSteps,"show":_vm.showTour,"hideHelpIcon":_vm.hideHelpIcon,"autoplay":true,"scrollFirstStep":false,"hideDoNotShowCheckbox":true}})],1)],1)],1),_c('v-card-subtitle',[_vm._v("Choose the Category Manager from the drop down, then download the “New Item Excel Template” below, fill out your required fields including the required fields noted with an *, then simply copy and paste the data into the field below. ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"normal-case",attrs:{"id":"dataEntry","text":"","color":"green"},on:{"click":_vm.templateClick}},[_vm._v("New Item Excel Template")])],1)],1)],1)],1),_c('v-row',{attrs:{"id":"pasteNewItem"}},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"text--black"},[_vm._v("Category Manager")]),_c('v-card-actions',[_c('v-autocomplete',{attrs:{"prepend-icon":"playlist_add_check","items":_vm.categoryManagers,"loading":_vm.processing,"disabled":_vm.processing,"autocomplete":"off","item-text":x => `${x.FirstName} ${x.LastName}`,"item-value":x => `${x.FirstName} ${x.LastName}`,"clearable":""},model:{value:(_vm.categoryManager),callback:function ($$v) {_vm.categoryManager=$$v},expression:"categoryManager"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[(
              _vm.manufacturers &&
              _vm.manufacturers.length > 0 &&
              _vm.brands &&
              _vm.brands.length > 0 &&
              _vm.vendors &&
              _vm.vendors.length > 0 &&
              _vm.unitOfMeasures &&
              _vm.unitOfMeasures.length > 0
            )?_c('new-item-paste-from-excel',{ref:"pasteFromExcel",attrs:{"id":_vm.id,"processing":_vm.processing,"multiSaveDisabled":_vm.multiSaveDisabled,"manufacturers":_vm.manufacturers,"brands":_vm.brands,"vendors":_vm.vendors,"unitOfMeasures":_vm.unitOfMeasures},on:{"submitClicked":_vm.submitClickedMulti,"showSnackbarMessage":function($event){return _vm.showSnackbarMessage($event.text, $event.color)}},model:{value:(_vm.excelData),callback:function ($$v) {_vm.excelData=$$v},expression:"excelData"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"id":"pasteDsd"}},[_c('v-col',[(_vm.vendors && _vm.vendors.length > 0 && _vm.unitOfMeasures && _vm.unitOfMeasures.length > 0)?_c('dsd-paste-from-excel',{ref:"dsdPasteFromExcel",attrs:{"id":_vm.id,"processing":_vm.processing,"multiSaveDisabled":_vm.multiSaveDisabled,"vendors":_vm.vendors,"unitOfMeasures":_vm.unitOfMeasures},on:{"submitClicked":_vm.submitClickedMulti,"showSnackbarMessage":function($event){return _vm.showSnackbarMessage($event.text, $event.color)}},model:{value:(_vm.dsdExcelData),callback:function ($$v) {_vm.dsdExcelData=$$v},expression:"dsdExcelData"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"loading":_vm.processing,"disabled":_vm.processing ||
              _vm.multiSaveDisabled ||
              _vm.zeroPasteItems ||
              !_vm.categoryManager ||
              _vm.categoryManager.length === 0},on:{"click":_vm.submitClickedMulti}},[_vm._v("Save")])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_vm._v(" Click SAVE once both the Merchandising NIF and DSD Distributor (if needed) are complete. Once SAVE is clicked, you can no longer edit. ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.multiBarcodeDialog),callback:function ($$v) {_vm.multiBarcodeDialog=$$v},expression:"multiBarcodeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Barcode From Database")]),_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.selectedBarcodeInfo),callback:function ($$v) {_vm.selectedBarcodeInfo=$$v},expression:"selectedBarcodeInfo"}},_vm._l((_vm.multiBarcodeOptions),function(barcodeOption,ix){return _c('v-radio',{key:ix,attrs:{"value":barcodeOption},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-row',{staticClass:"multiBarcode mt-4"},[_c('v-col',[(barcodeOption.CaseUpc)?_c('v-row',[_c('v-col',[_vm._v(" Case UPC: "+_vm._s(barcodeOption.CaseUpc)+" ")])],1):_vm._e(),(barcodeOption.OtherUpc)?_c('v-row',[_c('v-col',[_vm._v(" Other UPC: "+_vm._s(barcodeOption.CaseUpc)+" ")])],1):_vm._e(),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(barcodeOption.Size)+" "+_vm._s(barcodeOption.Uom)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(barcodeOption.SignsLabelsDescription1))])],1),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(barcodeOption.SignsLabelsDescription2))])],1)],1)],1)]},proxy:true}],null,true)})}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.selectedBarcodeInfo},on:{"click":_vm.autoFillFromDb}},[_vm._v("Fill with selected")]),_c('v-btn',{on:{"click":function($event){_vm.multiBarcodeDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }