<template>
  <core-signs-labels-view-template title="Activate / Deactivate">
    <v-col cols="12">
      <v-row>
        <v-col md="4" sm="6" cols="12">
          <v-btn @click="exceptionsReport">
            <v-icon class="mr-2">thumb_up</v-icon>Activations Report
          </v-btn>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-btn @click="exclusionsReport">
            <v-icon class="mr-2">thumb_down</v-icon>Deactivations Report
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            @click:clear="
              labelItemsAllSearch = null;
              getLabelItemsAllData();
            "
            v-debounce:300="getLabelItemsAllData"
            prepend-icon="search"
            v-model="labelItemsAllSearch"
            label="Search (UPC, item, desc)"
            :clearable="true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="5" lg="4" xl="4">
              <v-card>
                <v-card-text>
                  <v-card-title class="text-wrap">Selected Item Status</v-card-title>
                  <v-radio-group v-model="selectedItemStatus" @change="initLabelItemsAllTable">
                    <v-container>
                      <v-row no-gutters>
                        <v-col>
                          <v-radio
                            v-for="status in filteredItemStatuses"
                            :key="status.id"
                            :label="status.text"
                            :value="status"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8">
              <v-card>
                <v-row>
                  <v-col justify="center" align="center">
                    <span class="headline text--black">Item Status Key</span>
                  </v-col>
                </v-row>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="4" align="center" justify="center" class="mt-3">
                      <v-icon :color="itemStatuses[0].color">{{ itemStatuses[0].icon }}</v-icon>
                      <span class="ml-3">{{ itemStatuses[0].text }}</span>
                      <span class="item-status-description">{{ itemStatuses[0].description }}</span>
                    </v-col>
                    <v-col sm="4" v-if="$vuetify.breakpoint.smAndUp">
                      <v-row justify="center" class="mt-4">
                        <material-arrow :color="arrowColor" :doubleArrow="true"></material-arrow>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="4" align="center" justify="center">
                      <v-icon :color="itemStatuses[3].color">{{ itemStatuses[3].icon }}</v-icon>
                      <span class="ml-3">{{ itemStatuses[3].text }}</span>
                      <span class="item-status-description">{{ itemStatuses[3].description }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" align="center" justify="center" class="mt-3">
                      <v-icon :color="itemStatuses[4].color">{{ itemStatuses[4].icon }}</v-icon>
                      <span class="ml-3">{{ itemStatuses[4].text }}</span>
                      <span class="item-status-description">{{ itemStatuses[4].description }}</span>
                    </v-col>
                    <v-col sm="4" v-if="$vuetify.breakpoint.smAndUp">
                      <v-row justify="center" class="mt-4">
                        <material-arrow :color="arrowColor" :doubleArrow="true"></material-arrow>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="4" align="center" justify="center">
                      <v-icon :color="itemStatuses[1].color">{{ itemStatuses[1].icon }}</v-icon>
                      <span class="ml-3">{{ itemStatuses[1].text }}</span>
                      <span class="item-status-description">{{ itemStatuses[1].description }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-if="labelItemsAll"
              v-model="labelItemsAll.selected"
              :headers="labelItemsAll.headers"
              :items="labelItemsAll.items"
              :loading="labelItemsAll.loading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="labelItemsAll.options"
              @update:sort-by="labelItemsAll.get(labelItemsAllAdditionalFilter)"
              item-key="rowId"
              class="elevation-1 scrollable"
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="labelItemsAll"
                  :additionalFilter="labelItemsAllAdditionalFilter"
                  :colspan="labelItemsAll.headers.length"
                ></infinite-loader>
              </template>

              <template v-slot:item.status="{ item }">
                <v-icon
                  :title="itemStatuses[item.itemStatus].text"
                  :color="itemStatuses[item.itemStatus].color"
                  >{{ itemStatuses[item.itemStatus].icon }}</v-icon
                >
              </template>

              <template v-slot:item.action="{ item }">
                <v-tooltip bottom :disabled="item.restrictedFromDeactivation === false">
                  <template v-slot:activator="{ on }">
                    <label v-on="on" class="py-3">
                      <v-btn
                        v-if="item.itemStatus === 0"
                        @click="showDialog(item)"
                        :disabled="item.restrictedFromDeactivation"
                        >Deactivate</v-btn
                      >
                    </label>
                  </template>
                  <span class="white--text">This item cannot be deactivated.</span>
                </v-tooltip>

                <v-btn v-if="item.itemStatus === 1" @click="showDialog(item)"
                  >Remove Activation</v-btn
                >

                <v-tooltip bottom :disabled="item.globalExclusion !== true">
                  <template v-slot:activator="{ on }">
                    <label v-on="on" class="py-3">
                      <v-btn
                        v-if="item.itemStatus === 3"
                        @click="showDialog(item)"
                        :disabled="item.globalExclusion === true"
                        >Remove Deactivation</v-btn
                      >
                    </label>
                  </template>
                  <span class="white--text"
                    >This item has been deactivated globally and cannot be removed.</span
                  >
                </v-tooltip>

                <v-btn v-if="item.itemStatus === 4" @click="showDialog(item)">Activate</v-btn>
              </template>
              <template v-slot:item.specialOrder="{ item }">
                <v-btn
                  @click="sepcialOrderReport(item)"
                  :loading="specialOrderLoading"
                  :disabled="specialOrderLoading"
                  >Special Order</v-btn
                >
              </template>
            </v-data-table>
            <infinite-paganation :pogonaTable="labelItemsAll"></infinite-paganation>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-dialog max-width="400" v-model="itemStatuses[0].dialogModel">
              <v-card>
                <v-card-title>
                  <span class="headline">Deactivate Item</span>
                </v-card-title>
                <v-card-text>
                  <p class="mb-5">
                    Deactivating this item will remove it from future label printing and suggested
                    ordering and must have approval from your DTL.
                  </p>
                  <p>Have you received approval to deactivate this item?</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="deactivate"
                    :disabled="itemStatuses[0].dialogLoading"
                    :loading="itemStatuses[0].dialogLoading"
                    >Yes</v-btn
                  >
                  <v-btn @click="itemStatuses[0].dialogModel = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row>
            <v-dialog max-width="400" v-model="itemStatuses[1].dialogModel">
              <v-card>
                <v-card-title>
                  <span class="headline">Remove Exception</span>
                </v-card-title>
                <v-card-text>
                  <p class="mb-5">
                    Removing this exception will remove it from future label printing and suggested
                    ordering and must have approval from your DTL.
                  </p>
                  <p>Have you received approval to deactivate this item?</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="removeException"
                    :disabled="itemStatuses[1].dialogLoading"
                    :loading="itemStatuses[1].dialogLoading"
                    >Yes</v-btn
                  >
                  <v-btn @click="itemStatuses[1].dialogModel = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row>
            <v-dialog max-width="400" v-model="itemStatuses[3].dialogModel">
              <v-card>
                <v-card-title>
                  <span class="headline">Remove Exclusion</span>
                </v-card-title>
                <v-card-text>
                  <p class="mb-5">
                    Removing this exclusion will add it to future label printing and suggested
                    ordering and must have approval from your DTL.
                  </p>
                  <p>Have you received approval to reactivate this item?</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="removeExclusion"
                    :disabled="itemStatuses[3].dialogLoading"
                    :loading="itemStatuses[3].dialogLoading"
                    >Yes</v-btn
                  >
                  <v-btn @click="itemStatuses[3].dialogModel = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row>
            <v-dialog max-width="600" v-model="specialOrderNoItemsDialog">
              <v-card>
                <v-card-title>
                  <span class="headline">No Case UPC Found</span>
                </v-card-title>
                <v-card-text>
                  <p class="mb-5">
                    No case UPC's could be found for item number {{ specialOrderNoItem }}. This item
                    cannot be special ordered.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="specialOrderNoItemsDialog = false">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row>
            <v-dialog max-width="400" v-model="itemStatuses[4].dialogModel">
              <v-card>
                <v-card-title>
                  <span class="headline">Activate</span>
                </v-card-title>
                <v-card-text>
                  <p class="mb-5">
                    Activating this item will add it to future label printing and suggested ordering
                    and must have approval from your DTL.
                  </p>
                  <p>Have you received approval to reactivate this item?</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="addException"
                    :disabled="itemStatuses[4].dialogLoading"
                    :loading="itemStatuses[4].dialogLoading"
                    >Yes</v-btn
                  >
                  <v-btn @click="itemStatuses[4].dialogModel = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row>
            <data-report-dialog
              v-model="reportDialog"
              :reportData="reportData"
              :templateName="reportTemplateName"
              :loading.sync="loadingReportData"
              :supportsExcel="supportsExcel"
              @snackbar-error="$emit('snackbar-error', $event)"
            ></data-report-dialog>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <data-tour
          tourName="activateDeactivateTour"
          :steps="tourSteps"
          maxWidth="600px"
          :show="true"
        ></data-tour>
      </v-row>
    </v-col>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import PogonaDataTable from '@/utils/PogonaDataTable';
import PogonaDataHelper from '@/utils/PogonaDataHelper';

/*
  Activated = 0,
  Exception = 1,
  ExceptionAll = 2,
  Exclusion = 3,
  NotActivated = 4,
  NotActivatedAndExclusions = 5,
  ExceptionAndExceptionAll = 6
*/
const itemStatuses = [
  {
    id: 0,
    icon: 'check_circle',
    text: 'In Set',
    dialogModel: false,
    dialogLoading: false,
    color: 'green',
  },
  {
    id: 6,
    icon: 'thumb_up',
    text: 'Activated',
    dialogModel: false,
    color: 'orange',
    description: 'A special case, added to label printing items',
  },
  {
    id: 2,
    icon: 'thumb_up',
    text: 'Exceptions All',
    dialogModel: false,
    color: 'orange',
    description: "A special case, added to all store's sets",
  },
  {
    id: 3,
    icon: 'thumb_down',
    text: 'Deactivated',
    dialogModel: false,
    color: 'blue',
    description: "A special case, removed from your store's set",
  },
  {
    id: 4,
    icon: 'cancel',
    text: 'Not In Set',
    dialogModel: false,
    color: 'red',
  },
];

export default {
  data() {
    return {
      labelItemsAll: this.$store.state.activatedeactivate.labelItemsAll || null,
      itemStatuses,
      filteredItemStatuses: itemStatuses.filter(x => x.id !== 2),
      itemToModify: null,
      deactivateDialog: false,
      reportDialog: false,
      reportData: {},
      reportTemplateName: '',
      loadingReportData: false,
      specialOrderLoading: false,
      specialOrderNoItemsDialog: false,
      specialOrderNoItem: null,
      supportsExcel: true,
      dataHelper: new PogonaDataHelper(),
      arrowColor: '#495057',
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Activate Deactivate Video Tour',
          },
          video: 'activate_deactivate',
          placement: 'bottom',
        },
      ],
    };
  },
  mounted() {
    if (!this.labelItemsAll || !this.labelItemsAll.inited) {
      this.initLabelItemsAllTable();
    } else {
      this.setupEvents();
    }
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    baseUrlFromSelection() {
      switch (this.selectedItemStatus.id) {
        case 0:
          return `label/labelitem/activated/${this.storeNumber}`;
        case 3:
          return `label/labelitem/exclusions/${this.storeNumber}`;
        case 6:
          return `label/labelitem/exceptions/${this.storeNumber}`;
        default:
          return `label/labelitem/${this.storeNumber}/${this.selectedItemStatus.id}`;
      }
    },
    labelItemsAllSearch: {
      get() {
        return this.$store.state.activatedeactivate.labelItemsAllSearch;
      },
      set(val) {
        if (this.labelItemsAll) {
          this.labelItemsAll.onPageFilter = () => this.labelItemsAllAdditionalFilter;
        }
        this.$store.commit('activatedeactivate/labelItemsAllSearch', val);
      },
    },
    selectedItemStatus: {
      get() {
        return (
          this.$store.state.activatedeactivate.selectedItemStatus ||
          this.itemStatuses.filter(x => x.id !== 2)[0]
        );
      },
      set(val) {
        this.$store.commit('activatedeactivate/selectedItemStatus', val);
      },
    },
    labelItemsAllAdditionalFilter() {
      let filter = f('and');
      if (this.labelItemsAllSearch && this.labelItemsAllSearch.length > 0) {
        const trimmedSearch = this.labelItemsAllSearch.trim();
        filter = filter.and(
          f('or')
            .contains("'upc'", trimmedSearch)
            .contains("'itemId'", trimmedSearch)
            .contains("'fulldescription1'", trimmedSearch)
            .contains("'fulldescription2'", trimmedSearch),
        );
      }

      return filter;
    },
  },
  methods: {
    setupEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting items',
          err,
          id: 'f3e2f577-8868-4031-a01e-cd809a7e3ba7',
        });
      };

      this.labelItemsAll.removeListener('error', error);
      this.labelItemsAll.on('error', error);
    },
    getLabelItemsAllData(force) {
      this.labelItemsAll.get(
        this.labelItemsAllAdditionalFilter,
        typeof force === 'boolean' ? force : null,
      );
    },
    async deactivate() {
      const deactivatePromise = this.$authApi.http.post('label/storesetexclusion', {
        itemId: this.itemToModify.itemId,
        setId: 99999,
        storeNumber: this.storeNumber,
        upc: this.itemToModify.upc,
      });
      await this.modifyItem(deactivatePromise);
    },
    async removeException() {
      const removeExceptionPromise = this.$authApi.http.delete(
        `label/storesetexception/${this.itemToModify.exceptionExclusionPk}`,
      );
      await this.modifyItem(removeExceptionPromise);
    },
    async removeExclusion() {
      const removeExclusionPromise = this.$authApi.http.delete(
        `label/storesetexclusion/${this.itemToModify.exceptionExclusionPk}`,
      );
      await this.modifyItem(removeExclusionPromise);
    },
    async addException() {
      const activatePromise = this.$authApi.http.post('label/storesetexception', {
        setId: 99999,
        storeNumber: this.storeNumber,
        upc: this.itemToModify.upc,
      });
      await this.modifyItem(activatePromise);
    },
    async modifyItem(httpPromise) {
      try {
        this.itemStatuses[this.itemToModify.itemStatus].dialogLoading = true;
        await Promise.resolve(httpPromise);
        this.initLabelItemsAllTable();
      } catch (e) {
        this.$error(e);
      } finally {
        this.hideDialog(this.itemToModify);
      }
    },
    hideDialog(item) {
      this.itemStatuses[item.itemStatus].dialogModel = false;
      this.itemStatuses[item.itemStatus].dialogLoading = false;
      this.itemToModify = null;
    },
    showDialog(item) {
      this.itemStatuses[item.itemStatus].dialogModel = true;
      this.itemToModify = item;
    },
    async exceptionsReport() {
      this.loadReport(`label/report/exceptions/${this.storeNumber}`, ['rowId']);
    },
    async exclusionsReport() {
      this.loadReport(`label/report/exclusions/${this.storeNumber}`, ['rowId']);
    },
    async loadReport(url, orderBy) {
      this.loadingReportData = true;
      this.reportDialog = true;
      this.supportsExcel = true;
      this.reportTemplateName = '/exclusions-exceptions/main';

      try {
        this.reportData = await this.dataHelper.getAllReportData({
          $authApi: this.$authApi,
          url,
          orderBy,
          pageSize: 500,
        });
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error loading report data.',
          err,
          id: 'c2168817-49e9-4adc-a51b-5ea9ad696a3a',
        });
      }
    },
    async sepcialOrderReport(item) {
      this.specialOrderLoading = true;
      this.supportsExcel = false;
      this.reportTemplateName = '/special-order/main';

      const reportData = (
        await this.$authApi.http.post('label/report/special-order', {
          storeNumber: this.storeNumber,
          itemIds: [item.itemId],
        })
      ).data;

      if (reportData.items.length === 0) {
        this.loadingReportData = false;
        this.reportDialog = false;
        this.specialOrderLoading = false;

        // show message about no items found
        this.specialOrderNoItemsDialog = true;
        this.specialOrderNoItem = item.itemId;
      } else {
        this.loadingReportData = true;
        this.reportDialog = true;
        this.reportData = reportData;
      }
    },
    initLabelItemsAllTable() {
      this.labelItemsAll = new PogonaDataTable({
        headers: [
          {
            text: 'Status',
            value: 'status',
            sortable: false,
          },
          {
            text: 'UPC #',
            value: 'upc',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
          {
            text: 'Description 1',
            value: 'fulldescription1',
          },
          {
            text: 'Description 2',
            value: 'fulldescription2',
          },
          {
            text: 'Size',
            value: 'unitSize',
          },
          {
            text: 'UOM',
            value: 'unit',
          },
          {
            text: 'Action',
            value: 'action',
            sortable: false,
          },
          {
            text: 'Special Order',
            value: 'specialOrder',
            sortable: false,
          },
        ],
        keys: ['rowId'],
        defaultAllSelected: false,
        multiselect: true,
        baseUrl: this.baseUrlFromSelection,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['itemId'] },
        onPageFilter: () => this.labelItemsAllAdditionalFilter,
        isInfinite: true,
        queryStringParams:
          this.selectedItemStatus.id === 0
            ? {
                includeExceptions: false,
              }
            : null,
      });
      this.setupEvents();
      this.getLabelItemsAllData();
    },
  },
  watch: {
    storeNumber: {
      handler() {
        this.initLabelItemsAllTable();
      },
    },
    reportDialog: {
      handler(val) {
        // Cancel any requests in flight
        if (val === false) {
          this.dataHelper.cancelInFlightRequests();
          this.specialOrderLoading = false;
        }
      },
    },
    loadingReportData: {
      handler(val) {
        if (val === false) {
          this.specialOrderLoading = false;
        }
      },
    },
    labelItemsAll: {
      handler(val) {
        this.$store.commit('activatedeactivate/labelItemsAll', val);
      },
      deep: true,
    },
  },
};
</script>

<style>
.text--black {
  color: rgba(0, 0, 0, 0.8);
}
.item-status-description {
  display: block !important;
}
.arrow {
  font-size: 5em !important;
  font-stretch: ultra-expanded !important;
  clear: both;
  text-align: left;
}
</style>
