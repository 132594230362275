<template>
  <core-view-template title="Store Listings">
    <pcl-store-listing-all-component ref="storeListingAll"> </pcl-store-listing-all-component>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PclStoreListingAllComponent from '@/components/pcl/PclStoreListingAllComponent.vue';
import { namespace } from 'vuex-class';
import * as pm from '@/store/modules/pcl/mutations';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {
    PclStoreListingAllComponent,
  },
})
export default class PclLanding extends Vue {
  private async mounted() {
    if (this.$refs.storeListingAll) {
      await (this.$refs.storeListingAll as PclStoreListingAllComponent).reset();
    }
  }
}
</script>
