<template>
  <core-view-template :title="title">
    <div>
      <show-impersonation />
      <VendTransSearch></VendTransSearch>
      <VendTransGrid
        :headers="headers"
        :vendTrans="vpFinanceStore.vendTransResult"
        :loading="loading"
        :hideDefaultFooter="false"
        :page="page"
        @update-page="page = $event"
        @load-vend-trans="handleGridLoadEvent"
      ></VendTransGrid>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import VendTransSearch from '@/components/vendorportal/VendTransSearch.vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import VendTransGrid from '@/components/vendorportal/VendTransGrid.vue';
import { useRoute } from 'vue-router/composables';
import { VendTransSearchParametersDto } from '@psp/pogona_vendor_api_lib';
import { emptyList, toOption } from '@/utils/FableUtils.fs.js';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import { useTransGridShared } from '@/composables/TransGridShared';
import { getRouteValue } from '@/utils/Utils';
defineComponent({
  name: 'VendTransSearchHome',
});
const gridShared = useTransGridShared();
const headers = [
  gridShared.detailsHeader,
  gridShared.dataAreaIdHeader,
  gridShared.vendAcctHeader,
  gridShared.paymReferenceHeader,
  gridShared.paymModeHeader,
  gridShared.documentDateHeader,
  gridShared.closedHeader,
  gridShared.amountCurHeader,
  gridShared.dueDateHeader,
  gridShared.paymTermIdHeader,
  gridShared.txtHeader,
];
const route = useRoute();
const title = ref('Vendor Transaction Search');
const vpFinanceStore = useVpFinanceStore();
const loading = ref(false);
const page = ref(1);
function handleGridLoadEvent(skip: number, take: number) {
  loadVendTrans(skip, take);
}
const paymentRefRoute = computed(() => {
  return getRouteValue(route.params.paymentRef);
});
const vendAcctRoute = computed(() => {
  return getRouteValue(route.params.vendAcct);
});
const invoiceRoute = computed(() => {
  return getRouteValue(route.params.invoice);
});
const fromDateRoute = computed(() => {
  return getRouteValue(route.params.fromDate);
});
const toDateRoute = computed(() => {
  return getRouteValue(route.params.toDate);
});
const transStatusRoute = computed(() => {
  return parseInt(getRouteValue(route.params.transStatus));
});
function loadVendTrans(skip: number, take: number) {
  if (paymentRefRoute.value !== 'undefined') {
    if (skip === 0) {
      page.value = 1;
    }
    loading.value = true;
    vpFinanceStore
      .searchVendTransactions(
        new VendTransSearchParametersDto(
          paymentRefRoute.value,
          false,
          '',
          vendAcctRoute.value,
          emptyList(),
          fromDateRoute.value ? toOption(new Date(fromDateRoute.value)) : null,
          toDateRoute.value ? toOption(new Date(toDateRoute.value)) : null,
          skip,
          take,
          true,
          false,
          transStatusRoute.value,
        ),
      )
      .finally(() => {
        loading.value = false;
      });
  }
}
watch(
  [paymentRefRoute, vendAcctRoute, invoiceRoute, fromDateRoute, toDateRoute, transStatusRoute],
  () => {
    loadVendTrans(0, 10);
  },
  { immediate: true },
);
onMounted(async () => {
  // loadVendTrans(0, 10);
});
</script>
