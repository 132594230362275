import * as s from '../index';

export default {
  getUserName({ state }) {
    return state.username;
  },
  setShowModalLoading({ commit }, payload) {
    commit('setShowModalLoading', payload);
  },
  setUsername({ commit }, payload) {
    commit('username', payload);
  },
  setStoreNumber({ commit }, payload) {
    commit('storeNumber', payload);
  },
  setMenuVisibilities({ commit }, payload) {
    commit('menuVisibilities', payload);
  },
  setSelectedPrinter({ commit }, payload) {
    commit('selectedPrinter', payload);
  },
  setPrinterTray({ commit }, payload) {
    commit('printerTray', payload);
  },
  availablePrinters({ commit }, payload) {
    commit('availablePrinters', payload);
  },
  showLabelPreviewMode({ commit }, payload) {
    commit('showLabelPreviewMode', payload);
  },
  storeMappings({ commit }, payload) {
    commit('storeMappings', payload);
  },
  showStoreNumber({ commit }, payload) {
    commit('showStoreNumber', payload);
  },
  resetStore({ commit }, payload) {
    Object.keys(payload._mutations).forEach(mutation => {
      const mutationParts = mutation.split('/');

      // is the name of the mutation 'clear'?
      // is the mutation type a function? (it should be)
      if (
        mutationParts.length === 2 &&
        mutationParts[1] === 'clear' &&
        payload._modules.root.state[mutationParts[0]] &&
        typeof payload._mutations[mutation] === 'object' &&
        payload._mutations[mutation].length > 0 &&
        typeof payload._mutations[mutation][0] === 'function'
      ) {
        // call the mutation
        payload._mutations[mutation][0](payload._modules.root.state[mutationParts[0]]);
      }
    });
  },
};
