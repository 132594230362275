<template>
  <v-col>
    <v-row v-if="admin === true">
      <v-autocomplete prepend-icon="store" v-model="selectedStore" :items="stores" label="Store" />
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-text-field
          @click:clear="
            ingredientItemSearch = null;
            getIngredientItemsData();
          "
          v-debounce:300="getIngredientItemsData"
          prepend-icon="search"
          v-model="ingredientItemSearch"
          label="Search (UPC, item, desc)"
          :clearable="true"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-checkbox v-model="allItems" label="Show All Items"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        @click="ingredientItems.clearSelectedItems(ingredientItems)"
        :disabled="trackedDataSelectedCount === 0"
        >Clear Selected{{
          trackedDataSelectedCount > 0 ? ` (${trackedDataSelectedCount})` : ''
        }}</v-btn
      >
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-if="ingredientItems"
          v-model="ingredientItems.selected"
          :headers="ingredientItems.headers"
          :items="ingredientItems.items"
          :loading="ingredientItems.loading"
          :fixed-header="true"
          :options.sync="ingredientItems.options"
          @click:row="ingredientItems.itemRowMultiClicked($event, ingredientItems)"
          @item-selected="ingredientItems.itemRowMultiSelected($event, ingredientItems)"
          @update:sort-by="ingredientItems.get(ingredientItemsAdditionalFilter)"
          item-key="upc"
          show-select
          class="elevation-1 scrollable"
          show-expand
          :single-expand="true"
          :items-per-page="50"
          :disable-pagination="true"
        >
          <template v-slot:header.data-table-select></template>
          <template v-slot:body.append>
            <infinite-loader
              :pogonaTable="ingredientItems"
              :additionalFilter="ingredientItemsAdditionalFilter"
              :colspan="
                ingredientItems && ingredientItems.headers ? ingredientItems.headers.length : 0
              "
            ></infinite-loader>
          </template>
          <template v-slot:item.edit="{ item }" v-if="admin">
            <v-btn @click="editItem(item)">Edit</v-btn>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length">
              <v-row>
                <v-col>
                  <v-row class="font-weight-bold my-4">{{ item.userOption1 }}</v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : ingredientLabelBreakpoint">
                  <v-row>{{ item.userOption2 }}</v-row>
                  <v-row>{{ item.userOption3 }}</v-row>
                  <v-row>{{ item.userOption4 }}</v-row>
                  <v-row>{{ item.userOption5 }}</v-row>
                  <v-row>{{ item.userOption6 }}</v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row>{{ item.crudeProteinMin }}</v-row>
                  <v-row>{{ item.crudeFatMin }}</v-row>
                  <v-row>{{ item.moistureMax }}</v-row>
                  <v-row>{{ item.crudeFiberMin }}</v-row>
                  <v-row>{{ item.crudeFiberMax }}</v-row>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : ingredientLabelBreakpoint">
                  <v-row>{{ item.userOption7 }}</v-row>
                  <v-row>{{ item.userOption8 }}</v-row>
                  <v-row>{{ item.userOption9 }}</v-row>
                  <v-row>{{ item.userOption10 }}</v-row>
                  <v-row>{{ item.userOption11 }}</v-row>
                  <v-row>{{ item.userOption19 }}</v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row>{{ item.calciumMin }}</v-row>
                  <v-row>{{ item.calciumMax }}</v-row>
                  <v-row>{{ item.phosPhorus }}</v-row>
                  <v-row>{{ item.vitaminD3Min }}</v-row>
                  <v-row>{{ item.omega3FattyAcidsMin }}</v-row>
                  <v-row>{{ item.totalMicroorganismsMin }}</v-row>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : ingredientLabelBreakpoint">
                  <v-row>{{ item.userOption12 }}</v-row>
                  <v-row>{{ item.userOption13 }}</v-row>
                  <v-row>{{ item.userOption14 }}</v-row>
                  <v-row class="font-weight-bold">{{ item.userOption15 }}</v-row>
                  <v-row>{{ item.userOption16 }}</v-row>
                  <v-row>{{ item.userOption17 }}</v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row>{{ item.vitaminAmin }}</v-row>
                  <v-row>{{ item.vitaminAmax }}</v-row>
                  <v-row>{{ item.vitaminC }}</v-row>
                  <v-row>&nbsp;</v-row>
                  <v-row>{{ item.kcalKg }}</v-row>
                  <v-row>{{ item.kcalTreat }}</v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row class="font-weight-bold">{{ item.userOption18 }}</v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-tabs class="elevation-2">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#ingredients">Ingredients</v-tab>
                  <v-tab href="#manufactureInformation">Manufacture Information</v-tab>
                  <v-tab href="#feedingInstructions">Feeding Instructions</v-tab>

                  <v-tab-item value="ingredients">
                    <v-card>
                      <v-card-text>{{ item.ingredient }}</v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="manufactureInformation">
                    <v-card>
                      <v-card-text>
                        <v-row>{{ item.mfgname }}</v-row>
                        <v-row>{{ item.mfgaddress }}</v-row>
                        <v-row>{{ item.mfgphone }}</v-row>
                        <v-row>{{ item.mfgwebsite }}</v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="feedingInstructions">
                    <v-card>
                      <v-card-text>{{ item.feedingInstructions }}</v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <infinite-paganation :pogonaTable="ingredientItems"></infinite-paganation>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <retail-start-date-dialog
          v-model="retailStartDate"
          :queueLoading="ingredientItemsToBatchLoading"
          :queueDisabled="queueBatchDisabled"
          :queueCallback="ingredientItemsToBatch"
          :startDateDialog.sync="startDateDialog"
          :createdBatchInfo="createdBatchInfo"
          queueBtnText="Queue Signs"
        ></retail-start-date-dialog>
      </v-col>
    </v-row>
    <v-divider id="batchInfoItemsDivider" class="ma-8"></v-divider>
    <v-row>
      <v-col cols="12">
        <core-view-section
          v-if="createdBatchInfo"
          v-show="createdBatchInfo.printImmediately !== true"
          title="Queue Items"
        >
          <batch-check-items
            v-model="batchInfoItems"
            :batchInfo.sync="createdBatchInfo"
            @dataBound="batchInfoItemsDataBound"
            @error="$emit('snackbar-error', $event)"
            :footerBatchAndId="footerBatchAndId"
            :includeSetDelimters="false"
            :autoCompleteBatch="true"
            :showCompleteToggle="false"
            originatingPage="Ingredient Print"
            labelText="Sign"
          ></batch-check-items>
        </core-view-section>
      </v-col>
    </v-row>
    <v-row v-if="editDialog">
      <v-dialog v-model="editDialog">
        <v-card>
          <v-card-title>Edit Item {{ upcToPlu(itemToEdit.upc) }}</v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption1"
                      @change="itemToEdit.userOption1 = $event"
                      label="Guaranteed Analysis Label"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : ingredientLabelBreakpoint">
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption2"
                      @change="itemToEdit.userOption2 = $event"
                      label="CRUDE PROTEIN (MIN)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption3"
                      @change="itemToEdit.userOption3 = $event"
                      label="CRUDE FAT (MIN)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption4"
                      @change="itemToEdit.userOption4 = $event"
                      label="MOISTURE (MAX)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption5"
                      @change="itemToEdit.userOption5 = $event"
                      label="CRUDE FIBER (MIN)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption6"
                      @change="itemToEdit.userOption6 = $event"
                      label="CRUDE FIBER (MAX)"
                    ></v-text-field
                  ></v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.crudeProteinMin"
                      @change="itemToEdit.crudeProteinMin = $event"
                      label="Crude Protein Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.crudeFatMin"
                      @change="itemToEdit.crudeFatMin = $event"
                      label="Crude Fat Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.moistureMax"
                      @change="itemToEdit.moistureMax = $event"
                      label="Moisture Max"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.crudeFiberMin"
                      @change="itemToEdit.crudeFiberMin = $event"
                      label="Crude Fiber Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.crudeFiberMax"
                      @change="itemToEdit.crudeFiberMax = $event"
                      label="Crude Fiber Max"
                    ></v-text-field
                  ></v-row>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : ingredientLabelBreakpoint">
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption7"
                      @change="itemToEdit.userOption7 = $event"
                      label="CALCIUM (MIN)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption8"
                      @change="itemToEdit.userOption8 = $event"
                      label="CALCIUM (MAX)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption9"
                      @change="itemToEdit.userOption9 = $event"
                      label="PHOSPHORUS (MIN)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption10"
                      @change="itemToEdit.userOption10 = $event"
                      label="VitaminD3(MIN)IU/kg"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption11"
                      @change="itemToEdit.userOption11 = $event"
                      label="Omgea-3FattyAcids (MIN)"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption19"
                      @change="itemToEdit.userOption19 = $event"
                      label="Total Microorganisms (MIN)CFU/g"
                    ></v-text-field
                  ></v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.calciumMin"
                      @change="itemToEdit.calciumMin = $event"
                      label="Calcium Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.calciumMax"
                      @change="itemToEdit.calciumMax = $event"
                      label="Calcium Max"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.phosPhorus"
                      @change="itemToEdit.phosPhorus = $event"
                      label="Phosphorus"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.vitaminD3Min"
                      @change="itemToEdit.vitaminD3Min = $event"
                      label="Vitamin D3 Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.omega3FattyAcidsMin"
                      @change="itemToEdit.omega3FattyAcidsMin = $event"
                      label="Omega 3 Fatty Acids Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.totalMicroorganismsMin"
                      @change="itemToEdit.totalMicroorganismsMin = $event"
                      label="Total Microorganisms Min"
                    ></v-text-field
                  ></v-row>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : ingredientLabelBreakpoint">
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption12"
                      @change="itemToEdit.userOption12 = $event"
                      label="VitaminA(MIN) IU/kg"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption13"
                      @change="itemToEdit.userOption13 = $event"
                      label="Vitamin A Max"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption14"
                      @change="itemToEdit.userOption14 = $event"
                      label="VitaminC MG/KG"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption15"
                      @change="itemToEdit.userOption15 = $event"
                      label="Calorie Count"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption16"
                      @change="itemToEdit.userOption16 = $event"
                      label="KCAL_KG"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption17"
                      @change="itemToEdit.userOption17 = $event"
                      label="KCAL_TREAT"
                    ></v-text-field
                  ></v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : ingredientValueBreakpoint">
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.vitaminAmin"
                      @change="itemToEdit.vitaminAmin = $event"
                      label="Vitamin A Min"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.vitaminAmax"
                      @change="itemToEdit.vitaminAmax = $event"
                      label="Vitamin A Max"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.vitaminC"
                      @change="itemToEdit.vitaminC = $event"
                      label="Vitamin C"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field class="hide-disabled-text-field" :disabled="true"></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.kcalKg"
                      @change="itemToEdit.kcalKg = $event"
                      label="KCal Kg"
                    ></v-text-field
                  ></v-row>
                  <v-row
                    ><v-text-field
                      :maxlength="analysisMaxLength"
                      :value="itemToEdit.kcalTreat"
                      @change="itemToEdit.kcalTreat = $event"
                      label="KCal Treat"
                    ></v-text-field
                  ></v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      :maxlength="userOptionMaxLength"
                      :value="itemToEdit.userOption18"
                      @change="itemToEdit.userOption18 = $event"
                      label="Ingredients Label"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-tabs class="elevation-2">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#ingredients">Ingredients</v-tab>
                  <v-tab href="#manufactureInformation">Manufacture Information</v-tab>
                  <v-tab href="#feedingInstructions">Feeding Instructions</v-tab>

                  <v-tab-item value="ingredients">
                    <v-card>
                      <v-textarea
                        :maxlength="freeFormMaxLength"
                        :value="itemToEdit.ingredient"
                        @change="itemToEdit.ingredient = $event"
                      ></v-textarea>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="manufactureInformation">
                    <v-card>
                      <v-card-text>
                        <v-row
                          ><v-text-field
                            :maxlength="mfgInfoMaxLength"
                            :value="itemToEdit.mfgname"
                            @change="itemToEdit.mfgname = $event"
                            label="Mfg Name"
                          ></v-text-field
                        ></v-row>
                        <v-row
                          ><v-text-field
                            :maxlength="mfgInfoMaxLength"
                            :value="itemToEdit.mfgaddress"
                            @change="itemToEdit.mfgaddress = $event"
                            label="Mfg Address"
                          ></v-text-field
                        ></v-row>
                        <v-row
                          ><v-text-field
                            :maxlength="mfgphoneMaxlength"
                            :value="itemToEdit.mfgphone"
                            @change="itemToEdit.mfgphone = $event"
                            label="Mfg Phone"
                          ></v-text-field
                        ></v-row>
                        <v-row
                          ><v-text-field
                            :maxlength="mfgInfoMaxLength"
                            :value="itemToEdit.mfgwebsite"
                            @change="itemToEdit.mfgwebsite = $event"
                            label="Mfg Website"
                          ></v-text-field
                        ></v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="feedingInstructions">
                    <v-card>
                      <v-textarea
                        :maxlength="freeFormMaxLength"
                        :value="itemToEdit.feedingInstructions"
                        @change="itemToEdit.feedingInstructions = $event"
                      ></v-textarea>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-col cols="12">
              <v-row justify="end">
                <v-btn @click="submitEditItem" :disabled="savingItem" :loading="savingItem"
                  >Submit</v-btn
                >
                <v-btn @click="editDialog = false">Close</v-btn>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
/* eslint-disable indent */
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import uuid from 'uuid';
import PogonaDataTable from '@/utils/PogonaDataTable';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import BatchCheckItems from './BatchCheckItems.vue';
import RetailStartDateDialog from './RetailStartDateDialog.vue';
import Vue from 'vue';

const IngredientPrint = Vue.component('ingredient-print', {
  components: { BatchCheckItems, RetailStartDateDialog },
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ingredientItems:
        (this.admin
          ? this.$store.state.ingredientprintadmin.ingredientItems
          : this.$store.state.ingredientprint.ingredientItems) || {},
      retailStartDate: undefined, // undefined so the default in the component will be used
      startDateDialog: false,
      ingredientItemsToBatchLoading: false,
      adalHelper: new AdalHelpers(),
      ingredientItemsBatchWs: null,
      ingredientLabelBreakpoint: 8,
      ingredientValueBreakpoint: 4,
      editDialog: false,
      itemToEdit: null,
      savingItem: false,
      userOptionMaxLength: 35,
      analysisMaxLength: 10,
      freeFormMaxLength: 2000,
      mfgInfoMaxLength: 60,
      mfgphoneMaxlength: 15,
      stores: [],
    };
  },
  async mounted() {
    if (!this.ingredientItems || !this.ingredientItems.inited) {
      this.initIngredientItemsTable();
    } else {
      this.setupEvents();
    }

    if (this.admin === true) {
      await this.initStores();
    }
  },
  computed: {
    ...mapState('app', ['storeNumber']),

    batchInfoItems: {
      get() {
        return this.ingredientprintStore.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/batchInfoItems', val);
      },
    },
    ingredientprintStore() {
      return this.admin
        ? this.$store.state.ingredientprintadmin
        : this.$store.state.ingredientprint;
    },
    ingredientprintStoreString() {
      return this.admin ? 'ingredientprintadmin' : 'ingredientprint';
    },
    createdBatchInfo: {
      get() {
        return this.ingredientprintStore.createdBatchInfo || null;
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/createdBatchInfo', val);
      },
    },
    ingredientItemSearch: {
      get() {
        return this.ingredientprintStore.ingredientItemSearch || null;
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/ingredientItemSearch', val);
      },
    },
    selectedItems: {
      get() {
        return this.ingredientprintStore.selectedItems || [];
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/selectedItems', val);
      },
    },
    trackedDataSelectedCount: {
      get() {
        return this.ingredientprintStore.trackedDataSelectedCount || 0;
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/trackedDataSelectedCount', val);
      },
    },
    selectedStore: {
      get() {
        return this.ingredientprintStore.selectedStore || null;
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/selectedStore', val);
      },
    },
    allItems: {
      get() {
        return this.ingredientprintStore.allItems || null;
      },
      set(val) {
        this.$store.commit(this.ingredientprintStoreString + '/allItems', val);
      },
    },
    queueBatchDisabled() {
      return this.ingredientItemsToBatchLoading || this.selectedItems.length === 0;
    },
    ingredientItemsAdditionalFilter() {
      let filter = f('and');
      if (this.ingredientItemSearch && this.ingredientItemSearch.length > 0) {
        const trimmedSearch = this.ingredientItemSearch.trim();
        filter = filter.and(
          f('or')
            .contains("'upc'", trimmedSearch)
            .contains("'itemId'", trimmedSearch)
            .contains("'fulldescription1'", trimmedSearch)
            .contains("'fulldescription2'", trimmedSearch),
        );
      }
      return filter;
    },
    footerBatchAndId() {
      return `LBL ${
        this.createdBatchInfo && this.createdBatchInfo.batchInfoId
          ? this.createdBatchInfo.batchInfoId
          : ''
      }`;
    },
  },
  methods: {
    setupEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting ingredient items',
          err,
          id: '9af6a8cf-9920-4c56-ba77-9ab74668c803',
        });
      };

      const updateTrackedDataSelectedCount = count => {
        this.trackedDataSelectedCount = count;
      };

      this.ingredientItems.removeListener('error', error);
      this.ingredientItems.removeListener(
        'trackedDataSelectedCount',
        updateTrackedDataSelectedCount,
      );

      this.ingredientItems.on('error', error);
      this.ingredientItems.on('trackedDataSelectedCount', updateTrackedDataSelectedCount);
    },
    upcToPlu(upc) {
      if (upc && upc.length >= 12) {
        return upc.substring(6, upc.length - 1);
      }
      return '';
    },
    async getIngredientItemsData(query, force) {
      await this.ingredientItems.get(this.ingredientItemsAdditionalFilter, force);
    },
    async ingredientItemsToBatch(source) {
      const errorMessage = err => {
        this.ingredientItemsToBatchLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error creating batch from ingredients.',
          err,
          id: '63358f4f-3c77-430d-8f64-36b141a37f6d',
        });
      };

      try {
        this.batchInfoItems = {};
        this.createdBatchInfo = null;
        this.startDateDialog = false;
        this.ingredientItemsToBatchLoading = true;

        const adalToken = await this.adalHelper.getJwtToken(this.$authApi);
        const ingredientItemToBatchId = uuid();
        let batchCreated = false;

        const onClose = () => {
          // If the web socket connection closed before
          // the batch was created throw an error message.
          if (batchCreated === false) {
            errorMessage();
          }
        };

        // Register on message handler before posting the message
        const onmessage = event => {
          const recMessage = JSON.parse(event.data);

          batchCreated = true;

          this.ingredientItemsBatchWs.close();

          if (recMessage.success === false) {
            errorMessage();
            return;
          }

          this.createdBatchInfo = recMessage.batchInfo;
          this.createdBatchInfo.printImmediately = (source && source.printImmediately) || false;
        };

        const onOpen = async () => {
          const selectedUpcs = this.selectedItems.map(
            key => this.ingredientItems.trackedData[key].item.upc,
          );

          const ingredientsItemsBatchData = {
            id: ingredientItemToBatchId,
            upcs: selectedUpcs,
            storeNumber: this.admin ? this.selectedStore : this.storeNumber,
            retailStartDate: this.retailStartDate,
          };
          const servicebusUrl = this.admin
            ? 'servicebus/ingredientsstagebatch'
            : 'servicebus/ingredientsbatch';
          await this.$authApi.http.post(servicebusUrl, ingredientsItemsBatchData);
        };

        const servicebusQueue = this.admin ? 'ingredients_stage_batch' : 'ingredients_batch';
        this.ingredientItemsBatchWs = new PogonaWebSocket(
          this.$apiBasePath,
          servicebusQueue,
          ingredientItemToBatchId,
          adalToken,
          onClose,
          onmessage,
          null,
          onOpen,
        );
      } catch (e) {
        this.$error(e);
        errorMessage();
      }
    },
    batchInfoItemsDataBound() {
      // Jump to the batch data.
      this.ingredientItemsToBatchLoading = false;
      this.$vuetify.goTo('#batchInfoItemsDivider');
    },
    editItem(item) {
      this.editDialog = true;
      this.itemToEdit = item;
    },
    async submitEditItem() {
      try {
        this.savingItem = true;

        // remove these non-db props, or it won't work
        delete this.itemToEdit.nonScaledUpc;
        delete this.itemToEdit.itemId;
        delete this.itemToEdit.fulldescription1;
        delete this.itemToEdit.fulldescription2;
        delete this.itemToEdit.unitSize;
        delete this.itemToEdit.unitMeasure;

        await this.$authApi.http.patch(
          `label/ingredprintstage/${this.itemToEdit.upc}`,
          this.itemToEdit,
        );

        this.editDialog = false;
        this.savingItem = false;
        this.itemToEdit = null;
        this.initIngredientItemsTable();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error saving item',
          err,
          id: 'e6b41087-0f2b-4537-9547-ab9b37296b41',
        });
        this.savingItem = false;
      }
    },
    initIngredientItemsTable() {
      const headers = [
        {
          text: 'UPC #',
          value: 'upc',
        },
        {
          text: 'Item #',
          value: 'itemId',
        },
        {
          text: 'Description 1',
          value: 'fulldescription1',
        },
        {
          text: 'Description 2',
          value: 'fulldescription2',
        },
        {
          text: 'Size',
          value: 'unitSize',
        },
        {
          text: 'UOM',
          value: 'unitMeasure',
        },
        { text: '', value: 'data-table-expand' },
      ];

      if (this.admin) {
        headers.splice(headers.length - 1, 0, {
          text: 'Edit',
          value: 'edit',
        });
      }

      this.ingredientItems = new PogonaDataTable({
        headers,
        keys: ['upc'],
        defaultAllSelected: false,
        multiselect: true,
        baseUrl: this.admin
          ? `label/ingredprintstage/scaled/${this.selectedStore}`
          : `label/ingredprintstore/scaled/${this.storeNumber}`,
        httpClient: this.$authApi.http,
        options: {
          itemsPerPage: 50,
          sortBy: ['upc'],
        },
        onPageFilter: () => this.ingredientItemsAdditionalFilter,
        queryStringParams: { allItems: this.allItems ? 'true' : 'false' },
        isInfinite: true,
      });

      this.setupEvents();
      this.getIngredientItemsData();
    },
    async initStores() {
      const storeCount = (
        await this.$authApi.http.get("label/storeinfobridge/$count?$filter=storeNumber ne ''")
      ).data;

      const top = 200;
      let skip = 0;
      while (this.stores.length < storeCount) {
        const stores = (
          await this.$authApi.http.get(
            `label/storeinfobridge?$order-by=itemId&$filter=storeNumber ne ''&$top=${top}&$skip=${skip}`,
          )
        ).data;
        skip += top;

        stores.forEach(v => {
          this.stores.push(v.storeNumber);
        });
      }

      if (
        this.storeNumber &&
        this.storeNumber !== this.$defaultStore &&
        this.stores.indexOf(this.storeNumber) > -1
      ) {
        this.selectedStore = this.storeNumber;
      } else {
        this.selectedStore = this.stores[0];
      }
    },
    reInitItemsTable() {
      this.batchInfoItems = {};
      this.createdBatchInfo = null;
      this.initIngredientItemsTable();
    },
  },
  watch: {
    storeNumber: {
      handler() {
        this.reInitItemsTable();
      },
    },
    selectedStore: {
      handler() {
        this.reInitItemsTable();
      },
    },
    allItems: {
      handler() {
        this.reInitItemsTable();
      },
    },
    ingredientItems: {
      handler: function (val) {
        this.selectedItems = this.ingredientItems
          ? this.ingredientItems.trackedDataKeys.filter(
              key => this.ingredientItems.trackedData[key]._selected === true,
            )
          : [];
        this.$store.commit(this.ingredientprintStoreString + '/ingredientItems', val);
      },
      deep: true,
    },
  },
});

export default IngredientPrint;
export { IngredientPrint };
</script>

<style lang="scss" scoped>
.hide-disabled-text-field {
  visibility: hidden;
}
</style>
