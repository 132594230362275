import * as pclclient from '@psp/pogona_pcl_client_js';

export default class NewItemRequestAllFields {
  constructor(
    public RequestType: pclclient.StoreListingAllSearchParam | null = null,
    public PrimaryUpc: pclclient.StoreListingAllSearchParam | null = null,
    public Brand: pclclient.StoreListingAllSearchParam | null = null,
    public CategoryManagerName: pclclient.StoreListingAllSearchParam | null = null,
    public ManufacturerAccountNumber: pclclient.StoreListingAllSearchParam | null = null,
    public Status: pclclient.StoreListingAllSearchParam | null = null,
    public RejectReasons: pclclient.StoreListingAllSearchParam | null = null,
    public CreatedDateTime: pclclient.StoreListingAllSearchParam | null = null,
    public BatchId: pclclient.StoreListingAllSearchParam | null = null,
  ) {
    this.RequestType = new pclclient.StoreListingAllSearchParam(
      'RequestType',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
    this.PrimaryUpc = new pclclient.StoreListingAllSearchParam(
      'PrimaryUpc',
      null,
      pclclient.StoreListingAllSearchType.Like,
    );
    this.Brand = new pclclient.StoreListingAllSearchParam(
      'Brand',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
    this.CategoryManagerName = new pclclient.StoreListingAllSearchParam(
      'CategoryManagerName',
      null,
      pclclient.StoreListingAllSearchType.Like,
    );
    this.ManufacturerAccountNumber = new pclclient.StoreListingAllSearchParam(
      'ManufacturerAccountNumber',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
    this.Status = new pclclient.StoreListingAllSearchParam(
      'Status',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
    this.RejectReasons = new pclclient.StoreListingAllSearchParam(
      'RejectReasons',
      null,
      pclclient.StoreListingAllSearchType.Like,
    );
    this.CreatedDateTime = new pclclient.StoreListingAllSearchParam(
      'CreatedDateTime',
      null,
      pclclient.StoreListingAllSearchType.DateRange,
    );
    this.BatchId = new pclclient.StoreListingAllSearchParam(
      'BatchId',
      null,
      pclclient.StoreListingAllSearchType.In,
    );
  }
}
