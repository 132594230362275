import { value as value_1, some } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { newGuid as newGuid_1, parse } from "../fable_modules/fable-library-js.4.16.0/Guid.js";
import { BatchType } from "@/utils/VendorDomainTypes";
import { BatchType as BatchType_1 } from "../fable_modules/pogona_vendor_domain.fable.1.105.0/Batch.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.16.0/Types.js";
import { equals } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { now } from "../fable_modules/fable-library-js.4.16.0/Date.js";
import { EventMetaData } from "../fable_modules/pogona_vendor_domain.fable.1.105.0/VendorDomain.fs.js";
import { computeElcAgg as computeElcAgg_1, computeDutyAgg as computeDutyAgg_1, computeEstimatedFreightCostAgg as computeEstimatedFreightCostAgg_1, computePvDimAgg as computePvDimAgg_1, computeItemCbmAgg as computeItemCbmAgg_1, computeCaseCbmAgg as computeCaseCbmAgg_1, computeFobCostAgg as computeFobCostAgg_1, NewItemRequestCommand } from "../fable_modules/pogona_vendor_domain.fable.1.105.0/NewItemRequest.fs.js";
import { exists, empty, updateAt, toArray, length, isEmpty } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { split } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { last } from "../fable_modules/fable-library-js.4.16.0/Array.js";

export const allTransStatus = 0;

export const openTransStatus = 1;

export const closedTransStatus = 2;

export const transStatuses = [{
    Id: allTransStatus,
    Name: "All",
}, {
    Id: openTransStatus,
    Name: "Open",
}, {
    Id: closedTransStatus,
    Name: "Closed",
}];

export function toOption(value) {
    if (value == null) {
        return void 0;
    }
    else {
        return some(value);
    }
}

export function convertStringToGuid(guid) {
    return parse(guid);
}

export function convertBatchType(batchType) {
    if (batchType === BatchType.NewItemBatch) {
        return new BatchType_1(0, []);
    }
    else if (batchType === BatchType.PackageChangeBatch) {
        return new BatchType_1(1, []);
    }
    else {
        throw new Error("Unknown batch type");
    }
}

export function convertBatchTypeString(batchType) {
    switch (batchType) {
        case "NewItemBatch":
            return new BatchType_1(0, []);
        case "PackageChangeBatch":
            return new BatchType_1(1, []);
        default:
            throw new Error("Unknown batch type");
    }
}

export function newGuid() {
    return newGuid_1();
}

export function raiseIfError(result) {
    let copyOfStruct;
    if (result.tag === 1) {
        throw new Error((copyOfStruct = result.fields[0], toString(copyOfStruct)));
    }
}

export function isOk(result) {
    if (result.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function getOk(result) {
    if (result.tag === 1) {
        throw new Error("getOk should not be an Error");
    }
    else {
        return result.fields[0];
    }
}

export function isNone(opt) {
    if (opt == null) {
        return true;
    }
    else {
        return false;
    }
}

export function isSome(opt) {
    if (opt == null) {
        return false;
    }
    else {
        return true;
    }
}

export function getSome(opt) {
    if (opt == null) {
        throw new Error("Not expected to be None");
    }
    else {
        return value_1(opt);
    }
}

export function extractNewItemRequest(result) {
    if (result.tag === 1) {
        throw new Error("Error result");
    }
    else {
        return result.fields[0];
    }
}

export function areDifferent(originalValue, newValue) {
    if (equals(originalValue, newValue)) {
        return void 0;
    }
    else {
        return some(newValue);
    }
}

export function getCommandMetaData() {
    return new EventMetaData("", now());
}

export function createUpdateUpcCommandIfChanged(originalUpc, newUpc) {
    const matchValue = areDifferent(originalUpc, newUpc);
    if (matchValue == null) {
        return void 0;
    }
    else {
        const upc = matchValue;
        return new NewItemRequestCommand(1, [[getCommandMetaData(), upc]]);
    }
}

export function createUpdateDescriptionCommandIChanged(originalDescription, newDescription) {
    const matchValue = areDifferent(originalDescription, newDescription);
    if (matchValue == null) {
        return void 0;
    }
    else {
        const description = matchValue;
        return new NewItemRequestCommand(2, [[getCommandMetaData(), description]]);
    }
}

export function computeFobCostAgg(request) {
    return computeFobCostAgg_1(request);
}

export function computeCaseCbmAgg(request) {
    return computeCaseCbmAgg_1(request);
}

export function computeItemCbmAgg(request) {
    return computeItemCbmAgg_1(request);
}

export function computePvDimAgg(request) {
    return computePvDimAgg_1(request);
}

export function computeEstimatedFreightCostAgg(request) {
    return computeEstimatedFreightCostAgg_1(request);
}

export function computeDutyAgg(request) {
    return computeDutyAgg_1(request);
}

export function computeElcAgg(request) {
    return computeElcAgg_1(request);
}

export function getTextOption(o) {
    if (o == null) {
        return "";
    }
    else {
        let copyOfStruct = value_1(o);
        return toString(copyOfStruct);
    }
}

export function getText(u) {
    let copyOfStruct = u;
    return toString(copyOfStruct);
}

export function listIsEmpty(list) {
    return isEmpty(list);
}

export function listLength(list) {
    return length(list);
}

export function listToArray(list) {
    return toArray(list);
}

export function listUpdateAt(index, value, list) {
    return updateAt(index, value, list);
}

export function emptyList() {
    return empty();
}

export function getFileName(path) {
    const segments = split(path, ["/", "\\"], void 0, 1);
    if (segments.length === 0) {
        return "";
    }
    else {
        return last(segments);
    }
}

export function existsInList(value, list) {
    if (list != null) {
        return exists((x) => equals(x, value), list);
    }
    else {
        return false;
    }
}

