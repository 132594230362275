import { render, staticRenderFns } from "./ItemRequestImageUpload.vue?vue&type=template&id=d88a6984&scoped=true"
import script from "./ItemRequestImageUpload.vue?vue&type=script&lang=ts"
export * from "./ItemRequestImageUpload.vue?vue&type=script&lang=ts"
import style0 from "./ItemRequestImageUpload.vue?vue&type=style&index=0&id=d88a6984&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d88a6984",
  null
  
)

export default component.exports