import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PogonaApiClient } from '@psp/pogona_api_client_js';
import PogonaDataTable from '../utils/PogonaDataTable';
import ODataProps from '@/utils/ODataProps';
import HandHeldLine, { HandHeldLineDataTable } from '@/data/SignsAndLabels/HandHeldLine';
import buildQuery from 'odata-query';
import f from 'odata-filter-builder';
import store from '@/store';

export class UnitOfMeasureOverride {
  constructor(
    public unitOfMeasureOverrideId: number = 0,
    public unitMeasure: string = '',
    public size: number = 0,
    public packageUnitMeasure: string = '',
    public packageUnitMeasureConversionSymbol: string = '',
    public packageSize: number = 0,
    public upc: string = '',
    public itemId: string = '',
    public unitOfMeasureOverrideTypeId: number = 0,
    public isDryWeight = false,
    public isShampooConditioner = false,
    public timeInserted: string | null | undefined = null,
    public lastTimeUpdated: Date | null = null,
    public storeNumber: string = '',
  ) {}
}

export class UnitOfMeasureOverrideHandheldPayload {
  constructor(
    public handHeldMainId: number = 0,
    public uomOverride: UnitOfMeasureOverride,
    public excludedHandHeldLineIds: number[] = [] as number[],
  ) {}
}

export const useUnitOverrideStore = defineStore('unitoverridestore', () => {
  const client = ref<PogonaApiClient | null>(null);
  const items = ref<PogonaDataTable | any>({});
  const nonActivatedToggle = ref(false);
  const search = ref('');
  const selectedBarcodeSetups = ref<Array<string>>(['PrimaryUPC'] as Array<string>);
  const queuedItems = ref<any>({});
  const unitOfMeasureOverrideTypeId = ref<number>(-1);
  const determiningUnitOfMeasureOverrideTypeId = ref(false);
  const selectedUnitOfMeasureOverride = ref<UnitOfMeasureOverride | null>(null);
  const gettingSelectedUnitOfMeasureOverride = ref(false);
  const savingUnitOfMeasureOverride = ref(false);
  const gettingOverrides = ref(false);
  const gettingOverridesCount = ref(false);
  const gettingOverridesAllCount = ref(false);
  const overrides = ref<UnitOfMeasureOverride[]>([] as UnitOfMeasureOverride[]);
  const lastOverridePage = ref<UnitOfMeasureOverride[]>([] as UnitOfMeasureOverride[]);
  const overridesCount = ref(0);
  const overridesAllCount = ref(0);
  const overridesOData = ref(new ODataProps());
  const selectedOverrides = ref<UnitOfMeasureOverride[]>([] as UnitOfMeasureOverride[]);
  const batchInfoItems = ref<any>({});
  const createdBatchInfo = ref<any>(null);
  const currentTab = ref('');
  const handHeldHeaderData = ref<any>({});
  const handHeldSearch = ref('');
  const handheldCompleted = ref(false);
  const handHeldLineDataTable = ref<HandHeldLineDataTable>(
    new HandHeldLineDataTable([] as HandHeldLine[], [] as HandHeldLine[], 0, ''),
  );

  function init(http: any, baseUrl: string | null): void {
    client.value = new PogonaApiClient(null, http, baseUrl);
  }

  function buildOverridesQuery(payload: ODataProps): string {
    const odata = Object.assign({}, payload) as ODataProps;
    if (payload.filter && payload.filter.length > 0) {
      const filter = f('or').contains("'upc'", payload.filter).contains("'itemId'", payload.filter);
      odata.filter = filter.toString();
    }
    return buildQuery(odata);
  }

  function determineUnitOfMeasureOverrideTypeId(storeId: string): Promise<any> {
    determiningUnitOfMeasureOverrideTypeId.value = true;
    return client
      .value!.apiGet(`./label/unitofmeasureoverridetype/store/${storeId}`)
      .then(x => {
        unitOfMeasureOverrideTypeId.value = x.data;
        determiningUnitOfMeasureOverrideTypeId.value = false;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error determining unit of measureOverride type.',
            id: 'f57ed7c0-9ab3-400c-abff-1720e2f6f7f0',
          },
          { root: true },
        );
        throw err;
      });
  }
  function getUnitOfMeasureOverride(upc: string): Promise<any> {
    gettingSelectedUnitOfMeasureOverride.value = true;
    return client
      .value!.apiGet(
        `./label/unitofmeasureoverride?$filter=upc eq '${upc}' and unitOfMeasureOverrideTypeId eq ${unitOfMeasureOverrideTypeId.value}`,
      )
      .then(x => {
        if (x.data && x.data.length === 1) {
          selectedUnitOfMeasureOverride.value = x.data[0];
        } else {
          selectedUnitOfMeasureOverride.value = null;
        }
        gettingSelectedUnitOfMeasureOverride.value = false;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error getting unit of measure override.',
            id: 'bb16c988-eb71-4bbe-8b88-d704b61d50ec',
          },
          { root: true },
        );
        throw err;
      });
  }
  function createUnitOfMeasureOverride(payload: UnitOfMeasureOverride): Promise<any> {
    savingUnitOfMeasureOverride.value = true;
    return client
      .value!.apiPost('./label/unitofmeasureoverride', payload)
      .then(x => {
        savingUnitOfMeasureOverride.value = false;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error creating unit of measure override.',
            id: '2a501fce-d21d-4cdd-9617-01d0eb9e27f5',
          },
          { root: true },
        );
        throw err;
      });
  }
  function editUnitOfMeasureOverride(payload: UnitOfMeasureOverride): Promise<any> {
    savingUnitOfMeasureOverride.value = true;
    return client
      .value!.apiPatch(`./label/unitofmeasureoverride/${payload.unitOfMeasureOverrideId}`, payload)
      .then(x => {
        savingUnitOfMeasureOverride.value = false;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error saving unit override.',
            id: 'ec047483-0289-492a-b2ec-f6e11dc4385e',
          },
          { root: true },
        );
        throw err;
      });
  }

  function createUnitOfMeasureOverridesFromHandheldBatch(
    payload: UnitOfMeasureOverrideHandheldPayload,
  ): Promise<any> {
    savingUnitOfMeasureOverride.value = true;
    return client
      .value!.apiPost('./label/unitofmeasureoverride/handheld', payload)
      .then(x => {
        savingUnitOfMeasureOverride.value = false;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error saving unit override for handheld batch.',
            id: 'a5d62dc6-ac20-49b7-98db-cd735f0d0315',
          },
          { root: true },
        );
        throw err;
      });
  }

  function getUnitOfMeasureOverrides(payload: ODataProps | null): Promise<any> {
    gettingOverrides.value = true;
    let query = '';
    if (payload) {
      query = buildOverridesQuery(payload);
    }

    return client
      .value!.apiGet(`./label/unitofmeasureoverride${query}`)
      .then(x => {
        overrides.value = x.data;
        lastOverridePage.value = x.data;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error getting overrides.',
            id: 'e2bd6603-d644-4c64-8215-5b3aeb0b1c92',
          },
          { root: true },
        );
        throw err;
      })
      .finally(() => {
        gettingOverrides.value = false;
      });
  }

  function getUnitOfMeasureOverridesCount(payload: ODataProps): Promise<any> {
    gettingOverridesCount.value = true;
    const query = buildOverridesQuery(payload);

    return client
      .value!.apiGet(`./label/unitofmeasureoverride/$count${query}`)
      .then(x => {
        overridesCount.value = x.data;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error getting overrides count.',
            id: '858a729d-aaa9-4e9f-bc97-7233c7e6a91c',
          },
          { root: true },
        );
        throw err;
      })
      .finally(() => {
        gettingOverridesCount.value = false;
      });
  }

  function getUnitOfMeasureOverridesAllCount(): Promise<any> {
    gettingOverridesAllCount.value = true;
    return client
      .value!.apiGet(`./label/unitofmeasureoverride/$count`)
      .then(x => {
        overridesAllCount.value = x.data;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error getting overrides all count.',
            id: '02255593-917b-4bbe-bc2e-d41ed472d52f',
          },
          { root: true },
        );
        throw err;
      })
      .finally(() => {
        gettingOverridesAllCount.value = false;
      });
  }

  function deleteUnitOfMeasureOverride(payload: UnitOfMeasureOverride): Promise<any> {
    savingUnitOfMeasureOverride.value = true;
    return client
      .value!.apiDelete(`./label/unitofmeasureoverride/${payload.unitOfMeasureOverrideId}`, payload)
      .then(x => {
        savingUnitOfMeasureOverride.value = false;
      })
      .catch(err => {
        store.commit(
          'setError',
          {
            err: err,
            text: 'Error deleting unit override.',
            id: 'ec6f0ed0-3dcb-4fcb-9be9-3b4b08ebe6e6',
          },
          { root: true },
        );
        throw err;
      });
  }

  function removeQueuedItem(upc: string): void {
    if (selectedOverrides.value && selectedOverrides.value.length > 0) {
      const upcIxSelected = selectedOverrides.value.findIndex(x => x.upc === upc);
      if (upcIxSelected > -1) {
        selectedOverrides.value.splice(upcIxSelected, 1);
      }
    }
  }

  function setSelectedUnitOfMeasureOverride(payload: UnitOfMeasureOverride[] | null): void {
    if (payload && payload.length === 1) {
      selectedUnitOfMeasureOverride.value = payload[0];
    } else {
      selectedUnitOfMeasureOverride.value = null;
    }
  }

  return {
    client,
    items,
    nonActivatedToggle,
    search,
    selectedBarcodeSetups,
    queuedItems,
    unitOfMeasureOverrideTypeId,
    determiningUnitOfMeasureOverrideTypeId,
    selectedUnitOfMeasureOverride,
    gettingSelectedUnitOfMeasureOverride,
    savingUnitOfMeasureOverride,
    gettingOverrides,
    gettingOverridesCount,
    gettingOverridesAllCount,
    overrides,
    lastOverridePage,
    overridesCount,
    overridesAllCount,
    overridesOData,
    selectedOverrides,
    batchInfoItems,
    createdBatchInfo,
    currentTab,
    handHeldHeaderData,
    handHeldSearch,
    handheldCompleted,
    handHeldLineDataTable,
    init,
    determineUnitOfMeasureOverrideTypeId,
    getUnitOfMeasureOverride,
    createUnitOfMeasureOverride,
    editUnitOfMeasureOverride,
    createUnitOfMeasureOverridesFromHandheldBatch,
    getUnitOfMeasureOverrides,
    getUnitOfMeasureOverridesCount,
    getUnitOfMeasureOverridesAllCount,
    deleteUnitOfMeasureOverride,
    removeQueuedItem,
    setSelectedUnitOfMeasureOverride,
  };
});
