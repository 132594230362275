<template>
  <div>
    <v-lazy
      v-if="showPreview"
      v-model="isActive"
      :options="{
        threshold: 0.1,
      }"
      min-height="116px"
    >
      <label-preview
        :item.sync="ditem"
        :headers="headers"
        :url="url"
        :loading="loading"
        maxWidth="240px"
      />
    </v-lazy>
    <v-row justify="center" align="center" v-else>
      <v-btn @click.stop="ditem.overridePreviewMode = true" :disabled="disabled">
        <v-icon>image_search</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import LabelPreview from './LabelPreview.vue';
import Vue from 'vue';
import { mapState } from 'vuex';

const LabelPreviewLazy = Vue.component('LabelPreviewLazy', {
  components: {
    LabelPreview,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: null,
    },
    url: {
      type: String,
      required: true,
      default: null,
    },
    headers: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    overridePreviewMode: {
      type: Boolean,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ditem: this.item,
      isActive: this.value,
    };
  },
  computed: {
    ...mapState('app', ['username', 'showLabelPreviewMode']),
    showPreview() {
      const showPreview =
        (this.showLabelPreviewMode && this.overridePreviewMode !== false) ||
        (this.ditem && this.ditem.overridePreviewMode);

      // when showPreview is toggled, reset the loaded state
      if (showPreview === false) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.ditem.showPreviewDialogLoaded = false;
      }
      return showPreview;
    },
  },
  mounted() {
    Vue.set(this.ditem, 'showPreviewDialogLoaded', false);
    Vue.set(this.ditem, 'overridePreviewMode', false);
    Vue.set(this.ditem, 'reloadPreview', false);
    Vue.set(this.ditem, 'reload', () => {
      this.ditem.reloadPreview = true;
      // fake out the label preview to re-load the image.data.signLabelRetail;
      setTimeout(() => {
        this.ditem.reloadPreview = false;
      }, 0);
    });
  },
  watch: {
    isActive: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
    showLabelPreviewMode: {
      handler(val) {
        // they want to hide previews, reset the item.overridePreviewMode
        if (val !== true) {
          this.ditem.overridePreviewMode = false;
        }
      },
    },
  },
});

export default LabelPreviewLazy;
export { LabelPreviewLazy };
</script>
