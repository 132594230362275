<template>
  <v-row>
    <v-col>
      <v-row class="ml-3">
        <v-col>
          <v-checkbox
            v-model="includesHeaders"
            @change="textChange"
            label="Excel Data Includes Headers"
          />
        </v-col>
      </v-row>
      <v-row class="ml-3">
        <v-col cols="2">
          <v-btn @click="paste" :loading="pasteLoading" :disabled="pasteLoading">Paste</v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn @click="clear">Clear</v-btn>
        </v-col>
      </v-row>
      <v-row class="ml-3">
        <v-col>
          <v-card>
            <v-textarea
              v-model="excelText"
              label="Paste Excel text here"
              autocomplete="off"
              clearable
              clear-icon="mdi-close-circle"
              class="excel-text-area px-6 pt-6 pb-3"
              readonly
              @input="textChange"
              @click:clear="clear"
              @paste="paste"
            ></v-textarea>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-show="internalValue && internalValue.length > 0">
        <v-col>
          <v-data-table
            :items="internalValue"
            :headers="excelHeaders"
            class="elevation-1 scrollable"
          >
            <template v-slot:item="{ item, headers, index }">
              <tr>
                <td v-for="(header, hix) in headers" :key="hix">
                  <div v-if="header.value === 'editCol'">
                    <div v-if="editItem && editItem.equals(item) === true">
                      <v-icon @click="save">save</v-icon>
                      <v-icon @click="cancel">cancel</v-icon>
                    </div>
                    <div v-else>
                      <v-icon v-if="editable" @click="edit(item, index)">edit</v-icon>
                      <v-icon v-if="deleteable" @click="deleteItem(index)">delete</v-icon>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      header.image &&
                      header.image === true &&
                      item &&
                      item[header.value] &&
                      item[header.value].length > 0
                    "
                  >
                    <v-img :src="item[header.value]" max-width="150px" />
                  </div>
                  <div v-else>
                    <div v-if="editItem && editItem.equals(item) === true">
                      <data-date-picker :date.sync="editItem[header.value]" v-if="isDateField(hix)">
                      </data-date-picker>
                      <v-autocomplete
                        v-else-if="isLookupField(header.value)"
                        v-model="editItem[header.value]"
                        :items="lookupData(header.value)"
                        item-text="text"
                        item-value="dbValue"
                      />
                      <v-text-field
                        v-else
                        v-model="editItem[header.value]"
                        :hide-details="true"
                        dense
                        single-line
                      ></v-text-field>
                    </div>
                    <div v-else>
                      <div v-if="isDateField(hix)">
                        {{ item[header.value] | formatDate }}
                      </div>
                      <div v-else>{{ item[header.value] }}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-progress-linear v-if="errorsLoading" indeterminate color="green" />
          <v-expansion-panels v-model="errorsExpanded" :disabled="false">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span
                  :class="errors.length > 0 ? 'error-text' : null"
                  @click="errorsExpanded === 0 ? (errorsExpanded = 1) : (errorsExpanded = 0)"
                  >Errors ({{ errors.length }})</span
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <ul :class="errors.length > 0 ? 'mb-3' : ''">
                  <li v-for="(error, eix) in errors" :key="eix">
                    {{ error }}
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="text--black">
        <v-col>
          <v-card>
            <v-card-text>
              {{ errorHelperText }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator';
import Papa from 'papaparse';
import { unzip } from 'unzipit';
import { IExcelData } from '@/utils/IExcelData';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  components: {},
})
export default class PasteFromExcel extends Vue {
  private excelText: string | null = null;
  private excelLines: string[][] | null = null;
  private includesHeaders = true;
  private excelHeaders: any[] = [] as any[];
  private errorsExpanded = 0;
  private editItem: IExcelData | null = null;
  private editIndex = 0;
  private beforeEdit: IExcelData | null = null;
  private pasteLoading = false;
  private blobsToParse = [] as string[];

  @ModelSync('value', 'textChange', { type: Array })
  private internalValue!: Array<IExcelData>;

  @Prop()
  private typePrototype!: IExcelData;

  @Prop({ default: true })
  private editable!: boolean;
  @Prop({ default: true })
  private deleteable!: boolean;
  @Prop({ default: false })
  private errorsLoading!: boolean;

  @Prop({ required: false, default: 1 })
  private numberOfHeaders!: number;

  @Prop({ required: false, default: 0 })
  private mergedHeaderRows!: number;

  @Prop({ required: false, default: '' })
  private errorHelperText!: string;

  clear() {
    this.excelText = null;
    this.excelHeaders = [] as any[];
    this.textChange();
  }

  isDateField(index: number): boolean {
    return this.typePrototype.dateFieldIndexes.indexOf(index) > -1;
  }

  isLookupField(fieldName: string): boolean {
    return !!this.typePrototype.lookups[fieldName];
  }

  lookupData(fieldName: string) {
    return this.typePrototype?.lookups[fieldName] ? this.typePrototype.lookups[fieldName] : [];
  }

  get errors() {
    if (this.internalValue && this.internalValue.length > 0) {
      return this.internalValue.flatMap(x => x.errors);
    }
    return [];
  }

  edit(item: IExcelData, index: number) {
    this.editItem = item;
    this.editIndex = index;
  }

  deleteItem(index: number) {
    this.internalValue.splice(index, 1);
    if (this.excelText) {
      const splitText = this.excelText.split('\n');
      const indexToSplice = index + (this.includesHeaders ? this.numberOfHeaders : 0);
      splitText.splice(indexToSplice, 1);

      this.excelText = splitText.join('\n');
    }
  }

  save() {
    if (this.editItem && this.excelLines && this.excelLines[this.editIndex]) {
      const columns = [] as string[];
      for (const key of Object.keys(this.editItem)) {
        if (
          key !== 'internalErrors' &&
          key !== 'dateFieldIndexes' &&
          key !== '_errors' &&
          key !== '_dateFieldIndexes' &&
          ((this.editItem.excludedColumns &&
            this.editItem.excludedColumns.length > 0 &&
            this.editItem.excludedColumns.indexOf(key) === -1) ||
            (this.editItem?.excludedColumns?.length ?? 0) === 0)
        ) {
          columns.push(key);
        }
      }

      const tabDelimited = Papa.unparse([this.editItem], {
        delimiter: '\t',
        header: false,
        columns,
      });

      const textIndex = this.editIndex + (this.includesHeaders ? this.numberOfHeaders : 0);
      const parsed = Papa.parse(this.excelText, { delimiter: '\t', skipEmptyLines: true });
      parsed.data[textIndex] = tabDelimited.split('\t');
      this.excelLines[this.editIndex] = parsed.data[textIndex];

      this.excelText = '';
      parsed.data.forEach(val => {
        this.excelText += `${val.join('\t')}\n`;
      });
    }

    this.editItem = null;
  }

  cancel() {
    this.editItem = null;

    if (this.excelLines) {
      Vue.set(
        this.internalValue,
        this.editIndex,
        this.typePrototype.createType(this.editIndex, this.excelLines[this.editIndex]),
      );
    }
  }
  textChange() {
    // clear the array
    this.internalValue.splice(0, this.internalValue.length);
    // clear the headers
    this.excelHeaders.splice(0, this.internalValue.length);

    if (this.excelText && this.excelText.length > 0) {
      const parsed = Papa.parse(this.excelText, {
        delimiter: '\t',
        skipEmptyLines: true,
      });
      // remove first row of data because it will be header data
      if (this.includesHeaders === true) {
        parsed.data.splice(0, this.numberOfHeaders);
      }
      // check last row of data, is it just empty strings?
      const lastRow = parsed.data[parsed.data.length - 1];
      let lastRowAllEmpty = true;
      for (const d of lastRow) {
        if (d !== '') {
          lastRowAllEmpty = false;
          break;
        }
      }

      if (lastRowAllEmpty === true) {
        parsed.data.splice(parsed.data.length - 1, 1);
      }

      this.excelHeaders = this.typePrototype.headers;

      if (this.editable === true || this.deleteable === true) {
        this.excelHeaders.push({ value: 'editCol', text: 'Edit' });
      }
      this.excelLines = [];

      parsed.data.forEach((data, ix) => {
        if (this.excelLines) {
          this.excelLines.push(data);
        }

        const createdInstance = this.typePrototype.createType(ix, data);
        if (createdInstance) {
          this.internalValue.push(createdInstance);
        }
      });
    }
  }

  async sleep(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  async paste() {
    this.pasteLoading = true;

    const showError = () => {
      this.$emit('noClipboardData', {
        text: "No clipboard data found. Please try again. Sometimes the clipboard needs a few seconds to process the data before it's available.",
        id: '8c17a737-3c24-4f84-b61b-b2200872719b',
      });
    };

    try {
      const items = await navigator.clipboard.read();
      if (items.length === 1) {
        this.excelText = await navigator.clipboard.readText();
        if ((this.excelText?.length ?? 0) === 0) {
          showError();
          this.pasteLoading = false;
          return;
        }

        this.textChange();

        // now, find any images in the html
        const clipboardItem = items[0];
        if (clipboardItem.types.indexOf('text/html') > -1) {
          this.blobsToParse = [];
          const htmlBlob = await clipboardItem.getType('text/html');

          if (htmlBlob) {
            const reader = new FileReader();
            reader.onload = async event => {
              try {
                if (event?.target?.result) {
                  const htmlDoc = document.createElement('html');
                  htmlDoc.innerHTML = `<html><body>${event.target.result}</body></html>`;

                  const trs = htmlDoc.querySelectorAll('tbody > tr');
                  let trIx = 0;
                  for (const tr of trs) {
                    if (tr.children.length >= this.typePrototype.headers.length) {
                      for (const td of tr.children) {
                        // test to see if this is html or just text
                        if (td.children.length > 0) {
                          const blobRegex = /o:gfxdata="([^"]+)"/g;

                          let match = null as RegExpExecArray | null;
                          let blobData = '';
                          do {
                            match = blobRegex.exec(td.innerHTML);
                            // only capture the match if it's larger than the previous.
                            // excel will embed two blobs and only one of them actually has the image data
                            if (match && match.length === 2 && match[1].length > blobData.length) {
                              blobData = match[1];
                            }
                          } while (match);

                          if (blobData && blobData.length > 0) {
                            this.blobsToParse[trIx + this.mergedHeaderRows] = blobData;
                          }
                        }
                      }
                      trIx += 1;
                    }
                  }

                  await this.parseBlobsFromPaste();
                }

                this.pasteLoading = false;
              } catch (err) {
                console.error('err in reader', err);
              }
            };
            reader.readAsText(htmlBlob);
          } else {
            this.pasteLoading = false;
            showError();
          }
        } else {
          this.pasteLoading = false;
        }
      }
    } catch (err) {
      this.pasteLoading = false;
    }
  }

  async parseBlobsFromPaste() {
    if (this.blobsToParse) {
      for (const blobKey of Object.keys(this.blobsToParse)) {
        if (this.blobsToParse[blobKey]) {
          const fetchedBase64 = await fetch(
            `data:application/zip;base64,${this.blobsToParse[blobKey] as string}`,
          );
          const blobData = await fetchedBase64.blob();
          const { entries } = await unzip(blobData);

          const entryKeys = Object.keys(entries);
          for (const entryKey of entryKeys) {
            if (
              entryKey.startsWith('clipboard/media/') &&
              (entryKey.endsWith('png') || entryKey.endsWith('jpg') || entryKey.endsWith('jpeg'))
            ) {
              const fileNameParts = entryKey.split('/');
              const extensionParts = fileNameParts[fileNameParts.length - 1].split('.');
              if (extensionParts.length === 2) {
                const imageBlob = await entries[entryKey].blob(`image/${extensionParts[1]}`);
                this.$emit('imageBlob', {
                  blob: imageBlob,
                  fileName: `image${blobKey.padStart(3, '0')}.${extensionParts[1]}`,
                  rowIx: Number(blobKey) - (this.includesHeaders ? this.numberOfHeaders : 0),
                });
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.excel-text-area {
  font-family: monospace;
  line-height: 25px;
}
li {
  list-style: none;
}
.error-text {
  color: red;
  font-weight: bold;
}
</style>
