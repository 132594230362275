<template>
  <v-row justify="start" align="center">
    <v-snackbar v-model="snackbar" :color="color" :timeout="timeout">
      {{ text }}
      <v-icon class="float-right" @click="hide">cancel</v-icon>
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  props: {
    timeout: {
      type: Number,
      required: false,
      default: 5000,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  methods: {
    show() {
      this.snackbar = this.text && this.text.trim().length > 0;
    },
    hide() {
      this.snackbar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light .close-btn {
  background-color: rgba(255, 255, 255, 1) !important;
  color: rgba(0, 0, 0, 1) !important;
}

.theme--dark .close-btn {
  background-color: rgba(0, 0, 0, 0.87) !important;
  color: rgba(255, 255, 255, 1) !important;
}

.v-snack .v-icon:first-child {
  font-size: 1.5em !important;
  margin-top: -3px;
}
</style>

<style lang="scss">
.v-snack .v-snack__content {
  padding: 18px 5px 15px 20px !important;
}
</style>
