<template>
  <div>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-text-field
            label="Search by Item ID, SKU, or Brand"
            v-model="searchText"
            clearable
            @click:clear="clearClicked"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn @click="searchForText" :disabled="searchDisabled"><v-icon>search</v-icon></v-btn>
        </v-col>
        <v-col>
          <auto-ship-search-refinement
            v-on:search-clicked="refineSearchClicked"
            @snackbar-error="$emit('snackbar-error', $event)"
          />
        </v-col>
        <v-col>
          <v-btn type="submit" @click="downloadClicked" :loading="downloading">Download</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="exceptionData"
          :items-per-page="-1"
          :fixed-header="true"
          :loading="exceptionSearchInProgress"
          group-by="categoryHierarchy"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: '',
          }"
          hide-default-footer
          disable-sort
          :custom-sort="customGridSort"
        >
          <template v-slot:group.header="{ group, headers }">
            <td :colspan="headers.length">
              <span class="font-weight-bold">{{ group }}</span>
            </td>
          </template>
          <template v-slot:item.subscription="{ item }">
            {{ item.subscription ? 'Yes' : 'No' }}
          </template>
          <template v-slot:item.initialDiscount="{ item }">
            {{ item.initialDiscount ? 'Yes' : 'No' }}
          </template>
          <template v-slot:item.onGoingDiscount="{ item }">
            {{ item.onGoingDiscount ? 'Yes' : 'No' }}
          </template>
          <template v-slot:item.FoundOnImport="{ item }">
            {{ item.FoundOnImport ? 'Yes' : 'No' }}
          </template>
        </v-data-table>
      </v-row>
      <v-row> </v-row>
    </v-col>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <template v-slot:no-more> End of results </template>
      <template v-slot:no-results> End of results </template>
    </infinite-loading>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  SubscriptionExceptionSearchParams,
  SubscriptionExceptionSearchResult,
} from '@psp/pogona_ecom_client_js';
import AutoShipSearchRefinement from './AutoShipSearchRefinement.vue';
import * as ea from '@/store/modules/ecom/actions';
import * as es from '@/store/modules/ecom/state';
import * as em from '@/store/modules/ecom/mutations';
import InfiniteLoading from 'vue-infinite-loading';

const ecomModule = namespace('ecom');

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  components: {
    AutoShipSearchRefinement,
  },
})
export default class AutoShipSearchExceptions extends Vue {
  @ecomModule.Action(ea.searchSubscriptionExceptions)
  searchSubscriptionExceptions!: () => Promise<any>;
  @ecomModule.Action(ea.downloadSearchResults)
  downloadSearchResults!: () => Promise<any> | undefined;

  @ecomModule.Mutation(em.setExceptionFirstPage) setExceptionFirstPage!: () => void;
  @ecomModule.Mutation(em.incrementExceptionSearchPage) incrementExceptionSearchPage!: (
    payload: number,
  ) => void;
  @ecomModule.Mutation(em.setExceptionSearchText) setExceptionSearchText!: (
    payload: string,
  ) => void;
  @ecomModule.Mutation(em.clearExceptionData) clearExceptionData!: () => void;
  @ecomModule.Mutation(em.clearExceptionSearch) clearExceptionSearch!: () => void;

  @ecomModule.State(es.exceptionData) exceptionData!: SubscriptionExceptionSearchResult[];
  @ecomModule.State(es.exceptionSearch) exceptionSearch!: SubscriptionExceptionSearchParams;
  @ecomModule.State(es.exceptionSearchInProgress) exceptionSearchInProgress!: boolean;

  private searchText = '';
  public lastCount = 0;
  private infiniteId = +new Date();

  private headers: any = [
    { text: 'Item ID', value: 'ItemID' },
    { text: 'SKU', value: 'Sku' },
    { text: 'Brand', value: 'BrandName' },
    { text: 'Description', value: 'SkuName' },
    { text: 'Subscription', value: 'Subscription' },
    { text: 'Initial Discount', value: 'InitialDiscount' },
    { text: 'Ongoing Discount', value: 'OnGoingDiscount' },
    { text: 'Found on Import', value: 'FoundOnImport' },
  ];

  private downloading = false;

  public infiniteHandler($state: any) {
    this.search()
      .then(() => {
        if (
          this.exceptionData.length % this.exceptionSearch.PageSize === 0 &&
          this.exceptionData.length !== this.lastCount
        ) {
          $state.loaded();
          this.incrementExceptionSearchPage(1);
        } else {
          $state.complete();
        }
      })
      .catch(() => {
        $state.complete();
      });
  }

  private customGridSort(items: any[]): any[] {
    return items.sort(
      (a: SubscriptionExceptionSearchResult, b: SubscriptionExceptionSearchResult) => {
        var e = 1;
        var compare = this.compareString(a.Category1Name, b.Category1Name);
        if (compare !== 0) {
          e = compare;
        } else {
          compare = this.compareString(a.Category2Name, b.Category2Name);
          if (compare !== 0) {
            e = compare;
          } else {
            compare = this.compareString(a.Category3Name, b.Category3Name);
            if (compare !== 0) {
              e = compare;
            } else {
              compare = this.compareString(a.Category4Name, b.Category4Name);
              if (compare !== 0) {
                e = compare;
              } else {
                e = a.ItemID < b.ItemID ? -1 : a.ItemID === b.ItemID ? 0 : 1;
              }
            }
          }
        }

        return e;
      },
    );
  }

  private compareString(a: string | null, b: string | null): number {
    var compare = 1;
    if (a === b) {
      compare = 0;
    } else {
      if (b === null) {
        compare = -1;
      } else if (a !== null && a < b) {
        compare = -1;
      }
    }

    return compare;
  }

  private refineSearchClicked() {
    this.newSearch();
  }

  private downloadClicked() {
    this.downloading = true;
    this.downloadSearchResults()?.finally(() => (this.downloading = false));
  }

  get searchDisabled(): boolean {
    return this.exceptionSearchInProgress;
  }

  private searchForText() {
    this.clearExceptionSearch();
    this.setExceptionSearchText(this.searchText);
    this.newSearch();
  }

  public clearClicked() {
    this.clearExceptionSearch();
    this.setExceptionSearchText('');
    this.newSearch();
  }

  public reset() {
    this.searchText = '';
    this.newSearch();
  }

  public newSearch() {
    this.setExceptionFirstPage();
    this.clearExceptionData();
    this.infiniteId = this.infiniteId + 1;
  }

  private search() {
    this.lastCount = this.exceptionData.length;
    return this.searchSubscriptionExceptions();
  }
}
</script>
