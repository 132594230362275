<template>
  <core-view-template title="New Item Request">
    <v-container>
      <v-row v-if="saved"> Changes saved </v-row>
      <v-row v-if="!created">
        <edit-new-item-request
          :IsNew="false"
          @request-data="requestData"
          :Processing="processing"
          :Request="request"
        />
      </v-row>
    </v-container>
    <v-dialog v-model="processing" fullscreen persistent transition="none">
      <div class="text-center">
        <core-loading-modal :show="processing" :opacity="0.8" />
      </div>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </core-view-template>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditNewItemRequest from '@/components/vendorportal/EditNewItemRequest.vue';
import LoadingModal from '@/components/core/LoadingModal.vue';
import { getOk, getSome, isOk, isSome } from '@/utils/FableUtils.fs.js';
import { NewItemRequest } from '@/utils/VendorDomainTypes';

const vpModule = namespace('vendorportal');

@Component({
  components: { EditNewItemRequest, LoadingModal },
})
export default class EditNewItemRequestView extends Vue {
  @vpModule.Action('getNewItemRequestById') getNewItemRequestById!: (id: string) => Promise<any>;
  @vpModule.Action('updateNewItemRequest') updateNewItemRequest!: (data: any) => Promise<any>;
  @vpModule.Action('rejectRequest') rejectRequest!: (data: {
    AggregateId: string;
    ReasonText: string;
  }) => Promise<any>;

  @vpModule.Action('unlockRequest') unlockRequest!: (data: { AggregateId: string }) => Promise<any>;

  private request: NewItemRequest | null = null;
  private created = false;
  private processing = false;
  private saved = false;
  private showSnackbar = false;
  private snackbarMessage = '';
  private snackbarColor = '';

  private newItemClick() {
    this.created = false;
  }

  private showSnackbarMessage(message: string, color: string) {
    this.snackbarMessage = message;
    this.snackbarColor = color;
    this.showSnackbar = true;
  }

  private requestData(data: any) {
    this.processing = true;
    this.saved = false;
    this.updateNewItemRequest({
      Data: {
        AggregateId: data.id,
        Status: data.status,
        RequestID: 0,
        PrivateLabel: data.privateLabel,
        RequestType: data.requestType,
        FullUnitBarcode: data.fullUnitBarcode,
        CaseBarcode: data.caseBarcode,
        OtherBarcode: data.otherBarcode,
        ProductName: data.productName,
        RetailPackageSize: data.retailPackageSize,
        RetailPackageUOM: data.retailPackageUOM,
        ManufacturerAccountNumber: data.manufacturerAccountNumber,
        Brand: data.brand,
        VendorId: data.vendorId,
        ExternalItemNumber: data.externalItemNumber,
        MasterCase: data.masterCase,
        InnerCasePack: data.innerCasePack,
        LayerQuantity: data.layerQuantity,
        PalletQuantity: data.palletQuantity,
        DistributionNetwork: data.distributionNetwork,
        DirectToStore: data.directToStore,
        DirectToStoreOrVendorIntoPspdPurchaseCost: data.directToStoreOrVendorIntoPspdPurchaseCost,
        MfgSuggestedRetail: data.mfgSuggestedRetail,
        MapPrice: data.mapPrice,
        IMap: data.imap,
        AutoShipEligible: data.autoShipEligible,
        InitialDiscountFundingPercent: data.initialDiscountFundingPercent,
        OnGoingFundingDiscountPercent: data.onGoingFundingDiscountPercent,
        MaterialSafetyDataSheetNeeded: data.materialSafetyDataSheetNeeded,
        MinimumOrderQuantity: data.minimumOrderQuantity,
        UnitWeight: data.unitWeight,
        UnitWeightUOM: data.unitWeightUOM,
        UnitHeight: data.unitHeight,
        UnitWidth: data.unitWidth,
        UnitDepth: data.unitDepth,
        CaseHeight: data.caseHeight,
        CaseWidth: data.caseWidth,
        CaseDepth: data.caseDepth,
        ProductAttribute: data.productAttribute,
        Images: data.images,
        ProgramName: data.programName,
        HtsCode: data.htsCode,
        DutyRate: data.dutyRate,
        FobPort: data.fobPort,
        PluScan: data.pluScan,
        PluAssigned: data.pluAssigned,
        DaxItemNumber: data.daxItemNumber,
        SignsAndLabelsDescription1: data.signsAndLabelsDescription1,
        SignsAndLabelsDescription2: data.signsAndLabelsDescription2,
        VendorNumber: data.vendorNumber,
        ShipsToStores: data.shipsToStores,
        ShipUnitCostFromPspd: data.shipUnitCostFromPspd,
        RetailBasePrice: data.retailBasePrice,
        RetailHighPrice: data.retailHighPrice,
        Hierarchy: data.hierarchy,
        CategoryManagerName: data.categoryManagerName,
        PspdInventoryManagerName: data.pspdInventoryManagerName,
        Reason: data.reason,
        Distributors: data.dsdDistributor,
        Season: data.season,
        Seasonal: data.seasonal,
        Year: data.year,
        Category: data.category,
      },
      Request: this.request,
    })
      .then((result: any) => {
        this.saved = true;
        if (isOk(result)) {
          this.request = getOk(result);
          this.showSnackbarMessage('Request saved', 'success');
        } else {
          this.showSnackbarMessage('Request save failed', 'error');
        }
      })
      .catch((err: any) => this.showSnackbarMessage(`Request save failed error ${err}`, 'error'))
      .finally(() => {
        this.processing = false;
      });
  }

  private mounted() {
    if (this.$route.params.requestId) {
      this.processing = true;
      this.getNewItemRequestById(this.$route.params.requestId)
        .then((request: any) => {
          if (isSome(request)) {
            this.request = getSome(request);
          }
        })
        .finally(() => (this.processing = false));
    }
  }
}
</script>
