<template>
  <core-view-template :title="title">
    <div>
      <show-impersonation />
      <POSearch class="mb-4" @search="handleSearchEvent"></POSearch>
      <POHeaderGrid
        :poHeaderRows="vpFinanceStore.poSearchResults"
        :pos="[]"
        :loading="loading"
        :linkPO="true"
        :hideDefaultFooter="false"
        :page="page"
        @update-page="page = $event"
        @load-pos="headerGridEvent"
        ref="poHeaderGrid"
        :showDetailCols="false"
      ></POHeaderGrid>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import { useRoute } from 'vue-router/composables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import POSearch from '@/components/vendorportal/POSearch.vue';
import { POSearchParametersDto } from '@psp/pogona_vendor_api_lib';
import { toOption } from '@/utils/FableUtils.fs.js';
import POHeaderGrid from '@/components/vendorportal/POHeaderGrid.vue';

defineComponent({
  name: 'POSearchHome',
});

const vpFinanceStore = useVpFinanceStore();
const route = useRoute();
const loading = ref(false);
const poHeaderGrid = ref<InstanceType<typeof POHeaderGrid> | null>(null);
const page = ref(1);

const poSearchRoute = computed(() => {
  return route.params.poSearch === '-' ? '' : decodeURIComponent(route.params.poSearch);
});
const poNameRoute = computed(() => {
  return route.params.poName === '-' ? '' : decodeURIComponent(route.params.poName);
});
const siteIdRoute = computed(() => {
  return route.params.siteId === '-' ? '' : decodeURIComponent(route.params.siteId);
});
const routeFromDate = computed(() => {
  return route.params.fromDate === '-' ? null : decodeURIComponent(route.params.fromDate);
});
const routerToDate = computed(() => {
  return route.params.toDate === '-' ? null : decodeURIComponent(route.params.toDate);
});
const searchExists = computed(() => {
  return (
    poSearchRoute.value ||
    poNameRoute.value ||
    siteIdRoute.value ||
    routeFromDate.value ||
    routerToDate.value
  );
});
const title = computed(() => {
  return searchExists.value
    ? (poSearchRoute.value ? `PO #: ${poSearchRoute.value}` : '') +
        (poNameRoute.value ? ` Pet Partner Name: ${poNameRoute.value}` : '') +
        (siteIdRoute.value ? ` SiteID: ${siteIdRoute.value}` : '') +
        (routeFromDate.value ? ` From: ${routeFromDate.value}` : '') +
        (routerToDate.value ? ` To: ${routerToDate.value}` : '')
    : 'Purchase Order Search';
});

const poSearch = ref(poSearchRoute.value);
const poName = ref(poNameRoute.value);
const siteId = ref(siteIdRoute.value);
const fromDate = ref<string | null>(routeFromDate.value);
const toDate = ref<string | null>(routerToDate.value);

function handleSearchEvent() {
  loadPOs(0, 10);
}

function headerGridEvent(skip: number, take: number) {
  loadPOs(skip, take);
}

function loadPOs(skip: number, take: number) {
  if (poSearch.value !== 'undefined') {
    if (skip === 0) {
      page.value = 1;
    }
    loading.value = true;
    vpFinanceStore
      .searchPurchaseOrderHeaders(
        new POSearchParametersDto(
          poSearch.value,
          poName.value,
          siteId.value,
          fromDate.value ? toOption(new Date(fromDate.value)) : null,
          toDate.value ? toOption(new Date(toDate.value)) : null,
          skip,
          take,
        ),
      )
      .finally(() => {
        loading.value = false;
      });
  }
}
// Create options watch
watch(
  () => route.params,
  () => {
    poSearch.value = poSearchRoute.value;
    poName.value = poNameRoute.value;
    siteId.value = siteIdRoute.value;
    fromDate.value = routeFromDate.value;
    toDate.value = routerToDate.value;
    loadPOs(0, 10);
  },
);

onMounted(() => {
  vpFinanceStore.poSearchResults = [];
  loadPOs(0, 10);
});
</script>
<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
