<template>
  <core-signs-labels-view-template title="Store Set Designator Changes" :showStoreNumber="false">
    <core-info-blurb title="What are store set designator changes?">
      The store set designator change page allows you view changes made to a store or store's store
      set designators. The table below lists the most recent changes. You can also optionally filter
      the data.
    </core-info-blurb>
    <core-view-section title="Designator Changes">
      <v-row>
        <v-col cols="12" xl="4" lg="4" md="4">
          <data-date-picker
            v-model="changesBegin"
            label="Start"
            @change="storeSetAssignChanges.get(storeSetAssignChangesAdditionalFilter)"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4">
          <data-date-picker
            v-model="changesEnd"
            label="End"
            @change="storeSetAssignChanges.get(storeSetAssignChangesAdditionalFilter)"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4">
          <v-autocomplete
            v-model="selectedChangeTypes"
            :items="changeTypes"
            :clearable="false"
            label="Select one or more change types"
            @blur="storeSetAssignChanges.get(storeSetAssignChangesAdditionalFilter)"
            :multiple="true"
            item-value="storeSetAssignChangeTypeId"
            item-text="description"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6">
          <data-stores
            v-model="selectedStores"
            :multiple="true"
            @change="storeSetAssignChanges.get(storeSetAssignChangesAdditionalFilter)"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6">
          <v-autocomplete
            v-model="selectedSetIds"
            :items="setIds"
            :clearable="true"
            label="Select one or more sets"
            @change="storeSetAssignChanges.get(storeSetAssignChangesAdditionalFilter)"
            :multiple="true"
            item-value="setId"
            item-text="setId"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="storeSetAssignChanges.selected"
            :headers="storeSetAssignChanges.headers"
            :items="storeSetAssignChanges.items"
            :loading="storeSetAssignChanges.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="storeSetAssignChanges.options"
            item-key="storeSetAssignChangeId"
            class="elevation-1 scrollable"
            @update:sort-by="storeSetAssignChanges.get(storeSetAssignChangesAdditionalFilter)"
          >
            <template v-slot:body.append>
              <infinite-loader
                :pogonaTable="storeSetAssignChanges"
                :additionalFilter="storeSetAssignChangesAdditionalFilter"
                :colspan="storeSetAssignChanges.headers.length"
              ></infinite-loader>
            </template>
            <template v-slot:item.timeInserted="{ value }">
              {{ value | formatUtcDateTime }}
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="storeSetAssignChanges"></infinite-paganation>
        </v-col>
      </v-row>
    </core-view-section>
  </core-signs-labels-view-template>
</template>

<script>
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';
import moment from 'moment';
import PogonaDataTable from '@/utils/PogonaDataTable';

export default {
  data() {
    return {
      storeSetAssignChanges: null,
      changesBegin: moment(new Date()).add(-7, 'days').format('YYYY-MM-DD'),
      changesEnd: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
      selectedStores: [],
      setIds: [],
      selectedSetIds: [],
      changeTypes: [],
      selectedChangeTypes: [],
    };
  },
  async created() {
    await Promise.all([
      this.initStoreSetAssignChanges(),
      this.getSetIds(),
      this.getSetChangeTypes(),
    ]);

    // default to all change types
    this.selectedChangeTypes = this.changeTypes.map(x => x.storeSetAssignChangeTypeId);

    // get the grid data
    await this.storeSetAssignChanges.get(this.storeSetAssignChangesAdditionalFilter);
  },
  computed: {
    storeSetAssignChangesAdditionalFilter() {
      let filter = f('and')
        .ge('timeInserted', new Date(this.changesBegin))
        .le('timeInserted', new Date(this.changesEnd));

      if (this.selectedStores && this.selectedStores.length > 0) {
        filter = filter.and(f('and').in('storeNumber', this.selectedStores));
      }

      if (this.selectedSetIds && this.selectedSetIds.length > 0) {
        filter = filter.and(f('and').in('setId', this.selectedSetIds));
      }

      if (this.selectedChangeTypes && this.selectedChangeTypes.length > 0) {
        filter = filter.and(f('and').in('storeSetAssignChangeTypeId', this.selectedChangeTypes));
      } else {
        filter = filter.and(f('and').eq('storeSetAssignChangeTypeId', -1));
      }

      return filter;
    },
  },
  methods: {
    async getSetChangeTypes() {
      await this.fillSelectionData(
        'storesetassignchangetype',
        this.changeTypes,
        'storeSetAssignChangeTypeId',
      );
    },
    async getSetIds() {
      await this.fillSelectionData('storeset', this.setIds, 'setId');
    },
    async fillSelectionData(dtoType, items, orderby) {
      let objTemp = null;

      const pageSize = 200;
      let page = 0;

      do {
        const odataOptions = {
          top: pageSize,
          skip: pageSize * page,
          orderby,
        };
        const builtOdataQuery = buildQuery(odataOptions);

        // eslint-disable-next-line no-await-in-loop
        objTemp = (await this.$authApi.http.get(`label/${dtoType}${builtOdataQuery}`)).data;

        objTemp.forEach(i => {
          items.push(i);
        });

        page += 1;
      } while (objTemp === null || objTemp.length > 0);
    },
    async initStoreSetAssignChanges() {
      this.storeSetAssignChanges = new PogonaDataTable({
        headers: [
          {
            text: 'Change Type',
            value: 'changeTypeDescription',
          },
          {
            text: 'Store Number',
            value: 'storeNumber',
          },
          {
            text: 'POG ID',
            value: 'setId',
          },
          {
            text: 'Print Sequence',
            value: 'printSequence',
          },

          {
            text: 'Time of Change',
            value: 'timeInserted',
          },
        ],
        baseUrl: 'label/storesetassignchange/extended',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['storeSetAssignChangeId desc'] },
        onPageFilter: () => this.storeSetAssignChangesAdditionalFilter,
        alwaysGetData: false,
        isInfinite: true,
      });
      this.storeSetAssignChanges.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting store set assign changes',
          err,
          id: '0e0f0b88-7e69-4950-bb02-51a2c9d8749e',
        });
      });
    },
  },
};
</script>
