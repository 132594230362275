<template>
  <core-signs-labels-view-template title="Batch Check">
    <v-col cols="12">
      <core-view-section title="Batches">
        <v-row>
          <v-col cols="6">
            <v-dialog v-model="showRetailStartDateCalendar" max-width="290">
              <template v-slot:activator="{ on }">
                <v-text-field
                  id="retailStartDate"
                  readonly
                  prepend-icon="event"
                  :value="retailStartDateFormated"
                  label="Retail Start Date"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="retailStartDateChanged"
                v-model="retailStartDate"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="completedBatchInfos"
              label="Completed"
              @change="getBatchInfo"
              prepend-icon="playlist_add_check"
            />
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="showZeroApproved"
              label="Include Zero Approved"
              @change="getBatchInfo"
              prepend-icon="playlist_add_check"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              prepend-icon="search"
              v-model="search"
              label="Search (UPC, item, desc)"
              @change="getBatchInfo"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox
              prepend-icon="playlist_add_check"
              v-model="selectedBatchInfoTypes"
              :items="batchInfoTypes"
              @blur="getBatchInfo"
              label="Batch Types"
              multiple
              chips
              autocomplete="off"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" :input-value="selected" label>
                  <span class="pr-2">{{ item }}</span>
                  <v-icon small @click="parent.selectItem(item)">close</v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              v-if="batchInfo"
              v-model="batchInfo.selected"
              :headers="batchInfo.headers"
              :items="batchInfo.items"
              :single-select="true"
              :loading="batchInfoLoading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="batchInfo.options"
              @click:row="selectBatchInfoRow"
              @item-selected="selectedBatchInfoRow"
              @update:sort-by="batchInfo.get(batchInfoAdditionalFilter)"
              @dataBound="batchInfoDataBound"
              item-key="batchInfoId"
              show-select
              class="elevation-1 scrollable"
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="batchInfo"
                  :additionalFilter="batchInfoAdditionalFilter"
                  :colspan="batchInfo.headers.length"
                ></infinite-loader>
              </template>

              <template v-slot:item.beginDate="{ item }">{{
                item.beginDate | formatDate
              }}</template>
              <template v-slot:item.endDate="{ item }">{{ item.endDate | formatDate }}</template>
              <template v-slot:item.batchCreated="{ item }">{{
                item.batchCreated | formatDate
              }}</template>
              <template v-slot:item.completed="{ item }">{{
                item.completed ? 'Yes' : 'No'
              }}</template>
              <template v-slot:item.setToCompleted="{ item }">
                <v-checkbox
                  v-model="item.completed"
                  @click.stop="
                    editBatchCompletedItem = item;
                    showBatchCompleteDialog = true;
                  "
                ></v-checkbox>
              </template>
            </v-data-table>
            <infinite-paganation :pogonaTable="batchInfo"></infinite-paganation>
          </v-col>
        </v-row>
      </core-view-section>
      <v-row>
        <v-divider class="ma-8"></v-divider>
      </v-row>
      <v-row>
        <v-col>
          <core-view-section v-if="selectedBatchInfo !== null" title="Queue Items">
            <batch-check-items
              v-model="batchInfoItems"
              :batchInfo="selectedBatchInfo"
              @dataBound="batchInfoItemsDataBound"
              @error="$emit('snackbar-error', $event)"
              :footerBatchAndId="`Batch ${selectedBatchInfo.batchInfoId}`"
              :additionalCompleteUpdate="additionalCompleteUpdate"
              :overrideRetailStartDate="true"
              :allowRemove="false"
              :includeSetDelimters="true"
              :showCheckForDiscounts="showCheckForDiscounts"
              :showApprovedFilter="true"
              originatingPage="Batch Check"
            ></batch-check-items>
          </core-view-section>
        </v-col>
      </v-row>
      <v-row v-if="editBatchCompletedItem">
        <v-dialog v-model="showBatchCompleteDialog" max-width="700">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Set to {{ editBatchCompletedItem.completed ? '' : 'not' }} completed</span
              >
            </v-card-title>
            <v-card-text>
              Are you sure you want to set batch
              <strong>
                '{{ editBatchCompletedItem.batchDescription }}
                {{ editBatchCompletedItem.beginDate | formatDate }}'
              </strong>
              to {{ editBatchCompletedItem.completed ? '' : 'not' }} completed?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="setBatchCompletedStatus(editBatchCompletedItem)"
                :disabled="setBatchCompletedLoading"
                :loading="setBatchCompletedLoading"
                >Save</v-btn
              >
              <v-btn
                @click="
                  showBatchCompleteDialog = false;
                  editBatchCompletedItem.completed = !editBatchCompletedItem.completed;
                "
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <data-tour
          tourName="batchCheckTour"
          :steps="tourSteps"
          maxWidth="600px"
          :show="true"
        ></data-tour>
      </v-row>
    </v-col>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import f from 'odata-filter-builder';
import PogonaDataTable from '@/utils/PogonaDataTable';
import BatchCheckItems from './BatchCheckItems.vue';

const batchInfoTypeMappings = {
  'Regular (REG)': ['REG'],
  'Sale w/Card (SWC)': ['SWC'],
  'Sale No/Card (SNC)': ['SNC'],
  'Misc (MSC)': ['MSC'],
  'Teardown (TD1 & TD2)': ['TD1', 'TD2'],
};

export default {
  components: {
    BatchCheckItems,
  },
  data() {
    return {
      batchInfo: this.$store.state.batchcheck.batchInfo || null,
      showRetailStartDateCalendar: false,
      batchInfoTypes: Object.keys(batchInfoTypeMappings),
      batchInfoTypeMappings: batchInfoTypeMappings,
      showBatchCompleteDialog: false,
      setBatchCompletedLoading: false,
      editBatchCompletedItem: null,
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Batch Check Video Tour',
          },
          video: 'batch_check',
          placement: 'bottom',
        },
      ],
    };
  },
  created() {
    this.initBatchInfoTable(true);
  },
  computed: {
    ...mapState('app', ['storeNumber', 'username']),
    additionalCompleteUpdate() {
      // we just do this so the render progress component will clear the store for us
      return {
        type: 'batchCheck',
      };
    },
    batchInfoLoading() {
      return this.batchInfo.loading;
    },
    showCheckForDiscounts() {
      return this.selectedBatchInfo && this.selectedBatchInfo.retailType === 'REG';
    },
    batchInfoItems: {
      get() {
        return this.$store.state.batchcheck.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit('batchcheck/batchInfoItems', val);
      },
    },
    selectedBatchInfoTypes: {
      get() {
        return this.$store.state.batchcheck.selectedBatchInfoTypes &&
          this.$store.state.batchcheck.selectedBatchInfoTypes.length > 0
          ? this.$store.state.batchcheck.selectedBatchInfoTypes
          : Object.keys(this.batchInfoTypeMappings);
      },
      set(val) {
        this.$store.commit('batchcheck/selectedBatchInfoTypes', val);
      },
    },
    retailStartDate: {
      get() {
        return (
          this.$store.state.batchcheck.retailStartDate ||
          moment().startOf('day').subtract(7, 'day').format('YYYY-MM-DD')
        );
      },
      set(val) {
        this.$store.commit('batchcheck/retailStartDate', val);
      },
    },
    completedBatchInfos: {
      get() {
        return this.$store.state.batchcheck.completedBatchInfos;
      },
      set(val) {
        if (this.batchInfo) {
          this.batchInfo.onPageFilter = () => this.batchInfoAdditionalFilter;
        }
        this.$store.commit('batchcheck/completedBatchInfos', val);
      },
    },
    selectedBatchInfo: {
      get() {
        return this.$store.state.batchcheck.selectedBatchInfo;
      },
      set(val) {
        this.$store.commit('batchcheck/selectedBatchInfo', val);
      },
    },
    search: {
      get() {
        return this.$store.state.batchcheck.search;
      },
      set(val) {
        this.$store.commit('batchcheck/search', val);
      },
    },
    showZeroApproved: {
      get() {
        return this.$store.state.batchcheck.showZeroApproved;
      },
      set(val) {
        this.$store.commit('batchcheck/showZeroApproved', val);
      },
    },
    retailStartDateFormated() {
      return moment(this.retailStartDate).format('MM/DD/YYYY');
    },
    selectedBatchInfoMappings() {
      const builtBatchInfoMappings = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const selectedBatchInfoType of this.selectedBatchInfoTypes) {
        builtBatchInfoMappings.push(...this.batchInfoTypeMappings[selectedBatchInfoType]);
      }

      return builtBatchInfoMappings;
    },
    batchInfoAdditionalFilter() {
      let filter = f('and');
      // Format the batch types as an OData contains list
      if (this.selectedBatchInfoMappings && this.selectedBatchInfoMappings.length > 0) {
        filter = filter.in('retailType', this.selectedBatchInfoMappings);
      }

      filter = filter.ge(
        'beginDate',
        moment(this.retailStartDate).utc().startOf('day').toISOString(),
        false,
      );

      filter = filter.eq('completed', this.completedBatchInfos);

      if (this.showZeroApproved === false) {
        filter = filter.gt('itemApprovedCount', 0);
      }

      return filter;
    },
  },
  methods: {
    setupEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting batch info',
          err,
          id: 'f06dc14d-d486-44d0-b04a-8df5278b93e9',
        });
      };

      this.batchInfo.removeListener('error', error);
      this.batchInfo.on('error', error);
    },
    retailStartDateChanged() {
      this.showRetailStartDateCalendar = !this.showRetailStartDateCalendar;
      this.batchInfo.selected = [];
      this.getBatchInfo();
    },
    getBatchInfo() {
      this.batchInfo.get(this.batchInfoAdditionalFilter);
    },
    async selectBatchInfoRow(event) {
      // if the batch is already selected, and they click it again, the loading bar will become stuck
      // so we deselect it
      if (
        this.batchInfo.selected.length === 0 ||
        this.batchInfo.selected.findIndex(x => x.batchInfoId === event.batchInfoId) === -1
      ) {
        this.batchInfo.selected = [event];

        // skip this event if the batch info items grid is already shown
        if (!this.selectedBatchInfo || event.batchInfoId !== this.selectedBatchInfo.batchInfoId) {
          await this.selectedBatchInfoRow({ item: event, value: true });
        }
      } else {
        this.batchInfo.selected = [];
        this.batchInfo.loading = false;
        this.selectedBatchInfo = null;
        await this.selectedBatchInfoRow({ item: event, value: false });
      }
    },
    async resetTemplatesForBatch(batchInfoId) {
      await this.$authApi.http.post(`label/batchinfo/resettemplates/${batchInfoId}`);
    },
    async selectedBatchInfoRow(event) {
      // reset the templates back to the original
      if (event.item && event.item.batchInfoId) {
        await this.resetTemplatesForBatch(event.item.batchInfoId);
      }

      this.batchInfo.loading = event.value;
      this.selectedBatchInfo = event.item;
      // reset batch info items
      this.batchInfoItems = {};

      // if deselect, then wipe out the items grid
      if (event.value === false) {
        this.selectedBatchInfo = null;
      }
    },
    batchInfoDataBound() {
      this.batchInfo.loading = false;
    },
    batchInfoItemsDataBound() {
      this.batchInfo.loading = false;
    },
    async setBatchCompletedStatus(editBatchCompletedItem) {
      try {
        this.setBatchCompletedLoading = true;

        const completedPayload = {
          completed: editBatchCompletedItem.completed,
          completedOn: null,
          completedBy: null,
          completedOrigin: null,
        };
        if (editBatchCompletedItem.completed === true) {
          completedPayload.completedOn = moment.utc();
          completedPayload.completedBy = this.username;
          completedPayload.completedOrigin = 'batch_grid';
        }

        await this.$authApi.http.patch(
          `label/batchinfo/${editBatchCompletedItem.batchInfoId}`,
          completedPayload,
        );
        this.setBatchCompletedLoading = false;
        this.showBatchCompleteDialog = false;
        this.initBatchInfoTable();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error setting completed status.',
          err,
          id: 'd8c9a7c9-df82-4fb4-92f2-113436f6eb19',
        });
        this.setBatchCompletedLoading = false;
      }
    },
    batchInfoQueryStringParams() {
      if (this.search && this.search.trim().length > 0) {
        return {
          search: this.search,
        };
      }
      return null;
    },
    initBatchInfoTable(onMount) {
      if (!onMount || !this.batchInfo || !this.batchInfo.inited) {
        this.selectedBatchInfo = null;
        this.batchInfo = new PogonaDataTable({
          headers: [
            {
              text: 'Type',
              value: 'retailType',
            },
            {
              text: 'Begin Date',
              value: 'beginDate',
            },
            {
              text: 'End Date',
              value: 'endDate',
            },
            {
              text: 'Create Date',
              value: 'batchCreated',
            },
            {
              text: 'Batch Description',
              value: 'batchDescription',
            },
            {
              text: 'Total Count',
              value: 'itemCount',
            },
            {
              text: 'Approved Count',
              value: 'itemApprovedCount',
            },
            {
              text: 'Completed',
              value: 'completed',
            },
            {
              text: 'Set Completed Status',
              value: 'setToCompleted',
              sortable: false,
            },
          ],
          baseUrl: 'label/batchinfo/distinct',
          defaultFilter: f('and').gt('itemCount', 0, false).eq('storeNumber', this.storeNumber),
          httpClient: this.$authApi.http,
          options: { itemsPerPage: 50, sortBy: ['beginDate', 'retailType'] },
          onPageFilter: () => this.batchInfoAdditionalFilter,
          queryStringParams: this.batchInfoQueryStringParams,
          isInfinite: true,
        });
        this.setupEvents();
        this.getBatchInfo();
      } else {
        this.setupEvents();
        this.batchInfo.loading = false;
      }
    },
  },
  watch: {
    storeNumber: {
      handler() {
        this.search = null;
        this.initBatchInfoTable();
      },
    },
    batchInfo: {
      handler(val) {
        this.$store.commit('batchcheck/batchInfo', val);
      },
      deep: true,
    },
    search: {
      handler() {
        this.batchInfo.queryStringParams = this.batchInfoQueryStringParams();
      },
      deep: true,
    },
  },
};
</script>
