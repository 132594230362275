<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-checkbox v-model="checkValue" @change="handleInput" :prepend-icon="prependIcon">
        <template v-slot:label>
          <label v-on="on">Force New Render</label>
        </template>
      </v-checkbox>
    </template>
    <span class="white--text strong">NOTE: not normally needed.</span>
    <br />
    <span class="white--text">
      Forces the label sheet to be re-rendered. Only needed if there was a rendering issue on the
      original label sheet.
    </span>
  </v-tooltip>
</template>
<script>
import Vue from 'vue';

const ForceRenderCheckbox = Vue.component('ForceRenderCheckbox', {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      checkValue: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit('input', this.checkValue);
    },
  },
});

export default ForceRenderCheckbox;
export { ForceRenderCheckbox };
</script>
