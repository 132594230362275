const initState = (): unknown => {
  return {
    batchInfoItems: {},
    handHeldHeaderData: null,
    completed: false,
    createdBatchInfo: null,
    handHeldSearch: null,
  };
};

export { initState };

export default {
  batchInfoItems: {},
  handHeldHeaderData: null,
  completed: false,
  createdBatchInfo: null,
  handHeldSearch: null,
};
