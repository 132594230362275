<template>
  <v-dialog v-model="newUserStoreMappingDialog" max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">{{ newText }}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ newText }}</span>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-row>
            <v-col cols="10">
              <v-form @submit.stop.prevent="appendStoreToNewMappingGrid">
                <v-text-field
                  v-model="newStoreToMap"
                  label="Store Number"
                  :error-messages="storeNumberErrors"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="2">
              <v-btn @click="appendStoreToNewMappingGrid">Add Store</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="[
                  {
                    text: 'Store Number',
                    value: 'storeNumber',
                  },
                  {
                    text: 'Remove',
                    value: 'remove',
                  },
                ]"
                :items="newStoresToMap"
                :disable-pagination="true"
                :fixed-header="true"
                item-key="storeNumber"
                class="elevation-1 scrollable"
                :show-select="false"
              >
                <template v-slot:item.remove="{ item }">
                  <v-icon @click="removeNewStoreFromGrid(item)">delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="storeMappingsSaved" :disabled="saveDisabled" :loading="loading">Save</v-btn>
        <v-btn @click="newUserStoreMappingDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

const StoreMappings = Vue.component('StoreMappings', {
  props: {
    value: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    mappedStores: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    newText: {
      type: String,
      required: false,
      default: 'New Store Mappings',
    },
    additionalValidation: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      newUserStoreMappingDialog: this.dialog,
      newStoresToMap: this.value || [],
      newStoreToMap: null,
      storeNumberErrors: [],
    };
  },
  computed: {
    saveDisabled() {
      return !this.newStoresToMap || this.newStoresToMap.length === 0 || this.loading;
    },
  },
  methods: {
    storeMappingsSaved() {
      this.$emit('saved');
    },
    async appendStoreToNewMappingGrid() {
      this.storeNumberErrors = [];
      if (this.newStoreToMap) {
        // clean store string, make sure it's at least 4 chars, pad with zeros
        let cleanedStore = this.newStoreToMap.trim();
        if (cleanedStore.length < 4) {
          cleanedStore = cleanedStore.padStart(4, '0');
        }
        // scan items if already exists, don't add
        let exists = false;
        this.newStoresToMap.forEach(x => {
          if (x.storeNumber === cleanedStore) {
            exists = true;
          }
        });

        this.mappedStores.forEach(x => {
          if (x === cleanedStore) {
            exists = true;
          }
        });

        if (this.additionalValidation && typeof this.additionalValidation === 'function') {
          const additionalValidationResult = await this.additionalValidation(cleanedStore);
          exists = additionalValidationResult.exists;
        }

        if (exists === false) {
          // add to grid
          this.newStoresToMap.push({ storeNumber: cleanedStore });
        } else {
          this.storeNumberErrors.push(`Store number ${cleanedStore} already mapped`);
        }
        // clear text box
        this.newStoreToMap = null;
      }
    },
    removeNewStoreFromGrid(item) {
      if (this.newStoresToMap) {
        // eslint-disable-next-line no-restricted-syntax
        for (const s in this.newStoresToMap) {
          if (item.storeNumber === this.newStoresToMap[s].storeNumber) {
            // splice out store number
            this.newStoresToMap.splice(s, 1);
          }
        }
      }
    },
  },
  watch: {
    newStoresToMap: {
      handler: function (val) {
        this.$emit('input', val);
      },
    },
    value: {
      handler: function (val) {
        this.newStoresToMap = val;
      },
    },
    dialog: {
      handler: function (val) {
        this.newUserStoreMappingDialog = val;
      },
    },
    newUserStoreMappingDialog: {
      handler: function (val) {
        this.$emit('update:dialog', val);
      },
    },
  },
});

export default StoreMappings;
export { StoreMappings };
</script>
