/* eslint-disable @typescript-eslint/no-explicit-any */
import { IExcelData, Lookup } from '@/utils/IExcelData';
import { Vendor } from '@/utils/VendorDomainTypes';
import NumberHelpers from '../../../utils/NumberHelpers';

export default class DsdExcelData implements IExcelData {
  constructor(
    public vendors: Vendor[],
    public daxItemNumber = '',
    public upc = '',
    public vendor = '',
    public externalItemId = '',
    public storeCost = 0,
    public shipsAsCaseOrUnit = '',
  ) {}

  private _errors = [] as string[];
  public get errors(): Array<string> {
    return this._errors;
  }
  public pushError(val: string): void {
    this._errors.push(val);
  }

  public clearErrors(): void {
    this._errors = [];
  }

  private _dateFieldIndexes = [] as number[];
  public get dateFieldIndexes(): Array<number> {
    return this._dateFieldIndexes;
  }

  createType(rowIx: number, parameters: Array<string>): IExcelData | null {
    if (
      !parameters ||
      parameters.length === 0 ||
      parameters.length < 2 ||
      !parameters[2] ||
      parameters[2].length === 0
    ) {
      return null;
    }

    let storeCost = parameters[4];
    if (storeCost && storeCost.length > 0 && storeCost.startsWith('$')) {
      storeCost = storeCost.substring(1);
    }

    const newInstance = new DsdExcelData(
      this.vendors,
      parameters[0],
      parameters[1],
      parameters[2],
      parameters[3],
      NumberHelpers.convertParameterToNumber(storeCost),
      parameters[5],
    );
    return newInstance;
  }

  equals(other: IExcelData): boolean {
    if (!other) {
      return false;
    }
    const otherPetPartner = other as DsdExcelData;
    return otherPetPartner.upc === this.upc && otherPetPartner.vendor === this.vendor;
  }

  get lookups(): Record<string, Lookup[]> {
    return {
      vendorId: this.vendors.map(
        x => new Lookup(`${x.Number} ${x.Name}`, `${x.Number} ${x.Name}`, x.Number),
      ),
    };
  }

  public getLookUpDbValue(fieldName: string, value: string): string | number | null {
    try {
      if (this.lookups[fieldName] && value && value.length > 0) {
        const dbValueIx = this.lookups[fieldName].findIndex(x => x.value === value);
        if (dbValueIx > -1) {
          return this.lookups[fieldName][dbValueIx].dbValue;
        }
      }
    } catch (e) {
      console.error('getLookUpDbValue error', e);
    }
    return null;
  }

  get excludedColumns(): string[] {
    return ['vendors'];
  }

  get headers(): any[] {
    return [
      { value: 'daxItemNumber', text: 'DAX ITEM #' },
      { value: 'upc', text: 'ITEM UPC' },
      { value: 'vendor', text: 'DISTRIBUTOR ID' },
      { value: 'externalItemId', text: 'EXTERNAL ITEM ID (ITEM #)' },
      { value: 'storeCost', text: 'STORE COST' },
      { value: 'shipsAsCaseOrUnit', text: 'SHIPS TO STORE AS CASE OR UNIT' },
    ];
  }
}
