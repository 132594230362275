<template>
  <v-select
    v-model="localSelected"
    :items="transStatuses"
    item-text="Name"
    item-value="Id"
    label="Transaction Status"
  />
</template>
<script setup lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { allTransStatus, transStatuses } from '@/utils/FableUtils.fs.js';
defineComponent({
  name: 'TransStatusSelect',
});
// Created selected property to store the selected value
const props = withDefaults(
  defineProps<{
    selected: number;
  }>(),
  {
    selected: allTransStatus,
  },
);
const emit = defineEmits(['update:selected']);
const localSelected = ref(props.selected);
watch(localSelected, val => {
  // Emit the selected value to the parent component
  emit('update:selected', val);
});
watch(
  () => props.selected,
  val => {
    localSelected.value = val;
  },
);
onMounted(() => {
  emit('update:selected', localSelected.value);
});
</script>
