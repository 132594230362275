import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  labelItemsAll: set('labelItemsAll'),
  selectedItemStatus: set('selectedItemStatus'),
  labelItemsAllSearch: set('labelItemsAllSearch'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
};
