<template>
  <div>
    <v-row v-for="service in services" :key="service.ServiceId">
      <v-col>
        <v-row>
          <v-checkbox
            v-model="service.Selected"
            :label="service.Name"
            @change="serviceSelectedChange(service, $event)"
          ></v-checkbox>
        </v-row>
        <v-row v-if="service.ServiceId === groomingServiceType">
          <v-col cols="3" class="input-label">
            <label :class="!service.Selected ? 'grey--text' : ''">Number of Tables</label>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="value.GroomingStoreService.NumberOfTables"
              autocomplete="off"
              :error-messages="errorsMessages.GroomingNumberOfTables"
              :disabled="!service.Selected"
              @blur="validate('GroomingNumberOfTables')"
              @change="validate('GroomingNumberOfTables')"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="input-label">
            <label :class="!service.Selected ? 'grey--text' : ''">Number of Tubs</label>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="value.GroomingStoreService.NumberOfTubs"
              autocomplete="off"
              :error-messages="errorsMessages.GroomingNumberOfTubs"
              :disabled="!service.Selected"
              @blur="validate('GroomingNumberOfTubs')"
              @change="validate('GroomingNumberOfTubs')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-else-if="service.ServiceId === dogWashServiceType">
          <v-row>
            <v-col cols="4" class="input-label">
              <label :class="!service.Selected ? 'grey--text' : ''">Number of Stations</label>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="value.DogWashService.NumberOfStations"
                autocomplete="off"
                :error-messages="errorsMessages.DogWashNumberOfStations"
                :disabled="!service.Selected"
                @blur="validate('DogWashNumberOfStations')"
                @change="validate('DogWashNumberOfStations')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="input-label">
              <label :class="!service.Selected ? 'grey--text' : ''">Wash Station Type</label>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="value.DogWashService.DogWashTubTypeId"
                :items="dogWashTubTypes"
                :loading="dogWashTubTypesGetInProgress"
                :disabled="dogWashTubTypesGetInProgress || !service.Selected"
                clearable
                item-text="Name"
                item-value="DogWashTubTypeId"
                autocomplete="off"
              ></v-select> </v-col
          ></v-row>
        </v-row>
        <v-row v-else-if="service.ServiceId === curbsidePickupServiceType">
          <v-col cols="3" class="input-label">
            <label :class="!service.Selected ? 'grey--text' : ''">Start Date</label>
          </v-col>
          <v-col cols="8">
            <data-date-picker
              v-model="value.CurbsidePickupStoreService.StartDate"
              :clearable="true"
              :disabled="!service.Selected"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="service.ServiceId === deliveryServiceType">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="value.DeliveryStoreService.DeliveryMethodId"
                :items="deliveryMethodData"
                :loading="deliveryMethodsGetInProgress"
                :disabled="deliveryMethodsGetInProgress || !service.Selected"
                item-text="Name"
                item-value="DeliveryMethodId"
                autocomplete="off"
              ></v-select>
            </v-col>
            <v-col cols="3" class="input-label">
              <label :class="!service.Selected ? 'grey--text' : ''">Start Date</label>
            </v-col>
            <v-col cols="8">
              <data-date-picker
                v-model="value.DeliveryStoreService.StartDate"
                :clearable="true"
                :disabled="!service.Selected"
              />
            </v-col>
            <v-col cols="4" class="input-label">
              <label :class="!service.Selected ? 'grey--text' : ''">Delivery Area (mi)</label>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="value.DeliveryStoreService.DeliveryAreaMiles"
                :items="deliveryAreaLookup"
                :loading="deliveryAreaLookupGetInProgress"
                :disabled="deliveryAreaLookupGetInProgress || !service.Selected"
                :clearable="true"
                :item-text="x => (x && x.DeliveryAreaMiles ? x.DeliveryAreaMiles : '')"
                item-value="DeliveryAreaMiles"
                autocomplete="off"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                label="Backup Delivery Method"
                v-model="value.DeliveryStoreService.SecondaryDeliveryMethodId"
                :items="deliveryMethodData"
                :loading="deliveryMethodsGetInProgress"
                :disabled="deliveryMethodsGetInProgress || !service.Selected"
                clearable
                item-text="Name"
                item-value="DeliveryMethodId"
                autocomplete="off"
              ></v-select>
            </v-col>
          </v-row>
        </v-row>

        <v-row v-else-if="service.ServiceId === autoshipServiceType">
          <v-col cols="3" class="input-label">
            <label :class="!service.Selected ? 'grey--text' : ''">Start Date</label>
          </v-col>
          <v-col cols="8">
            <data-date-picker
              v-model="value.AutoShipStoreService.StartDate"
              :clearable="true"
              :disabled="!service.Selected"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as ps from '@/store/modules/pcl/state';
import { StoreCreateUpdatePayload, ServiceTypes } from '@psp/pogona_pcl_client_js';

const pclModule = namespace('pcl');

@Component({})
export default class PclStoreServices extends Vue {
  @Model('modelChanged', { type: StoreCreateUpdatePayload })
  readonly value!: StoreCreateUpdatePayload;

  @Prop({ required: true })
  readonly services!: ps.StoreService[];

  @Prop({ required: true })
  readonly errorsMessages!: ps.NewEditStoreValidationMessages;

  @Prop({ required: true })
  readonly validate!: unknown;

  @pclModule.State(ps.deliveryMethodsGetInProgress) deliveryMethodsGetInProgress!: boolean;
  @pclModule.State(ps.deliveryAreaLookupGetInProgress) deliveryAreaLookupGetInProgress!: boolean;
  @pclModule.State(ps.deliveryMethodData) deliveryMethodData!: any;
  @pclModule.State(ps.deliveryAreaLookup) deliveryAreaLookup!: any;
  @pclModule.State(ps.dogWashTubTypes) dogWashTubTypes!: any;
  @pclModule.State(ps.dogWashTubTypesGetInProgress) dogWashTubTypesGetInProgress!: any;

  serviceSelectedChange(service, e) {
    // if anything is deselected, clear their textbox and errors
    if (service.Selected === false) {
      switch (service.ServiceId as ServiceTypes) {
        case ServiceTypes.Grooming:
          if (
            this.errorsMessages.GroomingNumberOfTables &&
            this.errorsMessages.GroomingNumberOfTables.length > 0 &&
            this.value.GroomingStoreService
          ) {
            this.value.GroomingStoreService.NumberOfTables = null;
            this.errorsMessages.GroomingNumberOfTables = [] as string[];
          }

          if (
            this.errorsMessages.GroomingNumberOfTubs &&
            this.errorsMessages.GroomingNumberOfTubs.length > 0 &&
            this.value.GroomingStoreService
          ) {
            this.value.GroomingStoreService.NumberOfTubs = null;
            this.errorsMessages.GroomingNumberOfTubs = [] as string[];
          }
          break;
        case ServiceTypes.DogWash:
          if (
            this.errorsMessages.DogWashNumberOfStations &&
            this.errorsMessages.DogWashNumberOfStations.length > 0 &&
            this.value.DogWashService
          ) {
            this.value.DogWashService.NumberOfStations = null;
            this.errorsMessages.DogWashNumberOfStations = [] as string[];
          }
          break;
      }
    }
  }

  private get groomingServiceType() {
    return ServiceTypes.Grooming;
  }

  private get dogWashServiceType() {
    return ServiceTypes.DogWash;
  }

  private get curbsidePickupServiceType() {
    return ServiceTypes.CurbsidePickup;
  }

  private get deliveryServiceType() {
    return ServiceTypes.Delivery;
  }

  private get autoshipServiceType() {
    return ServiceTypes.Autoship;
  }
}
</script>
