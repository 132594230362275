<template>
  <v-dialog v-model="dialog" max-width="50rem">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">Advanced Search</v-btn>
    </template>
    <v-card :loading="searchLoading">
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <span class="headlline">Advanced Search</span>
          </v-col>
          <v-col cols="2">
            <div class="float-right">
              <v-btn small @click="dialog = false" class="my-0"
                ><v-icon small>mdi-close</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Item ID (exact matches only)" v-model="itemId" clearable />
            </v-col>
            <v-col cols="6"><v-text-field label="SKU" v-model="upc" clearable /></v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="selectedBrands"
                label="Select Brands (type to search)"
                :items="brands"
                item-text="BrandName"
                item-value="Id"
                chips
                multiple
                clearable
                :loading="loadingBrands"
                deletable-chips
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-radio-group v-model="include" column>
                <v-radio label="Found Items" :value="foundItemType"></v-radio>
                <v-radio label="Not Found Items" :value="notFoundItemType"></v-radio>
                <v-radio label="All" :value="allItemType"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="selectedDepartment"
                :items="departments"
                label="Select Department"
                item-text="CategoryName"
                item-value="Id"
                chips
                clearable
                deletable-chips
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="selectedSubDepartment"
                :items="subDepartments"
                label="Select Sub Department"
                item-text="CategoryName"
                item-value="Id"
                chips
                clearable
                deletable-chips
                :disabled="subDepartmentsDisabled"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="selectedCategory"
                :items="categories"
                label="Select Category"
                item-text="CategoryName"
                item-value="Id"
                chips
                clearable
                deletable-chips
                :disabled="categoriesDisabled"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="selectedSubCategories"
                :items="subCategories"
                label="Select Sub Categories"
                item-text="CategoryName"
                item-value="Id"
                chips
                clearable
                deletable-chips
                multiple
                :disabled="subCategoriesDisabled"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <div>
                <v-btn @click="clearClicked" class="mr-3">Clear</v-btn>
                <v-btn @click="searchClicked">Search</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SubscriptionExceptionSearchParams, IncludeItemsType } from '@psp/pogona_ecom_client_js';
import DwBrand from '@psp/pogona_ecom_client_js/DwBrand';
import DwCategory from '@psp/pogona_ecom_client_js/DwCategory';
import * as ea from '@/store/modules/ecom/actions';
import * as es from '@/store/modules/ecom/state';
import * as em from '@/store/modules/ecom/mutations';

const ecomModule = namespace('ecom');

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({})
export default class AutoShipSearchRefinement extends Vue {
  @ecomModule.Action(ea.getBrands)
  getBrands!: () => Promise<any>;
  @ecomModule.Action(ea.getDepartments)
  getDepartments!: () => Promise<any>;
  @ecomModule.Action(ea.getSubDepartments)
  getSubDepartments!: (payload: number) => Promise<any>;
  @ecomModule.Action(ea.getCategories)
  getCategories!: (payload: number) => Promise<any>;
  @ecomModule.Action(ea.getSubCategories)
  getSubCategories!: (payload: number) => Promise<any>;

  @ecomModule.State(es.brands) brands!: DwBrand[];
  @ecomModule.State(es.departments) departments!: DwCategory[];
  @ecomModule.State(es.subDepartments) subDepartments!: DwCategory[];
  @ecomModule.State(es.categories) categories!: DwCategory[];
  @ecomModule.State(es.subCategories) subCategories!: DwCategory[];
  @ecomModule.State(es.exceptionSearch) exceptionSearch!: SubscriptionExceptionSearchParams;

  @ecomModule.Mutation(em.setExceptionDataSearch) setExceptionDataSearch!: (
    payload: SubscriptionExceptionSearchParams,
  ) => void;

  private searchLoading = false;
  private dialog = false;
  private itemId: number | null = null;
  private upc: string | null = null;
  private selectedBrands: any = [];
  private selectedDepartment: number | null = null;
  private selectedSubDepartment: number | null = null;
  private selectedCategory: number | null = null;
  private selectedSubCategories: any = [];
  private unwatchDepartment: (() => void) | null = null;
  private unwatchSubDepartment: (() => void) | null = null;
  private unwatchCategory: (() => void) | null = null;
  private include = IncludeItemsType.All;

  private loadingBrands = false;
  private loadingDepartments = false;
  private loadingSubDepartments = false;
  private loadingCategories = false;
  private loadingSubCategories = false;

  get foundItemType(): IncludeItemsType {
    return IncludeItemsType.FoundItems;
  }

  get notFoundItemType(): IncludeItemsType {
    return IncludeItemsType.NotFoundItems;
  }

  get allItemType(): IncludeItemsType {
    return IncludeItemsType.All;
  }

  private get subDepartmentsDisabled() {
    return this.selectedDepartment === null;
  }

  private get categoriesDisabled() {
    return this.selectedSubDepartment === null;
  }

  private get subCategoriesDisabled() {
    return this.selectedCategory === null;
  }

  private clearClicked() {
    this.itemId = null;
    this.upc = null;
    this.selectedBrands = [];
    this.selectedDepartment = null;
    this.selectedSubDepartment = null;
    this.selectedCategory = null;
    this.selectedSubCategories = [];
    this.include = IncludeItemsType.All;
  }

  private searchClicked() {
    const searchParams: SubscriptionExceptionSearchParams = {
      ...this.exceptionSearch,
      BrandIds: this.selectedBrands,
      CategoryLevel1Id: this.selectedDepartment,
      CategoryLevel2Id: this.selectedSubDepartment,
      CategoryLevel3Ids: this.selectedCategory === null ? [] : [this.selectedCategory],
      CategoryLevel4Ids: this.selectedSubCategories,
      PageNumber: 1,
      ItemId: this.itemId,
      Sku: this.upc,
      Include: this.include,
    };

    this.setExceptionDataSearch(searchParams);
    this.dialog = false;
    this.$emit('search-clicked');
  }

  public onDepartmentChange(newdep: number | null, olddep: number | null) {
    this.selectedSubDepartment = null;
    this.selectedCategory = null;
    this.selectedSubCategories = [];
    if (newdep !== null && newdep !== olddep) {
      this.loadingSubDepartments = true;
      this.getSubDepartments(newdep).finally(() => (this.loadingSubDepartments = false));
    }
  }

  public onSubDepartmentChange(newsubdep: number | null, oldsubdep: number | null) {
    this.selectedCategory = null;
    this.selectedSubCategories = [];
    if (newsubdep !== null && newsubdep !== oldsubdep) {
      this.loadingCategories = true;
      this.getCategories(newsubdep).finally(() => (this.loadingCategories = false));
    }
  }

  public onCategoryChange(newcat: number | null, oldcat: number | null) {
    this.selectedSubCategories = [];
    if (newcat !== null && newcat !== oldcat) {
      this.loadingSubCategories = true;
      this.getSubCategories(newcat).finally(() => (this.loadingSubCategories = false));
    }
  }

  private setupCategoryWatches(): void {
    this.unwatchDepartment = this.$watch('selectedDepartment', this.onDepartmentChange);
    this.unwatchSubDepartment = this.$watch('selectedSubDepartment', this.onSubDepartmentChange);
    this.unwatchCategory = this.$watch('selectedCategory', this.onCategoryChange);
  }

  private unWatchCategories(): void {
    if (this.unwatchDepartment !== null) {
      this.unwatchDepartment();
    }
    if (this.unwatchSubDepartment !== null) {
      this.unwatchSubDepartment();
    }
    if (this.unwatchCategory !== null) {
      this.unwatchCategory();
    }
  }

  @Watch('dialog') private onDialogChange(val: boolean) {
    if (val) {
      this.unWatchCategories();

      this.searchLoading = true;
      this.itemId = this.exceptionSearch.ItemId;
      this.upc = this.exceptionSearch.Sku;

      this.loadingBrands = true;
      this.loadingDepartments = true;
      var brandp = this.getBrands()
        .then(() => (this.selectedBrands = this.exceptionSearch.BrandIds))
        .finally(() => {
          this.loadingBrands = false;
        });
      var deptp = this.getDepartments()
        .then(() => {
          this.selectedDepartment = this.exceptionSearch.CategoryLevel1Id;
        })
        .finally(() => (this.loadingDepartments = false));

      let subdeptp: any = Promise.resolve();
      if (this.exceptionSearch.CategoryLevel1Id && this.exceptionSearch.CategoryLevel2Id) {
        this.loadingSubDepartments = true;
        subdeptp = this.getSubDepartments(this.exceptionSearch.CategoryLevel1Id)
          .then(() => (this.selectedSubDepartment = this.exceptionSearch.CategoryLevel2Id))
          .finally(() => (this.loadingSubDepartments = false));
      } else {
        this.selectedSubDepartment = null;
      }

      let catp: any = Promise.resolve();
      if (
        this.exceptionSearch.CategoryLevel2Id &&
        this.exceptionSearch.CategoryLevel3Ids.length > 0
      ) {
        this.loadingCategories = true;
        catp = this.getCategories(this.exceptionSearch.CategoryLevel2Id)
          .then(() => (this.selectedCategory = this.exceptionSearch.CategoryLevel3Ids[0]))
          .finally(() => (this.loadingCategories = false));
      } else {
        this.selectedCategory = null;
      }

      let subcatp: any = Promise.resolve();
      if (
        this.exceptionSearch.CategoryLevel3Ids.length > 0 &&
        this.exceptionSearch.CategoryLevel4Ids.length > 0
      ) {
        this.loadingSubCategories = true;
        subcatp = this.getSubCategories(this.exceptionSearch.CategoryLevel3Ids[0])
          .then(() => (this.selectedSubCategories = this.exceptionSearch.CategoryLevel4Ids))
          .finally(() => (this.loadingSubCategories = false));
      }

      Promise.all([brandp, deptp, subdeptp, catp, subcatp]).finally(() => {
        this.searchLoading = false;
        this.setupCategoryWatches();
      });
    }
  }
}
</script>
