<template>
    <core-view-template title="Key Tracking">
        <core-view-section title="Generate Next Key">
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title class="text--black">
                            <v-row>
                                <v-col cols="auto"> How Does This Work? </v-col></v-row></v-card-title>
                        <v-card-subtitle>Choose a tracking key from the "Available Keys" dropdown. The next key will be
                            automatically generated and copied to your clipboard. If you wish to generate the next key,
                            simply click "Generate".
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    <v-autocomplete v-model="trackingKey" :loading="trackingKeysGetInProgress"
                        :disabled="trackingKeysGetInProgress" :items="trackingKeys"
                        @change="generatedKey = ''; generateNextKey()" label="Available Keys" item-text="description"
                        item-value="keyName" spellcheck="false" autocomplete="off" />
                </v-col>
                <v-col cols="3">
                    <v-btn @click="generateNextKey" :disabled="generatingTrackingKeyValueInProgress"
                        :loading="generatingTrackingKeyValueInProgress">Generate</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="pt-0">
                    <v-text-field readonly v-model="generatedKey" spellcheck="false" autocomplete="off" />
                </v-col>
                <v-col cols="6">
                    <v-icon class="pointer" :disabled="generatedKey && generatedKey.length > 0"
                        @click="copy">content_copy</v-icon>
                </v-col>
            </v-row>
        </core-view-section>
    </core-view-template>
</template>

<script setup lang="ts">
import Vue, { ref, onMounted, defineComponent } from 'vue';
import { useKeyTrackingStore } from '@/stores/keytrackingstore';
import { storeToRefs } from 'pinia';

defineComponent({
    name: 'KeyTracking'
});

const emit = defineEmits([
    'snackbar-success'
]);

const keyTrackingStore = useKeyTrackingStore();

const {
    trackingKeysGetInProgress,
    trackingKeys,
    generatingTrackingKeyValueInProgress,
    generatedKey
} = storeToRefs(keyTrackingStore);

const trackingKey = ref('');

onMounted(async () => {
    trackingKey.value = '';
    generatedKey.value = '';
    await keyTrackingStore.getTrackingKeys();
});

async function generateNextKey() {
    await keyTrackingStore.generateNextTrackingKeyValue(trackingKey.value);
    copy();
}

function copy() {
    navigator.clipboard.writeText(generatedKey.value);
    emit('snackbar-success', `'${generatedKey.value}' copied to keyboard`);
}
</script>