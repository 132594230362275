<template>
  <core-signs-labels-view-template title="Data Import" :showStoreNumber="false">
    <core-info-blurb title="What is data import?">
      The data import page allows you to import various data sets for Signs and Labels. Some
      examples include: store sets, deactivations, and ingredients. You can view a sample file by
      clicking the "Download Sample" link below the import type dropdown.
    </core-info-blurb>
    <v-row>
      <v-select
        v-model="selectedStoreSetDataImportType"
        :disabled="uploading"
        :items="storeSetDataImportTypes"
        label="Import Type"
        item-text="description"
        item-value="storeSetDataImportTypeId"
        prepend-icon="playlist_add_check"
      ></v-select>
    </v-row>
    <v-row v-if="showDownloadSample" class="mb-2">
      <a :href="importSample"> <v-icon class="mr-2">get_app</v-icon>Download Sample</a>
    </v-row>
    <v-row>
      <v-file-input
        v-model="dataFile"
        prepend-icon="mdi-paperclip"
        :accept="acceptableFormats"
        label="Item Data"
        show-size
        :disabled="uploading"
      ></v-file-input>
    </v-row>
    <v-row v-if="setDesignatorsUpload">
      <v-file-input
        v-model="setDesignatorsFile"
        prepend-icon="mdi-paperclip"
        :accept="acceptableFormats"
        :label="`Set Designators${selectedStoreSetDataImportType === 2 ? ' (Optional)' : ''}`"
        show-size
        :disabled="uploading"
      ></v-file-input>
    </v-row>
    <v-row v-if="edlpUpload">
      <v-col>
        <v-row>
          <v-col>
            <v-dialog max-width="340" v-model="startDateDialog">
              <template v-slot:activator="{ on }">
                <v-text-field
                  prepend-icon="today"
                  v-model="retailStartDate"
                  readonly
                  v-on="on"
                  label="Retail Start Date"
                ></v-text-field>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Choose a start date</span>
                </v-card-title>
                <v-col cols="12">
                  <v-row>
                    <v-date-picker class="mt-2 mx-auto" v-model="retailStartDate"></v-date-picker>
                  </v-row>

                  <slot></slot>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="startDateDialog = false">OK</v-btn>
                  </v-card-actions>
                </v-col>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedRetailType"
              label="Retail Type (optional)"
              :items="retailTypes"
              clearable
              prepend-icon="checklist"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedDescType"
              label="Desc Type (optional)"
              :items="descTypes"
              clearable
              prepend-icon="checklist"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-text-field
        v-model="description"
        label="Description (optional)"
        maxlength="1024"
        prepend-icon="edit"
        :disabled="uploading"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-dialog v-model="confirmUploadDialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="!canUpload" :loading="uploading">Upload</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Confirm Upload</span>
          </v-card-title>
          <v-card-text
            >Are you sure you want to upload the selected file{{ setDesignatorsUpload ? 's' : '' }}
            as
            {{ getAOrAn(getStoreSetDataImportTypesDescription(selectedStoreSetDataImportType)) }}
            {{
              getStoreSetDataImportTypesDescription(selectedStoreSetDataImportType)
            }}?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                uploadFiles();
                confirmUploadDialog = false;
              "
              >OK</v-btn
            >
            <v-btn @click="confirmUploadDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <span class="text-center" v-if="progressMessage">{{ progressMessage }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-progress-linear
              v-if="uploading || waitingForImportStart"
              v-model="uploadProgress"
              :indeterminate="progressIndeterminate"
              height="25"
            >
              <template v-if="!progressIndeterminate" v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="storeSetImports.selected"
          :headers="storeSetImports.headers"
          :items="storeSetImports.items"
          :loading="storeSetImports.loading"
          :disable-pagination="true"
          :options.sync="storeSetImports.options"
          :fixed-header="true"
          @update:sort-by="storeSetImports.get()"
          item-key="storeSetDataImportId"
          class="elevation-1 scrollable"
        >
          <template v-slot:item.storeSetDataImportTypeId="{ value }">{{
            getStoreSetDataImportTypesDescription(value)
          }}</template>
          <template v-slot:item.storeSetDataImportStatusId="{ value }">{{
            getStoreSetDataImportStatusIdDescription(value)
          }}</template>
          <template v-slot:item.completed="{ item }">
            <data-circular-progress
              v-if="item.storeSetDataImportStatusId === 1 || item.storeSetDataImportStatusId === 2"
              class="my-2"
              :size="50"
              :width="5"
              :id="item.storeSetDataImportGuid"
              :showDescription="false"
              :showName="false"
              :initialValue="item.completed"
              :closeOnError="false"
              @error="initStoreSetImports"
              @progressUpdated="progressUpdated"
            ></data-circular-progress>
            <span v-else>{{ item.completed }}%</span>
          </template>
          <template v-slot:item.itemCount="{ item }">
            <div
              v-if="item.storeSetDataImportStatusId === 3 || item.storeSetDataImportStatusId === 4"
            >
              {{ item.itemCount }}
            </div>
          </template>
          <template v-slot:item.timeCreatedUtc="{ value }">{{
            value | formatUtcDateTime
          }}</template>
          <template v-slot:item.timeFinishedUtc="{ value }">{{
            value | formatUtcDateTime
          }}</template>
          <template v-slot:item.print="{ item }">
            <v-btn v-if="canPrint(item)" :to="printRoute(item)">Print</v-btn>
          </template>
          <template v-slot:item.downloadMain="{ item }">
            <v-btn
              v-if="item.storeSetDataBlobName && item.storeSetDataBlobName.length > 0"
              @click="download(item.storeSetDataBlobName)"
              :disabled="downloading"
              :loading="downloading"
              >Download Main</v-btn
            >
          </template>
          <template v-slot:item.downloadSecondary="{ item }">
            <v-btn
              v-if="item.storeSetDesignatorsBlobName && item.storeSetDesignatorsBlobName.length > 0"
              @click="download(item.storeSetDesignatorsBlobName)"
              :disabled="downloading"
              :loading="downloading"
              >Download Secondary</v-btn
            >
          </template>
        </v-data-table>
        <infinite-paganation :pogonaTable="storeSetImports"></infinite-paganation>
      </v-col>
    </v-row>
  </core-signs-labels-view-template>
</template>

<script>
/* eslint-disable no-await-in-loop */
import { mapState } from 'vuex';
import uuid from 'uuid';
import moment from 'moment';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import PogonaDataTable from '@/utils/PogonaDataTable';
import blobToBuffer from 'blob-to-buffer';
import { ContainerClient } from '@azure/storage-blob';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      retailStartDate: moment().startOf('day').format('YYYY-MM-DD'),
      startDateDialog: false,
      dataFile: null,
      setDesignatorsFile: null,
      uploadProgress: 0,
      uploading: false,
      storeSetDataImportTypes: [],
      selectedStoreSetDataImportType: 1,
      storeSetDataImportStatuses: [],
      waitingForImportStart: false,
      wsConn: null,
      importId: null,
      adalHelper: new AdalHelpers(),
      storeSetImports: {},
      progressMessage: null,
      refreshedProgress: [],
      description: null,
      confirmUploadDialog: false,
      showDownloadSample: false,
      acceptableFormats: '.csv,.xlsx',
      selectedRetailType: null,
      selectedDescType: null,
      retailTypes: ['REG', 'SNC', 'SWC'],
      descTypes: ['BAS', 'HOT', 'INS', 'MIX', 'MON', 'RTC'],
      downloading: false,
    };
  },
  async created() {
    this.initStoreSetImports();

    this.storeSetDataImportTypes = (
      await this.$authApi.http.get('label/storesetdataimporttype')
    ).data.sort(this.compareImportTypes);
    this.storeSetDataImportStatuses = (
      await this.$authApi.http.get('label/storesetdataimportstatuses')
    ).data;

    this.selectedStoreSetDataImportType = this.storeSetDataImportTypes[0].storeSetDataImportTypeId;
  },
  computed: {
    ...mapState('app', ['username']),
    setDesignatorsUpload() {
      return (
        this.selectedStoreSetDataImportType === 1 ||
        this.selectedStoreSetDataImportType === 2 ||
        this.selectedStoreSetDataImportType === 8
      );
    },
    edlpUpload() {
      return this.selectedStoreSetDataImportType === 4 || this.selectedStoreSetDataImportType === 7;
    },
    progressIndeterminate() {
      return this.uploadProgress === 0 || this.waitingForImportStart;
    },
    canUpload() {
      let filesSelected = !!this.dataFile;
      if (this.selectedStoreSetDataImportType === 1 && filesSelected === true) {
        filesSelected = !!this.setDesignatorsFile;
      }
      return this.uploading === false && filesSelected === true;
    },
    importSample() {
      const importType = this.storeSetDataImportTypes.filter(
        x => x.storeSetDataImportTypeId === this.selectedStoreSetDataImportType,
      );

      if (
        importType &&
        importType.length === 1 &&
        importType[0].sampleBlobUrl &&
        importType[0].sampleBlobUrl.length > 0
      ) {
        return `${this.$storageHost}${importType[0].sampleBlobUrl}?_=${new Date().getTime()}`;
      }
      return null;
    },
  },
  methods: {
    canPrint(item) {
      return (
        item.storeSetDataImportStatusId === 3 &&
        (item.storeSetDataImportTypeId === 1 ||
          item.storeSetDataImportTypeId === 2 ||
          item.storeSetDataImportTypeId === 3 ||
          item.storeSetDataImportTypeId === 6)
      );
    },
    printRoute(item) {
      if (this.canPrint(item) === true) {
        switch (item.storeSetDataImportTypeId) {
          case 1:
            return {
              name: 'sl_new_import_store_set',
              params: { importId: item.storeSetDataImportId },
            };
          case 2:
          case 3:
            return {
              name: 'sl_store_set_stage_print',
            };
          case 6:
            return {
              name: 'sl_ingredient_stage_print',
            };
        }
      }
      return null;
    },
    compareImportTypes(a, b) {
      // Use toUpperCase() to ignore character casing
      const importA = a.description.toUpperCase();
      const importB = b.description.toUpperCase();

      let comparison = 0;
      if (importA > importB) {
        comparison = 1;
      } else if (importA < importB) {
        comparison = -1;
      }
      return comparison;
    },
    setError(err) {
      this.uploading = false;
      this.waitingForImportStart = false;
      this.$emit('snackbar-error', {
        text: 'Error uploading data',
        err,
        id: '11d90554-0ef8-420d-b0c1-5bb5fc565e3f',
      });
    },
    progressUpdated(progress) {
      // We do this to update the status column in the grid
      if (
        progress &&
        progress.complete > 0 &&
        (!this.refreshedProgress[progress.id] ||
          (progress.complete > this.refreshedProgress[progress.id] && progress.complete >= 100) ||
          progress.error)
      ) {
        // if this is the first progress message, reset the upload state
        if (!this.refreshedProgress[progress.id]) {
          this.resetUpload();
        }

        this.initStoreSetImports();
        this.refreshedProgress[progress.id] = progress.complete;
      }
    },
    async uploadFiles() {
      this.uploading = true;
      this.progressMessage = 'Uploading file(s)';
      try {
        // first, chunk the file(s) and send them to the server.
        // the server will save them into blob storage.
        this.importId = uuid();
        const sliceSize = 10485760;

        const files = [{ file: this.dataFile, isData: true, blobName: '' }];

        let chunks = Math.ceil(this.dataFile.size / sliceSize);
        if (this.setDesignatorsUpload === true && this.setDesignatorsFile) {
          chunks += Math.ceil(this.setDesignatorsFile.size / sliceSize);
          files.push({ file: this.setDesignatorsFile, isData: false, blobName: '' });
        }

        let chunk = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const f of files) {
          let sliceIx = 1;

          let fileSlice = f.file.slice(0, sliceSize);
          while (fileSlice.size > 0) {
            const fileSliceBuffer = await this.convertBlobToBuffer(fileSlice);

            const createdBlob = (
              await this.$authApi.http.post(
                `storeset/upload/${f.isData ? 'storesetdata' : 'storesetdesignators'}/${
                  this.importId
                }/${sliceIx === 1 ? 'true' : 'false'}`,
                {
                  data: fileSliceBuffer.toString('base64'),
                  fileName: f.file.name,
                },
              )
            ).data;

            f.blobName = createdBlob.blobName;

            fileSlice = f.file.slice(sliceSize * sliceIx++, sliceSize * sliceIx);
            chunk++;
            this.uploadProgress = ((chunk / chunks) * 100).toFixed(0);
          }
        }

        // now, create a row in StoreSetDataImport
        const newImport = (
          await this.$authApi.http.post('label/storesetdataimport/now', {
            storeSetDataImportTypeId: this.selectedStoreSetDataImportType,
            storeSetDataBlobName: files[0].blobName,
            storeSetDesignatorsBlobName: files.length > 1 ? files[1].blobName : null,
            storeSetDataImportGuid: this.importId,
            description: this.description,
            username: this.username,
          })
        ).data;

        // send a message to begin import
        await this.$authApi.http.post('servicebus/storesetimport', {
          id: this.importId,
          storeSetDataImportId: newImport.storeSetDataImportId,
          storeSetDataImportType: newImport.storeSetDataImportTypeId,
          storeSetDataBlobName: newImport.storeSetDataBlobName,
          storeSetDesignatorsBlobName: newImport.storeSetDesignatorsBlobName,
          timeCreatedUtc: newImport.timeCreatedUtc,
          retailStartDate: this.retailStartDate,
          retailType: this.selectedRetailType,
          descType: this.selectedDescType,
        });

        this.progressMessage = 'Waiting for import to start';

        // Refresh grid
        this.initStoreSetImports();

        this.waitingForImportStart = true;

        // wait for the import start message
        const onmessage = async event => {
          const importStartedData = JSON.parse(event.data);
          this.wsConn.close();

          if (importStartedData.error) {
            this.setError(importStartedData.error);
          } else {
            this.resetUpload();
          }
        };

        const adalToken = await this.adalHelper.getJwtToken(this.$authApi);
        this.wsConn = new PogonaWebSocket(
          this.$apiBasePath,
          'store_set_import_start',
          this.importId,
          adalToken,
          onclose,
          onmessage,
          90, // timeout
        );

        this.dataFile = null;
        this.setDesignatorsFile = null;
        this.selectedRetailType = null;
        this.selectedDescType = null;
      } catch (e) {
        this.setError();
      }
    },
    async convertBlobToBuffer(blob) {
      return new Promise((resolve, reject) => {
        blobToBuffer(blob, (err, buffer) => {
          if (err) {
            reject(err);
          }

          resolve(buffer);
        });
      });
    },
    resetUpload() {
      this.waitingForImportStart = false;
      this.uploading = false;
      this.progressMessage = null;
      this.description = null;
    },
    getStoreSetDataImportTypesDescription(storeSetDataImportTypeId) {
      if (this.storeSetDataImportTypes) {
        const filtered = this.storeSetDataImportTypes.filter(
          x => x.storeSetDataImportTypeId === storeSetDataImportTypeId,
        );

        if (filtered && filtered.length === 1) {
          return filtered[0].description;
        }
      }

      return '';
    },
    getStoreSetDataImportStatusIdDescription(storeSetDataImportStatusId) {
      if (this.storeSetDataImportStatuses) {
        const filtered = this.storeSetDataImportStatuses.filter(
          x => x.storeSetDataImportStatusId === storeSetDataImportStatusId,
        );

        if (filtered && filtered.length === 1) {
          return filtered[0].description;
        }
      }

      return '';
    },
    getAOrAn(word) {
      if (!word && word.length > 0) {
        return null;
      }
      const firstLetter = word.toLowerCase()[0];
      return ['a', 'e', 'i', 'o', 'u'].indexOf(firstLetter) > -1 ? 'an' : 'a';
    },
    initStoreSetImports() {
      this.storeSetImports = new PogonaDataTable({
        headers: [
          {
            text: 'ID',
            value: 'storeSetDataImportId',
          },
          {
            text: 'Type',
            value: 'storeSetDataImportTypeId',
          },
          {
            text: 'Status',
            value: 'storeSetDataImportStatusId',
          },
          {
            text: 'Progress',
            value: 'completed',
          },
          {
            text: 'Items',
            value: 'itemCount',
          },
          {
            text: 'Description',
            value: 'description',
          },
          {
            text: 'Created At',
            value: 'timeCreatedUtc',
          },
          {
            text: 'Finished At',
            value: 'timeFinishedUtc',
          },
          {
            text: 'Result',
            value: 'processResult',
          },
          {
            text: 'Print',
            value: 'print',
            sortable: false,
          },
          {
            text: 'Download Main',
            value: 'downloadMain',
            sortable: false,
          },
          {
            text: 'Download Secondary',
            value: 'downloadSecondary',
            sortable: false,
          },
        ],
        baseUrl: 'label/storesetdataimport',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['storeSetDataImportId desc'] },
        isInfinite: true,
      });

      this.storeSetImports.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting imports',
          err,
          id: '3f54783f-1071-4ff1-9edf-afbe896207dc',
        });
      });

      this.storeSetImports.get();
    },
    async download(file) {
      this.downloading = true;
      try {
        const urlParts = `${this.$storageHost}/storesets/${file}`.split('/');
        const containerClient = new ContainerClient(
          urlParts.slice(0, urlParts.length - 1).join('/'),
        );

        const blobClient = containerClient.getBlobClient(urlParts[urlParts.length - 1]);
        const download = await blobClient.download();

        const blobBody = await download.blobBody;

        // save the file to disk
        saveAs(blobBody, urlParts[urlParts.length - 1]);
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error downloading file',
          err,
          id: '8fd60fd9-3143-4bb9-97f2-ace16dbc68c8',
        });
      }

      this.downloading = false;
    },
  },
  watch: {
    importSample: {
      handler: function (val) {
        if (val) {
          this.showDownloadSample = true;
        } else {
          this.showDownloadSample = false;
        }
      },
    },
  },
};
</script>
