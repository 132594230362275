<!-- eslint-disable no-case-declarations -->
<template>
  <v-col>
    <v-row v-for="val in socialMediaLookup" :key="val.SocialMediaId">
      <v-col cols="3" class="input-label">
        <label>{{ getSocialMediaName(val.SocialMediaId) }}</label>
      </v-col>
      <v-col cols="7">
        <v-row>
          <label class="input-label input-social-media-prefix">{{
            getSocialMediaPrefix(val.SocialMediaId)
          }}</label>
          <v-text-field
            :value="getSocialMediaValue(val.SocialMediaId).UrlSuffix"
            @input="event => setSocialMediaValue(val.SocialMediaId, event)"
            autocomplete="off"
            maxlength="256"
            :error-messages="getSocialMediaErrorMessages(val.SocialMediaId)"
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              v-if="val.Linkable === true"
              :disabled="previewButtonDisabled(val.SocialMediaId)"
              :href="`${val.UrlPrefix}${getSocialMediaValue(val.SocialMediaId).UrlSuffix}`"
              target="_blank"
            >
              <v-icon>open_in_new</v-icon>
            </v-btn>
          </template>
          <span>Open link in new window</span>
        </v-tooltip></v-col
      >
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator';
import { StoreSocialMedia, SocialMedia } from '@psp/pogona_pcl_client_js';

@Component({})
export default class PclSocialMediaEdit extends Vue {
  private errorMessage =
    'This field may only contain alphanumeric characters, dashes, underscores, or dots.';

  @ModelSync('socialMedias', 'input', { type: Array })
  readonly value!: StoreSocialMedia[];

  @Prop() readonly socialMediaLookup!: SocialMedia[];
  @Prop() readonly storeId!: number;
  @Prop() readonly errors!: string[];

  private hashtagSocialMedia!: StoreSocialMedia;
  private hashtagSocialMediaErrors: string[] = [] as string[];
  private facebookSocialMedia!: StoreSocialMedia;
  private facebookSocialMediaErrors: string[] = [] as string[];
  private twitterSocialMedia!: StoreSocialMedia;
  private twitterSocialMediaErrors: string[] = [] as string[];
  private instagramSocialMedia!: StoreSocialMedia;
  private instagramSocialMediaErrors: string[] = [] as string[];
  private yelpSocialMedia!: StoreSocialMedia;
  private yelpSocialMediaErrors: string[] = [] as string[];
  private googleSocialMedia!: StoreSocialMedia;
  private googleSocialMediaErrors: string[] = [] as string[];

  previewButtonDisabled(socialMediaId) {
    return (
      (this.getSocialMediaValue(socialMediaId)?.UrlSuffix ?? '').trim().length === 0 ||
      this.validateSocialMediaValue(this.getSocialMediaValue(socialMediaId).UrlSuffix) === false
    );
  }

  getSocialMediaName(socialMediaId) {
    const socialMedia = this.socialMediaLookup.filter(x => x.SocialMediaId == socialMediaId);
    if (socialMedia.length === 1) {
      return socialMedia[0].Name;
    }
    return '';
  }

  getSocialMediaPrefix(socialMediaId) {
    const socialMedia = this.socialMediaLookup.filter(x => x.SocialMediaId == socialMediaId);
    if (socialMedia.length === 1) {
      return socialMedia[0].UrlPrefix;
    }
    return '';
  }

  getSocialMediaErrorMessages(socialMediaId) {
    switch (socialMediaId) {
      case 1:
        return this.hashtagSocialMediaErrors;
      case 2:
        return this.facebookSocialMediaErrors;
      case 3:
        return this.twitterSocialMediaErrors;
      case 4:
        return this.instagramSocialMediaErrors;
      case 5:
        return this.yelpSocialMediaErrors;
      case 6:
        return this.googleSocialMediaErrors;
    }
  }

  getSocialMediaValue(socialMediaId): StoreSocialMedia {
    if (this.value) {
      const socialMedia = this.value.filter(x => x.SocialMediaId === socialMediaId);
      switch (socialMediaId) {
        case 1:
          if (!this.hashtagSocialMedia) {
            this.hashtagSocialMedia =
              socialMedia.length === 1
                ? socialMedia[0]
                : new StoreSocialMedia(socialMediaId, this.storeId, '');
          }
          return this.hashtagSocialMedia;
        case 2:
          if (!this.facebookSocialMedia) {
            this.facebookSocialMedia =
              socialMedia.length === 1
                ? socialMedia[0]
                : new StoreSocialMedia(socialMediaId, this.storeId, '');
          }
          return this.facebookSocialMedia;
        case 3:
          if (!this.twitterSocialMedia) {
            this.twitterSocialMedia =
              socialMedia.length === 1
                ? socialMedia[0]
                : new StoreSocialMedia(socialMediaId, this.storeId, '');
          }
          return this.twitterSocialMedia;
        case 4:
          if (!this.instagramSocialMedia) {
            this.instagramSocialMedia =
              socialMedia.length === 1
                ? socialMedia[0]
                : new StoreSocialMedia(socialMediaId, this.storeId, '');
          }
          return this.instagramSocialMedia;
        case 5:
          if (!this.yelpSocialMedia) {
            this.yelpSocialMedia =
              socialMedia.length === 1
                ? socialMedia[0]
                : new StoreSocialMedia(socialMediaId, this.storeId, '');
          }
          return this.yelpSocialMedia;
        case 6:
          if (!this.googleSocialMedia) {
            this.googleSocialMedia =
              socialMedia.length === 1
                ? socialMedia[0]
                : new StoreSocialMedia(socialMediaId, this.storeId, '');
          }
          return this.googleSocialMedia;
      }
      return new StoreSocialMedia(socialMediaId, this.storeId, '');
    } else {
      return new StoreSocialMedia(0, this.storeId, '');
    }
  }

  validateSocialMediaValue(event) {
    const validatorRegex = new RegExp(/^(\w|-|_|\.)*$/);
    const socialMediaMatch = validatorRegex.exec(event);

    return !!socialMediaMatch;
  }

  setSocialMediaValue(socialMediaId, event) {
    if (!this.value) {
      this.$emit('input', [] as StoreSocialMedia[]);
      this.$emit('change', [] as StoreSocialMedia[]);

      // call setSocialMediaValue again so that this.value is set
      this.$nextTick(() => {
        this.setSocialMediaValue(socialMediaId, event);
      });
      return;
    }

    const valStoreSocial = this.value.filter(x => x.SocialMediaId === socialMediaId);
    const isValid = this.validateSocialMediaValue(event);
    switch (socialMediaId) {
      case 1:
        this.hashtagSocialMedia.UrlSuffix = event;
        if (valStoreSocial?.length === 1) {
          valStoreSocial[0] = this.hashtagSocialMedia;
        } else {
          this.value.push(this.hashtagSocialMedia);
        }

        this.hashtagSocialMediaErrors = [];
        if (isValid === false) {
          this.hashtagSocialMediaErrors.push(this.errorMessage);
        }
        break;
      case 2:
        this.facebookSocialMedia.UrlSuffix = event;
        if (valStoreSocial?.length === 1) {
          valStoreSocial[0] = this.facebookSocialMedia;
        } else {
          this.value.push(this.facebookSocialMedia);
        }

        this.facebookSocialMediaErrors = [];
        if (isValid === false) {
          this.facebookSocialMediaErrors.push(this.errorMessage);
        }
        break;
      case 3:
        this.twitterSocialMedia.UrlSuffix = event;
        if (valStoreSocial?.length === 1) {
          valStoreSocial[0] = this.twitterSocialMedia;
        } else {
          this.value.push(this.twitterSocialMedia);
        }

        this.twitterSocialMediaErrors = [];
        if (isValid === false) {
          this.twitterSocialMediaErrors.push(this.errorMessage);
        }
        break;
      case 4:
        this.instagramSocialMedia.UrlSuffix = event;
        if (valStoreSocial?.length === 1) {
          valStoreSocial[0] = this.instagramSocialMedia;
        } else {
          this.value.push(this.instagramSocialMedia);
        }

        this.instagramSocialMediaErrors = [];
        if (isValid === false) {
          this.instagramSocialMediaErrors.push(this.errorMessage);
        }
        break;
      case 5:
        this.yelpSocialMedia.UrlSuffix = event;
        if (valStoreSocial?.length === 1) {
          valStoreSocial[0] = this.yelpSocialMedia;
        } else {
          this.value.push(this.yelpSocialMedia);
        }

        this.yelpSocialMediaErrors = [];
        if (isValid === false) {
          this.yelpSocialMediaErrors.push(this.errorMessage);
        }
        break;
      case 6:
        this.googleSocialMedia.UrlSuffix = event;
        if (valStoreSocial?.length === 1) {
          valStoreSocial[0] = this.googleSocialMedia;
        } else {
          this.value.push(this.googleSocialMedia);
        }

        this.googleSocialMediaErrors = [];
        if (isValid === false) {
          this.googleSocialMediaErrors.push(this.errorMessage);
        }
        break;
    }

    this.$emit('update:errors', [
      ...this.hashtagSocialMediaErrors,
      ...this.facebookSocialMediaErrors,
      ...this.twitterSocialMediaErrors,
      ...this.instagramSocialMediaErrors,
      ...this.yelpSocialMediaErrors,
      ...this.googleSocialMediaErrors,
    ]);
    this.$emit('input', this.value);
    this.$emit('change', this.value);
  }
}
</script>
