import * as vx from 'vuex';
import { EcomProductState } from './state';
import * as m from '@/store/mutations';
import FileSaver from 'file-saver';
import dateFormat from 'dateformat';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const exportProductXML = 'exportProductXML';

export default {
  [exportProductXML]({ state, commit }: vx.ActionContext<EcomProductState, any>): Promise<unknown> {
    return state
      .client!.exportProductXML()
      .then(x => {
        FileSaver.saveAs(x.data, `product-feed-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xml`);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving product XML',
            id: '8e558f14-8750-4605-b602-330a193d3152',
          },
          { root: true },
        );
        throw err;
      });
  },
};
