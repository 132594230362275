<template>
  <v-row>
    <v-col>
      <v-row class="text--black mt-3">
        <v-col>
          <p v-if="showRenderSavedMessage">
            Your render progress has been saved, progress can viewed on the
            <router-link
              :to="{
                name: 'sl_renderings_progress',
                query: {
                  batchHashId: renderLabelsData.batchHash.toLowerCase(),
                },
              }"
            >
              <a>Render Progress Page</a>
            </router-link>
          </p>
        </v-col>
      </v-row>

      <v-row v-if="!renderings && showPatienceMessage">
        <v-col>
          <p>
            This is taking a little longer than usual. Please leave this page open and your render
            should begin shortly.
          </p></v-col
        >
      </v-row>

      <v-row>
        <v-col>
          <paper-stock-loading-images
            v-model="paperStockDialog"
            :showAll="false"
            :specIds="renderings ? renderings.map(x => x.specId) : []"
          >
          </paper-stock-loading-images>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-progress-linear
            v-if="!renderings"
            :indeterminate="startError === false"
            :value="startError === false ? 0 : 100"
            :color="startError === false ? 'green' : 'red'"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row v-if="renderings">
        <v-col>
          <p v-if="renderings && completeRenderings.length < renderings.length && batchInfoId">
            Hang tight, we're rendering your print sheet{{ renderings.length > 1 ? 's' : '' }}.
          </p>

          <v-row v-if="showStuckMessage">
            <v-col>
              <p class="text--black">
                Uh oh! It looks like one of your renders is stuck. Click reload to reactivate the
                render.
                <v-btn color="green" @click="$router.go()">
                  <!-- eslint-disable-next-line -->
                  Reload
                </v-btn>
              </p></v-col
            >
          </v-row>

          <v-row align="start" justify="start">
            <v-col id="renderProgresses" cols="12">
              <data-circular-progress
                class="mr-4 cursor-pointer"
                v-for="(render, ix) in renderings"
                v-bind:key="ix"
                :size="150"
                :id="render.id"
                :showDescription="true"
                :showName="true"
                :name="render.description"
                :firstRecTimeout="circularFirstRecTimeout"
                :forceComplete.sync="render.forceComplete"
                @progressUpdated="progressUpdated"
                @error="setError"
                @click.native.stop="progressClicked(render)"
              ></data-circular-progress>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        v-if="
          printDialogActive === false &&
          showCompleteToggle &&
          renderings &&
          completeRenderings.length >= renderings.length &&
          batchInfoId
        "
      >
        <v-col>
          <v-alert class="batch-alert" :type="batchInfoComplete ? 'success' : 'info'">
            <p v-if="!batchInfoComplete">Set the batch to complete once you're finished.</p>
            <p v-if="batchInfoComplete">Batch is complete.</p>
            <v-switch
              :loading="batchInfoCompleteLoading"
              :readonly="batchInfoCompleteLoading"
              inset
              v-model="batchInfoComplete"
              @change="updateBatchInfoComplete('render_page')"
            ></v-switch>
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <core-view-section
            v-show="completeRenderings && completeRenderings.length > 0"
            :title="`${$filters.initalCaps(currentCarouselItemName)} Sheet`"
          >
            <data-print-dialog
              v-model="printDialogActive"
              v-if="this.$useCustomPrintDialog"
              :pdfUrl="selectedRenderingPdfUrl"
              :partialUrls="selectedRenderingPartialFiles"
              :pdfFileName="fileNameFromFilePath(selectedRenderingPdfUrl)"
              :isLandscape="selectedRenderingIsPortrait === false"
              :numPages="selectedRenderingTotalPages"
              :loading="printLoading"
              :disabled="printLoading"
              :showPartialsCheckbox="selectedRenderingShowPartials"
              @printed="printed"
              @print-finished="printFinished"
            ></data-print-dialog>
            <v-carousel
              v-if="completeRenderings && completeRenderings.length > 0"
              v-model="carouselModel"
              :cycle="false"
              :hide-delimiters="completeRenderings.length <= 1"
              :show-arrows="completeRenderings.length > 1"
              @change="carouselChanged"
              class="pdf-carousel"
            >
              <v-carousel-item
                v-for="completeRendering in completeRenderings"
                v-bind:key="completeRendering.renderId"
              >
                <label
                  class="text--black"
                  v-if="
                    printDialogActive === true &&
                    completeRendering &&
                    completeRendering.totalPages &&
                    completeRendering.totalPages > 0
                  "
                  >Total Pages: {{ completeRendering.totalPages }}</label
                >
                <data-pdf
                  :src="completeRendering.blobUrl"
                  :downloadButton="!printDialogActive"
                  :printButton="!printDialogActive"
                  :zoom="'page-width'"
                  :cacheBuster.sync="completeRendering.cacheBuster"
                />
              </v-carousel-item>
            </v-carousel>
          </core-view-section>
        </v-col>
      </v-row>

      <v-row>
        <data-tour
          class="mb-3"
          tourName="renderTour"
          :steps="tourSteps"
          :show="showTour"
          :hideHelpIcon="hideHelpIcon"
        ></data-tour>
      </v-row>

      <v-row v-if="promptCompleteDialog">
        <v-dialog v-model="promptCompleteDialog" persistent max-width="290">
          <v-card>
            <v-card-title>
              <span class="headline">Set To Complete?</span>
            </v-card-title>
            <v-card-text> Would you like to set this batch to complete? </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="promptCompleteLoading"
                :loading="promptCompleteLoading"
                @click="setBatchCompleteFromPrompt"
                >Yes</v-btn
              >
              <v-btn
                :disabled="promptCompleteLoading"
                :loading="promptCompleteLoading"
                @click="promptCompleteDialog = false"
                >No</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';
import stringHelperMixins from '@/mixins/string-helper-mixins';
import handHeldMixins from '@/mixins/hand-held-mixins';
import blobUrlMixins from '@/mixins/blob-url-mixins';
import { mapState } from 'vuex';
import { PaperStockLoadingImages } from './PaperStockLoadingImages.vue';
import { ContainerClient } from '@azure/storage-blob';

const RenderProgress = Vue.component('render-progress', {
  mixins: [stringHelperMixins, blobUrlMixins, handHeldMixins],
  props: {
    renderLabelsData: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  components: {
    PaperStockLoadingImages,
  },
  data() {
    return {
      batchHash: null,
      wsConn: null,
      renderings: null,
      completeRenderings: [],
      currentCarouselItemName: null,
      batchInfoId: null,
      batchInfoComplete: false,
      batchInfoCompleteLoading: false,
      adalHelper: new AdalHelpers(),
      startError: false,
      autoCompleteBatch: false,
      showCompleteToggle: true,
      carouselModel: null,
      circularFirstRecTimeout: 3600000,
      showPatienceMessage: false,
      renderPatienceMessageTimeout: 60000,
      showRenderSavedMessage: false,
      showTour: false,
      hideHelpIcon: true,
      printDialogActive: false,
      paperStockDialog: false,
      promptCompleteDialog: false,
      promptCompleteLoading: false,
      printLoading: false,
      renderStuckAfter: 60 * 1000,
      tourSteps: [
        {
          target: '#renderProgresses > div:last-child',
          header: {
            title: 'Selecting Print Sheet',
          },
          content: "Click these circular progress meters to view a print sheet once it's complete.",
          placement: 'bottom',
        },
        {
          target: '.v-window__next',
          header: {
            title: 'Selecting Print Sheet',
          },
          content: 'You can also click these arrows to cycle through print sheets.',
          placement: 'left',
        },
      ],
    };
  },
  computed: {
    ...mapState('app', ['username']),
    selectedRendering() {
      if (this.carouselModel !== null) {
        return this.completeRenderings[this.carouselModel];
      }
      return null;
    },
    selectedRenderingPdfUrl() {
      if (this.selectedRendering) {
        return this.selectedRendering.blobUrl;
      }
      return null;
    },
    selectedRenderingIsPortrait() {
      if (this.selectedRendering) {
        return this.selectedRendering.isPortrait;
      }

      return null;
    },
    selectedRenderingTotalPages() {
      if (this.selectedRendering) {
        return this.selectedRendering.totalPages;
      }

      return 0;
    },
    selectedRenderingPartialFiles() {
      if (
        this.selectedRendering &&
        !!this.selectedRendering.partialFiles &&
        this.selectedRendering.partialFiles.length > 0
      ) {
        const partialFiles = this.selectedRendering.partialFiles;
        return partialFiles.sort();
      }

      return null;
    },
    selectedRenderingShowPartials() {
      if (this.selectedRendering) {
        return (
          !!this.selectedRendering.partialFiles && this.selectedRendering.partialFiles.length > 1
        );
      }

      return false;
    },
    selectedRenderingPdfFileName() {
      if (this.selectedRenderingPdfUrl) {
        const pdfUrlParts = this.selectedRenderingPdfUrl.split('/');
        return pdfUrlParts[pdfUrlParts.length - 1];
      }
      return null;
    },
    showStuckMessage() {
      let showMessage = true;

      // only show render is stuck message when they all are
      // since we limit the number of concurrent renders per batch to 2
      // the stuck message was being shown while the other renders were waiting to run
      if (this.renderings && this.renderings.length > 0) {
        for (var render of this.renderings) {
          if (render.isStuck !== true) {
            showMessage = false;
            break;
          }
        }
      } else {
        showMessage = false;
      }

      if (showMessage === true) {
        this.$appInsights.trackException({
          exception: new Error('render is stuck'),
          properties: {
            text: 'render is stuck',
            username: this.username,
            storeNumber: this.storeNumber,
            route: this.$route,
            id: 'c4a7be5e-206d-41be-b0c0-df16aea6a1a5',
            renderings: this.renderings,
          },
        });
      }

      return showMessage;
    },
  },
  methods: {
    printFinished() {
      this.printLoading = false;
    },
    progressClicked(render) {
      const completedIx = this.getCompletedIndexRenderById(render.id);
      if (completedIx > -1) {
        this.carouselModel = completedIx;
      }

      // if the tour popup is showing, and they click a progress circle
      // advance to the next step in the tour.
      if (this.completeRenderings.length > 1) {
        this.showTour = true;
        this.hideHelpIcon = false;
      }
      this.setCarouselItemName(completedIx);
    },
    getRenderById(id) {
      return this.renderings.filter(r => r.id === id)[0];
    },
    getCompletedIndexRenderById(id) {
      return this.completeRenderings.findIndex(r => r.renderId === id);
    },
    progressUpdated(progress) {
      if (this.renderings && this.renderings.length > 0) {
        for (const render of this.renderings) {
          if (render.id === progress.id) {
            render.lastProgressUpdated = new Date();

            // i hate it, but this works, otherwise the showStuckMessage won't fire
            const updateIsStuck = r => {
              Vue.set(r, 'isStuck', false);
            };
            setTimeout(() => {
              updateIsStuck.bind(this)(render);
            }, 0);
          }
        }
      }

      // clear this no matter what, either it's done and we'll clear it,
      // or we're getting messages from the render api we don't need to keep checking
      const render = this.getRenderById(progress.id);
      clearInterval(render.checkForCompleteIntervalId);

      if (progress && progress.complete >= 100 && progress.additionalData) {
        clearInterval(render.lastProgressIntervalId);
        // add displayOrder to here and sort
        if (this.completeRenderings.findIndex(x => x.renderId === progress.id) === -1) {
          const itemsCount = this.completeRenderings.push({
            renderId: progress.id,
            blobUrl: progress.additionalData.pdfUrl,
            description: render.description,
            displayOrder: render.displayOrder,
            isPortrait: render.isPortrait,
            totalPages: progress.additionalData.totalPages,
            totalLabels: progress.additionalData.totalLabels,
            partialFiles: progress.additionalData.partialFiles,
            cacheBuster: new Date().getTime().toString(),
          });

          if (itemsCount === 1) {
            this.setCarouselItemName(0);
          } else if (itemsCount > 1) {
            this.showTour = true;
            this.hideHelpIcon = false;
          }

          // determine the lowest display order value
          const minDisplayRender = Math.min(...this.renderings.map(x => x.displayOrder));
          // if this is the lowest value one, go to it
          // or if the carouselModel is null, set the model
          if (render.displayOrder === minDisplayRender || this.carouselModel === null) {
            // set to the lowest render
            this.carouselModel = itemsCount - 1;
            this.setCarouselItemName(this.carouselModel);
          }

          // all renders are done?
          if (
            itemsCount === this.renderings.length &&
            this.autoCompleteBatch === true &&
            this.printDialogActive === false
          ) {
            this.setBatchComplete(true, 'auto_complete');
          }
        }
      }
    },
    carouselChanged(item) {
      this.setCarouselItemName(item);
    },
    setCarouselItemName(completeRenderingIx) {
      this.currentCarouselItemName = this.completeRenderings[completeRenderingIx].description;
    },
    async setBatchComplete(completed, origin) {
      if (this.batchInfoId && this.batchInfoId > 0) {
        const completedPayload = {
          completed,
          completedOn: null,
          completedBy: null,
          completedOrigin: null,
        };
        if (completed === true) {
          completedPayload.completedOn = moment.utc();
          completedPayload.completedBy = this.username;
          completedPayload.completedOrigin = origin;
        }

        await this.$authApi.http.patch(`label/batchinfo/${this.batchInfoId}`, completedPayload);
      }
    },
    async updateBatchInfoComplete(origin) {
      try {
        this.batchInfoCompleteLoading = true;
        await this.setBatchComplete(this.batchInfoComplete, origin);

        if (this.renderLabelsData.additionalCompleteUpdate) {
          switch (this.renderLabelsData.additionalCompleteUpdate.type) {
            case 'handHeld': {
              const handheldId = this.renderLabelsData.additionalCompleteUpdate.ids[0];

              await this.setHandHeldCompletedStatus(handheldId, this.batchInfoComplete);

              // now clear the store, since that batch won't exist anymore
              this.$store.state.handheldprint.handHeldHeaderData = null;
              this.$store.state.handheldprint.createdBatchInfo = null;

              break;
            }
            case 'batchCheck': {
              // now clear the store, since that batch won't exist anymore
              this.$store.state.batchcheck.batchInfo = null;
              this.$store.state.batchcheck.selectedBatchInfo = null;

              break;
            }
            default: {
              break;
            }
          }
        }
      } catch (err) {
        this.$error(err);
        // Update did not succeed, set back
        this.batchInfoComplete = !this.batchInfoComplete;
        this.$emit('snackbar-error', {
          text: 'Error setting batch to complete.',
          err,
          id: '74d852cf-9e1e-44cd-ae5a-406e0f392e40',
        });
      } finally {
        this.batchInfoCompleteLoading = false;
      }
    },
    setError(extraErrorMessage) {
      this.startError = true;
      let extraErrorMessageFormatted = null;
      if (
        extraErrorMessage !== null &&
        extraErrorMessage !== undefined &&
        typeof extraErrorMessage === 'string'
      ) {
        extraErrorMessageFormatted = extraErrorMessage;
      }
      this.$emit('snackbar-error', {
        text: `${'Error rendering labels. '}${extraErrorMessageFormatted || ''}`,
        err: new Error(extraErrorMessage),
        id: '77680140-2c52-4274-8275-abc28eb202e2',
      });

      // If this is an autoCompleteBatch, set to complete even though it's in an error state.
      if (this.autoCompleteBatch === true && this.printDialogActive === false) {
        this.setBatchComplete(true, 'auto_complete');
      }
    },
    async printed() {
      this.printLoading = true;

      // prompt the user if they want to set the batch to complete
      if (this.showCompleteToggle === true) {
        const currentBatch = (await this.$authApi.http.get(`label/batchinfo/${this.batchInfoId}`))
          .data;

        let showCompleteDialog = currentBatch.completed === false;

        if (
          showCompleteDialog === false &&
          this.renderLabelsData.additionalCompleteUpdate &&
          this.renderLabelsData.additionalCompleteUpdate.type === 'handHeld'
        ) {
          const handheldId = this.renderLabelsData.additionalCompleteUpdate.ids[0];

          const currentHandHeldBatch = (
            await this.$authApi.http.get(`label/handheldmain/${handheldId}`)
          ).data;

          showCompleteDialog = currentHandHeldBatch.completed === false;
        }

        if (showCompleteDialog === true) {
          this.promptCompleteDialog = true;
        }
      }
    },
    async setBatchCompleteFromPrompt() {
      this.promptCompleteLoading = true;
      try {
        this.batchInfoComplete = true;
        await this.updateBatchInfoComplete('print_dialog');
        this.promptCompleteLoading = false;
        this.promptCompleteDialog = false;
      } catch (err) {
        this.promptCompleteLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error setting batch to complete.',
          err,
          id: '4634e20b-f5b9-4f1e-96b4-51d2b02d5a55',
        });
      }
    },
    setRenderToComplete(render) {
      this.$authApi.http.get(`label/render/blob/metadata/${render.blobFileName}`).then(result => {
        try {
          const metadata = result.data;

          let totalPages = null;
          let totalLabels = null;

          if (
            metadata &&
            isNaN(metadata.totalPages) === false &&
            isNaN(metadata.totalLabels) === false
          ) {
            totalPages = Number(metadata.totalPages);
            totalLabels = Number(metadata.totalLabels);
          }

          this.progressUpdated({
            id: render.id,
            name: 'Rendering Labels',
            description: 'Complete.',
            complete: 100,
            additionalData: {
              pdfUrl: `${this.$storageHost}/${render.blobFileName}`,
              totalPages,
              totalLabels,
              partialFiles: [],
            },
            source: 'render_labels',
          });
        } catch (e) {
          this.$appInsights.trackException({
            exception: e,
            properties: {
              text: `Error getting properies for PDF '${render.blobFileName}'`,
              username: this.username,
              storeNumber: this.storeNumber,
              route: this.$route,
              id: 'eb4bfcb9-3139-42df-b4f8-ba499d6fe467',
            },
          });
        }
      });
    },
  },
  async created() {
    // Check to see if we have all the data we need
    if (
      this.renderLabelsData &&
      this.renderLabelsData.batchInfoId !== undefined &&
      this.renderLabelsData.startingPosition !== undefined &&
      this.renderLabelsData.batchHash !== undefined
    ) {
      this.batchInfoId = this.renderLabelsData.batchInfoId;
      this.batchHash = this.renderLabelsData.batchHash;
      this.autoCompleteBatch = this.renderLabelsData.autoCompleteBatch;
      this.showCompleteToggle = this.renderLabelsData.showCompleteToggle;

      // ensure the right username is provided
      this.renderLabelsData.username = this.username;

      const adalToken = await this.adalHelper.getJwtToken(this.$authApi);

      let recMessage = false;
      // wait for the render message, indicating it has started
      const onmessage = event => {
        recMessage = true;
        const renderData = JSON.parse(event.data);
        this.wsConn.close();

        if (
          renderData.failed === true ||
          !renderData ||
          !renderData.renderings ||
          renderData.renderings.length === 0
        ) {
          this.setError(renderData.errorMessage);
        } else {
          this.renderings = renderData.renderings.sort((x, y) => x.displayOrder - y.displayOrder);

          // add a forceComplete, "lastProgressUpdated", interval id, and isStuck prop
          for (const render of this.renderings) {
            Vue.set(render, 'isStuck', false);

            render.forceComplete = false;
            render.lastProgressUpdated = new Date();
            render.lastProgressIntervalFunc = r => {
              Vue.set(
                r,
                'isStuck',
                new Date().getTime() - r.lastProgressUpdated.getTime() > this.renderStuckAfter,
              );
            };
            render.lastProgressIntervalId = setInterval(() => {
              render.lastProgressIntervalFunc.bind(this)(render);
            }, Math.round(this.renderStuckAfter / 2) + 1000);

            render.checkForCompleteWithoutMeta = 0;
            render.checkForCompleteFunc = async r => {
              const pdfUrl = `${this.$storageHost}/${r.blobFileName}`;
              const urlParts = pdfUrl.split('/');

              const containerClient = new ContainerClient(
                urlParts.slice(0, urlParts.length - 1).join('/'),
              );
              const blobClient = containerClient.getBlobClient(urlParts[urlParts.length - 1]);

              const blobExists = await blobClient.exists();

              if (blobExists === true) {
                render.checkForCompleteWithoutMeta += 1;

                const metadata = (
                  await this.$authApi.http.get(`label/render/blob/metadata/${r.blobFileName}`)
                ).data;
                if (
                  render.checkForCompleteWithoutMeta >= 6 ||
                  (metadata &&
                    isNaN(metadata.totalPages) === false &&
                    isNaN(metadata.totalLabels) === false)
                ) {
                  r.forceComplete = true;
                  this.setRenderToComplete(r);
                }
              }
            };

            render.checkForCompleteIntervalId = -1;
            if (render.blobExists === false) {
              render.checkForCompleteIntervalId = setInterval(() => {
                render.checkForCompleteFunc.bind(this)(render);
              }, this.$checkBlobExistsTimeMs);
            }

            // when the file already exists, just set it to complete
            if (render.blobExists === true && this.renderLabelsData.force === false) {
              render.forceComplete = true;
              this.setRenderToComplete(render);
            }
          }

          this.$emit('started');
        }
      };

      const onclose = () => {
        if (recMessage === false) {
          this.setError();
        }
      };

      const onOpen = async () => {
        try {
          await this.$authApi.http.post('servicebus/renderlabels', this.renderLabelsData);
          if (this.renderLabelsData.excludeFromRenderingProgress === false) {
            this.showRenderSavedMessage = true;
          }
          if (this.batchInfoId && this.batchInfoId > 0) {
            const result = await this.$authApi.http.get(`label/batchinfo/${this.batchInfoId}`);
            this.batchInfoComplete = result.data.completed === 1;
          }
        } catch (e) {
          this.setError();
        }
      };

      this.wsConn = new PogonaWebSocket(
        this.$apiBasePath,
        'render_start',
        this.batchHash,
        adalToken,
        onclose,
        onmessage,
        this.$renderStartWaitTimeMs,
        onOpen,
      );

      setTimeout(() => {
        this.showPatienceMessage = true;
      }, this.renderPatienceMessageTimeout);
    } else {
      this.setError();
    }
  },
  watch: {
    carouselModel: {
      handler: function (val) {
        if (this.completeRenderings[val] && this.completeRenderings[val].cacheBuster) {
          this.completeRenderings[val].cacheBuster = new Date().getTime().toString();
        }
      },
    },
  },
});

export default RenderProgress;
export { RenderProgress };
</script>

<style scoped>
.v-window__prev,
.v-window__next {
  background-color: #212121;
  padding: 0 7px;
  border-radius: 27px;
}
.v-window__prev {
  padding: 0 7px;
}
.v-window__next {
  margin-right: 30px !important;
}

.pdf-carousel,
.batch-alert {
  max-width: 71em;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
