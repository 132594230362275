<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="dialogClick"> Manage Employees </v-btn>
      </template>
      <v-card :loading="processing">
        <v-card-title>
          <span class="headline">Employee</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedEmployee"
                  label="Select Employee"
                  :items="employeeDropDownData"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="First Name:"
                  v-model="employee.FirstName"
                  :error-messages="errorMessages.FirstName"
                  @blur="validate('FirstName')"
                  @change="validate('FirstName')"
                  counter="50"
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Last Name:"
                  v-model="employee.LastName"
                  :error-messages="errorMessages.LastName"
                  @blur="validate('LastName')"
                  @change="validate('LastName')"
                  counter="50"
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Cell:"
                  v-model="employee.Cell"
                  :error-messages="errorMessages.Cell"
                  @blur="validate('Cell')"
                  @change="validate('Cell')"
                  counter="10"
                  maxlength="10"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email:"
                  v-model="employee.Email"
                  :error-messages="errorMessages.Email"
                  @blur="validate('Email')"
                  @change="validate('Email')"
                  counter="50"
                  maxlength="50"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteClicked" :disabled="!deleteEnabled"
            >Delete</v-btn
          >
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveClicked">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmDelete" max-width="400px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete {{ this.employee.FirstName }} {{ this.employee.LastName }}
        </v-card-title>
        <v-card-actions>
          <v-btn @click="showConfirmDelete = false">No</v-btn>
          <v-btn @click="deleteConfirmed">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <p v-for="e in validationErrors" v-bind:key="e">
        {{ e }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pcldto from '@psp/pcldto';
import * as yup from 'yup';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {},
})
export default class PclEmployee extends Vue {
  @pclModule.State(ps.employees) employees!: pcldto.pclEmployee[];
  @pclModule.Action(pa.getEmployees) getEmployees!: () => Promise<any>;
  @pclModule.Action(pa.createEmployee) createEmployee!: (
    payload: pcldto.pclEmployee,
  ) => Promise<unknown>;
  @pclModule.Action(pa.updateEmployee) updateEmployee!: (
    payload: pcldto.pclEmployee,
  ) => Promise<unknown>;
  @pclModule.Action(pa.deleteEmployee) deleteEmployee!: (payload: number) => Promise<unknown>;

  private loadingEmployees = false;
  private processing = false;
  private selectedEmployee: any = 0;
  private dialog = false;
  private showSnackbar = false;
  private snackbarMessage = '';
  private snackbarColor = '';
  private employee: pcldto.pclEmployee = new pcldto.pclEmployee(null, null, 0, '', '');
  private errorMessages: ps.PclEmployeeValidationMessages = new ps.PclEmployeeValidationMessages();
  private validationErrors = [];
  private showConfirmDelete = false;
  private confirmDelete = false;

  private employeeSchema: any = yup.object().shape({
    FirstName: yup.string().trim().required(),
    LastName: yup.string().trim().required(),
    Email: yup.string().trim().email().nullable().optional(),
    Cell: yup
      .string()
      .trim()
      .nullable()
      .matches(/^(\d{10}|)$/, 'Cell must be 10 digits or blank')
      .max(10)
      .optional(),
  });

  private validate(field: any) {
    this.errorMessages[field] = [];
    this.employeeSchema.validateAt(field, this.employee).catch((err: any) => {
      this.errorMessages[field] = err.errors;
    });
  }

  private clearEmployee() {
    this.selectedEmployee = 0;
    this.employee = new pcldto.pclEmployee(null, null, 0, '', '');
    this.errorMessages = new ps.PclEmployeeValidationMessages();
  }

  private dialogClick() {
    this.loadingEmployees = true;
    this.getEmployees().then(() => (this.loadingEmployees = false));
    this.clearEmployee();
  }

  get employeeDropDownData(): any {
    const data = [] as unknown[];
    data.push({ name: '(Add New)', value: 0 });
    this.employees.map(e =>
      data.push({ name: `${e.FirstName} ${e.LastName}`, value: e.EmployeeId }),
    );
    return data;
  }

  private showSnackbarMessage(message: string, color: string) {
    this.snackbarMessage = message;
    this.snackbarColor = color;
    this.showSnackbar = true;
  }

  @Watch('selectedEmployee') onEmployeeChange(newEmp: number) {
    if (newEmp === 0) {
      this.employee = new pcldto.pclEmployee(null, null, 0, '', '');
    } else {
      const found = this.employees.find(e => e.EmployeeId == newEmp);
      if (found) {
        this.employee = found;
      }
    }
  }

  get deleteEnabled() {
    return this.employee?.EmployeeId > 0;
  }

  private deleteConfirmed() {
    this.showConfirmDelete = false;
    if (this.employee.EmployeeId > 0) {
      this.deleteEmployee(this.employee.EmployeeId)
        .then(() => {
          this.processing = false;
          this.clearEmployee();
          this.showSnackbarMessage('Employee deleted', 'success');
          this.$emit('save-complete');
          this.dialog = false;
        })
        .catch(() => {
          this.processing = false;
          this.showSnackbarMessage('Employee deletion failed', 'error');
        });
    }
  }

  private deleteClicked() {
    this.showConfirmDelete = true;
  }

  private async saveClicked() {
    this.employeeSchema
      .validate(this.employee)
      .then((e: pcldto.pclEmployee) => {
        this.employee = e;
        this.validationErrors = [];
        this.processing = true;
        if (this.employee.EmployeeId == 0) {
          this.createEmployee(this.employee)
            .then(() => {
              this.processing = false;
              this.clearEmployee();
              this.showSnackbarMessage('Created Employee', 'success');
              this.$emit('save-complete');
              this.dialog = false;
            })
            .catch(() => {
              this.processing = false;
              this.showSnackbarMessage('Employee save failed', 'error');
            });
        } else {
          this.updateEmployee(this.employee)
            .then(() => {
              this.processing = false;
              this.clearEmployee();
              this.showSnackbarMessage('Updated Employee', 'success');
              this.$emit('save-complete');
              this.dialog = false;
            })
            .catch(() => {
              this.processing = false;
              this.showSnackbarMessage('Employee save failed', 'error');
            });
        }
      })
      .catch(err => {
        this.validationErrors = err.errors;
        this.showSnackbarMessage('Cannot save employee, please fix the errors.', 'error');
      });
  }
}
</script>
