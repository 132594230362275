<template>
  <v-row>
    <v-col>
      <v-dialog :max-width="error ? '450px' : '600px'" v-model="showImageDialog">
        <template v-slot:activator="{}">
          <a
            class="text-decoration-underline"
            :href="url"
            @click.stop.prevent="showImageDialog = true"
            >{{ fileName }}</a
          >
        </template>
        <v-row
          :class="[loaded ? 'checkerboard' : '', error ? 'white-background' : '', 'ma-0']"
          justify="center"
        >
          <data-authed-image
            v-if="!error"
            @load="loaded = true"
            @error="error = true"
            :src="url"
            :headers="imageHeaders"
            transition="none"
          />
          <span v-else class="ma-6">Problem loading image. Does it need to be uploaded?</span>
        </v-row>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import Vue, { computed, ref, defineComponent, watch } from 'vue';

defineComponent({
  name: 'CouponImageDialog',
});

const showImageDialog = ref(false);
const loaded = ref(false);
const error = ref(false);
const cacheBuster = ref(new Date().getTime());
const imageHeaders = {
  'x-api-host': Vue.prototype.$apiHostAndProtocol,
};

const props = defineProps({
  couponImage: {
    type: String,
    required: true,
  },
});

const fileName = computed(() => {
  return props.couponImage;
});

const url = computed(() => {
  return `/ecom2/couponimage/${props.couponImage}?_=${cacheBuster.value}`;
});

watch(
  () => showImageDialog.value,
  () => {
    error.value = false;
    loaded.value = false;
    cacheBuster.value = new Date().getTime();
  },
);
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.checkerboard {
  background-image: url('../../assets/checkboard.png');
  background-repeat: repeat;
  overflow: hidden;
  max-width: 600px;
}
.white-background {
  background-color: white;
}
.center {
  text-align: center;
}
</style>
