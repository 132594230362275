<template>
  <core-signs-labels-view-template title="Cost and Movement">
    <core-view-section title="Items">
      <activated-labels
        v-model="labelItems"
        :showClearButton="false"
        :multiselect="false"
        :showNonActivatedToggle="true"
        :clearSelectedOnSearch="true"
        :nonActivatedToggle.sync="nonActivatedToggle"
        @snackbar-error="$emit('snackbar-error', $event)"
        :search.sync="search"
        :selectedBarcodeSetups.sync="selectedBarcodeSetups"
      ></activated-labels>
      <v-btn v-if="showExpandAllButton" @click="expandOrCollapse"
        >{{ canExpandAll ? 'Expand' : 'Collapse' }} All Sections</v-btn
      >
    </core-view-section>
    <core-view-section
      v-model="pricingHistoryExpanded"
      class="history-section"
      title="Pricing History"
      v-if="labelItems && labelItems.selected && labelItems.selected.length > 0"
      :expandable="true"
      :loading="pricingHistoryLoading"
    >
      <v-row>
        <v-col cols="12" xl="6" lg="6">
          <v-card outlined>
            <v-card-subtitle>Base Price</v-card-subtitle>
            <v-data-table
              :height="$vuetify.breakpoint.lgAndUp ? minHistoryCardHeight : ''"
              v-if="basePriceHistory"
              v-model="basePriceHistory.selected"
              :headers="basePriceHistory.headers"
              :items="basePriceHistory.items"
              :loading="basePriceHistory.loading"
              :disable-pagination="true"
              :options.sync="basePriceHistory.options"
              :fixed-header="true"
              @update:sort-by="basePriceHistory.get()"
              item-key="basePriceId"
              class="elevation-1 scrollable"
            >
              <template v-slot:item.beginDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.endDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.signLabelRetail="{ value }">{{
                value | formatCurrency
              }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="basePriceHistory"></infinite-paganation>
          </v-card>
        </v-col>
        <v-col cols="12" xl="6" lg="6">
          <v-card outlined>
            <v-card-subtitle>Discount With Card</v-card-subtitle>
            <v-data-table
              :height="$vuetify.breakpoint.lgAndUp ? minHistoryCardHeight : ''"
              v-if="swcHistory"
              v-model="swcHistory.selected"
              :headers="swcHistory.headers"
              :items="swcHistory.items"
              :loading="swcHistory.loading"
              :disable-pagination="true"
              :options.sync="swcHistory.options"
              :fixed-header="true"
              @update:sort-by="swcHistory.get()"
              item-key="discountPriceWcid"
              class="elevation-1 scrollable"
            >
              <template v-slot:item.beginDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.endDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.discountAmount="{ value }">{{
                value | formatCurrency
              }}</template>
              <template v-slot:item.discountPercent="{ value }">{{
                value | formatPercent(2)
              }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="swcHistory"></infinite-paganation>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="6" lg="6">
          <v-card outlined>
            <v-card-subtitle>Buy More Save More</v-card-subtitle>
            <v-data-table
              :height="$vuetify.breakpoint.lgAndUp ? minHistoryCardHeight : ''"
              v-if="bmsHistory"
              v-model="bmsHistory.selected"
              :headers="bmsHistory.headers"
              :items="bmsHistory.items"
              :loading="bmsHistory.loading"
              :disable-pagination="true"
              :options.sync="bmsHistory.options"
              :fixed-header="true"
              @update:sort-by="bmsHistory.get()"
              item-key="discountPriceBmsid"
              class="elevation-1 scrollable"
              show-expand
              :single-expand="true"
              :expanded.sync="bmsExpanded"
            >
              <template v-slot:item.beginDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.endDate="{ value }">{{ value | formatDate }}</template>

              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length">
                  <v-data-table
                    :headers="bmsLevelsHeaders"
                    :items="item.pspDiscountPriceBmsLevels"
                    dense
                  >
                    <template v-slot:item.level1Disc="{ value }">{{
                      formatBmsDiscount(value, item.discountType)
                    }}</template>
                    <template v-slot:item.level2Disc="{ value }">{{
                      formatBmsDiscount(value, item.discountType)
                    }}</template>
                    <template v-slot:item.level3Disc="{ value }">{{
                      formatBmsDiscount(value, item.discountType)
                    }}</template>
                    <template v-slot:item.level4Disc="{ value }">{{
                      formatBmsDiscount(value, item.discountType)
                    }}</template>
                    <template v-slot:item.level5Disc="{ value }">{{
                      formatBmsDiscount(value, item.discountType)
                    }}</template>
                    <template v-slot:item.level6Disc="{ value }">{{
                      formatBmsDiscount(value, item.discountType)
                    }}</template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
            <infinite-paganation :pogonaTable="bmsHistory"></infinite-paganation>
          </v-card>
        </v-col>
        <v-col cols="12" xl="6" lg="6">
          <v-card outlined>
            <v-card-subtitle>Discount No Card</v-card-subtitle>
            <v-data-table
              :height="$vuetify.breakpoint.lgAndUp ? minHistoryCardHeight : ''"
              v-if="sncHistory"
              v-model="sncHistory.selected"
              :headers="sncHistory.headers"
              :items="sncHistory.items"
              :loading="sncHistory.loading"
              :disable-pagination="true"
              :options.sync="sncHistory.options"
              :fixed-header="true"
              @update:sort-by="sncHistory.get()"
              item-key="discountPriceNcid"
              class="elevation-1 scrollable"
            >
              <template v-slot:item.beginDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.endDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.discountAmount="{ value }">{{
                value | formatCurrency
              }}</template>
              <template v-slot:item.discountPercent="{ value }">{{
                value | formatPercent(2)
              }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="sncHistory"></infinite-paganation>
          </v-card>
        </v-col>
      </v-row>
    </core-view-section>
    <core-view-section
      v-model="costHistoryExpanded"
      class="history-section"
      title="Cost History"
      v-if="labelItems && labelItems.selected && labelItems.selected.length > 0"
      :expandable="true"
      :loading="baseCostLoading"
    >
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-subtitle>Base Cost</v-card-subtitle>
            <v-data-table
              v-if="baseCost"
              v-model="baseCost.selected"
              :headers="baseCost.headers"
              :items="baseCost.items"
              :loading="baseCost.loading"
              :disable-pagination="true"
              :options.sync="baseCost.options"
              :fixed-header="true"
              @update:sort-by="baseCost.get()"
              item-key="costId"
              class="elevation-1 scrollable"
            >
              <template v-slot:item.beginDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.endDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.tradeAgreementCost="{ value }">{{
                value | formatCurrency
              }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="baseCost"></infinite-paganation>
          </v-card>
        </v-col>
      </v-row>
    </core-view-section>
    <core-view-section
      v-model="movementHistoryExpanded"
      class="history-section"
      title="Movement History"
      v-if="labelItems && labelItems.selected && labelItems.selected.length > 0"
      :expandable="true"
      :loading="dailyMovementHistoryLoading"
    >
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-subtitle>Daily Movement</v-card-subtitle>
            <v-data-table
              v-if="dailyMovementHistory"
              v-model="dailyMovementHistory.selected"
              :headers="dailyMovementHistory.headers"
              :items="dailyMovementHistory.items"
              :loading="dailyMovementHistory.loading"
              :disable-pagination="true"
              :options.sync="dailyMovementHistory.options"
              :fixed-header="true"
              @update:sort-by="dailyMovementHistory.get()"
              item-key="basePriceId"
              class="elevation-1 scrollable"
            >
              <template v-slot:item.movementDate="{ value }">{{ value | formatDate }}</template>
              <template v-slot:item.totalRetail="{ value }">{{ value | formatCurrency }}</template>
              <template v-slot:item.totalMovement="{ value }">{{ value | formatDecimal }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="dailyMovementHistory"></infinite-paganation>
          </v-card>
        </v-col>
      </v-row>
    </core-view-section>
  </core-signs-labels-view-template>
</template>

<script>
/* eslint-disable guard-for-in */
import { mapState } from 'vuex';
import ActivatedLabels from '../ActivatedLabelsComponent.vue';
import f from 'odata-filter-builder';
import PogonaDataTable from '@/utils/PogonaDataTable';

export default {
  components: {
    ActivatedLabels,
  },
  data() {
    return {
      labelItems: this.$store.state.costmovementhistory.labelItems || {},
      basePriceHistory: this.$store.state.costmovementhistory.basePriceHistory || {},
      swcHistory: this.$store.state.costmovementhistory.swcHistory || {},
      sncHistory: this.$store.state.costmovementhistory.sncHistory || {},
      bmsHistory: this.$store.state.costmovementhistory.bmsHistory || {},
      dailyMovementHistory: this.$store.state.costmovementhistory.dailyMovementHistory || {},
      baseCost: this.$store.state.costmovementhistory.baseCost || {},
      minHistoryCardHeight: '20em',
      bmsLevelsHeaders: [
        {
          text: 'Lvl1 Qty',
          value: 'level1Qty',
        },
        {
          text: 'Lvl1 Disc',
          value: 'level1Disc',
        },
        {
          text: 'Lvl2 Qty',
          value: 'level2Qty',
        },
        {
          text: 'Lvl2 Disc',
          value: 'level2Disc',
        },
        {
          text: 'Lvl3 Qty',
          value: 'level3Qty',
        },
        {
          text: 'Lvl3 Disc',
          value: 'level3Disc',
        },
        {
          text: 'Lvl4 Qty',
          value: 'level4Qty',
        },
        {
          text: 'Lvl4 Disc',
          value: 'level4Disc',
        },
        {
          text: 'Lvl5 Qty',
          value: 'level5Qty',
        },
        {
          text: 'Lvl5 Disc',
          value: 'level5Disc',
        },
        {
          text: 'Lvl6 Qty',
          value: 'level6Qty',
        },
        {
          text: 'Lvl6 Disc',
          value: 'level6Disc',
        },
      ],
    };
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    search: {
      get() {
        return this.$store.state.costmovementhistory.search || '';
      },
      set(val) {
        this.$store.commit('costmovementhistory/search', val);
      },
    },
    pricingHistoryExpanded: {
      get() {
        return this.$store.state.costmovementhistory.pricingHistoryExpanded || false;
      },
      set(val) {
        this.$store.commit('costmovementhistory/pricingHistoryExpanded', val);
      },
    },
    costHistoryExpanded: {
      get() {
        return this.$store.state.costmovementhistory.costHistoryExpanded || false;
      },
      set(val) {
        this.$store.commit('costmovementhistory/costHistoryExpanded', val);
      },
    },
    movementHistoryExpanded: {
      get() {
        return this.$store.state.costmovementhistory.movementHistoryExpanded || false;
      },
      set(val) {
        this.$store.commit('costmovementhistory/movementHistoryExpanded', val);
      },
    },
    bmsExpanded: {
      get() {
        return this.$store.state.costmovementhistory.bmsExpanded || [];
      },
      set(val) {
        this.$store.commit('costmovementhistory/bmsExpanded', val);
      },
    },
    nonActivatedToggle: {
      get() {
        return this.$store.state.costmovementhistory.nonActivatedToggle || false;
      },
      set(val) {
        this.$store.commit('costmovementhistory/nonActivatedToggle', val);
      },
    },
    selectedBarcodeSetups: {
      get() {
        return (
          this.$store.state.costmovementhistory.selectedBarcodeSetups || [
            'PrimaryUPC',
            'PLU',
            'Case UPC',
          ]
        );
      },
      set(val) {
        this.$store.commit('costmovementhistory/selectedBarcodeSetups', val);
      },
    },
    showExpandAllButton() {
      return (
        this.basePriceHistory &&
        this.swcHistory &&
        this.sncHistory &&
        this.bmsHistory &&
        this.dailyMovementHistory &&
        this.baseCost
      );
    },
    canExpandAll() {
      return (
        this.pricingHistoryExpanded === false ||
        this.costHistoryExpanded === false ||
        this.movementHistoryExpanded === false
      );
    },
    selectedLabelItem() {
      if (this.labelItems && this.labelItems.selected && this.labelItems.selected.length === 1) {
        return this.labelItems.selected[0];
      }
      return null;
    },
    pricingHistoryLoading() {
      if (this.basePriceHistory && this.swcHistory && this.sncHistory && this.bmsHistory) {
        return (
          this.basePriceHistory.loading ||
          this.swcHistory.loading ||
          this.sncHistory.loading ||
          this.bmsHistory.loading
        );
      }
      return false;
    },
    dailyMovementHistoryLoading() {
      if (this.dailyMovementHistory) {
        return this.dailyMovementHistory.loading;
      }
      return false;
    },
    baseCostLoading() {
      if (this.baseCost) {
        return this.baseCost.loading;
      }
      return false;
    },
  },
  methods: {
    expandOrCollapse() {
      let expand = true;
      if (this.canExpandAll === false) {
        expand = false;
      }

      this.pricingHistoryExpanded = expand;
      this.costHistoryExpanded = expand;
      this.movementHistoryExpanded = expand;
    },
    initBasePriceHistory() {
      this.basePriceHistory = new PogonaDataTable({
        headers: [
          {
            text: 'Begin Date',
            value: 'beginDate',
          },
          {
            text: 'End Date',
            value: 'endDate',
          },
          {
            text: 'Retail',
            value: 'signLabelRetail',
          },
          {
            text: 'R/Type',
            value: 'salesUom',
          },
          {
            text: 'Base Description',
            value: 'description',
          },
          {
            text: 'Item #',
            value: 'itemNumber',
          },
        ],
        keys: ['basePriceId'],
        defaultAllSelected: false,
        multiselect: false,
        baseUrl: 'label/baseprice',
        defaultFilter: f('and')
          .eq('storeNumber', this.storeNumber)
          .eq('itemNumber', this.selectedLabelItem.itemId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['beginDate', 'salesUom'], sortDesc: [true] },
        isInfinite: true,
      });

      this.basePriceHistory.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting base price history',
          err,
          id: 'fe83354c-7517-49f3-ac3b-0e252be440ce',
        });
      });

      this.basePriceHistory.get();
    },
    initSwcHistory() {
      this.swcHistory = new PogonaDataTable({
        headers: [
          {
            text: 'Begin Date',
            value: 'beginDate',
          },
          {
            text: 'End Date',
            value: 'endDate',
          },
          {
            text: 'Dollar',
            value: 'discountAmount',
          },
          {
            text: 'Percent',
            value: 'discountPercent',
          },
          {
            text: 'Promotion Description',
            value: 'description',
          },
          {
            text: 'R/Type',
            value: 'salesUom',
          },
          {
            text: 'Item #',
            value: 'itemNumber',
          },
        ],
        keys: ['discountPriceWcid'],
        defaultAllSelected: false,
        multiselect: false,
        baseUrl: 'label/discountPriceWc',
        defaultFilter: f('and')
          .eq('storeNumber', this.storeNumber)
          .eq('itemNumber', this.selectedLabelItem.itemId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['beginDate', 'salesUom'], sortDesc: [true] },
        isInfinite: true,
      });

      this.swcHistory.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting sale with card price history',
          err,
          id: '998b4c55-5d83-43ef-84e4-bc8e4e60f1ba',
        });
      });

      this.swcHistory.get();
    },
    initSncHistory() {
      this.sncHistory = new PogonaDataTable({
        headers: [
          {
            text: 'Begin Date',
            value: 'beginDate',
          },
          {
            text: 'End Date',
            value: 'endDate',
          },
          {
            text: 'Dollar',
            value: 'discountAmount',
          },
          {
            text: 'Percent',
            value: 'discountPercent',
          },
          {
            text: 'Promotion Description',
            value: 'description',
          },
          {
            text: 'R/Type',
            value: 'salesUom',
          },
          {
            text: 'Item #',
            value: 'itemNumber',
          },
        ],
        keys: ['discountPriceNcid'],
        defaultAllSelected: false,
        multiselect: false,
        baseUrl: 'label/discountPriceNc',
        defaultFilter: f('and')
          .eq('storeNumber', this.storeNumber)
          .eq('itemNumber', this.selectedLabelItem.itemId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['beginDate', 'salesUom'], sortDesc: [true] },
        isInfinite: true,
      });

      this.sncHistory.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting sale no card history',
          err,
          id: '47b36979-b362-47d7-bb40-86fda6323031',
        });
      });

      this.sncHistory.get();
    },
    initBmsHistory() {
      this.bmsHistory = new PogonaDataTable({
        headers: [
          {
            text: 'Begin Date',
            value: 'beginDate',
          },
          {
            text: 'End Date',
            value: 'endDate',
          },
          {
            text: 'R/Type',
            value: 'salesUom',
          },
          {
            text: 'Offer Description',
            value: 'offerDescription',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
        ],
        keys: ['discountPriceBmsid'],
        defaultAllSelected: false,
        multiselect: false,
        baseUrl: 'label/discountPriceBms/grouped',
        defaultFilter: f('and')
          .eq('storeNumber', this.storeNumber)
          .eq('itemId', this.selectedLabelItem.itemId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['beginDate', 'salesUom'], sortDesc: [true] },
        isInfinite: true,
      });

      this.bmsHistory.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting buy more save more price history',
          err,
          id: 'a15ecbe4-c47d-4666-96c2-c38790652168',
        });
      });

      this.bmsHistory.get();
    },
    initDailyMovementHistory() {
      this.dailyMovementHistory = new PogonaDataTable({
        headers: [
          {
            text: 'Date',
            value: 'movementDate',
          },
          {
            text: 'Movement',
            value: 'totalMovement',
          },
          {
            text: 'Total Retail',
            value: 'totalRetail',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
        ],
        keys: ['movementId'],
        defaultAllSelected: false,
        multiselect: false,
        baseUrl: 'label/movement',
        defaultFilter: f('and')
          .eq('storeNumber', this.storeNumber)
          .eq('itemId', this.selectedLabelItem.itemId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['movementDate'], sortDesc: [true] },
        isInfinite: true,
      });

      this.dailyMovementHistory.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting daily movement history',
          err,
          id: 'a82d0669-3e00-4d9c-bba0-8240c327415e',
        });
      });

      this.dailyMovementHistory.get();
    },
    initBaseCostHistory() {
      this.baseCost = new PogonaDataTable({
        headers: [
          {
            text: 'Begin Date',
            value: 'beginDate',
          },
          {
            text: 'End Date',
            value: 'endDate',
          },
          {
            text: 'Cost',
            value: 'tradeAgreementCost',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
        ],
        keys: ['costId'],
        defaultAllSelected: false,
        multiselect: false,
        baseUrl: 'label/cost',
        defaultFilter: f('and')
          .eq('storeNumber', this.storeNumber)
          .eq('itemId', this.selectedLabelItem.itemId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['beginDate'], sortDesc: [true] },
        isInfinite: true,
      });

      this.baseCost.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting base cost history',
          err,
          id: '8a165abc-50a2-45e4-9ad6-207e319d060b',
        });
      });

      this.baseCost.get();
    },
    formatBmsDiscount(value, discountType) {
      if (value) {
        if (discountType === 0) {
          return this.$filters.formatCurrency(value);
        } else if (discountType === 1) {
          return this.$filters.formatPercent(value, 2);
        }
      }
      return '';
    },
  },
  watch: {
    'labelItems.selected': {
      handler: function (val) {
        if (val && val.length === 1) {
          this.initBasePriceHistory();
          this.initSwcHistory();
          this.initSncHistory();
          this.initBmsHistory();
          this.initDailyMovementHistory();
          this.initBaseCostHistory();
        }
      },
    },
    labelItems: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/labelItems', val);
      },
      deep: true,
    },
    basePriceHistory: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/basePriceHistory', val);
      },
      deep: true,
    },
    swcHistory: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/swcHistory', val);
      },
      deep: true,
    },
    sncHistory: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/sncHistory', val);
      },
      deep: true,
    },
    bmsHistory: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/bmsHistory', val);
      },
      deep: true,
    },
    dailyMovementHistory: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/dailyMovementHistory', val);
      },
      deep: true,
    },
    baseCost: {
      handler: function (val) {
        this.$store.commit('costmovementhistory/baseCost', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.history-section .v-card__subtitle {
  font-weight: bold;
  color: black;
}
</style>
