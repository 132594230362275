<template>
  <core-view-template title="Product XML Export">
    <v-row>
      <v-col cols="12">
        <v-btn @click="downloadClicked" :loading="loading">Download</v-btn>
      </v-col>
    </v-row>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as epa from '@/store/modules/ecomproduct/actions';

/* eslint-disable @typescript-eslint/no-explicit-any */

const ecomProductModule = namespace('ecomproduct');

@Component({
  components: {},
})
export default class ProductXmlExport extends Vue {
  @ecomProductModule.Action(epa.exportProductXML) exportProductXML!: () => Promise<unknown>;

  private loading = false;

  private downloadClicked() {
    this.loading = true;
    this.exportProductXML().finally(() => (this.loading = false));
  }
}
</script>
