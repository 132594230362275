export interface IExcelData {
  errors: Array<string>;
  dateFieldIndexes: Array<number>;
  createType(rowIx: number, parameters: Array<any>): IExcelData | null;
  equals(other: IExcelData): boolean;
  getLookUpDbValue(fieldName: string, value: string): string | number | null;
  readonly headers: any[];
  readonly lookups: Record<string, Lookup[]>;
  readonly excludedColumns: string[];
}

export class Lookup {
  constructor(
    public text = '',
    public value: string = '',
    public dbValue: string | number = '',
  ) {}
}
