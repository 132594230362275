<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-btn @click="refreshGrid">Refresh</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-if="itemUpdateFileSearchResult"
            :headers="headers"
            :items="itemUpdateFileSearchResult.Results"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="searchtemUpdateFileInProgress"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="itemUpdateFileSearchResult.TotalCount"
            class="elevation-1 scrollable mb-10"
            @update:options="updateSortBy"
            hide-default-footer
            item-key="ProcessFileId"
            height="100%"
          >
            <template v-slot:header.ProcessingProfileId="{ header }">
              <v-col>
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  :hideable="false"
                ></pcl-store-listing-all-header-text>

                <v-row>
                  <v-autocomplete
                    prepend-icon="playlist_add_check"
                    v-model="itemUpdateFileAllFields.ProcessingProfileId.SearchValue"
                    :items="processingProfiles"
                    @blur="paramUpdate"
                    multiple
                    autocomplete="off"
                    item-text="FriendlyName"
                    item-value="ProcessingProfileId"
                    clearable
                  >
                  </v-autocomplete>
                </v-row>
              </v-col>
            </template>

            <template v-slot:item.ProcessingProfileId="{ value }">
              {{ getFormattedProcessingProfile(value) }}
            </template>

            <template v-slot:header.ProcessFileStatusId="{ header }">
              <v-col>
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  :hideable="false"
                ></pcl-store-listing-all-header-text>

                <v-row>
                  <v-autocomplete
                    prepend-icon="playlist_add_check"
                    v-model="itemUpdateFileAllFields.ProcessFileStatusId.SearchValue"
                    :items="processFileStatuses"
                    @blur="paramUpdate"
                    multiple
                    autocomplete="off"
                    item-text="Description"
                    item-value="ProcessFileStatusId"
                    clearable
                  >
                  </v-autocomplete>
                </v-row>
              </v-col>
            </template>

            <template v-slot:item.ProcessFileStatusId="{ value }">
              {{ getFormattedProcessingStatus(value) }}
            </template>

            <template v-if="isPspMerchServices" v-slot:item.edit="{ item }">
              <v-icon @click="edit(item)">edit</v-icon>
            </template>

            <template v-slot:header.TimeInserted="{ header }">
              <v-col class="date-range-col">
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  cssClass="date-filter"
                ></pcl-store-listing-all-header-text>

                <v-row class="date-range-row">
                  <pcl-date-range
                    v-model="itemUpdateFileAllFields.TimeInserted.SearchValue"
                    @change="paramUpdate"
                    :dialogHeader="header.text"
                  ></pcl-date-range>
                </v-row>
              </v-col>
            </template>

            <template v-slot:item.TimeInserted="{ value }">{{ value | formatDate }}</template>

            <template v-slot:header.TimeProcessed="{ header }">
              <v-col class="date-range-col">
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  cssClass="date-filter"
                ></pcl-store-listing-all-header-text>

                <v-row class="date-range-row">
                  <pcl-date-range
                    v-model="itemUpdateFileAllFields.TimeProcessed.SearchValue"
                    @change="paramUpdate"
                    :dialogHeader="header.text"
                  ></pcl-date-range>
                </v-row>
              </v-col>
            </template>

            <template v-slot:item.TimeProcessed="{ value }">{{ value | formatDate }}</template>

            <template v-slot:header.UploadedBy="{ header }">
              <v-col>
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  :hideable="false"
                ></pcl-store-listing-all-header-text>

                <v-row>
                  <v-text-field
                    v-model="itemUpdateFileAllFields.UploadedBy.SearchValue"
                    clearable
                    @click:clear.stop="paramUpdate"
                    @click.stop
                    v-debounce:300="paramUpdate"
                    prepend-icon="search"
                  ></v-text-field>
                </v-row>
              </v-col>
            </template>

            <template v-slot:header.Archived="{ header }">
              <v-col>
                <pcl-store-listing-all-header-text
                  :header="header"
                  :options="options"
                  :hideable="false"
                ></pcl-store-listing-all-header-text>

                <v-row>
                  <v-autocomplete
                    prepend-icon="playlist_add_check"
                    v-model="itemUpdateFileAllFields.Archived.SearchValue"
                    :items="yesNoBools"
                    @blur="paramUpdate"
                    multiple
                    autocomplete="off"
                    item-text="text"
                    item-value="value"
                    clearable
                  >
                  </v-autocomplete>
                </v-row>
              </v-col>
            </template>

            <template v-slot:item.Archived="{ item }">
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <v-checkbox
                    v-model="item.Archived"
                    :bind="attrs"
                    :on="on"
                    @click.stop="
                      fileToEdit = item;
                      changeArchiveDialog = true;
                    "
                    class="changeArchiveCheckbox"
                  />
                </template>
                <span>Click here to flip archive status</span>
              </v-tooltip>
            </template>

            <template v-if="isPspMerchServices" v-slot:item.process="{ item }">
              <v-btn
                :disabled="
                  item.ProcessedProfileId !== null ||
                  item.ProcessedProfileId === item.ProcessingProfileId ||
                  [2].indexOf(item.ProcessFileStatusId) > -1
                "
                @click="process(item)"
                >Process File</v-btn
              >
            </template>

            <template v-slot:item.downloadSource="{ item }">
              <v-btn
                :disabled="
                  item.SourceFileBlobPath === null ||
                  downloadingSources[item.ProcessFileId] === true
                "
                :loading="downloadingSources[item.ProcessFileId] === true"
                @click="downloadSourceFile(item)"
                >Download Source</v-btn
              >
            </template>

            <template v-slot:item.downloadProcessed="{ item }">
              <v-btn
                :disabled="
                  item.ProcessedFileBlobPath === null ||
                  downloadingProcessed[item.ProcessFileId] === true
                "
                :loading="downloadingProcessed[item.ProcessFileId] === true"
                @click="downloadProcessedFile(item)"
                >Download Processed</v-btn
              >
            </template>

            <template v-slot:item.sourceFileName="{ item }">{{ getSourceFileName(item) }}</template>

            <template v-slot:item.ProcessedRunId="{ value }">
              {{ value && value !== '00000000-0000-0000-0000-000000000000' ? value : '' }}
            </template>

            <template v-slot:footer="{}">
              <div class="my-3 ml-2">
                {{ itemUpdateFileSearchResult.Results.length }} /
                {{ itemUpdateFileSearchResult.TotalCount }}
              </div>
            </template>

            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="
                      itemUpdateFileSearchResult &&
                      itemUpdateFileSearchResult.Results &&
                      itemUpdateFileSearchResult.TotalCount > 0
                    "
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-dialog v-model="editDialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Process Profile</span>
              </v-card-title>
              <v-card-subtitle>Select the processing profile below and click save.</v-card-subtitle>
              <v-card-text>
                <v-autocomplete
                  prepend-icon="playlist_add_check"
                  v-model="updateProfileId"
                  :items="processingProfiles"
                  autocomplete="off"
                  item-text="FriendlyName"
                  item-value="ProcessingProfileId"
                  clearable
                >
                </v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="savingProfile || fileToEdit.ProcessingProfileId === updateProfileId"
                  :loading="savingProfile"
                  @click="saveProfile"
                  >Save</v-btn
                >
                <v-btn
                  :disabled="savingProfile"
                  :loading="savingProfile"
                  @click="
                    editDialog = false;
                    fileToEdit = {};
                  "
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-dialog v-model="changeArchiveDialog" max-width="600px" persistent>
            <v-card>
              <v-card-title>
                <span class="headline">Change archive status</span>
              </v-card-title>
              <v-card-text>
                Are you sure you want to set this to
                <b style="font-size: 1em">{{ fileToEdit.Archived ? '' : 'un' }}archived</b>
                for this file upload?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="changingArchiveStatus"
                  :loading="changingArchiveStatus"
                  @click="changeArchiveStatus"
                  >Yes</v-btn
                >
                <v-btn
                  :disabled="changingArchiveStatus"
                  :loading="changingArchiveStatus"
                  @click="
                    changeArchiveDialog = false;
                    fileToEdit.Archived = !fileToEdit.Archived;
                  "
                  >No</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ODataProps from '@/utils/ODataProps';
import buildQuery from 'odata-query';
import FileSaver from 'file-saver';
import ItemUpdateFileAllFields from '@/store/modules/vendorportal/ItemUpdateFileAllFields';
import {
  ItemUpdateFileSearchResult,
  PogovProcessFile,
  SearchParam,
  ProcessFileStatus,
  ProcessingProfile,
} from '@/utils/VendorDomainTypes';
import { StoreListingAllSearchType } from '@psp/pogona_pcl_client_js';
import PclStoreListingAllHeaderText from '@/components/pcl/PclStoreListingAllHeaderText.vue';
import PclNumberRange from '@/components/pcl/PclNumberRange.vue';
import PclDateRange from '@/components/pcl/PclDateRange.vue';
import { getOk, isOk, getFileName } from '@/utils/FableUtils.fs.js';

const vpModule = namespace('vendorportal');
@Component({
  components: { PclStoreListingAllHeaderText, PclNumberRange, PclDateRange },
})
export default class ItemUpdateSearchDataTable extends Vue {
  @vpModule.State('itemUpdateFileSearchResult')
  itemUpdateFileSearchResult!: ItemUpdateFileSearchResult;

  @vpModule.Action('performSearchItemUpdateFile') performSearchItemUpdateFile!: (
    data: any,
  ) => Promise<any>;
  @vpModule.Action('getProcessFileStatuses') getProcessFileStatuses!: () => Promise<any>;
  @vpModule.Action('getProcessingProfiles') getProcessingProfiles!: () => Promise<any>;
  @vpModule.Action('downloadAdfFile') downloadAdfFile!: (data: any) => Promise<string>;
  @vpModule.Action('setProfileForUploadFile') setProfileForUploadFile!: (
    data: any,
  ) => Promise<string>;
  @vpModule.Action('setArchivedForUploadFile') setArchivedForUploadFile!: (
    data: any,
  ) => Promise<string>;
  @vpModule.Action('updateProcessFileRunId') updateProcessFileRunId!: (data: {
    ProcessFileId: number;
    RunId: string;
  }) => Promise<any>;

  @vpModule.Action('updateProcessFileStatus') updateProcessFileStatus!: (data: {
    ProcessFileId: number;
    Status: number;
  }) => Promise<any>;

  @vpModule.Action('processPriceChangeFile') processPriceChangeFile!: (data: {
    Pipeline: string;
    FilePath: string;
  }) => Promise<any>;

  @vpModule.State('processFileStatuses') processFileStatuses!: ProcessFileStatus[];
  @vpModule.State('processingProfiles') processingProfiles!: ProcessingProfile[];

  @vpModule.Mutation('updateProcessFile') updateProcessFile!: (data: {
    ProcessFileId: number;
    ProcessingStatusId: number;
    RunId: string;
  }) => void;

  public headers = [
    { text: 'Edit', value: 'edit', sortable: false },
    { text: 'Processing Profile', value: 'ProcessingProfileId' },
    { text: 'Status', value: 'ProcessFileStatusId' },
    { text: 'Created', value: 'TimeInserted' },
    { text: 'Processed', value: 'TimeProcessed' },
    { text: 'Uploaded By', value: 'UploadedBy' },
    { text: 'Archived', value: 'Archived' },
    { text: 'Process', value: 'process', sortable: false },
    { text: 'Download Source', value: 'downloadSource', sortable: false },
    { text: 'Download Processed', value: 'downloadProcessed', sortable: false },
    { text: 'File', value: 'sourceFileName', sortable: false },
    { text: 'Run ID', value: 'ProcessedRunId' },
  ];

  public searchtemUpdateFileInProgress = false;
  public options: any = { sortBy: ['ProcessFileId'], sortDesc: [true] };
  public infiniteId = +new Date();
  public odataProps = new ODataProps();
  public lastCount = 0;
  public itemUpdateFileAllFields = new ItemUpdateFileAllFields();
  public downloadingSources: Record<number, boolean> = {} as Record<number, boolean>;
  public downloadingProcessed: Record<number, boolean> = {} as Record<number, boolean>;
  public fileToEdit: PogovProcessFile = {} as PogovProcessFile;
  public editDialog = false;
  public savingProfile = false;
  public updateProfileId: number | null = null;
  public changeArchiveDialog = false;
  public changingArchiveStatus = false;
  public isPspMerchServices = false;

  public defaultSkip = 50;

  public yesNoBools = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];

  public async refreshGrid() {
    await this.paramUpdate();
  }

  public async executeSearch(searchParams: SearchParam[], odata: ODataProps) {
    const odataStr = buildQuery(odata);

    this.searchtemUpdateFileInProgress = true;
    try {
      await this.performSearchItemUpdateFile({
        Params: searchParams,
        Odata: odataStr,
        Skip: odata.skip,
      });
      this.odataProps.skip += this.defaultSkip;
    } catch (err) {
      console.error(err);
    } finally {
      this.searchtemUpdateFileInProgress = false;
    }
  }

  public async updateSortBy(event) {
    this.odataProps.skip = 0;

    let orderByArray: Array<string> = [] as Array<string>;
    if (event.sortBy && event.sortBy.length > 0) {
      for (const oix in event.sortBy) {
        const asc = event.sortDesc && event.sortDesc.length > oix && event.sortDesc[oix] === false;
        orderByArray.push(`${event.sortBy[oix]} ${asc ? 'asc' : 'desc'}`);
      }
    }

    this.infiniteId = this.infiniteId + 1;
    if (orderByArray && orderByArray.length > 0) {
      this.odataProps.orderBy = orderByArray;
    }

    await this.executeSearch(await this.createSearchParams(), this.odataProps);
  }

  createSearchParams() {
    // if we don't use settimeout, cleared values will not be recongized
    return new Promise<SearchParam[]>((resolve, reject) => {
      setTimeout(() => {
        try {
          const searchParams = [] as SearchParam[];
          for (const fieldKey of Object.keys(this.itemUpdateFileAllFields)) {
            if (
              (this.itemUpdateFileAllFields[fieldKey] &&
                this.itemUpdateFileAllFields[fieldKey].SearchValue &&
                this.itemUpdateFileAllFields[fieldKey].SearchValue.length > 0) ||
              (this.itemUpdateFileAllFields[fieldKey] &&
                this.itemUpdateFileAllFields[fieldKey].SearchValue &&
                this.itemUpdateFileAllFields[fieldKey].SearchType ===
                  StoreListingAllSearchType.DateRange)
            ) {
              searchParams.push(this.itemUpdateFileAllFields[fieldKey]);
            }
          }
          resolve(searchParams);
        } catch (err) {
          reject(err);
        }
      }, 0);
    });
  }

  async paramUpdate() {
    this.odataProps.skip = 0;
    await this.executeSearch(await this.createSearchParams(), this.odataProps);
  }

  public infiniteHandler($state: any) {
    this.createSearchParams().then(params => {
      this.executeSearch(params, this.odataProps)
        .then(() => {
          if (
            this.itemUpdateFileSearchResult.Results.length % this.odataProps.top === 0 &&
            this.itemUpdateFileSearchResult.Results.length !== this.lastCount
          ) {
            $state.loaded();
            this.odataProps.skip += this.defaultSkip;
          } else {
            $state.complete();
          }
          this.lastCount = this.itemUpdateFileSearchResult.Results.length;
        })
        .catch(() => {
          $state.complete();
        });
    });
  }

  public async mounted() {
    this.isPspMerchServices = await this.$authApi.roleHasRights('PSPMerchServices');
    await Promise.all([this.getProcessFileStatuses(), this.getProcessingProfiles()]);
  }

  public getFormattedProcessingProfile(value) {
    if (value && this.processingProfiles) {
      const profile = this.processingProfiles.filter(x => x.ProcessingProfileId === value);
      if (profile && profile.length === 1) {
        return profile[0].FriendlyName;
      }
    }
    return '';
  }

  public getFormattedProcessingStatus(value) {
    if (value && this.processFileStatuses) {
      const status = this.processFileStatuses.filter(x => x.ProcessFileStatusId === value);
      if (status && status.length === 1) {
        return status[0].Description;
      }
    }
    return '';
  }

  public async process(item: PogovProcessFile) {
    const profile = this.processingProfiles.find(
      file => file.ProcessingProfileId === item.ProcessingProfileId,
    );
    if (profile) {
      this.processPriceChangeFile({
        Pipeline: profile.PipelineName,
        FilePath: item.SourceFileBlobPath,
      }).then((response: any) => {
        if (isOk(response)) {
          let result = getOk(response);
          this.updateProcessFileRunId({ ProcessFileId: item.ProcessFileId, RunId: result });
          this.updateProcessFileStatus({ ProcessFileId: item.ProcessFileId, Status: 2 }); // 2 = Processing
          this.updateProcessFile({
            ProcessFileId: item.ProcessFileId,
            RunId: result,
            ProcessingStatusId: 2,
          });
        } else {
          this.$emit('snackbar-error', {
            text: 'Response from process call failed',
            err: null,
            id: '727d560d-b424-40eb-ace7-a310c548ca99',
          });
        }
      });
    } else {
      this.$emit('snackbar-error', {
        text: 'Profile not found',
        err: null,
        id: '3f77e416-1086-4c87-b279-da288db51069',
      });
    }
  }

  setDownloadingSourceFile(processFileId: number, downloading: boolean) {
    for (const result of this.itemUpdateFileSearchResult.Results) {
      if (result.ProcessFileId === processFileId) {
        Vue.set(this.downloadingSources, processFileId, downloading);
      }
    }
  }

  async downloadSourceFile(item: PogovProcessFile) {
    this.setDownloadingSourceFile(item.ProcessFileId, true);
    try {
      await this.downloadBlob(item.SourceFileBlobPath);
      this.setDownloadingSourceFile(item.ProcessFileId, false);
    } catch (err) {
      this.setDownloadingSourceFile(item.ProcessFileId, false);
      this.$emit('snackbar-error', {
        text: 'Error downloading source file.',
        err,
        id: '9293304e-212e-4acf-a814-0e0ba9e5e4ec',
      });
    }
  }

  setDownloadingProcessedFile(processFileId: number, downloading: boolean) {
    for (const result of this.itemUpdateFileSearchResult.Results) {
      if (result.ProcessFileId === processFileId) {
        Vue.set(this.downloadingProcessed, processFileId, downloading);
      }
    }
  }

  async downloadProcessedFile(item: PogovProcessFile) {
    this.setDownloadingProcessedFile(item.ProcessFileId, true);
    try {
      await this.downloadBlob(item.ProcessedFileBlobPath);
      this.setDownloadingProcessedFile(item.ProcessFileId, false);
    } catch (err) {
      this.setDownloadingProcessedFile(item.ProcessFileId, false);
      this.$emit('snackbar-error', {
        text: 'Error downloading processed file.',
        err,
        id: '0ca5f9dd-3e6e-4c3e-8d5b-9c54f52be1e2',
      });
    }
  }

  getSourceFileName(item: PogovProcessFile) {
    return getFileName(item.SourceFileBlobPath);
  }

  async downloadBlob(blobPath) {
    const blobParts = blobPath.split('/');
    const fileName = blobParts[blobParts.length - 1];

    const result = await this.downloadAdfFile({
      File: blobPath,
    });

    if (isOk(result)) {
      const resOk = getOk(result);
      const fileBlob = new Blob([new Uint8Array(resOk, 0, resOk.byteLength)]);
      FileSaver.saveAs(fileBlob, fileName);
    }
  }

  public async edit(item: PogovProcessFile) {
    this.fileToEdit = item;
    this.updateProfileId = item.ProcessingProfileId;
    this.editDialog = true;
  }

  public async saveProfile() {
    try {
      if (this.fileToEdit) {
        this.savingProfile = true;
        await this.setProfileForUploadFile({
          ProcessFileId: this.fileToEdit.ProcessFileId,
          ProcessingProfileId: this.updateProfileId,
        });
        this.savingProfile = false;
        this.editDialog = false;

        await this.paramUpdate();
      }
    } catch (err) {
      this.savingProfile = false;
      this.$emit('snackbar-error', {
        text: 'Error saving profile.',
        err,
        id: '0f773382-816a-4d67-8c7e-02725f81e4cc',
      });
    }
  }

  public async changeArchiveStatus() {
    try {
      this.changingArchiveStatus = true;
      await this.setArchivedForUploadFile({
        ProcessFileId: this.fileToEdit.ProcessFileId,
        Archived: this.fileToEdit.Archived,
      });
      this.changingArchiveStatus = false;
      this.changeArchiveDialog = false;

      await this.paramUpdate();
    } catch (err) {
      this.changingArchiveStatus = false;
      this.$emit('snackbar-error', {
        text: 'Error saving archive status.',
        err,
        id: 'df578c51-87ad-4975-8e1b-6767d537b173',
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.changeArchiveCheckbox {
  cursor: pointer;
}
</style>
