import { set } from '@/utils/vuex';
import { initState } from './state';

export default {
  batchInfoItems: set('batchInfoItems'),
  handHeldHeaderData: set('handHeldHeaderData'),
  completed: set('completed'),
  createdBatchInfo: set('createdBatchInfo'),
  handHeldSearch: set('handHeldSearch'),
  clear(state: any): void {
    Object.assign(state, initState());
  },
};
