import * as vx from 'vuex';
import { EcomState } from './state';
import { DwCategoryRequest } from '@psp/pogona_ecom_client_js/DwCategoryRequest';
import * as m from '@/store/mutations';
import ODataProps from '@/utils/ODataProps';
import * as em from './mutations';
import FileSaver from 'file-saver';
import dateFormat from 'dateformat';
import buildQuery from 'odata-query';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const searchSubscriptionExceptions = 'searchSubscriptionExceptions';
export const getBrands = 'getBrands';
export const getDepartments = 'getDepartments';
export const getSubDepartments = 'getSubDepartments';
export const getCategories = 'getCategories';
export const getSubCategories = 'getSubCategories';
export const downloadSearchResults = 'downloadSearchResults';
export const validateExcelFile = 'validateExcelFile';
export const processExcelFile = 'processExcelFile';
export const getSubscriptionImportProcesses = 'getSubscriptionImportProcesses';
export const exportErrors = 'exportErrors';
export const exportTemplate = 'exportTemplate';
export const getIgnoredItems = 'getIgnoredItems';
export const getIgnoredItemsCount = 'getIgnoredItemsCount';
export const uploadIgnoredItems = 'uploadIgnoredItems';

export default {
  [searchSubscriptionExceptions]({
    commit,
    state,
  }: vx.ActionContext<EcomState, any>): Promise<any> {
    commit('setExceptionSearchInProgress', true);
    return state
      .client!.searchSubscriptionExceptions(state.exceptionSearch)
      .then(x => {
        commit(em.setExceptionData, x.data);
        commit(em.setExceptionSearchInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving exceptions',
            id: 'f95c6c6a-14c2-400e-8b01-49585b8d9dce',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getBrands]({ commit, state }: vx.ActionContext<EcomState, any>): Promise<any> {
    if (state.brands.length > 0) {
      return Promise.resolve();
    } else {
      return state
        .client!.getDwBrands()
        .then(x => {
          commit(em.setBrands, x.data);
        })
        .catch(err => {
          commit(
            m.setError,
            {
              err,
              text: 'Error retrieving brands',
              id: '73d19bfb-e74d-4612-b96c-0fb6d89dbb23',
            },
            { root: true },
          );
          throw err;
        });
    }
  },
  [getDepartments]({ commit, state }: vx.ActionContext<EcomState, any>): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(1);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(em.setDepartments, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving departments',
            id: 'b05bf3b4-f2db-4e80-847c-825a38e902f1',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getSubDepartments](
    { commit, state }: vx.ActionContext<EcomState, any>,
    parentId: number | null,
  ): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(2, parentId);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(em.setSubDepartments, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving sub departments',
            id: '55197899-a46f-41b5-95cd-cdc5172e2797',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getCategories](
    { commit, state }: vx.ActionContext<EcomState, any>,
    parentId: number | null,
  ): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(3, parentId);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(em.setCategories, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving categories',
            id: 'bb254095-5972-4f67-bd22-0e2dfba7ac0c',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getSubCategories](
    { commit, state }: vx.ActionContext<EcomState, any>,
    parentId: number | null,
  ): Promise<any> {
    const request: DwCategoryRequest = new DwCategoryRequest(4, parentId);
    return state
      .client!.getDwCategories(request)
      .then(x => {
        commit(em.setSubCategories, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving sub categories',
            id: '55449d11-456d-4581-a782-a0fab95c8d0c',
          },
          { root: true },
        );
        throw err;
      });
  },
  [downloadSearchResults]({
    commit,
    state,
  }: vx.ActionContext<EcomState, any>): Promise<any> | undefined {
    return state
      .client!.downloadSearchResults(state.exceptionSearch)
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `autoship-exceptions-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving download',
            id: '7c723899-5550-40de-913d-b42444ff07f3',
          },
          { root: true },
        );
        throw err;
      });
  },
  [validateExcelFile](
    { state, commit }: vx.ActionContext<EcomState, any>,
    payload: File,
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', payload, payload.name);
    return state
      .client!.validateExceptionsExcelFile(formData)
      .then(x => {
        commit(em.setUploadFile, payload);
        commit(em.setValidationData, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error uploading Excel file',
            id: '0069dcc9-606a-4b02-a31f-31678dfe6732',
          },
          { root: true },
        );
        throw err;
      });
  },
  [processExcelFile](
    { state, commit }: vx.ActionContext<EcomState, any>,
    payload: File,
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append('file', payload, payload.name);
    return state.client!.processExceptionsExcelFile(formData).catch(err => {
      commit(
        m.setError,
        {
          err,
          text: 'Error processing Excel file',
          id: 'd5910f6f-f715-4bd5-99e9-eebb12ea2877',
        },
        { root: true },
      );
      throw err;
    });
  },
  [getSubscriptionImportProcesses]({
    state,
    commit,
  }: vx.ActionContext<EcomState, any>): Promise<unknown> {
    return state
      .client!.getSubscriptionImportProcesses()
      .then(x => {
        commit(em.setProcessingStateInfo, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving processing data',
            id: '1cf2357c-988e-477b-b44c-6b52aa36fa1d',
          },
          { root: true },
        );
        throw err;
      });
  },
  [exportErrors](
    { commit, state }: vx.ActionContext<EcomState, any>,
    correlationId: string,
  ): Promise<any> {
    return state
      .client!.exportErrors(correlationId)
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `autoship-error-exceptions-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving exception errors',
            id: 'f1ae6696-618b-48df-ab7c-e14dfe8d85bc',
          },
          { root: true },
        );
        throw err;
      });
  },
  [exportTemplate]({ commit, state }: vx.ActionContext<EcomState, any>): Promise<any> {
    return state
      .client!.exportTemplate()
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `autoship-exceptions-template-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving exception template',
            id: 'e025aaa6-43bb-4091-b075-898ad1c78e2d',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getIgnoredItems](
    { state, commit }: vx.ActionContext<EcomState, any>,
    odata: ODataProps,
  ): Promise<unknown> {
    let odataStr = '';
    if (odata) {
      odataStr = buildQuery(odata).substring(1);
    } else {
      odataStr = buildQuery({ top: 50 }).substring(1);
    }

    commit(em.setGettingIgnoredItems, true);
    return state
      .client!.getIgnoredItems(odataStr)
      .then(x => {
        commit(em.setGettingIgnoredItems, false);
        commit(em.setIgnoredItems, x.data);
      })
      .catch(err => {
        commit(em.setGettingIgnoredItems, false);
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving ignored data',
            id: '1cf2357c-988e-477b-b44c-6b52aa36fa1d',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getIgnoredItemsCount](
    { state, commit }: vx.ActionContext<EcomState, any>,
    odata: ODataProps,
  ): Promise<unknown> {
    let odataStr = '';
    if (odata) {
      odataStr = buildQuery(odata).substring(1);
    } else {
      odataStr = buildQuery({ top: 50 }).substring(1);
    }

    return state
      .client!.getIgnoredItemsCount(odataStr)
      .then(x => {
        commit(em.setIgnoredItemsCount, x.data);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error retrieving ignored data count',
            id: '9a91c6bc-9001-4050-816f-1a2fd86e1393',
          },
          { root: true },
        );
        throw err;
      });
  },
  [uploadIgnoredItems](
    { state, commit }: vx.ActionContext<EcomState, any>,
    ignoredItemsFile: File,
  ): Promise<unknown> {
    commit(em.setIgnoredItemsUploadInProgress, true);
    commit(em.setIgnoredItemsUploadResult, null);
    return state
      .client!.uploadIgnoredItems(ignoredItemsFile)
      .then(x => {
        commit(em.setIgnoredItemsUploadInProgress, false);
        commit(em.setIgnoredItemsUploadResult, x.data);
      })
      .catch(err => {
        commit(em.setIgnoredItemsUploadInProgress, false);
        commit(em.setIgnoredItemsUploadResult, err.response.data);
        commit(
          m.setError,
          {
            err,
            text: 'Error uploading ignored items',
            id: '72fdc403-bc18-442b-a1ad-3dfcba019d7e',
          },
          { root: true },
        );
        throw err;
      });
  },
};
