<template>
  <core-view-template title="Edit Division/Region">
    <v-progress-linear indeterminate v-if="loading || saving" />
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="dr.Name"
          label="Divsion/Region Name"
          :error-messages="errorMessages.Name"
          @blur="validate('Name')"
          @change="validate('Name')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="selectedVicePresidentEmployeeId"
          :items="employeeDropDownData"
          label="Vice President"
          item-text="name"
          item-value="value"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="selectedDirectorRoleEmployeeId"
          :items="employeeDropDownData"
          label="Director"
          item-text="name"
          item-value="value"
        />
      </v-col>
    </v-row>
    <v-row>
      <core-view-section
        v-for="(d, i) in dr.Districts"
        :key="d.DistrictId"
        :title="`District ${d.Name}`"
      >
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="d.Name"
              label="Disrtict Name"
              :error-messages="getDistrictNameErrorMessage(i)"
              @blur="validate(`Districts[${i}].Name`)"
              @keyup="validate(`Districts[${i}].Name`)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn @click="removeDistrict(d)" v-if="!districtHasStore(d.DistrictId)">Remove</v-btn>
            <v-dialog
              v-else
              v-model="getDistrictStoreDialog(d.DistrictId).showDialog"
              max-width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="showDistrictStoreDialog(d.DistrictId)"
                  >Show Stores</v-btn
                >
              </template>
              <v-card>
                <v-card-title>Stores in District {{ d.Name }}</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-for="s in d.Stores" :key="s.StoreId">
                      {{ s.StoreId }} - {{ s.Name }}
                    </v-row>
                  </v-container>
                  <v-card v-for="m in d.Markets" :key="m.MarketId" :v-if="m.Stores.length > 0">
                    <v-card-title> Stores in Market {{ m.Name }} </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row v-for="s in m.Stores" :key="s.StoreId">
                          {{ s.StoreId }} - {{ s.Name }}
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="getDistrictStoreDialog(d.DistrictId).showDialog = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="getDistrictData(d.DistrictId).selectedDistrictTeamLeaderId"
              :items="employeeDropDownData"
              label="District Team Leader"
              item-text="name"
              item-value="value"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="getDistrictData(d.DistrictId).selectedFieldTrainers"
              :items="employeeDropDownData"
              label="Field Trainers"
              item-text="name"
              item-value="value"
              multiple
              clearable
              chips
              deletable-chips
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="getDistrictData(d.DistrictId).selectedSSTLIds"
              :items="employeeDropDownData"
              label="SSTL"
              item-text="name"
              item-value="value"
              multiple
              clearable
              chips
              deletable-chips
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="getDistrictData(d.DistrictId).selectCDLIds"
              :items="employeeDropDownData"
              label="CDL"
              item-text="name"
              item-value="value"
              multiple
              clearable
              chips
              deletable-chips
            />
          </v-col>
        </v-row>
        <v-row class="px-3" v-for="(m, mi) in d.Markets" :key="m.MarketId">
          <v-col cols="4">
            <v-text-field
              v-model="m.Name"
              label="Market Name"
              :error-messages="getDistrictMarketNameErrorMessage(i, mi)"
              @blur="validate(`Districts[${i}].Markets[${mi}].Name`)"
              @keyup="validate(`Districts[${i}].Markets[${mi}].Name`)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="getMarketData(d.DistrictId, m.MarketId).selectedMarketLeader"
              :items="employeeDropDownData"
              label="Market Team Leader"
              item-text="name"
              item-value="value"
            />
          </v-col>
          <v-col cols="4">
            <v-btn @click="removeMarket(m)" v-if="m.Stores.length === 0">Remove</v-btn>
            <v-dialog
              v-if="m.Stores.length > 0"
              v-model="getMarketStoreDialog(m.MarketId).showDialog"
              max-width="300px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="showMarketStores(m.MarketId)"
                  >Show Stores</v-btn
                >
              </template>
              <v-card>
                <v-card-title>Stores in Market {{ m.Name }}</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-for="s in m.Stores" :key="s.StoreId">
                      {{ s.StoreId }} - {{ s.Name }}
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="getMarketStoreDialog(m.MarketId).showDialog = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="addMarket(d.DistrictId)">Add Market</v-btn>
          </v-col>
        </v-row>
      </core-view-section>
      <v-row>
        <v-col cols="12">
          <v-btn @click="addDistrict">Add District</v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="saveClicked" :loading="saving">Save</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackbar" :top="true" timeout="6000" :color="snackbarColor">
      <p>{{ snackbarMessage }}</p>
      <p v-for="e in validationErrors" v-bind:key="e">
        {{ e }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </core-view-template>
</template>
<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pm from '@/store/modules/pcl/mutations';
import * as pcldb from '@psp/pcldb';
import * as pcldto from '@psp/pcldto';
import * as pclclient from '@psp/pogona_pcl_client_js';
import * as yup from 'yup';

/* eslint-disable @typescript-eslint/no-explicit-any */

const pclModule = namespace('pcl');

@Component({
  components: {},
})
export default class DivisionRegionEdit extends Vue {
  @pclModule.State(ps.divisionRegionEdit) divisionRegionEdit!: pcldto.pclDivisionRegionWithRelated;
  @pclModule.State(ps.employees) employees!: pcldto.pclEmployee[];

  @pclModule.Mutation(pm.clearDivisionRegionEdit) clearDivisionRegionEdit!: () => void;
  @pclModule.Action(pa.getDivisionRegionForEdit) getDivisionRegionForEdit!: (
    payload: number,
  ) => Promise<unknown>;
  @pclModule.Action(pa.getEmployees) getEmployees!: () => Promise<any>;
  @pclModule.Action(pa.saveDivisionRegion) saveDivisionRegion!: (
    payload: pclclient.SaveDivisionRegionData,
  ) => Promise<any>;

  private deleteEntities: any[] = [];
  private marketStoreDialog: ps.MarketStoreDialog[] = [] as ps.MarketStoreDialog[];
  private districtStoreDialog: ps.DistrictStoreDialog[] = [] as ps.DistrictStoreDialog[];
  private nextId = -1;
  private showSnackbar = false;
  private snackbarMessage = '';
  private snackbarColor = '';
  private validationErrors = [];
  private saving = false;
  private selectedVicePresidentEmployeeId = 0;
  private selectedDirectorRoleEmployeeId = 0;
  private loading = false;
  private errorMessages: ps.PclDivisionRegionMessages = new ps.PclDivisionRegionMessages();
  private dr: pcldto.pclDivisionRegionWithRelated = new pcldto.pclDivisionRegionWithRelated(
    null,
    [],
    0,
    '',
    null,
  );
  private districtData: ps.DistrictSelections[] = [] as ps.DistrictSelections[];

  private drSchema: any = yup.object().shape({
    Name: yup.string().required(),
    Districts: yup.array().of(
      yup.object().shape({
        Name: yup.string().required(),
        Markets: yup.array().of(
          yup.object().shape({
            Name: yup.string().required(),
          }),
        ),
      }),
    ),
  });

  districtHasStore(districtId: number): boolean {
    var found = false;
    var index = this.dr.Districts.findIndex(d => d.DistrictId === districtId);

    if (index > -1) {
      found = this.dr.Districts[index].Stores.length > 0;
      if (!found) {
        found = this.dr.Districts[index].Markets.some(m => m.Stores.length > 0);
      }
    }

    return found;
  }

  deleteRole(roleId: number) {
    if (roleId > 0) {
      const role = new pcldb.RoleEntity();
      role.RoleId = roleId;
      this.deleteEntities.push(new pcldb.SerializedRole(JSON.stringify(role)));
    }
  }

  removeDistrict(district: pcldto.pclDivisionRegionWithRelatedTypes.District) {
    if (district.DistrictId > 0) {
      district.Markets.forEach(m => this.addMarketDeltion(m));
      district.CritterDistrictLeadRoles.forEach(r => {
        this.deleteRole(r.RoleId);
      });
      if (district.DistrictTeamLeaderRole && district.DistrictTeamLeaderRole.RoleId > 0) {
        this.deleteRole(district.DistrictTeamLeaderRole.RoleId);
      }
      district.FieldTrainerRoles.forEach(r => {
        this.deleteRole(r.RoleId);
      });
      district.SeniorStoreTeamLeaderRoles.forEach(r => {
        this.deleteRole(r.RoleId);
      });
      const dDelete = new pcldb.DistrictEntity();
      dDelete.DistrictId = district.DistrictId;
      this.deleteEntities.push(new pcldb.SerializedDistrict(JSON.stringify(dDelete)));
    }
    let index = this.districtData.findIndex(d => d.districtId == district.DistrictId);
    if (index >= 0) {
      this.districtData.splice(index, 1);
    }
    index = this.dr.Districts.findIndex(d => d.DistrictId === district.DistrictId);
    if (index >= 0) {
      this.dr.Districts.splice(index, 1);
    }
  }

  addMarketDeltion(market: pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market) {
    if (market.MarketId > 0) {
      if (market.MarketTeamLeaderRole && market.MarketTeamLeaderRole.RoleId > 0) {
        this.deleteRole(market.MarketTeamLeaderRole.RoleId);
      }
      const mdelete = new pcldb.MarketEntity();
      mdelete.MarketId = market.MarketId;
      this.deleteEntities.push(new pcldb.SerializedMarket(JSON.stringify(mdelete)));
    }
  }

  removeMarket(market: pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market) {
    this.addMarketDeltion(market);
    let index = this.districtData.findIndex(d => d.districtId === market.DistrictId);
    if (index >= 0) {
      let mIndex = this.districtData[index].markets.findIndex(m => m.marketId === market.MarketId);
      if (mIndex >= 0) {
        this.districtData[index].markets.splice(mIndex, 1);
      }
    }
    index = this.dr.Districts.findIndex(d => d.DistrictId === market.DistrictId);
    if (index >= 0) {
      let mIndex = this.dr.Districts[index].Markets.findIndex(m => m.MarketId === market.MarketId);
      if (mIndex >= 0) {
        this.dr.Districts[index].Markets.splice(mIndex, 1);
      }
    }
  }

  getMarketStoreDialog(marketId: number): ps.MarketStoreDialog {
    let index = this.marketStoreDialog.findIndex(m => m.marketId == marketId);
    if (index === -1) {
      index = this.marketStoreDialog.push(new ps.MarketStoreDialog(marketId, false)) - 1;
    }
    return this.marketStoreDialog[index];
  }

  showMarketStores(marketId: number) {
    this.getMarketStoreDialog(marketId).showDialog = true;
  }

  getDistrictStoreDialog(districtId: number): ps.DistrictStoreDialog {
    let index = this.districtStoreDialog.findIndex(d => d.districtId === districtId);
    if (index === -1) {
      index = this.districtStoreDialog.push(new ps.DistrictStoreDialog(districtId, false)) - 1;
    }
    return this.districtStoreDialog[index];
  }

  showDistrictStoreDialog(districtId: number) {
    this.getDistrictStoreDialog(districtId).showDialog = true;
  }

  public addDistrict(): number {
    this.dr.Districts.push(
      new pcldto.pclDivisionRegionWithRelatedTypes.District(
        [],
        this.nextId,
        null,
        this.dr.DivisionRegionId,
        [],
        [],
        '',
        [],
        [],
      ),
    );
    this.getDistrictData(this.nextId);
    this.nextId--;
    return this.nextId + 1;
  }

  public addMarket(districtId: number) {
    const index = this.dr.Districts.findIndex(d => d.DistrictId === districtId);
    if (index === -1) {
      throw new Error(`addMarket district not found ${districtId}`);
    }
    this.dr.Districts[index].Markets.push(
      new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.Market(
        districtId,
        this.nextId,
        null,
        '',
        [],
      ),
    );
    this.getMarketData(districtId, this.nextId);
    this.nextId--;
  }

  private getDistrictNameErrorMessage(i: number) {
    return this.getErrorMessages(`Districts[${i}].Name`);
  }

  private getDistrictMarketNameErrorMessage(districtIndex: number, marketIndex: number) {
    return this.getErrorMessages(`Districts[${districtIndex}].Markets[${marketIndex}].Name`);
  }

  getDistrictData(districtId: number): ps.DistrictSelections {
    let index = this.districtData.findIndex(d => d.districtId === districtId);
    if (index === -1) {
      index = this.districtData.push(new ps.DistrictSelections(districtId)) - 1;
    }

    return this.districtData[index] as ps.DistrictSelections;
  }

  getMarketData(districtId: number, marketId: number) {
    const district = this.getDistrictData(districtId);
    let index = district.markets.findIndex(m => m.marketId === marketId);
    if (index === -1) {
      index = district.markets.push(new ps.MarketSelections(marketId)) - 1;
    }

    return district.markets[index] as ps.MarketSelections;
  }

  getErrorMessages(field: any) {
    if (!this.errorMessages[field]) {
      this.$set(this.errorMessages, field, []);
    }

    return this.errorMessages[field];
  }

  setErrorMessages(field: any, values: string[]) {
    this.$set(this.errorMessages, field, values);
  }

  @Watch('districtData', { deep: true }) districtDataChange(next: ps.DistrictSelections[]) {
    this.mapDistrictUiModelToDr(next);
  }

  mapDistrictUiModelToDr(districtData: ps.DistrictSelections[]) {
    districtData.forEach(dd => {
      const district = this.dr.Districts.find(d => d.DistrictId === dd.districtId);
      if (!district) {
        throw new Error(`District not found for key ${dd.districtId}`);
      }
      if (dd.selectedDistrictTeamLeaderId !== district?.DistrictTeamLeaderRole?.EmployeeId) {
        if (dd.selectedDistrictTeamLeaderId === 0) {
          district.DistrictTeamLeaderRole = null;
        } else {
          if (dd.selectedDistrictTeamLeaderId !== district.DistrictTeamLeaderRole?.EmployeeId) {
            district.DistrictTeamLeaderRole =
              new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.DistrictTeamLeaderRolepclDistrictTeamLeaderRole(
                dd.districtId,
                null,
                dd.selectedDistrictTeamLeaderId,
                0,
                pclclient.RoleName.DistrictTeamLeader,
              );
          }
        }
      }

      district.FieldTrainerRoles = dd.selectedFieldTrainers.map(ft => {
        const index = district.FieldTrainerRoles.findIndex(dft => dft.EmployeeId === ft);
        return index === -1
          ? new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.FieldTrainerRolepclFieldTrainerRole(
              dd.districtId,
              null,
              ft,
              0,
              pclclient.RoleName.FieldTrainer,
            )
          : district.FieldTrainerRoles[index];
      });

      district.SeniorStoreTeamLeaderRoles = dd.selectedSSTLIds.map(ss => {
        const index = district.SeniorStoreTeamLeaderRoles.findIndex(dss => dss.EmployeeId == ss);
        return index === -1
          ? new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.SeniorStoreTeamLeaderRolepclSeniorStoreTeamLeaderRole(
              dd.districtId,
              null,
              ss,
              0,
              pclclient.RoleName.SeniorStoreTeamLeader,
            )
          : district.SeniorStoreTeamLeaderRoles[index];
      });

      district.CritterDistrictLeadRoles = dd.selectCDLIds.map(cdl => {
        const index = district.CritterDistrictLeadRoles.findIndex(dc => dc.EmployeeId == cdl);
        return index === -1
          ? new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.CritterDistrictLeadRolepclCritterDistrictLeadRole(
              dd.districtId,
              null,
              cdl,
              0,
              pclclient.RoleName.CritterDistrictLead,
            )
          : district.CritterDistrictLeadRoles[index];
      });

      dd.markets.forEach(m => {
        const index = district.Markets.findIndex(m2 => m2.MarketId === m.marketId);
        if (index === -1) {
          throw new Error(`Market mapping not found for market ${m.marketId}`);
        }
        const market = district.Markets[index];
        if (m.selectedMarketLeader !== market.MarketTeamLeaderRole?.EmployeeId) {
          if (m.selectedMarketLeader === 0) {
            market.MarketTeamLeaderRole = null;
          } else {
            market.MarketTeamLeaderRole =
              new pcldto.pclDivisionRegionWithRelatedTypes.DistrictTypes.MarketTypes.MarketTeamLeaderRolepclMarketTeamLeaderRole(
                null,
                m.selectedMarketLeader,
                m.marketId,
                0,
                pclclient.RoleName.MarketTeamLeader,
              );
          }
        }
      });
    });
  }

  private validate(field: any): any {
    this.setErrorMessages(field, []);
    try {
      this.drSchema.validateSyncAt(field, this.dr);
    } catch (err: any) {
      this.setErrorMessages(field, err.errors);
    }
  }

  @Watch('selectedVicePresidentEmployeeId') selectedVicePresidentEmployeeIdChanged(
    next: number,
    current: number,
  ): void {
    if (next !== current) {
      if (next === 0) {
        this.dr.VicePresidentRole = null;
      } else {
        if (this.dr.VicePresidentRole?.EmployeeId !== next) {
          this.dr.VicePresidentRole =
            new pcldto.pclDivisionRegionWithRelatedTypes.VicePresidentRolepclVicePresidentRole(
              this.dr.DivisionRegionId,
              null,
              next,
              0,
              pclclient.RoleName.VicePresidentOfStores,
            );
        }
      }
    }
  }

  @Watch('selectedDirectorRoleEmployeeId') selectedDirectorRoleEmployeeIdChange(
    next: number,
    current: number,
  ): void {
    if (next !== current) {
      if (next === 0) {
        this.dr.DirectorRole = null;
      } else {
        if (this.dr.DirectorRole?.EmployeeId !== next) {
          this.dr.DirectorRole =
            new pcldto.pclDivisionRegionWithRelatedTypes.DirectorRolepclDirectorRole(
              this.dr.DivisionRegionId,
              null,
              next,
              0,
              pclclient.RoleName.Director,
            );
        }
      }
    }
  }

  get employeeDropDownData(): any {
    const data = [] as unknown[];
    data.push({ name: '(None)', value: 0 });
    this.employees.map(e =>
      data.push({ name: `${e.FirstName} ${e.LastName}`, value: e.EmployeeId }),
    );
    return data;
  }

  private saveClicked() {
    this.drSchema
      .validate(this.dr)
      .then((dr: pcldto.pclDivisionRegionWithRelated) => {
        this.dr = dr;
        this.validationErrors = [];
        this.saving = true;
        const data = new pclclient.SaveDivisionRegionData();
        data.DivisionRegion = this.dr;
        data.DeleteEntities = this.deleteEntities;
        this.saveDivisionRegion(data)
          .then(() => {
            this.showSnackbarMessage('Divsion/Region saved', 'success');
            this.$router.push({
              name: 'pcl_division_region_edit',
              params: { drId: this.divisionRegionEdit.DivisionRegionId.toString() },
            });
          })
          .catch(() => {
            this.showSnackbarMessage('Error saving division region', 'error');
          })
          .finally(() => (this.saving = false));
      })
      .catch(err => {
        this.validationErrors = err.errors;
        this.showSnackbarMessage('Cannot save division/region, please fix the errors.', 'error');
      });
  }

  private initDrValues() {
    this.selectedVicePresidentEmployeeId =
      this.dr.VicePresidentRole === null ? 0 : this.dr.VicePresidentRole.EmployeeId;
    this.selectedDirectorRoleEmployeeId =
      this.dr.DirectorRole === null ? 0 : this.dr.DirectorRole.EmployeeId;
    this.dr.Districts.forEach(d => {
      const districtData = this.getDistrictData(d.DistrictId);
      districtData.districtId = d.DistrictId;
      districtData.selectedDistrictTeamLeaderId =
        d.DistrictTeamLeaderRole === null ? 0 : d.DistrictTeamLeaderRole.EmployeeId;
      districtData.selectedFieldTrainers = d.FieldTrainerRoles.map(f => f.EmployeeId);
      districtData.selectedSSTLIds = d.SeniorStoreTeamLeaderRoles.map(r => r.EmployeeId);
      districtData.selectCDLIds = d.CritterDistrictLeadRoles.map(r => r.EmployeeId);

      d.Markets.forEach(m => {
        const market = this.getMarketData(d.DistrictId, m.MarketId);
        market.selectedMarketLeader =
          m.MarketTeamLeaderRole === null ? 0 : m.MarketTeamLeaderRole.EmployeeId;
      });
    });
  }

  private loadDivisionRegion(id: number): Promise<any> {
    this.loading = true;
    return this.getEmployees().then(() => {
      if (id === 0) {
        this.clearDivisionRegionEdit();
        this.dr = { ...this.divisionRegionEdit };
        this.initDrValues();
        this.loading = false;
      } else {
        return this.getDivisionRegionForEdit(id)
          .then(() => {
            this.dr = { ...this.divisionRegionEdit };
            this.initDrValues();
          })
          .finally(() => (this.loading = false));
      }
    });
  }

  private showSnackbarMessage(message: string, color: string) {
    this.snackbarMessage = message;
    this.snackbarColor = color;
    this.showSnackbar = true;
  }

  get drId(): number {
    return parseInt(this.$route.params.drId);
  }

  private mounted() {
    this.loadDivisionRegion(this.drId);
  }

  @Watch('$route') public onRouteChanged() {
    this.loadDivisionRegion(this.drId);
  }
}
</script>
