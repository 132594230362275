<template>
  <core-view-template title="Neighbor Arrival Notification Enabled Stores">
    <core-view-section title="Enabled Stores">
      <v-row>
        <v-col>
          <store-mappings
            v-model="newStoresToMap"
            @saved="addNewUserStoreMappings"
            :mappedStores="mappedStoresProjected"
            :loading="newStoreMappingLoading"
            :dialog.sync="newStoreMappingDialog"
            newText="New Arrival Notification Store"
          ></store-mappings>
        </v-col>
        <v-col>
          <v-dialog v-model="deleteMappingDialog" max-width="500">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="enabledStores.selected.length < 1"
                >Delete Arrival Notification Store{{ selectedStoresPlural }}</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Disable Store{{ selectedStoresPlural }}</span>
              </v-card-title>
              <v-card-text
                >Are you sure you want to disable {{ enabledStores.selected.length }} store{{
                  selectedStoresPlural
                }}?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="deleteStoreMaps"
                  :disabled="deleteMappingLoading"
                  :loading="deleteMappingLoading"
                  >Yes</v-btn
                >
                <v-btn @click="deleteMappingDialog = false">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col>
          <v-data-table
            v-if="enabledStores"
            v-model="enabledStores.selected"
            :headers="enabledStores.headers"
            :items="enabledStores.items"
            :loading="enabledStores.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="enabledStores.options"
            @update:sort-by="() => {}"
            @click:row="enabledStores.itemRowMultiClicked($event, enabledStores)"
            @item-selected="enabledStores.itemRowMultiSelected($event, enabledStores)"
            item-key="storeNumber"
            class="elevation-1 scrollable"
            show-select
          >
            <template v-slot:body.append>
              <infinite-loader
                :pogonaTable="enabledStores"
                :colspan="enabledStores.headers.length"
              ></infinite-loader>
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="enabledStores"></infinite-paganation>
        </v-col>
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script>
import PogonaDataTable from '@/utils/PogonaDataTable';

export default {
  data() {
    return {
      enabledStores: null,
      newStoresToMap: [],
      newStoreMappingLoading: false,
      newStoreMappingDialog: false,
      deleteMappingDialog: false,
      deleteMappingItem: null,
      deleteMappingLoading: false,
    };
  },
  async created() {
    await this.initStoreMappings();
  },
  computed: {
    mappedStoresProjected() {
      return this.enabledStores.items.map(x => x.storeNumber);
    },
    selectedStoresPlural() {
      return this.enabledStores.selected.length === 1 ? '' : 's';
    },
  },
  methods: {
    async deleteStoreMaps() {
      try {
        this.deleteMappingLoading = true;

        const deletes = [];
        this.enabledStores.selected.forEach(mappedStore => {
          deletes.push(
            this.$authApi.http.delete(
              `pogona/neighborarrivalnotificationenabledstore/${mappedStore.storeNumber}`,
            ),
          );
        });

        await Promise.all(deletes);

        this.deleteMappingLoading = false;
        this.deleteMappingDialog = false;

        await this.initStoreMappings();
      } catch (err) {
        this.deleteMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to delete user store mappings',
          err,
          id: '694e784b-0215-4208-9834-9977a8d81b10',
        });
      }
    },
    showDeleteDialog(item) {
      this.deleteMappingItem = item;
      this.deleteMappingDialog = true;
    },
    async addNewUserStoreMappings() {
      try {
        this.newStoreMappingLoading = true;

        const posts = [];
        this.newStoresToMap.forEach(store => {
          posts.push(
            this.$authApi.http.post('pogona/neighborarrivalnotificationenabledstore', {
              storeNumber: store.storeNumber,
            }),
          );
        });

        await Promise.all(posts);

        this.newStoreMappingLoading = false;
        this.newStoreMappingDialog = false;

        this.newStoresToMap = [];
        await this.initStoreMappings();
      } catch (err) {
        this.newStoreMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to save store mappings',
          err,
          id: '64b3b208-d23f-46ff-a8b0-b7a6fcc30c2c',
        });
      }
    },
    async initStoreMappings() {
      this.enabledStores = new PogonaDataTable({
        headers: [
          {
            text: 'Store Number',
            value: 'storeNumber',
          },
        ],
        baseUrl: 'pogona/neighborarrivalnotificationenabledstore',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['storeNumber'] },
        isInfinite: true,
        multiselect: true,
        keys: ['storeNumber'],
      });

      this.enabledStores.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting mapped stores',
          err,
          id: 'aba1c36c-08eb-4772-a038-381cc89626b5',
        });
      });

      await this.enabledStores.get();
    },
  },
};
</script>
