<template>
  <v-switch
    v-model="dshowLabelPreviewMode"
    :label="`Print Preview ${dshowLabelPreviewMode ? 'On' : 'Off'}`"
  ></v-switch>
</template>

<script>
import Vue from 'vue';

const LabelPreviewSwitch = Vue.component('LabelPreviewSwitch', {
  data() {
    return {
      dshowLabelPreviewMode: this.$store.state.app.showLabelPreviewMode,
    };
  },
  watch: {
    dshowLabelPreviewMode: {
      handler(val) {
        this.$store.dispatch('app/showLabelPreviewMode', val || false);
      },
    },
  },
});

export default LabelPreviewSwitch;
export { LabelPreviewSwitch };
</script>
