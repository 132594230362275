const initState = (): unknown => {
  return {
    batchInfoItems: {},
    batchInfo: {},
    selectedBatchInfoTypes: [],
    completedBatchInfos: false,
    selectedBatchInfo: null,
    retailStartDate: null,
    search: null,
    showZeroApproved: false,
  };
};

export { initState };

export default {
  batchInfoItems: {},
  batchInfo: {},
  selectedBatchInfoTypes: [],
  completedBatchInfos: false,
  selectedBatchInfo: null,
  retailStartDate: null,
  search: null,
  showZeroApproved: false,
};
