<template>
  <core-signs-labels-view-template title="Hand Held Print">
    <core-view-section title="Hand Held Batches">
      <v-row class="mt-4">
        <v-btn-toggle v-model="reportButtonGroup" group id="reportButtonGroup">
          <v-dialog max-width="340" v-model="preinventorySetupDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="handheldSelected || reportLoading"
                >Pre-Count Report</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Pre-Count Setup</span>
              </v-card-title>
              <v-card-text>
                <v-col cols="12">
                  <v-row v-if="selectedBatchIsTrueScan === false">
                    <v-row> Please select the barcode types you want to include.</v-row>
                    <v-row class="mb-0">
                      <v-col cols="3">
                        <v-checkbox label="Primary" v-model="includePrimary"></v-checkbox>
                        <v-checkbox label="PLU" v-model="includePlu"></v-checkbox>
                        <v-checkbox label="Case" v-model="includeCase"></v-checkbox>
                        <v-checkbox label="Other" v-model="includeOther"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-row>
                  <data-date-picker v-model="preCountReportStart" label="Retail Start Date" />
                  <v-checkbox
                    v-model="useHandheldScanOrderPreCount"
                    label="Use Handheld Scan Order"
                  ></v-checkbox>

                  <v-checkbox
                    v-if="selectedBatchIsTrueScan === true"
                    v-model="sumByUpc"
                    label="Sum Up By UPC"
                  ></v-checkbox>

                  <v-checkbox
                    v-if="selectedBatchIsTrueScan === true"
                    v-model="excludeZeroCount"
                    label="Exclude Zero Count Items"
                    class="mb-2"
                  ></v-checkbox>

                  <v-row v-if="selectedBatchIsTrueScan === false">
                    Note: when no UPC is available for the selected type(s), the system will
                    automatically fallback on the primary, PLU, case, or the other UPC for the item.
                  </v-row>

                  <v-row v-if="selectedBatchIsTrueScan === true" class="mb-1">
                    Note: when "Sum Up By UPC" is selected, UPC(s) scanned multiple times will have
                    their report lines combined, and entered quantities summed up.
                  </v-row>

                  <v-row v-if="selectedBatchIsTrueScan === true" class="mb-1"
                    >The barcode type selectors have been removed for batches created with the new
                    handheld application. The UPC scanned is what will appear in this report.
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="preCountReportDisabled" @click.stop="openPreInventoryReport"
                      >Report</v-btn
                    >
                    <v-btn @click="preinventorySetupDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-col>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog max-width="340" v-model="rgaSetupDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="handheldSelected || reportLoading">RGA Form Report</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">RGA Setup</span>
              </v-card-title>
              <v-card-text>
                <v-col cols="12">
                  <v-row>
                    <v-text-field
                      prepend-icon="perm_identity"
                      v-model="rgaFormCustomerNumber"
                      label="Customer #"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      prepend-icon="settings_input_composite"
                      v-model="rgaFormSupplierName"
                      label="Supplier Name"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <data-date-picker v-model="rgaReportStart" label="Retail Start Date" />
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="openRgaFormReport">Report</v-btn>
                    <v-btn
                      @click="
                        rgaSetupDialog = false;
                        rgaFormCustomerNumber = rgaFormSupplierName = '';
                      "
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-card-text>
            </v-card>
          </v-dialog>

          <retail-start-date-dialog
            v-model="handheldListingStartDate"
            :queueCallback="openHandHeldListingReport"
            :queueDisabled="handheldSelected"
            :startDateDialog.sync="handheldListingDialog"
            queueBtnText="Movement Report"
            queueDialogBtnText="Report"
          ></retail-start-date-dialog>
        </v-btn-toggle>
        <v-row justify="end" align="center" class="mr-3">
          <v-col>
            <v-btn :class="$vuetify.breakpoint.mdAndUp ? ['float-right'] : []" @click="getData"
              >Check For New Batches</v-btn
            >
          </v-col>
        </v-row>
      </v-row>
      <hand-held-data-table
        v-if="handHeldHeaderData"
        :modelValue.sync="handHeldHeaderData"
        :selected.sync="handHeldHeaderDataSelected"
        :handHeldSearch.sync="handHeldSearch"
        :completed.sync="completed"
        @row-clicked="selectHandHeldHeaderRow"
        @item-selected="selectHandHeldHeaderRow"
        @search-cleared="
          handHeldSearch = null;
          getData();
        "
        @get-data="getData"
      >
        <template v-slot:setToCompleted="{ item }">
          <v-checkbox
            v-model="item.completed"
            @click.stop="
              editHandHeldCompletedItem = item;
              showHandHeldCompleteDialog = true;
            "
          ></v-checkbox>
        </template>
      </hand-held-data-table>
      <v-row v-if="editHandHeldCompletedItem">
        <v-dialog v-model="showHandHeldCompleteDialog" max-width="700">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Set to {{ editHandHeldCompletedItem.completed ? '' : 'not' }} completed</span
              >
            </v-card-title>
            <v-card-text>
              Are you sure you want to set handheld batch
              <strong>
                '{{ editHandHeldCompletedItem.description }}
                {{
                  editHandHeldCompletedItem.headerDescription &&
                  editHandHeldCompletedItem.headerDescription.length > 0
                    ? ` -- ${editHandHeldCompletedItem.headerDescription}`
                    : ''
                }}'
              </strong>
              to {{ editHandHeldCompletedItem.completed ? '' : 'not' }} completed?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="setHandHeldCompleted(editHandHeldCompletedItem)"
                :disabled="setHandHeldCompletedLoading"
                :loading="setHandHeldCompletedLoading"
                >Save</v-btn
              >
              <v-btn
                @click="
                  showHandHeldCompleteDialog = false;
                  editHandHeldCompletedItem.completed = !editHandHeldCompletedItem.completed;
                "
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <retail-start-date-dialog
            v-model="retailStartDate"
            :queueLoading="handHeldToBatchLoading"
            :queueDisabled="handheldSelected"
            :queueCallback="handHeldToBatch"
            :startDateDialog.sync="startDateDialog"
            :createdBatchInfo="createdBatchInfo"
            :showCasePricing="true"
            :useCasePricing.sync="useCasePricing"
          >
            <v-card-actions>
              <v-col class="my-0 py-0 ml-3">
                <v-row>
                  <v-checkbox
                    v-model="useHandHeldScanOrder"
                    label="Use Hand Held Scan Order"
                    class="my-0 py-0"
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-card-actions>
          </retail-start-date-dialog>
        </v-col>
      </v-row>
      <v-row>
        <data-report-dialog
          v-model="reportDialog"
          :reportData="reportData"
          :templateName="reportTemplateName"
          :loading.sync="loadingReportData"
          :fileShareReport="fileShareReport"
          @snackbar-error="$emit('snackbar-error', $event)"
        ></data-report-dialog>
      </v-row>
    </core-view-section>
    <v-divider id="batchInfoItemsDivider" class="ma-8"></v-divider>
    <core-view-section
      v-if="createdBatchInfo"
      v-show="createdBatchInfo.printImmediately !== true"
      title="Queue Items"
    >
      <batch-check-items
        v-model="batchInfoItems"
        :batchInfo.sync="createdBatchInfo"
        @dataBound="batchInfoItemsDataBound"
        @error="$emit('snackbar-error', $event)"
        :footerBatchAndId="footerBatchAndId"
        :additionalCompleteUpdate="additionalCompleteUpdate"
        :includeSetDelimters="false"
        :autoCompleteBatch="true"
        originatingPage="Hand Held"
      ></batch-check-items>
    </core-view-section>
    <data-tour
      tourName="handheldPrintTour"
      :steps="tourSteps"
      maxWidth="600px"
      :show="true"
    ></data-tour>
  </core-signs-labels-view-template>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import uuid from 'uuid';
import PogonaDataTable from '@/utils/PogonaDataTable';
import AdalHelpers from '@/utils/AdalHelpers';
import BatchCheckItems from './BatchCheckItems.vue';
import RetailStartDateDialog from './RetailStartDateDialog.vue';
import HandHeldDataTable from '@/components/label/HandHeldDataTable.vue';
import PogonaDataHelper from '@/utils/PogonaDataHelper';
import handHeldMixins from '@/mixins/hand-held-mixins';
import HandHeldBatchCreator from '@/utils/HandHeldBatchCreator';

export default {
  mixins: [handHeldMixins],
  components: {
    BatchCheckItems,
    RetailStartDateDialog,
    HandHeldDataTable,
  },
  data() {
    return {
      handHeldHeaderData: this.$store.state.handheldprint.handHeldHeaderData || null,
      retailStartDate: undefined, // undefined so the default in the component will be used
      startDateDialog: false,
      adalHelper: new AdalHelpers(),
      handHeldToBatchLoading: false,
      handHeldBatchWs: null,
      reportData: {},
      reportTemplateName: '',
      reportLoading: false,
      reportDialog: false,
      rgaSetupDialog: false,
      rgaFormCustomerNumber: '',
      rgaFormSupplierName: '',
      useHandHeldScanOrder: true,
      loadingReportData: false,
      editHandHeldCompletedItem: null,
      showHandHeldCompleteDialog: false,
      setHandHeldCompletedLoading: false,
      useCasePricing: false,
      dataHelper: new PogonaDataHelper(),
      handheldListingStartDate: moment()
        .startOf('day')
        .subtract(7 * 13, 'days') // go back 13 weeks by default
        .format('YYYY-MM-DD'),
      handheldListingDialog: false,
      reportButtonGroup: null, // No Options Selected
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Hand Held Print Video Tour',
          },
          video: 'handheld_print',
          placement: 'bottom left',
        },
      ],
      primaryReportType: 2,
      allReportType: 3,
      preinventorySetupDialog: false,
      preCountReportStart: moment().startOf('day').format('YYYY-MM-DD'),
      rgaReportStart: moment().startOf('day').format('YYYY-MM-DD'),
      fileShareReport: null,
      useHandheldScanOrderPreCount: false,
      includePrimary: true,
      includePlu: false,
      includeCase: false,
      includeOther: false,
      sumByUpc: true,
      excludeZeroCount: true,
      handHeldHeaderDataSelected: [],
    };
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    batchInfoItems: {
      get() {
        return this.$store.state.handheldprint.batchInfoItems || {};
      },
      set(val) {
        this.$store.commit('handheldprint/batchInfoItems', val);
      },
    },
    handHeldSearch: {
      get() {
        return this.$store.state.handheldprint.handHeldSearch;
      },
      set(val) {
        this.$store.commit('handheldprint/handHeldSearch', val);
      },
    },
    completed: {
      get() {
        return this.$store.state.handheldprint.completed;
      },
      set(val) {
        this.$store.commit('handheldprint/completed', val);
      },
    },
    createdBatchInfo: {
      get() {
        return this.$store.state.handheldprint.createdBatchInfo;
      },
      set(val) {
        this.$store.commit('handheldprint/createdBatchInfo', val);
      },
    },
    footerBatchAndId() {
      return `HHB ${
        this.handHeldHeaderData &&
        this.handHeldHeaderDataSelected &&
        this.handHeldHeaderDataSelected.length > 0
          ? this.handHeldHeaderDataSelected[0].handHeldMainId
          : ''
      }`;
    },
    additionalCompleteUpdate() {
      if (
        this.handHeldHeaderData &&
        this.handHeldHeaderDataSelected &&
        this.handHeldHeaderDataSelected.length > 0
      ) {
        return {
          type: 'handHeld',
          ids: [this.handHeldHeaderDataSelected[0].handHeldMainId, this.storeNumber],
          fromAxView: this.handHeldHeaderDataSelected[0].fromAxView,
        };
      }

      return null;
    },
    handheldSelected() {
      return (
        this.handHeldToBatchLoading ||
        !this.handHeldHeaderData ||
        !this.handHeldHeaderDataSelected ||
        this.handHeldHeaderDataSelected.length === 0
      );
    },
    preCountReportDisabled() {
      return (
        this.includePrimary === false &&
        this.includePlu === false &&
        this.includeCase === false &&
        this.includeOther === false
      );
    },
    selectedBatchIsTrueScan() {
      if (
        this.handHeldHeaderData &&
        this.handHeldHeaderDataSelected &&
        this.handHeldHeaderDataSelected.length === 1
      ) {
        return this.handHeldHeaderDataSelected[0].trueScan === true;
      }
      return false;
    },
  },
  mounted() {
    if (!this.handHeldHeaderData || !this.handHeldHeaderData.inited) {
      this.getData();
    } else {
      this.setupEvents();
    }
  },
  methods: {
    setupEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting hand held data',
          err,
          id: 'ab7d9979-f1ff-4a36-b85f-a0c80d3aa444',
        });
      };

      this.handHeldHeaderData.removeListener('error', error);
      this.handHeldHeaderData.on('error', error);
    },
    async selectHandHeldHeaderRow(event) {
      let item = event;
      if (
        event.value &&
        typeof event.value === 'boolean' &&
        event.item &&
        typeof event.item === 'object'
      ) {
        item = event.item;
      }

      this.handHeldHeaderDataSelected = [];
      this.handHeldHeaderDataSelected.push(item);
      this.preCountReportStart = moment(item.headerDate).format('YYYY-MM-DD');
      this.rgaReportStart = this.preCountReportStart;
    },
    async handHeldToBatch(source) {
      const errorMessage = err => {
        this.handHeldToBatchLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error creating batch from hand held.',
          err,
          id: 'e73c560d-3733-40f3-928d-61e44d6be466',
        });
      };

      try {
        this.batchInfoItems = {};
        this.createdBatchInfo = null;
        this.startDateDialog = false;
        this.handHeldToBatchLoading = true;

        let batchCreated = false;

        const onClose = () => {
          this.useCasePricing = false;
          // If the web socket connection closed before
          // the batch was created throw an error message.
          if (batchCreated === false) {
            errorMessage();
          }
        };

        // Register on message handler before posting the message
        const onmessage = event => {
          const recMessage = JSON.parse(event.data);
          batchCreated = true;

          this.handHeldBatchWs.close();

          if (recMessage.success === false) {
            errorMessage(recMessage);
            return;
          }

          this.createdBatchInfo = recMessage.batchInfo;
          this.createdBatchInfo.printImmediately = (source && source.printImmediately) || false;
        };

        const hbc = new HandHeldBatchCreator(
          this.$authApi,
          this.$apiBasePath,
          this.storeNumber,
          this.retailStartDate,
          this.useCasePricing,
          this.handHeldHeaderDataSelected[0].handHeldMainId,
          this.useHandHeldScanOrder === false,
          this.handHeldHeaderDataSelected[0].fromAxView,
        );
        this.handHeldBatchWs = await hbc.open(onClose, onmessage);
      } catch (err) {
        this.$error(err);
        errorMessage(err);
      }
    },
    batchInfoItemsDataBound() {
      // Jump to the batch data.
      this.handHeldToBatchLoading = false;
      this.$vuetify.goTo('#batchInfoItemsDivider');
    },
    getData() {
      this.handHeldHeaderData = null;

      this.initHandHeldHeaders();
      this.handHeldHeaderData.loading = false;
      this.handHeldHeaderData.get();
    },
    async setHandHeldCompleted(editHandHeldCompletedItem) {
      try {
        this.setHandHeldCompletedLoading = true;

        await this.setHandHeldCompletedStatus(
          editHandHeldCompletedItem.handHeldMainId,
          editHandHeldCompletedItem.completed,
        );

        this.setHandHeldCompletedLoading = false;
        this.showHandHeldCompleteDialog = false;
        this.getData();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error setting completed status.',
          err,
          id: '61982f21-b525-4374-8f68-600097c3e538',
        });
        this.setHandHeldCompletedLoading = false;
      }
    },
    handHeldMainQueryStringParams() {
      if (this.handHeldSearch && this.handHeldSearch.trim().length > 0) {
        return {
          search: this.handHeldSearch,
        };
      }
      return null;
    },
    initHandHeldHeaders() {
      this.handHeldHeaderData = new PogonaDataTable({
        headers: [
          {
            text: 'Batch ID',
            value: 'handHeldMainId',
          },
          {
            text: 'Label Type',
            value: 'labelType',
          },
          {
            text: 'Description',
            value: 'headerDescription',
          },
          {
            text: 'Batch Date',
            value: 'headerDate',
          },
          {
            text: 'Set Completed Status',
            value: 'setToCompleted',
            sortable: false,
          },
        ],
        baseUrl: `label/handheldmain/store/${this.storeNumber}/${this.completed}`,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['headerDate'], sortDesc: [true] },
        isInfinite: true,
      });
      this.handHeldHeaderData.queryStringParams = this.handHeldMainQueryStringParams();
      this.setupEvents();
    },
    async openPreInventoryReport() {
      this.preinventorySetupDialog = false;
      const useHandheldScanOrderPreCount = this.useHandheldScanOrderPreCount;
      this.useHandheldScanOrderPreCount = false;

      this.fileShareReport = {
        directory: 'Pre-Count',
        fileName: `${this.storeNumber}-${moment().format('YYYYMMDD')}-${
          this.handHeldHeaderDataSelected[0].handHeldMainId
        }-PreCount.xlsx`,
        isExcel: true,
      };

      let includes = '?';

      if (this.selectedBatchIsTrueScan === true) {
        includes += `sumByUpc=${this.sumByUpc === true ? 'true' : 'false'}&excludeZeroCount=${
          this.excludeZeroCount === true ? 'true' : 'false'
        }&`;
      } else {
        // create 'include' query string args
        if (this.includePrimary === true) {
          includes += 'includePrimary=true&';
        }
        if (this.includePlu === true) {
          includes += 'includePlu=true&';
        }
        if (this.includeCase === true) {
          includes += 'includeCase=true&';
        }
        if (this.includeOther === true) {
          includes += 'includeOther=true';
        }
      }

      if (includes[includes.length - 1] === '&') {
        includes = includes.substring(0, includes.length - 1);
      }

      // because we do in-memeory filtering on the label data api server
      // we need to get every row all at once, otherwise we'll see duplicates where the pages end
      this.openReport(
        '/pre-count/main',
        `label/report/preinventory/${this.handHeldHeaderDataSelected[0].handHeldMainId}/${
          this.handHeldHeaderDataSelected[0].fromAxView
        }/${moment(this.preCountReportStart).format('YYYY-MM-DD')}${includes.trimEnd('&')}`,
        useHandheldScanOrderPreCount,
        999999,
      );
    },
    async openRgaFormReport() {
      this.rgaSetupDialog = false;
      this.fileShareReport = null;

      this.openReport(
        '/rga-form/main',
        `label/report/rgaform/${this.handHeldHeaderDataSelected[0].handHeldMainId}/${
          this.handHeldHeaderDataSelected[0].fromAxView
        }/${moment(this.rgaReportStart).format('YYYY-MM-DD')}?customerNum=${
          this.rgaFormCustomerNumber
        }&supplier=${this.rgaFormSupplierName}`,
        true,
      );
      this.rgaFormCustomerNumber = '';
      this.rgaFormSupplierName = '';
    },
    async openHandHeldListingReport() {
      this.handheldListingDialog = false;
      this.fileShareReport = null;

      this.openReport(
        '/movement-report/main',
        `label/report/movement-report/${this.handHeldHeaderDataSelected[0].handHeldMainId}/${this.handHeldHeaderDataSelected[0].fromAxView}?startTime=${this.handheldListingStartDate}`,
        true,
      );
    },
    async openReport(templateName, dataUrl, useHandheldScanOrder, pagesize) {
      this.reportDialog = true;
      this.loadingReportData = true;
      this.reportTemplateName = templateName;

      try {
        this.reportData = await this.dataHelper.getAllReportData({
          $authApi: this.$authApi,
          url: dataUrl,
          orderBy: useHandheldScanOrder ? ['printSequence'] : ['upc'],
          pageSize: pagesize || 500,
        });
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error loading report data.',
          err,
          id: '9da3b05b-5515-4441-8835-89e5190944fc',
        });
      }
    },
  },
  watch: {
    storeNumber: {
      handler() {
        this.batchInfoItems = {};
        this.createdBatchInfo = null;
        this.getData();
      },
    },
    reportDialog: {
      handler(val) {
        // Cancel any requests in flight
        if (val === false) {
          this.dataHelper.cancelInFlightRequests();
        }
      },
    },
    handHeldHeaderData: {
      handler(val) {
        this.$store.commit('handheldprint/handHeldHeaderData', val);
      },
      deep: true,
    },
    handHeldSearch: {
      handler(val) {
        this.handHeldHeaderData.queryStringParams = this.handHeldMainQueryStringParams();
        this.$store.commit('handheldprint/handHeldHeaderData', val);
      },
      deep: true,
    },
  },
};
</script>
