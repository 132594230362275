import {
  StoreSetCompareState,
  StoreSetDataCompared,
  StoreSet,
  StoreSetCompareParams,
} from './state';
import * as pm from './mutations';
import * as m from '@/store/mutations';
import * as vx from 'vuex';
import { AxiosRequestConfig } from 'axios';
import axios from 'axios';

export const getAllStoreSets = 'getAllStoreSets';
export const getStoreStoreSets = 'getStoreStoreSets';
export const getComparedStoreSet = 'getComparedStoreSet';

export default {
  [getAllStoreSets]({
    commit,
    state,
  }: vx.ActionContext<StoreSetCompareState, any>): Promise<unknown> {
    commit(pm.setAllStoreSetsGetInProgress, true);

    return state.client
      ?.apiGet('label/storeset?$top=99999&$orderby=setId asc')
      .then(x => {
        commit(pm.setAllStoreSets, x.data);
        commit(pm.setAllStoreSetsGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: 'Error getting all store sets.',
            id: '5367d079-4ef4-407d-8861-764835c01cf8',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getStoreStoreSets](
    { commit, state }: vx.ActionContext<StoreSetCompareState, any>,
    payload: string,
  ): Promise<unknown> {
    commit(pm.setStoreStoreSetsGetInProgress, true);

    return state.client
      ?.apiGet(`label/storeset/store/${payload}?$top=99999&$orderby=setId asc`)
      .then(x => {
        commit(pm.setStoreStoreSets, x.data);
        commit(pm.setStoreStoreSetsGetInProgress, false);
      })
      .catch(err => {
        commit(
          m.setError,
          {
            err,
            text: "Error getting store's store sets.",
            id: 'fae949bd-6e38-4e31-bc93-c26f539b1491',
          },
          { root: true },
        );
        throw err;
      });
  },
  [getComparedStoreSet](
    { commit, state }: vx.ActionContext<StoreSetCompareState, any>,
    payload: StoreSetCompareParams,
  ): Promise<unknown> {
    commit(pm.setCompareStoreSetsInProgress, true);

    const cancelMsg = 'Operation canceled by the user.';
    const requestToken = axios.CancelToken;
    // cancel any request in flight
    if (state.compareStoreSetsGetInProgress) {
      state.compareStoreSetsGetInProgress.cancel(cancelMsg);
    }

    // if both set ID's are the same, don't even bother
    if (payload.SourceSetId === payload.TargetSetId) {
      commit(pm.setCompareStoreSetsInProgress, false);
      return Promise.resolve(0);
    }

    const ctsSource = requestToken.source();
    state.compareStoreSetsGetInProgress = ctsSource;

    return state.client
      ?.apiGet(
        `label/storesetdata/compare/${payload.StoreNumber}/${payload.SourceSetId}/${payload.TargetSetId}`,
        null,
        undefined,
        {
          cancelToken: ctsSource.token,
        },
      )
      .then(x => {
        commit(pm.setComparedStoreSet, x.data);
        commit(pm.setCompareStoreSetsInProgress, false);
      })
      .catch(err => {
        // if it was cancelled, bail
        if (err && err.message && err.message === cancelMsg) {
          return;
        }

        commit(
          m.setError,
          {
            err,
            text: 'Error comparing store sets.',
            id: '62c3a153-b4b6-47e7-9a43-d9763e90479b',
          },
          { root: true },
        );
        throw err;
      });
  },
};
