<template>
  <v-container>
    <v-row>
      <span>File name: {{ uploadFile.name }}</span>
    </v-row>
    <v-row>
      <span>Valid rows found: {{ validationData.Count }}</span>
    </v-row>
    <v-row>
      <span class="mt-3">File Preview: *</span>
    </v-row>
    <v-row>
      <v-data-table :headers="headers" :items="validationData.SampleRows" hide-default-footer>
        <template v-slot:item.Subscription="{ item }">
          {{ item.Subscription ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.InitialDiscount="{ item }">
          {{ item.InitialDiscount ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.OnGoingDiscount="{ item }">
          {{ item.OnGoingDiscount ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <span>* If subscription = false then all flags are set to false automatically</span>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="cancelClicked">Cancel</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="saveExceptionsClicked">Save Exceptions</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as ea from '@/store/modules/ecom/actions';
import * as em from '@/store/modules/ecom/mutations';
import * as es from '@/store/modules/ecom/state';
import { SubscriptionValidationResult } from '@psp/pogona_ecom_client_js/SubscriptionValidationResult';

/* eslint-disable @typescript-eslint/no-explicit-any */

const ecomModule = namespace('ecom');

@Component({})
export default class AutoShipValidationReview extends Vue {
  @ecomModule.State(es.validationData) validationData!: SubscriptionValidationResult | null;
  @ecomModule.State(es.uploadFile) uploadFile!: File | null;

  @ecomModule.Mutation(em.setUploadFile) setUploadFile!: (payload: File | null) => void;
  @ecomModule.Mutation(em.setValidationData) setValidationData!: (
    payload: SubscriptionValidationResult | null,
  ) => void;
  @ecomModule.Mutation(em.setShowProcessing) setShowProcessing!: (payload: boolean) => void;

  @ecomModule.Action(ea.processExcelFile) processExcelFile!: (payload: File | null) => void;

  private cancelClicked() {
    this.setUploadFile(null);
    this.setValidationData(null);
  }

  private saveExceptionsClicked() {
    this.processExcelFile(this.uploadFile);
    this.setShowProcessing(true);
    this.setValidationData(null);
  }

  private headers = [
    { text: 'ItemId', value: 'ItemId' },
    { text: 'SKU', value: 'Sku' },
    { text: 'Subscription', value: 'Subscription' },
    { text: 'Initial Discount', value: 'InitialDiscount' },
    { text: 'On Going Discount', value: 'OnGoingDiscount' },
  ];
}
</script>
